import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { InputWrapper } from "./InputWrapper";
import { Radio } from "./Radio";
import cx from "classnames";

export const ChoiceGroup = ({ children, disabled, label, name, choices, validate, initialValue, ...props }) => {
  return (
    <InputWrapper label={label} {...props}>
      {_.map(choices, (c, idx) => {
        const isLast = idx === choices.length - 1;

        return (
          <Field key={idx} initialValue={initialValue} name={name} validate={validate} type="radio" value={c.value} label={c.label}>
            {(inputProps) => {
              return (
                <Radio showError={isLast} disabled={disabled} {...inputProps} id={`${name}-${idx}`} labelClass={cx({ "me-1": !isLast })} />
              );
            }}
          </Field>
        );
      })}

      {children}
    </InputWrapper>
  );
};
