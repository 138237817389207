import React, { useMemo } from "react";
import { Toolbar } from "../../../components/Toolbar";
import { Link } from "react-router-dom";
import { Routes } from "../../Routes";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { AdministrateTable } from "../../components/AdministrateTable";
import { ClinicPayorMappingActionCell } from "../components/ClinicPayorMappingActionCell";
import { AddClinicPayorMappingModal } from "../components/AddClinicPayorMappingModal";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { ImportClinicPayorMappingsModal } from "../components/ImportClinicPayorMappingsModal";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";

const useColumns = makeColumns((clinicId) => [
  {
    id: "payor",
    accessor: "payor.name",
    Header: "Payor",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="name" labelKey="name" searchParam="name" />
    ),
    Cell: ({ row, value }) => (
      <Link to={Routes.payor.url(row.original.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
  },
  {
    id: "insuranceName",
    accessor: "insuranceName",
    Header: "Provided Payor Name",
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <ClinicPayorMappingActionCell clinicId={clinicId} {...row} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const ClinicsPayorMappingsTab = ({ clinic, clinicId, refresh }) => {
  const columns = useColumns(clinicId);

  const [canCreate] = useCreatePermission("ClinicPayorMapping");

  const extraParams = useMemo(() => ({ clinic: { id: clinicId } }), [clinicId]);
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onAdd = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/clinic_payor_mappings/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreate && (
            <>
              <ImportClinicPayorMappingsModal clinicId={clinicId} outline className="me-2" onImport={onImport} />
              <AddClinicPayorMappingModal clinicId={clinicId} color="primary" onAdd={onAdd} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/clinic_payor_mappings.json"}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
        resourceName={"payor"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
