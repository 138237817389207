import React, { useState } from "react";
import { Toolbar } from "../../../components/Toolbar";
import { Link } from "react-router-dom";
import { Routes } from "../../Routes";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission, useUpdatePermission } from "../../utils";
import { AdministrateTable } from "../../components/AdministrateTable";
import { ClinicTestMappingsActionCell } from "../components/ClinicTestMappingsActionCell";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AddClinicTestMappingModal } from "../components/AddClinicTestMappingModal";
import { BoolCell } from "../../../components/grid/cells/BoolCell";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { ImportClinicTestMappingModal } from "../components/ImportClinicTestMappingModal";
import { Switch } from "../../../components/inputs/Switch";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";

const useColumns = makeColumns((clinicId) => [
  {
    id: "lab.id",
    accessor: "lab.labName",
    Header: "Lab",
    Cell: ({ row, value }) => (
      <Link to={Routes.lab.url(row.original.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/labs.json" valueKey="id" labelKey="lab_name" />,
  },
  {
    id: "test.id",
    accessor: "test.commercialName",
    Header: "Test",
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/tests.json" valueKey="id" labelKey="commercial_name" />,
  },
  {
    id: "provided_test_name",
    accessor: "providedTestName",
    Header: "Provided Test Name",
  },
  {
    id: "reflex_to_pa",
    accessor: "reflexToPa",
    Header: "Reflex To PA",
    Cell: BoolCell,
    Filter: BoolFilter,
    width: 80,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ClinicTestMappingsActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const ClinicsTestMappingsTab = ({ clinic, clinicId }) => {
  const columns = useColumns(clinicId);

  const [canCreate] = useCreatePermission("ClinicTestMapping");
  const [canUpdate] = useUpdatePermission("ClinicTestMapping");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const extraParams = { clinic: { id: clinicId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onAdd = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/clinic_test_mappings/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreate && (
            <>
              <ImportClinicTestMappingModal clinicId={clinicId} outline className="me-2" onImport={onImport} />
              <AddClinicTestMappingModal clinicId={clinicId} color="primary" onAdd={onAdd} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/clinic_test_mappings.json"}
        extraParams={extraParams}
        permissions={[DESTROY, UPDATE]}
        resourceName={"clinic_test_mapping"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};

// TODO: update
