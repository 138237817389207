import { AnimatePresence, motion } from "framer-motion";
import classNames from "classnames";
import { ChevronLeft, ChevronRight, Plus, User } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import { useCreatePermission } from "../system_config/utils";
import { api, redirectTo } from "../util/api";
import { useLocalStorage, useUniqueId } from "../util/hooks";
import { Button } from "./Button";
import Icon from "./Icon";
import { EventMiniBus } from "../util/event-bus";

export const Sidebar = ({ userName, newCasePath, logoPath, logoPathCollapsed, logoutPath, navItems = [], userMenuItems = [] }) => {
  const { value: collapsed, set: setCollapsed } = useLocalStorage("careviso_sidebar_collapsed", false);
  const toggleCollapsed = useCallback(() => setCollapsed(!collapsed), [collapsed, setCollapsed]);
  const sidebarEl = useMemo(() => document.getElementById("sidebar"), []);
  useEffect(() => {
    sidebarEl?.classList?.toggle("collapsed", collapsed);
  }, [sidebarEl, collapsed]);

  EventMiniBus.on("sidebar:toggle", () => {
    toggleCollapsed();
  });

  const logos = useMemo(() => ({ wide: logoPath, collapsed: logoPathCollapsed }), [logoPath, logoPathCollapsed]);

  return (
    <>
      <SidebarNav collapsed={collapsed} logos={logos} newCasePath={newCasePath} navItems={navItems} />
      <SidebarUser collapsed={collapsed} userName={userName} logoutPath={logoutPath} userMenuItems={userMenuItems} />
      <SidebarToggler collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
    </>
  );
};

function SidebarNav({ collapsed, logos, newCasePath, navItems }) {
  const [canCreate] = useCreatePermission("Case");
  const { wide: logoPath, collapsed: logoPathCollapsed } = logos;

  return (
    <Nav tag="nav" vertical>
      <NavItem tag="header">
        <img alt="Careviso Logo" src={collapsed ? logoPathCollapsed : logoPath} />
      </NavItem>

      {canCreate && (
        <NavItem tag="section" className="sidebar__actions">
          <Button
            href={newCasePath}
            icon={<Plus />}
            id="new-case-button"
            color="primary"
            className={classNames({ "btn-round": collapsed })}
          >
            {collapsed ? null : "New Case"}
          </Button>

          {collapsed && (
            <UncontrolledTooltip target="new-case-button" placement="right">
              New Case
            </UncontrolledTooltip>
          )}
        </NavItem>
      )}

      <Nav>
        {navItems.map((item) => (
          <SidebarNavItem tag="section" collapsed={collapsed} item={item} key={item.path} />
        ))}
      </Nav>
    </Nav>
  );
}

const SidebarNavItem = ({ item, collapsed }) => {
  const id = useUniqueId("sidebar-item");

  return (
    <NavItem>
      <NavLink id={id} href={item.path} active={item.active}>
        {item.icon ? <Icon name={item.icon} className="nav-icon" /> : null}

        {collapsed ? null : <span className={`sidebar__nav_link_label${item.active && "-active"}`}>{item.label}</span>}
      </NavLink>

      {collapsed && (
        <UncontrolledTooltip placement="right" target={id}>
          {item.label}
        </UncontrolledTooltip>
      )}
    </NavItem>
  );
};

function SidebarUser({ collapsed, userName, logoutPath, userMenuItems }) {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const toggleUserMenu = () => setUserMenuOpen(!userMenuOpen);

  const handleLogout = async () => {
    try {
      await api.delete(`${logoutPath}?return_to=${window.location.href}`);
    } finally {
      redirectTo("/users/sign_in", "You have been logged out.");
    }
  };

  return (
    <footer className="sidebar__footer">
      <Nav pills vertical className="sidebar__nav">
        <Dropdown nav isOpen={userMenuOpen} toggle={toggleUserMenu} direction="right">
          <DropdownToggle nav>
            <User className={classNames("sidebar__nav_icon nav-icon", { "me-2": !collapsed })} />

            {!collapsed && (
              <>
                {userName}
                <ChevronRight size={16} className="ms-auto" />
              </>
            )}
          </DropdownToggle>

          <DropdownMenu style={{ marginLeft: -10 }}>
            {userMenuItems.map((item) => (
              <DropdownItem key={item.path} href={item.path}>
                {item.label}
              </DropdownItem>
            ))}

            <DropdownItem divider />

            <DropdownItem onClick={handleLogout}>Log Out</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Nav>
    </footer>
  );
}

function SidebarToggler({ collapsed, toggleCollapsed }) {
  const CollapseIcon = collapsed ? ChevronRight : ChevronLeft;

  return (
    <AnimatePresence mode="wait">
      <Button onClick={toggleCollapsed} size="sm" className="sidebar__collapse_button" title="Open Sidebar. Or hit CTRL + b">
        <motion.div>
          <CollapseIcon size={16} />
        </motion.div>
      </Button>
    </AnimatePresence>
  );
}
