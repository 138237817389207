import React, { useMemo } from "react";

export const MoneyCell = ({ cents }) => {
  const dollars = useMemo(() => {
    if (cents == null) return null;
    return (cents / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
  }, [cents]);

  if (_.isNil(dollars)) {
    return null;
  }

  return <div className="text-mono text-end">{dollars}</div>;
};
