import { createRoot } from "react-dom/client";
import { ActiveClinicSelector } from "../select_clinic/ActiveClinicSelector";

export function initActiveClinicSelector() {
  const container = document.getElementById("active_clinic_selector");

  if (container) {
    const root = createRoot(container);
    const { submitUrl, redirectUrl, clinicOptions } = container.dataset;

    root.render(<ActiveClinicSelector submitUrl={submitUrl} redirectUrl={redirectUrl} clinicOptions={JSON.parse(clinicOptions)} />);
  }
}
