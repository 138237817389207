import { Field } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { DateInput } from "../../../components/inputs/final_form/DateInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required } from "../../../util/validators";
import { Fieldset } from "../../../components/inputs/final_form/Fieldset";
import { Col, Row } from "reactstrap";
import { useMemo, useState } from "react";
import { Switch } from "../../../components/inputs/final_form/Switch";

export const AuthTokenForm = ({ token, handleSubmit, submitting, ...rest }) => {
  const [tokenHovered, setTokenHovered] = useState(false);

  const toggleToken = () => setTokenHovered(!tokenHovered);

  const initialAccountOptions = useMemo(() => {
    return token?.account ? [{ label: token?.account.fullNameOrEmail, value: token?.account.id }] : [];
  }, [token?.account]);

  const initialAuthableLabOptions = useMemo(() => {
    return token?.authableLabs ? token?.authableLabs.map((lab) => ({ label: lab.lab_name, value: lab.id })) : [];
  }, [token?.authableLabs]);

  const initialAuthableClinicOptions = useMemo(() => {
    return token?.authableClinics ? token?.authableClinics.map((clinic) => ({ label: clinic.clinic_name, value: clinic.id })) : [];
  }, [token?.authableClinics]);

  return (
    <>
      {token && token.secret && token.token && (
        <>
          <div className="d-flex flex-column align-items-center">
            <p className="mb-1">Token and Secret</p>

            <div
              className="d-flex flex-row bg-secondary rounded-2 p-2 mb-2 justify-content-center"
              onMouseEnter={toggleToken}
              onMouseLeave={toggleToken}
              style={{ minWidth: "22rem" }}
            >
              {tokenHovered ? (
                <p className="token mb-0 fs-6 px-2 font-monospace">
                  {token.token} - {token.secret}
                </p>
              ) : (
                <p className="text-white mb-0 fw-semibold">Sensitive Information</p>
              )}
            </div>
          </div>

          <hr />
        </>
      )}

      <Row>
        <Col>
          <Field
            component={AdministrateSelectInput}
            validate={required}
            resourceUrl="/admin/accounts.json"
            labelKey="full_name_or_email"
            name="accountId"
            label="Account"
            valueKey="id"
            required
            initialOptions={initialAccountOptions}
          />
        </Col>

        <Col>
          <Field component={TextInput} validate={required} label="Description" name="description" required />
        </Col>
      </Row>

      <Row>
        <Col>
          <Field
            component={AdministrateSelectInput}
            resourceUrl="/admin/enums/auth_token_types_options.json"
            label="Token Type"
            name="tokenType"
            labelKey="label"
            valueKey="id"
            validate={required}
            required
          />
        </Col>

        <Col>
          <Field name="expiresAt" component={DateInput} validate={required} label="Expires At" type="date" required />
        </Col>
      </Row>

      <Fieldset legend="Authable">
        <Row>
          <Col>
            <Field
              component={AdministrateSelectInput}
              resourceUrl="/admin/labs.json"
              label="Labs"
              name="authableLabs"
              valueKey="id"
              isMulti
              initialOptions={initialAuthableLabOptions}
            />
          </Col>

          <Col>
            <Field
              component={AdministrateSelectInput}
              resourceUrl="/admin/clinics.json"
              label="Clinics"
              name="authableClinics"
              isMulti
              initialOptions={initialAuthableClinicOptions}
            />
          </Col>
        </Row>
      </Fieldset>

      <Fieldset legend="Signature" className="mb-3">
        <Row>
          <Col>
            <Field component={Switch} defaultValue={false} label="Allow Basic" id="allow_basic" name="allowBasic" type="checkbox" />
          </Col>

          <Col>
            <Field
              component={Switch}
              defaultValue={false}
              label="Allow Signature v1"
              id="allow_signature_v1"
              name="allowSignatureV1"
              type="checkbox"
            />
          </Col>
        </Row>
      </Fieldset>

      <div className="d-flex flex-row-reverse">
        <LoadingButton loading={submitting} onClick={handleSubmit} type="submit" color="primary" disabled={submitting}>
          Submit
        </LoadingButton>
      </div>
    </>
  );
};
