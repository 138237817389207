import _ from "lodash";
import { formatDate } from "../../../util/formatters";

export const DueDateCell = ({ row }) => {
  const task = _.get(row, "original.pa_request.nextTaskDue");

  if (!task) {
    return null;
  }

  const { dueDate, completed } = task;
  let formattedDate = formatDate(dueDate);

  if (completed) {
    return (
      <span className="text-subtle">
        <del>{formattedDate}</del>
      </span>
    );
  } else {
    return <span className="text-warning">{formattedDate}</span>;
  }
};
