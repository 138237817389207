import React, { useCallback } from "react";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";

export const ImportLabIdentifierModal = ({ labId, onImport, ...rest }) => {
  const handleImport = useCallback(
    async (values) => {
      const params = {
        payorIds: values.payorIds,
        file: {
          bytes: values.file,
        },
      };

      const res = await api.post(`/admin/lab_identifiers/import.json?lab_id=${labId}`, snakifyKeys(params));

      if (onImport) {
        onImport(res.data?.meta?.message, res.data?.meta?.error_details);
      }
    },
    [onImport, labId]
  );

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Import NPI/TIN Overrides..."
      exampleDownloadPath="/admin/lab_identifiers/example"
    ></ImportModal>
  );
};
