import React from "react";
import { formatCentsAsDollars } from "../util/formatters";

export const PriceLabel = ({ cents }) => {
  const dollarString = formatCentsAsDollars(cents);
  const [d, c] = dollarString.split(".");

  return (
    <span className="price">
      <span className="dollar">{d}</span>
      <span className="cents">.{c}</span>
    </span>
  );
};
