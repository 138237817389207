import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { EditClinicTestMappingModal } from "./EditClinicTestMappingModal";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const ClinicTestMappingsActionCell = ({ row }) => {
  const [canUpdate] = useUpdatePermission("ClinicTestMapping", row.original.id);
  const [canDestroy] = useDestroyPermission("ClinicTestMapping", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();

  return (
    <>
      {canUpdate && (
        <EditClinicTestMappingModal
          color="link"
          clinicTestMapping={row.original}
          onUpdate={(message) => {
            refresh();
            setMessage(message);
          }}
        />
      )}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/clinic_test_mappings/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted "${row.original.providedTestName}".`);
          }}
          onError={setError}
        />
      )}

      <History entityName={"ClinicTestMapping"} subjectId={row.original.id} />
    </>
  );
};
