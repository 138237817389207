import React, { useCallback } from "react";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";

export const ImportGroupsModal = ({ onImport, ...rest }) => {
  const handleImport = useCallback(
    async (values) => {
      const params = {
        file: {
          bytes: values.file,
        },
      };
      const res = await api.post("/admin/groups/import.json", snakifyKeys(params));

      onImport?.(res.data?.meta?.message, res.data?.meta?.error_details);
    },
    [onImport]
  );

  return <ImportModal buttonProps={rest} onImport={handleImport} title="Import Groups..." exampleDownloadPath="/admin/groups/example" />;
};
