import { Button } from "reactstrap";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { Link } from "react-router-dom";
import { Routes } from "../../Routes";

export const AuthTokenActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("AuthToken", row.original.id);
  const [canUpdate] = useUpdatePermission("AuthToken", row.original.id);

  return (
    <>
      {canUpdate && (
        <Link to={Routes.authToken.url(row.original.id)}>
          <Button color="link">Edit</Button>
        </Link>
      )}

      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/auth_tokens/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Auth Token ${row.original.description}`);
          }}
          onError={setError}
        />
      )}
    </>
  );
};
