import React from "react";
import { Field, FormSpy } from "react-final-form";
import { Checkbox } from "../../../../components/inputs/final_form/Checkbox";
import { LoadingButton } from "../../../../components/LoadingButton";
import { required } from "../../../../util/validators";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";

export const SeeqerReflexMappingForm = ({ handleSubmit, submitting, initialTestOptions = [] }) => {
  return (
    <>
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/labs.json"
        searchParam="name"
        label="Lab"
        kept={true}
        name="labId"
        validate={required}
      />
      <Field id="allPayors" component={Checkbox} label="All Payors" name="allPayors" type="checkbox" />
      <Field name="allPayors" subscription={{ value: true }}>
        {({ input: { value } }) =>
          !value && (
            <Field component={AdministrateSelectInput} resourceUrl="/admin/payors.json" label="Payors" name="payorId" searchParam="name" />
          )
        }
      </Field>
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          return values.labId ? (
            <>
              <Field id="allTests" component={Checkbox} label="All Tests" name="allTests" type="checkbox" />
              <Field name="allTests" subscription={{ value: true }}>
                {({ input: { value } }) =>
                  !value && (
                    <Field
                      component={AdministrateSelectInput}
                      resourceUrl={`/admin/tests.json?test[direction]=asc&test[order]=commercial_name&lab=${values.labId}&archived=false`}
                      label="Tests"
                      labelKey="commercial_name"
                      name="testIds"
                      initialOptions={initialTestOptions}
                      isMulti
                      valueKey="id"
                      key="tests"
                    />
                  )
                }
              </Field>
            </>
          ) : (
            <>
              <Field id="allTests" component={Checkbox} label="All Tests" name="allTests" type="checkbox" disabled={true} />
              <Field name="allTests" subscription={{ value: true }}>
                {({ input: { value } }) =>
                  !value && (
                    <Field
                      component={AdministrateSelectInput}
                      label="Tests"
                      resourceUrl={"/admin/tests.json?archived=false"}
                      labelKey="commercial_name"
                      name="testIds"
                      isMulti
                      valueKey="id"
                      isDisabled={true}
                      key="tests-disabled"
                    />
                  )
                }
              </Field>
            </>
          );
        }}
      </FormSpy>

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
