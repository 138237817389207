import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { useUpdatePermission } from "../../utils";
import { LabReflexToPAToggle } from "./LabReflexToPAToggle";

export const LabReflexToPAActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canUpdate] = useUpdatePermission("ClinicLabPermission", row.original.id);

  const { reflexToPa, lab } = row.original ?? {};

  const handleChange = (msg) => {
    setMessage(msg);
    refresh();
  };

  return (
    <LabReflexToPAToggle
      clinicLabPermission={row.original}
      labName={lab.labName}
      reflexToPa={reflexToPa}
      onChange={handleChange}
      readOnly={!canUpdate}
    />
  );
};
