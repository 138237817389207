import React, { useState } from "react";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission } from "../../utils";

export const PayorNonCoveredCodeActionCell = ({ payorId, ...row }) => {
  const [canDestroy] = useDestroyPermission("CptCoverageDenial", row.original.id);

  const [removing, setRemoving] = useState(false);
  const { refresh, setMessage, setError } = useTableContext();

  const handleRemoveNonCoveredCode = async () => {
    setRemoving(true);
    try {
      const { data } = await api.delete(`/admin/cpt_coverage_denials/${row.original.id}`, { params: { payor_ids: [payorId] } });
      refresh();
      setMessage(`Non-Covered CPT Code ${row.original.cptCode.code} deleted`);
    } catch (err) {
      setError(extractErrorMessage(err));
    } finally {
      setRemoving(false);
    }
  };

  return (
    canDestroy && (
      <LoadingButton loading={removing} color="link" className="text-danger" onClick={handleRemoveNonCoveredCode}>
        Remove
      </LoadingButton>
    )
  );
};
