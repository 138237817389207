import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { OutOfNetworkPaRequiredToggle } from "./OutOfNetworkPaRequiredToggle";

export const OutOfNetworkPaRequiredActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const { id: cptCodeSettingId, requiresPaOutOfNetwork } = row?.original ?? {};
  const { id: payorId, name: payorName } = row?.original?.payor ?? {};
  const { id: cptCodeId, code: cptCode } = row?.original?.cptCode ?? {};

  const handleChange = (msg) => {
    setMessage(msg);
    refresh();
  };

  const handleError = (msg) => {
    setError(msg);
    refresh();
  };

  return (
    <OutOfNetworkPaRequiredToggle
      payorId={payorId}
      payorName={payorName}
      requiresPaOutOfNetwork={requiresPaOutOfNetwork}
      cptCodeSettingId={cptCodeSettingId}
      cptCodeId={cptCodeId}
      cptCode={cptCode}
      onChange={handleChange}
      onError={handleError}
    />
  );
};
