import { createRoot } from "react-dom/client";
import { LabSettingsStatusTranslations } from "../../admin/LabSettingsStatusTranslations";

export const initAdminLabSettings = () => {
  const container = document.getElementById("lab-settings-status-translations");

  if (container != null) {
    const root = createRoot(container);

    const { fieldValue } = container.dataset;
    let parsedFieldValue = [];

    try {
      parsedFieldValue = JSON.parse(fieldValue || "[]");
    } catch (e) {
      console.warn("Encountered an error when attempting to parse json", e);
    }

    root.render(<LabSettingsStatusTranslations defaultValue={parsedFieldValue} />);
  }
};
