import "bootstrap";
import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import * as _ from "lodash";

// Global availability
window.React = React;
window.ReactDOM = ReactDOM;
window._ = _;

// App
import app from "orcajs";

// Admin Actions
import { initAdminLab } from "./actions/admin/init_admin_lab";
import { initAdminLabSettings } from "./actions/admin/init_admin_lab_settings";
import { initBillingRatesGrid } from "./actions/admin/init_billing_rates_grid";
import { initBillingRateDeleteButton } from "./actions/admin/init_billing_rate_delete_button";
import { initLabSettings } from "./actions/admin/init_lab_settings";
import { initClonePolicies } from "./actions/admin/init_clone_policies";
import { initNewAuthTokenForm } from "./actions/forms/init_new_auth_token_form";

import { initSystemConfig } from "./actions/init_system_config";
import { initFlashMessage } from "./actions/init_flash_message";

// Form Actions
import { initEditSafety } from "./actions/forms/init_edit_safety";
import { initNewCase } from "./actions/forms/init_new_case_form";
import { initNewCaseShadowbox } from "./actions/forms/init_new_case_form_shadowbox";
import { initLabFixForm } from "./actions/forms/init_lab_fix_form";
import { initSubmitRightDirectionForm } from "./actions/forms/init_submit_right_direction_form";
import { initSubmitRightPhoneForm } from "./actions/forms/init_submit_right_phone_form.js";
import { initCleave } from "./actions/forms/inputs/init_cleave";
import { initCptSelect } from "./actions/forms/inputs/init_cpt_select";
import { initCustomFileInputs } from "./actions/forms/inputs/init_custom_file_input";
import { initDatepicker } from "./actions/forms/inputs/init_datepicker";
import { initDenialReason } from "./actions/forms/inputs/init_denial_reason_dropdown";
import { initIcdSelect } from "./actions/forms/inputs/init_icd_select";
import { initPolicySelect } from "./actions/forms/inputs/init_policy_select";
import { initSelectize } from "./actions/forms/inputs/init_selectize";
import { initStatusSelect } from "./actions/forms/inputs/init_status_select";
import { initSubstatusSelect } from "./actions/forms/inputs/init_substatus_select";
import { initReopenCase } from "./actions/forms/inputs/init_reopen_case";
import { initClinicOpsSupportNeeded } from "./actions/forms/inputs/init_clinic_ops_support_needed";
import { initDenialReasonModal } from "./actions/init_deny_case_modal";
import { initPayorModal } from "./actions/init_payor_modal.js";

// Grid Actions
import { initClinicIndex } from "./clinic/init_clinic_index";

import { initFaxesGrid } from "./actions/grids/init_faxes_grid";
import { initFillableWorkflowDocumentsGrid } from "./actions/grids/init_fillable_workflow_documents_grid";
import { initOperationsGrid } from "./actions/grids/init_operations_grid";
import { initPartialClinicMatchesGrid } from "./actions/grids/init_partial_clinic_matches_grid";
import { initUnmappedClinicMatchesGrid } from "./actions/grids/init_unmapped_clinic_matches_grid";

// Util Actions
import { initPageSizeSelector } from "./actions/utils/init_page_size_selector";
import { initPasswordStrength } from "./actions/utils/init_password_strength";
import { initRepeater } from "./actions/utils/init_repeater";
import { initSentry } from "./actions/utils/init_sentry";
import { initSessionCheck } from "./actions/utils/init_session_check";

import { initCannedFaxResponseButton } from "./actions/init_canned_fax_response_button";
import { initChangePayor } from "./actions/init_change_payor";
import { initDashboard } from "./actions/init_dashboard";
import { initFax } from "./actions/init_fax";
import { initClinicShow } from "./clinic/init_clinic_show";
import { initClinicMappingModal } from "./actions/init_clinic_mapping_modal.js";
import { initAssignmentModal } from "./actions/init_assignment_modal";
import { initFillableDocumentEditor } from "./actions/init_fillable_document_editor";
import { initRequestSplitter } from "./actions/init_request_splitter";
import { initSupplementalDocument } from "./actions/init_supplemental_document";
import { initWebhookOptions } from "./actions/init_webhook_options";
import { initClipboard } from "./actions/utils/init_clipboard";
import { initSeeqerRefresh } from "./actions/init_seeqer_refresh";
import { initPriceComparison } from "./actions/init_price_comparison";
import { initSeerezDetails } from "./actions/init_seerez_details";
import { initSeerezCaseHistory } from "./actions/init_seerez_case_history";
import { initSeerezOverview } from "./actions/init_seerez_overview";

import { initToggleDetailsText } from "./actions/init_toggle_details_text";
import { initToggleMemberIdBenefitsInformation } from "./actions/init_toggle_member_id_benefits_information";
import { initTooltips } from "./actions/init_tooltips.js";
import { initTrackableElement } from "./actions/init_trackable_element";

import { initClininicalGlossaries } from "./actions/init_clinical_glossaries";
import {
  initAcceptInvitationValidation,
  initPasswordChangeValidation,
  initPasswordOptionalChangeValidation,
  initSignupValidation,
} from "./actions/utils/init_signup_validation";
import { initRecordSubmissionAttemptModal } from "./actions/workflow/init_record_submission_attempt_modal";
import { initBenefitsInvestigationWorkflow } from "./actions/workflow/init_benefits_investigation_workflow";
import { initDigitalFollowup } from "./actions/workflow/init_digital_followup";
import { initMissingInfoFollowup } from "./actions/workflow/init_missinginfo_followup";
import { initMissingInfoCategoriesPicker } from "./actions/workflow/init_missing_info_categories_picker.js";
import { initPaperFollowup } from "./actions/workflow/init_paper_followup";
import { initPriorAuthWorkflow } from "./actions/workflow/init_prior_auth_workflow";
import { initWorkflowNextButtons } from "./actions/workflow/init_workflow_next_button";
import { initMyCases } from "./my_cases/init_my_cases";
import { initAssignmentList, initAssignmentSearch, initReassignmentSearch } from "./my_cases/init_assignment_list";
import { initAdminPayorConfigGrid } from "./actions/admin/init_admin_payor_config_grid";
import { initCaseAttachmentsPreview } from "./actions/init_case_attachments_preview";
import { initIcons } from "./actions/init_icons";
import { initApprovedCases } from "./cases_grid/init_approved_cases";
import { initMissingInfoCases } from "./cases_grid/init_missing_info_cases";
import { initSidebar } from "./actions/init_sidebar";
import { initCaseView } from "./actions/init_case_view";
import { initHotkeys } from "./actions/init_hotkeys";
import { initActiveClinicSelector } from "./actions/init_active_clinic_selector";
import { initFillableInsuranceDocumentsForm } from "./actions/forms/init_fillable_insurance_documents_form.js";
import { initFwdForm } from "./actions/init_fwd_form.js";
import { initCaseSearch } from "./actions/init_case_search.js";
import { initDashboardExportModal } from "./actions/init_dashboard_export_modal.js";
import { initSubmitRightPaperResponse } from "./actions/forms/init_submit_right_paper_response.js";
import { initPayorSelect } from "./actions/forms/inputs/init_payor_select.js";
import { initPrecompileValidatorsDependencies } from "./actions/init_precompile_validators_dependencies.js";

// register actions
app.registerGlobalAction(initIcons);
app.registerGlobalAction(initSidebar);
app.registerGlobalAction(initRepeater, { priority: 99 });
app.registerGlobalAction(initSentry);
app.registerGlobalAction(initClipboard);
app.registerGlobalAction(initCleave);
app.registerGlobalAction(initSelectize);
app.registerGlobalAction(initDatepicker);
app.registerGlobalAction(initCustomFileInputs);
app.registerGlobalAction(initPageSizeSelector);
app.registerGlobalAction(initStatusSelect);
app.registerGlobalAction(initCptSelect);
app.registerGlobalAction(initPayorSelect);
app.registerGlobalAction(initIcdSelect);
app.registerGlobalAction(initSubstatusSelect);
app.registerGlobalAction(initReopenCase);
app.registerGlobalAction(initClinicOpsSupportNeeded);
app.registerGlobalAction(initSubmitRightDirectionForm);
app.registerGlobalAction(initSubmitRightPhoneForm);
app.registerGlobalAction(initCaseSearch);
app.registerGlobalAction(initPolicySelect);
app.registerGlobalAction(initAdminLabSettings);
app.registerGlobalAction(initFax);
app.registerGlobalAction(initClinicMappingModal);
app.registerGlobalAction(initAssignmentModal);
app.registerGlobalAction(initRecordSubmissionAttemptModal);
app.registerGlobalAction(initPayorModal);
app.registerGlobalAction(initFlashMessage);
app.registerGlobalAction(initCaseAttachmentsPreview);
app.registerGlobalAction(initTooltips);
app.registerGlobalAction(initCaseView);
app.registerGlobalAction(initTrackableElement);
app.registerGlobalAction(initHotkeys);
app.registerGlobalAction(initDashboardExportModal);
app.registerGlobalAction(initPrecompileValidatorsDependencies);

app.registerGlobalAction(initSessionCheck, {
  excludes: [
    "sessions",
    "devise-sessions",
    "devise-passwords",
    "registrations",
    "invitations",
    "two_factor_authentication",
    "devise-confirmations",
    "devise-unlocks",
    "invitations",
    "quarantine",
  ],
});

app.registerAction("admin-system-system_config", initSystemConfig);

app.registerAction("cases-lab_fix", initLabFixForm);

app.registerAction("request_drafts", initNewCase);
app.registerAction("request_drafts", initNewCaseShadowbox);
app.registerAction("operations-fillable_insurance_documents", initFillableDocumentEditor);

app.registerAction("operations-fillable_workflow_documents", initFillableDocumentEditor);

app.registerAction("operations-fillable_insurance_documents-index", initFillableWorkflowDocumentsGrid);
app.registerAction("operations-fillable_workflow_documents-index", initFillableWorkflowDocumentsGrid);

app.registerAction("dashboard", initDashboard);
app.registerAction("labs-settings", initWebhookOptions);
app.registerAction("admin-labs-edit", initAdminLab);
app.registerAction("admin-labs-new", initAdminLab);
app.registerAction("admin-webhook_configs-index", initWebhookOptions);
app.registerAction("admin-payors-grid", initAdminPayorConfigGrid);
app.registerAction("admin-auth_tokens-new", initNewAuthTokenForm);

app.registerAction("registrations-new", initPasswordStrength);
app.registerAction("registrations-new", initSignupValidation);

app.registerAction("registrations-edit", initPasswordStrength);
app.registerAction("registrations-edit", initPasswordChangeValidation);
app.registerAction("registrations-update", initPasswordStrength);
app.registerAction("registrations-update", initPasswordChangeValidation);

app.registerAction("accounts-edit", initPasswordStrength);
app.registerAction("accounts-edit", initPasswordOptionalChangeValidation);

app.registerAction("invitations-edit", initPasswordStrength);
app.registerAction("invitations-edit", initAcceptInvitationValidation);

app.registerAction("invitations-update", initPasswordStrength);
app.registerAction("invitations-update", initAcceptInvitationValidation);

app.registerAction("labs-settings", initLabSettings);
app.registerAction("lab_settings-index", initLabSettings);

app.registerAction("workflow-queue", initOperationsGrid);

app.registerAction("cases-show", initSeeqerRefresh);
app.registerAction("cases-show", initPriceComparison);
app.registerAction("cases-show", initSeerezDetails);
app.registerAction("cases-show", initSeerezCaseHistory);
app.registerAction("cases-show", initSeerezOverview);

app.registerAction("clinical_glossaries-index", initClininicalGlossaries);

app.registerAction("operations-cases-show", initRequestSplitter);

app.registerAction("operations-prior_authorize_requests-show", initChangePayor);

app.registerAction("operations-prior_authorize_requests-edit", initEditSafety);
app.registerAction("operations-prior_authorize_requests-edit", initDenialReason);

app.registerAction("workflow", initWorkflowNextButtons);

app.registerAction("workflow-prior_auth_workflow", initChangePayor);
app.registerAction("workflow-prior_auth_workflow", initEditSafety);
app.registerAction("workflow-prior_auth_workflow", initPriorAuthWorkflow);
app.registerAction("workflow-prior_auth_workflow", initPaperFollowup);
app.registerAction("workflow-prior_auth_workflow", initDigitalFollowup);
app.registerAction("workflow-prior_auth_workflow", initSubmitRightPaperResponse);
app.registerAction("workflow-prior_auth_workflow", initMissingInfoFollowup);
app.registerAction("workflow-prior_auth_workflow", initMissingInfoCategoriesPicker);
app.registerAction("workflow-prior_auth_workflow", initDenialReason);

app.registerAction("workflow-benefits_investigation_workflow", initEditSafety);
app.registerAction("workflow-benefits_investigation_workflow", initBenefitsInvestigationWorkflow);
app.registerAction("workflow-benefits_investigation_workflow", initCannedFaxResponseButton);

app.registerAction("admin-billing_rates-index", initBillingRatesGrid);
app.registerAction("admin-billing_rates-show", initBillingRateDeleteButton);

app.registerAction("admin-payors-clone_payor_policies", initClonePolicies);

app.registerAction("supplemental_documents-s3_index", initSupplementalDocument);

app.registerAction("operations-fax-index", initFaxesGrid);

app.registerAction("operations-clinic_mapping-partial_mapping_index", initPartialClinicMatchesGrid);
app.registerAction("operations-clinic_mapping-unmapped_mapping_index", initUnmappedClinicMatchesGrid);

app.registerAction("cases-my_cases", initMyCases);
app.registerAction("cases-assignment_list", initAssignmentList);
app.registerAction("cases-assign", initAssignmentSearch);
app.registerAction("cases-reassign", initReassignmentSearch);

app.registerAction("cases-show", initDenialReasonModal);

app.registerAction("cases-show", initToggleDetailsText);

app.registerAction("clinics-index", initClinicIndex);
app.registerAction("clinics-show", initClinicShow);

app.registerAction("workflow-benefits_investigation_workflow-show", initToggleMemberIdBenefitsInformation);

app.registerAction("cases-approved_cases", initApprovedCases);
app.registerAction("cases-missing_info_cases", initMissingInfoCases);

app.registerAction("quarantine-select_clinic", initActiveClinicSelector);

app.registerAction("operations-fillable_workflow_documents-new", initFwdForm);
app.registerAction("operations-fillable_workflow_documents-edit", initFwdForm);

app.registerAction("operations-fillable_insurance_documents-new", initFillableInsuranceDocumentsForm);
app.registerAction("operations-fillable_insurance_documents-edit", initFillableInsuranceDocumentsForm);

// determine namespace
if (document.body.matches("[class^=workflow]")) {
  document.body.classList.add("workflow");
}
const namespaces = [].concat(...document.body.classList);

// run app
app.run(namespaces);
