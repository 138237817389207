import _, { fromPairs, uniqBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { Col, FormText } from "reactstrap";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { FormRow } from "../components/forms/FormRow";
import { api } from "../util/api";
import { composeValidators, required } from "../util/validators";
import { getNpiData, translateNpiResult } from "./ProviderInfoFields";
import { StyledAsyncPaginate } from "../components/inputs/StyledSelect";
import { InputWrapper, serializeInput } from "../components/inputs/final_form/InputWrapper";

const mapProvidersToOptions = (providers) => _.map(providers, (p) => ({ label: `${p.name} (${p.npi})`, value: p.npi }));

const setPartialProviderData = (selectedProvider, form) => {
  const providerName = selectedProvider.name.split(" ");
  const providerFirstName = providerName[0];
  const providerLastName = providerName.slice(1).join(" ");

  const partialInfo = { basic: { first_name: providerFirstName, last_name: providerLastName } };
  const partialResult = translateNpiResult(partialInfo);

  form.mutators.setNpiDataMutator(partialResult);
};

const UNEXPECTED_ERROR_MESSAGE = "An unexpected error occurred while searching for Provider data.";

export const ClinicProviderInfoFields = ({ clinicProvidersUrl, isClinicUser, isRequestingProviderTypePharmacy, form, values }) => {
  const [error, setError] = useState(null);
  const [npiError, setNpiError] = useState(null);
  const [providerOptions, setProviderOptions] = useState([]);
  const [loadingNpiData, setLoadingNpiData] = useState(false);
  const [loadingProviderLookup, setLoadingProviderLookup] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");

  const handleNpiChange = async (selected) => {
    if (selected.value && selected.value.length >= 9) {
      const _selectedProvider = providerOptions.find((p) => p.npi === selected.value);
      setLoadingNpiData(true);

      // set provider name in the form in case we can't load npi data
      setPartialProviderData(_selectedProvider, form);
      setSelectedProvider([selected.value]);
      form.change("providerInfo.physicianNPI", selected.value);
      await getNpiData(_selectedProvider.npi, form, setNpiError, setLoadingNpiData, null, UNEXPECTED_ERROR_MESSAGE);
    }
  };

  const loadOptions = async (search, _loadedOptions, { page }) => {
    try {
      const params = { distinct: true, kept: true, page, per: 10, search };

      const {
        data: { resources, meta },
      } = await api.get(clinicProvidersUrl, { params });

      const filteredProviders = resources.filter((provider) => provider.npi);
      const hasMore = Boolean(meta?.page && meta?.total_pages && meta.page < meta.total_pages);

      const options = mapProvidersToOptions(_.uniqBy(filteredProviders, "id"));

      setProviderOptions(_.uniqBy(filteredProviders, "id"));
      setLoadingProviderLookup(false);

      return {
        options,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      setLoadingProviderLookup(false);
      console.error(err.message);
      setError(err);

      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      };
    }
  };

  return (
    <>
      <h5 className="mb-4">Provider & Practice Info</h5>

      <DetailedErrorAlert error={error || npiError} />

      <FormRow>
        <Col>
          <Field
            render={AsyncProvidersSelect}
            data-testid="physicianNPI"
            required
            loadOptions={loadOptions}
            disabled={loadingProviderLookup || loadingNpiData}
            validate={required}
            label="Provider Name"
            value={selectedProvider}
            defaultOptions={true}
            placeholder="Choose a Provider..."
            name="providerInfo.physicianNPI"
            additional={{ page: 1 }}
            onChange={handleNpiChange}
            cacheOptions
            format={(value) => (value ? value.value : "")}
            getOptionValue={(option) => option.value}
          />
        </Col>
      </FormRow>
    </>
  );
};

const AsyncProvidersSelect = ({ children, input, disabled, value, label, valid, meta, ...rest }) => {
  const handleChange = useCallback(
    (selected) => {
      input.onChange(selected.value);
    },
    [input]
  );

  return (
    <InputWrapper label={label} required={rest.required}>
      <StyledAsyncPaginate
        onChange={handleChange}
        includeBlank
        valueKey="value"
        value={value}
        isDisabled={disabled}
        valid={valid}
        {...input}
        {...rest}
      />

      {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
      {children}
    </InputWrapper>
  );
};
