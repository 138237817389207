import React from "react";
import { uniqByShape } from "../../../util/helpers";
import { formatPercent } from "../../../util/formatters";

const normalizePolicies = (policies) => {
  return uniqByShape(policies.map((policy) => ({ name: policy[0], url: policy[1] })));
};

const cptCodesMultiplier = (data) => {
  const codeCounts = {};
  data.forEach((item) => {
    codeCounts[item.code] = (codeCounts[item.code] || 0) + 1;
  });

  const cptMultipliers = [];
  const seenCodes = new Set();

  data.forEach((item) => {
    if (!seenCodes.has(item.code)) {
      seenCodes.add(item.code);
      if (codeCounts[item.code] > 1) {
        item.code = `${item.code}x${codeCounts[item.code]}`;
      }
      cptMultipliers.push(item);
    }
  });

  return cptMultipliers;
};

const policyRows = ({ policyInfo, biState, verifiedCptCodes }) => {
  const cptCodes = cptCodesMultiplier(verifiedCptCodes);
  if (biState === "error") {
    return cptCodes?.map((cptCode) => {
      return {
        code: cptCode.code,
        pa_required: cptCode.lab_specific_pa_required || cptCode.cpt_code_pa_required,
        group_id_pa_not_required: cptCode.group_id_pa_not_required,
        pa_approval_percentage: cptCode.pa_approval_percentage || null,
      };
    });
  } else {
    if (policyInfo) {
      const cptCoverages = {};

      cptCodes.forEach((cptCode) => {
        const baseCode = cptCode.code.split("x")[0];
        if (policyInfo?.cpt_coverage && policyInfo?.cpt_coverage[baseCode]) {
          cptCoverages[cptCode.code] = policyInfo?.cpt_coverage[baseCode];
        }
      });

      return Object.keys(cptCoverages || {}).map((cptCode) => {
        const element = cptCoverages[cptCode];
        const verifiedCptCode = verifiedCptCodes.find((cpt) => cpt.code === cptCode);

        return {
          code: cptCode,
          pa_require_status: element.pa_require_status,
          covered: element?.covered,
          policies: normalizePolicies(element?.policies),
          pa_required: element?.pa_required,
          gc_requirement: element?.gc_requirement || false,
          group_id_pa_not_required: cptCode.group_id_pa_not_required || verifiedCptCode?.group_id_pa_not_required,
          pa_approval_percentage: cptCode.pa_approval_percentage || verifiedCptCode?.pa_approval_percentage || null,
          predictive_coverage_analysis: element?.predictive_coverage_analysis,
        };
      });
    }
  }

  return [{ code: null, policies: null, paRequired: null }];
};

const PolicyRow = ({ key, cptCoverage, paRequirementsConfigured, coverageAnalysis, trizettoEnabled, showPredictiveCoverage }) => {
  return (
    <tr key={key} className="js-table-row">
      <td className="cell-data">
        {cptCoverage.policies?.map((policy, _policyIndex) => (
          <>
            <span>
              &bull;{" "}
              <a target="_blank" rel="noopener noreferrer" href={policy.url}>
                {trizettoEnabled ? "View Link" : policy.name}
              </a>
            </span>
            <br />
          </>
        ))}
      </td>
      <td className="cell-data">
        <h5 className="fw-bold text-start text-dark">{cptCoverage.code}</h5>
      </td>
      <td className="cell-data">
        <h5 className="fw-bold text-start text-dark">{cptCoverage.pa_require_status}</h5>
      </td>
      {showPredictiveCoverage && (
        <>
          <td className="cell-data">
            <h5 className="fw-normal text-start text-dark">
              {trizettoEnabled && !coverageAnalysis ? (
                <strong>Unavailable</strong>
              ) : trizettoEnabled ? (
                (cptCoverage.pa_required === null || cptCoverage.pa_required === "No") && predictiveCoverageMessageTrizetto(cptCoverage)
              ) : cptCoverage.pa_approval_percentage === null ? (
                <strong>Unavailable</strong>
              ) : (
                predictiveCoverageMessage(cptCoverage)
              )}
            </h5>
          </td>
          <td className="cell-data">
            <h5 className="fw-bold text-start text-dark">
              {trizettoEnabled && (cptCoverage.pa_required === null || paRequirementsConfigured ? "Covered" : "Unavailable")}
            </h5>
          </td>
        </>
      )}
    </tr>
  );
};

const paRequiredMapping = (cptCoverage, paRequirementsConfigured) => {
  if (cptCoverage.pa_required === null) {
    return "Unknown";
  } else if (cptCoverage.group_id_pa_not_required) {
    // PA not required by the Group ID Specific PA Requirement setting
    return "No";
  } else if (paRequirementsConfigured) {
    // default PA requirement logic
    return cptCoverage.pa_required ? "Yes" : "No";
  } else {
    return "Unavailable";
  }
};

const predictiveCoverageMessage = (cptCoverage) => {
  return (
    <>
      <strong>{cptCoverage.pa_approval_percentage}%</strong> likelihood of PA Approval
    </>
  );
};

const predictiveCoverageMessageTrizetto = (cptCoverage) => {
  return (
    <>
      <strong>{formatPercent(cptCoverage.predictive_coverage_analysis.paid)}</strong> likelihood of Coverage
    </>
  );
};

export const PolicyInfo = ({
  policyInfo,
  paRequirementsConfigured,
  biState,
  verifiedCptCodes,
  trizettoEnabled,
  showPaApprovalPercentage,
  isPharmacyOwnedByClinic,
}) => {
  const coverage = policyRows({
    policyInfo,
    biState,
    verifiedCptCodes,
  });

  const showPredictiveCoverage = trizettoEnabled || showPaApprovalPercentage;
  const predictiveTooltipMessage = `careviso’s analytics model
predicts the likelihood of health plan coverage 
or prior authorization approval, built off 
a complex machine learning platform.`;

  return (
    <table id="policy-section" className="table table-striped mt-2 rounded">
      <thead>
        <tr>
          <th title="Medical Policies attached.">Medical Policy</th>
          <th title="CPT Codes.">{isPharmacyOwnedByClinic ? "Procedure Code" : "CPT Code"}</th>
          <th title="Requires Prior Authorization?">PA Required</th>
          {showPredictiveCoverage && <th title={predictiveTooltipMessage}>Predictive Coverage Analysis</th>}
        </tr>
      </thead>

      <tbody>
        {policyInfo
          ? coverage.map((cptCoverage, cptCoverageIndex, _code) => (
              <PolicyRow
                key={cptCoverageIndex}
                cptCoverage={cptCoverage}
                paRequirementsConfigured={paRequirementsConfigured}
                coverageAnalysis={false}
                trizettoEnabled={trizettoEnabled}
                showPredictiveCoverage={showPredictiveCoverage}
              />
            ))
          : coverage.map((cptCoverage, index) => (
              <tr key={index} className="js-table-row">
                <td className="cell-data"></td>

                <td className="cell-data">
                  <h5 className="fw-bold text-start text-dark">{cptCoverage.code}</h5>
                </td>
                <td className="cell-data">
                  <h5 className="fw-bold text-start text-dark">{paRequiredMapping(cptCoverage, paRequirementsConfigured)}</h5>
                </td>
                <td className="cell-data">
                  <h5 className="fw-normal text-start text-dark">
                    {showPredictiveCoverage &&
                      !trizettoEnabled &&
                      (cptCoverage.pa_approval_percentage === null ? <strong>Unavailable</strong> : predictiveCoverageMessage(cptCoverage))}
                  </h5>
                </td>
              </tr>
            ))}
      </tbody>
    </table>
  );
};
