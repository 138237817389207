import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";

export const MembershipsActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const path = row.original.type == "lab" ? "lab_memberships" : "clinic_memberships";

  return (
    <AdministrateResourceDeleteButton
      deleteUrl={`/admin/${path}/${row.original.id}/`}
      onSuccess={() => {
        refresh();
        setMessage(`Removed Membership to ${row.original.name}`);
      }}
      onError={(msg) => setError(msg)}
      label="Remove Membership"
    />
  );
};
