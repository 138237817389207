import React from "react";
import { ActionAlert } from "../../../components/ActionAlert";
import { LoadingButton } from "../../../components/LoadingButton";
import { WizardContext } from "../WizardContext";

export const ContactSupervisorScreen = () => (
  <WizardContext.Consumer>
    {({ exitWorkflow, loading }) => (
      <ActionAlert
        color="info"
        actions={
          <LoadingButton color="info" loading={loading} onClick={exitWorkflow}>
            Ok
          </LoadingButton>
        }
      >
        <strong>Contact your Supervisor for Next Steps</strong>
        <br />
        <small>
          It is unusual to not require followup. Please check with your supervisor to determine what should be done with this case.
        </small>
      </ActionAlert>
    )}
  </WizardContext.Consumer>
);
