import React from "react";
import { YesNoToggle } from "../../../../components/wizards/YesNoToggle";
import { formatDate } from "../../../../util/formatters";
import { WizardContext } from "../../WizardContext";
import { FollowupAlertScreen } from "../FollowupAlertScreen";

export const MedicalReviewPeerToPeerRequiredBranch = (props) => (
  <WizardContext.Consumer>
    {({ state, updateStatus, createFollowupTask, loading }) => (
      <FollowupAlertScreen
        days={2}
        loading={loading}
        onClick={async () => {
          await updateStatus("medicalreview", "pending_peer_to_peer", false);
          await createFollowupTask(2, "Follow up on Digital Submission - Peer to Peer");
        }}
      >
        Case will be moved to <strong>Peer to Peer</strong>
      </FollowupAlertScreen>
    )}
  </WizardContext.Consumer>
);
