import React from "react";
import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../../components/AdministrateTable";
import { useDestroyPermission } from "../../../utils";
import { EditUiPathBotAutomationModal } from "./EditUiPathBotAutomationModal";
import { History } from "../../../tools/sandbox/release/History";

export const UiPathBotActionCell = ({ row }) => {
  const [canDelete] = useDestroyPermission("UiPathPortalConfiguration", row.original.id, row.original._permissions);

  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = (msg) => {
    setMessage(`Updated ${row.original.dashboardDisplayName}`);
    refresh();
  };

  return (
    <>
      <EditUiPathBotAutomationModal color="link" onEdit={handleChange} automation={row.original} />
      {canDelete && (
        <AdministrateResourceDeleteButton
          label="Delete"
          deleteUrl={`/admin/ui_path_portal_configurations/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted ${row.original.uiPathFolderName}, ${row.original.uiPathReleaseName},`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
      <History entityName={"UiPathPortalConfiguration"} subjectId={row.original.id} />
    </>
  );
};
