import React from "react";
import { TokenCell } from "./TokenCell";

// Cells for a tokenized Case ID that also links to the case in question

export const OpsCaseLinkCell = ({ row, value }) =>
  value ? (
    <a href={`/operations/cases/${value}`} target="_blank" rel="noopener noreferrer">
      <TokenCell row={row} value={value} />
    </a>
  ) : null;

export const CaseLinkCell = ({ row, value }) =>
  value ? (
    <a href={`/cases/${value}`} target="_blank" rel="noopener noreferrer">
      <TokenCell row={row} value={value} />
    </a>
  ) : null;
