import React from "react";
import { TrinaryTree } from "../../../../components/wizards/TrinaryTree";
import { YesNoToggle } from "../../../../components/wizards/YesNoToggle";
import { WizardContext } from "../../WizardContext";
import { PayorHasReachedDeterminationBranch } from "./PayorHasReachedDeterminationBranch";
import { PayorHasNotReachedDeterminationBranch } from "./PayorHasNotReachedDeterminationBranch";

export const HasReceivedSubmissionBranch = () => (
  <WizardContext.Consumer>
    {({ state, dispatch }) => (
      <>
        <YesNoToggle
          label="Has Payor reached a determination?"
          id="payorHasReachedDetermination"
          value={state.answers.payorHasReachedDetermination}
          onChange={(v) => {
            dispatch({
              type: "setAnswer",
              key: "payorHasReachedDetermination",
              value: v,
              doTransition: false,
            });
          }}
        />
        <TrinaryTree
          branch={state.answers.payorHasReachedDetermination}
          trueBranch={<PayorHasReachedDeterminationBranch />}
          falseBranch={<PayorHasNotReachedDeterminationBranch />}
        />
      </>
    )}
  </WizardContext.Consumer>
);
