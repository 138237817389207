import classnames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Card } from "reactstrap";
import { LoadingIcon } from "../components/LoadingIcon";
import { StatCard } from "../components/StatCard";
import { formatDisplayNumber, formatPercent } from "../util/formatters";
import { DonutChart } from "../components/DonutChart";

const InNetworkDonutChart = ({ inNetworkFraction, className }) => {
  const noData = inNetworkFraction === null || inNetworkFraction === undefined;
  const inNetworkVal = noData ? 0.33 : inNetworkFraction;
  const outNetworkVal = noData ? 0.67 : 1 - inNetworkVal;

  const data = [
    { x: "In Network", y: inNetworkVal },
    { x: "Out of Network", y: outNetworkVal },
  ];

  const legend = data.map((d) => {
    const value = noData ? "N/A%" : formatPercent(d.y);
    return { name: `${value} ${d.x}` };
  });

  const color = noData ? "secondary" : "primary";

  return (
    <div className={className}>
      <DonutChart color={color} data={data} legend={legend} height={100} width={300} padding={{ right: 150 }} className="w-50" />
    </div>
  );
};

const PAPageStats = ({ paStats }) => (
  <Col>
    <Card className="p-3">
      <h5 className="m-2">Prior Authorization</h5>

      <div className="d-flex flex-row w-100">
        <StatCard icon="clipboard-list" value={formatDisplayNumber(paStats.total_cases)} label="Total Cases" variant="info" />
        <StatCard icon="check-circle" value={formatPercent(paStats.approval_rate)} label="Approval Rate" variant="success" />
        <StatCard icon="X-circle" value={formatPercent(paStats.denial_rate)} variant="secondary" label="Denial Rate" />
        <StatCard icon="help-circle" value={formatPercent(paStats.mi_rate)} variant="warning" label="Missing Info" />
      </div>
    </Card>
  </Col>
);

const BIPageStats = ({ biStats }) => (
  <Col>
    <Card className="p-3">
      <h5 className="m-2">seeQer (Benefits Investigation)</h5>
      <div className="d-flex flex-row w-100">
        <StatCard icon="clipboard-list" value={formatDisplayNumber(biStats.total_cases)} label="Total Cases" variant="info" />
        <InNetworkDonutChart className="w-25" inNetworkFraction={biStats.in_network_rate} />
        <StatCard icon="copy" value={formatPercent(biStats.pa_required_rate)} label="PA Required" variant="secondary" />
        <StatCard icon="trending-up" value={formatPercent(biStats.success_rate)} label="seeQer Success Rate" variant="success" />
      </div>
    </Card>
  </Col>
);

const OverviewStats = ({ priorAuthEnabled, benefitsInvestigationEnabled, paStats, biStats }) => {
  const biValue = benefitsInvestigationEnabled ? formatPercent(biStats.success_rate) : "N/A";
  const paValue = priorAuthEnabled ? formatPercent(paStats.approval_rate) : "N/A";

  return (
    <>
      <Col>
        <Card className="p-3">
          <div className="d-flex flex-row w-100" style={{ height: "calc(0.625rem + 0.625rem + 0.625rem + 0.625rem + 36px + 21px)" }}>
            <StatCard
              icon="clipboard-list"
              value={formatDisplayNumber(paStats.total_cases + biStats.total_cases)}
              label="Total Cases"
              variant="info"
            />
            <InNetworkDonutChart className="w-50" inNetworkFraction={biStats.in_network_rate} />
          </div>
        </Card>
      </Col>
      <Col>
        <Card className="p-3">
          <div className="d-flex flex-row w-100">
            <StatCard
              icon="trending-up"
              value={biValue}
              label="seeQer Success Rate"
              variant={benefitsInvestigationEnabled ? "success" : "secondary"}
            />
            {priorAuthEnabled && (
              <StatCard
                icon="check-circle"
                value={paValue}
                label="Prior Auth Approval Rate"
                variant={priorAuthEnabled ? "success" : "secondary"}
              />
            )}
          </div>
        </Card>
      </Col>
    </>
  );
};

export const StatsRow = ({
  startDate,
  endDate,
  priorAuthEnabled = true,
  benefitsInvestigationEnabled = true,
  currentFocus,
  biStats,
  paStats,
  loading,
}) => {
  let StatComponent = OverviewStats;

  if (currentFocus === "type_benefits_investigation") {
    StatComponent = BIPageStats;
  } else if (currentFocus === "type_prior_auth") {
    StatComponent = PAPageStats;
  }

  return loading ? (
    <span className="text-muted">
      <LoadingIcon className="me-2" />
      Loading Stats
    </span>
  ) : (
    <Row className="mb-3">
      <StatComponent
        priorAuthEnabled={priorAuthEnabled}
        benefitsInvestigationEnabled={benefitsInvestigationEnabled}
        paStats={paStats}
        biStats={biStats}
      />
    </Row>
  );
};
