import { useEffect, useMemo, useRef, useState } from "react";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { EnumFilter } from "../../../components/inputs/EnumFilter";
import { Toolbar } from "../../../components/Toolbar";
import { api } from "../../../util/api";
import { deepCompact, snakifyKeys } from "../../../util/helpers";
import { encodeFilterParams } from "../../../util/table";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { DESTROY, UPDATE, useCreatePermission } from "../../utils";
import { AddReportDeliveryModal } from "../components/AddReportDeliveryModal";
import { AutoDeliveryEnabledCell } from "../components/AutoDeliveryEnabledCell";
import { ImportLabReportDeliveriesModal } from "../components/ImportLabReportDeliveriesModal";
import { LabsReportDeliveryActionCell } from "../components/LabsReportDeliveryActionCell";

const useColumns = (reportTypeOptions, deliveryMethodOptions, labSettingId) =>
  useMemo(
    () => [
      {
        id: "delivery_method",
        accessor: "deliveryMethod",
        Header: "Delivery Method",
        Filter: (props) => <EnumFilter {...props} options={deliveryMethodOptions} />,
      },
      {
        id: "report_type",
        accessor: "reportType",
        Header: "Report Type",
        Filter: (props) => <EnumFilter {...props} options={reportTypeOptions} />,
      },
      {
        id: "target",
        accessor: "target",
        Header: "Target",
      },
      {
        id: "auto_delivery_enabled",
        accessor: "autoDeliveryEnabled",
        Header: "Auto Delivery Enabled",
        Cell: AutoDeliveryEnabledCell,
        Filter: (props) => <BoolFilter {...props} yesLabel="Enabled" noLabel="Not Enabled" />,
      },
      {
        id: "actions",
        Header: "Actions",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => <LabsReportDeliveryActionCell labSettingId={labSettingId} {...row} />,
      },
    ],
    [reportTypeOptions, deliveryMethodOptions, labSettingId]
  );

const getReportTypeOptions = async (setReportTypeOptions) => {
  try {
    const { data } = await api.get("/admin/report_delivery_configs/report_type_options.json");
    setReportTypeOptions(data.resources);
  } catch (err) {
    console.error(err);
  }
};

const getDeliveryMethodOptions = async (setDeliveryMethodOptions) => {
  try {
    const { data } = await api.get("/admin/report_delivery_configs/delivery_method_options.json");
    setDeliveryMethodOptions(data.resources);
  } catch (err) {
    console.error(err);
  }
};

export const LabsReportDeliveriesTab = ({ lab, labId }) => {
  const tableRef = useRef();
  const [reportTypeOptions, setReportTypeOptions] = useState([]);
  const [deliveryMethodOptions, setDeliveryMethodOptions] = useState([]);
  const labSettingId = lab.labSetting.id;
  const columns = useColumns(labId, reportTypeOptions, deliveryMethodOptions, labSettingId);
  const [tableFilters, setTableFilters] = useState({});
  const [canCreate] = useCreatePermission("ReportDeliveryConfig");

  const extraParams = useMemo(() => deepCompact({ labSetting: { id: labSettingId } }), [labSettingId]);

  useEffect(() => {
    getReportTypeOptions(setReportTypeOptions);
    getDeliveryMethodOptions(setDeliveryMethodOptions);
  }, []);

  const allFilters = useMemo(
    () => ({
      ...snakifyKeys(extraParams),
      ...encodeFilterParams(tableFilters),
    }),
    [extraParams, tableFilters]
  );

  const onImport = (message) => {
    const { refresh, setMessage } = tableRef.current;
    setMessage(message);
    refresh();
  };

  const onAddReportDelivery = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Successfully Added ${message}`);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/report_delivery_configs/export"
            filters={allFilters}
            ref={tableRef}
          />

          {canCreate && (
            <>
              <ImportLabReportDeliveriesModal outline className="me-2" onImport={onImport} labId={labId} />
              <AddReportDeliveryModal lab={lab} color="primary" onAdd={onAddReportDelivery} labSettingId={labSettingId} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/report_delivery_configs.json"}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
        resourceName={"report_delivery_config"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
