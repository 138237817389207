import { Container, Row } from "reactstrap";
import { Toolbar } from "../../components/Toolbar";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { AdministrateTable } from "../components/AdministrateTable";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../utils";
import { useCallback } from "react";
import { CountCell } from "../../components/grid/cells/CountCell";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Routes } from "../Routes";
import { BoolCell } from "../../components/grid/cells/BoolCell";
import { DateCell } from "../../components/grid/cells/DateCell";
import { humanizeString } from "../../util/formatters";
import { BoolFilter } from "../../components/inputs/BoolFilter";
import { DateFilter } from "../../components/inputs/DateFilter";
import { AdministrateEnumFilter } from "../components/AdministrateEnumFilter";
import { AuthTokenActionCell } from "./components/AuthTokenActionCell";
import { CreateAuthTokenModal } from "./components/CreateAuthTokenModal";

const AccountCell = ({ account }) => (
  <>
    {account ? (
      <Link target="_blank" to={Routes.user.url(account?.id)}>
        <span className="token">{account?.email || "None"}</span>
      </Link>
    ) : (
      <span className="token">No User Attached</span>
    )}
  </>
);

const useColumns = makeColumns(() => [
  {
    id: "description",
    accessor: "description",
    Header: "Description",
  },
  {
    id: "authable_labs",
    accessor: "authableLabs",
    Header: "Authable Labs",
    Cell: ({ value }) => <CountCell count={value?.length} label="Labs" />,
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/labs.json" isMulti valueKey="id" labelKey="lab_name" searchParam="lab_name" />
    ),
  },
  {
    id: "authable_clinics",
    accessor: "authableClinics",
    Header: "Authable Clinics",
    Cell: ({ value }) => <CountCell count={value?.length} label="Clinics" />,
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/clinics.json"
        isMulti
        valueKey="id"
        labelKey="clinic_name"
        searchParam="clinic_name"
      />
    ),
  },
  {
    id: "account",
    accessor: "account",
    Header: "Account",
    Cell: ({ value }) => <AccountCell account={value} />,
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/accounts.json" valueKey="id" labelKey="email" searchParam="email" />
    ),
  },
  {
    id: "token_type",
    accessor: (value) => humanizeString(value.tokenType ?? "None"),
    Header: "Token Type",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/enums/auth_token_types_options.json" valueKey="id" labelKey="label" />
    ),
  },
  {
    id: "token",
    accessor: "token",
    Header: "Token",
  },
  {
    id: "last_used_at",
    accessor: "lastUsedAt",
    Header: "Last Used At",
    Cell: DateCell,
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    id: "expires_at",
    accessor: "expiresAt",
    Header: "Expires At",
    Cell: DateCell,
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    id: "allow_basic",
    accessor: "allowBasic",
    Header: "Basic",
    Cell: BoolCell,
    Filter: BoolFilter,
  },
  {
    id: "allow_signature_v1",
    accessor: "allowSignatureV1",
    Header: "Signature V1",
    Cell: BoolCell,
    Filter: BoolFilter,
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: AuthTokenActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const AuthTokenIndexPage = () => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const columns = useColumns();
  const [canCreate] = useCreatePermission("AuthToken");

  const onAdd = useCallback(
    (message, errorDetails = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, errorDetails.length ? "warning" : "success", errorDetails);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Auth Tokens">
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Toolbar className="ml-auto">{canCreate && <CreateAuthTokenModal onAdd={onAdd} color="primary" />}</Toolbar>
        </Row>
      </Container>

      <AdministrateTable
        resourceUrl={"/admin/auth_tokens.json"}
        resourceName={"auth_token"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
