import { createRoot } from "react-dom/client";
import { WorkflowGrid } from "../../workflow_grid";
import { OPERATIONS_COLUMNS } from "../../workflow_grid/columns";

export const initOperationsGrid = () => {
  const workContainer = document.getElementById("work-queue");

  if (workContainer) {
    const root = createRoot(workContainer);

    const {
      defaultAssignee,
      assignmentUrl,
      bulkCloseUrl,
      bulkEscalateUrl,
      canEscalate,
      canAssign,
      assignableUsers,
      assignedUsersActiveCases,
      availableWorkflows,
      searchableSteps,
      searchableStatuses,
      searchableSubstatuses,
      initialFilterOptions,
    } = workContainer.dataset;

    const parsedCanAssign = canAssign === "true";
    const parsedAssignableUsers = assignableUsers ? JSON.parse(assignableUsers) : null;
    const parsedAssignedUsersWithActiveCases = assignedUsersActiveCases ? JSON.parse(assignedUsersActiveCases) : null;

    const workflowOptions = availableWorkflows ? JSON.parse(availableWorkflows) : null;
    const stepOptions = searchableSteps ? JSON.parse(searchableSteps) : null;
    const statusOptions = searchableStatuses ? JSON.parse(searchableStatuses) : null;
    const substatusOptions = searchableSubstatuses ? JSON.parse(searchableSubstatuses) : null;
    let { lab: initialLabOptions, payor: initialPayorOptions } = JSON.parse(initialFilterOptions);
    initialLabOptions = initialLabOptions || [];
    initialPayorOptions = initialPayorOptions || [];

    const filterOptions = {
      workflowOptions,
      stepOptions,
      statusOptions,
      substatusOptions,
      assignableUsers: parsedAssignableUsers,
      assignedUsersWithActiveCases: parsedAssignedUsersWithActiveCases,
      initialLabOptions,
      initialPayorOptions,
    };

    root.render(
      <WorkflowGrid
        apiUrl="/cases.json"
        canAssign={parsedCanAssign}
        bulkCloseUrl={bulkCloseUrl}
        bulkEscalateUrl={bulkEscalateUrl}
        canEscalate={canEscalate}
        assignmentUrl={assignmentUrl}
        defaultAssignee={defaultAssignee}
        filterOptions={filterOptions}
        columns={OPERATIONS_COLUMNS}
      />
    );
  }
};
