import axios from "axios";
import { uniq, isMatch } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Fade, Row } from "reactstrap";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { DateInput } from "../components/inputs/final_form/DateInput";
import { PayorPathSelector } from "../components/inputs/final_form/PayorPathSelector";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { StyledSelect as Select, StyledAsyncSelect as AsyncSelect } from "../components/inputs/StyledSelect";
import { Switch } from "../components/inputs/final_form/Switch";
import { SerializedPayorSelector } from "../components/inputs/final_form/PayorSelector";
import { SerializedClinicSelector } from "../components/inputs/final_form/ClinicSelector";
import { SerializedLabSelector } from "../components/inputs/final_form/LabSelector";
import { LoadingButton } from "../components/LoadingButton";
import { PageHeader } from "../components/PageHeader";
import { Toolbar } from "../components/Toolbar";
import { createStartDate } from "../dashboard";
import { api, redirectTo } from "../util/api";
import { isBlank } from "../util/helpers";
import { encodeTableState } from "../util/table";
import { AssignableCasesTable } from "./assignment/AssignableCasesTable";
import { AssigneeStats } from "./assignment/AssigneeStats";
import { useMountEffect } from "../util/hooks";
import { CornerDownRight } from "lucide-react";
import { LabSelector } from "../components/inputs/LabSelector";

const START_DATE = createStartDate({ days: 90 });

const serializeFilters = ({ labIds, payorIds, clinicIds, status, createdDate, taskDueDate, requiresUSAssignee, payorPaths }) => {
  const filters = [
    {
      id: "lab",
      value: labIds,
    },
    {
      id: "payor",
      value: payorIds,
    },
    {
      id: "clinic",
      value: clinicIds,
    },
    {
      id: "status",
      value: status,
    },
    {
      id: "created_at",
      value: {
        operation: "before",
        args: createdDate,
      },
    },
    {
      id: "due_date",
      value: {
        operation: "equals",
        args: taskDueDate,
      },
    },
    {
      id: "requires_us_assignee",
      value: requiresUSAssignee,
    },
    {
      id: "payor_paths",
      value: payorPaths,
    },
  ];

  return { filtered: filters.filter((f) => !isBlank(f?.value)) };
};

const OffshoreFilterField = ({ isOffshoreAccount, form }) => {
  // If the account we're assigning to is offshore,
  //  we want to lock this dropdown so that we only return cases that don't require US processing.
  // This effect handles updating the value when we change the assignee to/from an offshore user
  useEffect(() => {
    if (isOffshoreAccount) {
      form.change("requiresUSAssignee", false);
    }
  }, [form, isOffshoreAccount]);

  return (
    <Field
      label="USA Only"
      name="requiresUSAssignee"
      component={SelectInput}
      isClearable
      disabled={isOffshoreAccount}
      options={[
        {
          value: true,
          label: "USA Required",
        },
        {
          value: false,
          label: "USA Not Required",
        },
      ]}
    />
  );
};

function createOptions(arrayToMap) {
  return arrayToMap.map((el) => ({ label: el.path, value: el.path }));
}

const AssignmentSearchFormBody = ({
  handleSubmit,
  submitting,
  form,
  account,
  isOffshoreAccount,
  searchableStatuses,
  payorPathDefaults,
  noDefault = null,
  noPreload = null,
}) => {
  const activatedLabs = useMemo(() => account.activated_labs.map((x) => String(x)), [account]);

  useMountEffect(() => {
    if (payorPathDefaults) {
      form.change("payorPaths", payorPathDefaults);
    }

    if (!noPreload) {
      form.submit();
    }
  });

  const labChange = (labs) => {
    form.change("labIds", labs);
  };

  const payorChange = (payors) => {
    form.change("payorIds", payors);
  };

  const clinicChange = (clinics) => {
    form.change("clinicIds", clinics);
  };

  const handleFormReset = () => {
    if (noDefault) {
      form.restart({
        allLabs: true,
      });

      return;
    }

    form.reset();
  };

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <Row>
                <Col>
                  <Field
                    id="allLabs"
                    component={Switch}
                    label="All Labs"
                    name="allLabs"
                    type="checkbox"
                    defaultValue={noDefault ? true : null}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => {
                      return !!values.allLabs == true ? (
                        <Field
                          onChange={labChange}
                          render={SerializedLabSelector}
                          isMulti
                          label="Labs"
                          name="labIds"
                          keyLabel="lab_name"
                          key="unfilteredLabs"
                        />
                      ) : (
                        <Field
                          onChange={labChange}
                          render={SerializedLabSelector}
                          isMulti
                          label="Labs"
                          name="labIds"
                          key="filteredLabs"
                          filterList={activatedLabs}
                          initialValue={activatedLabs}
                        />
                      );
                    }}
                  </FormSpy>
                </Col>
                <Col>
                  <Field
                    onChange={payorChange}
                    render={SerializedPayorSelector}
                    isMulti
                    label="Payors"
                    name="payorIds"
                    valueSelector="id"
                    usOnly={isOffshoreAccount}
                  />
                </Col>
                <Col>
                  <Field onChange={clinicChange} render={SerializedClinicSelector} isMulti label="Clinics" name="clinicIds" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field component={SelectInput} options={searchableStatuses} isMulti label="Status" name="status" />
                </Col>
                <Col>
                  <OffshoreFilterField isOffshoreAccount={isOffshoreAccount} form={form} />
                </Col>
                <Col />
              </Row>
              <Row>
                <Col>
                  <Field component={DateInput} label="Created On or Before" name="createdDate" />
                </Col>
                <Col>
                  <Field component={DateInput} label="Task Due Date" name="taskDueDate" />
                </Col>
                <Col>
                  <Field component={PayorPathSelector} isMulti label="Payor Path" name="payorPaths" />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>

        <CardFooter>
          <LoadingButton size="sm" color="primary" loading={submitting} onClick={handleSubmit} className="me-2">
            Search Cases
          </LoadingButton>

          <Button color="danger" disabled={submitting} size="sm" outline onClick={handleFormReset}>
            Reset Search
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export const AssignmentSearchForm = ({ onFormSubmit, initialValues = {}, ...props }) => {
  return (
    <Form onSubmit={onFormSubmit}>
      {({ handleSubmit, submitting, form }) => {
        return <AssignmentSearchFormBody handleSubmit={handleSubmit} submitting={submitting} form={form} {...props} />;
      }}
    </Form>
  );
};

// pageLength is min(pageSize, results.length); i.e. the number of cases on the page
// forceRefresh is a function that this will call when it wants to force a refresh of the parent
//  because it's done something that needs to go back to the database (assigning a case)
const AssignmentToolbar = ({ account, pageLength, selectedCaseIds, forceRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const assignButtonDisabled = selectedCaseIds.length < 1;

  const handleAssign = async () => {
    setLoading(true);
    setError(null);

    try {
      await api.post("/cases/assign_user", { case_ids: selectedCaseIds, assignee: account.id });
      // Force a reload of the table.
      forceRefresh();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAssignAndEscalate = async () => {
    setLoading(true);
    setError(null);

    try {
      await Promise.all([
        api.post("/cases/assign_user", { case_ids: selectedCaseIds, assignee: account.id }),
        api.post("/cases/bulk_escalate", { case_ids: selectedCaseIds, escalate: true }),
      ]);

      // Force a reload of the table.
      forceRefresh();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const assignButtonText =
    selectedCaseIds.length > 0 ? (
      <>
        Assign{" "}
        <span className="ms-1 me-1">
          <strong>{selectedCaseIds.length}</strong>
        </span>{" "}
        Cases
      </>
    ) : (
      "Assign"
    );

  const assignAndEscalateButtonText =
    selectedCaseIds.length > 0 ? (
      <>
        Assign & Escalate{" "}
        <span className="ms-1 me-1">
          <strong>{selectedCaseIds.length}</strong>
        </span>{" "}
        Cases
      </>
    ) : (
      "Assign & Escalate"
    );

  return (
    <>
      <DetailedErrorAlert error={error} />

      <Toolbar>
        <div>
          Selected <strong>{selectedCaseIds.length}</strong> of <strong>{pageLength}</strong> Cases
        </div>

        <div className="ms-auto">
          <LoadingButton
            onClick={handleAssign}
            loading={loading}
            disabled={assignButtonDisabled || loading}
            color="primary"
            className="me-2"
          >
            {assignButtonText}
          </LoadingButton>

          <LoadingButton onClick={handleAssignAndEscalate} loading={loading} disabled={assignButtonDisabled || loading} color="warning">
            {assignAndEscalateButtonText}
          </LoadingButton>
        </div>
      </Toolbar>
    </>
  );
};

// The component at the top of the page that allows you to select
//  a new target to assign to without going back to the assignment list page
const AssignmentTargetSelect = ({ account, assignableAccounts }) => {
  const onChange = useCallback((option) => {
    redirectTo(`/cases/assign/${option.value}`);
  }, []);

  const optionForAccount = useMemo(() => ({ label: account.name, value: account.id }), [account.id, account.name]);

  return (
    <div style={{ width: 500 }}>
      <Select
        options={assignableAccounts.map((a) => ({ label: a.name, value: a.id }))}
        defaultValue={optionForAccount}
        onChange={onChange}
      />
    </div>
  );
};

export const AssignmentSearch = ({
  initialAccount,
  assignableAccounts,
  searchableStatuses,
  searchableClinics,
  searchableLabsWithPriorAuthEnabled,
  payorPathDefaults,
  showUserNotes,
}) => {
  const tableRef = useRef();

  const account = initialAccount;

  // This is the source of truth for filters applied to the table.
  // (Sort and pageSize and current page are stored in the table itself.)
  // filters is just the last data from the form
  const [filters, setFilters] = useState(null);

  // The number of pages
  const [pages, setPages] = useState(0);

  // The number of cases showing on the current page
  const [pageLength, setPageLength] = useState(0);

  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selectedCaseIds, setSelectedCaseIds] = useState([]);

  // Sets table state to loading, and then attempts to fetch the data based
  // on the data url
  const fetchData = useCallback(
    async (rawTableState) => {
      if (!filters) {
        return;
      }
      // show the loading overlay
      setLoading(true);

      try {
        let dataReq = await api.get("/cases.json", {
          params: {
            ...encodeTableState(rawTableState),
            ...serializeFilters(filters), // Filters from the form
            // always set searches
            case_type: "type_prior_auth",
            hide_done: true,
            hide_assigned: true,
            hide_held: true,
            start_date: START_DATE,
            sort_for_assign_and_reassign: true,
          },
        });

        setRows(dataReq.data.rows);
        setPages(dataReq.data.pages);
        setPageLength(dataReq.data.rows.length);
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.error(e);
        }
      } finally {
        setLoading(false);
      }
    },
    [filters]
  );

  const searchCases = (filters) => {
    setFilters((prevFilters) => {
      if (isMatch(prevFilters, filters)) {
        return;
      }

      return filters;
    });

    tableRef.current.resetPage();
  };

  const forceRefresh = () => {
    tableRef.current.updateTableData();
    tableRef.current.resetPage();
  };

  return (
    <>
      <PageHeader header="Assign Cases" />

      <div className="d-flex flex-row justify-start align-items-center gap-2 mb-3">
        <CornerDownRight color="#68b7d9" />

        <Button className="p-0" color="link" href={`/cases/reassign/${account.id}`}>
          Re-Assign Cases
        </Button>
      </div>

      <Card className={"mb-3"}>
        <CardBody>
          <Row>
            <Col>
              <label className="form-label">Assign to:</label>
              <AssignmentTargetSelect account={account} assignableAccounts={assignableAccounts} />
            </Col>
            <Col>
              <div className="d-flex flex-row align-items-end justify-content-end ">
                {account.is_offshore && <span className="badge badge-warning me-3">Offshore Account</span>}
                <div className="badge badge-info">{account.primary_group_name}</div>
              </div>

              {showUserNotes && (
                <div className="d-flex flex-column align-items-start justify-content-start flex-wrap">
                  <h6>User Notes:</h6>

                  <blockquote className="text-muted">{account.notes ?? "No notes..."}</blockquote>
                </div>
              )}
            </Col>
          </Row>

          <AssigneeStats account={account} />
        </CardBody>
      </Card>

      <div className="mb-3">
        <AssignmentSearchForm
          onFormSubmit={searchCases}
          searchableStatuses={searchableStatuses}
          account={account}
          searchableClinics={searchableClinics}
          isOffshoreAccount={account.is_offshore}
          payorPathDefaults={payorPathDefaults}
          searchableLabsWithPriorAuthEnabled={searchableLabsWithPriorAuthEnabled}
        />
      </div>

      <Card>
        <CardHeader className="mb-0">
          <AssignmentToolbar forceRefresh={forceRefresh} account={account} pageLength={pageLength} selectedCaseIds={selectedCaseIds} />
        </CardHeader>

        <CardBody className="p-0">
          <AssignableCasesTable
            ref={tableRef}
            showPagination={true}
            data={rows}
            pages={pages} // This is the total number of pages, comes back from the API
            loading={loading}
            onFetchData={fetchData}
            onSelectionChange={(selection) => setSelectedCaseIds(uniq(selection))}
          />
        </CardBody>
      </Card>
    </>
  );
};
