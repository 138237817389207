import { createRoot } from "react-dom/client";
import { RecordSubmissionAttemptButton } from "../../submission_attempts/RecordSubmissionAttemptModal";

export function initRecordSubmissionAttemptModal() {
  const container = document.getElementById("record-attempt-modal");

  if (container) {
    const root = createRoot(container);
    const {
      addRecord,
      cannedResponses,
      kasePublicId,
      portalId,
      readonlyStatus,
      remainingPortals,
      selectedStatus,
      selectedSubstatus,
      denialReasonCodeOptions,
      skipNoHcpEnrollment,
      submitPath,
      submissionType,
    } = container.dataset;

    root.render(
      <RecordSubmissionAttemptButton
        addRecord={addRecord}
        cannedResponses={JSON.parse(cannedResponses)}
        kasePublicId={kasePublicId}
        portalId={portalId}
        readonlyStatus={readonlyStatus}
        remainingPortals={JSON.parse(remainingPortals)}
        selectedStatus={selectedStatus}
        selectedSubstatus={selectedSubstatus}
        denialReasonCodeOptions={JSON.parse(denialReasonCodeOptions)}
        skipNoHcpEnrollment={skipNoHcpEnrollment}
        submissionType={submissionType}
        submitPath={submitPath}
      />
    );
  }
}
