import React from "react";
import { Field, FormSpy } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { atLeastOneFieldRequired } from "../../../util/validators";

export const TestAliasForm = ({ handleSubmit, submitting, ...rest }) => {
  return (
    <>
      <Field component={TextInput} type="hidden" name="lab.id" />
      <Field component={TextInput} type="hidden" name="id" />

      <Field component={TextInput} label="Lab" name="lab.labName" disabled />
      <Field component={TextInput} label="Test" name="commercialName" disabled />

      <Field
        component={TextInput}
        validate={atLeastOneFieldRequired("labTestMappingTestName")}
        label="Test Identifier"
        name="labTestMappingTestIdentifier"
      />
      <Field
        component={TextInput}
        validate={atLeastOneFieldRequired("labTestMappingTestIdentifier")}
        label="Test Name"
        name="labTestMappingTestName"
      />

      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const identifer = values.labTestMappingTestIdentifier;
          const testName = values.labTestMappingTestName;

          const showDanger =
            (identifer != null && !identifer.includes("|") && testName?.includes("|")) ||
            (testName != null && !testName.includes("|") && identifer?.includes("|"));
          const showWarning = !showDanger && (identifer?.includes("|") || testName?.includes("|"));

          return (
            <>
              {showDanger && (
                <div className="mb-3">
                  <span className="text-danger">
                    Due to the included "|" in only 1 of test identifier and test name this mapping is likely to not work as expected. It is
                    expected that for a panel both the test identifier and test name will contain a "|".
                  </span>
                </div>
              )}
              {showWarning && (
                <div className="mb-3">
                  <span className="text-warning">
                    Due to the included "|" in the identifier or test name this test alias will be treated as a panel.
                  </span>
                </div>
              )}
            </>
          );
        }}
      </FormSpy>

      <LoadingButton color="primary" loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Create Lab Test Mapping
      </LoadingButton>
    </>
  );
};
