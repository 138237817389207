import _ from "lodash";
import React from "react";
import { UncontrolledButtonDropdown as ButtonDropdown } from "reactstrap";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import {
  INSURANCE_MEMBER_ID,
  VERIFIED_PAYOR,
  CASE_LAB_NO_FILTER,
  CASE_LAB_ORDER_ID,
  CASE_LAB_PATIENT_ID,
  CASE_PATIENT_DOB,
  CASE_PATIENT_NAME,
  CURRENT_STATUS_NO_FILTER,
  CURRENT_SUBSTATUS_NO_FILTER,
} from "../../../components/grid/columns";
import { OpsCaseLinkCell } from "../../../components/grid/cells/CaseLinkCell";
import cx from "classnames";
import { Play } from "lucide-react";
import { formatHL7, userNameOrEmail } from "../../../util/formatters";

const DetailsButton = ({ id }) => (
  <a className="btn btn-sm btn-outline-secondary" href={`/operations/cases/${id}`}>
    Details
  </a>
);

const ProcessButtons = ({ row: r }) => {
  const id = r.original.public_id;
  const disabled = r.original.closed;
  const processLinkClasses = cx("btn btn-outline-primary btn-sm", {
    disabled: disabled,
  });

  return (
    <>
      <ButtonDropdown className="me-2">
        <a href={`/workflow/${id}`} className={processLinkClasses}>
          <Play className="btn__icon" />
          Process
        </a>
      </ButtonDropdown>
      <DetailsButton id={id} />
    </>
  );
};

const PROCESS = {
  Header: "Actions",
  id: "actions",
  Cell: ProcessButtons,
  className: "action-cell",
  minWidth: 240,
  disableFilters: true,
  disableSortBy: true,
};

const PUBLIC_ID = {
  Header: "Case ID",
  accessor: "public_id",
  Cell: OpsCaseLinkCell,
  minWidth: 125,
  disableFilters: true,
  disableSortBy: true,
};

const ASSIGNEE = {
  Header: "Assignee",
  accessor: (r) => userNameOrEmail(r.assignee),
  minWidth: 180,
  disableFilters: true,
  disableSortBy: true,
};

const CURRENT_WORKFLOW = {
  Header: "Current Workflow",
  accessor: "workflow_state",
  minWidth: 150,
  disableFilters: true,
  disableSortBy: true,
};

const VERIFIED_CPT_CODES = {
  Header: "Verified CPT Codes",
  accessor: (r) => formatHL7(r.pa_request.testInfo.cptCodes),
  Cell: TokenCell,
  minWidth: 150,
  disableFilters: true,
  disableSortBy: true,
};

const TEST_NAMES = {
  Header: "Test Names",
  accessor: "pa_request.testInfo.testName",
  Cell: TokenCell,
  minWidth: 150,
  disableFilters: true,
  disableSortBy: true,
};

export const INTERNAL_COLUMNS = [
  PROCESS,
  PUBLIC_ID,
  CASE_PATIENT_NAME({ disableSortBy: true, disableFilters: true }),
  CASE_PATIENT_DOB,
  VERIFIED_PAYOR({ disableSortBy: true, disableFilters: true }),
  INSURANCE_MEMBER_ID,
  CASE_LAB_NO_FILTER,
  ASSIGNEE,
  CURRENT_WORKFLOW,
  CURRENT_STATUS_NO_FILTER,
  CURRENT_SUBSTATUS_NO_FILTER,
  VERIFIED_CPT_CODES,
  TEST_NAMES,
  CASE_LAB_PATIENT_ID,
  CASE_LAB_ORDER_ID,
];
