import $ from "jquery";
import {
  signupValidation,
  passwordChangeValidation,
  passwordOptionalChangeValidation,
  acceptInvitationValidation,
} from "../../registration/signup_validation";

export const initSignupValidation = () => {
  $(() => {
    signupValidation();
  });
};

export const initPasswordChangeValidation = () => {
  $(() => {
    passwordChangeValidation();
  });
};

export const initPasswordOptionalChangeValidation = () => {
  $(() => {
    passwordOptionalChangeValidation();
  });
};

export const initAcceptInvitationValidation = () => {
  $(() => {
    acceptInvitationValidation();
  });
};
