import axios from "axios";
import cx from "classnames";
import { debounce } from "lodash";
import React, { Component } from "react";
import { PersistantReactTable } from "../components/grid/PersistantReactTable";
import { api } from "../util/api";
import { encodeTableState } from "../util/table";
import { INSURANCE_DOCUMENT_COLUMNS, WORKFLOW_DOCUMENT_COLUMNS } from "./columns";

export class FillableWorkflowDocumentsGrid extends Component {
  state = {
    data: [],
    pages: -1,
    loading: false,
    search: "",
  };

  constructor(props) {
    super(props);
    this.table = React.createRef();
    this.fetchData = debounce(this.fetchData, 300);
  }

  updateTableData = () => this.table.updateTableData();

  fetchData = async (tableState = null, _instance) => {
    // show the loading overlay
    this.setState({ loading: true });

    try {
      let dataReq = await api.get(this.props.apiUrl, {
        params: {
          lab_id: this.props.labId,
          discarded: this.props.discarded,
          ...tableState,
        },
      });

      this.setState({
        data: dataReq.data.rows,
        pages: dataReq.data.pages,
      });
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e);
      }
    } finally {
      this.setState({ loading: false, tableState });
    }
  };

  onFetchData = async (rawTableState, _instance) => {
    const tableState = encodeTableState(rawTableState, this.state);

    try {
      await this.fetchData(tableState);
    } catch (e) {
      console.error(e);
    }
  };

  onSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => this.updateTableData());
  };

  getDefaultFiltered = () => {
    const { defaultPublished } = this.props;
    return defaultPublished ? [{ id: "published", value: !!defaultPublished ? 1 : 0 }] : [];
  };

  rowProps = (rowInfo) => {
    if (!rowInfo) return {};
    const { original } = rowInfo;

    return {
      className: cx({
        read: original.read,
        unread: !original.read,
      }),
    };
  };

  render() {
    const { data, pages, loading, search } = this.state;
    const { formType } = this.props;

    const columns = formType === "workflow" ? WORKFLOW_DOCUMENT_COLUMNS : INSURANCE_DOCUMENT_COLUMNS;

    return (
      <PersistantReactTable
        title="Insurance Documents"
        ref={(i) => (this.table = i)}
        columns={columns}
        data={data}
        pages={pages}
        loading={loading}
        onFetchData={this.onFetchData}
        getTrProps={this.rowProps}
        defaultSorted={[{ id: "created_at", desc: true }]}
        getDefaultFiltered={this.getDefaultFiltered}
        onSearch={this.onSearchChange}
        search={search}
      />
    );
  }
}
