import { createRoot } from "react-dom/client";

import { FillableDocumentEditor } from "../fillable_document_editor";

export const initFillableDocumentEditor = () => {
  const editorContainer = document.getElementById("fillable_document_editor");

  if (editorContainer) {
    const root = createRoot(editorContainer);
    const { getSchemaPath, updateSchemaPath, previewSchemaPath, editDocumentPath, exportSchemaEnabled } = editorContainer.dataset;

    root.render(
      <FillableDocumentEditor
        getSchemaPath={getSchemaPath}
        updateSchemaPath={updateSchemaPath}
        editDocumentPath={editDocumentPath}
        previewSchemaPath={previewSchemaPath}
        exportEnabled={JSON.parse(exportSchemaEnabled)}
      />
    );
  }
};
