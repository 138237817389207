import React, { useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { Checkbox } from "../../../../components/inputs/final_form/Checkbox";
import { BoolInput } from "../../../../components/inputs/final_form/SelectInput";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../../components/LoadingButton";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";
import { SelectInput } from "../../../../components/inputs/final_form/SelectInput";
import { required } from "../../../../util/validators";
import { api } from "../../../../util/api";

const CASE_TYPE_OPTIONS = [
  { value: "type_prior_auth", label: "Prior Auth" },
  { value: "type_benefits_investigation", label: "Benefits Investigation" },
];

const StatusesList = (props) => {
  return (
    <>
      {props.values.caseType == "type_prior_auth" && (
        <Field
          component={AdministrateSelectInput}
          resourceUrl="/admin/enums/prior_auth_statuses.json"
          label="Status"
          name="status"
          validate={required}
          required
          labelKey="label"
          onUpdate={props.updateSubstatusOptions}
        />
      )}
      {props.values.caseType == "type_benefits_investigation" && (
        <Field
          component={AdministrateSelectInput}
          resourceUrl="/admin/enums/benefits_investigation_statuses.json"
          label="Status"
          name="status"
          validate={required}
          required
          labelKey="label"
        />
      )}
    </>
  );
};

export const loadSubstatuses = async (status) => {
  const res = await api.get(`/admin/enums/prior_auth_substatuses.json?status=${status}`);
  return res.data.resources;
};

export const SubstatusesList = (props) => {
  return <Field isClearable component={SelectInput} options={props.options} label="Substatus" name="substatus" labelKey="label" />;
};

export const clearSubstatusMutator = ([], state, { changeValue }) => {
  changeValue(state, "substatus", () => null);
};

export const StatusMappingForm = ({ handleSubmit, submitting, form }) => {
  const [substatusOptions, setSubstatusOptions] = useState(null);

  const updateSubstatusOptions = (selectedStatus) => {
    setSubstatusOptions(null);
    form.mutators.clearSubstatusMutator();

    if (selectedStatus) {
      loadSubstatuses(selectedStatus.value).then((options) => setSubstatusOptions(options));
    }
  };

  return (
    <>
      <Field id="allLabs" component={Checkbox} label="All Labs" name="allLabs" type="checkbox" />
      <Field name="allLabs" subscription={{ value: true }}>
        {({ input: { value } }) =>
          !value && (
            <Field
              component={AdministrateSelectInput}
              resourceUrl="/admin/labs.json"
              label="Labs"
              name="labId"
              isMulti
              subscription={{ value: true }}
            />
          )
        }
      </Field>
      <Field id="allPayors" component={Checkbox} label="All Payors" name="allPayors" type="checkbox" />
      <Field name="allPayors" subscription={{ value: true }}>
        {({ input: { value } }) =>
          !value && (
            <Field
              component={AdministrateSelectInput}
              resourceUrl="/admin/payors.json"
              label="Payors"
              name="payorId"
              isMulti
              searchParam="name"
            />
          )
        }
      </Field>
      <Field
        component={SelectInput}
        label="Case Type"
        options={CASE_TYPE_OPTIONS}
        name="caseType"
        includeBlank
        required
        validate={required}
        onUpdate={() => updateSubstatusOptions(null)}
      />

      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          return (
            <>
              <StatusesList values={values} updateSubstatusOptions={updateSubstatusOptions} />
              {values.caseType === "type_prior_auth" && substatusOptions && <SubstatusesList options={substatusOptions} />}
            </>
          );
        }}
      </FormSpy>

      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/cpt_codes.json"
        label="CPT Code Ids"
        name="cptCodeIds"
        isMulti
        valueKey="id"
      />
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          return values.allLabs != true && values.labId?.length === 1 ? (
            <Field
              component={AdministrateSelectInput}
              resourceUrl={`/admin/tests.json?test[direction]=asc&test[order]=commercial_name&lab=${values.labId[0]}&archived=false`}
              label="Tests"
              labelKey="commercial_name"
              name="testIds"
              isMulti
              valueKey="id"
              key="tests"
            />
          ) : (
            <Field
              component={AdministrateSelectInput}
              label="Tests"
              resourceUrl={"/admin/tests.json?archived=false"}
              labelKey="commercial_name"
              name="testIds"
              isMulti
              valueKey="id"
              isDisabled={true}
              key="tests-disabled"
            />
          );
        }}
      </FormSpy>

      <Field component={TextInput} label="Group ID" name="groupId" />
      <Field component={BoolInput} label="Request Submitted" name="requestSubmitted" includeBlank />
      <Field component={BoolInput} label="Discard Case" name="discardCase" />
      <Field component={TextInput} label="Automated Comment" name="automatedComment" />
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
