import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const InsurancePayorMappingForm = ({ handleSubmit, submitting, showPrefixField = true }) => (
  <>
    <Field
      component={AdministrateSelectInput}
      resourceUrl="/admin/labs.json"
      searchParam="name"
      label="Lab"
      kept={true}
      name="labId"
      validate={required}
    />
    <Field
      component={AdministrateSelectInput}
      resourceUrl="/admin/payors.json"
      searchParam="name"
      label="Payor"
      kept={true}
      name="payorId"
      validate={required}
    />
    <Field component={TextInput} validate={required} label="Provided Insurance Name" name="insuranceName" />
    <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
      Submit
    </LoadingButton>
  </>
);
