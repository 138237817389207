import _, { isEmpty } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Input, Label, ModalBody, ModalFooter } from "reactstrap";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { LoadingButton } from "../components/LoadingButton";
import { api, redirectTo } from "../util/api";
import { SplitHistory } from "./SplitHistory";
import { SplitPicker } from "./SplitPicker";

function makeTestOption(option) {
  return {
    label: option.name,
    value: option.id,
  };
}

function makeCptOption(option) {
  return {
    label: option.name,
    value: option.name,
  };
}

const makeSplit = () => ({ tests: "", codes: "" });

const diffCounts = (a, b) =>
  _.reduce(
    a,
    (acc, count, key) => {
      acc[key] = count - _.get(b, key, 0);
      return acc;
    },
    {}
  );

const sumCounts = (a, b) =>
  _.reduce(
    a,
    (acc, count, key) => {
      acc[key] = count + _.get(b, key, 0);
      return acc;
    },
    {}
  );

const getCptOptionsBySplit = (splits, allCptCodeOptions) => {
  const optionsBySplit = splits.map(() => {
    const usedCodes = _.flatten(_.compact(splits.map((s) => (_.isArray(s.codes) ? s.codes : null))));

    //
    // This is an element-wise diff of the used codes
    // and all code options. This is different from a filter,
    // insofar as we don't want to remove _all_ matches,
    // only one instance of each match.
    //
    // Simplified Example:
    //
    // usedCodes = ['0008B']
    // allCodes = ['0008B', '0008B', '222M0']
    // diff = ['0008B', '222M0']
    //
    let newOptions = [...allCptCodeOptions];
    _.each(usedCodes, (c) => {
      newOptions = _.without(
        newOptions,
        _.find(newOptions, (o) => o.value === c)
      );
    });

    // Once we have the diff, we want to unique on value
    // so that we don't end up returning more than one
    // instance of each possible option
    return _.uniqBy(newOptions, "value");
  });

  return optionsBySplit;
};

const getTestOptionsBySplit = (splits, allTestOptions) => {
  const optionsBySplit = splits.map(() => {
    const usedTests = _.flatten(_.compact(splits.map((s) => (_.isArray(s.tests) ? s.tests : null))));
    return _.differenceBy(allTestOptions, usedTests, "value");
  });

  return optionsBySplit;
};

export const RequestSplitter = ({ caseId }) => {
  //
  // State
  //

  const [splits, setSplits] = useState([makeSplit(), makeSplit()]);
  const [allTestOptions, setAllTestOptions] = useState([]);
  const [allCptCodeOptions, setAllCptCodeOptions] = useState([]);
  const [splitHistory, setSplitHistory] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  //
  // Handlers
  //

  const fetchTestOptions = useCallback(
    async (mounted = true) => {
      try {
        const testUrl = `/operations/prior_authorize_requests/${caseId}/test_options`;
        const { data } = await api.get(testUrl);

        if (mounted) {
          setAllTestOptions(data.map(makeTestOption));
        }
      } catch (e) {
        if (mounted) {
          setError(e);
          setAllTestOptions([]);
        }
      }
    },
    [caseId, setAllTestOptions, setError]
  );

  const fetchCptCodeOptions = useCallback(
    async (mounted = true) => {
      try {
        const cptCodeUrl = `/operations/prior_authorize_requests/${caseId}/cpt_code_options`;
        const { data } = await api.get(cptCodeUrl);

        if (mounted) {
          setAllCptCodeOptions(data.map(makeCptOption));
        }
      } catch (e) {
        if (mounted) {
          setError(e);
          setAllCptCodeOptions([]);
        }
      }
    },
    [caseId, setAllCptCodeOptions, setError]
  );

  const fetchSplitFromParent = useCallback(
    async (mounted = true) => {
      try {
        const parentSplitUrl = `/operations/prior_authorize_requests/${caseId}/split_history`;
        const { data } = await api.get(parentSplitUrl);

        if (mounted) {
          setSplitHistory(data);
        }
      } catch (e) {
        if (mounted) {
          setError(e);
          setSplitHistory(null);
        }
      }
    },
    [caseId, setSplitHistory, setError]
  );

  const handleSplitCountChange = useCallback(
    (e) => {
      const count = Math.max(e.target.valueAsNumber, 2);
      const oldSplits = [...splits];
      const newSplits = _.times(count).map(() => oldSplits.shift() || makeSplit());

      setSplits(newSplits);
    },
    [setSplits, splits]
  );

  const handleSplitUpdate = (updatedSplit, idx) => {
    const newSplits = [...splits];
    newSplits[idx] = updatedSplit;
    setSplits(newSplits);
  };

  const handleAddRemaining = (remainingTests, remainingCodeCounts) => {
    const tests = remainingTests.map((rt) => allTestOptions.find((o) => o.label === rt));
    const codes = _.flatMap(remainingCodeCounts, (count, code) => _.times(count, () => code));
    const newSplit = { tests, codes };

    setSplits([...splits, newSplit]);
  };

  const mapCodesToCptIds = useCallback((codes) => codes.map((c) => allCptCodeOptions.find((o) => o.label === c)), [allCptCodeOptions]);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    setError(null);

    try {
      const payload = _.map(splits, (s) => ({
        ...s,
        codes: mapCodesToCptIds(s.codes),
      }));

      const { data } = await api.post(`/operations/prior_authorize_requests/${caseId}/splits`, { split_attribute: payload });
      redirectTo(data.value[0]);
    } catch (e) {
      setError(e);
    } finally {
      setSubmitting(false);
    }
  }, [splits, caseId, mapCodesToCptIds]);

  //
  // Effects
  //

  useEffect(() => {
    let mounted = true;
    const fetchAll = async () => Promise.all([fetchTestOptions(mounted), fetchCptCodeOptions(mounted), fetchSplitFromParent(mounted)]);

    fetchAll();

    return () => void (mounted = false);
  }, [fetchTestOptions, fetchCptCodeOptions, fetchSplitFromParent]);

  //
  // Calculated State
  //

  const hasSplitHistory = useMemo(
    () => !isEmpty(_.get(splitHistory, "parent")) || !isEmpty(_.get(splitHistory, "children")),
    [splitHistory]
  );

  const unsplittable = useMemo(() => !_.get(splitHistory, "is_splittable", true), [splitHistory]);
  const allCptsSelected = useMemo(() => true, []);
  const allSplitsHaveTest = useMemo(() => !splits.some((x) => x.tests == "" || x.tests == null), [splits]);

  const testOptions = useMemo(() => getTestOptionsBySplit(splits, allTestOptions), [splits, allTestOptions]);
  const cptOptions = useMemo(() => getCptOptionsBySplit(splits, allCptCodeOptions), [splits, allCptCodeOptions]);

  const totalCodeCounts = useMemo(() => _.countBy(allCptCodeOptions, "label"), [allCptCodeOptions]);
  const consumedCodeCounts = useMemo(() => _.countBy(_.flatten(splits.map((s) => s.codes))), [splits]);
  const remainingCodeCounts = useMemo(() => diffCounts(totalCodeCounts, consumedCodeCounts), [totalCodeCounts, consumedCodeCounts]);
  const remainingTests = useMemo(() => testOptions[0]?.map((o) => o.label), [testOptions]);

  const hasRemainingTests = remainingTests.length > 0;
  const hasRemainingCpts = useMemo(() => _.sum(_.values(remainingCodeCounts)) > 0, [remainingCodeCounts]);

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">Split Request</h5>

        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>

      <ModalBody>
        <DetailedErrorAlert error={error} />

        {hasSplitHistory && <SplitHistory {...splitHistory} />}

        <div className="mb-3 d-flex flex-row align-items-center">
          Split Into{" "}
          <Input
            type="number"
            name="split-count"
            id="split-count"
            className="mx-2"
            value={splits?.length || 2}
            onChange={handleSplitCountChange}
            disabled={unsplittable}
            style={{ width: 80, display: "inline-block" }}
          />{" "}
          Cases
        </div>

        <hr className="my-4" />

        {hasRemainingTests > 0 && (
          <div className="d-flex flex-row flex-wrap align-items-center mb-3">
            <Label>Unused tests:&nbsp;</Label>
            {_.map(remainingTests, (t) => (
              <span className="token mx-1 mb-1" key={t}>
                {t}
              </span>
            ))}
          </div>
        )}

        {hasRemainingCpts && (
          <div className="d-flex flex-wrap flex-row align-items-center mb-3">
            <Label>Unused codes:&nbsp;</Label>

            {_.map(remainingCodeCounts, (count, code) =>
              count > 0 ? (
                <span className="token mx-1 mb-1" key={code}>
                  {code}&times;{count}
                </span>
              ) : null
            )}
          </div>
        )}

        {(hasRemainingCpts || hasRemainingTests) && <hr className="my-4" />}

        {splits.map((split, idx) => (
          <SplitPicker
            key={idx}
            split={split}
            testOptions={testOptions[idx]}
            cptOptions={cptOptions[idx]}
            disabled={unsplittable}
            onChangeTest={(tests) => handleSplitUpdate({ ...split, tests }, idx)}
            onChangeCodes={(codes) => handleSplitUpdate({ ...split, codes }, idx)}
            // set the max for each code to the current count of this input
            // plus the remaining counts for all inputs; this is to allow
            // any input to consume all remaining copies of a code
            maxCounts={sumCounts(_.countBy(split.codes), remainingCodeCounts)}
          />
        ))}

        {(hasRemainingCpts || hasRemainingTests) && !unsplittable && (
          <div className="text-end">
            <Button size="sm" outline onClick={() => handleAddRemaining(remainingTests, remainingCodeCounts)}>
              Add Split with Remainder
            </Button>
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Button outline color="secondary" data-bs-dismiss="modal">
          Cancel
        </Button>

        <LoadingButton
          color="primary"
          disabled={submitting || unsplittable || !allCptsSelected || !allSplitsHaveTest}
          loading={submitting}
          onClick={handleSubmit}
        >
          Split
        </LoadingButton>
      </ModalFooter>
    </>
  );
};
