import React from "react";
import { TrinaryTree } from "../../../../components/wizards/TrinaryTree";
import { YesNoToggle } from "../../../../components/wizards/YesNoToggle";
import { WizardContext } from "../../WizardContext";
import { MedicalReviewPeerToPeerRequiredBranch } from "./MedicalReviewPeerToPeerRequiredBranch";
import { MedicalReviewPeerToPeerNotRequiredBranch } from "./MedicalReviewPeerToPeerNotRequiredBranch";

export const MedicalReviewNotMissingInfoBranch = (props) => (
  <WizardContext.Consumer>
    {({ state, caseData, dispatch }) => (
      <>
        <YesNoToggle
          label="Does this case require peer-to-peer communication?"
          id="medicalReviewPeerToPeer"
          value={state.answers.medicalReviewPeerToPeer}
          onChange={(v) => {
            dispatch({
              type: "setAnswer",
              key: "medicalReviewPeerToPeer",
              value: v,
              doTransition: false,
            });
          }}
        />

        <TrinaryTree
          branch={state.answers.medicalReviewPeerToPeer}
          trueBranch={<MedicalReviewPeerToPeerRequiredBranch />}
          falseBranch={<MedicalReviewPeerToPeerNotRequiredBranch />}
        />
      </>
    )}
  </WizardContext.Consumer>
);
