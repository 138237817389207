import React, { useState } from "react";
import { Alert } from "reactstrap";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { SearchCaseIdForm } from "./components/SearchCaseIdForm";
import { PullBenefitsLogListing } from "./PullBenefitsLogListing";

export const PullBenefitsLogPage = () => {
  const [caseId, setCaseId] = useState(null);

  const onSubmit = (val) => {
    setCaseId(val);
  };

  return (
    <SystemConfigLayout title={["Tools", "Pull Benefits Logs"]}>
      <SearchCaseIdForm onSubmit={onSubmit} />

      {caseId && <PullBenefitsLogListing caseId={caseId} />}
    </SystemConfigLayout>
  );
};
