import cx from "classnames";
import React from "react";
import { Button, ButtonGroup } from "reactstrap";

export const PillToggle = ({ options, value, onChange, disabled, readOnly, className }) => {
  return (
    <ButtonGroup className={cx("btn-group-pills", className)}>
      {options.map((option) => {
        const selected = option.value === value;

        return (
          <Button
            color={selected ? "blue" : undefined}
            outline={!selected}
            className={cx({ selected })}
            disabled={disabled || readOnly}
            key={option.value}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};
