import React from "react";
import { Field } from "react-final-form";
import { MoneyInput } from "../../../components/inputs/final_form/MoneyInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { PercentInput } from "../../../components/inputs/final_form/PercentInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { atLeastOneFieldWithMoneyRequired, required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const CptCodeListPriceForm = ({ handleSubmit, submitting }) => {
  return (
    <>
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/cpt_codes.json"
        label="CPT Code"
        name="cptCodeId"
        required
        validate={required}
      />
      <Field component={MoneyInput} validate={atLeastOneFieldWithMoneyRequired("medicarePercentage")} label="Price" name="priceCents" />
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
