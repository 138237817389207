import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronRight } from "lucide-react";
import { Collapse, Card, CardBody, List, Button } from "reactstrap";
import { formatCentsAsDollars, formatDate } from "../../../util/formatters";
import { initPriceComparison } from "../../../actions/init_price_comparison";
import { EstimateInfoList } from "./EstimateInfoList";
import { EstimateInfoListPharmacyUser } from "./EstimateInfoListPharmacyUser";

export const EstimateInfo = ({
  biInfo,
  priceComparisonEnabled,
  caseId,
  payorShowPriceComparison,
  serviceType,
  labOrClinicUser,
  isPharmacyOwnedByClinic,
  noMoopProvided = false,
  coveredChargesEnabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const estimateData = biInfo;
  const patientResponsibility = biInfo?.estimated_patient_responsibility_total;
  const displayPatientResponsibilityValue =
    patientResponsibility || patientResponsibility === 0 ? formatCentsAsDollars(patientResponsibility) : "N/A";

  const toggleCollapse = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (priceComparisonEnabled) {
      initPriceComparison(true);
    }
  }, [priceComparisonEnabled]);

  return (
    <div className="d-flex flex-column justify-content-center">
      <h4 className="text-secondary h3 fw-medium mt-4">seeQer Estimate</h4>

      <div className={`border-info ${isOpen ? "border-1" : "border-0"}`}>
        <div id="estimate-section" className="d-flex flex-row p-3 rounded-top bg-info bg-opacity-10">
          <div className="col-md-4">
            <h6 className="fw-bold">Estimated Total Patient Responsibility</h6>
            <h5 className="text-dark fw-bold">{displayPatientResponsibilityValue}</h5>
          </div>

          <div className="col-md-2">
            <h6 className="fw-bold">Effective Date</h6>

            {!!biInfo?.effective_date ? (
              <h5 className="text-dark fw-bold">{formatDate(biInfo.effective_date.substr(0, 10))}</h5>
            ) : (
              <h5>N/A</h5>
            )}
          </div>

          <div className="col-md-3">
            {!labOrClinicUser && (
              <>
                <h6 className="fw-bold">Benefits Type</h6>

                {serviceType && <h5 className="text-dark fw-bold">{serviceType}</h5>}
              </>
            )}
          </div>

          <div className="col-md-3 d-flex flex-column align-items-between justify-content-end gap-1">
            {priceComparisonEnabled && (
              <div id="price_comparison" data-case-id={caseId} data-payor-show-price-comparison={payorShowPriceComparison}></div>
            )}
            <Button
              className="bg-info bg-opacity-50 py-1 btn-block"
              onClick={toggleCollapse}
              title="Click here to show seeQer estimate data."
            >
              <h6 className="fw-bold my-auto">{isOpen ? "Hide" : "Show"} Details</h6>
              {isOpen ? <ChevronDown className="my-auto" /> : <ChevronRight className="my-auto" />}
            </Button>
          </div>
        </div>

        <Collapse className="rounded-bottom" isOpen={isOpen}>
          <Card
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            className="border-0"
          >
            <CardBody>
              <List type="unstyled">
                {!!estimateData ? (
                  <>
                    {isPharmacyOwnedByClinic ? (
                      <EstimateInfoListPharmacyUser
                        biInfo={biInfo}
                        noMoopProvided={noMoopProvided}
                        coveredChargesEnabled={coveredChargesEnabled}
                      />
                    ) : (
                      <EstimateInfoList biInfo={biInfo} noMoopProvided={noMoopProvided} />
                    )}
                  </>
                ) : (
                  <>This case doesn't have estimated coverage data yet.</>
                )}
              </List>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    </div>
  );
};
