import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { DiscardPayorContactModal } from "./DiscardPayorContactModal";
import { EditPayorContactModal } from "./EditPayorContactModal";
import { History } from "../../tools/sandbox/release/History";

export const PayorContactActionCell = ({ payorId, ...row }) => {
  const [canUpdate] = useUpdatePermission("PayorContact", row.original.id);
  const [canDestroy] = useDestroyPermission("PayorContact", row.original.id);

  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = (msg) => {
    setMessage(`Updated ${row.original.title}`);
    refresh();
  };

  const handleDiscard = (msg) => {
    setMessage(`Deleted ${row.original.title}`);
    refresh();
  };

  const handleError = (msg) => {
    setError(msg);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditPayorContactModal color="link" onCreate={handleChange} payorId={payorId} contact={row.original} />}
      {canDestroy && <DiscardPayorContactModal color="link" className="text-danger" onDelete={handleDiscard} contact={row.original} />}
      <History entityName="PayorContact" subjectId={row.original.id} />
    </>
  );
};
