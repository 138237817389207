const reopenCaseForm = document.querySelector("#reopen-case-form");

export function initReopenCase() {
  if (reopenCaseForm) {
    subStatusDefaultOption();
    commentPublicValue();
  }
}

const subStatusDefaultOption = () => {
  let substatusDropdown = reopenCaseForm.querySelector(".request_substatus");

  if (substatusDropdown) {
    let blankOption = document.createElement("option");
    blankOption.text = "-- Select a Substatus --";
    blankOption.selected = true;
    blankOption.value = "";
    substatusDropdown.prepend(blankOption);
  }
};

const commentPublicValue = () => {
  let commentContent = reopenCaseForm.querySelector("#create_comment_form_content");
  if (commentContent) {
    let commentPublic = reopenCaseForm.querySelector("#create_comment_form_public");

    commentContent.addEventListener("change", () => {
      // only set comment public field value if content is not blank
      commentPublic.value = commentContent.value !== "" ? true : null;
    });
  }
};
