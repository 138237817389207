import React from "react";
import { connect } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { FormGroup, ListGroup, ListGroupItem } from "reactstrap";
import { addField, selectField, selectFieldById, updateFieldOrder } from "../store";
import { AddFieldDropdown } from "./AddFieldDropdown";

class SidebarToolsComponent extends React.Component {
  render() {
    const { onAddField, onSelectField, selectedField, onUpdateFieldOrder, defaultFields, customFields, currentPage, fields } = this.props;

    const sortedFields = _.sortBy(fields, "fieldOrder");
    const pageGroups = _.groupBy(sortedFields, (f) => f.position.page);

    const setPageFields = (fieldList) => {
      onUpdateFieldOrder(fieldList);
    };

    const currentFields = _.flatMap(pageGroups, (pageFields, page) => {
      const pageHeader = (
        <ListGroupItem disabled tag="button" action className="pl-2 py-1" key={page}>
          Page {parseInt(page) + 1}
        </ListGroupItem>
      );

      const result = (
        <ReactSortable list={pageFields} setList={setPageFields}>
          {pageFields.map((f, i) => (
            <ListGroupItem
              tag="button"
              action
              active={selectedField && f.id === selectedField.id}
              onClick={() => onSelectField(f.id)}
              key={`${page}.${i}`}
              className="py-1"
              id={f.id}
            >
              {f.label}
            </ListGroupItem>
          ))}
        </ReactSortable>
      );

      return (
        <React.Fragment key={page}>
          {pageHeader}
          {result}
        </React.Fragment>
      );
    });

    return (
      <div>
        <FormGroup>
          <AddFieldDropdown onAddField={onAddField} defaultFields={defaultFields} customFields={customFields} currentPage={currentPage} />
        </FormGroup>

        {currentFields && <ListGroup className="mb-3">{currentFields}</ListGroup>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fields: state.fields,
    selectedField: selectFieldById(state, state.selectedField),
    defaultFields: state.defaultFields,
    customFields: state.customFields,
    currentPage: state.currentPage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddField: (data) => {
    dispatch(addField({ data }));
  },

  onSelectField: (fieldId) => {
    dispatch(selectField({ fieldId }));
  },

  onUpdateFieldOrder: (fieldList) => {
    dispatch(updateFieldOrder({ fieldList }));
  },
});

export const SidebarTools = connect(mapStateToProps, mapDispatchToProps)(SidebarToolsComponent);
