import React from "react";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";

export const ImportLabsModal = ({ onImport, policyId, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      file: {
        bytes: values.file,
      },
    };
    const res = await api.post("/admin/labs/import.json", snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message, res.data?.meta?.error_details);
    }
  };

  return <ImportModal buttonProps={rest} onImport={handleImport} title="Import Labs..." exampleDownloadPath="/admin/labs/example" />;
};
