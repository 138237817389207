import React from "react";
import { ActionAlert } from "../../../components/ActionAlert";
import { LoadingButton } from "../../../components/LoadingButton";
import { WizardContext } from "../WizardContext";

export const RecordResponseScreen = () => (
  <WizardContext.Consumer>
    {({ continueWorkflow, loading }) => (
      <ActionAlert
        color="success"
        actions={
          <>
            <LoadingButton color="success" loading={loading} onClick={continueWorkflow}>
              Continue
            </LoadingButton>
          </>
        }
      >
        Continue with workflow to finalize Case.
      </ActionAlert>
    )}
  </WizardContext.Consumer>
);
