import axios from "axios";
import { uniq, isMatch } from "lodash";
import { isBlank } from "../util/helpers";
import React, { useMemo, useState, useCallback, useRef } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import { DetailedAlert } from "../components/DetailedAlert";
import { StyledSelect as Select } from "../components/inputs/StyledSelect";
import { LoadingButton } from "../components/LoadingButton";
import { Toolbar } from "../components/Toolbar";
import { api, extractErrorDetails, extractErrorMessage } from "../util/api";
import { AssignableCasesTable } from "./assignment/AssignableCasesTable";
import { AssignmentSearchForm } from "./AssignmentSearch";
import { AssigneeStats } from "./assignment/AssigneeStats";
import { encodeTableState } from "../util/table";
import { createStartDate } from "../dashboard";

const START_DATE = createStartDate({ days: 90 });

const ReassignmentToolbar = ({ account, assignableUsers, selectedCaseIds, handleResponse }) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [assignee, setAssignee] = useState(null);

  const assignableUserOptions = useMemo(() => {
    return [
      {
        value: null,
        label: <span className="fw-bolder">Unassign</span>,
      },
    ].concat(
      assignableUsers
        .filter((au) => au.email !== account.email)
        .map(({ email, name }) => ({ value: email, label: name || email }))
        .sort((userA, userB) => (userA.label.toLowerCase() < userB.label.toLowerCase() ? -1 : 1))
    );
  }, [assignableUsers, account]);

  const reassignButtonDisabled = selectedCaseIds.length == 0 || assignee == null;

  const handleReassign = async () => {
    setLoading(true);
    setResult(null);

    try {
      const result = await api.post("/cases/assign_user", { case_ids: selectedCaseIds, assignee: assignee.value });

      setResult({
        message: result.data.meta.message,
        details: result.data.meta.error_details,
        messageStyle: (result.data.meta.error_details ?? []).length > 0 ? "warning" : "success",
      });

      handleResponse(result);
    } catch (err) {
      setResult({
        message: extractErrorMessage(err),
        details: extractErrorDetails(err),
        messageStyle: "danger",
      });
    } finally {
      setLoading(false);
    }
  };

  const reassignButtonText =
    selectedCaseIds.length > 0 ? (
      <>
        Reassign <strong>{selectedCaseIds.length}</strong> Cases
      </>
    ) : (
      "Reassign"
    );

  return (
    <>
      {result && <DetailedAlert {...result} />}
      <Toolbar>
        <div className="ml-auto">
          <span className="me-2 d-inline-block" style={{ minWidth: 320 }}>
            <Select
              type="select"
              isClearable
              isSearchable
              autoFocus
              value={assignee}
              onChange={setAssignee}
              options={assignableUserOptions}
              placeholder="Select Assignee"
            />
          </span>

          <LoadingButton
            onClick={handleReassign}
            loading={loading}
            disabled={reassignButtonDisabled || loading}
            color="primary"
            style={{ gap: 2 }}
          >
            {reassignButtonText}
          </LoadingButton>
        </div>
      </Toolbar>
    </>
  );
};

const serializeFilters = (
  { labIds, payorIds, clinicIds, status, createdDate, taskDueDate, requiresUSAssignee, payorPaths },
  accountEmail
) => {
  const filters = [
    {
      id: "lab",
      value: labIds,
    },
    {
      id: "payor",
      value: payorIds,
    },
    {
      id: "clinic",
      value: clinicIds,
    },
    {
      id: "status",
      value: status,
    },
    {
      id: "created_at",
      value: {
        operation: "before",
        args: createdDate,
      },
    },
    {
      id: "due_date",
      value: {
        operation: "equals",
        args: taskDueDate,
      },
    },
    {
      id: "requires_us_assignee",
      value: requiresUSAssignee,
    },
    {
      id: "payor_paths",
      value: payorPaths,
    },
    {
      id: "assignee",
      value: accountEmail,
    },
  ];
  return { filtered: filters?.filter((f) => !isBlank(f?.value)) };
};

export const ReassignmentSearch = ({ account, assignableUsers, searchableStatuses, payorPathDefaults, showUserNotes }) => {
  const [assignedCases, setAssignedCases] = useState([]);
  const [selectedCaseIds, setSelectedCaseIds] = useState([]);

  const tableRef = useRef();
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [pageLength, setPageLength] = useState(0);
  const [rows, setRows] = useState(assignedCases);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(
    async (rawTableState) => {
      const filterParam = filters ? serializeFilters(filters, account.email) : { filtered: [{ id: "assignee", value: account.email }] };

      // show the loading overlay
      setLoading(true);

      try {
        let dataReq = await api.get("/cases.json", {
          params: {
            ...encodeTableState(rawTableState),
            ...filterParam, // Filters from the form
            // always set searches
            case_type: "type_prior_auth",
            hide_done: true,
            hide_assigned: false,
            hide_held: true,
            start_date: START_DATE,
            sort_for_assign_and_reassign: true,
          },
        });

        setRows(dataReq.data.rows);
        setPages(dataReq.data.pages);
        setPageLength(dataReq.data.rows.length);
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.error(e);
        }
      } finally {
        setLoading(false);
      }
    },
    [account.email, filters]
  );

  const handleResponse = useCallback(
    async (response) => {
      const failedCaseIds = response.data?.updated?.invalid_assignments?.map?.((ia) => ia.id) ?? [];
      const reassignedCaseIds = selectedCaseIds.filter((id) => !failedCaseIds.includes(id));

      setAssignedCases((prev) => prev.filter((c) => !reassignedCaseIds.includes(c.public_id)));
      setSelectedCaseIds((prev) => prev.filter((id) => failedCaseIds.includes(id)));

      await tableRef?.current?.updateTableData();
    },
    [selectedCaseIds]
  );

  const handleSearch = useCallback((filters) => {
    setFilters(() => {
      return filters;
    });

    tableRef.current.resetPage();
  }, []);

  return (
    <>
      <Card className="mb-3">
        {showUserNotes && (
          <Row className="px-4 pt-4">
            <Col>
              <h6>User Notes:</h6>
              <blockquote class="text-muted">{account.notes ?? "No notes..."}</blockquote>
            </Col>
          </Row>
        )}
        <AssigneeStats account={account} />
      </Card>

      <Card className="mb-3">
        <AssignmentSearchForm
          onFormSubmit={handleSearch}
          account={account}
          searchableStatuses={searchableStatuses}
          isOffshoreAccount={account.is_offshore}
          payorPathDefaults={payorPathDefaults}
          noPreload
          noDefault
        />
      </Card>

      <Card>
        <CardHeader>
          <ReassignmentToolbar
            account={account}
            selectedCaseIds={selectedCaseIds}
            assignableUsers={assignableUsers}
            handleResponse={handleResponse}
          />
        </CardHeader>

        <CardBody className="p-0">
          <AssignableCasesTable
            ref={tableRef}
            data={rows}
            loading={loading}
            onFetchData={fetchData}
            onSelectionChange={(selection) => setSelectedCaseIds(uniq(selection))}
            page={page}
            pages={pages}
            showPagination={true}
            pageSize={pageLength}
            defaultPageSize={assignedCases?.length > 0 ? assignedCases?.length : 10}
            onPageSizeChange={(size) => setPageLength(size)}
          />
        </CardBody>
      </Card>
    </>
  );
};
