import React, { useCallback } from "react";

import { Toolbar } from "../../components/Toolbar";
import { TokenCell } from "../../components/grid/cells/TokenCell";
import { CountCell } from "../../components/grid/cells/CountCell";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { makeColumns, UPDATE, useAdministrateTable } from "../utils";
import { AddTestRepositoryModal } from "./components/AddTestRepositoryModel";
import { TestRepositoryActionCell } from "./components/TestRepositoryActionCell";
import { BoolFilter } from "../../components/inputs/BoolFilter";
import { LimitCoinsuranceActionCell } from "./components/LimitCoinsuranceActionCell";
import { AdministrateEnumFilter } from "../components/AdministrateEnumFilter";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "Id",
    Cell: TokenCell,
  },
  {
    id: "commercial_name",
    accessor: "commercialName",
    Header: "Product/Procedure/Test Name",
  },
  {
    id: "test_identifier",
    accessor: "testIdentifier",
    Header: "Product/Procedure/Test ID",
  },
  {
    id: "cpt_codes",
    accessor: "cptCodes",
    Cell: ({ value }) => (
      <div title={value}>
        <CountCell count={value?.length} label="CPT Codes" />
      </div>
    ),
    Header: "Procedure Codes",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" isMulti valueKey="id" labelKey="code" searchParam="code" />
    ),
  },
  {
    id: "limit_coinsurance",
    accessor: "limitCoinsurance",
    Header: "Limit Coinsurance to 20%",
    disableSortBy: true,
    className: "action-cell",
    Cell: LimitCoinsuranceActionCell,
    Filter: BoolFilter,
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: TestRepositoryActionCell,
    disableFilters: true,
    disableSortBy: false,
  },
]);

export const TestRepositoryIndexPage = () => {
  const columns = useColumns();
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  const onCreate = useCallback(
    (values) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`New Product/Procedure/Test (${values.commercial_name}) Added.`);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Product/Procedure/Test Repository">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AddTestRepositoryModal color="primary" onCreate={onCreate} />
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/tests.json?central_repository=${true}`}
        resourceName={"test"}
        permissions={[UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
