import React, { useMemo, useState } from "react";
import { FormWizard } from "../components/forms/FormWizard";
import { FormWizardPage } from "../components/forms/FormWizardPage";
import { PatientInsuranceInfo } from "./pages/PatientInsuranceInfo";
import { OrderInfo } from "./pages/OrderInfo";
import { ArrowRight, Check } from "lucide-react";
import { ClinicProviderInfoFields } from "../new_case/ClinicProviderInfoFields";
import { setCptCodesMutator } from "../new_case/TestAndRequestInfoHelperMethods";
import { setNpiDataMutator } from "../new_case/ProviderInfoFields";
import { api, redirectTo } from "../util/api";
import { Alert } from "reactstrap";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";

export const NewCaseFormShadowbox = ({
  draftId,
  draftData,
  hasDraft = false,
  updateUrl,
  createUrl,
  clinicProvidersUrl,
  uninsuredPatientPayor,
}) => {
  const [resourceId, setResourceId] = useState(draftId);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [doUpdates, setDoUpdates] = useState(hasDraft);

  const initialValues = useMemo(() => ({ ...draftData, orderedWorkflows: "benefitsInvestigation" }), [draftData]); // eslint-disable-line react-hooks/exhaustive-deps

  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = parseInt(urlParams.get("page"), 10);

  const handleSave = async (v, isUpdate = false, doRedirect = false, page = null) => {
    const method = isUpdate ? "put" : "post";
    const saveUrl = isUpdate ? updateUrl.replace("__ID__", resourceId) : createUrl;

    try {
      setError(null);
      const res = await api[method](saveUrl, v);
      const { resourceId, nextUrl, message } = res.data;

      if (resourceId) {
        setResourceId(resourceId);
      }

      if (nextUrl) {
        if (doRedirect) {
          const messageStyle = res.status === 201 ? "success" : "info";
          redirectTo(nextUrl, message, messageStyle);
        } else {
          history.pushState(null, "", `${nextUrl}?page=${page}`);
        }
      }
    } catch (err) {
      setError(err);
    }
  };

  const onSave = async (v, page) => {
    await handleSave(v, doUpdates, false, page);

    setDoUpdates(true);
  };

  const onPublish = async (v, page) => {
    await handleSave({ ...v, submit: true }, doUpdates, true, page);
    setDoUpdates(true);
  };

  return (
    <>
      <DetailedErrorAlert error={error} fallbackMessage="There was an error while saving." />

      {message && (
        <Alert color="success" className="mb-3">
          {message}
        </Alert>
      )}

      <FormWizard
        wizardTitle="Request New Case"
        initialValues={initialValues}
        initialPage={pageParam ?? 0}
        mutators={{
          setNpiDataMutator,
          setCptCodesMutator,
        }}
        onPageTransitionSuccess={(form, values) => {
          // We can Add this logic in the future if we want :)
        }}
      >
        <FormWizardPage
          pageName="Patient &amp; Insurance Info"
          onPrevPage={onSave}
          onNextPage={onSave}
          saveButtonColor={"primary"}
          saveButtonLabel="Save &amp; Continue"
          saveButtonIcon={<ArrowRight className="ms-2" />}
        >
          {(formProps) => {
            return (
              <>
                <ClinicProviderInfoFields clinicProvidersUrl={clinicProvidersUrl} {...formProps} />
                <PatientInsuranceInfo uninsuredPatientPayor={uninsuredPatientPayor} {...formProps} />
              </>
            );
          }}
        </FormWizardPage>

        <FormWizardPage
          pageName="Order Info"
          onPrevPage={onSave}
          onNextPage={onPublish}
          saveButtonColor={"success"}
          saveButtonLabel="Submit"
          saveButtonIcon={<Check className="ms-2" />}
        >
          {(formProps) => {
            return <OrderInfo {...formProps} />;
          }}
        </FormWizardPage>
      </FormWizard>
    </>
  );
};
