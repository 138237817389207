import React, { useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { Checkbox } from "../../../components/inputs/final_form/Checkbox";
import { BoolInput } from "../../../components/inputs/final_form/SelectInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { required } from "../../../util/validators";
import { api } from "../../../util/api";
import { SITE_OF_SERVICE_OPTIONS } from "../BenefitPullTypePage";
import { objectToOptions } from "../../../util/formatters";
import { SPECIALIES } from "../../../util/data/specialities";

export const BenefitPullTypeForm = ({ handleSubmit, submitting, ...rest }) => {
  return (
    <>
      <Field
        component={SelectInput}
        label="Site of Service"
        options={SITE_OF_SERVICE_OPTIONS}
        name="siteOfService"
        required
        validate={required}
      />
      <Field
        component={AdministrateSelectInput}
        resourceUrl={`/admin/tests.json?test[direction]=asc&test[order]=commercial_name&archived=false`}
        label="Product/Procedure/Test"
        labelKey="commercial_name"
        name="testId"
        valueKey="id"
        key="test"
        validate={required}
      />
      <Field
        component={AdministrateSelectInput}
        resourceUrl={`/admin/payors.json?payor[direction]=asc&payor[order]=name&archived=false`}
        label="Payor"
        labelKey="name"
        name="payorIds"
        isMulti
        valueKey="id"
        key="payor"
      />
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/cpt_codes.json"
        label="Procedure Codes"
        name="cptCodeIds"
        isMulti
        valueKey="id"
        validate={required}
      />
      <Field
        component={SelectInput}
        options={objectToOptions(SPECIALIES)}
        label="Provider Speciality"
        name="providerSpeciality"
        validate={required}
      />
      <Field component={TextInput} label="Practice Type Code" name="practiceTypeCode" validate={required} />
      <Field component={TextInput} label="Practice Type Object" name="practiceTypeObject" validate={required} />
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting} validate={required}>
        Submit
      </LoadingButton>
    </>
  );
};
