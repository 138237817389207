import classnames from "classnames";
import _ from "lodash";
import React, { useState } from "react";
import { StyledSelect as Select } from "./StyledSelect";
import { Card, Input, ListGroup, ListGroupItem } from "reactstrap";
import { reactSelectColors } from "./ReactSelectWidget";
import { RemotePaginateSelector } from "./RemotePaginateSelector";
import { featureEnabled } from "../../util/feature";
import { produce } from "immer";

export const CPT_URL = "/codes/cpt.json";
const CPT_CODE_REGEX = /([a-zA-Z0-9]{5})(\s*[xX\*]\s*(\d+))?/;

// returns code or a list of codes
// No op if the flag is on
const expandCptCode = (code) => {
  if (featureEnabled("use_procedure_code_multiplier_column")) {
    throw "Logic issue; shouldn't be expanding with the flag on";
  }

  if (!code) {
    return null;
  }

  const codeParts = code.match(CPT_CODE_REGEX);

  if (!codeParts) {
    return null;
  }

  const baseCode = codeParts[1];
  const multiplier = codeParts[3];

  if (!baseCode) {
    return code;
  }

  if (multiplier && parseInt(multiplier, 10)) {
    return Array(parseInt(multiplier, 10)).fill(baseCode);
  }

  return [baseCode];
};

const DEFAULT_MAX = featureEnabled("use_procedure_code_multiplier_column") ? Number.MAX_SAFE_INTEGER : 600;
const DEFAULT_MIN = 1;

const expandCptCodes = (codes) => _.flatten(_.map(codes, (c) => expandCptCode(c)));

const cptsToOptions = (cpts) => {
  if (cpts.length && !cpts[0].code) {
    console.error(cpts);
    throw "Data issue, cptsToOptions called with old format?";
  }

  return _.map(cpts, (v) => ({
    value: v.id || v.code,
    label: v.code,
  }));
};

// > ["0001U", "0001U", "0001U", "0002M", "0002M"]
// < [{ id: undefined, code: "0001U", multiplier: 3 }, { id: undefined, code: "0002M", multiplier: 2 }]
const compressCptCodes = (codes) => {
  const cptCodeCounts = _.countBy(codes, _.identity);

  return _.map(_.uniq(codes), (code) => ({
    code: code,
    // We don't have the id in the legacy expanded-list format so we fallback to using the code as the id
    // This lets other code use `.id` as a unique identifier
    id: code,
    multiplier: cptCodeCounts[code],
  }));
};

// After the flag is on, we still will have old-data where every code has a multiplier of 1
// This function counts and combines these
const combineCptCodes = (codes) => {
  // Similar to _.countBy(codes, "code") but we need to save the id as well as the code and count
  const cptCounts = {};
  for (const cpt of codes) {
    if (Object.hasOwn(cptCounts, cpt.code)) {
      cptCounts[cpt.code].multiplier++;
    } else {
      cptCounts[cpt.code] = cpt;
    }
  }

  return Object.values(cptCounts);
};

// Converts our internal format ({ id: 1, code: "0001U", multiplier: 3 }) to a list of codes (["0001U", "0001U", "0001U"])
const explodeCodes = (codes) => {
  return _.flatten(_.map(codes, (c) => Array(c.multiplier).fill(c.code)));
};

const toExternalCodeList = (codes) => {
  if (featureEnabled("use_procedure_code_multiplier_column")) {
    return codes;
  } else {
    return explodeCodes(codes);
  }
};

// The internal representation this component uses is
//  [{id: 1, code: "U1100", multiplier: 1}]
// Since this has all of the information.
// This is a controlled component--it doesn't store the list of codes itself
export const CptSelector = ({
  onChange,
  value,
  disabled,
  readOnly,
  valid = true,
  options = null,
  maxCounts = null,
  minCounts = null,
  showQuantities = true,
  ...rest
}) => {
  // If the flag is on, then the value argument is in the same format as our internal representation:
  //     [{ id: 1, code: "0001U", multiplier: 3 }, { id: 2, code: "0002M", multiplier: 2 }]
  // If the flag is off, then value is in the legacy format. Most of the time this looks like:
  //     ["0001U", "0001U", "0001U", "0001U"]
  // But the legacy format could also look like ["0001Ux4"], since that format is used on some pages/serializers
  // expandCptCodes is responsible for normalizing these legacy formats
  // compressCptCodes then takes that array and converts it into the internal/new format
  const cptCodes = featureEnabled("use_procedure_code_multiplier_column")
    ? combineCptCodes(value)
    : compressCptCodes(expandCptCodes(value));

  // We can remove these validations if we want
  if (featureEnabled("use_procedure_code_multiplier_column")) {
    if (!Array.isArray(value)) {
      console.error(value);
      throw "value must be an array.";
    }
    for (let code of value) {
      if (typeof code.id !== "number") {
        console.error(code);
        throw "Ctp codes must have an integer id.";
      }
      if (typeof code.code !== "string") {
        console.error(code);
        throw "Ctp codes must have a string code.";
      }
      if (typeof code.multiplier !== "number") {
        console.error(code);
        throw "Ctp codes must have an integer multiplier.";
      }
    }
  }

  // [{ value: "123123", label: "123123"}, {value: "222", label: "222"}]
  const selectedCodes = cptsToOptions(cptCodes);

  // Get max. Supports different maxes for different codes, from maxCounts param
  // code is e.g. "0001U"
  const getMaxCountForCode = (code) => {
    return maxCounts ? (_.isObjectLike(maxCounts) ? _.get(maxCounts, code, DEFAULT_MAX) : maxCounts) : DEFAULT_MAX;
  };

  // Ditto but min
  const getMinCountForCode = (code) => {
    return minCounts ? (_.isObjectLike(minCounts) ? _.get(minCounts, code, DEFAULT_MIN) : minCounts) : DEFAULT_MIN;
  };

  // Since options doesn't include multipliers, we need the oldCtps
  //  and getMinCountForCode to create the new codes
  const optionsToCpts = (options, oldCpts) =>
    options.map(
      // If we can't find an existing code, we create a new one with the default multiplier
      (code) => oldCpts.find((c) => c.id === code.value) || { id: code.value, code: code.label, multiplier: getMinCountForCode(code.label) }
    );

  // This is called by RPS after choosing a code
  // So code is one of the currently selected options, e.g. {value: "0001U", label: "0001U"}
  // This only fires when the codes change, not when the count changes
  const handleCodeChange = (code) => {
    if (disabled || readOnly) {
      return false;
    }

    // Convert the options to the ctpCodes format
    const newCptCodes = optionsToCpts(code, cptCodes);

    onChange(toExternalCodeList(newCptCodes));
  };

  const handleCountChange = (codeId, count) => {
    if (disabled || readOnly) {
      return false;
    }

    if (_.isNaN(count)) {
      return false;
    }

    count = _.round(count);

    const cptCode = cptCodes.find((c) => c.id === codeId);

    const maxCount = getMaxCountForCode(cptCode.code);
    const minCount = getMinCountForCode(cptCode.code);

    const clampedCount = _.clamp(count, minCount, maxCount);
    // Using immer to deep copy and update cptCodes
    const newCptCodes = produce(cptCodes, (draft) => {
      draft.find((c) => c.id === codeId).multiplier = clampedCount;
    });

    onChange(toExternalCodeList(newCptCodes));
  };

  return (
    <>
      {options ? (
        <Select
          {...rest}
          styles={reactSelectColors(!valid)}
          value={selectedCodes}
          onChange={handleCodeChange}
          options={options}
          isDisabled={disabled || readOnly}
          isMulti
        />
      ) : (
        <RemotePaginateSelector
          {...rest}
          styles={reactSelectColors(!valid)}
          resourceUrl={CPT_URL}
          value={_.map(selectedCodes, "value")}
          valueKey={featureEnabled("use_procedure_code_multiplier_column") ? "id" : "code"}
          labelKey="code"
          dataKey="resources"
          initialOptions={selectedCodes}
          onChange={handleCodeChange}
          isDisabled={disabled || readOnly}
          returnOptionsFromOnChange={true}
          isMulti
        />
      )}

      {selectedCodes.length > 0 && showQuantities && (
        <Card className={classnames("bg-light", { "border-danger": !valid })} data-testid={rest["data-testid"]}>
          <ListGroup flush>
            {cptCodes.map((c) => (
              <ListGroupItem key={c.code} className={classnames({ "bg-light": disabled || readOnly })}>
                <div className="d-flex w-100 align-items-center justify-content-between">
                  <label htmlFor={c.code}>
                    <code>{c.code}</code> &times; {c.multiplier}
                  </label>
                  <div style={{ maxWidth: 150 }}>
                    <Input
                      id={c.code}
                      onInput={(e) => handleCountChange(c.id, e.target.valueAsNumber)}
                      value={c.multiplier}
                      disabled={disabled}
                      readOnly={readOnly}
                      type="number"
                    />
                  </div>
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </Card>
      )}
    </>
  );
};
