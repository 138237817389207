import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../../components/AdministrateTable";
import { History } from "../../../tools/sandbox/release/History";
import { useDestroyPermission } from "../../../utils";
import { EditFollowupTaskConfigModal } from "./EditFollowupTaskConfigModal";

export const FollowupTaskConfigActionCell = ({ row }) => {
  const [canDelete] = useDestroyPermission("FollowupTaskConfig", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();

  const handleEditSuccess = () => {
    setMessage("Updated Follow-up Task Config");
    refresh();
  };

  return (
    <>
      <EditFollowupTaskConfigModal color="link" onEdit={handleEditSuccess} followupTaskConfig={row.original} />
      {canDelete && (
        <AdministrateResourceDeleteButton
          label="Delete"
          deleteUrl={`/admin/followup_task_configs/${row.original.id}`}
          onSuccess={() => {
            setMessage("Deleted Follow-up Task Config");
            refresh();
          }}
          onError={(msg) => setError(msg)}
        />
      )}
      <History entityName={"FollowupTaskConfig"} subjectId={row.original.id} />
    </>
  );
};
