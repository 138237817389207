import React, { useEffect, useState, useRef } from "react";
import { Field, FormSpy } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required, numericInRange } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { adminApi as api } from "../../../util/api";
import { DENIED_STATUSES } from "../../../util/data/statuses";

const getSubstatusOptions = async (status, setSubstatusOptions) => {
  try {
    const { data } = await api.get(`/admin/enums/prior_auth_substatuses.json?status=${status}`);
    setSubstatusOptions(data.resources);
  } catch (err) {
    console.error(err);
  }
};

export const PortalMessageForm = ({ benefitManagementPortalId, handleSubmit, submitting, ...rest }) => {
  const [status, setStatus] = useState("");
  const [substatusOptions, setSubstatusOptions] = useState([]);
  const myRef = useRef();

  const handleStatusChange = (selected) => {
    myRef.current?.select?.clearValue();

    setStatus(selected?.value);
  };

  useEffect(() => {
    getSubstatusOptions(status, setSubstatusOptions);
  }, [status]);

  return (
    <>
      <Field component={TextInput} type="hidden" name="benefitManagementPortalId" />
      <Field component={TextInput} required validate={required} label="Portal Message" name="message" />
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/enums/submit_right_direction_options.json"
        labelKey="label"
        valueKey="id"
        label="Submit Right Direction"
        name="submitRightDirection"
        validate={required}
      />
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          return (
            <>
              {values.submitRightDirection == "task_out_for_n_number_of_days_to_try_again" && (
                <>
                  <Field component={TextInput} label="Set Task Name" name="taskName" />
                  <Field
                    component={TextInput}
                    type="number"
                    name="taskDuration"
                    label="Set Task Duration(Days)"
                    validate={numericInRange(0, 90)}
                    min="0"
                    max="90"
                  />
                </>
              )}
              {values.submitRightDirection == "status_the_case" && (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/enums/prior_auth_statuses.json"
                    label="Status"
                    name="status"
                    labelKey="label"
                    onUpdate={handleStatusChange}
                  />

                  {substatusOptions?.length >= 1 && (
                    <Field component={SelectInput} label="Substatus" name="substatus" options={substatusOptions} ref={myRef} includeBlank />
                  )}

                  {DENIED_STATUSES.includes(values.status) && (
                    <Field
                      component={AdministrateSelectInput}
                      resourceUrl="/admin/enums/denial_reason_options.json"
                      label="Denial Reason"
                      name="denialReasonCode"
                      labelKey="label"
                    />
                  )}
                </>
              )}
            </>
          );
        }}
      </FormSpy>
      <Field component={AdministrateSelectInput} resourceUrl="/admin/payors.json" label="Payor" name="payorId" searchParam="name" />
      <Field component={TextInput} label="Canned Comment" name="cannedComment" />
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
