import React from "react";
import { CollapsePanel } from "./CollapsePanel";
import { DetailGrid, DetailGridItem } from "./DetailGrid";

export const InsuranceInfo = ({ insuranceInfo }) => {
  return (
    <CollapsePanel labelText="Insurance Info">
      <DetailGrid>
        <DetailGridItem title="Insurance" value={insuranceInfo?.name} />
        <DetailGridItem title="Verified Insurance" value={insuranceInfo?.verifiedName} />
        <DetailGridItem title="Member ID" value={insuranceInfo?.memberId} />
        <DetailGridItem title="Active Coverage" value={insuranceInfo?.activeCoverage} />
        <DetailGridItem title="Plan Type" value={insuranceInfo?.planType} />
        <DetailGridItem title="Funding Type" value={insuranceInfo?.fundingType} />
      </DetailGrid>
    </CollapsePanel>
  );
};
