import React, { useMemo } from "react";
import { UncontrolledPopover } from "reactstrap";
import { BoolIcon } from "../../components/BoolIcon";
import { IcdPopoverContent } from "./IcdPopoverContent";
import {
  cptRequiresGCForPayor,
  cptRequiresPaForPayor,
  cptSettingsForPayor,
  findPolicyForCptCode,
  icdCodesForPolicyCoverage,
} from "./utils";

const getPriorAuthLabel = (requiresPa, hasCptSetting) => {
  if (requiresPa) return "Yes";
  if (hasCptSetting) return "No";

  return (
    <>
      No<span className="text-danger ms-1">*</span>
    </>
  );
};

export const PayorRow = ({ group, payor, code, ...props }) => {
  // Check whether this code requires prior auth with this payor
  const requiresPriorAuth = useMemo(() => cptRequiresPaForPayor(payor, code), [payor, code]);

  const hasCptSettings = useMemo(() => cptSettingsForPayor(payor, code).length > 0, [payor, code]);

  const requiresGC = useMemo(() => cptRequiresGCForPayor(payor, code), [payor, code]);

  // Find the first policy that has a policy coverage that matches this row's CPT code
  const relevantPolicy = useMemo(() => findPolicyForCptCode(group, code), [group, code]);

  // Get all the ICD10 Codes that are covered by the relevant policy
  const policyCoveredIcdCodes = useMemo(() => {
    return relevantPolicy ? icdCodesForPolicyCoverage(relevantPolicy) : [];
  }, [relevantPolicy]);

  return (
    <tr {...props}>
      <td>{/* group name */}</td>
      <td>{payor.name}</td>
      <td>
        <span className="token">{code.code}</span>
      </td>
      <td>{/* covered */}</td>
      <td>
        <BoolIcon value={requiresPriorAuth} /> {getPriorAuthLabel(requiresPriorAuth, hasCptSettings)}
      </td>

      <td>
        <BoolIcon value={requiresGC} /> {requiresGC ? "Yes" : "No"}
      </td>

      <td id={`policy-cell-${group.id}-${payor.id}`}>
        <PolicyDisplay policy={relevantPolicy} />

        {policyCoveredIcdCodes.length > 0 && (
          <UncontrolledPopover trigger="hover" placement="left" target={`policy-cell-${group.id}-${payor.id}`}>
            <IcdPopoverContent codes={policyCoveredIcdCodes} />
          </UncontrolledPopover>
        )}
      </td>
    </tr>
  );
};

const PolicyDisplay = ({ policy }) => {
  if (policy?.reference_link) {
    return (
      <a href={policy.reference_link} target="_blank" rel="noopener noreferrer">
        {policy?.name || policy.reference_link}
      </a>
    );
  }

  if (policy?.name) {
    return policy.name;
  }

  return null;
};
