import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { Checkbox } from "../../../components/inputs/final_form/Checkbox";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { camelizeKeys, snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { GC_REQUIREMENT_OPTIONS } from "../pa_requirements/components/PaRequirementsList";

export const CreatePaRequirementModal = ({ onCreate, labSpecific = false, groupSpecific = false, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);

    try {
      const res = await api.post("/admin/payor_cpt_codes_settings", snakifyKeys({ payorCptCodesSetting: values }));

      if (onCreate) {
        onCreate(camelizeKeys(res.data.resource));
      }
      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Setting...
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add PA Requirement Setting...</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/payors.json"
                    required
                    validate={required}
                    label="Payor"
                    name="payorId"
                    searchParam="name"
                  />
                  {labSpecific && (
                    <Field component={AdministrateSelectInput} resourceUrl="/admin/labs.json" label="Lab" name="labId" searchParam="name" />
                  )}
                  {groupSpecific && <Field component={TextInput} label="Group ID" name="groupid" />}
                  <Field id="allCptCodes" component={Checkbox} label="Apply to All CPT Codes" name="allCptCodes" type="checkbox" />
                  <Field name="allCptCodes" subscription={{ value: true }}>
                    {({ input: { value } }) =>
                      !value && (
                        <Field
                          component={AdministrateSelectInput}
                          resourceUrl="/admin/cpt_codes.json"
                          label="CPT Code"
                          name="cptCodeId"
                          required
                          subscription={{ value: true }}
                        />
                      )
                    }
                  </Field>

                  <Field
                    type="checkbox"
                    component={Switch}
                    required
                    label="In Network Requires Prior Auth?"
                    name="requiresPaInNetwork"
                    id="requiresPaInNetwork"
                  />
                  <Field
                    type="checkbox"
                    component={Switch}
                    required
                    label="Out of Network Requires Prior Auth?"
                    name="requiresPaOutOfNetwork"
                    id="requiresPaOutOfNetwork"
                  />

                  <Field
                    component={SelectInput}
                    label="Genetic Counseling Requirement"
                    name="gcRequirement"
                    includeBlank
                    options={GC_REQUIREMENT_OPTIONS}
                  />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
