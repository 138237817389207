import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission } from "../../utils";
import { DiscardLabModal } from "./DiscardLabModal";

export const LabActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDelete] = useDestroyPermission("Lab", row.original.id);

  const onLabDelete = () => {
    setMessage(`Deleted ${row.original.labName}`);
    refresh();
  };

  return (
    <>
      {canDelete && (
        <DiscardLabModal
          labSlug={row.original.slug}
          labName={row.original.labName}
          onDelete={onLabDelete}
          color="link"
          className="text-danger"
        />
      )}
    </>
  );
};
