const clinicOpsSupportNeedeForm = document.querySelector("#clinic-ops-support-needed-form");
export function initClinicOpsSupportNeeded() {
  if (clinicOpsSupportNeedeForm) {
    commentPublicValue();
  }
}

const commentPublicValue = () => {
  let commentContent = clinicOpsSupportNeedeForm.querySelector("#create_comment_form_content");
  let freeCommentContent = clinicOpsSupportNeedeForm.querySelector("#create_comment_form_free_text_content");

  if (commentContent) {
    commentContent.addEventListener("change", () => {
      let optionValue = commentContent.options[commentContent.selectedIndex].innerHTML;
      if (optionValue !== "Unverified Physician Address In AIM") {
        freeCommentContent.disabled = false;
        freeCommentContent.value = optionValue + " - ";
      } else {
        freeCommentContent.value = null;
        freeCommentContent.disabled = true;
      }
    });
  }
};
