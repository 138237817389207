import _ from "lodash";
import React, { useMemo, useState, useCallback } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { formatDateTime, parseCurrency } from "../../../util/formatters";
import { snakifyKeys } from "../../../util/helpers";
import { TestForm } from "./TestForm";
import arrayMutators from "final-form-arrays";

export const EditTestModal = ({ test, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    const newValues = _.cloneDeep(values);

    const { labTestMappings } = values;
    const currentAliases = test?.labTestMappings || [];
    const formIds = _.flatMap(labTestMappings, "id");
    setError(null);

    // The list of elements removed
    const aliasesToDelete = currentAliases
      .filter((a) => {
        return !formIds.includes(a.id);
      })
      .map((alias) => {
        return { ...alias, _destroy: true };
      });

    // Full List to update
    const updateAliases = _.concat(labTestMappings, aliasesToDelete);

    try {
      if (values.cashPayPricingCents) {
        newValues.cashPayPricingCents = parseCurrency(newValues.cashPayPricingCents) * 100;
      }
      if (!values.cptCodeIds) {
        newValues.cptCodeIds = [];
      }
      const params = {
        test: {
          ...newValues,
          labTestMappingsAttributes: updateAliases,
        },
      };

      const res = await api.patch(`/admin/tests/${test.id}`, snakifyKeys(params));

      if (onUpdate) {
        onUpdate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };
  const initialValues = useMemo(
    () => ({
      ...test,
      cashPayPricingCents: test.cashPayPricingCents ? test.cashPayPricingCents / 100 : undefined,
      testCategoryId: test.testCategory?.id,
      allPortals: test.benefitManagementPortals?.length == 0,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(test)]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Edit Test</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <dl>
            <dt>Created At</dt>
            <dd>{formatDateTime(test.createdAt)}</dd>

            <dt>Last Updated At</dt>
            <dd>{formatDateTime(test.updatedAt)}</dd>
          </dl>

          <Form onSubmit={handleCreate} mutators={{ ...arrayMutators }} initialValues={initialValues}>
            {(props) => <TestForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
