import { useCallback } from "react";
import { CountCell } from "../../../components/grid/cells/CountCell";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission, useUpdatePermission } from "../../utils";
import { AddInvalidMemberIdModal } from "./components/AddInvalidMemberIdModal";
import { InvalidMemberIdActionCell } from "./components/InvalidMemberIdActionCell";
import { ImportInvalidMemberIdsModal } from "./components/ImportInvalidMemberIdsModal";
import { History } from "../../tools/sandbox/release/History";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "Id",
  },
  {
    id: "invalid_member_id",
    accessor: "invalidMemberId",
    Header: "Member Id",
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: InvalidMemberIdActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const InvalidMemberIdsIndex = () => {
  const [canCreate] = useCreatePermission("InvalidMemberId");
  const columns = useColumns();
  const extraParams = { kept: true };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  const onAdd = useCallback(
    (message, errorDetails = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, errorDetails.length ? "warning" : "success", errorDetails);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Invalid Member Id Management">
      <Toolbar className="align-items-center mb-3">
        <History entityName="InvalidMemberId" className="me-2" />
        {canCreate && (
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/invalid_member_ids/export"
            filters={allFilters}
            ref={tableRef}
          />
        )}
        {canCreate && <ImportInvalidMemberIdsModal outline className="me-2" onImport={onAdd} />}
        {canCreate && <AddInvalidMemberIdModal onAdd={onAdd} color="primary" />}
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/invalid_member_ids.json"}
        resourceName={"invalid_member_id"}
        extraParams={extraParams}
        onFilteredChange={setTableFilters}
        permissions={[UPDATE, DESTROY]}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
