import _ from "lodash";
import React, { useState, useMemo } from "react";
import { Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { parseCurrency } from "../../../util/formatters";
import { LabIdentifierForm } from "./LabIdentifierForm";
import { ADDRESS_FIELDS } from "../../../components/inputs/final_form/AddressFields";

export const EditLabIdentifierModal = ({ labIdentifier, onEdit, ...rest }) => {
  const rekeyValues = (values) => {
    const newPayload = _.cloneDeep(values);
    newPayload.addressAttributes = _.pick(values, ADDRESS_FIELDS);
    return _.omit(newPayload, ADDRESS_FIELDS);
  };
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (values) => {
    const newValues = _.cloneDeep(values);

    setError(null);
    try {
      const res = await api.patch(`/admin/lab_identifiers/${labIdentifier.id}`, {
        lab_identifier: snakifyKeys(rekeyValues(newValues)),
      });

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const initialValues = {
    ...labIdentifier,
    payorId: labIdentifier.payor?.id,
    testIds: labIdentifier.tests.map((t) => t?.id),
    benefitManagementPortalId: labIdentifier.benefitManagementPortal?.id,
    street: labIdentifier.address?.street,
    street2: labIdentifier.address?.street2,
    city: labIdentifier.address?.city,
    zip: labIdentifier.address?.zip,
    country: labIdentifier.address?.country,
    state: labIdentifier.address?.state,
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Lab Identifier</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={handleEdit} initialValues={initialValues}>
            {(props) => <LabIdentifierForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
