import { Plus, Trash } from "lucide-react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Button, Col, Container, FormGroup, Row } from "reactstrap";
import { Fieldset } from "../../../../components/inputs/final_form/Fieldset";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../../components/LoadingButton";
import { distinctValues, required } from "../../../../util/validators";

export const GlossaryForm = ({ handleSubmit, submitting, pristine }) => {
  return (
    <>
      <Field component={TextInput} required validate={required} label="Term" name="term" />
      <Field component={TextInput} type="textarea" rows="8" required validate={required} label="Definition" name="definition" />

      <Fieldset legend="Aliases">
        <FieldArray name="clinicalGlossaryAliases">
          {({ fields }) => (
            <Container>
              {fields.map((name, index) => (
                <FormGroup key={name} row>
                  <Col xs="10">
                    <Field component={TextInput} name={`${name}.term`} validate={distinctValues("clinicalGlossaryAliases", "term")} />
                  </Col>
                  <Col xs="2">
                    <Button color="danger" outline className="pull-right" onClick={() => fields.remove(index)}>
                      <Trash />
                    </Button>
                  </Col>
                </FormGroup>
              ))}
              <Row>
                <Button color="primary" onClick={() => fields.push({ term: "" })} outline className="ms-auto">
                  <Plus className="me-2" />
                  Add Alias
                </Button>
              </Row>
            </Container>
          )}
        </FieldArray>
      </Fieldset>
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine} className="mt-3">
        Save
      </LoadingButton>
    </>
  );
};
