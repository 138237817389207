import { createRoot } from "react-dom/client";

import { FaxesGrid } from "../../faxes_grid/index";

export const initFaxesGrid = () => {
  const gridContainer = document.getElementById("faxes_grid");

  if (gridContainer) {
    const { labId, discarded, sent, faxesUrl, isInternalUser, labList } = gridContainer.dataset;
    const root = createRoot(gridContainer);

    root.render(
      <FaxesGrid apiUrl={faxesUrl} labId={labId} discarded={discarded} sent={sent} isInternalUser={isInternalUser} labList={labList} />
    );
  }
};
