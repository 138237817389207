import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const CreatePolicyCoverageModal = ({ policy, onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);

    const { cptCodeIds, ...rest } = values;
    const createPayloads = cptCodeIds.map((cptCodeId) => snakifyKeys({ cptCodeId, ...rest }));

    try {
      // Do all the creates
      const resValues = await Promise.allSettled(
        createPayloads.map(async (p) => {
          await api.post("/admin/policy_coverages", { policy_coverage: p });
        })
      );

      const failures = resValues.filter((v) => v.status === "rejected");
      const successes = resValues.filter((v) => v.status !== "rejected");

      if (onCreate) {
        onCreate({ failures, successes });
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add CPT Code...
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Link {policy.name} To...</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{ policyId: policy.id }}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/cpt_codes.json"
                    label="CPT Codes"
                    name="cptCodeIds"
                    required
                    validate={required}
                    isMulti
                  />

                  <Field component={AdministrateSelectInput} resourceUrl="/admin/icd10_codes.json" label="ICD10 Code" name="icd10CodeId" />
                  <Field component={TextInput} name="policyId" required validate={required} readOnly type="hidden" />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
