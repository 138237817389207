import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { EditFeeScheduleByPlanTypeModal } from "./EditFeeScheduleByPlanTypeModal";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const LabFeeScheduleByPlanTypeActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("CptFeeScheduleByPlanType", row.original.id);
  const [canUpdate] = useUpdatePermission("CptFeeScheduleByPlanType", row.original.id);

  const handleChange = (msg) => {
    setMessage(`Updated Cpt Fee Schedule By Plan Type ${row.original.id}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditFeeScheduleByPlanTypeModal color="link" onEdit={handleChange} feeSchedule={row.original} />}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/cpt_fee_schedule_by_plan_types/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Cpt Fee Schedule By Plan Type ${row.original.id}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}

      <History entityName="CptFeeScheduleByPlanType" subjectId={row.original.id} />
    </>
  );
};
