import { Check, XCircle } from "lucide-react";
import React from "react";

export const CheckmarkCell = ({ value }) => {
  const checked = !!value;
  const Icon = checked ? <Check /> : <XCircle />;
  const colorClass = checked ? "success" : "subtle";

  return (
    <div className="text-center">
      <Icon className={`text-${colorClass}`} />
    </div>
  );
};
