import { Badge, Container, Row } from "reactstrap";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { DESTROY, UPDATE, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable } from "../../components/AdministrateTable";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AddPhoneResponseModal } from "./components/AddPhoneResponseModal";
import { useCallback } from "react";
import { PhoneResponseActionCell } from "./components/PhoneResponseActionCell";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { ImportPhoneResponseModal } from "./components/ImportPhoneResponseModal";
import _ from "lodash";
import { humanizeString } from "../../../util/formatters";

const PayorNamesDisplayer = ({ payors }) => {
  return (
    <>
      {payors &&
        payors.map((payor) => (
          <li style={{ listStyle: "none" }} className="token">
            <a target="_blank" rel="noopener noreferrer" href={`/admin/system/payors/${payor.id}/general`}>
              {payor.name}
            </a>
          </li>
        ))}
      {_.isEmpty(payors) && (
        <li style={{ listStyle: "none" }} className="token">
          ALL PAYORS
        </li>
      )}
    </>
  );
};

const statusesColors = {
  approved: "success",
  partially_approved: "info",
  denied: "danger",
  received: "info",
  processing: "info",
  submitted: "info",
  missinginfo: "warning",
  medicalreview: "warning",
  completed: "success",
  none: "secondary",
  documented_clean_claim: "info",
  rejected: "danger",
  held: "info",
  duplicate: "info",
  not_required: "info",
  code_specific_result: "info",
  open: "primary",
  closed: "info",
  preprocessing: "warning",
};

export const StatusDisplayer = ({ status }) => {
  let color = statusesColors[status?.toLowerCase()];
  let selectedStatus = window.pa_statuses[status] || window.bi_statuses[status];

  return (
    <span title={selectedStatus?.name} className={`badge-${color} badge text-xs`}>
      <small>{humanizeString(selectedStatus?.name || "None")}</small>
    </span>
  );
};

export const SubstatusDisplayer = ({ substatus }) => {
  let color = substatus === "none" ? "secondary" : "info";
  let substatusString = window.pa_substatuses[substatus];

  return (
    <span title={substatusString || "None"} className={`badge-${color} badge`}>
      <small>{substatusString || "None"}</small>
    </span>
  );
};

const useColumns = makeColumns(() => [
  {
    id: "message",
    accessor: "message",
    Header: "Message",
  },
  {
    id: "submit_right_direction",
    accessor: (row) => {
      switch (row.submitRightDirection) {
        case "status_the_case":
          return "Status The Case";
        case "task_out_for_n_number_of_days_to_try_again":
          return "Task Out For N Number of Days To Try Again";
        case "proceed_to_the_next_submission":
          return "Proceed To The Next Submission";
      }
    },
    Header: "Submit Right Direction",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl={"/admin/enums/phone_response_submit_right_direction_options.json"}
        valueKey="id"
        labelKey="label"
      />
    ),
  },
  {
    id: "payors",
    accessor: "payors",
    Header: "Verified Payor Name",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" isMulti valueKey="id" labelKey="name" searchParam="name" />
    ),
    Cell: (props) => <PayorNamesDisplayer payors={props.value} />,
  },
  {
    id: "status",
    accessor: "status",
    Header: "Status",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/enums/prior_auth_statuses.json" valueKey="id" labelKey="label" />
    ),
    Cell: (props) => <StatusDisplayer status={props.value || "none"} />,
  },
  {
    id: "substatus",
    accessor: "substatus",
    Header: "Substatus",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/enums/prior_auth_substatuses.json" valueKey="value" labelKey="label" />
    ),
    Cell: (props) => <SubstatusDisplayer substatus={props.value || "none"} />,
  },
  {
    id: "canned_comment",
    accessor: "cannedComment",
    Header: "Canned Comment",
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: PhoneResponseActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PhoneResponsesIndex = () => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const columns = useColumns();
  const [canCreate] = useCreatePermission("PhoneResponse");

  const onAdd = useCallback(
    (message, errorDetails = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, errorDetails.length ? "warning" : "success", errorDetails);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Phone Responses">
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Toolbar className="ml-auto">
            {canCreate && (
              <AdministrateBackgroundExportButton
                outline
                className="me-2"
                exportUrl="/admin/phone_responses/export"
                filters={allFilters}
                ref={tableRef}
              />
            )}

            {canCreate && <ImportPhoneResponseModal outline className="me-2" onImport={onAdd} />}
            {canCreate && <AddPhoneResponseModal onAdd={onAdd} color="primary" />}
          </Toolbar>
        </Row>
      </Container>

      <AdministrateTable
        resourceUrl={"/admin/phone_responses.json"}
        resourceName={"phone_response"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
