import * as Sentry from "@sentry/react";
import { api } from "../../util/api";

function isNextButton(el) {
  return el instanceof HTMLAnchorElement && !el.classList.contains("disabled") && el.classList.contains("next-button");
}

function hiddenInput(name, value) {
  const input = document.createElement("input");
  input.name = name;
  input.value = value;
  input.type = "hidden";
  return input;
}

function formFrom(formButton) {
  const form = document.createElement("form");
  form.classList.add("hidden");
  form.action = formButton.href;
  if (formButton.dataset.method) {
    form.method = formButton.dataset.method;
  }
  form.append(
    hiddenInput(document.querySelector("meta[name=csrf-param]").content, document.querySelector("meta[name=csrf-token]").content)
  );
  return form;
}

function formDisabled(form, disabled) {
  const classListMeth = disabled ? "add" : "remove";
  form.disabled = disabled;
  form.classList[classListMeth]("disabled");
  for (var inp of form.querySelectorAll("input,button,select,textarea")) {
    inp.disabled = disabled;
    inp.classList[classListMeth]("disabled");
  }
}

function buttonDisable(button, disabled) {
  const classListMeth = disabled ? "add" : "remove";

  button.disabled = disabled;
  button.classList[classListMeth]("disabled");
}

function addAlert(type, message) {
  const html = `<div class="alert alert-${type} alert-dismissible fade show">
            ${message}
            <button type="button" data-bs-dismiss="alert" class="btn-close" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>`;
  const el = document.createElement("div");
  el.innerHTML = html;
  document.getElementById("alerts").append(el.firstChild);
}

const formSaveError = "Failed to save form, please fix errors and try again.";
const formInvalidError = "Can't save, form is invalid.";

export function initWorkflowNextButtons() {
  const workflowCard = document.getElementById("workflow-card");
  if (!workflowCard) {
    console.warn("No workflow card on workflow page.");
    return;
  }

  const workflowForm = workflowCard.querySelector(".workflow form");
  if (!workflowForm) return;

  function formDataAsString() {
    const data = new FormData(workflowForm);
    data.delete("authenticity_token");
    const urlParams = new URLSearchParams(data);
    return urlParams.toString();
  }

  const initialFormData = formDataAsString();
  function formChanged() {
    return initialFormData != formDataAsString();
  }

  workflowCard.addEventListener("click", (evt) => {
    if (isNextButton(evt.target) && formChanged()) {
      evt.preventDefault();
      evt.stopPropagation();

      const btnEl = evt.target;

      if (!workflowForm.disabled && workflowForm.checkValidity()) {
        const formData = new FormData(workflowForm);
        const methodInput = workflowForm.querySelector("input[name=_method]");
        const method = methodInput ? methodInput.value : workflowForm.method || "PATCH";

        buttonDisable(btnEl, true);
        formDisabled(workflowForm, true);

        api[method.toLowerCase()](workflowForm.action, formData, {
          responseType: "json",
          headers: {
            Accept: "application/json",
          },
        })
          .then((res) => {
            const form = formFrom(evt.target);
            document.body.append(form);
            form.submit();
          })
          .catch((err) => {
            Sentry.captureException(err);
            addAlert("danger", formSaveError);
          })
          .finally(() => {
            formDisabled(workflowForm, false);
            buttonDisable(btnEl, false);
          });
      } else {
        addAlert("warning", formInvalidError);
      }
    }
  });
}
