import { BoolCell } from "../../../components/grid/cells/BoolCell";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { Toolbar } from "../../../components/Toolbar";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { DESTROY, UPDATE, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreateUiPathBotAutomationModal } from "./components/CreateUiPathBotAutomationModal";
import { ImportUiPathBotAutomationModal } from "./components/ImportUiPathBotAutomationModal";
import { UiPathBotActionCell } from "./components/UiPathBotActionCell";
import { History } from "../../tools/sandbox/release/History";
import { humanizeString } from "../../../util/formatters";
import { CountCell } from "../../../components/grid/cells/CountCell";

const parseBotTypeColor = (botType) => {
  if (botType === undefined) return "secondary";

  switch (botType) {
    case "new_submission":
      return "info";
    case "followup_request":
      return "warning";
  }
};

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    maxWidth: 70,
  },
  {
    id: "payors.id",
    accessor: "payorNames",
    Header: "Payors",
    Cell: ({ value }) => (
      <div title={value}>
        <CountCell count={value?.length} label="Payors" />
      </div>
    ),
    Filter: (props) => <AdministrateEnumFilter {...props} isMulti resourceUrl="/admin/payors.json" valueKey="id" searchParam="name" />,
    disableSortBy: true,
    maxWidth: 100,
  },
  {
    id: "benefit_management_portal.name",
    accessor: "benefitManagementPortal.name",
    Header: "Portal",
    disableSortBy: true,
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/benefit_management_portals.json"
        valueKey="name"
        labelKey="name"
        searchParam="name"
      />
    ),
  },
  {
    id: "ui_path_bot_type",
    accessor: "uiPathBotType",
    Header: "Bot Type",
    Cell: (props) => (
      <span className={`badge badge-${parseBotTypeColor(props.value)} bg-opacity-10`}>
        <small>{humanizeString(props.value || "None")}</small>
      </span>
    ),
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/enums/ui_path_bot_types.json"
        valueKey="id"
        labelKey="label"
        searchParam="search"
      />
    ),
    maxWidth: 120,
  },
  {
    id: "ui_path_folder_name",
    accessor: "uiPathFolderName",
    Header: "Folder Name",
    Cell: TokenCell,
  },
  {
    id: "ui_path_release_name",
    accessor: "uiPathReleaseName",
    Header: "Release Name",
  },
  {
    id: "enabled",
    accessor: "enabled",
    Header: "Enabled",
    Cell: ({ value }) => <BoolCell value={value} />,
    Filter: (props) => <BoolFilter {...props} />,
    maxWidth: 80,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: UiPathBotActionCell,
    disableFilters: true,
    disableSortBy: true, //action cell
    minWidth: 220,
  },
]);

export const UiPathBotPage = () => {
  const [canCreate] = useCreatePermission("UiPathPortalConfiguration");

  const columns = useColumns();

  const onCreate = () => {
    const { refresh, setMessage } = tableRef.current;

    setMessage("Created new bot automation");
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  return (
    <SystemConfigLayout title={["Automation", "UiPath Bot Automation"]}>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/ui_path_portal_configurations/export"
            filters={allFilters}
            ref={tableRef}
          />

          <History entityName={"UiPathPortalConfiguration"} />

          {canCreate && (
            <>
              <ImportUiPathBotAutomationModal onImport={onImport} outline className="mx-2" />
              <CreateUiPathBotAutomationModal color="primary" onCreate={onCreate} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/ui_path_portal_configurations.json"}
        resourceName={"ui_path_portal_configurations"}
        permissions={[UPDATE, DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
