import { createRoot } from "react-dom/client";

import { DocumentIndex } from "../supplemental_document";

export const initSupplementalDocument = () => {
  const documentIndex = document.getElementById("supplemental-document-index");

  if (documentIndex) {
    const root = createRoot(documentIndex);
    const { proxyUrl, downloadUrl } = documentIndex.dataset;
    root.render(<DocumentIndex proxyUrl={proxyUrl} downloadUrl={downloadUrl} />);
  }
};
