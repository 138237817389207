import { useState } from "react";
import { snakifyKeys } from "../../../util/helpers";
import { api, extractErrorMessage } from "../../../util/api";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Form } from "react-final-form";
import { PayorNoteForm } from "./PayorNoteForm";

export const CreatePayorNoteModal = ({ payorId, onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);
    try {
      values.payor_id = payorId;
      const res = await api.post("/admin/payor_notes", { payor_note: snakifyKeys(values) });

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Note...
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Create New Note</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={handleCreate}>{(props) => <PayorNoteForm {...props} />}</Form>
        </ModalBody>
      </Modal>
    </>
  );
};
