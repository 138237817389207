import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { EditLabIdentifierModal } from "./EditLabIdentifierModal";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const LabIdentifierActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("LabIdentifier", row.original.id);
  const [canUpdate] = useUpdatePermission("LabIdentifier", row.original.id);

  const handleChange = (msg) => {
    setMessage("Updated LabIdentifier");
    refresh();
  };

  return (
    <>
      {canUpdate && <EditLabIdentifierModal color="link" onEdit={handleChange} labIdentifier={row.original} />}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/lab_identifiers/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage("Deleted lab identifier");
          }}
          onError={(msg) => setError(msg)}
        />
      )}
      <History entityName={"LabIdentifier"} subjectId={row.original.id} />
    </>
  );
};
