import { createRoot } from "react-dom/client";
import { ClinicMappingModal } from "../components/ClinicMappingModal";

export function initClinicMappingModal() {
  const container = document.getElementById("clinic_mapping_modal");

  if (container) {
    const root = createRoot(container);
    const { caseId, buttonProps } = container.dataset;
    root.render(<ClinicMappingModal caseId={caseId} buttonProps={JSON.parse(buttonProps)} />);
  }
}
