import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";

const INSURANCE_TYPE_OPTIONS = [
  { value: "FULLY_FUNDED", label: "FULLY FUNDED" },
  { value: "SELF_FUNDED", label: "SELF FUNDED" },
  { value: "UNKNOWN", label: "UNKNOWN" },
];

export const ClinicLabRedirectionForm = ({ handleSubmit, submitting, create = true }) => {
  return (
    <>
      <Field component={TextInput} type="hidden" name="clinicId" />
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/labs.json"
        searchParam="name"
        label="Lab"
        kept={true}
        required
        name="associatedLabId"
        validate={required}
      />
      <Field component={AdministrateSelectInput} resourceUrl="/admin/tests.json" label="Test" name="testId" />
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/payors.json"
        searchParam="name"
        label="Insurance Name"
        kept={true}
        name="payorId"
      />
      <Field component={SelectInput} label="Insurance Type" options={INSURANCE_TYPE_OPTIONS} name="fundingType" includeBlank />
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/labs.json"
        searchParam="name"
        label="Redirected Lab"
        kept={true}
        required
        name="redirectedLabId"
        validate={required}
      />
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
