import { useMemo, useState } from "react";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { AuthTokenForm } from "./AuthTokenForm";
import { snakifyKeys } from "../../../util/helpers";
import { Form } from "react-final-form";
import { Card } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";

export const AuthTokenGeneralTab = ({ authToken, authTokenId, refresh }) => {
  const [error, setError] = useState(null);

  const initialValues = useMemo(
    () => ({
      ...authToken,
      authableLabs: authToken?.authableLabs.map((lab) => lab.id),
      authableClinics: authToken?.authableClinics.map((clinic) => clinic.id),
    }),
    [authToken]
  );

  const handleUpdate = async (params) => {
    setError(null);

    try {
      const res = await api.patch(`/admin/auth_tokens/${params.id}`, snakifyKeys({ auth_token: params }));
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <Card className="border-0 p-2">
      {error && <DetailedErrorAlert error={error} />}

      <Form onSubmit={handleUpdate} initialValues={initialValues}>
        {(props) => <AuthTokenForm {...props} token={authToken} />}
      </Form>
    </Card>
  );
};
