import { useCallback } from "react";
import { Link } from "react-router-dom";
import { CountCell } from "../../components/grid/cells/CountCell";
import { Toolbar } from "../../components/Toolbar";
import { AdministrateBackgroundExportButton } from "../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { Routes } from "../Routes";
import { makeColumns, useAdministrateTable } from "../utils";
import { AddGroupModal } from "./components/AddGroupModal";
import { ImportGroupsModal } from "./components/ImportGroupsModal";
import { GroupActionCell } from "./components/GroupActionCell";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    disableSortBy: true,
  },
  {
    id: "team_name",
    accessor: "teamName",
    Header: "Group Name",
    Cell: ({ row, value }) => <Link to={Routes.group.url(row.original.id)}>{value}</Link>,
    disableSortBy: true,
  },
  {
    id: "group_lead",
    accessor: "leader.fullNameOrEmail",
    Header: "Group Lead",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: "group_type",
    accessor: "groupType",
    Header: "Group Type",
    disableSortBy: true,
  },
  {
    id: "member_count",
    accessor: "memberCount",
    Header: "Members",
    Cell: ({ row, value }) => <CountCell count={value} label="Members" />,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: GroupActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const GroupsIndexPage = () => {
  const columns = useColumns();

  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  const onImport = useCallback(
    (message, details = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, details.length ? "warning" : "success", details);
    },
    [tableRef]
  );

  const onAdd = useCallback(
    (values) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`New Group "${values.teamName}" Added.`);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="User Groups">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <ImportGroupsModal outline className="me-2" onImport={onImport} />

          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/groups/export"
            filters={allFilters}
            ref={tableRef}
          />

          <AddGroupModal onAdd={onAdd} />
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/groups.json"}
        resourceName={"account_groups"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
