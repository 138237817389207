import React, { useState } from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { EditTestRepositoryModal } from "./EditTestRepositoryModal";

export const TestRepositoryActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();

  return (
    <EditTestRepositoryModal
      color="link"
      testRepository={row.original}
      onUpdate={() => {
        refresh();
        setMessage(`Updated Test ${row.original.commercialName}`);
      }}
    />
  );
};
