import { Field, Form } from "react-final-form";
import { Badge, Button, Card, Collapse, FormText } from "reactstrap";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { LoadingButton } from "../components/LoadingButton";
import { useCallback, useMemo, useState } from "react";
import { api } from "../util/api";
import { snakifyKeys } from "../util/helpers";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { Check } from "lucide-react";
import { OnChange } from "react-final-form-listeners";
import _ from "lodash";

const MI_CATEGORIES_MAP = {
  "Missing ICD10 Code(s)": ["verified_icd10_codes"],
  "Demographic clarification (Name & DOB)": [
    "request_patient.first_name",
    "request_patient.last_name",
    "request_patient.gender",
    "request_patient.dob",
  ],
  "Missing supervising MD NPI": ["request_provider.npi"],
  "Missing Member ID": ["primary_request_insurance.memberid"],
  "Missing primary insurance information": [
    "primary_request_insurance.insurance_name",
    "primary_request_insurance.provided_payor_id",
    "payor_id",
  ],
};

const createOptions = (categories) => {
  return categories.map((category) => ({
    label: category.name,
    value: category.id,
  }));
};

export const MissingInfoCategoriesPicker = ({ caseId, missingFields, missingInfoCategories, saveUrl, initialCategories }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const miCategoriesOptions = useMemo(
    () => createOptions(missingInfoCategories.filter((category) => category.active)),
    [missingInfoCategories]
  );

  const currentMissingFields = useMemo(() => missingFields.filter((field) => field.missing), [missingFields]);

  const matches = useMemo(
    () =>
      Object.keys(MI_CATEGORIES_MAP).filter((key) => {
        let fields = currentMissingFields.filter((field) => MI_CATEGORIES_MAP[key].includes(field["field_key"]));

        if (fields.length > 0) return key;
      }),
    [currentMissingFields]
  );

  const suggestedFields = useMemo(
    () => miCategoriesOptions.filter((option) => matches.includes(option.label)),
    [miCategoriesOptions, matches]
  );

  const initialValues = useMemo(
    () => ({
      categories: _.uniq([...initialCategories, ...suggestedFields.map((field) => field.value)]),
    }),
    [initialCategories, suggestedFields]
  );

  const toggle = () => setIsOpen((prev) => !prev);

  const handleSubmit = async ({ categories }) => {
    try {
      setSuccess(false);
      setError(null);

      const params = {
        caseId: caseId,
        missing_info_category_ids: categories,
      };

      setIsSubmitting(true);

      const request = await api.post(saveUrl, snakifyKeys(params));

      if (request.status == 200) setSuccess(true);
    } catch (err) {
      setError(err);
    }
    setIsSubmitting(false);
  };

  const handleCategoryChange = (form, value) => {
    form.change("categories", value);
    setSuccess(false);
  };

  const hasNewFields = !initialCategories.includes(...suggestedFields.map((field) => field.value)) && suggestedFields.length > 0;

  return (
    <Card className="alert alert-warning mb-3">
      <h5 className="card-title">Select Missing Info Category(s):</h5>

      {error && <DetailedErrorAlert error={error} />}

      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit, form, submitSucceeded, submitting }) => {
          return (
            <>
              <Field
                component={SelectInput}
                name="categories"
                label="Available Options"
                placeholder="Choose or search..."
                options={miCategoriesOptions}
                isMulti
                validate={(value) => (value.length ? undefined : "Please select a Missing Info Category")}
              >
                {success && (
                  <FormText>
                    Submitted successfully! <Check size={14} color="green" />
                  </FormText>
                )}

                {hasNewFields && !submitSucceeded && !submitting && <FormText>System suggested options, submit to save...</FormText>}
              </Field>

              <OnChange name="categories">{(value) => handleCategoryChange(form, value)}</OnChange>

              <div className="d-flex flex-row justify-content-between">
                {suggestedFields && suggestedFields.length > 0 && (
                  <Button color="secondary" onClick={toggle} title="Show System Auto Detected Missing Information...">
                    Show Auto Detected <Badge className="ms-2">{suggestedFields.length}</Badge>
                  </Button>
                )}

                <LoadingButton
                  loading={isSubmitting}
                  type="button"
                  onClick={handleSubmit}
                  title="Assign Missing Info Categories to this case..."
                >
                  Submit
                </LoadingButton>
              </div>
            </>
          );
        }}
      </Form>

      {suggestedFields && (
        <Collapse isOpen={isOpen}>
          <div className="bg-secondary bg-opacity-10 px-3 py-2 mt-3 rounded-1">
            {suggestedFields &&
              suggestedFields.length > 0 &&
              suggestedFields.map((field) => <li key={`${field.value}-${field.label}`}>{field.label}</li>)}
          </div>
        </Collapse>
      )}
    </Card>
  );
};
