import React from "react";
import _ from "lodash";
import cn from "classnames";
import { ArrowLeft } from "lucide-react";

// This component matches the _page_header.html partial

const SequentialHeader = ({ headerParts, ...rest }) => {
  const parts = _.castArray(headerParts);

  return (
    <h1 {...rest}>
      {parts.map((p, idx) => {
        const isLastPart = idx === parts.length - 1;
        return (
          <span key={p}>
            <span>{p}</span>

            {!isLastPart && <small className="text-muted mx-2 d-inline-block">&rsaquo;</small>}
          </span>
        );
      })}
    </h1>
  );
};

export const PageHeader = ({ header, subContent, actions, secondaryActions, simple = false, onBack }) => (
  <header className={cn("page-header mb-3 clearfix", simple && "page-header--simple")}>
    {onBack && (
      <div className="page-header__back-button">
        <ArrowLeft onClick={onBack} />
      </div>
    )}

    <div className="header-col">
      <div className="d-flex align-items-center">
        <SequentialHeader headerParts={header} className="h3 page-header__title me-3" />
        {secondaryActions}
      </div>

      {subContent && <div className="page-header__sub">{subContent}</div>}
    </div>

    {actions && (
      <div className="header-col text-end">
        <div className="page-header__sub">{actions}</div>
      </div>
    )}
  </header>
);
