import React, { useState, useCallback } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { api, extractErrorMessage } from "../../../util/api";
import { required } from "../../../util/validators";
import { snakifyKeys } from "../../../util/helpers";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { PortalMessageForm } from "./PortalMessageForm";

export const AddPortalMessageModal = ({ portal, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = useCallback(
    async (values) => {
      setError(null);
      try {
        const { data } = await api.post(`/admin/portal_messages/`, { portal_message: snakifyKeys(values) });

        if (data) {
          onAdd(data);
        }

        setOpen(false);
      } catch (err) {
        setError(extractErrorMessage(err));
      }
    },
    [onAdd]
  );

  const formValidation = (values) => {
    const errors = {};

    if (values.submitRightDirection == "status_the_case" && !values.status) {
      errors.status = "Status is required";
    } else {
      return {};
    }

    return errors;
  };
  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Portal Message
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add Portal Message</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} validate={formValidation} initialValues={{ benefitManagementPortalId: portal.id }}>
            {(props) => <PortalMessageForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
