import $ from "jquery";

export const initClonePolicies = () => {
  const container = document.getElementById("clone-payor-policies");
  let payorId = null;

  if (container != null) {
    $("#clone_policies_payor_id").change(function () {
      payorId = $("#clone_policies_payor_id").val();

      fetchPolicies(payorId);
    });
  }
};

const fetchPolicies = async (payorId) => {
  if (!payorId) {
    return false;
  }

  const url = `/admin/payors/associated_policies`;
  $.ajax(url, {
    method: "GET",
    data: {
      id: payorId,
    },
    success({ policies }) {
      showClonedPolicies(policies);
    },
    error() {
      console.error("Error fetching policies");
    },
  });
};

const showClonedPolicies = (policies) => {
  let innerHtml = "Note: ";

  if (policies.length) {
    innerHtml += policies.slice(0, policies.length - 1).join(", ") + " and " + policies.slice(-1) + " will be cloned.";
  } else {
    innerHtml += "There are no policies for this payor to be cloned";
  }
  $("#cloned-policies").html(innerHtml);
};
