import _ from "lodash";
import React from "react";
import { RemotePaginateSelector } from "./RemotePaginateSelector";
export const CLINIC_URL = "/clinics.json";

export const ClinicSelector = ({ valueSelector = "id", labelSelector = "clinic_name", ...rest }) => {
  return (
    <>
      <RemotePaginateSelector
        {...rest}
        resourceUrl={CLINIC_URL}
        valueKey={valueSelector}
        labelKey={labelSelector}
        isDisabled={rest.disabled || rest.readOnly}
        dataKey="rows"
      />
    </>
  );
};
