import cn from "classnames";
import React, { useEffect, useState } from "react";
import { api } from "../util/api";
import { useDebounced } from "../util/hooks";

const useInvalidMemberId = (initialMemberId) => {
  const [isValid, setIsValid] = useState(true);
  const [memberId, setMemberId] = useState(initialMemberId);

  const checkMemberId = useDebounced(async (newMemberId) => {
    const res = await api.get(`/invalid_member_id/check_invalid?member_id=${newMemberId}`);
    setIsValid(!res.data.is_invalid);
  });

  // When the memberId changes, make a network request to check if it's on our black-list of invalid member ids
  useEffect(() => {
    checkMemberId(memberId);
  }, [memberId, checkMemberId]);

  return [isValid, memberId, setMemberId];
};

export const MemberIdInput = ({ initialMemberId, selectPayorForm }) => {
  const [isValid, memberId, setMemberId] = useInvalidMemberId(initialMemberId);

  useEffect(() => {
    selectPayorForm.dispatchEvent(new CustomEvent(`member_id-changed`));
  }, [isValid, selectPayorForm]);

  return (
    <div className="form-group string optional case_primary_request_insurance_memberid form-group-valid">
      <label className="form-control-label string optional" htmlFor="case_primary_request_insurance_attributes_memberid">
        Member ID
      </label>
      <input
        className={cn("form-control string optional", !isValid && "is-invalid")}
        type="text"
        value={memberId}
        onChange={(evt) => {
          setMemberId(evt.target.value);
        }}
        name="case[primary_request_insurance_attributes][memberid]"
        id="case_primary_request_insurance_attributes_memberid"
      />
      <div className="invalid-feedback">This may be an invalid member ID, please check the requisition.</div>
    </div>
  );
};
