import { Trash } from "lucide-react";
import { useState } from "react";
import { LoadingButton } from "../../components/LoadingButton";
import { api } from "../../util/api";
import cx from "classnames";

export const ArchiveButton = ({ id, archived, classNames, ...props }) => {
  const [loading, setLoading] = useState(false);
  const url = `/cases/${id}/toggle_archived`;
  const handleClick = async (e) => {
    /* TODO: something about this trigger causes a warning about updating an unmounted component,
    but we reload the page anyway so :shrug: */
    e.preventDefault();
    setLoading(true);

    try {
      await api.post(url);
      window.location.reload();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      outline
      className={cx("btn-icon", classNames)}
      onClick={handleClick}
      type="button"
      size="sm"
      color="white"
      loading={loading}
      {...props}
    >
      <Trash size={16} />
    </LoadingButton>
  );
};
