import React from "react";
import { TokenCell } from "../components/grid/cells/TokenCell";
import { VIEW_REQUEST } from "../components/grid/columns";
import { DateFilter } from "../components/inputs";
import { formatDateTime } from "../util/formatters";
import { PartialClinicMatchActionsCell } from "./PartialClinicMatchActionsCell";

export const COLUMNS = [
  VIEW_REQUEST,
  {
    Header: "Public ID",
    id: "public_id",
    accessor: "public_id",
    Cell: TokenCell,
    width: 175,
  },
  {
    Header: "Provider NPI",
    accessor: "provider_npi",
  },
  {
    Header: "Provider Practice Name",
    accessor: "provider_practice_name",
  },
  {
    Header: "External Clinic",
    accessor: "external_clinic_name",
  },
  {
    Header: "External Clinic Phone",
    accessor: "external_clinic_phone",
  },
  {
    Header: "External Clinic Zip",
    accessor: "external_clinic_zip_code",
  },
  {
    Header: "Potential Clinic",
    id: "clinic",
    accessor: "potential_clinic_name",
    Cell: ({ row }) => <a href={`/admin/clinics/${row.original.potentialClinicId}`}>{r.original.potential_clinic_name}</a>,
  },
  {
    Header: "Uploaded Date",
    id: "created_at",
    accessor: (r) => formatDateTime(r.created_at),
    Filter: (props) => <DateFilter {...props} />,
    width: 175,
  },
  {
    Header: "",
    disableFilters: true,
    disableSortBy: true,
    Cell: PartialClinicMatchActionsCell,
  },
];
