import React from "react";
import { useSelectInputHandlers } from "./final_form/SelectInput";
import { StyledSelect as Select } from "./StyledSelect";

export const EnumFilter = ({ options, column: { filterValue, setFilter }, ...rest }) => {
  const { filterFunc, selectFunc, handleChange } = useSelectInputHandlers(rest.isMulti, filterValue, setFilter);

  return (
    <Select
      isClearable
      isSearchable
      value={filterFunc(options, selectFunc)}
      onChange={handleChange}
      options={options}
      menuPortalTarget={document.body}
      placeholder="Show All"
      {...rest}
    />
  );
};
