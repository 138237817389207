import { Glasses } from "lucide-react";
import { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DownloadButton } from "./DownloadButton";
import { FaxDestroyButton } from "./FaxDestroyButton";
import { PreviewFaxForm } from "./PreviewFaxForm";
import { FullScreenModal } from "../components/FullScreenModal";

export const PreviewButton = ({ id, fax, canAttach, canDestroy, ...props }) => {
  const [open, setOpen] = useState(false);

  const toggleModal = async () => {
    setOpen(!open);
  };

  return (
    <>
      <Button {...props} className="btn-icon" outline size="xs" title="Preview Fax" onClick={toggleModal}>
        <Glasses />
      </Button>
      <FullScreenModal
        isOpen={open}
        toggle={toggleModal}
        headerContent={
          <>
            Preview Fax
            <span>
              <DownloadButton id={id} size="lg" className="ms-2" />
              {Boolean(canDestroy) && <FaxDestroyButton id={id} size="lg" />}
            </span>
          </>
        }
        bodyContent={<PreviewFaxForm id={id} fax={fax} canAttach={canAttach} />}
      ></FullScreenModal>
    </>
  );
};
