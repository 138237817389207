import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";
import { ImportModal } from "../../components/ImportModal";
import { DetailedAlert } from "../../../components/DetailedAlert";

export const ImportPayorsModal = ({ onImport, portalId, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      benefitManagementPortalId: portalId,
      file: {
        bytes: values.file,
      },
    };

    const res = await api.post(`/admin/payor_portal_mappings/import.json`, snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message);
    }
  };

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Import Portal Messages..."
      exampleDownloadPath="/admin/payor_portal_mappings/example"
    ></ImportModal>
  );
};
