import React, { useContext } from "react";
import { Button } from "reactstrap";
import { YesNoPanel } from "../../../../components/wizards/YesNoPanel";
import { WizardContext } from "../../WizardContext";

export const CanResolveMissingInfoScreen = () => {
  const { dispatch } = useContext(WizardContext);

  const handleNoMissingInfo = () => {
    dispatch({
      type: "setAnswer",
      key: "noMissingInfo",
      value: true,
      doTransition: true,
    });
  };

  return (
    <>
      <YesNoPanel
        question="Can you resolve the missing information without contacting the lab?"
        yesAction={() => {
          dispatch({
            type: "setAnswer",
            key: "needContactLab",
            value: false,
            doTransition: true,
          });
        }}
        noAction={() => {
          dispatch({
            type: "setAnswer",
            key: "needContactLab",
            value: true,
            doTransition: true,
          });
        }}
      />

      <div className="d-flex flex-row align-items-center justify-content-center mt-3">
        <Button color="link" onClick={handleNoMissingInfo}>
          There is no Missing Info
        </Button>
      </div>
    </>
  );
};
