import React, { useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { adminApi as api } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { UiPathBotAutomationForm } from "./UiPathBotAutomationForm";

export const CreateUiPathBotAutomationModal = ({ onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const initialValues = { enabled: true };

  const toggleModal = () => setOpen(!open);

  const handleAdd = async (params) => {
    setError(null);

    try {
      const { data } = await api.post("/admin/ui_path_portal_configurations", { ui_path_portal_configuration: snakifyKeys(params) });

      if (onCreate) {
        onCreate(data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add UiPath Bot Automation
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add UiPath Bot Automation</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form initialValues={initialValues} onSubmit={handleAdd}>
            {(props) => <UiPathBotAutomationForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
