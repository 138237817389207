import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "./TextInput";
import { SelectInput } from "./SelectInput";
import { STATE_OPTIONS } from "../../../util/data/states";
import { BlankableField } from "../../../components/inputs/final_form/BlankableField";

export const ADDRESS_FIELDS = ["street", "street2", "city", "state", "zip", "country"];

export const StateSelector = (props) => <SelectInput options={STATE_OPTIONS} {...props} isClearable />;

export const CountrySelector = (props) => <SelectInput options={[{ value: "US", label: "US" }]} {...props} isClearable />;

export const AddressFields = (props) => (
  <>
    <BlankableField component={TextInput} label="Street" name="street" {...props} />
    <BlankableField component={TextInput} label="Street 2" name="street2" {...props} />
    <BlankableField component={TextInput} label="City" name="city" {...props} />
    <BlankableField component={StateSelector} label="State" name="state" {...props} />
    <BlankableField component={TextInput} label="Zip" name="zip" {...props} />
    <BlankableField component={CountrySelector} label="Country" name="country" {...props} />
  </>
);

export const EditAddressFields = (props) => (
  <>
    <BlankableField component={TextInput} label="Street" name="address.street" {...props} />
    <BlankableField component={TextInput} label="Street 2" name="address.street2" {...props} />
    <BlankableField component={TextInput} label="City" name="address.city" {...props} />
    <BlankableField component={StateSelector} label="State" name="address.state" {...props} />
    <BlankableField component={TextInput} label="Zip" name="address.zip" {...props} />
    <BlankableField component={CountrySelector} label="Country" name="address.country" {...props} />
  </>
);
