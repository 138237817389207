import { useMemo } from "react";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { UIPathSubmitForm } from "./components/UIPathSubmitForm";
import { UIPathHistory } from "./components/UIPathHistory";
import { TabbedRoutes } from "../../components/TabbedRoutes";

const useTabs = () =>
  useMemo(
    () => [
      {
        path: "send",
        label: "Send Case",
        Component: UIPathSubmitForm,
      },
      {
        path: "history",
        label: "History",
        Component: UIPathHistory,
      },
    ],
    []
  );

export const UIPathTestCasesIndex = () => {
  const tabs = useTabs();

  return (
    <SystemConfigLayout title={["Tools", "UIPath Test Cases"]}>
      <TabbedRoutes tabs={tabs} defaultTab={"send"} />
    </SystemConfigLayout>
  );
};
