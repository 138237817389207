import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { Alert } from "reactstrap";
import { PayorGroupsGeneralTab } from "./tabs/PayorGroupsGeneralTab";
import { PayorGroupsPayorsTab } from "./tabs/PayorGroupsPayorsTab";
import { useAdminResource } from "../utils";
import { TabbedRoutes } from "../components/TabbedRoutes";
import { Routes } from "../Routes";
import { PayorGroupsMedicaidFeeScheduleTab } from "./tabs/PayorGroupsMedicaidFeeScheduleTab";

const useBreadcrumbs = (pageTitle, payorGroupId) =>
  useMemo(
    () => [
      { url: Routes.payorGroups.url(), label: Routes.payorGroups.label },
      { url: Routes.payorGroup.url(payorGroupId), label: pageTitle, active: true },
    ],
    [pageTitle, payorGroupId]
  );

const usePayorGroup = (payorGroupId) => useAdminResource(`/admin/payor_groups/${payorGroupId}.json`);

const TABS = [
  {
    path: "general",
    label: "General",
    Component: PayorGroupsGeneralTab,
  },
  {
    path: "payors",
    label: "Payors",
    Component: PayorGroupsPayorsTab,
  },
  {
    path: "medicaid_fee_schedules",
    label: "Medicaid Fee Schedules",
    Component: PayorGroupsMedicaidFeeScheduleTab,
  },
];

export const PayorGroupsShowPage = () => {
  const { payorGroupId } = useParams();
  const { resource: payorGroup, error, isLoading, fetch } = usePayorGroup(payorGroupId);
  const pageTitle = payorGroup?.name || `Payor Group #${payorGroupId}`;
  const breadcrumbs = useBreadcrumbs(pageTitle, payorGroupId);

  return (
    <SystemConfigLayout title={pageTitle} showBreadcrumbs crumbs={breadcrumbs} loading={isLoading}>
      {error && <Alert color="danger">{error}</Alert>}

      <TabbedRoutes tabs={TABS} defaultTab={"general"} payorGroup={payorGroup} payorGroupId={payorGroupId} refresh={fetch} />
    </SystemConfigLayout>
  );
};
