import React from "react";
import { Button } from "reactstrap";
import { DateFilter } from "../components/inputs/DateFilter";
import { formatDateTime } from "../util/formatters";
import { TokenCell } from "../components/grid/cells/TokenCell";
import { BoolCell } from "../components/grid/cells/BoolCell";
import { BoolFilter } from "../components/inputs/BoolFilter";

const ActionCell =
  (baseUrl) =>
  ({ row }) => {
    return (
      <>
        <Button size="sm" outline color="secondary" className="me-1" href={`/${baseUrl}/${row.original.id}`}>
          Edit
        </Button>

        <Button size="sm" outline color="secondary" href={`/${baseUrl}/${row.original.id}/schema/edit`}>
          Edit Layout
        </Button>
      </>
    );
  };

const WorkflowDocumentActionCell = ActionCell("operations/fillable_workflow_documents");
const InsuranceDocumentActionCell = ActionCell("operations/fillable_insurance_documents");

export const WORKFLOW_DOCUMENT_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Lab",
    accessor: "lab.lab_name",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Workflow",
    accessor: "workflow",
    Cell: TokenCell,
  },
  {
    Header: "Document Type",
    accessor: "document_type",
    Cell: TokenCell,
  },
  {
    Header: "Created At",
    id: "created_at",
    accessor: (r) => formatDateTime(r.created_at),
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    Header: "Last Updated",
    id: "updated_at",
    accessor: (r) => formatDateTime(r.created_at),
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    Header: "",
    id: "actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: WorkflowDocumentActionCell,
    width: 200,
  },
];

export const INSURANCE_DOCUMENT_COLUMNS = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Payor",
    accessor: "payor_name",
  },
  {
    Header: "Lab",
    accessor: "lab_name",
  },
  {
    Header: "Published",
    accessor: "published",
    Cell: BoolCell,
    Filter: BoolFilter,
  },
  {
    Header: "State",
    accessor: "state",
  },
  {
    Header: "Created At",
    id: "created_at",
    accessor: (r) => formatDateTime(r.created_at),
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    Header: "Last Updated",
    id: "updated_at",
    accessor: (r) => formatDateTime(r.created_at),
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    Header: "",
    id: "actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: InsuranceDocumentActionCell,
    width: 200,
  },
];
