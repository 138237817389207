import $ from "jquery";

export const initToggleDetailsText = () => {
  const $biResultsSelector = $("#bi-result-details");

  if ($biResultsSelector.length > 0) {
    $biResultsSelector.click(() => {
      $biResultsSelector.text() === " Show Full Details "
        ? $biResultsSelector.text(" Hide Full Details ")
        : $biResultsSelector.text(" Show Full Details ");
    });
  }
};
