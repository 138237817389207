import React from "react";
import { YesNoToggle } from "../../../../components/wizards/YesNoToggle";
import { formatDate } from "../../../../util/formatters";
import { WizardContext } from "../../WizardContext";

export const HasNotReceivedSubmissionBranch = (props) => (
  <WizardContext.Consumer>
    {({ state, caseData, dispatch }) => (
      <YesNoToggle
        id="patientHadCoverage"
        value={state.answers.patientHadCoverage}
        label={
          <span>
            Did patient have coverage as of <strong>{formatDate(caseData.orderInfo.collectionDate)}</strong>?
          </span>
        }
        onChange={(v) => {
          dispatch({
            type: "setAnswer",
            key: "patientHadCoverage",
            value: v,
            doTransition: true,
          });
        }}
      />
    )}
  </WizardContext.Consumer>
);
