import $ from "jquery";
import "../../util/jquery.are-you-sure";

export const initEditSafety = () => {
  $(() => {
    $("form").areYouSure({
      message: "Are you sure you want to leave? Changes you made may be lost.",
    });
  });
};
