import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { Fieldset } from "../components/inputs/final_form/Fieldset";
import { LoadingButton } from "../components/LoadingButton";
import { api, redirectTo } from "../util/api";
import { snakifyKeys } from "../util/helpers";
import { SubmitRightTestForm } from "./SubmitRightTestForm";
import { trackEvent } from "../util/track";
import { FORM_ERROR } from "final-form";

export const SubmitRightDirectionForm = ({
  caseId,
  portalId,
  payorId,
  saveUrl,
  nextPortalUrl,
  portalName,
  request,
  tests,
  portalMessages,
  portalMessageOptions,
  denialReasonCodeOptions,
  verifiedCptCodeOptions,
  attachmentCategories,
}) => {
  const numTests = tests.length;
  const [error, setError] = useState(null);
  const [confirmedTests, setConfirmedTests] = useState(Array(numTests).fill(false));

  const getMessageObj = (message) => {
    const specificMessage = _.find(portalMessages, (f) => f.message == message && f?.payor_id == payorId);

    // returns payor specific portal message or the portal message configure for "All Payors"
    return specificMessage ? specificMessage : _.find(portalMessages, (f) => f.message == message && _.isNil(f.payor_id));
  };

  const onSubmit = async (params) => {
    const errors = validateCptCodesFields(params);
    if (errors) return errors;

    setError(null);
    params.tests.forEach((t) => (t.portalMessageId = getMessageObj(t.portalMessage).id));

    const snakeParams = {
      tests: params.tests.map((t) => snakifyKeys(_.omit(t, "portalMessage"))),
    };

    try {
      const result = await api.post(saveUrl, snakeParams);

      let shouldStatus = snakeParams.tests.some((test) => test.status);
      if (shouldStatus) {
        trackEvent("status_case_sr2", caseId, {
          step: "status_case_sr2",
        });
      }

      if (snakeParams.tests.find((test) => test.status === "medicalreview")) {
        redirectTo(`/cases/my_cases`);
      } else {
        redirectTo(result.data.meta.redirect_url);
      }
    } catch (err) {
      setError(err);
    }
  };

  const submitText = numTests > 1 ? "Submit All Responses" : "Submit Response";
  const finished = _.every(confirmedTests);

  const validateCptCodesFields = (values) => {
    let cptCodesLength = 0;
    let isCodeSpecificResult = values.tests.some((test) => test.status === "code_specific_result");

    values.tests &&
      values.tests.some((test) => test.status) &&
      values.tests.map((test) => {
        if (isCodeSpecificResult) {
          // CD-9133 Need to ensure that CPT Codes can be mixed
          cptCodesLength += test?.approvedCptCodes?.length || 0;
          cptCodesLength += test?.deniedCptCodes?.length || 0;
          cptCodesLength += test?.documentedCleanClaimCptCodes?.length || 0;
        }
      });

    if (cptCodesLength < verifiedCptCodeOptions.length && isCodeSpecificResult)
      return { [FORM_ERROR]: "Please ensure that every CPT Code is assigned to a status." };
  };

  return (
    <>
      <DetailedErrorAlert error={error} />

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, form, submitError }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Fieldset legend={`Submit to Portal ${portalName}`}>
                {tests.map((test, idx) => {
                  const header = numTests > 1 ? test.commercial_name : null;
                  return (
                    <SubmitRightTestForm
                      key={`testcard${idx}`}
                      caseId={caseId}
                      testId={test.id}
                      portalId={portalId}
                      header={header}
                      index={idx}
                      request={request}
                      getMessageObj={getMessageObj}
                      portalMessageOptions={portalMessageOptions}
                      denialReasonCodeOptions={denialReasonCodeOptions}
                      verifiedCptCodeOptions={verifiedCptCodeOptions}
                      confirmedTests={confirmedTests}
                      setConfirmedTests={setConfirmedTests}
                      setError={setError}
                      nextPortalUrl={nextPortalUrl}
                      attachmentCategories={attachmentCategories}
                    />
                  );
                })}
              </Fieldset>

              <hr />

              {submitError && <DetailedErrorAlert error={submitError} />}

              <LoadingButton className="mb-4" color="primary" loading={submitting} disabled={submitting || !finished}>
                {submitText}
              </LoadingButton>
            </form>
          );
        }}
      </Form>
    </>
  );
};
