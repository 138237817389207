import { useMemo } from "react";
import { CountCell } from "../../../components/grid/cells/CountCell";
import { Toolbar } from "../../../components/Toolbar";
import { deepCompact } from "../../../util/helpers";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { ImportPoliciesModal } from "../../policies/components/ImportPoliciesModal";
import { DESTROY, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { AddPoliciesModal } from "../components/AddPoliciesModal";
import { PayorPolicyActionCell } from "../components/PayorPolicyActionCell";

const useColumns = makeColumns((payorId) => [
  {
    id: "name",
    accessor: "name",
    Header: "Name",
  },
  {
    id: "cpt_codes",
    accessor: "cptCount",
    Header: "CPT Codes",
    Cell: ({ value }) => <CountCell count={value} label="CPT Codes" />,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" isMulti valueKey="code" labelKey="code" />,
  },
  {
    id: "icd10_codes",
    accessor: "icd10Count",
    Header: "ICD10 Codes",
    Cell: ({ value }) => <CountCell count={value} label="ICD10 Codes" />,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/icd10_codes.json" isMulti valueKey="code" labelKey="code" />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <PayorPolicyActionCell payorId={payorId} {...row} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PayorsPoliciesTab = ({ payor, payorId }) => {
  const [canCreate] = useCreatePermission("Policy");
  const columns = useColumns(payorId);

  const extraParams = useMemo(() => deepCompact({ payors: { id: payorId } }), [payorId]);
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onAddPolicies = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar>
          <div style={{ width: 300 }}></div>
        </Toolbar>

        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/policies/export"
            filters={allFilters}
            ref={tableRef}
          />

          {canCreate && (
            <>
              <ImportPoliciesModal outline className="me-2" onImport={onImport} defaultPayor={payorId} disabled={true} />
              <AddPoliciesModal payor={payor} color="primary" onAdd={onAddPolicies} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/policies.json"}
        resourceName={"policy"}
        extraParams={extraParams}
        permissions={[DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
