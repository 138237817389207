import React, { useState } from "react";
import { LoadingButton } from "../../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../../util/api";

export const AddMulitplePayorReviewsButton = ({
  label = "Mark as Reviewed",
  resourceId,
  resourceType,
  reviewType,
  onReview,
  onError,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      await api.post(`/admin/payor_cpt_codes_settings/add_multiple_reviews.json`, { payor_id: resourceId });

      if (onReview) {
        await onReview();
      }
    } catch (err) {
      console.error(err);

      if (onError) {
        onError(extractErrorMessage(err));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton onClick={handleClick} loading={loading} color="link" {...rest}>
      {label}
    </LoadingButton>
  );
};
