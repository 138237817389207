import { Link } from "react-router-dom";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable } from "../../components/AdministrateTable";
import { Routes } from "../../Routes";
import { makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { AddPortalLabModal } from "../components/AddPortalLabModal";
import { PortalLabActionCell } from "../components/PortalLabActionCell";

const useColumns = makeColumns((portalId) => [
  {
    id: "lab_name",
    accessor: "dashboardDisplayName",
    Header: "Lab",
    Cell: ({ row, value }) => (
      <Link to={Routes.lab.url(row.original.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
  },
  {
    id: "actions",
    Header: "Actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => <PortalLabActionCell portalId={portalId} {...row} />,
  },
]);

export const PortalLabsTab = ({ portal, portalId }) => {
  const [canCreate] = useCreatePermission("LabPortalMapping");
  const columns = useColumns(portalId);
  const extraParams = { benefit_management_portals: { id: portalId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onCreate = (results) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(results.meta.message);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">{canCreate && <AddPortalLabModal color="primary" portal={portal} onAdd={onCreate} />}</Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/labs.json"}
        extraParams={extraParams}
        resourceName={"lab"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
