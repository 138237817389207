import React, { useCallback, useMemo, useState } from "react";
import { StyledSelect as Select } from "../../../components/inputs/StyledSelect";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { GC_REQUIREMENT_OPTIONS } from "../pa_requirements/components/PaRequirementsList";

export const GcRequirementSelect = ({ payorName, gcRequirement, cptCodeSettingId, cptCode, onChange, onError }) => {
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(
    async (newRequirement) => {
      setLoading(true);
      try {
        await adminApi.put(
          `/admin/payor_cpt_codes_settings/${cptCodeSettingId}.json`,
          snakifyKeys({ payor_cpt_codes_setting: { gcRequirement: newRequirement?.value ?? null } })
        );

        onChange(`Set ${payorName} - ${cptCode} as ${newRequirement?.label ?? "None"}`);
      } catch (err) {
        onError(extractErrorMessage(err));
      } finally {
        setLoading(false);
      }
    },
    [cptCodeSettingId, payorName, cptCode, setLoading, onChange, onError]
  );

  const selectedOption = useMemo(() => GC_REQUIREMENT_OPTIONS.find((o) => o.value === gcRequirement), [gcRequirement]);

  return (
    <Select
      key={cptCodeSettingId}
      type="select"
      isDisabled={loading}
      isLoading={loading}
      value={selectedOption}
      onChange={handleChange}
      options={GC_REQUIREMENT_OPTIONS}
      isClearable
    />
  );
};
