import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { EditFeeScheduleByTestModal } from "./EditFeeScheduleByTestModal";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const LabFeeScheduleByTestActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("CptFeeScheduleByTest", row.original.id);
  const [canUpdate] = useUpdatePermission("CptFeeScheduleByTest", row.original.id);

  const handleChange = (msg) => {
    setMessage(`Updated Cpt Fee Schedule By Test ${row.original.test.commercialName}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditFeeScheduleByTestModal color="link" onEdit={handleChange} feeSchedule={row.original} />}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/cpt_fee_schedule_by_tests/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Cpt Fee Schedule By Test ${row.original.test.commercialName}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}

      <History entityName={"CptFeeScheduleByTest"} subjectId={row.original.id} />
    </>
  );
};
