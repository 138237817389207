import React, { useMemo } from "react";
import { Container, Row } from "reactstrap";
import { Toolbar } from "../../../components/Toolbar";
import { snakifyKeys } from "../../../util/helpers";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreatePayorContactModal } from "../components/CreatePayorContactModal";
import { ImportPayorContactModal } from "../components/ImportPayorContactModal";
import { PayorContactActionCell } from "../components/PayorContactActionCell";

const useColumns = makeColumns((payorId) => [
  {
    id: "title",
    accessor: "title",
    Header: "Contact",
  },
  {
    id: "phone_number",
    accessor: "phoneNumber",
    Header: "Phone Number",
  },
  {
    id: "fax_number",
    accessor: "faxNumber",
    Header: "Fax Number",
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <PayorContactActionCell payorId={payorId} {...row} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PayorsContactsTab = ({ payor, payorId }) => {
  const [canCreate] = useCreatePermission("PayorContact");
  const columns = useColumns(payorId);

  const extraParams = useMemo(() => snakifyKeys({ payorId }), [payorId]);
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onCreate = (newPayorContact) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created new payor ${newPayorContact.title}`);
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/payor_contacts/export"
            filters={allFilters}
            ref={tableRef}
          />

          {canCreate && (
            <>
              <ImportPayorContactModal outline className="me-2" onImport={onImport} payorId={payorId} />
              <CreatePayorContactModal color="primary" onCreate={onCreate} payorId={payorId} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/payor_contacts.json?payor_id=${payorId}`}
        resourceName={"payor_contacts"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
