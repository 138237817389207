import { useCallback, useState } from "react";
import { api, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { Form } from "react-final-form";
import { MissingInfoCategoryForm } from "./MissingInfoCategoryForm";

export const AddMissingInfoCategoryModal = ({ onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = useCallback(
    async (params) => {
      try {
        const res = await api.post("/admin/missing_info_categories", snakifyKeys(params));

        if (onAdd) {
          onAdd(res.data?.meta?.message, res.data?.meta?.error_details);
        }

        setOpen(false);
      } catch (err) {
        setError(extractErrorMessage(err));
      }
    },
    [onAdd]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add New Missing Info Category
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New Missing Info Category</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{}}>
            {(props) => <MissingInfoCategoryForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
