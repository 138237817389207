import React, { useState, useCallback } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { api, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { GlossaryForm } from "./GlossaryForm";

export const AddGlossaryModal = ({ onAdd, ...buttonProps }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = useCallback(
    async (values) => {
      setError(null);
      try {
        const params = {
          clinicalGlossary: {
            term: values.term,
            definition: values.definition,
            clinicalGlossaryAliasesAttributes: _.filter(values.clinicalGlossaryAliases, "term"),
          },
        };
        const { data } = await api.post(`/admin/clinical_glossaries/`, snakifyKeys(params));

        if (data) {
          onAdd(data.resource);
        }

        setOpen(false);
      } catch (err) {
        setError(extractErrorMessage(err));
      }
    },
    [onAdd]
  );

  return (
    <>
      <Button {...buttonProps} onClick={toggleModal}>
        Add Glossary Term
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Glossary Term</ModalHeader>
        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form
            onSubmit={handleCreate}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={{ clinicalGlossaryAliases: [{ term: "" }] }}
            keepDirtyOnReinitialize={true}
          >
            {(props) => <GlossaryForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
