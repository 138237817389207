import React from "react";
import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../../components/AdministrateTable";
import { useDestroyPermission } from "../../../utils";
import { EditStatusMappingModal } from "./EditStatusMappingModal";
import { History } from "../../../tools/sandbox/release/History";

export const StatusMappingActionCell = ({ row }) => {
  const [canDelete] = useDestroyPermission("StatusMapping", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = (msg) => {
    setMessage(`Updated ${row.original.dashboardDisplayName}`);
    refresh();
  };

  return (
    <>
      <EditStatusMappingModal color="link" onEdit={handleChange} statusMapping={row.original} />
      {canDelete && (
        <AdministrateResourceDeleteButton
          label="Remove"
          deleteUrl={`/admin/status_mappings/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Discarded ${row.original.dashboardDisplayName}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
      <History className="ms-4" entityName="StatusMapping" subjectId={row.original.id} />
    </>
  );
};
