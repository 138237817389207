import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { useTableContext } from "../../components/AdministrateTable";
import { extractErrorMessage } from "../../../util/api";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { Routes } from "../../Routes";
import { Button } from "reactstrap";

export const LabDocumentActionCell = ({ labId, ...row }) => {
  const [canDestroy] = useDestroyPermission("LabDocument", row.original.id);
  const [canUpdate] = useUpdatePermission("LabDocument", row.original.id);

  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = () => {
    setMessage(`Updated Lab Document ${row.original.id}`);
    refresh();
  };

  return (
    <>
      <Button color="link" href={Routes.lab_document.url(row.original.id)} target="_blank" rel="noopener noreferrer">
        Download
      </Button>

      {canDestroy && (
        <AdministrateResourceDeleteButton
          label="Delete"
          deleteUrl={`/admin/lab_documents/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Lab Document ${row.original.id} deleted.`);
          }}
          onError={(msg) => setError(extractErrorMessage(msg))}
        />
      )}
    </>
  );
};
