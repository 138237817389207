import React, { useState } from "react";
import { Alert, Collapse, List } from "reactstrap";

export const DetailedAlert = ({
  message,
  messageStyle = "info",
  details = [],
  className = "d-flex flex-row justify-content-between",
  alertLinkClass = "alert-link",
  ...rest
}) => {
  const [detailsOpen, setDetailsOpen] = useState(false);
  const hasDetails = details && details.length > 0;

  const showMessage = Boolean(message);

  return showMessage ? (
    <Alert color={messageStyle} {...rest}>
      <div className={className}>
        <span>{message}</span>

        {hasDetails && (
          <a href="#" className={alertLinkClass} onClick={() => setDetailsOpen(!detailsOpen)}>
            {`${detailsOpen ? "Hide" : "Show"} Details`}
          </a>
        )}
      </div>

      {hasDetails && (
        <Collapse isOpen={detailsOpen}>
          <div className="mt-2">
            <List type="unstyled">
              {details.map((d, idx) => (
                <li key={idx}>{d}</li>
              ))}
            </List>
          </div>
        </Collapse>
      )}
    </Alert>
  ) : null;
};
