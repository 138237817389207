import React from "react";
import { Field, Form } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required } from "../../../util/validators";
import { camelizeKeys } from "../../../util/helpers";

export const BoxDirectoryForm = ({ handleAction, directory }) => {
  return (
    <Form onSubmit={handleAction} initialValues={camelizeKeys({ ...directory })}>
      {({ handleSubmit, submitting }) => {
        return (
          <>
            <Field component={TextInput} required validate={required} label="Box Folder Id" name="boxFolderId" />
            <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
              Save
            </LoadingButton>
          </>
        );
      }}
    </Form>
  );
};
