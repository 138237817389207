import cn from "classnames";
import { ChevronDown, ChevronRight } from "lucide-react";
import { useState } from "react";
import { Badge, Button, Card, CardBody, Collapse } from "reactstrap";

export const CollapsePanel = ({ children, labelText, labelNum, actions, className, ...rest }) => {
  const [isCollapsed, setCollapsed] = useState(false);

  const toggleCollapse = () => {
    setCollapsed((collapsed) => !collapsed);
  };

  const ArrowIcon = isCollapsed ? ChevronRight : ChevronDown;

  return (
    <div className={cn("collapse-panel", className, "d-flex flex-column")} {...rest}>
      <div className="collapse-panel__header d-flex flex-row align-items-center mb-2">
        <Button className="btn-round collapse-panel__toggle me-3" size="sm" color="info-secondary" onClick={toggleCollapse}>
          <ArrowIcon width="16" height="16" />
        </Button>

        <h5 className="collapse-panel__title m-0">
          {labelText}

          {labelNum && (
            <Badge pill className="text-dark" color="light ms-1">
              {labelNum}
            </Badge>
          )}
        </h5>

        {actions && <div className="collapse-panel__actions ms-auto">{actions}</div>}
      </div>

      <Collapse isOpen={!isCollapsed}>
        <Card className="collapse-panel__card border-0 rounded-0">
          <CardBody>{children}</CardBody>
        </Card>
      </Collapse>
    </div>
  );
};
