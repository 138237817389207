import { useCallback, useEffect, useRef } from "react";
import { Toolbar } from "../../components/Toolbar";
import { AdministrateBackgroundExportButton } from "../components/AdministrateBackgroundExportButton";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { DESTROY, makeColumns, useAdministrateTable, useCreatePermission } from "../utils";
import { AddPayorPathModal } from "./components/AddPayorPathModal";
import { AdministrateTable } from "../components/AdministrateTable";
import { PayorPathActionCell } from "./components/PayorPathActionCell";
import { ImportPayorPathsModal } from "./components/ImportPayorPathsModal";
import { api } from "../../util/api";

const useColumns = makeColumns((letterOptions) => [
  {
    id: "path",
    accessor: "path",
    Header: "Payor Path",
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: (props) => <PayorPathActionCell letterOptions={letterOptions} {...props} />,
    disableFilters: true,
    disableSortBy: true,
    maxWidth: 150,
  },
]);

const fetchAllowedLetters = async () => {
  try {
    const request = await api.get("/admin/payor_paths/allowed_letters.json");

    return request.data.resources;
  } catch (e) {
    console.error(e);
    return e;
  }
};

export const PayorPathsIndexPage = ({}) => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const [canCreate] = useCreatePermission("PayorPath");
  const letterOptions = useRef();
  const columns = useColumns(letterOptions);

  const onAdd = useCallback(
    (values) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`New Payor Path "${values.letters.join("")}" Added.`);
    },
    [tableRef]
  );

  const onImport = useCallback(
    (message, details = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, details.length ? "warning" : "success", details);
    },
    [tableRef]
  );

  // CD-8106: Join both options to create paths.
  async function getData() {
    const letters = await fetchAllowedLetters();

    // Sorting alphabetically
    letterOptions.current = ["", ...letters].sort((a, b) => a.localeCompare(b));
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <SystemConfigLayout title="Payor Paths">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/payor_paths/export"
            filters={allFilters}
            ref={tableRef}
          />

          {canCreate && (
            <>
              <ImportPayorPathsModal outline className="me-2" onImport={onImport} />

              <AddPayorPathModal letterOptions={letterOptions} onAdd={onAdd} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/payor_paths.json"}
        resourceName={"payor_path"}
        onFilteredChange={setTableFilters}
        permissions={[DESTROY]}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
