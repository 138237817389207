import _, { noop } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, useField, useForm } from "react-final-form";
import { ConfirmModal } from "../components/ConfirmModal";
import { DateInput } from "../components/inputs/final_form/DateInput";
import { DropzoneInput } from "../components/inputs/final_form/DropzoneInput";
import { Fieldset } from "../components/inputs/final_form/Fieldset";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { LoadingButton } from "../components/LoadingButton";
import { api, redirectTo } from "../util/api";
import { snakifyKeys } from "../util/helpers";
import { numericInRange, required } from "../util/validators";
import { trackEvent } from "../util/track";
import { Card, CardText, CardTitle } from "reactstrap";
import { humanizeString } from "../util/formatters";

const REQUIRED_STATUS_FIELDS = {
  approved: ["authorizationNumber", "authorizationDate"],
  denied: [],
  documented_clean_claim: ["submissionReferenceNumber"],
  medicalreview: [],
  partially_approved: ["authorizationNumber", "authorizationDate", "approvedCptCodes"],
  submitted: [],
  rejected: [],
};

const DISPLAY_STATUS_FIELDS = {
  approved: ["authorizationNumber", "authorizationDate", "authorizationEffectiveDate", "authorizationExpirationDate"],
  denied: ["denialReasonCode", "submissionReferenceNumber"],
  documented_clean_claim: ["submissionReferenceNumber"],
  medicalreview: ["submissionReferenceNumber", "expectedCloseDate"],
  partially_approved: [
    "authorizationNumber",
    "authorizationDate",
    "authorizationEffectiveDate",
    "authorizationExpirationDate",
    "approvedCptCodes",
    "deniedCptCodes",
    "denialReasonCode",
  ],
  code_specific_result: ["approvedCptCodes", "deniedCptCodes", "documentedCleanClaimCptCodes"],
  submitted: [],
  rejected: [],
};

export const notAllCodes = (codesOptions) => (value) => {
  if (codesOptions.length === value?.length)
    return "Please do not insert all CPT Codes into one field. If you need to, use the correct Submit Right Response instead.";

  return undefined;
};

const StatusDependentTestField = ({ name, index, displayCheck, requiredCondition, displayCondition, ...props }) => {
  if (!displayCondition) {
    return null;
  }

  return <Field name={`tests[${index}].${name}`} required={requiredCondition} validate={requiredCondition ? required : noop} {...props} />;
};

const ConfirmationButton = ({ children, showModal = false, loading, disabled, onClick, onModalConfirm, ...rest }) => {
  const confirmButton = (
    <LoadingButton type="button" color="primary" loading={loading} disabled={disabled} onClick={onClick} {...rest}>
      {children}
    </LoadingButton>
  );

  return showModal ? (
    <ConfirmModal
      Target={confirmButton}
      onConfirm={onModalConfirm}
      confirmLabel="Confirm"
      title="Proceed to Next Portal"
      confirmMessage="Confirming this status will move the case to the next portal. Are you sure?"
      confirmButtonProps={{ type: "button" }}
    />
  ) : (
    confirmButton
  );
};

export const FieldsetWrapper = ({ displayCondition, labelText, children }) => {
  if (displayCondition) {
    return (
      <Card className="px-2 pt-2 pb-0 mb-3 bg-secondary bg-opacity-10 border-0">
        <CardTitle className="d-flex flex-row justify-content-between gap-2">
          <p className="fw-semibold w-fit mb-0">{labelText}</p>
        </CardTitle>

        {children}
      </Card>
    );
  }

  return <>{children}</>;
};

export const SubmitRightTestForm = ({
  caseId,
  testId,
  portalId,
  header,
  index,
  request,
  getMessageObj,
  portalMessageOptions,
  denialReasonCodeOptions,
  verifiedCptCodeOptions,
  confirmedTests,
  setConfirmedTests,
  setError,
  nextPortalUrl,
  attachmentCategories = {},
}) => {
  // form state and related effects
  const form = useForm();
  const portalMessage = useField(`tests[${index}].portalMessage`, { subscription: { value: true } })?.input?.value;

  const approvedCptCodesValue = useField(`tests[${index}].approvedCptCodes`, { subscription: { value: true } })?.input?.value;
  const deniedCptCodesValue = useField(`tests[${index}].deniedCptCodes`, { subscription: { value: true } })?.input?.value;
  const cleanClaimCptCodesValue = useField(`tests[${index}].documentedCleanClaimCptCodes`, { subscription: { value: true } })?.input?.value;

  const [confirmed, _setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approvedCodes, setApprovedCodes] = useState(verifiedCptCodeOptions);
  const [deniedCodes, setDeniedCodes] = useState(verifiedCptCodeOptions);
  const [cleanClaimCodes, setCleanClaimCodes] = useState(verifiedCptCodeOptions);

  const handleApprovedChange = (selectedCodes) => {
    const selectedValues = selectedCodes.map((code) => code.value);
    const nonSelectedValues = verifiedCptCodeOptions.filter((code) => !selectedValues.includes(code.value));

    // Filter out these values from the denied codes and clean claim
    setDeniedCodes(nonSelectedValues.filter((code) => !cleanClaimCptCodesValue.includes(code.value)));
    setCleanClaimCodes(nonSelectedValues.filter((code) => !deniedCptCodesValue.includes(code.value)));
  };

  const handleDeniedChange = (selectedCodes) => {
    const selectedValues = selectedCodes.map((code) => code.value);
    const nonSelectedValues = verifiedCptCodeOptions.filter((code) => !selectedValues.includes(code.value));

    // Filter out these values from the approved codes and clean claim
    setApprovedCodes(nonSelectedValues.filter((code) => !cleanClaimCptCodesValue.includes(code.value)));
    setCleanClaimCodes(nonSelectedValues.filter((code) => !approvedCptCodesValue.includes(code.value)));
  };

  const handleCleanClaimChange = (selectedCodes) => {
    const selectedValues = selectedCodes.map((code) => code.value);
    const nonSelectedValues = verifiedCptCodeOptions.filter((code) => !selectedValues.includes(code.value));

    // Filter out these values from the approved codes and denied
    setApprovedCodes(nonSelectedValues.filter((code) => !deniedCptCodesValue.includes(code.value)));
    setDeniedCodes(nonSelectedValues.filter((code) => !approvedCptCodesValue.includes(code.value)));
  };

  const setConfirmed = useCallback(
    (value) => {
      _setConfirmed(value);

      // Also update the parent copy
      const confirmedArray = [...confirmedTests];
      confirmedArray[index] = value;
      setConfirmedTests(confirmedArray);
    },
    [setConfirmedTests, index, confirmedTests]
  );

  // Upon choosing a portal message, we clear out the confirmation.
  // We don't want this hook to fire any other time; the linter is wrong here.
  useEffect(() => {
    if (confirmed) {
      setConfirmed(false);
    }
  }, [portalMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const messageObj = !_.isEmpty(portalMessage) && getMessageObj(portalMessage);

  const { status } = messageObj;

  const displayForStatus = useCallback((field) => (DISPLAY_STATUS_FIELDS[status] || []).includes(field), [status]);
  const requiredForStatus = useCallback((field) => (REQUIRED_STATUS_FIELDS[status] || []).includes(field), [status]);

  // other handlers

  const isCodeSpecificResult = useMemo(() => status === "code_specific_result", [status]);

  const displayAuthorizationFields = useCallback(
    (field) => {
      return (approvedCptCodesValue?.length > 0 && isCodeSpecificResult) || displayForStatus(field);
    },
    [approvedCptCodesValue?.length, displayForStatus, isCodeSpecificResult]
  );

  const displayDenialReason = useCallback(
    (field) => {
      return (deniedCptCodesValue?.length > 0 && isCodeSpecificResult) || displayForStatus(field);
    },
    [deniedCptCodesValue?.length, displayForStatus, isCodeSpecificResult]
  );

  const requiresAuthorizationFields = useCallback(
    (field) => {
      return (approvedCptCodesValue?.length > 0 && isCodeSpecificResult) || requiredForStatus(field);
    },
    [approvedCptCodesValue?.length, requiredForStatus, isCodeSpecificResult]
  );

  const requiresDenialReason = useCallback(
    (field) => {
      return (deniedCptCodesValue?.length > 0 && isCodeSpecificResult) || requiredForStatus(field);
    },
    [deniedCptCodesValue?.length, requiredForStatus, isCodeSpecificResult]
  );

  const handleConfirm = () => {
    setLoading(true);

    if (messageObj?.message !== undefined) {
      form.change(`tests[${index}].cannedComment`, messageObj.canned_comment);
      if (messageObj.submit_right_direction == "status_the_case") {
        form.change(`tests[${index}].status`, messageObj.status);
        form.change(`tests[${index}].substatus`, messageObj.substatus);

        if (messageObj?.denial_reason_code) {
          form.change(`tests[${index}].denialReasonCode`, messageObj?.denial_reason_code);
        }
      } else if (messageObj.submit_right_direction == "task_out_for_n_number_of_days_to_try_again") {
        form.change(`tests[${index}].taskName`, messageObj.task_name);
        form.change(`tests[${index}].taskDuration`, messageObj.task_duration);

        trackEvent("task_out_case_sr2", caseId, {
          step: "task_out_case_sr2",
        });
      }
    }

    trackEvent("submission_confirm_button", caseId, {
      step: "submission_confirm_button",
    });

    setConfirmed(true);
    setLoading(false);
  };

  const confirmProceedNextPortal = async (cannedComment) => {
    setLoading(true);
    setError(null);

    const params = {
      id: caseId,
      portalId: portalId,
      cannedComment: cannedComment,
    };

    try {
      await api.post(nextPortalUrl, snakifyKeys(params));

      trackEvent("proceed_to_next_portal_confirm_button_sr2", caseId, {
        step: "proceed_to_next_portal_confirm_button_sr2",
      });

      redirectTo("/workflow/" + caseId);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <Fieldset legend={header}>
      {(messageObj?.status === "code_specific_result" || messageObj?.status === "partially_approved") &&
        verifiedCptCodeOptions.length === 1 && (
          <Card color="warning" className="text-center py-4 bg-opacity-10 border-0 mb-2">
            <CardText>This Case only has 1 CPT Code, use the correct Submit Right Response instead.</CardText>
          </Card>
        )}

      <Field component={TextInput} type="hidden" name={`tests[${index}].testId`} initialValue={testId} />
      <Field component={SelectInput} label="Portal Message" name={`tests[${index}].portalMessage`} options={portalMessageOptions} />

      {confirmed && messageObj?.submit_right_direction === "task_out_for_n_number_of_days_to_try_again" && (
        <>
          <Field component={TextInput} label="Task Name" name={`tests[${index}].taskName`} disabled={true} />
          <Field
            component={TextInput}
            type="number"
            name={`tests[${index}].taskDuration`}
            label="Task Duration(Days)"
            validate={numericInRange(0, 90)}
            min="0"
            max="90"
            disabled={true}
          />
        </>
      )}

      {confirmed && messageObj?.submit_right_direction === "status_the_case" && (
        <>
          <Field
            component={TextInput}
            label="Status"
            format={(val) => humanizeString(val || "None")}
            name={`tests[${index}].status`}
            disabled={true}
          />

          <Field
            component={TextInput}
            label="Substatus"
            format={(val) => humanizeString(val || "None")}
            name={`tests[${index}].substatus`}
            disabled={true}
          />

          <StatusDependentTestField
            requiredCondition={requiredForStatus("submissionReferenceNumber")}
            displayCondition={displayForStatus("submissionReferenceNumber")}
            name="submissionReferenceNumber"
            index={index}
            component={TextInput}
            label="Reference Number"
            initialValue={request.submission_reference_number}
          />

          <FieldsetWrapper labelText="Approval Fields" displayCondition={isCodeSpecificResult}>
            <StatusDependentTestField
              requiredCondition={requiredForStatus("approvedCptCodes")}
              displayCondition={displayForStatus("approvedCptCodes")}
              name="approvedCptCodes"
              index={index}
              component={SelectInput}
              isMulti
              options={approvedCodes}
              onUpdate={handleApprovedChange}
              label="Approved CPT Codes"
              placeholder="Select one or more CPT Codes..."
              validate={notAllCodes(verifiedCptCodeOptions)}
            />

            <StatusDependentTestField
              requiredCondition={requiresAuthorizationFields("authorizationNumber")}
              displayCondition={displayAuthorizationFields("authorizationNumber")}
              name="authorizationNumber"
              index={index}
              component={TextInput}
              label="Authorization Number"
              initialValue={request.authorization_number}
            />

            <StatusDependentTestField
              requiredCondition={requiresAuthorizationFields("authorizationDate")}
              displayCondition={displayAuthorizationFields("authorizationDate")}
              name="authorizationDate"
              index={index}
              component={DateInput}
              label="Authorization Date"
              initialValue={request.authorization_date}
            />

            <StatusDependentTestField
              requiredCondition={requiredForStatus("authorizationEffectiveDate")}
              displayCondition={displayAuthorizationFields("authorizationEffectiveDate")}
              name="authorizationEffectiveDate"
              index={index}
              component={DateInput}
              label="Authorization Effective Date"
              initialValue={request.authorization_effective_date}
            />

            <StatusDependentTestField
              requiredCondition={requiredForStatus("authorizationExpirationDate")}
              displayCondition={displayAuthorizationFields("authorizationExpirationDate")}
              name="authorizationExpirationDate"
              index={index}
              component={DateInput}
              label="Authorization Expiration Date"
              initialValue={request.authorization_expiration_date}
            />
          </FieldsetWrapper>

          <FieldsetWrapper labelText="Denial Fields" displayCondition={isCodeSpecificResult}>
            <StatusDependentTestField
              requiredCondition={requiredForStatus("deniedCptCodes")}
              displayCondition={displayForStatus("deniedCptCodes")}
              name="deniedCptCodes"
              index={index}
              component={SelectInput}
              isMulti
              options={deniedCodes}
              onUpdate={handleDeniedChange}
              label="Denied CPT Codes"
              placeholder="Select one or more CPT Codes..."
              validate={notAllCodes(verifiedCptCodeOptions)}
            />

            <StatusDependentTestField
              requiredCondition={requiresDenialReason("denialReasonCode")}
              displayCondition={displayDenialReason("denialReasonCode")}
              name="denialReasonCode"
              index={index}
              component={SelectInput}
              label="Denial Reason Code"
              options={denialReasonCodeOptions}
              initialValue={request.denial_reason_code}
              disabled={messageObj?.denial_reason_code ? true : false}
            />
          </FieldsetWrapper>

          <FieldsetWrapper labelText="Clean Claim Fields" displayCondition={isCodeSpecificResult}>
            <StatusDependentTestField
              requiredCondition={requiredForStatus("documentedCleanClaimCptCodes")}
              displayCondition={displayForStatus("documentedCleanClaimCptCodes")}
              name="documentedCleanClaimCptCodes"
              index={index}
              component={SelectInput}
              isMulti
              options={cleanClaimCodes}
              onUpdate={handleCleanClaimChange}
              label="Documented Clean Claim CPT Codes"
              placeholder="Select one or more CPT Codes..."
              validate={notAllCodes(verifiedCptCodeOptions)}
            />
          </FieldsetWrapper>

          <StatusDependentTestField
            requiredCondition={requiredForStatus("expectedCloseDate")}
            displayCondition={displayForStatus("expectedCloseDate")}
            name="expectedCloseDate"
            index={index}
            component={DateInput}
            label="Expected Close Date"
          />
        </>
      )}

      {confirmed && messageObj && (
        <>
          <Field component={TextInput} label="Canned Comment" name={`tests[${index}].cannedComment`} />
          <Field component={DropzoneInput} label="Attach File" name={`tests[${index}].attachment`} />

          <Field
            component={SelectInput}
            label="Attachment Category"
            name={`tests[${index}].attachmentCategory`}
            options={_.map(attachmentCategories, (c, key) => ({ label: c, value: key }))}
          />
        </>
      )}

      {!confirmed && (
        <ConfirmationButton
          showModal={messageObj?.submit_right_direction === "proceed_to_the_next_portal"}
          onClick={handleConfirm}
          onModalConfirm={() => confirmProceedNextPortal(messageObj.canned_comment)}
          loading={loading}
          disabled={loading || _.isEmpty(portalMessage)}
        >
          Confirm Response
        </ConfirmationButton>
      )}
    </Fieldset>
  );
};
