import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { EditCptCodeModal } from "./EditCptCodeModal";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const CptCodeActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();

  const [canUpdate] = useUpdatePermission("CptCode", row.original.id);
  const [canDestroy] = useDestroyPermission("CptCode", row.original.id);

  return (
    <>
      {canUpdate && (
        <EditCptCodeModal
          color="link"
          cptCode={row.original}
          onUpdate={(newCode) => {
            refresh();
            setMessage(`Added CPT Code ${newCode.code}`);
          }}
        />
      )}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/cpt_codes/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted CPT Code "${row.original.code}".`);
          }}
          onError={setError}
        />
      )}
      <History className="ms-4" entityName="CptCode" subjectId={row.original.id} />
    </>
  );
};
