import { useCallback, useState } from "react";
import { Switch } from "../../../components/inputs/Switch";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";

export const InNetworkPaRequiredToggle = ({
  payorId,
  payorName,
  requiresPaInNetwork,
  cptCodeSettingId,
  cptCodeId,
  cptCode,
  onChange,
  onError,
}) => {
  const [loading, setLoading] = useState(false);
  const label = loading ? "Loading..." : requiresPaInNetwork ? "Yes" : "No";

  const getUpdateMessage = useCallback(
    (requiresPaInNetwork) => `Set In network PA Required for ${payorName} - ${cptCode} as ${requiresPaInNetwork ? "Yes" : "No"}`,
    [cptCode, payorName]
  );

  const handleCreate = useCallback(async () => {
    setLoading(true);

    try {
      await adminApi.post(
        "/admin/payor_cpt_codes_settings.json",
        snakifyKeys({ payorCptCodesSetting: { requiresPaInNetwork: true, payorId, cptCodeId } })
      );
      onChange(getUpdateMessage(true));
    } catch (err) {
      onError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, [cptCodeId, payorId, getUpdateMessage, setLoading, onChange, onError]);

  const handleUpdate = useCallback(
    async (e) => {
      setLoading(true);
      const requiresPaInNetwork = e.target.checked;

      try {
        await adminApi.put(
          `/admin/payor_cpt_codes_settings/${cptCodeSettingId}.json`,
          snakifyKeys({ payorCptCodesSetting: { requiresPaInNetwork } })
        );

        onChange(getUpdateMessage(requiresPaInNetwork));
      } catch (err) {
        onError(extractErrorMessage(err));
      } finally {
        setLoading(false);
      }
    },
    [cptCodeSettingId, getUpdateMessage, setLoading, onChange, onError]
  );

  const handleChange = requiresPaInNetwork === null ? handleCreate : handleUpdate;

  return (
    <Switch
      disabled={loading}
      id={`requires_pa_in_network_${cptCodeSettingId}`}
      checked={Boolean(requiresPaInNetwork)}
      label={label}
      onChange={handleChange}
    />
  );
};
