import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { useTableContext } from "../../components/AdministrateTable";
import { EditLabNoteModal } from "./EditLabNoteModal";
import { extractErrorMessage } from "../../../util/api";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";

export const LabNoteActionCell = ({ labId, ...row }) => {
  const [canDestroy] = useDestroyPermission("LabNote", row.original.id);
  const [canUpdate] = useUpdatePermission("LabNote", row.original.id);

  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = () => {
    setMessage(`Updated Lab Note ${row.original.id}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditLabNoteModal color="link" onUpdate={handleChange} labId={labId} note={row.original} />}

      {canDestroy && (
        <AdministrateResourceDeleteButton
          label="Delete"
          deleteUrl={`/admin/lab_notes/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Lab Note ${row.original.id} deleted.`);
          }}
          onError={(msg) => setError(extractErrorMessage(msg))}
        />
      )}
    </>
  );
};
