import _ from "lodash";
import React, { useState, useMemo } from "react";
import { Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ClinicLabRedirectionForm } from "./ClinicLabRedirectionForm";

export const EditClinicLabRedirectionModal = ({ clinicLabRedirection, onEdit, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (values) => {
    const newValues = _.cloneDeep(values);

    setError(null);
    try {
      const res = await api.patch(`/admin/clinic_lab_redirections/${clinicLabRedirection.id}`, {
        clinic_lab_redirection: snakifyKeys(newValues),
      });

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const initialValues = {
    ...clinicLabRedirection,
    clinicId: clinicLabRedirection.clinic.id,
    testId: clinicLabRedirection.test?.id,
    payorId: clinicLabRedirection.payor?.id,
    associatedLabId: clinicLabRedirection.associatedLab.id,
    redirectedLabId: clinicLabRedirection.redirectedLab.id,
    fundingType: clinicLabRedirection.fundingType,
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Clinic Lab Redirection</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={handleEdit} initialValues={initialValues}>
            {(props) => <ClinicLabRedirectionForm {...props} create={false} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
