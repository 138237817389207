import React from "react";
import { formatCentsAsDollars } from "../../../util/formatters";

export const EstimateInfoListPharmacyUser = ({ biInfo, noMoopProvided = false, coveredChargesEnabled }) => {
  return (
    <>
      {biInfo.productBenefits.map((benefits, index) => (
        <>
          <h3 className="text-dark fw-bold mb-3">Benefit {index + 1}</h3>
          {benefits.products.map((product) => (
            <>
              <li className="d-flex flex-row justify-content-between align-items-center mx-2" key={"product-name"}>
                <h5 className="text-muted fw-bold">{product.productName}</h5>
              </li>

              {product.procedureCodes.map((procedureCode) => (
                <li className="d-flex flex-row justify-content-between align-items-center mb-2 mx-2" key={"procedure-code"}>
                  {procedureCode.multiplier > 1 ? (
                    <span className="token mx-4 fs-6">
                      {procedureCode.code}x{procedureCode.multiplier}
                    </span>
                  ) : (
                    <span className="token mx-4 fs-6">{procedureCode.code}</span>
                  )}

                  <h5 className="test-dark fw-bold">{procedureCode.cptCodeCost}</h5>
                </li>
              ))}
            </>
          ))}
          {coveredChargesEnabled && (
            <>
              <li className="d-flex flex-row justify-content-between align-items-center mb-2 mx-2" key={"patient-responsibility-covered"}>
                <h5 className="text-muted fw-semibold">Total Covered Charges</h5>
                <h5 className="text-dark fw-bold">{formatCentsAsDollars(biInfo.estimated_patient_responsibility_covered)}</h5>
              </li>

              <li
                className="d-flex flex-row justify-content-between align-items-center mb-2 mx-2"
                key={"patient-responsibility-noncovered"}
              >
                <h5 className="text-muted fw-semibold">Total Non Covered Charges</h5>
                <h5 className="text-dark fw-bold">{formatCentsAsDollars(biInfo.estimated_patient_responsibility_noncovered)}</h5>
              </li>
            </>
          )}
          <li className="d-flex flex-row justify-content-between align-items-center mb-2 mx-2" key={"deductible-remaining"}>
            <h5 className="text-muted fw-semibold">Current Remaining Deductible</h5>
            <h5 className="text-dark fw-bold">{benefits.deductible_remaining}</h5>
          </li>

          <li className="d-flex flex-row justify-content-between align-items-center mb-2 mx-2" key={"moop"}>
            <h5 className="text-muted fw-semibold">Current Max Out of Pocket</h5>
            <h5 className="text-dark fw-bold">{noMoopProvided || benefits.moop_remaining == null ? "NA" : benefits.moop_remaining}</h5>
          </li>

          <li className="d-flex flex-row justify-content-between align-items-center mb-2 mx-2" key={"copay"}>
            <h5 className="text-muted fw-semibold">Copay</h5>
            <h5 className="text-dark fw-bold">{benefits.copay}</h5>
          </li>

          <li className="d-flex flex-row justify-content-between align-items-center mb-2 mx-2" key={"coinsurance"}>
            <h5 className="text-muted fw-semibold">Coinsurance</h5>
            <h5 className="text-dark fw-bold">{benefits.coinsurance}</h5>
          </li>

          <li className="d-flex flex-row justify-content-between align-items-center mb-4 mx-2" key={"estimated-patient-responsibility"}>
            <h5 className="text-muted fw-semibold">Estimated Patient Responsibility</h5>
            <h5 className="text-dark fw-bold">{benefits.estimated_patient_responsibility}</h5>
          </li>
        </>
      ))}
      <li className="d-flex flex-column align-items-start bg-info bg-opacity-25 p-2" key={"estimated-total-healthplan-responsibility"}>
        <h4 className="text-dark fw-bold mb-2 mx-2">Estimated Total HealthPlan Responsibility</h4>
        <h5 className="text-dark fw-bold px-2 pb-1">{formatCentsAsDollars(biInfo.total_charges_covered_by_healthplan)}</h5>
      </li>
    </>
  );
};
