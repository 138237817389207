import { useCallback } from "react";
import { CountCell } from "../../../components/grid/cells/CountCell";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission, useUpdatePermission } from "../../utils";
import { AddTestCategoryModal } from "./components/AddTestCategoryModal";
import { TestCategoryActionCell } from "./components/TestCategoryActionCell";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "Id",
  },
  {
    id: "name",
    accessor: "name",
    Header: "Name",
  },
  {
    id: "test.id",
    accessor: "testCount",
    Header: "Tests",
    Cell: ({ value }) => <CountCell count={value} label="Tests" />,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: TestCategoryActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const TestCategoryIndex = () => {
  const [canCreate] = useCreatePermission("TestCategory");
  const columns = useColumns();
  const extraParams = { kept: true };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  const onAdd = useCallback(
    (values) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`New Test Category "${values.name}" Added.`);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Test Category Management">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">{canCreate && <AddTestCategoryModal onAdd={onAdd} color="primary" />}</Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/test_categories.json"}
        resourceName={"test"}
        extraParams={extraParams}
        onFilteredChange={setTableFilters}
        permissions={[UPDATE, DESTROY]}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
