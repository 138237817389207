import { useMemo } from "react";
import { DateCell } from "../../../components/grid/cells/DateCell";
import { DateFilter } from "../../../components/inputs/DateFilter";
import { PayorNoteActionCell } from "../../payors/components/PayorNoteActionCell";
import { TextBoxCell } from "../../payors/tabs/PayorNotesTab";
import { DESTROY, UPDATE, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { snakifyKeys } from "../../../util/helpers";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable } from "../../components/AdministrateTable";
import { CreateLabNoteModal } from "../components/CreateLabNoteModal";
import { LabNoteActionCell } from "../components/LabNoteActionCell";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { ImportLabNotesModal } from "../components/ImportLabNotesModal";

const useColumns = makeColumns((labId) => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    maxWidth: 15,
  },
  {
    id: "note",
    accessor: "note",
    Header: "Note",
    Cell: ({ row }) => <TextBoxCell note={row.values?.note} />,
  },
  {
    id: "created_at",
    accessor: "createdAt",
    Header: "Created At",
    maxWidth: 25,
    Filter: (props) => <DateFilter {...props} />,
    Cell: ({ row }) => <DateCell value={row.values?.created_at} />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <LabNoteActionCell labId={labId} {...row} />,
    disableFilters: true,
    disableSortBy: true,
    maxWidth: 40,
  },
]);

export const LabNotesTab = ({ lab, labId }) => {
  const [canCreate] = useCreatePermission("LabNote");
  const columns = useColumns(labId);

  const extraParams = useMemo(() => snakifyKeys({ labId }), [labId]);
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onCreate = (newLabNote) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created new Lab Note ${newLabNote.id}`);
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <AdministrateBackgroundExportButton
          outline
          className="me-2"
          exportUrl="/admin/lab_notes/export"
          filters={allFilters}
          ref={tableRef}
        />

        {canCreate && <ImportLabNotesModal labId={labId} outline className="me-2" onImport={onImport} />}

        {canCreate && <CreateLabNoteModal color="primary" onCreate={onCreate} labId={labId} />}
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/lab_notes.json?lab_id=${labId}`}
        resourceName={"lab_note"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
        striped
      />
    </>
  );
};
