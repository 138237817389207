import React, { useState, useCallback } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { LoadingButton } from "../../../components/LoadingButton";
import { api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const AddPayorGroupMemberModal = ({ payorGroup, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = useCallback(
    async (values) => {
      setError(null);

      const result = await api.post(`/admin/payor_groups/${payorGroup.id}/payors`, snakifyKeys({ payorIds: values.payorIds }));

      onAdd?.(result);

      setOpen(false);
    },
    [payorGroup, onAdd]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Payors
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add Payors to {payorGroup.name}</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate}>
            {({ handleSubmit, form, submitting }) => {
              return (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/payors.json"
                    required
                    label="Payors to Add"
                    searchParam="name"
                    name="payorIds"
                    labelKey="name"
                    isMulti
                  />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
