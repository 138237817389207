import classnames from "classnames";
import Cleave from "cleave.js/react";
import React from "react";
import { FormText } from "reactstrap";
import { InputWrapper } from "./InputWrapper";

export const MoneyInput = ({ children, input, label, meta, ...rest }) => {
  return (
    <InputWrapper label={label} required={rest.required}>
      <Cleave
        options={{
          prefix: "$",
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          rawValueTrimPrefix: true,
        }}
        {...input}
        className={classnames("form-control", rest.className, {
          "is-invalid": Boolean(meta.touched && meta.error),
        })}
        {...rest}
      />
      {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
      {children}
    </InputWrapper>
  );
};
