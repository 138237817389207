import React, { useCallback } from "react";
import { Container, Row } from "reactstrap";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { AddVisualModal } from "./components/AddVisualModal";
import { VisualActionCell } from "./components/VisualActionCell";
import { GraphicFileCell } from "./components/GraphicFileCell";

const useColumns = makeColumns(() => [
  {
    id: "description",
    accessor: "description",
    Header: "Description",
  },
  {
    id: "status",
    accessor: "status",
    Header: "Status",
  },
  {
    id: "graphic_file",
    accessor: "completionScreenVisual[0].name",
    Header: "Graphic File",
    Cell: GraphicFileCell,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: VisualActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const VisualManagementIndex = () => {
  const { tableRef, setTableFilters } = useAdministrateTable();
  const columns = useColumns();
  const [canCreate] = useCreatePermission("SubmitRightVisualManagement");

  const onAdd = useCallback(
    (values) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`New Visual "${values.description} Added.`);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Completion Screen Visual Management">
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Toolbar className="ml-auto">{canCreate && <AddVisualModal onAdd={onAdd} color="primary" />}</Toolbar>
        </Row>
      </Container>

      <AdministrateTable
        resourceUrl={"/admin/submit_right_visual_managements.json"}
        resourceName={"submit_right_visual_management"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
