import { useState } from "react";
import { find } from "lodash";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { SubmitRightPaperResponseForm } from "./SubmitRightPaperResponseForm";
import { api, redirectTo } from "../util/api";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { snakifyKeys } from "../util/helpers";
import { FaxForm } from "../components/FaxForm";
import { FORM_ERROR } from "final-form";

const createOptions = (array) => {
  return array.map((el) => ({
    label: el.message,
    value: el.message,
  }));
};

const FaxPortableModal = ({
  caseId,
  buttonLabel = "Send Fax",
  modalHeader = "Send Fax",
  buttonProps = {},
  modalProps = {},
  disabled = false,
  requireRecipients = true,
  requireAttachments = true,
  initialValues = {},
  onSend,
  showButton = true,
  isOpen,
  toggleAction,
}) => {
  return (
    <>
      {showButton && (
        <Button {...buttonProps} onClick={toggleModal} disabled={disabled}>
          {buttonLabel}
        </Button>
      )}

      <Modal isOpen={isOpen} toggle={toggleAction} {...modalProps} size="lg" backdrop={"static"}>
        <ModalHeader toggle={toggleAction}>{modalHeader}</ModalHeader>
        <ModalBody>
          <FaxForm
            casePublicId={caseId}
            requireRecipients={requireRecipients}
            requireAttachments={requireAttachments}
            initialValues={initialValues}
            onCancel={toggleAction}
            onSend={onSend}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export const SubmitRightPaperResponse = ({
  caseId,
  saveUrl,
  request,
  paStatuses,
  paperResponses,
  verifiedCptCodeOptions,
  denialReasonCodeOptions,
}) => {
  const [error, setError] = useState(null);
  const [showFax, setShowFax] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const paperResponseOptions = createOptions(paperResponses);

  const toggleModal = () => setShowFax((prev) => !prev);

  const getPaperResponseObj = (paperResponse) => {
    return find(paperResponses, (f) => f.message == paperResponse);
  };

  const addExtraParams = (params) => {
    if (!params.id) {
      params.caseId = caseId;
    }
  };

  const validateCptCodesFields = (values) => {
    let cptCodesLength = 0;
    let isCodeSpecificResult = values.paperSubmissionResponse.status === "code_specific_result";

    if (isCodeSpecificResult) {
      // CD-9133 Need to ensure that CPT Codes can be mixed
      cptCodesLength += values.paperSubmissionResponse?.approvedCptCodes?.length || 0;
      cptCodesLength += values.paperSubmissionResponse?.deniedCptCodes?.length || 0;
      cptCodesLength += values.paperSubmissionResponse?.documentedCleanClaimCptCodes?.length || 0;
    }

    if (cptCodesLength < verifiedCptCodeOptions.length && isCodeSpecificResult)
      return { [FORM_ERROR]: "Please ensure that every CPT Code is assigned to a status." };
  };

  const onSubmit = async (params) => {
    const errors = validateCptCodesFields(params);
    if (errors) return errors;

    setError(null);
    addExtraParams(params);
    const snakeParams = snakifyKeys(params);

    // The front end shows fax modal when resource url is null
    try {
      const { data } = await api.post(saveUrl, snakeParams);

      if (data?.meta?.redirect_url?.length > 0) {
        redirectTo(data.meta.redirect_url);
      }

      if (data.meta.redirect_url == null) {
        setShowFax(true);
      }
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <DetailedErrorAlert error={error} />

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, valid, submitError, ...rest }) => (
          <form onSubmit={handleSubmit}>
            <SubmitRightPaperResponseForm
              caseId={caseId}
              request={request}
              confirmed={confirmed}
              options={paperResponseOptions}
              paperResponses={paperResponses}
              setConfirmedFunction={setConfirmed}
              verifiedCptCodeOptions={verifiedCptCodeOptions}
              denialReasonCodeOptions={denialReasonCodeOptions}
              paStatuses={paStatuses}
              getPaperResponseObj={getPaperResponseObj}
              setError={setError}
              setShowFax={setShowFax}
              {...rest}
            />

            {submitError && <DetailedErrorAlert error={submitError} />}

            <div className="py-2">
              {confirmed && (
                <Button color="primary" loading={submitting} disabled={submitting || !confirmed}>
                  Submit Response
                </Button>
              )}
            </div>
          </form>
        )}
      </Form>

      <FaxPortableModal showButton={false} caseId={caseId} toggleAction={toggleModal} isOpen={showFax} />
    </>
  );
};
