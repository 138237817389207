import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { Fieldset } from "../components/inputs/final_form/Fieldset";
import { LoadingButton } from "../components/LoadingButton";
import { api, redirectTo } from "../util/api";
import { snakifyKeys } from "../util/helpers";
import { SubmitRightPhoneResponseForm } from "./SubmitRightPhoneResponseForm";
import { trackEvent } from "../util/track";
import { FaxModal } from "../components/FaxModal";
import { FORM_ERROR } from "final-form";

export const SubmitRightPhoneForm = ({
  caseId,
  request,
  defaultFaxParams,
  phoneResponses,
  phoneResponseOptions,
  denialReasonCodeOptions,
  verifiedCptCodeOptions,
  paStatuses,
  saveUrl,
}) => {
  const [error, setError] = useState(null);
  const [confirmedPhoneResponse, setConfirmedPhoneResponse] = useState(false);
  const [showFaxButton, setShowFaxButton] = useState(false);

  const getPhoneResponseObj = (phoneResponse) => {
    return _.find(phoneResponses, (f) => f.message == phoneResponse);
  };

  const addExtraParams = (params) => {
    if (!params.id) {
      params.id = caseId;
    }
  };

  const trackStatusChange = (status) => {
    if (status) {
      trackEvent("status_case_sr2_phone_response", caseId, {
        step: "status_case_sr2_phone_response",
      });
    }
  };

  const validateCptCodesFields = (values) => {
    let cptCodesLength = 0;
    let isCodeSpecificResult = values.phoneSubmissionResponse.status === "code_specific_result";

    if (isCodeSpecificResult) {
      // CD-9133 Need to ensure that CPT Codes can be mixed
      cptCodesLength += values.phoneSubmissionResponse?.approvedCptCodes?.length || 0;
      cptCodesLength += values.phoneSubmissionResponse?.deniedCptCodes?.length || 0;
      cptCodesLength += values.phoneSubmissionResponse?.documentedCleanClaimCptCodes?.length || 0;
    }

    if (cptCodesLength < verifiedCptCodeOptions.length && isCodeSpecificResult)
      return { [FORM_ERROR]: "Please ensure that every CPT Code is assigned to a status." };
  };

  const onSubmit = async (params) => {
    const errors = validateCptCodesFields(params);
    if (errors) return errors;

    setError(null);
    addExtraParams(params);
    const snakeParams = snakifyKeys(params);
    const status = snakeParams.phone_submission_response?.status;

    try {
      const result = await api.post(saveUrl, snakeParams);
      trackStatusChange(status);
      redirectTo(result.data.meta.redirect_url);
    } catch (err) {
      setError(err);
    }
  };

  const faxInitialValues = {
    phone_clinical_documents_fax: true,
    add_success_message: true,
    ...defaultFaxParams,
  };

  return (
    <>
      <DetailedErrorAlert error={error} />

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, form, submitError }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Fieldset legend={"Submit via Phone"}>
                <SubmitRightPhoneResponseForm
                  caseId={caseId}
                  request={request}
                  phoneResponseOptions={phoneResponseOptions}
                  denialReasonCodeOptions={denialReasonCodeOptions}
                  verifiedCptCodeOptions={verifiedCptCodeOptions}
                  paStatuses={paStatuses}
                  getPhoneResponseObj={getPhoneResponseObj}
                  confirmedPhoneResponse={confirmedPhoneResponse}
                  setConfirmedPhoneResponse={setConfirmedPhoneResponse}
                  setError={setError}
                  setShowFaxButton={setShowFaxButton}
                  nextSubmissionUrl={saveUrl}
                />
              </Fieldset>

              <hr />

              {submitError && <DetailedErrorAlert error={submitError} />}

              {showFaxButton && (
                <FaxModal
                  caseId={caseId}
                  requireRecipients={true}
                  requireAttachments={true}
                  buttonProps={{ className: "mb-4 me-2" }}
                  buttonLabel="Fax Clinical Documents"
                  modalHeader="Fax Clinical Documents"
                  initialValues={faxInitialValues}
                />
              )}

              <LoadingButton className="mb-4" color="primary" loading={submitting} disabled={submitting || !confirmedPhoneResponse}>
                Submit Response
              </LoadingButton>
            </form>
          );
        }}
      </Form>
    </>
  );
};
