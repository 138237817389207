import { Card, CardBody } from "reactstrap";
import { ProgressList } from "../../../../components/ProgressList";
import { InfoGrid } from "../../../../components/grid/InfoGrid";

export const CaseHistoryDemo = () => {
  const firstStationContent = () => {
    return (
      <>
        <div>Jack(jack.kelly@covermytest.com)</div>
        <div>Created case oynaa 5533 via Lab Portal</div>
      </>
    );
  };

  const secondStationContent = () => {
    return <div>Case closed</div>;
  };

  const historyExample = [
    { secondaryLabel: "06/01/2022 8:10:39 PM", content: firstStationContent(), status: "caseHistory", historyStepIcon: "monitor" },
    { secondaryLabel: "06/01/2022 8:10:39 PM", content: secondStationContent(), status: "caseHistory", historyStepIcon: "anchor" },
  ];
  return (
    <>
      <div className="mt-4">
        <Card className="d-flex flex-row mb-3 border border-0">
          <CardBody className="h-50 border rounded-2">
            <h5 className="mb-4">Case History Example</h5>
            <ProgressList items={historyExample} />
          </CardBody>
        </Card>
      </div>
    </>
  );
};
