import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { formatDateTime, parseCurrency } from "../../../util/formatters";
import { snakifyKeys } from "../../../util/helpers";
import { BenefitPullTypeForm } from "./BenefitPullTypeForm";

export const EditBenefitPullTypeModal = ({ benefitPullType, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleUpdate = async (values) => {
    const benefit_pull_type = snakifyKeys(values);

    setError(null);
    try {
      const res = await api.patch(`/admin/benefit_pull_types/${benefit_pull_type.id}`, { benefit_pull_type });

      if (onUpdate) {
        onUpdate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const initialValues = useMemo(
    () => ({
      ...benefitPullType,
      testId: benefitPullType.test?.id,
      cptCodeIds: benefitPullType.cptCodes.map((c) => c.id),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(benefitPullType)]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Edit Benefit Pull Type</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <dl>
            <dt>Created At</dt>
            <dd>{formatDateTime(benefitPullType.createdAt)}</dd>

            <dt>Last Updated At</dt>
            <dd>{formatDateTime(benefitPullType.updatedAt)}</dd>
          </dl>

          <Form onSubmit={handleUpdate} initialValues={initialValues}>
            {(props) => <BenefitPullTypeForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
