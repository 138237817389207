import _ from "lodash";

export const tuplesToOptions = (tuples) => {
  return tuples.map((t) => {
    return { label: t[0], value: t[1] };
  });
};
// Utility Functions
const policyCoversCptCode = (policy, code) => _.some(policy?.policy_coverages, (cov) => cov.cpt_code?.id === code.id);
export const findPolicyForCptCode = (group, code) => _.find(group?.policies, (pol) => policyCoversCptCode(pol, code));
export const cptSettingsForPayor = (payor, code) => payor?.payor_cpt_codes_settings.filter((c) => c.cpt_code_id === code.id) ?? [];
export const cptRequiresPaForPayor = (payor, code) => _.some(cptSettingsForPayor(payor, code), (c) => c.requires_pa === true);
export const cptRequiresGCForPayor = (payor, code) => _.some(cptSettingsForPayor(payor, code), (c) => c.gc_requirement !== null);
export const icdCodesForPolicyCoverage = (policy) => _.uniq(policy?.policy_coverages.map((c) => c.icd10_code?.code));
export const icdCodesForPayorGroupCoverage = (group) => _.uniq(_.map(group?.payor_group_coverages, (c) => c.icd10_code?.code));
