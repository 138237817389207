import _ from "lodash";
import React from "react";
import cx from "classnames";
import { CheckCircle, ChevronRight, HelpCircle } from "lucide-react";
import { formatDate } from "../../util/formatters";
import { CASE_PATIENT_NAME, VERIFIED_PAYOR } from "../../components/grid/columns";
import { EnumFilter, DateFilter } from "../../components/inputs";
import { RadioFilter } from "../../components/inputs/RadioFilter";
import { ACTION_CELL, PUBLIC_ID_CELL, STATUS_CELL, MISSING_INFO_CATEGORIES_CELL } from "./columns/cells";

const CASE_PUBLIC_ID_COLUMN = {
  Header: `${window.branding.brandNameShort} Case ID`,
  id: "public_id",
  accessor: "public_id",
  Cell: ({ value }) => (value ? PUBLIC_ID_CELL(value) : null),
  width: 130,
  disableSortBy: true,
};

const MISSING_INFO_DATE_COLUMN = ({ isInternalUser = true }) => {
  const accessor = isInternalUser === false ? "pa_request.StatusUpdatedAt" : "pa_request.statusUpdatedAt";

  return {
    Header: "Date of Missing Info",
    id: "status_updated_at",
    accessor: (r) => formatDate(_.get(r, accessor)),
    minWidth: 130,
    Filter: (props) => <DateFilter {...props} />,
  };
};

const CASE_LAB_PATIENT_ID = ({ isInternalUser = true }) => {
  const accessor = isInternalUser === false ? "pa_request.Patient.PatientId" : "pa_request.patientInfo.externalPatientId";
  return {
    Header: "Lab Patient ID",
    id: "patient_id",
    accessor: accessor,
    Cell: ({ value }) => (value ? value : "-"),
    minWidth: 150,
    disableSortBy: true,
  };
};

const CASE_LAB_ORDER_ID = ({ isInternalUser = true }) => {
  const accessor = isInternalUser === false ? "pa_request.LabOrder.LabOrderId" : "pa_request.orderInfo.externalOrderId";

  return {
    Header: "Lab Order ID",
    id: "order_id",
    accessor: accessor,
    Cell: ({ value }) => (value ? value : "-"),
    minWidth: 130,
    disableSortBy: true,
  };
};

const RECEIVED_DATE_COLUMN = {
  Header: "Received Date",
  id: "created_at",
  accessor: (r) => formatDate(_.get(r, "created_at")),
  minWidth: 130,
  disableSortBy: true,
  disableFilters: true,
};

const VERIFIED_DOS_COLUMN = ({ isInternalUser = true }) => {
  const accessor = isInternalUser === false ? "pa_request.VerifiedDOS" : "pa_request.verifiedDOS";

  return {
    Header: "Verified Date of Service",
    id: "cached_verified_dos",
    accessor: (r) => formatDate(_.get(r, accessor)),
    minWidth: 130,
    Filter: (props) => <DateFilter {...props} />,
  };
};

const TEST_NAME = ({ isInternalUser = true }) => {
  const accessor = isInternalUser === false ? "pa_request.TestNames" : "pa_request.testInfo.testName";

  return {
    Header: "Test Name",
    id: "test_name",
    accessor: accessor,
    minWidth: 130,
    disableFilters: true,
  };
};

const STATUS_COLUMN = (options, currentStatus) => {
  let iconClassNames = cx({ "text-success": currentStatus === "approved" }, { "text-danger": currentStatus === "missing information" });
  let IconComponent = CheckCircle;
  let minWidth = 120;
  const disableFilters = currentStatus === "missing information" || currentStatus === "approved";

  if (currentStatus === "missing information") {
    IconComponent = HelpCircle;
    minWidth = 185;
  }

  return {
    Header: "Status",
    id: "status",
    accessor: "cached_workflow_status",
    Cell: ({ value }) => STATUS_CELL(value, IconComponent, iconClassNames, currentStatus),
    width: minWidth,
    Filter: (props) => <EnumFilter {...props} options={options} />,
    disableSortBy: true,
    disableFilters,
    className: "pt-0",
  };
};

const DOB_COLUMN = ({ isInternalUser = true }) => {
  const accessor = isInternalUser === false ? "pa_request.LabOrder.DOB" : "pa_request.patientInfo.dob";

  return {
    Header: "Date of Birth",
    id: "patient_dob",
    accessor: (r) => formatDate(_.get(r, accessor)),
    Filter: (props) => <DateFilter {...props} />,
    width: 0,
    disableSortBy: true,
    hideColumn: true,
  };
};

const STATE_COLUMN = () => {
  const options = {
    inline: true,
    enumOptions: [
      { label: "All", value: "all" },
      { label: "Archived", value: true },
      { label: "Active", value: false },
    ],
  };

  return {
    Header: "State",
    id: "archived",
    accessor: (r) => `${_.get(r, "archived")}`,
    Filter: (props) => <RadioFilter {...props} options={options} />,
    width: 0,
    disableSortBy: true,
    hideColumn: true,
  };
};

const MISSING_INFO_CATEGORIES_COLUMN = ({ missingInfoCategoryOptions }) => {
  return {
    Header: "Missing Info Categories",
    id: "missing_info_categories",
    accessor: "missing_info_categories",
    width: 350,
    Cell: MISSING_INFO_CATEGORIES_CELL,
    Filter: (props) => <EnumFilter isMulti {...props} options={missingInfoCategoryOptions} />,
    disableSortBy: true,
  };
};

export const ACTION_COLUMN = (actions) => {
  return {
    Header: "Action",
    id: "actions",
    Cell: ({ row }) => ACTION_CELL(actions(row)),
    filterable: false,
    width: 120,
  };
};

export const columns = (filterOptions, status, extraColumns = [], isInternalUser = true) => {
  const { statusOptions, missingInfoCategoryOptions } = filterOptions;
  const internalUser = Boolean(isInternalUser);

  let defaultColumns = [
    STATE_COLUMN(),
    DOB_COLUMN({ isInternalUser: internalUser }),
    CASE_PUBLIC_ID_COLUMN,
    CASE_PATIENT_NAME({ disableFilters: true, isInternalUser: internalUser }),
    CASE_LAB_PATIENT_ID({ isInternalUser: internalUser }),
    CASE_LAB_ORDER_ID({ isInternalUser: internalUser }),
    RECEIVED_DATE_COLUMN,
    VERIFIED_PAYOR({ disableFilters: true }),
    TEST_NAME({ isInternalUser: internalUser }),
    STATUS_COLUMN(statusOptions, status),
  ];

  if (status === "missing information") {
    defaultColumns.splice(3, 0, MISSING_INFO_DATE_COLUMN({ isInternalUser: internalUser }));
    defaultColumns.splice(8, 0, VERIFIED_DOS_COLUMN({ isInternalUser: internalUser }));
    defaultColumns.splice(11, 0, MISSING_INFO_CATEGORIES_COLUMN({ missingInfoCategoryOptions }));
  }

  return extraColumns.length > 0 ? defaultColumns.concat(extraColumns) : defaultColumns;
};
