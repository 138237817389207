import React from "react";
import { Field, FormSpy } from "react-final-form";
import { MoneyInput } from "../../../components/inputs/final_form/MoneyInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { Checkbox } from "../../../components/inputs/final_form/Checkbox";
import { atLeastOneOfMultipleFieldsRequired } from "../../../util/validators";
import { AddressFields, ADDRESS_FIELDS } from "../../../components/inputs/final_form/AddressFields";

const CASE_TYPE_OPTIONS = [
  { value: "type_prior_auth", label: "Prior Auth" },
  { value: "type_benefits_investigation", label: "Benefits Investigation" },
];
export const LabIdentifierForm = ({ handleSubmit, submitting, values }) => {
  const testResourceParams = `test[direction]=asc&test[order]=commercial_name&lab=${values.lab.id}&archived=false`;

  return (
    <>
      <Field component={TextInput} type="hidden" name="lab" />
      <Field component={TextInput} label="Lab Name" name="labName" type="text" required />
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/payors.json"
        label="Payor"
        name="payorId"
        searchParam="name"
        validate={atLeastOneOfMultipleFieldsRequired(["benefitManagementPortalId", "testIds", "servicingLabNpi"])}
      />
      <Field
        component={AdministrateSelectInput}
        key="benefitManagementPortalId"
        label="Verified Portal"
        labelKey="name"
        name="benefitManagementPortalId"
        resourceUrl="/admin/benefit_management_portals.json"
        validate={atLeastOneOfMultipleFieldsRequired(["testIds", "payorId", "servicingLabNpi"])}
        valueKey="id"
      />
      <Field
        isMulti
        component={AdministrateSelectInput}
        resourceUrl={`/admin/tests.json?${testResourceParams}`}
        label="Verified Tests"
        labelKey="commercial_name"
        searchParam="commercial_name"
        name="testIds"
        validate={atLeastOneOfMultipleFieldsRequired(["benefitManagementPortalId", "payorId", "servicingLabNpi"])}
        valueKey="id"
      />
      <Field
        component={TextInput}
        label="Servicing Lab NPI"
        name="servicingLabNpi"
        type="text"
        validate={atLeastOneOfMultipleFieldsRequired(["testIds", "payorId", "benefitManagementPortalId"])}
      />
      <Field component={TextInput} label="NPI" name="npi" type="text" />
      <Field component={TextInput} label="TIN" name="tin" type="text" />
      <Field component={Checkbox} label="Default Address" type="checkbox" name="defaultAddress" />
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          return (
            !values.defaultAddress && (
              <>
                <AddressFields></AddressFields>
              </>
            )
          );
        }}
      </FormSpy>
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
