import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { removeField, selectField, updateField, selectFieldById, saveDocument } from "../store";

import { HotKeys } from "react-hotkeys";
import { formatFieldPosition } from "../utils";

const map = {
  moveSelection: ["left", "right", "up", "down", "shift+left", "shift+right", "shift+up", "shift+down"],
  deleteSelection: ["del", "backspace"],
  deselectAll: ["esc"],
  save: ["meta+s"],
};

class KBSComponent extends React.Component {
  render() {
    const handlers = {
      moveSelection: this.moveSelection,
      deleteSelection: this.deleteSelection,
      deselectAll: this.deselectAll,
      save: this.save,
    };

    return (
      <HotKeys keyMap={map} handlers={handlers}>
        <React.Fragment key={this.props.key} children={this.props.children} />
      </HotKeys>
    );
  }

  moveSelection = (e) => {
    const { selectedField } = this.props;

    if (!selectedField) return;

    const pageIdx = selectedField.position.page;

    if (document.activeElement instanceof HTMLInputElement) return;

    const currentX = selectedField.position.at.x;
    const currentY = selectedField.position.at.y;
    const delta = e.shiftKey ? 10 : 1;
    let newPos;

    if (e.keyCode === 37) {
      // Left
      newPos = formatFieldPosition(pageIdx, currentX - delta, currentY);
    } else if (e.keyCode === 38) {
      // Up
      newPos = formatFieldPosition(pageIdx, currentX, currentY - delta);
    } else if (e.keyCode === 39) {
      // Right
      newPos = formatFieldPosition(pageIdx, currentX + delta, currentY);
    } else if (e.keyCode === 40) {
      // Down
      newPos = formatFieldPosition(pageIdx, currentX, currentY + delta);
    }

    // Do update
    this.props.onUpdateField(selectedField.id, newPos);
  };

  deleteSelection = () => {
    const { selectedField } = this.props;
    if (document.activeElement instanceof HTMLInputElement) return;
    if (!selectedField) return;

    this.props.onRemoveField(selectedField.id);
  };

  deselectAll = () => {
    const { selectedField } = this.props;
    if (document.activeElement instanceof HTMLInputElement) return;
    if (!selectedField) return;

    this.props.onDeselectAll();
  };

  save = (e) => {
    e.preventDefault();
    this.props.onSave();
  };
}

const mapStateToProps = (state) => ({
  selectedField: selectFieldById(state, state.selectedField),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onUpdateField: (fieldId, data) => {
    dispatch(updateField({ fieldId, data }));
  },
  onDeselectAll: () => {
    dispatch(selectField({ fieldId: null }));
  },
  onRemoveField: (fieldId) => {
    dispatch(removeField({ fieldId }));
  },
  onSave: () => dispatch(saveDocument(ownProps.updateSchemaPath)),
});

export const KBS = connect(mapStateToProps, mapDispatchToProps)(KBSComponent);
