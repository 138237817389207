import classnames from "classnames";
import React from "react";
import { FormGroup, FormText, Input, Label } from "reactstrap";

export const Radio = ({ children, input, id, label, meta, showError = true, labelClass, ...rest }) => (
  <FormGroup check>
    <Input {...input} {...rest} invalid={Boolean(meta.touched && meta.error)} type="radio" id={id} />
    <Label check className={classnames(labelClass, { "text-danger": Boolean(meta.touched && meta.error) })} htmlFor={id}>
      {label}
    </Label>

    {showError && meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
    {children}
  </FormGroup>
);
