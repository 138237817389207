import _ from "lodash";
import moment from "moment-timezone";
import classnames from "classnames";
import React from "react";
import Flatpickr from "react-flatpickr";
import { FormText } from "reactstrap";
import { InputWrapper } from "./InputWrapper";

export const DateInput = ({ children, input, label, meta, className, ...rest }) => {
  return (
    <InputWrapper label={label} required={rest.required}>
      <Flatpickr
        className={classnames("form-control", className, {
          "is-invalid": Boolean(meta.touched && meta.error),
        })}
        data-enable-time={rest.isDateTime}
        placeholder="MM/DD/YYYY"
        options={{
          allowInput: true,
          altInput: true,
          altFormat: "m/d/Y",
          dateFormat: "Y-m-d",
          parseDate: (str) => {
            // we run through this function twice, once when the user inputs and again from the onChange function.
            // this needs to handle user inputted string as well as altFormat and dateFormat
            const enteredDate = moment(str, "MM/DD/YYYY");
            const isoDate = moment(str, "YYYY-MM-DD");

            return enteredDate.isValid() ? enteredDate.toDate() : isoDate.toDate();
          },
        }}
        {...input}
        {...rest}
        onChange={(dates) => {
          if (_.isNil(dates) || dates.length < 1) {
            input.onChange(null);
          } else {
            // this sends through parseDate and needs to be in dateFormat
            input.onChange(moment(dates[0]).format("YYYY-MM-DD"));
          }
        }}
      />

      {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
      {children}
    </InputWrapper>
  );
};
