import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useParams } from "react-router-dom";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { atLeastOneFieldRequired } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";

export const AddNonCoveredCodesModal = ({ payor, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const { payorId } = useParams();
  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);

    const { cptCodeIds, ...rest } = values;
    const createPayloads = cptCodeIds.map((cptCodeId) => snakifyKeys({ cptCodeId, ...rest }));

    try {
      const resValues = await Promise.allSettled(
        createPayloads.map(async (p) => {
          await api.post("/admin/cpt_coverage_denials", { cpt_coverage_denial: p });
        })
      );

      const failures = resValues.filter((v) => v.status === "rejected");
      const successes = resValues.filter((v) => v.status !== "rejected");

      if (onAdd) {
        onAdd({ failures, successes });
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Non-Covered CPT Codes
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Non-Covered CPT Codes</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={handleCreate} initialValues={{ payorId }}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    isMulti
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/cpt_codes.json"
                    label="Cpt Codes"
                    name="cptCodeIds"
                    distinct={true}
                    validate={atLeastOneFieldRequired("cptCodeIds")}
                  />
                  <Field component={TextInput} type="hidden" name="payorId" />
                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
