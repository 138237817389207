import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "./DetailedErrorAlert";
import Case from "case";
import { AttachToCaseForm } from "./AttachToCaseForm";
import { Paperclip } from "lucide-react";

export const attachmentCategoryOptions = [
  { value: "other", label: "Other" },
  { value: "fax_inbound", label: "Inbound Faxes" },
  { value: "fax_outbound", label: "Outbound Faxes" },
  { value: "insurance_info", label: "Insurance Info/Face Sheet/Card" },
  { value: "patient_medical_records", label: "Patient Medical Records" },
  { value: "payor_correspondence", label: "Payer Correspondence" },
  { value: "prior_auth_request_form", label: "Prior Auth Request Form" },
  { value: "prior_auth_requests", label: "Prior Auth Results" },
  { value: "test_requisition", label: "Test Requisition/Questionnaire" },
  { value: "bi_results", label: "Benefits Investigation Results" },
  { value: "result_document", label: "Result PDF" },
];

export const AttachButton = ({
  type,
  error,
  onSubmit,
  disabled = false,
  withCategory = true,
  className,
  size = "xs",
  acknowledgements,
  onLoadCase,
}) => {
  const [open, setOpen] = useState(false);

  const toggleModal = () => setOpen(!open);
  const onClick = (e) => {
    e.preventDefault();
    toggleModal();
  };

  return (
    <>
      <Button size={size} disabled={disabled} className="btn-icon" onClick={onClick} outline title="Link to Case">
        <Paperclip />
      </Button>
      <Modal isOpen={open} toggle={toggleModal} className={className}>
        <ModalHeader toggle={toggleModal}>{`Link ${Case.title(type)} to Case`}</ModalHeader>
        <ModalBody>
          <DetailedErrorAlert error={error} />
          <AttachToCaseForm
            onSubmit={onSubmit}
            onLoadCase={onLoadCase}
            type={type}
            withCategory={withCategory}
            acknowledgements={acknowledgements}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
