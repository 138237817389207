import { useCallback } from "react";
import { Link } from "react-router-dom";

import { CountCell } from "../../components/grid/cells/CountCell";
import { Toolbar } from "../../components/Toolbar";
import { AdministrateEnumFilter } from "../components/AdministrateEnumFilter";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { Routes } from "../Routes";
import { DESTROY, makeColumns, useAdministrateTable, useCreatePermission } from "../utils";

import { AddPortalModal } from "./components/AddPortalModal";
import { PortalActionCell } from "./components/PortalActionCell";

const useColumns = makeColumns(() => [
  {
    id: "name",
    accessor: "name",
    Header: "Name",
    Cell: ({ row, value }) => <Link to={Routes.portal.url(row.original.id)}>{value}</Link>,
  },
  {
    id: "payors.id",
    accessor: "payorCount",
    Header: "Payors",
    Cell: ({ value }) => <CountCell count={value} label="Payors" />,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="id" searchParam="name" />,
  },
  {
    id: "labs.id",
    accessor: "labCount",
    Header: "Labs",
    Cell: ({ value }) => <CountCell count={value} label="Labs" />,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/labs.json" valueKey="id" searchParam="lab_name" />,
  },
  {
    id: "actions",
    accessor: "Actions",
    Cell: PortalActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PortalsIndexPage = () => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const [canCreate] = useCreatePermission("BenefitManagementPortal");
  const columns = useColumns();

  const onAdd = useCallback(
    (values) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`New Portal "${values.name} Added.`);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Portal Management">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">{canCreate && <AddPortalModal onAdd={onAdd} color="primary" />}</Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/benefit_management_portals.json"}
        resourceName={"benefit_management_portal"}
        onFilteredChange={setTableFilters}
        permissions={[DESTROY]}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
