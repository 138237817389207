import React, { useMemo } from "react";
import { get } from "lodash";
import { UncontrolledTooltip as Tooltip } from "reactstrap";
import { MessageSquareWarningIcon } from "lucide-react";

export const ProvidedInfoCell = ({ row, value, column }) => {
  const { index } = row;
  const id = useMemo(() => `_${column.id}_${index}`, [column.id, index]);

  if (!column.providedInfoAccessor) {
    throw new Error("ProvidedInfoCell rendered on a column that doesn't have a providedInfoAccessor.");
  }
  const providedValue =
    typeof column.providedInfoAccessor === "string"
      ? get(row.original, column.providedInfoAccessor)
      : column.providedInfoAccessor(row.original, index);

  if (!providedValue) {
    console.warn("Missing provided value for field", providedValue, row);
  }

  // If providedValue is null / undefined / "" we don't display a correction
  const isCorrected = providedValue && value !== providedValue;

  return (
    <>
      <span id={id}>
        {value} {isCorrected && <MessageSquareWarningIcon className="text-warning" />}
      </span>

      {value && (
        <Tooltip trigger="hover" target={id} delay={300} offset={[0, 7]}>
          {isCorrected ? `${value} (corrected from ${providedValue})` : value}
        </Tooltip>
      )}
    </>
  );
};
