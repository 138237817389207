import React from "react";
import { YesNoPanel } from "../../../../components/wizards/YesNoPanel";
import { WizardContext } from "../../WizardContext";

export const AnyMissingInfoRemainingScreen = () => (
  <WizardContext.Consumer>
    {({ dispatch }) => (
      <>
        <YesNoPanel
          question="Does the case have any remaining missing information?"
          yesAction={() => {
            dispatch({
              type: "setAnswer",
              key: "anyRemainingMissingInfo",
              value: true,
              doTransition: true,
            });
          }}
          noAction={() => {
            dispatch({
              type: "setAnswer",
              key: "anyRemainingMissingInfo",
              value: false,
              doTransition: true,
            });
          }}
        />
      </>
    )}
  </WizardContext.Consumer>
);
