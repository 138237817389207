import React, { useState, useCallback } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";
import { DateInput } from "../../../components/inputs/final_form/DateInput";

export const MonthlyBillingReportModal = ({ onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = async (values) => {
    setError(null);

    try {
      const { data } = await api.get(
        `/admin/billing_rates/billing_report?${encodeURIComponent("billing_report[start_date]")}=${values.startDate}&commit=Generate Report`
      );
      onCreate();
      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Monthly Billing Report
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="md">
        <ModalHeader toggle={toggleModal}>Monthly Billing Report</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />
          <Form onSubmit={handleCreate}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field component={DateInput} required label="Report Date" name="startDate" initialValue={firstDay} />
                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
