import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { PlanTypeNetworkStatusToggle } from "../../payors/components/PlanTypeNetworkStatusToggle";
import { useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const PayorPlanTypeNetworkStatusActionCell = ({ payorId, payorName, ...row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const { labId, labName, payorLabSettingId, id: planTypeNetworkSettingId, planTypeGroup, inNetwork } = row.original;
  const [canUpdate] = useUpdatePermission("PlanTypeNetworkSetting", planTypeNetworkSettingId);

  const handleChange = (msg) => {
    setMessage(msg);
    refresh();
  };

  const handleError = (msg) => {
    setError(msg);
    refresh();
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      {canUpdate && (
        <PlanTypeNetworkStatusToggle
          payorId={payorId}
          payorName={payorName}
          inNetwork={inNetwork}
          planTypeGroup={planTypeGroup}
          payorLabSettingId={payorLabSettingId}
          planTypeNetworkSettingId={planTypeNetworkSettingId}
          labId={labId}
          labName={labName}
          onChange={handleChange}
          onError={handleError}
          disabled={!canUpdate}
        />
      )}

      <History entityName="PlanTypeNetworkSetting" subjectId={planTypeNetworkSettingId ?? "unspecified"} />
    </div>
  );
};
