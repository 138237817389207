import { Field, useField } from "react-final-form";
import { Alert, Card, CardText, FormText } from "reactstrap";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { Fieldset } from "../components/inputs/final_form/Fieldset";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormRow } from "../components/forms/FormRow";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { DateInput } from "../components/inputs/final_form/DateInput";
import { numericInRange, required } from "../util/validators";
import { LoadingButton } from "../components/LoadingButton";
import { isEmpty, noop, find } from "lodash";
import { trackEvent } from "../util/track";
import { FieldsetWrapper, notAllCodes } from "../submit_right_direction/SubmitRightTestForm";
import { humanizeString } from "../util/formatters";

const REQUIRED_STATUS_FIELDS = {
  approved: ["paperSubmissionResponse.authorizationNumber", "paperSubmissionResponse.authorizationDate"],
  denied: [],
  documented_clean_claim: ["paperSubmissionResponse.submissionReferenceNumber"],
  medicalreview: [],
  partially_approved: [
    "paperSubmissionResponse.authorizationNumber",
    "paperSubmissionResponse.authorizationDate",
    "paperSubmissionResponse.approvedCptCodes",
  ],
  submitted: [],
  rejected: [],
};

const DISPLAY_STATUS_FIELDS = {
  approved: [
    "paperSubmissionResponse.authorizationNumber",
    "paperSubmissionResponse.authorizationDate",
    "paperSubmissionResponse.authorizationEffectiveDate",
    "paperSubmissionResponse.authorizationExpirationDate",
  ],
  denied: ["paperSubmissionResponse.denialReasonCode", "paperSubmissionResponse.submissionReferenceNumber"],
  documented_clean_claim: ["paperSubmissionResponse.submissionReferenceNumber"],
  medicalreview: ["paperSubmissionResponse.submissionReferenceNumber", "paperSubmissionResponse.expectedCloseDate"],
  partially_approved: [
    "paperSubmissionResponse.authorizationNumber",
    "paperSubmissionResponse.authorizationDate",
    "paperSubmissionResponse.authorizationEffectiveDate",
    "paperSubmissionResponse.authorizationExpirationDate",
    "paperSubmissionResponse.approvedCptCodes",
    "paperSubmissionResponse.deniedCptCodes",
    "paperSubmissionResponse.denialReasonCode",
  ],
  code_specific_result: [
    "paperSubmissionResponse.approvedCptCodes",
    "paperSubmissionResponse.deniedCptCodes",
    "paperSubmissionResponse.documentedCleanClaimCptCodes",
  ],
  submitted: [],
  rejected: [],
};

const StatusDependentTestField = ({ name, displayCondition, requiredCondition, ...props }) => {
  if (!displayCondition) {
    return null;
  }

  return <Field name={`${name}`} required={requiredCondition} validate={requiredCondition ? required : noop} {...props} />;
};

export const SubmitRightPaperResponseForm = ({
  paperResponses,
  options,
  handleSubmit,
  form,
  values,
  confirmed,
  verifiedCptCodeOptions,
  getPaperResponseObj,
  denialReasonCodeOptions,
  paSubstatuses,
  paStatuses,
  request,
  setConfirmedFunction,
  caseId,
  setShowFax,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const [approvedCodes, setApprovedCodes] = useState(verifiedCptCodeOptions);
  const [deniedCodes, setDeniedCodes] = useState(verifiedCptCodeOptions);
  const [cleanClaimCodes, setCleanClaimCodes] = useState(verifiedCptCodeOptions);

  const paperResponse = useField("paperSubmissionResponse.message", {
    subscription: { value: true },
  })?.input?.value;

  const approvedCptCodesValue = useField("paperSubmissionResponse.approvedCptCodes", { subscription: { value: true } })?.input?.value;
  const deniedCptCodesValue = useField("paperSubmissionResponse.deniedCptCodes", { subscription: { value: true } })?.input?.value;
  const cleanClaimCptCodesValue = useField("paperSubmissionResponse.documentedCleanClaimCptCodes", { subscription: { value: true } })?.input
    ?.value;

  const handleApprovedChange = (selectedCodes) => {
    const selectedValues = selectedCodes.map((code) => code.value);
    const nonSelectedValues = verifiedCptCodeOptions.filter((code) => !selectedValues.includes(code.value));

    // Filter out these values from the denied codes and clean claim
    setDeniedCodes(nonSelectedValues.filter((code) => !cleanClaimCptCodesValue.includes(code.value)));
    setCleanClaimCodes(nonSelectedValues.filter((code) => !deniedCptCodesValue.includes(code.value)));
  };

  const handleDeniedChange = (selectedCodes) => {
    const selectedValues = selectedCodes.map((code) => code.value);
    const nonSelectedValues = verifiedCptCodeOptions.filter((code) => !selectedValues.includes(code.value));

    // Filter out these values from the approved codes and clean claim
    setApprovedCodes(nonSelectedValues.filter((code) => !cleanClaimCptCodesValue.includes(code.value)));
    setCleanClaimCodes(nonSelectedValues.filter((code) => !approvedCptCodesValue.includes(code.value)));
  };

  const handleCleanClaimChange = (selectedCodes) => {
    const selectedValues = selectedCodes.map((code) => code.value);
    const nonSelectedValues = verifiedCptCodeOptions.filter((code) => !selectedValues.includes(code.value));

    // Filter out these values from the approved codes and denied
    setApprovedCodes(nonSelectedValues.filter((code) => !deniedCptCodesValue.includes(code.value)));
    setDeniedCodes(nonSelectedValues.filter((code) => !approvedCptCodesValue.includes(code.value)));
  };

  const setConfirmed = useCallback(
    (value) => {
      setConfirmedFunction(value);
    },
    [setConfirmedFunction]
  );

  // Upon choosing a paper response, we clear out the confirmation.
  // We don't want this hook to fire any other time; the linter is wrong here.
  useEffect(() => {
    if (confirmed) {
      setConfirmed(false);
      setShowFax(false);
    }
  }, [paperResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  const responseObj = !isEmpty(paperResponse) && getPaperResponseObj(paperResponse);
  const { status } = responseObj;

  const displayForStatus = useCallback((field) => (DISPLAY_STATUS_FIELDS[status] || []).includes(field), [status]);
  const requiredForStatus = useCallback((field) => (REQUIRED_STATUS_FIELDS[status] || []).includes(field), [status]);

  const parseStatusAndSubstatus = (responseObj) => {
    const parsedStatus = responseObj.status;
    const parsedSubstatus = responseObj.substatus;

    form.change("paperSubmissionResponse.status", parsedStatus);
    form.change("paperSubmissionResponse.substatus", parsedSubstatus);
  };

  const isCodeSpecificResult = useMemo(() => status === "code_specific_result", [status]);

  const displayAuthorizationFields = useCallback(
    (field) => {
      return (approvedCptCodesValue?.length > 0 && isCodeSpecificResult) || displayForStatus(field);
    },
    [approvedCptCodesValue?.length, displayForStatus, isCodeSpecificResult]
  );

  const displayDenialReason = useCallback(
    (field) => {
      return (deniedCptCodesValue?.length > 0 && isCodeSpecificResult) || displayForStatus(field);
    },
    [deniedCptCodesValue?.length, displayForStatus, isCodeSpecificResult]
  );

  const requiresAuthorizationFields = useCallback(
    (field) => {
      return (approvedCptCodesValue?.length > 0 && isCodeSpecificResult) || requiredForStatus(field);
    },
    [approvedCptCodesValue?.length, requiredForStatus, isCodeSpecificResult]
  );

  const requiresDenialReason = useCallback(
    (field) => {
      return (deniedCptCodesValue?.length > 0 && isCodeSpecificResult) || requiredForStatus(field);
    },
    [deniedCptCodesValue?.length, requiredForStatus, isCodeSpecificResult]
  );

  const handleConfirm = () => {
    setLoading(true);

    if (responseObj?.message !== undefined) {
      form.change("paperSubmissionResponse.paperResponseId", responseObj.id);
      form.change("paperSubmissionResponse.submitRightDirection", responseObj.submit_right_direction);
      form.change("paperSubmissionResponse.cannedComment", responseObj.canned_comment);

      if (responseObj.submit_right_direction == "status_the_case") {
        parseStatusAndSubstatus(responseObj);

        if (responseObj?.denial_reason_code) {
          form.change("paperSubmissionResponse.denialReasonCode", responseObj.denial_reason_code);
        }
      } else if (responseObj.submit_right_direction == "task_out_for_n_number_of_days_to_try_again") {
        form.change("paperSubmissionResponse.taskName", responseObj.task_name);
        form.change("paperSubmissionResponse.taskDuration", responseObj.task_duration);

        trackEvent("task_out_case_sr2_paper_response", caseId, {
          step: "task_out_case_sr2_paper_response",
        });
      } else if (responseObj.submit_right_direction == "resubmit_to_portal") {
        trackEvent("resubmit_to_portal_sr2_paper_response", caseId, {
          step: "resubmit_to_portal_sr2_paper_response",
        });
      }
    }
    trackEvent("paper_submission_confirm_button", caseId, {
      step: "paper_submission_confirm_button",
    });

    setConfirmed(true);
    setLoading(false);
  };

  return (
    <>
      <Fieldset legend="Paper Submission Results">
        {(responseObj?.status === "code_specific_result" || responseObj?.status === "partially_approved") &&
          verifiedCptCodeOptions.length === 1 && (
            <Card color="warning" className="text-center py-4 bg-opacity-10 border-0 mb-2">
              <CardText>This Case only has 1 CPT Code, use the correct Submit Right Response instead.</CardText>
            </Card>
          )}

        <FormRow>
          <Field
            label="Paper Submission Response"
            name="paperSubmissionResponse.message"
            component={SelectInput}
            options={options}
            validate={required}
          >
            <FormText>Change it to restart...</FormText>
          </Field>
        </FormRow>

        {confirmed && responseObj?.submit_right_direction === "task_out_for_n_number_of_days_to_try_again" && (
          <>
            <Field component={TextInput} label="Task Name" name="paperSubmissionResponse.taskName" disabled={true} />
            <Field
              component={TextInput}
              label="Task Duration(Days)"
              name="paperSubmissionResponse.taskDuration"
              validate={numericInRange(0, 90)}
              min="0"
              max="90"
              disabled={true}
            />
          </>
        )}

        {confirmed && responseObj?.submit_right_direction === "status_the_case" && (
          <>
            <Field
              component={TextInput}
              label="Status"
              format={(val) => humanizeString(val || "None")}
              name="paperSubmissionResponse.status"
              disabled={true}
            />
            <Field
              component={TextInput}
              label="Substatus"
              format={(val) => humanizeString(val || "None")}
              name="paperSubmissionResponse.substatus"
              disabled={true}
            />

            <StatusDependentTestField
              requiredCondition={requiredForStatus("paperSubmissionResponse.submissionReferenceNumber")}
              displayCondition={displayForStatus("paperSubmissionResponse.submissionReferenceNumber")}
              name="paperSubmissionResponse.submissionReferenceNumber"
              component={TextInput}
              label="Reference Number"
              initialValue={request.submission_reference_number}
            />

            <FieldsetWrapper labelText="Approval Fields" displayCondition={isCodeSpecificResult}>
              <StatusDependentTestField
                requiredCondition={requiredForStatus("paperSubmissionResponse.approvedCptCodes")}
                displayCondition={displayForStatus("paperSubmissionResponse.approvedCptCodes")}
                name="paperSubmissionResponse.approvedCptCodes"
                component={SelectInput}
                isMulti
                options={approvedCodes}
                onUpdate={handleApprovedChange}
                label="Approved CPT Codes"
                placeholder="Select one or more CPT Codes..."
                validate={notAllCodes(verifiedCptCodeOptions)}
              />

              <StatusDependentTestField
                requiredCondition={requiresAuthorizationFields("paperSubmissionResponse.authorizationNumber")}
                displayCondition={displayAuthorizationFields("paperSubmissionResponse.authorizationNumber")}
                name="paperSubmissionResponse.authorizationNumber"
                component={TextInput}
                label="Authorization Number"
                initialValue={request.authorization_number}
              />

              <StatusDependentTestField
                requiredCondition={requiresAuthorizationFields("paperSubmissionResponse.authorizationDate")}
                displayCondition={displayAuthorizationFields("paperSubmissionResponse.authorizationDate")}
                name="paperSubmissionResponse.authorizationDate"
                component={DateInput}
                label="Authorization Date"
                initialValue={request.authorization_date}
              />

              <StatusDependentTestField
                requiredCondition={requiredForStatus("paperSubmissionResponse.authorizationEffectiveDate")}
                displayCondition={displayAuthorizationFields("paperSubmissionResponse.authorizationEffectiveDate")}
                name="paperSubmissionResponse.authorizationEffectiveDate"
                component={DateInput}
                label="Authorization Effective Date"
                initialValue={request.authorization_effective_date}
              />

              <StatusDependentTestField
                requiredCondition={requiredForStatus("paperSubmissionResponse.authorizationExpirationDate")}
                displayCondition={displayAuthorizationFields("paperSubmissionResponse.authorizationExpirationDate")}
                name="paperSubmissionResponse.authorizationExpirationDate"
                component={DateInput}
                label="Authorization Expiration Date"
                initialValue={request.authorization_expiration_date}
              />
            </FieldsetWrapper>

            <FieldsetWrapper labelText="Denial Fields" displayCondition={isCodeSpecificResult}>
              <StatusDependentTestField
                requiredCondition={requiredForStatus("paperSubmissionResponse.deniedCptCodes")}
                displayCondition={displayForStatus("paperSubmissionResponse.deniedCptCodes")}
                name="paperSubmissionResponse.deniedCptCodes"
                component={SelectInput}
                isMulti
                options={deniedCodes}
                onUpdate={handleDeniedChange}
                label="Denied CPT Codes"
                placeholder="Select one or more CPT Codes..."
                validate={notAllCodes(verifiedCptCodeOptions)}
              />

              <StatusDependentTestField
                requiredCondition={requiresDenialReason("paperSubmissionResponse.denialReasonCode")}
                displayCondition={displayDenialReason("paperSubmissionResponse.denialReasonCode")}
                name="paperSubmissionResponse.denialReasonCode"
                component={SelectInput}
                label="Denial Reason Code"
                options={denialReasonCodeOptions}
                initialValue={request.denial_reason_code}
                disabled={responseObj?.denial_reason_code ? true : false}
              />
            </FieldsetWrapper>

            <FieldsetWrapper labelText="Clean Claim Fields" displayCondition={isCodeSpecificResult}>
              <StatusDependentTestField
                requiredCondition={requiredForStatus("paperSubmissionResponse.documentedCleanClaimCptCodes")}
                displayCondition={displayForStatus("paperSubmissionResponse.documentedCleanClaimCptCodes")}
                name="paperSubmissionResponse.documentedCleanClaimCptCodes"
                component={SelectInput}
                isMulti
                options={cleanClaimCodes}
                onUpdate={handleCleanClaimChange}
                label="Documented Clean Claim CPT Codes"
                placeholder="Select one or more CPT Codes..."
                validate={notAllCodes(verifiedCptCodeOptions)}
              />
            </FieldsetWrapper>

            <StatusDependentTestField
              displayCheck={displayForStatus}
              requiredCheck={requiredForStatus}
              name="paperSubmissionResponse.expectedCloseDate"
              component={DateInput}
              label="Expected Close Date"
            />
          </>
        )}

        {confirmed && responseObj && (
          <>
            <Field component={TextInput} label="Canned Comment" name="paperSubmissionResponse.cannedComment" />
          </>
        )}

        {confirmed && responseObj?.submit_right_direction === "re_send_fax" && <Alert color="info">A Fax Form will open.</Alert>}

        {confirmed && responseObj?.submit_right_direction === "resubmit_to_portal" && (
          <Alert color="info">
            A new submission will be allowed.
            <br />
            You'll be redirected to Submission Step.
          </Alert>
        )}

        {!confirmed && (
          <LoadingButton color="primary" onClick={handleConfirm} loading={loading} disabled={loading || isEmpty(paperResponse)}>
            Confirm Response
          </LoadingButton>
        )}
      </Fieldset>
    </>
  );
};
