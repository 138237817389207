import cn from "classnames";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { DropTarget } from "react-dnd";
import { findDOMNode } from "react-dom";
import { FIELD } from "../ItemTypes";
import {
  calculateClientScaleFactor,
  calculateClientPosition,
  calculateNaturalPosition,
  calculateNewPosition,
  uniqid,
  formatFieldPosition,
} from "../utils";
import { updatePageDefinition } from "../store";
import { DraggableTag } from "./DraggableTag";

const pageTarget = {
  drop(_props, monitor, component) {
    // Calculate new position
    const item = monitor.getItem();
    const bounds = findDOMNode(component).getBoundingClientRect();
    const delta = monitor.getDifferenceFromInitialOffset();
    const absolute = monitor.getSourceClientOffset();
    const { newX, newY } = calculateNewPosition(item, bounds, delta, absolute);

    const { naturalX, naturalY } = calculateNaturalPosition(newX, newY, component.props.pageDefinition, component.props.pageSize);

    // Update state object
    const id = item.id;
    const newPos = formatFieldPosition(component.props.pageIdx, naturalX, naturalY);

    // Dispatch event
    component.props.onUpdateField(id, newPos);
  },
};

const collect = (connect, _monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
  };
};

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  renderDraggableTag = (field, idx) => {
    const { onSelectField, selectedField, pageDefinition, pageSize } = this.props;

    const x = _.get(field, "position.at.x");
    const y = _.get(field, "position.at.y");
    const id = _.get(field, "id", uniqid());

    const isSelected = selectedField && id === selectedField.id;

    const clientPosition = calculateClientPosition(x, y, pageDefinition, pageSize);
    const scaleFactors = calculateClientScaleFactor(pageDefinition, pageSize);

    if (!clientPosition) {
      return;
    }

    return (
      <DraggableTag
        onClick={(e) => {
          e.stopPropagation();
          onSelectField(id);
        }}
        fieldDefinition={field}
        id={id}
        x={clientPosition.clientX}
        y={clientPosition.clientY}
        isSelected={isSelected}
        label={field.label}
        key={idx}
        type={field.type}
        scaleFactors={scaleFactors}
        options={field.options}
      />
    );
  };

  getImageSizes = () => ({
    naturalWidth: this.pageImage.naturalWidth,
    naturalHeight: this.pageImage.naturalHeight,
    clientWidth: this.pageImage.clientWidth,
    clientHeight: this.pageImage.clientHeight,
  });

  setImageSizes = () => {
    const { onUpdatePageDefinitions, pageIdx } = this.props;
    this.props.onUpdatePageDefinitions(pageIdx, this.getImageSizes());
  };

  componentDidMount() {
    window.addEventListener("resize", this.setImageSizes);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setImageSizes);
  }

  render() {
    const { loading } = this.state;
    const { fields, imgSrc, connectDropTarget } = this.props;

    const fieldTags = _.map(fields, this.renderDraggableTag);

    let pageClasses = cn("fde__page", {
      "fde__page--loading": loading,
    });

    return connectDropTarget(
      <div>
        {loading && <span className="text-muted small">Loading Page...</span>}

        <div className={pageClasses} style={{ position: "relative" }} ref={(page) => (this.page = page)}>
          {!loading && fieldTags}

          <img
            src={imgSrc}
            ref={(el) => (this.pageImage = el)}
            onLoad={() => {
              this.setState({ loading: false });
              this.setImageSizes();
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    pageDefinition: state.pageDefinitions[ownProps.pageIdx],
    pageSize: state.pageSizes[ownProps.pageIdx],
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdatePageDefinitions: (pageIndex, page) => {
    dispatch(updatePageDefinition({ pageIndex, page }));
  },
});

const TargetPage = DropTarget(FIELD, pageTarget, collect)(Page);

export const DroppablePage = connect(mapStateToProps, mapDispatchToProps)(TargetPage);
