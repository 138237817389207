import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { Form } from "react-final-form";
import { camelizeKeys, snakifyKeys } from "../../../../util/helpers";
import { PaperResponseForm } from "./PaperResponseForm";
import { useCallback, useState } from "react";
import { api, extractErrorMessage } from "../../../../util/api";

export const EditPaperResponseModal = ({ paperResponse, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleUpdate = useCallback(
    async (params) => {
      setError(null);

      try {
        const res = await api.patch(`/admin/paper_responses/${params.id}`, snakifyKeys({ paper_response: params }));

        if (onUpdate) {
          onUpdate(res.data.resource);
        }

        setOpen(false);
      } catch (err) {
        setError(extractErrorMessage(err));
      }
    },
    [onUpdate]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Paper Response</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleUpdate} initialValues={camelizeKeys({ ...paperResponse })}>
            {(props) => <PaperResponseForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
