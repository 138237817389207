import _ from "lodash";
import React, { useCallback, useState } from "react";
import { FormText } from "reactstrap";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { LoadingIcon } from "../components/LoadingIcon";
import { api } from "../util/api";
import { useDebounced } from "../util/hooks";

const fetchSuggestedPayor = async (val) => {
  try {
    const response = await api.get(`/request_drafts/prefix_mapping.json?member_id=${val}`);
    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const checkPayorSupport = async (payor) => {
  const req = await api.get(`/payors/check_support?id=${payor.id}`);
  return req.data.is_supported;
};

export const InsuranceMemberIdInput = ({ selectedPayorId, input, onMatchFound, ...props }) => {
  const { onChange, value } = input;
  const [checkingMatchingPayors, setCheckingMatchingPayors] = useState(false);
  const [matchingPayor, setMatchingPayor] = useState(null);
  const [memberIdValue, setMemberIdValue] = useState(value);

  const debouncedFetchSuggestedPayor = useDebounced(fetchSuggestedPayor, 300);

  const handleChange = useCallback(
    async (val) => {
      setMemberIdValue(val);

      if (onChange) {
        onChange(val);
      }

      if (val.length < 1) {
        setMatchingPayor(null);
        return;
      }

      setCheckingMatchingPayors(true);

      try {
        const matchingPayors = await debouncedFetchSuggestedPayor(val);
        const suggestedPayor = _.get(matchingPayors, "0.payor", null);
        // If there's a matching payor that isn't supported, we still set it here
        setMatchingPayor(suggestedPayor);
        isPayorSupported = await checkPayorSupport(suggestedPayor);

        // Despite its name, onMatchFound is called with null if there is no matching payor found
        if (suggestedPayor && isPayorSupported) {
          onMatchFound(suggestedPayor.public_id, suggestedPayor.name);
        } else {
          onMatchFound(null, null);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setCheckingMatchingPayors(false);
      }
    },
    [onChange, setCheckingMatchingPayors, onMatchFound, debouncedFetchSuggestedPayor]
  );

  const suggestedPayorName = matchingPayor?.name;
  const suggestedPayorId = matchingPayor?.public_id;

  return (
    <>
      <TextInput {...props} input={{ ...input, value: memberIdValue, onChange: (e) => handleChange(e.target.value) }}>
        <>
          {checkingMatchingPayors ? (
            <FormText>
              <LoadingIcon /> Checking for known payors...
            </FormText>
          ) : (
            <>
              {suggestedPayorName &&
                (checkPayorSupport(matchingPayor) ? (
                  suggestedPayorId === selectedPayorId && (
                    <FormText>
                      The member id you provided is associated with <strong>{suggestedPayorName}</strong>. The Primary Insurance has been
                      changed to <strong>{suggestedPayorName}</strong>.
                    </FormText>
                  )
                ) : (
                  <FormText>
                    The member id you provided is associated with <strong>{suggestedPayorName}</strong>. This Payor is not supported by your
                    organization.
                  </FormText>
                ))}
            </>
          )}
        </>
      </TextInput>
    </>
  );
};
