import _, { uniqBy } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { api } from "../../util/api";
import { useDebounced } from "../../util/hooks";
import { StyledAsyncPaginate as AsyncPaginate } from "./StyledSelect";

const mapResourcesToOptions = (resources, valueKey, labelKey) => resources.map((r) => ({ value: r[valueKey], label: r[labelKey] }));

export const RemotePaginateSelector = ({
  resourceUrl,
  onChange,
  onUpdate,
  value,
  valueKey = "id",
  labelKey = "name",
  searchParam = "search",
  distinct = true,
  kept = false,
  initialOptions = [],
  excludeOptions = [],
  filterOptions = [],
  per = 10,
  scope,
  dataKey,
  onOptionsChange,
  onOptionsLoad,
  extraParams,
  resourceMappingFn,
  returnOptionsFromOnChange,
  loadDefaultOptions,
  ...rest
}) => {
  const [lastOptions, setLastOptions] = useState(() => initialOptions ?? []);

  const handleChangeMulti = useCallback((selected) => (selected ? onChange(selected.map((v) => v.value)) : onChange([])), [onChange]);
  const handleChangeSingle = useCallback((selected) => (selected ? onChange(selected.value) : onChange(null)), [onChange]);

  const handleChange = useCallback(
    (selected) => {
      onUpdate?.(selected);

      if (returnOptionsFromOnChange) {
        return onChange(selected);
      } else {
        if (rest.isMulti) {
          handleChangeMulti(selected);
        } else {
          handleChangeSingle(selected);
        }
      }
    },
    [onUpdate, onChange, handleChangeMulti, handleChangeSingle, rest.isMulti, returnOptionsFromOnChange]
  );

  // we are allowing a new param to pass back the
  // list of options to the specified setter to the component
  // rendering the administrate select component
  const updateLastOptions = useCallback(
    (opts) => {
      setLastOptions((old) => {
        const newOptions = uniqBy([...old, ...opts], "value");
        onOptionsChange?.(newOptions);

        return newOptions;
      });
    },
    [setLastOptions, onOptionsChange]
  );

  const loadResources = useDebounced(async (search, loadedOptions, { page }) => {
    try {
      const params = { scope, distinct, kept, page, per, ids: filterOptions, ...extraParams };
      params[searchParam] = search;

      const req = await api.get(resourceUrl, { params });

      const { meta } = req.data;
      const resources = req.data[dataKey];
      const options = resourceMappingFn ? resourceMappingFn(resources) : mapResourcesToOptions(resources, valueKey, labelKey);
      const hasMore = Boolean(meta?.page && meta?.total_pages && meta.page < meta.total_pages);

      const newOptions = _.differenceBy(options, loadedOptions, "value");

      // Now that we've loaded new options, make them available to find from the select
      updateLastOptions(newOptions);

      return {
        options: newOptions,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      console.error(err);

      return {
        options: [],
        hasMore: false,
        additional: { page: 1 },
      };
    }
  }, 300);

  // If we get a function to load default options, call that function and add it to our options cache
  useEffect(() => {
    const doLoad = async () => {
      if (loadDefaultOptions) {
        const defaultOptions = await loadDefaultOptions();
        updateLastOptions(defaultOptions);
      }
    };

    doLoad();
  }, [loadDefaultOptions, updateLastOptions]);

  // We are explicitly using == here because _sometimes_ we are
  // deserializing select values from the URL or other locations
  // and we end up needing to compare a numeric 2 to a string 2
  // and want them to be equivalent
  const selectedValue = rest.isMulti
    ? lastOptions.filter((o) => value && value.some((v) => o.value == v))
    : lastOptions.find((option) => option.value == value);

  const disabled = rest.disabled || rest.readOnly || rest.isDisabled;

  return (
    <AsyncPaginate
      {...rest}
      isDisabled={disabled}
      isSearchable
      cacheOptions
      defaultOptions
      value={selectedValue ?? null}
      loadOptions={loadResources}
      filterOption={(o) => !excludeOptions.includes(o) && !excludeOptions.includes(o.value)}
      onChange={handleChange}
      additional={{ page: 1 }}
      includeBlank
    />
  );
};
