import { Link } from "react-router-dom";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { Routes } from "../../Routes";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreateInsurancePayorMappingModal } from "../components/CreateInsurancePayorMappingModal";
import { ImportInsurancePayorMappingModal } from "../components/ImportInsurancePayorMappingModal";
import { InsurancePayorMappingActionCell } from "../components/InsurancePayorMappingActionCell";
import { History } from "../../tools/sandbox/release/History";

const useColumns = makeColumns(() => [
  {
    id: "lab",
    accessor: "lab",
    Header: "Lab",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/labs.json?lab[direction]=asc&lab[order]=lab_name&per_page=100"
        valueKey="lab_name"
        labelKey="lab_name"
        kept={true}
      />
    ),
    Cell: ({ row, value }) => (
      <Link to={Routes.lab.url(value.id)}>
        <span className="token">{value.labName}</span>
      </Link>
    ),
  },
  {
    id: "payor",
    accessor: "payor.name",
    Header: "Verified Payor Name",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/payors.json?payor[direction]=asc&payor[order]=payor_name&per_page=100"
        valueKey="name"
        labelKey="name"
        kept={true}
      />
    ),
    Cell: ({ row, value }) => (
      <Link to={Routes.payor.url(row.original.payor?.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
  },
  {
    id: "insurance_name",
    accessor: "insuranceName",
    Header: "Provided Insurance Name",
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: InsurancePayorMappingActionCell,
    disableFilters: true,
    disableSortBy: true, //action cell
  },
]);

export const InsurancePayorMappingIndexPage = () => {
  const [canCreate] = useCreatePermission("InsurancePayorMapping");
  const columns = useColumns();

  const onCreate = (newMapping) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created ${newMapping.insurance_name}`);
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  return (
    <SystemConfigLayout title="Insurance Payor Mappings">
      <Toolbar className="align-items-center mb-3">
        {canCreate && (
          <Toolbar className="ms-auto">
            <AdministrateBackgroundExportButton
              outline
              className="me-2"
              exportUrl="/admin/insurance_payor_mappings/export"
              filters={allFilters}
              ref={tableRef}
            />
            <History entityName={"InsurancePayorMapping"} />
            <ImportInsurancePayorMappingModal outline className="mx-2" onImport={onImport} />
            <CreateInsurancePayorMappingModal color="primary" onCreate={onCreate} />
          </Toolbar>
        )}
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/insurance_payor_mappings.json"}
        resourceName={"insurance_payor_mapping"}
        permissions={[UPDATE, DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
