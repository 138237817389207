import { createRoot } from "react-dom/client";
import { NewAuthTokenForm } from "../../auth_token/NewAuthTokenForm";

export const initNewAuthTokenForm = () => {
  const newAuthTokenContainer = document.getElementById("new_auth_token");

  if (newAuthTokenContainer) {
    const root = createRoot(newAuthTokenContainer);
    const { tokenTypes } = newAuthTokenContainer.dataset;

    root.render(<NewAuthTokenForm tokenTypes={JSON.parse(tokenTypes)} />);
  }
};
