import React from "react";
import { CollapsePanel } from "./CollapsePanel";
import { DetailGrid, DetailGridItem } from "./DetailGrid";

export const SeerezOtherInfo = ({ seerezOtherInfo }) => {
  return (
    <CollapsePanel labelText="Other Info">
      <DetailGrid>
        {seerezOtherInfo.authorizationNumber && <DetailGridItem title="Authorization Number" value={seerezOtherInfo.authorizationNumber} />}
        {seerezOtherInfo.denialNumber && <DetailGridItem title="Denial #" value={seerezOtherInfo.denialNumber} />}
        {seerezOtherInfo.authorizationDate && <DetailGridItem title="Authorization Date" value={seerezOtherInfo.authorizationDate} />}
        {seerezOtherInfo.authorizationEffectiveDate && (
          <DetailGridItem title="Authorization Effective" value={seerezOtherInfo.authorizationEffectiveDate} />
        )}
        {seerezOtherInfo.authorizationExpirationDate && (
          <DetailGridItem title="Authorization Expiration" value={seerezOtherInfo.authorizationExpirationDate} />
        )}
        {seerezOtherInfo.uhcSrn && <DetailGridItem title="UHC-SRN" value={seerezOtherInfo.uhcSrn} />}
        {seerezOtherInfo.claimNumber && <DetailGridItem title="Claim Number" value={seerezOtherInfo.claimNumber} />}
        {seerezOtherInfo.serviceDate && <DetailGridItem title="Date of Service" value={seerezOtherInfo.serviceDate} />}
        {seerezOtherInfo.collectionDate && <DetailGridItem title="Collection Date" value={seerezOtherInfo.collectionDate} />}
      </DetailGrid>
    </CollapsePanel>
  );
};
