import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { required } from "../../../../util/validators";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";
import { LoadingButton } from "../../../../components/LoadingButton";

export const ProviderPayorNumbersForm = ({ handleSubmit, submitting }) => {
  return (
    <>
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/providers.json"
        searchParam="npi"
        label="Provider NPI"
        name="providerId"
        validate={required}
        required
      />
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/payors.json"
        searchParam="name"
        label="Payor Name"
        kept={true}
        name="payorId"
        validate={required}
        required
      />
      <Field component={TextInput} validate={required} label="Provider Payor Number" name="providerSpecificSubmissionNumber" required />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Save
      </LoadingButton>
    </>
  );
};
