import _ from "lodash";
import React, { useEffect, useCallback, useState } from "react";
import { Field, Form } from "react-final-form";
import { ReactSortable } from "react-sortablejs";
import { Button, Col, FormText, Row } from "reactstrap";
import { api, stringifyParams, useAPI } from "../util/api";
import { required } from "../util/validators";
import { CheckboxGroup } from "./CheckboxGroup";
import { DetailedErrorAlert } from "./DetailedErrorAlert";
import { DetailedAlert } from "./DetailedAlert";
import { Checkbox } from "./inputs/final_form/Checkbox";
import { TextInput } from "./inputs/final_form/TextInput";
import { LoadingButton } from "./LoadingButton";
import { LoadingIcon } from "./LoadingIcon";

const recipientsUrl = (casePublicId, skipClinicRecipient = false) => {
  let baseUrl = `/operations/fax/recipients/${casePublicId}`;

  if (skipClinicRecipient) {
    baseUrl += "?skip_clinic_recipient=true";
  }

  return baseUrl;
};

const attachmentsUrl = (casePublicId) => `/operations/fax/attachments/${casePublicId}`;
const previewUrl = "/operations/fax/preview";
const sendFaxUrl = "/operations/fax.json";

const useFaxValidator = (requireRecipients, requireAttachments) =>
  useCallback(
    (v) => {
      const errors = {};

      if (requireRecipients && (!v.fax || v.fax.length < 1) && !v.additional_fax_number) {
        errors.fax = "Please select at least one recipient";
      }

      if (requireAttachments && (!v.attachments || v.attachments.length < 1)) {
        errors.attachments = "Please select at least one attachment";
      }

      return errors;
    },
    [requireRecipients, requireAttachments]
  );

export const FaxForm = ({
  casePublicId,
  faxSendPart,
  requireRecipients = true,
  requireAttachments = true,
  initialValues = {},
  onSend,
  onCancel,
  isSubmission = false,
}) => {
  const [error, setError] = useState(null);
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState(null);
  const [sendingStatus, setSendingStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { data: attachments, setData: setAttachments, isLoading: loadingAttachments } = useAPI(attachmentsUrl(casePublicId));
  const { data: recipients, isLoading: loadingRecipients } = useAPI(recipientsUrl(casePublicId, initialValues.skip_clinic_recipient));

  useEffect(() => {
    if (sendingStatus === "sending") {
      const interval = setInterval(async () => {
        try {
          const fax_sends = await api.get(`/operations/fax/fax_sends?case_id=${casePublicId}`);
          const data = fax_sends.data;

          const res = await api.get(`/operations/fax/status?send_id=${data[data.length - 1]?.id}`);

          const status = res.data.status;
          if (status === "sent") {
            setSendingStatus("sent");
          } else if (status === "error") {
            setSendingStatus("error");
            setErrorMessage(res.data.error);
          }
        } catch (error) {
          console.error("Error checking fax status", error);
          setSendingStatus("error");
          setErrorMessage("An error occurred while checking the fax status.");
        }
      }, 5000); // Poll every 5 seconds

      return () => clearInterval(interval);
    }
  }, [sendingStatus, casePublicId]);

  const handleSubmit = async (values, form) => {
    const orderedAttachments = attachments.map((a) => a.key);

    // reorder our values to match the sorted order
    values.attachments = orderedAttachments.filter((a) => values.attachments.includes(a));
    values.is_submission = isSubmission;

    setSending(true);
    if (faxSendPart === true) {
      setSendingStatus("sending");
    }
    setError(null);

    try {
      const payload = { request_id: casePublicId, send_fax: values };
      const res = await api.post(sendFaxUrl, payload, {
        headers: { "Content-Type": "application/json" },
      });

      if (res.data.success) {
        setMessage(res.data.message);
        form.restart();
      }

      if (_.isFunction(onSend)) {
        onSend();
      }
    } catch (err) {
      console.error(err);
      if (faxSendPart === true) {
        setSendingStatus("error");
      }
      setErrorMessage("An error occurred while sending the fax.");
      setError(err);
    } finally {
      setSending(false);
    }
  };

  const validateFaxForm = useFaxValidator(requireRecipients, requireAttachments);

  if (loadingAttachments || loadingRecipients) {
    return <LoadingIcon />;
  }

  return (
    <Form onSubmit={handleSubmit} validate={validateFaxForm} initialValues={initialValues}>
      {({ handleSubmit, submitting, errors, touched, values, valid }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <DetailedErrorAlert error={error} fallbackMessage="Unexpected error while sending faxes" />
            {message && <DetailedAlert message={message} />}

            <Row>
              <Col>
                <Field required validate={required} name="to" component={TextInput} type="text" label="To Recipient">
                  <FormText>
                    Rendered as the <strong>To</strong> value on the Cover Sheet
                  </FormText>
                </Field>
              </Col>
              <Col>
                <Field required validate={required} name="from" component={TextInput} type="text" label="From Sender">
                  <FormText>
                    Rendered as the <strong>From</strong> value on the Cover Sheet
                  </FormText>
                </Field>
              </Col>
            </Row>

            <Field required validate={required} name="message" component={TextInput} type="textarea" label="Message">
              <FormText>Additional comments added to the Cover Sheet</FormText>
            </Field>

            <hr />

            <Row>
              <Col>
                <CheckboxGroup label="Recipients" required error={touched.fax && errors.fax}>
                  {recipients.map((r) => (
                    <Field name="fax" type="checkbox" component={Checkbox} id={r.key} label={r.label} value={r.key} key={r.key} />
                  ))}

                  <Field name="fax" type="checkbox" component={Checkbox} label="Other (enter below)" value="Other" />
                </CheckboxGroup>

                {values?.fax?.includes("Other") && (
                  <>
                    <Field name="additional_fax_recipient" component={TextInput} type="text" label="Additional Fax Recipient" />
                    <Field name="additional_fax_company" component={TextInput} type="text" label="Additional Fax Company" />
                    <Field
                      required
                      validate={required}
                      name="additional_fax_number"
                      component={TextInput}
                      type="text"
                      label="Additional Fax Number"
                    />
                  </>
                )}
              </Col>
              <Col>
                <CheckboxGroup
                  label="Attachments"
                  required
                  className="send_fax_attachments"
                  error={touched.attachments && errors.attachments}
                >
                  <ReactSortable list={attachments} setList={setAttachments}>
                    {attachments.map((r) => (
                      <Field name="attachments" type="checkbox" component={Checkbox} id={r.key} label={r.label} value={r.key} key={r.key} />
                    ))}
                  </ReactSortable>
                </CheckboxGroup>
              </Col>
            </Row>

            <div className="actions mt-3 d-flex flex-row justify-content-between">
              <Button
                color="link"
                disabled={!valid}
                href={`${previewUrl}?${stringifyParams({ request_id: casePublicId, send_fax: values }, true)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download Preview
              </Button>

              <div>
                {onCancel && (
                  <Button type="button" color="secondary" outline onClick={onCancel}>
                    Cancel
                  </Button>
                )}
                <div className="col-auto mb-2 me-4 text-end">
                  <LoadingButton
                    type="submit"
                    color="primary"
                    className={onCancel && "ms-2"}
                    loading={sending || submitting}
                    disabled={submitting}
                  >
                    Send Fax
                  </LoadingButton>
                </div>
                {sendingStatus === "sending" && faxSendPart && (
                  <div>
                    <p>Fax is sending, please wait...</p>
                    <div className="text-center">
                      <LoadingIcon />
                    </div>
                  </div>
                )}
                {sendingStatus === "sent" && faxSendPart && <p>Fax has been sent successfully!</p>}
                {sendingStatus === "error" && faxSendPart && (
                  <p>
                    <strong>Error:</strong> {errorMessage}
                  </p>
                )}
              </div>
            </div>
          </form>
        );
      }}
    </Form>
  );
};
