import _ from "lodash";
import React from "react";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import {
  INSURANCE_MEMBER_ID,
  VERIFIED_PAYOR,
  CASE_LAB_NO_FILTER,
  CASE_LAB_ORDER_ID,
  CASE_LAB_PATIENT_ID,
  CASE_PATIENT_DOB,
  CASE_PATIENT_NAME,
  CURRENT_STATUS_NO_FILTER,
  CURRENT_SUBSTATUS_NO_FILTER,
} from "../../../components/grid/columns";

const DetailsButton = ({ row }) => {
  const id = row.original.public_id;
  return (
    <a className="btn btn-sm btn-outline-secondary" href={`/prior_authorize_requests/${id}`}>
      Details
    </a>
  );
};

const DETAILS = {
  Header: "Actions",
  id: "actions",
  Cell: DetailsButton,
  className: "action-cell",
  minWidth: 200,
  disableFilters: true,
  disableSortBy: true,
};

const PUBLIC_ID = {
  Header: "Case ID",
  accessor: "public_id",
  Cell: TokenCell,
  minWidth: 125,
  disableFilters: true,
  disableSortBy: true,
};

const CURRENT_WORKFLOW = {
  Header: "Current Workflow",
  accessor: "workflow_state",
  minWidth: 150,
  disableFilters: true,
  disableSortBy: true,
};

export const EXTERNAL_COLUMNS = [
  DETAILS,
  PUBLIC_ID,
  CASE_PATIENT_NAME({ disableSortBy: true, disableFilters: true }),
  CASE_PATIENT_DOB,
  VERIFIED_PAYOR({ disableSortBy: true, disableFilters: true }),
  INSURANCE_MEMBER_ID,
  CASE_LAB_NO_FILTER,
  CURRENT_WORKFLOW,
  CURRENT_STATUS_NO_FILTER,
  CURRENT_SUBSTATUS_NO_FILTER,
  CASE_LAB_ORDER_ID,
  CASE_LAB_PATIENT_ID,
];
