import _ from "lodash";
import { useCallback, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { Field, Form } from "react-final-form";
import { LoadingButton } from "../../components/LoadingButton";
import { DropzoneInput } from "../../components/inputs/final_form/DropzoneInput";
import { SerializedLabSelector } from "../../components/inputs/final_form/LabSelector";
import { SerializedPayorSelector } from "../../components/inputs/final_form/PayorSelector";
import { Switch } from "../../components/inputs/final_form/Switch";
import { TextInput } from "../../components/inputs/final_form/TextInput";
import { api, redirectTo } from "../../util/api";
import { DetailedErrorAlert } from "../../components/DetailedErrorAlert";
import { required } from "../../util/validators";
import { StateSelector } from "../../components/inputs/final_form/AddressFields";
import { SelectInput } from "../../components/inputs/final_form/SelectInput";

const FillableInsuranceDocumentForm = ({ fid, formUrl, initialLabsOptions, initialPayorsOptions }) => {
  const [error, setError] = useState(null);
  const [autoFaxLabsOptions, setAutoFaxLabsOptions] = useState(initialLabsOptions);

  const setupExisting = (fid, labs) => {
    if (fid.fillable_insurance_documents_labs.length == 0) {
      return labs;
    } else {
      fid.fillable_insurance_documents_labs.forEach((fidl) => {
        labs.push({ id: fidl.id, lab_id: fidl.lab_id.toString(), auto_fax_enabled: fidl.auto_fax_enabled }); // Calling .toString() for the lab_id is required in order for later comparisons to work as expected.
      });
    }

    return labs;
  };

  const addNewLabs = (labIds, labs) => {
    if (labIds.length > 0) {
      labIds.forEach((id) => {
        // Iterate over existing labs and add new labs from the form values (labIds).
        if (!labs.map((lab) => lab.lab_id.toString()).includes(id.toString())) {
          labs.push({ lab_id: id.toString() });
        }
      });
    }

    return labs;
  };

  const handleAutoFaxLabs = (autoFaxLabIds, labs) => {
    if (autoFaxLabIds.length == 0) {
      // Set each lab to auto_fax_enabled false since there are no auto_fax_ids in the form.
      labs.forEach((lab) => {
        _.assign(lab, { auto_fax_enabled: false });
      });
    } else {
      // Set each lab to auto_fax_enabled: true or false depending on if the lab_id exists in autoFaxLabIds.
      labs.forEach((lab) => {
        autoFaxLabIds.includes(lab.lab_id) ? _.assign(lab, { auto_fax_enabled: true }) : _.assign(lab, { auto_fax_enabled: false });
      });
    }

    return labs;
  };

  const handleDeletedLabs = (labIds, labs) => {
    if (labIds.length == 0) {
      labs.forEach((lab) => {
        _.assign(lab, { _destroy: true }); // Add destroy param to any existing labs since there are no labs in the form.
      });
    } else {
      labs.forEach((lab) => {
        if (!labIds.includes(lab.lab_id)) {
          _.assign(lab, { _destroy: true }); // Add destroy param for any existing labs that are not in the labs selected in the form.
        }
      });
    }

    return labs;
  };

  const onSubmit = useCallback(
    async (values) => {
      setError(null);

      const handleFormValues = (values, fid) => {
        let labs = [];
        const labIds = values.lab_ids.map(String);
        const autoFaxLabIds = values.auto_fax_lab_ids.map(String);

        labs = setupExisting(fid, labs);
        labs = addNewLabs(labIds, labs);
        labs = handleAutoFaxLabs(autoFaxLabIds, labs);
        labs = handleDeletedLabs(labIds, labs);

        return labs;
      };

      try {
        const modifiedValues = {
          ...values,
          fillable_insurance_documents_labs_attributes: handleFormValues(values, fid),
          template_file: values.template_file?.[0],
        };

        if (fid.id) {
          await api.patch(formUrl, { fillable_insurance_document: modifiedValues });
          window.location.reload();
        } else {
          await api.post(formUrl, { fillable_insurance_document: modifiedValues });
          redirectTo(`/operations/fillable_insurance_documents`);
        }
      } catch (err) {
        setError(err);
      }
    },
    [fid, formUrl]
  );

  const initialValues = useMemo(() => {
    return {
      ..._.pick(fid, ["name", "state", "published"]),
      lab_ids: fid.lab_ids.map((id) => id?.toString()),
      payor_ids: fid.payor_ids.map((id) => id?.toString()),
      auto_fax_lab_ids: fid.auto_fax_labs.map((lab) => lab?.id),
    };
  }, [fid]);

  return (
    <>
      <DetailedErrorAlert error={error} />

      <Form onSubmit={onSubmit} initialValues={initialValues} keepDirtyOnReinitialize>
        {({ form, handleSubmit, submitting }) => {
          const labChange = (labs) => {
            form.change(
              "lab_ids",
              labs.map((lab) => lab.value)
            );
            setAutoFaxLabsOptions(labs);
          };

          const payorChange = (payors) => {
            form.change("payor_ids", payors);
          };

          const autoFaxLabsChange = (labs) => {
            form.change("auto_fax_lab_ids", labs);
          };

          return (
            <>
              <Field label="Form Name" required validate={required} component={TextInput} name="name" />
              <Field
                onChange={payorChange}
                render={SerializedPayorSelector}
                initialOptions={initialPayorsOptions}
                isMulti
                label="Payors"
                name="payor_ids"
              />
              <Field label="State" component={StateSelector} name="state" />
              <Field // This field represents the FillableInsuranceDocument's associated FillableInsuranceDocumentLabs (FIDL).
                onChange={labChange}
                render={SerializedLabSelector}
                initialOptions={initialLabsOptions}
                placeholder="To apply to all labs, select no labs."
                isMulti
                label="Labs"
                name="lab_ids"
                scope="lab_type_only"
                returnOptionsFromOnChange={true}
              />
              <Field // This field should be a sub-set of the above FIDL labs, and lets the user choose which one to enable auto-fax for.
                label="Auto Fax Enabled"
                component={SelectInput}
                onChange={autoFaxLabsChange}
                name="auto_fax_lab_ids"
                isMulti
                options={autoFaxLabsOptions}
                disabled={autoFaxLabsOptions.length == 0}
                initialValue={initialValues.auto_fax_lab_ids}
              />

              <hr />

              <Field component={Switch} name="published" type="checkbox" label="Published" />
              <Field component={DropzoneInput} label="Template File" name="template_file" />

              <LoadingButton color="primary" loading={submitting} onClick={handleSubmit}>
                {fid.id ? "Update" : "Save"}
              </LoadingButton>
            </>
          );
        }}
      </Form>
    </>
  );
};

export const initFillableInsuranceDocumentsForm = () => {
  const fidFormContainer = document.getElementById("fid-form");

  if (fidFormContainer) {
    const root = createRoot(fidFormContainer);
    const { fid, formUrl, initialLabsOptions, initialPayorsOptions } = fidFormContainer.dataset;

    root.render(
      <FillableInsuranceDocumentForm
        fid={JSON.parse(fid) || null}
        formUrl={formUrl}
        initialLabsOptions={JSON.parse(initialLabsOptions)}
        initialPayorsOptions={JSON.parse(initialPayorsOptions)}
      />
    );
  }
};
