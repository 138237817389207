import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { AsyncPaginate } from "react-select-async-paginate";
import { reactSelectColors } from "./ReactSelectWidget";

const defaultStyles = () => ({
  ...reactSelectColors(false),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
});

export const StyledSelect = ({ styles, ...props }) => (
  <Select
    {...props}
    menuPortalTarget={document.body}
    styles={{ ...defaultStyles(), ...styles, menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
  />
);

export const StyledAsyncSelect = ({ styles, ...props }) => <AsyncSelect {...props} styles={{ ...defaultStyles(), ...styles }} />;

export const StyledAsyncPaginate = ({ styles, ...props }) => <AsyncPaginate {...props} styles={{ ...defaultStyles(), ...styles }} />;
