import React from "react";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission } from "../../utils";
import { EditInsurancePayorMappingModal } from "./EditInsurancePayorMappingModal";
import { History } from "../../tools/sandbox/release/History";

export const InsurancePayorMappingActionCell = ({ row }) => {
  const [canDelete] = useDestroyPermission("InsurancePayorMapping", row.original.id, row.original._permissions);

  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = (msg) => {
    setMessage(`Updated ${row.original.dashboardDisplayName}`);
    refresh();
  };

  return (
    <>
      <EditInsurancePayorMappingModal color="link" onEdit={handleChange} insurancePayorMapping={row.original} />
      {canDelete && (
        <AdministrateResourceDeleteButton
          label="Delete"
          deleteUrl={`/admin/insurance_payor_mappings/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted ${row.original.insuranceName}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
      <History entityName={"InsurancePayorMapping"} subjectId={row.original.id} />
    </>
  );
};
