import { EditPayorPathModal } from "./EditPayorPathModal";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const PayorPathActionCell = ({ letterOptions, row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("PayorPath", row.original.id);

  const handleEditSuccess = () => {
    setMessage(`Updated Payor Path ${row.original.path}`);
    refresh();
  };

  return (
    <>
      <EditPayorPathModal color="link" onEdit={handleEditSuccess} payorPath={row.original} letterOptions={letterOptions} />

      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/payor_paths/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Payor Path ${row.original.path}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}

      <History entityName="PayorPath" subjectId={row.original.id} />
    </>
  );
};
