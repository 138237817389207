import _ from "lodash";
import { useEffect, useRef } from "react";
import { useDeepCompareEffect } from "../../util/hooks";
import { trackEvent } from "../../util/track";
import { redirectTo } from "../../util/api";
import { current } from "immer";

export const eightHours = 60 * 60 * 8;

export const goBack = (dispatch) => {
  dispatch({ type: "goback" });
};

export const reset = async (dispatch, caseId, name, stored, initial) => {
  if (confirm("Are you sure")) {
    dispatch({ type: "reset" });
    await trackFinish(caseId, name, "Wizard Reset");
    stored.clear();
    stored.set(initial);
  }
};

export const redirectToStep = (workflow, step, caseId, flash = null, flashType = null) => {
  redirectTo(`/workflow/${workflow}/${step}/${caseId}`, flash, flashType);
};

export const trackFinish = async (caseId, wizard, reason) => trackEvent("FOLLOWUP_WIZARD_FINISHED", caseId, { wizard, reason });

export const useTrackEvents = (caseId, wizard, state) => {
  const { currentStep, history, previousAction } = state;

  const lastWizard = useRef(null);
  const previousHistory = useRef(history.length);

  useEffect(() => {
    if (previousHistory.current == 0 && history.length > 0) {
      trackEvent("FOLLOWUP_STARTED", caseId, { wizard });
    }
    previousHistory.current = history.length;
  }, [caseId, history.length, wizard]);

  useDeepCompareEffect(() => {
    const currWizard = `${wizard}:${caseId}`;
    if (lastWizard.current != currWizard) {
      lastWizard.current = currWizard;
      return;
    }
    if (previousAction.type == "setAnswer") {
      trackEvent("FOLLOWUP_QUESTION_ANSWERED", caseId, {
        wizard,
        question: _.startCase(previousAction.key),
        answer: previousAction.value,
      });
    }
  }, [caseId, wizard, previousAction]);
};

export const useWizardReducer = (steps, initialStep, answers = {}) => {
  const getNextStep = createNextStepGetter(steps);
  const initialState = {
    currentStep: initialStep,
    history: [],
    answers: answers,
  };
  const reducer = createWizardReducer(getNextStep, initialState);
  return [reducer, initialState];
};

export const createNextStepGetter = (steps) => (draft, action) => {
  const { currentStep } = draft;

  if (Object.keys(steps).includes(currentStep)) {
    const stepObj = steps[currentStep];
    const nextStep = _.invoke(stepObj, "next", current(draft), action);
    return nextStep;
  }

  console.warn(`Unknown Step: ${currentStep}. Cannot transition.`);
  return currentStep;
};

export const createWizardReducer = (stepFn, initialState) => (draft, action) => {
  if (action.type == "reset") {
    return {
      ...initialState,
      previousAction: action,
    };
  }

  const mainDiv = document.getElementById("main");

  draft.previousAction = action;
  let nextStep = null;
  switch (action.type) {
    case "setAnswer":
      draft.answers[action.key] = action.value;
      break;
    case "setAnswertext":
      draft.answers[action.key] = action.value;
      break;
    case "clearAnswer":
      draft.answers[action.key] = initialState.answers[action.key];
      break;
    case "gonext":
      nextStep = stepFn(draft, action);

      draft.history.push(draft.currentStep);
      draft.currentStep = nextStep;
      if (mainDiv) {
        mainDiv.scrollTop = 0;
      }
      break;
    case "goback":
      nextStep = draft.history.pop();
      if (!_.isNil(nextStep)) {
        draft.currentStep = nextStep;
        if (mainDiv) {
          mainDiv.scrollTop = 0;
        }
      }
      break;
  }

  if (action.doTransition) {
    nextStep = stepFn(draft, action);

    draft.history.push(draft.currentStep);
    draft.currentStep = nextStep;
    if (mainDiv) {
      mainDiv.scrollTop = 0;
    }
  }
};
