import { FormText, Input, InputGroup } from "reactstrap";
import { LoadingButton } from "../../LoadingButton";
import { InputWrapper } from "./InputWrapper";

export const TextInput = ({ children, input, label, meta, ...rest }) => (
  <InputWrapper label={label} required={rest.required}>
    <Input {...input} {...rest} invalid={Boolean(meta.touched && meta.error)} />
    {meta.touched && meta.error && (
      <FormText color="danger" className="me-2">
        {meta.error}
      </FormText>
    )}
    {children}
  </InputWrapper>
);

export const TextInputWithButton = ({ buttonLabel, buttonProps = {}, children, input, label, meta, ...rest }) => {
  return (
    <InputWrapper label={label} required={rest.required}>
      <InputGroup className="border-0">
        <Input {...input} {...rest} className="input-border" invalid={Boolean(meta.touched && meta.error)} />
        {buttonLabel && <LoadingButton {...buttonProps}>{buttonLabel}</LoadingButton>}
      </InputGroup>
      {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
      {children}
    </InputWrapper>
  );
};
