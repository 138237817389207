import $ from "jquery";
import { isEqual } from "lodash";

export const initLabSettings = () => {
  $(() => {
    // User invitation
    const $inviteForm = $("#invite-user"),
      $invitationModal = $("#invitation-modal"),
      $inviteSucceeded = $("#invite-succeeded"),
      $inviteFailed = $("#invite-failed"),
      $roleForms = $("form.role-form"),
      $roleUpdated = $("#role-update-success"),
      $roleUpdateError = $("#role-update-error");

    $inviteForm
      .on("ajax:success", () => {
        $invitationModal.modal("toggle");
        $inviteSucceeded.collapse("toggle");
      })
      .on("ajax:error", () => {
        $invitationModal.modal("toggle");
        $inviteFailed.collapse("toggle");
      });

    $roleForms.on("ajax:success", () => $roleUpdated.collapse("toggle")).on("ajax:error", () => $roleUpdateError.collapse("toggle"));

    // Test Option Forms
    const $testOptionForms = $("#test-options form.edit-test-options"),
      $testOptionInputs = $("#test-options input.form-control");

    function setData(key) {
      $testOptionForms.each((__, f) => {
        const $form = $(f);
        const data = $form
          .find(".form-control")
          .map((_, el) => el)
          .toArray()
          .reduce((acc, x) => Object.assign(acc, { [x.name]: x.value }), {});
        $form.data(key, data);
      });
    }

    function setDisabled($j, isDisabled) {
      $j.prop("disabled", isDisabled);
      $j.find("input").prop("disabled", isDisabled);
      $j.find(".btn").toggleClass(".btn-warning", !isDisabled);
      $j.find(".btn").toggleClass(".btn-outline-warning", isDisabled);
    }
    window.$ = $;
    setData("original");

    $testOptionInputs.on("keyup mouse", (evt) => {
      setData("active");
      $testOptionForms.each((__, f) => {
        const $form = $(f);
        setDisabled($form, isEqual($form.data("original"), $form.data("active")));
      });
      const areDisabled = $testOptionForms.map((__, f) => f.disabled).toArray();
      if (areDisabled.every((x) => x)) {
        setDisabled($testOptionForms, false);
      }
    });
  });
};
