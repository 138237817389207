import { Eye } from "lucide-react";
import { useState } from "react";
import { LoadingButton } from "../components/LoadingButton";
import { api } from "../util/api";

export const MarkReadButton = ({ id, row, disabled, ...props }) => {
  const [loading, setLoading] = useState(false);

  const title = row.read ? "Mark as Unread" : "Mark as Read";
  const postPath = row.read ? `/operations/fax/${id}/mark_as_unread` : `/operations/fax/${id}/mark_as_read`;
  return (
    <LoadingButton
      {...props}
      size="xs"
      disabled={disabled || loading}
      outline
      title={title}
      onClick={async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          await api.post(postPath);
          window.location.reload();
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }}
      loading={loading}
    >
      <Eye />
    </LoadingButton>
  );
};
