import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useDestroyPermission } from "../../utils";

export const PortalActionCell = ({ row }) => {
  const [canDestroy] = useDestroyPermission("BenefitManagementPortal", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();

  return (
    canDestroy && (
      <AdministrateResourceDeleteButton
        deleteUrl={`/admin/benefit_management_portals/${row.original.id}`}
        onSuccess={() => {
          refresh();
          setMessage(`Deleted Benefit Managment Portal "${row.original.name}".`);
        }}
        onError={setError}
      />
    )
  );
};
