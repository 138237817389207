import React from "react";
import { Button } from "reactstrap";
import { AdministrateTable } from "../../components/AdministrateTable";
import { makeColumns, useAdministrateTable } from "../../utils";

const useColumns = makeColumns(() => [
  {
    id: "created_at",
    accessor: "createdAt",
    Header: "Date",
    disableFilters: true,
  },
  {
    id: "download",
    accessor: "downloadUrl",
    Header: "File Name",
    Cell: ({ row, value }) => (
      <Button href={value} download target="_blank" rel="noopener noreferrer" color="link">
        {row.original.filename}
      </Button>
    ),
    disableFilters: true,
  },
  {
    id: "account_id",
    accessor: "account",
    Header: "Last Generated By",
    Cell: ({ value }) => {
      return value.globalRole === "system" ? "System" : value.fullNameOrEmail;
    },
    disableFilters: true,
  },
  {
    id: "rows",
    accessor: "rows",
    Header: "Rows in File",
    disableFilters: true,
  },
  {
    id: "cases_updated",
    accessor: "casesUpdated",
    Header: "Cases Re-statused",
    disableFilters: true,
  },
]);

export const BulkPaRestatusHistory = () => {
  const columns = useColumns();

  return (
    <AdministrateTable resourceUrl={"/admin/bulk_pa_restatus_histories.json"} resourceName={"bulk_pa_restatus_history"} columns={columns} />
  );
};
