import { useState } from "react";
import { Card, CardBody, CardTitle, Input, Table } from "reactstrap";
import { Chip } from "../../../components/Chip";
import { DonutChart } from "../../../components/DonutChart";
import { PillToggle } from "../../../components/inputs/PillToggle";
import { StatusSelector } from "../../../components/inputs/StatusSelector";
import { CutoffStatBody, CutoffStatCard, StatCard } from "../../../components/StatCard";
import { InfoGridDemo } from "./demos/InfoGridDemo";
import { Form, Field } from "react-final-form";
import { DropzoneInput } from "../../../components/inputs/final_form/DropzoneInput";
import { BIReceiptDemo } from "./demos/BIReceiptDemo";
import { ProgressListDemo } from "./demos/ProgressListDemo";
import { CaseHistoryDemo } from "./demos/CaseHistoryDemo";
import { History } from "./release/History";

const historySample = [
  {
    id: 0,
    name: "Account",
  },
  {
    id: 1,
    name: "BenefitsInvestigationData",
  },
  {
    id: 1447,
    name: "Case",
  },
  {
    id: 1,
    name: "Clinic",
  },
  {
    id: 1,
    name: "ClinicMembership",
  },
  {
    id: 1,
    name: "CptCodeVerification",
  },
  {
    id: 1,
    name: "Fax",
  },
  {
    id: 1,
    name: "Icd10CodeVerification",
  },
  {
    id: 1,
    name: "Lab",
  },
  {
    id: 1,
    name: "LabMembership",
  },
  {
    id: 1,
    name: "LabPortalMapping",
  },
  {
    id: 1,
    name: "LabSetting",
  },
  {
    id: 1,
    name: "Payor",
  },
  {
    id: 1,
    name: "PayorContact",
  },
  {
    id: 1,
    name: "PayorGroup",
  },
  {
    id: 1,
    name: "PayorPortalMapping",
  },
  {
    id: 1,
    name: "PriorAuthRequest",
  },
  {
    id: 1,
    name: "PriorAuthRequestDetail",
  },
  {
    id: 1,
    name: "PriorAuthRequestWorkflowStatus",
  },
  {
    id: 1,
    name: "Provider",
  },
  {
    id: 1,
    name: "SubmissionAttempt",
  },
  {
    id: 1,
    name: "SubmissionRecord",
  },
  {
    id: 1,
    name: "Task",
  },
  {
    id: 1,
    name: "TestVerification",
  },
  {
    id: 1,
    name: "User",
  },
];

export const SandboxIndex = () => {
  const [caseId, setCaseId] = useState("");
  const [pillValue, setPillValue] = useState("foo");

  function updateCaseId(e) {
    setCaseId(e.target.value);
  }

  const statusHtmlAttributes = {
    name: "prior_auth_requests_submission_attempt_form[status]",
    id: "prior_auth_requests_submission_attempt_form_status",
  };
  const substatusHtmlAttributes = {
    name: "prior_auth_requests_submission_attempt_form[substatus]",
    id: "prior_auth_requests_submission_attempt_form_substatus",
  };

  return (
    <>
      <Table striped>
        <thead>
          <tr>
            <th>#</th>
            <th>Use Case</th>
          </tr>
        </thead>
        <tbody>
          {historySample.map((el, i) => (
            <tr key={`history-demo-${i}`}>
              <th scope="row">{++i}</th>
              <td>{el.name}</td>
              <td>
                <History entityName={el.name} subjectId={el.id} />
              </td>
            </tr>
          ))}
          <tr>
            <th scope="row">Experimental</th>
            <td>By Model: Payor</td>
            <td>
              <History entityName={"Payor"} />
            </td>
          </tr>
        </tbody>
      </Table>

      <hr />

      <Form onSubmit={(form) => console.log(form)}>
        {() => (
          <Card className="w-25">
            <CardBody>
              <CardTitle tag="h5">Status Picker Component</CardTitle>
              <Input id="caseId" name="caseId" placeholder="PA/BI Case ID" onChange={updateCaseId} />
              <StatusSelector
                substatusHtmlAttributes={substatusHtmlAttributes}
                statusHtmlAttributes={statusHtmlAttributes}
                id="status"
                name="status"
                caseId={caseId}
              />
            </CardBody>
          </Card>
        )}
      </Form>

      <hr />

      <PillToggle
        value={pillValue}
        onChange={setPillValue}
        options={[
          { value: "foo", label: "Pill" },
          { value: "bar", label: "Toggle" },
          { value: "baz", label: "Options" },
        ]}
      />

      <hr />

      <Card>
        <CardBody className={"d-flex flex-row"}>
          <StatCard
            value={"320"}
            label={"Total Cases"}
            icon={"file-text"}
            variant={"primary"}
            url={"http://example.com"}
            className={"w-25 me-3"}
          />
          <StatCard
            value={"0%"}
            label={"Denial Rate"}
            icon={"slash"}
            variant={"secondary"}
            url={"http://example.com"}
            className={"w-25 me-3"}
          />
          <StatCard
            value={"100%"}
            label={"Approval Rate"}
            icon={"check-circle"}
            variant={"success"}
            url={"http://example.com"}
            className={"w-25 me-3"}
          />
          <StatCard
            value={"12%"}
            label={"Missing Info"}
            icon={"help-circle"}
            variant={"danger"}
            url={"http://example.com"}
            className={"w-25"}
          />
        </CardBody>
      </Card>

      <div className="p-3" style={{ backgroundColor: "#e0e0e0" }}>
        <CutoffStatCard>
          <CutoffStatBody value={"30"} label={"Missing Info"} icon={"help-circle"} variant={"danger"} />
          <CutoffStatBody value={"100"} label={"Denied"} icon={"slash"} variant={"warning"} url={"http://example.com"} />
          <CutoffStatBody value={"10"} label={"Not Required"} icon={"x-circle"} variant={"secondary"} />
          <CutoffStatBody value={"180"} label={"Approved"} icon={"check-circle"} variant={"success"} url={"http://example.com"} />
        </CutoffStatCard>
      </div>

      <Card className="w-25">
        <CardBody>
          <CardTitle tag="h5">Chip Example</CardTitle>
          <Chip label={"Primary"} icon={"Feather"} iconClass={"text-primary"} />
          <Chip label={"Secondary"} icon={"Cpu"} iconClass={"text-secondary"} />
          <Chip label={"Success"} icon={"CloudDrizzle"} iconClass={"text-success"} />
          <Chip label={"Warning"} iconClass={"text-warning"} />
          <Chip label={"Danger"} icon={"Gift"} iconClass={"text-danger"} />
        </CardBody>
      </Card>

      <hr />

      <InfoGridDemo />

      <hr />

      <Card className="w-25">
        <CardBody>
          <CardTitle tag="h5">DonutChart Component</CardTitle>
          <DonutChart
            color="primary"
            data={[
              { x: "In Network", y: 35 },
              { x: "Out Network", y: 55 },
            ]}
            height={150}
            width={250}
            padding={{ right: 150 }}
          />
          <DonutChart
            color="success"
            data={[
              { x: "In Network", y: 60 },
              { x: "Out Network", y: 40 },
            ]}
            height={230}
            width={300}
            padding={{ right: 150 }}
          />
          <DonutChart
            color="secondary"
            data={[
              { x: "In Network", y: 30 },
              { x: "Out Network", y: 70 },
            ]}
            height={275}
            width={320}
            padding={{ right: 150 }}
          />
          <DonutChart
            color="warning"
            data={[
              { x: "In Network", y: 20 },
              { x: "Out Network", y: 80 },
            ]}
            height={300}
            width={350}
            padding={{ right: 150 }}
          />
        </CardBody>
      </Card>

      <hr />

      <Card className="mt-3 w-25">
        <Form onSubmit={console.log}>
          {() => {
            return (
              <>
                <Field component={DropzoneInput} label="Supplemental Documents" name="file" />
                <Field component={DropzoneInput} accept=".csv" label="CSV only test" name="file-csv" />
                <Field multiple component={DropzoneInput} label="Multiple document test" name="documents" />
              </>
            );
          }}
        </Form>
      </Card>

      <hr />

      <BIReceiptDemo />

      <hr />

      <ProgressListDemo />

      <hr />

      <CaseHistoryDemo />

      <hr />
    </>
  );
};
