import React, { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { objectToOptions } from "../../../util/formatters";
import { camelizeKeys, snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";
import { GROUP_TYPES } from "../../../util/data/group_types";
import { Switch } from "../../../components/inputs/final_form/Switch";

const globalGroupTypes = objectToOptions(GROUP_TYPES);

export const GroupsGeneralTab = ({ group, groupId, refresh }) => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const handleUpdate = async (values) => {
    setError(null);
    setMessage(null);

    try {
      await api.put(`/admin/groups/${groupId}`, { group: snakifyKeys(values) });

      setMessage(`Updated user group.`);
      refresh();
    } catch (err) {
      setError(err);
    }
  };

  const initialValues = useMemo(() => camelizeKeys(group), [group]);
  const initialAccountOptions = useMemo(() => {
    return group.leader ? [{ label: group.leader.full_name_or_email, value: group.leader.id }] : [];
  }, [group]);
  return (
    <>
      <DetailedErrorAlert error={error} />
      {message && <Alert color="info">{message}</Alert>}

      <Form onSubmit={handleUpdate} initialValues={initialValues}>
        {({ handleSubmit, submitting, pristine }) => {
          return (
            <>
              <Field component={TextInput} required validate={required} label="ID" name="id" readOnly />
              <Field component={TextInput} label="Group Name" name="teamName" />
              <Field component={SelectInput} options={globalGroupTypes} label="Group Type" name="groupType" />
              <Field
                component={AdministrateSelectInput}
                resourceUrl="/admin/accounts.json"
                required
                validate={required}
                label="Group Lead"
                name="leaderAccountId"
                labelKey="full_name_or_email"
                initialOptions={initialAccountOptions}
              />
              <Field component={Switch} label="Customer Success Team" name="customerSuccessTeam" type="checkbox" />

              <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine}>
                Save
              </LoadingButton>
            </>
          );
        }}
      </Form>
    </>
  );
};
