import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { NetworkStatusCell } from "../../payors/components/NetworkStatusCell";
import { makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { ImportPlanTypeNetworkStatusSettingsModal } from "../components/ImportPlanTypeNetworkStatusSettingsModal";
import { LabPlanTypeNetworkStatusActionCell } from "../components/LabPlanTypeNetworkStatusActionCell";

const useColumns = makeColumns(({ labId, labName }) => [
  {
    id: "associated_payor_id",
    accessor: "payorId",
    Header: "Payor Id",
    disableSortBy: true,
    disableFilters: true,
    Cell: TokenCell,
    width: 80,
  },
  {
    id: "payor_id",
    accessor: "payorName",
    Header: "Payor",
    disableSortBy: true,
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="id" labelKey="name" searchParam="name" />
    ),
  },
  {
    id: "plan_type_group",
    accessor: "planTypeGroupLabel",
    Header: "Plan Type",
    disableSortBy: true,
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/labs/plan_type_groups.json"
        valueKey="value"
        labelKey="label"
        searchParam="plan_type_group"
      />
    ),
    Cell: TokenCell,
  },
  {
    id: "in_network",
    accessor: "inNetwork",
    Header: "Network Status",
    Cell: NetworkStatusCell,
    disableSortBy: true,
    Filter: (props) => <BoolFilter {...props} yesLabel="In-Network" noLabel="Out-of-Network" />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <LabPlanTypeNetworkStatusActionCell labId={labId} labName={labName} {...row} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const LabsPlanTypeNetworkStatusTab = ({ labId, lab }) => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const [canCreate] = useCreatePermission("PlanTypeNetworkSetting");

  const labName = lab.labName ?? lab.dashboardDisplayName;
  const columns = useColumns({ labId, labName });

  const onAddLabPayorSettings = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onExportSuccess = () => {
    const { setMessage } = tableRef.current;
    setMessage("Export started. You will receive an email when your file is ready", "success");
  };

  const onExportError = (err) => {
    const { setError } = tableRef.current;
    setError(err);
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl={`/admin/labs/${labId}/plan_type_network_status/export`}
            filters={allFilters}
            onError={onExportError}
            onSuccess={onExportSuccess}
          />

          {canCreate && (
            <ImportPlanTypeNetworkStatusSettingsModal outline className="me-2" onImport={onAddLabPayorSettings} labId={labId} />
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/labs/${labId}/plan_type_network_statuses.json`}
        resourceName={"plan_type_network_statuses"}
        onFilteredChange={setTableFilters}
        permissions={[UPDATE]}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
