import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { atLeastOneFieldRequired, required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const PayorMemberPrefixForm = ({ handleSubmit, submitting, showPrefixField = true }) => (
  <>
    <Field
      component={AdministrateSelectInput}
      resourceUrl="/admin/payors.json"
      searchParam="name"
      label="Payor"
      name="payorId"
      validate={required}
    />
    {showPrefixField && (
      <Field
        component={TextInput}
        validate={atLeastOneFieldRequired("memberIdPrefixRegex")}
        label="Payor Member ID Prefix"
        name="memberIdPrefix"
      />
    )}
    <Field
      component={TextInput}
      validate={atLeastOneFieldRequired("memberIdPrefix")}
      label="Payor Member ID Regex"
      name="memberIdPrefixRegex"
    />
    <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
      Submit
    </LoadingButton>
  </>
);
