import { useCallback } from "react";
import { Link } from "react-router-dom";
import { CountCell } from "../../components/grid/cells/CountCell";
import { Toolbar } from "../../components/Toolbar";
import { AdministrateEnumFilter } from "../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { Routes } from "../Routes";
import { DESTROY, makeColumns, useAdministrateTable, useCreatePermission } from "../utils";
import { AddPayorGroupModal } from "./components/AddPayorGroupModal";
import { ImportPayorGroupsModal } from "./components/ImportPayorGroupsModal";
import { PayorGroupActionCell } from "./components/PayorGroupActionCell";

const useColumns = makeColumns(() => [
  {
    id: "name",
    accessor: "name",
    Header: "Group Name",
    Cell: ({ row, value }) => <Link to={Routes.payorGroup.url(row.original.id)}>{value}</Link>,
  },
  {
    id: "abbreviation",
    accessor: "abbreviation",
    Header: "Payor Path Letter",
  },
  {
    id: "payors.id",
    accessor: "payorCount",
    Header: "Payors",
    Cell: ({ value }) => <CountCell count={value} label="Payors" />,
    Filter: (props) => <AdministrateEnumFilter {...props} isMulti resourceUrl="/admin/payors.json" valueKey="id" searchParam="name" />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: PayorGroupActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PayorGroupsIndexPage = () => {
  const columns = useColumns();
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const [canCreate] = useCreatePermission("PayorGroup");

  const onImport = useCallback(
    (message, details = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, details.length ? "warning" : "success", details);
    },
    [tableRef]
  );

  const onAdd = useCallback(
    (values) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`New Payor Group "${values.name}" Added.`);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Payor Groups">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/payor_groups/export"
            filters={allFilters}
            ref={tableRef}
          />

          {canCreate && (
            <>
              <ImportPayorGroupsModal outline className="me-2" onImport={onImport} />
              <AddPayorGroupModal onAdd={onAdd} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/payor_groups.json"}
        resourceName={"payor_group"}
        onFilteredChange={setTableFilters}
        permissions={[DESTROY]}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
