import { createRoot } from "react-dom/client";
import { BillingRatesGrid } from "../../billing_rates_grid/index";

export const initBillingRatesGrid = () => {
  const container = document.getElementById("billing_rates");
  const root = createRoot(container);

  const { billingRatesUrl } = container.dataset;
  root.render(<BillingRatesGrid dataUrl={billingRatesUrl} />);
};
