import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";
import { ImportModal } from "../../components/ImportModal";

export const ImportLabPayorSettingsModal = ({ onImport, labId, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      labId: values.labId,
      file: {
        bytes: values.file,
      },
    };

    const res = await api.post(`/admin/payor_lab_settings/import.json`, snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message, res.data?.meta?.error_details);
    }
  };

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Add Payor Network Statuses to Lab from CSV..."
      initialValues={{ labId }}
      exampleDownloadPath="/admin/labs/payor_network_status_example_csv"
    >
      {(formProps) => <Field component={TextInput} name="labId" type="hidden" required validate={required} />}
    </ImportModal>
  );
};
