import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import React, { useState } from "react";
import { Form, FormSpy, useFormState } from "react-final-form";
import { Button, Col, Row, UncontrolledTooltip } from "reactstrap";
import { LoadingButton } from "../LoadingButton";
import { PageHeader } from "../PageHeader";
import { ProgressTimeline } from "../../new_case/ProgressTimeline";

export const FormWizard = ({ initialValues, wizardTitle, children, initialPage = 0, onPageTransitionSuccess, ...rest }) => {
  const [internalValues, setInternalValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const [page, setPage] = useState(initialPage || 0);

  const pageNames = _.map(React.Children.toArray(children), (c) => c.props.pageName);

  const activePage = React.Children.toArray(children)[page];
  const saveButtonLabel = activePage.props.saveButtonLabel;
  const saveButtonColor = activePage.props.saveButtonColor;
  const saveButtonIcon = activePage.props.saveButtonIcon;

  const handleNextPage = async (values, newPage) => {
    const onNextPage = activePage.props.onNextPage;

    setInternalValues(values);

    if (_.isFunction(onNextPage)) {
      try {
        setSaving(true);
        await onNextPage(values, newPage);
      } finally {
        setSaving(false);
      }
    }
  };

  const handlePageBack = async (values, newPage) => {
    const onPrevPage = activePage.props.onPrevPage;

    setInternalValues(values);

    if (_.isFunction(onPrevPage)) {
      try {
        setSaving(true);
        await onPrevPage(values, newPage);
      } finally {
        setSaving(false);
      }
    }
  };

  const onSubmit = async (values) => {
    const lastPage = React.Children.count(children) - 1;
    const newPage = Math.min(page + 1, lastPage);

    await handleNextPage(values, newPage);
    setPage(newPage);
  };

  const onPreviousPage = async (values, onSuccess) => {
    const newPage = Math.max(page - 1, 0);

    await handlePageBack(values, newPage);

    if (_.isFunction(onSuccess)) {
      onSuccess();
    }

    setPage(newPage);
  };

  return (
    <div className="form-wizard">
      <div className="mx-4 rounded-2">
        <Row>
          <Col className="col-4">
            <ProgressTimeline pageNames={pageNames} formValues={internalValues} page={page} />
          </Col>

          <Col className="col-8">
            <Form onSubmit={onSubmit} initialValues={internalValues} {...rest}>
              {(formProps) => {
                const { handleSubmit, form, valid, submitFailed, submitting, pristine, values } = formProps;

                const handleSubmitSucceeded = () => {
                  onPageTransitionSuccess(form, values);
                };

                return (
                  <div className="p-4">
                    <form onSubmit={handleSubmit} noValidate>
                      <AnimatePresence mode="wait">
                        <motion.div
                          key={page}
                          initial={{ x: 300, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          exit={{ x: -300, opacity: 0 }}
                        >
                          {React.cloneElement(activePage, formProps)}
                        </motion.div>
                      </AnimatePresence>

                      <FormSpy
                        subscription={{ submitSucceeded: true }}
                        onChange={({ submitSucceeded }) => {
                          if (submitSucceeded) {
                            handleSubmitSucceeded();
                          }
                        }}
                      />

                      <Row className="p-1">
                        <Col>
                          {page > 0 && (
                            <Button
                              color="link"
                              data-testid="previousPageButton"
                              disabled={submitting || saving}
                              onClick={() => onPreviousPage(values, handleSubmitSucceeded)}
                            >
                              <i className="fas fa-arrow-left me-2"></i>
                              Previous Page
                            </Button>
                          )}
                        </Col>

                        <Col className="text-end">
                          <LoadingButton
                            id="wizard_next"
                            data-testid="continueButton"
                            loading={submitting}
                            disabled={submitting || saving}
                            type="submit"
                            color={saveButtonColor}
                          >
                            {saveButtonLabel}
                            {saveButtonIcon}
                          </LoadingButton>

                          {(submitFailed || !pristine) && !valid && (
                            <UncontrolledTooltip target="wizard_next">
                              Your current page has invalid fields. You cannot continue until the page is valid.
                            </UncontrolledTooltip>
                          )}
                        </Col>
                      </Row>
                    </form>
                  </div>
                );
              }}
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};
