import { Card, CardBody, CardTitle } from "reactstrap";
import { InfoGrid } from "../../../../components/grid/InfoGrid";

export const InfoGridDemo = () => {
  const firstExampleItems = [
    { label: "Order Type", content: "Prior Authorization" },
    { label: "Member ID", content: "922137101" },
    { label: "Primary Insurance", content: "United Healthcare" },
    { label: "James, Smith", content: "12/12/1993, Male", labelClassNames: "fw-bolder" },
  ];

  const secondExampleItems = [
    { label: "Physician NPI", content: "12345678480", copyable: true },
    { label: "Physician Practice Name", content: "Helpline Hospital, 3021932" },
    { label: "Physician Name", content: "James, Parker" },
    { label: "Generic Counseler Name", content: "Danial, Sams", copyable: true },
  ];

  return (
    <>
      <div className="d-flex flex-column">
        <div className="w-25 d-flex flex-wrap mb-3">
          <span className="text-muted">* Borders and background colors do not make part of the component.</span>
        </div>
        <h5>Info Grid Demo (Example 1)</h5>
        <Card className="w-25 mb-3 bg-success bg-opacity-10 border border-0">
          <CardBody>
            <InfoGrid items={firstExampleItems} loaded={true} />
          </CardBody>
        </Card>

        <h5>Info Grid Demo (Example 2)</h5>
        <Card className="w-50 mb-3">
          <CardBody>
            <InfoGrid items={secondExampleItems} loaded={true} />
          </CardBody>
        </Card>

        <h5>Info Grid Demo (No Data)</h5>
        <Card className="w-25">
          <CardBody>
            <InfoGrid loaded={false} />
          </CardBody>
        </Card>
      </div>
    </>
  );
};
