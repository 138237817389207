import React from "react";
import { EstimateInfo } from "./components/panel/EstimateInfo";
import { PolicyInfo } from "./components/panel/PolicyInfo";
import { InsuranceInfo } from "../components/InsuranceInfo";
import { PracticeInfo } from "../components/PracticeInfo";
import { TestInfo } from "../components/TestInfo";
import { ErrorRenderer } from "../components/ErrorRenderer";
import { LoadingIcon } from "../components/LoadingIcon";

export const BIView = ({
  test,
  practice,
  insurance,
  bi,
  configured,
  hidePolicyLink,
  biState,
  biErrors,
  priceComparisonEnabled,
  caseId,
  hasVerifiedPayor,
  hasVerifiedTests,
  verifiedCptCodes,
  payorShowPriceComparison,
  noMoopProvided,
  serviceType,
  trizettoEnabled,
  showPaApprovalPercentage,
  isPharmacyOwnedByClinic,
  labOrClinicUser,
  clinicTypePharmacy,
  coveredChargesEnabled,
}) => {
  return (
    <div className="mt-2">
      {biState === "complete" && (
        <>
          <EstimateInfo
            biInfo={bi}
            priceComparisonEnabled={priceComparisonEnabled}
            caseId={caseId}
            payorShowPriceComparison={payorShowPriceComparison}
            serviceType={serviceType}
            labOrClinicUser={labOrClinicUser}
            isPharmacyOwnedByClinic={isPharmacyOwnedByClinic}
            noMoopProvided={noMoopProvided}
            coveredChargesEnabled={coveredChargesEnabled}
          />
          <PolicyInfo
            policyInfo={bi}
            paRequirementsConfigured={configured}
            hidePolicyLink={hidePolicyLink}
            biState={"complete"}
            verifiedCptCodes={verifiedCptCodes}
            trizettoEnabled={trizettoEnabled}
            showPaApprovalPercentage={showPaApprovalPercentage}
            isPharmacyOwnedByClinic={isPharmacyOwnedByClinic}
          />
        </>
      )}

      {biState === "error" && (
        <>
          <ErrorRenderer errors={biErrors} />
          {hasVerifiedTests && hasVerifiedPayor && (
            <PolicyInfo
              policyInfo={bi}
              paRequirementsConfigured={configured}
              hidePolicyLink={hidePolicyLink}
              biState={"error"}
              verifiedCptCodes={verifiedCptCodes}
              showPaApprovalPercentage={showPaApprovalPercentage}
              isPharmacyOwnedByClinic={isPharmacyOwnedByClinic}
            />
          )}
        </>
      )}

      <h4 className="text-secondary h3 fw-medium mt-4">
        seeQer Details
        {biState === "processing" && (
          <>
            <span className="text-info" style={{ paddingRight: 10, paddingLeft: 5 }}>
              - Results Processing
            </span>
            <LoadingIcon className="text-info" />
          </>
        )}
      </h4>

      <div className="vstack gap-3">
        <TestInfo testInfo={test} isPharmacyOwnedByClinic={isPharmacyOwnedByClinic} />
        {!isPharmacyOwnedByClinic && <PracticeInfo practiceInfo={practice} />}
        <InsuranceInfo insuranceInfo={insurance} />
      </div>
    </div>
  );
};
