import React from "react";
import { adminApi as api } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { ImportModal } from "../../../components/ImportModal";

export const ImportUiPathBotAutomationModal = ({ onImport, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      file: {
        bytes: values.file,
      },
    };

    const res = await api.post(`/admin/ui_path_portal_configurations/import.json`, snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message, res.data?.meta?.error_details);
    }
  };

  return (
    <ImportModal
      onImport={handleImport}
      title="Import UiPath Automations"
      exampleDownloadPath="/admin/ui_path_portal_configurations/example"
      buttonProps={rest}
    />
  );
};
