import $ from "jquery";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LoadingButton } from "../../components/LoadingButton";
import { api, redirectTo } from "../../util/api";
import { getNow } from "../../util/helpers";
import { useInterval } from "../../util/hooks";
import { fetchTtl, retrieveTtl, storeTtl } from "../../util/session";

// Number of seconds to fudge to account
// for small cross-window differences in
// the timeout based on when the check
// interval is runnings
const FUDGE_FACTOR = 5;

// poll every second
const SESSION_POLL_INTERVAL = 1000;

// show notification if it's less than 3 mins before session timeout
const notificationDelay = 180;

const doRedirect = () => {
  setHardTimeout();
  redirectTo("/users/sign_in", "You have been logged out automatically.");
};

const setHardTimeout = () => {
  // this ensures that other session checks will
  // end up with a ttl < 0 and will do a logout
  storeTtl(-1);
};

const SessionCheckApp = () => {
  useEffect(() => {
    try {
      fetchTtl();
    } catch (err) {
      console.error(err);
      setHardTimeout();
    }
  }, []);

  const [sessionModalOpen, setSessionModalOpen] = useState(false);
  const [expiresIn, setExpiresIn] = useState(null);
  const [resetting, setResetting] = useState(false);
  const [loggingOut, setLoggintOut] = useState(false);

  useInterval(async () => {
    const ttl = retrieveTtl();

    // bail early on initial load
    if (ttl === 0 || !ttl) {
      return;
    }

    const now = getNow();
    const expiresIn = ttl - now;

    // Store the expiration timer for display to the user
    setExpiresIn(expiresIn);

    // Less than one minute left, notify user
    if (expiresIn < notificationDelay && !sessionModalOpen) {
      setSessionModalOpen(true);
    } else if (expiresIn > notificationDelay && sessionModalOpen) {
      setSessionModalOpen(false);
    }

    // Out of time, do a logout; we account for a small
    // fudge-factor here to deal with small differences
    // in timing if the user has multiple windows open
    if (expiresIn + FUDGE_FACTOR < 0) {
      setLoggintOut(true);

      try {
        // if we've already logged out this will 403; if we haven't, this will
        // log us out and store the current user location to return to after
        // logging back in
        await api.delete(`/users/sign_out?return_to=${window.location.href}`);
      } finally {
        doRedirect();
      }
    }
  }, SESSION_POLL_INTERVAL);

  const handleContinue = async () => {
    try {
      setResetting(true);

      await api.get("/reset_timeout");
      await fetchTtl();

      setResetting(false);
      setSessionModalOpen(false);
    } catch (err) {
      console.error(err);
      setHardTimeout();
    }
  };

  return (
    <Modal isOpen={sessionModalOpen} backdrop={"static"}>
      <ModalHeader>
        {loggingOut ? "You are Being Logged Out" : `You will be logged out in ${Math.max(parseInt(expiresIn, 10), 0)} seconds`}
      </ModalHeader>

      {!loggingOut && (
        <ModalBody>
          Your session is about to timeout. Click "Stay Logged In" to continue your session, or "Log Out" if you'd like to log out right
          away.
        </ModalBody>
      )}

      <ModalFooter>
        <LoadingButton loading={resetting} disabled={loggingOut || resetting} onClick={handleContinue} color="primary">
          Stay Logged In
        </LoadingButton>

        <LoadingButton loading={loggingOut} disabled={loggingOut || resetting} onClick={setHardTimeout} outline color="secondary">
          Log Out
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

export const initSessionCheck = () => {
  // When we initialize this, we want to clear it to 0 because
  // we are definitely not expired on page load
  storeTtl(0);

  // Make sure that if we click the main logout button
  // we expire the session in local storage
  const $mainLogoutButton = $("#logout_button");
  $mainLogoutButton.on("click", () => setHardTimeout());

  // Mount the session modal application
  var container = document.createElement("div");
  container.id = "session-modal";
  document.body.appendChild(container);

  const root = createRoot(container);
  root.render(<SessionCheckApp />);
};
