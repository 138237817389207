import axios from "axios";
import { debounce } from "lodash";
import React, { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Button } from "reactstrap";
import { DetailedAlert } from "../../components/DetailedAlert";
import { DetailedErrorAlert } from "../../components/DetailedErrorAlert";
import { PersistantReactTable } from "../../components/grid/PersistantReactTable";
import { adminApi as api } from "../../util/api";
import { camelizeKeys, snakifyKeys } from "../../util/helpers";
import { useDebounced, useStableValue } from "../../util/hooks";
import { encodeTableStateForAdmin } from "../../util/table";
import { usePermissions } from "../PermissionContext";

const TableContext = React.createContext({});
export const useTableContext = () => useContext(TableContext);

export const AdministrateTable = React.forwardRef(
  ({ resourceUrl, resourceName, columns = [], extraParams = {}, permissions = [], ...rest }, tableRef) => {
    const internalRef = useRef();
    const [data, setData] = useState([]);
    const [pages, setPages] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [tableState, setTableState] = useState({});
    const [error, setError] = useState(null);
    const [message, setMessageString] = useState(null);
    const [messageStyle, setMessageStyle] = useState("info");
    const [messageDetails, setMessageDetails] = useState([]);

    const stableExtraParams = useStableValue(extraParams);
    const stablePermissions = useStableValue(permissions);

    const { insertInstancePermission } = usePermissions();

    const onFetchData = useDebounced(
      useCallback(
        async (rawTableState) => {
          setLoading(true);

          try {
            const tableParams = snakifyKeys(encodeTableStateForAdmin(rawTableState, resourceName));
            const additionalParams = snakifyKeys(stableExtraParams);
            const permissionParams = stablePermissions.length > 0 ? { permissions: stablePermissions.join(",") } : null;
            const filterParams = { ...tableParams, ...additionalParams, ...permissionParams };

            const {
              data: { meta, resources },
            } = await api.get(resourceUrl, { params: filterParams });

            const camelData = resources.map(camelizeKeys);
            const camelMeta = camelizeKeys(meta);
            if (camelMeta.permissions?.length > 0) {
              camelMeta.permissions.forEach((p) => {
                if (p.id) {
                  p.abilities.forEach((a) =>
                    insertInstancePermission({
                      resourceName: p.resource,
                      instanceId: p.id,
                      permissionName: a.ability,
                      permitted: a.permitted,
                    })
                  );
                }
              });
            }

            setData(camelData);
            setPages(camelMeta.totalPages);
          } catch (e) {
            if (!axios.isCancel(e)) {
              console.error(e);
            }
          } finally {
            setLoading(false);
            setTableState(rawTableState);
          }
        },
        [resourceName, stableExtraParams, resourceUrl, stablePermissions, insertInstancePermission]
      ),
      400
    );

    const handleRefresh = useCallback(() => {
      if (internalRef.current) {
        onFetchData(internalRef.current.tableState);
      }
    }, [onFetchData]);

    const setMessage = (msgString, msgStyle = "info", msgDetails = []) => {
      setMessageString(msgString);
      setMessageStyle(msgStyle);
      setMessageDetails(msgDetails);
    };

    const clearFilters = useCallback(() => {
      if (internalRef.current) {
        internalRef.current.clearFilters();
      }
    }, [internalRef]);

    const exposedContext = {
      table: internalRef.current,
      refresh: handleRefresh,
      clearFilters,
      setMessage,
      setError,
    };

    // Expose context upwards with a ref
    useImperativeHandle(tableRef, () => exposedContext);

    // Refresh table if the refresh handler changes
    useEffect(() => handleRefresh(), [handleRefresh]);

    return (
      // Expose context to child components via context
      <TableContext.Provider value={exposedContext}>
        <DetailedErrorAlert error={error} />

        {message && (
          <DetailedAlert
            message={message}
            messageStyle={messageStyle}
            details={messageDetails}
            className=""
            alertLinkClass="alert-link ms-2"
          />
        )}

        <Button onClick={clearFilters} disabled={loading} outline color="secondary" size="sm" className="mb-3">
          Clear Filters
        </Button>

        <PersistantReactTable
          ref={internalRef}
          tableState={tableState}
          columns={columns}
          data={data}
          pages={pages}
          loading={loading}
          multiSort={false}
          onFetchData={onFetchData}
          defaultPageSize={50}
          dense
          {...rest}
        />
      </TableContext.Provider>
    );
  }
);
