import { X } from "lucide-react";
import { useState } from "react";
import { LoadingButton } from "../components/LoadingButton";
import { api } from "../util/api";

export const SuppressCaseButton = ({ id, ...props }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingButton
      {...props}
      size="sm"
      outline
      color="danger"
      title="Suppress Case"
      onClick={async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          await api.post(`/operations/prior_authorize_requests/clinic_mappings/unmapped/${id}/suppress`);
          window.location.reload();
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }}
      loading={loading}
    >
      <X />
    </LoadingButton>
  );
};
