import React, { useState } from "react";
import { LoadingButton } from "../LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../util/api";

export const MarkReviewedButton = ({ label = "Mark as Reviewed", resourceId, resourceType, reviewType, onReview, onError, ...rest }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    try {
      await api.put(`/admin/${resourceType}/${resourceId}/review/${reviewType}.json`);

      if (onReview) {
        await onReview();
      }
    } catch (err) {
      console.error(err);

      if (onError) {
        onError(extractErrorMessage(err));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton onClick={handleClick} loading={loading} color="link" {...rest}>
      {label}
    </LoadingButton>
  );
};
