import classnames from "classnames";
import { FormGroup, FormText } from "reactstrap";
import { Switch as BSSWitch } from "../Switch";

export const Switch = ({ children, input, label, meta, id, name, ...rest }) => (
  <FormGroup>
    <BSSWitch
      {...input}
      {...rest}
      className={classnames({ "text-danger": Boolean(meta.touched && meta.error) })}
      label={label}
      invalid={Boolean(meta.touched && meta.error)}
      id={id}
      disabled={rest.readOnly || rest.disabled}
    />

    {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
    {children}
  </FormGroup>
);
