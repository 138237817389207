import classNames from "classnames";
import React from "react";

export const DetailGrid = ({ children, className, ...rest }) => {
  return (
    <dl className={classNames("detail-grid", className)} {...rest}>
      {children}
    </dl>
  );
};

export const DetailGridItem = ({ title, value, hasValue = true, blankValue = "N/A", size = 3, copyable = false, ...rest }) => {
  return (
    <div>
      <dt className="text-muted fw-semibold">{title}</dt>
      <dd className="fs-5">{Boolean(value) && hasValue ? value : blankValue}</dd>
    </div>
  );
};
