import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { Switch } from "../../../components/inputs/final_form/Switch";

export const EditClinicSupportdLabModal = ({ clinicLabPermission, onEdit, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const initialValues = {
    labId: clinicLabPermission.lab.id,
    reflexToPa: clinicLabPermission.reflexToPa,
    useLabPricing: clinicLabPermission.useLabPricing,
  };

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (params) => {
    setError(null);
    try {
      const updateParams = { id: clinicLabPermission.id, ...params };
      const res = await api.patch(`/admin/clinic_lab_permissions/${clinicLabPermission.id}`, snakifyKeys(updateParams));

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Clinic Supported Labs</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          <Form onSubmit={handleEdit} initialValues={initialValues}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/labs.json"
                    label="Lab"
                    name="labId"
                    searchParam="name"
                    required
                  />
                  <Field component={Switch} label="Reflex to PA" name="reflexToPa" id="reflex-to-pa" type="checkbox" />
                  <Field component={Switch} label="Use Lab Pricing" name="useLabPricing" id="use-lab-pricing" type="checkbox" />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Save
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
