import { createRoot } from "react-dom/client";
import { AssignmentList } from "./AssignmentList";
import { AssignmentSearch } from "./AssignmentSearch";
import { ReassignmentSearch } from "./ReassignmentSearch";

export const initAssignmentList = () => {
  const container = document.getElementById("assignment_list_grid");

  if (container) {
    const root = createRoot(container);
    const { assignmentListPath, groups, defaultGroup } = container.dataset;

    root.render(<AssignmentList groups={JSON.parse(groups)} assignmentListPath={assignmentListPath} defaultGroup={defaultGroup} />);
  }
};

export const initAssignmentSearch = () => {
  const container = document.getElementById("assignment_search");

  if (container) {
    const root = createRoot(container);
    const { accountId, account, searchableStatuses, assignableAccounts, payorPathDefaults, showUserNotes } = container.dataset;

    root.render(
      <AssignmentSearch
        initialAccount={JSON.parse(account)}
        assignableAccounts={JSON.parse(assignableAccounts)}
        searchableStatuses={JSON.parse(searchableStatuses)}
        payorPathDefaults={JSON.parse(payorPathDefaults)}
        accountId={accountId}
        showUserNotes={showUserNotes === "true"}
      />
    );
  }
};

export const initReassignmentSearch = () => {
  const container = document.getElementById("reassignment_search");

  if (container) {
    const root = createRoot(container);

    const { account, assignableUsers, searchableStatuses, payorPathDefaults, showUserNotes } = container.dataset;

    root.render(
      <ReassignmentSearch
        account={JSON.parse(account)}
        assignableUsers={JSON.parse(assignableUsers)}
        searchableStatuses={JSON.parse(searchableStatuses)}
        payorPathDefaults={JSON.parse(payorPathDefaults)}
        showUserNotes={showUserNotes === "true"}
      />
    );
  }
};
