import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { parseCurrency } from "../../../util/formatters";
import { LabIdentifierForm } from "./LabIdentifierForm";
import { AddressFields, ADDRESS_FIELDS } from "../../../components/inputs/final_form/AddressFields";

export const CreateLabIdentifierModal = ({ lab, onCreate, ...rest }) => {
  const rekeyValues = (values) => {
    const newPayload = _.cloneDeep(values);
    newPayload.addressAttributes = _.pick(values, ADDRESS_FIELDS);
    return _.omit(newPayload, ADDRESS_FIELDS);
  };

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    const newValues = _.cloneDeep(values);
    newValues.labId = lab.id;
    if (newValues.defaultAddress == true) {
      newValues.address = values.lab.address;
    }

    setError(null);
    try {
      const res = await api.post("/admin/lab_identifiers", { lab_identifier: snakifyKeys(rekeyValues(newValues)) });

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add NPI/TIN Overrides
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add NPI/TIN Overrides</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{ lab }}>
            {(props) => <LabIdentifierForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
