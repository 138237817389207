import React, { useCallback } from "react";
import { api } from "../../util/api";
import { RemotePaginateSelector } from "./RemotePaginateSelector";

export const LAB_URL = "/labs.json";

export const LabSelector = ({ resourceUrl, scope, valueSelector = "id", labelSelector = "lab_name", filterList = undefined, ...rest }) => {
  const loadDefaultOptions = useCallback(async () => {
    if ((Array.isArray(rest.value) && rest.value.length === 0) || !rest.value) {
      return [];
    }

    try {
      const res = await api.get(`/labs/find_by_id.json`, { params: { id: rest.value } });
      return res.data.resources.map((l) => ({ value: l[valueSelector], label: l[labelSelector] }));
    } catch (error) {
      console.error("Failed to fetch default options for lab selector:", error);
      return [];
    }
  }, [rest.value, labelSelector, valueSelector]);

  return (
    <>
      <RemotePaginateSelector
        {...rest}
        resourceUrl={resourceUrl ? resourceUrl : LAB_URL}
        scope={scope}
        valueKey={valueSelector}
        labelKey={labelSelector}
        filterOptions={filterList}
        loadDefaultOptions={rest.value ? loadDefaultOptions : undefined}
        isDisabled={rest.disabled || rest.readOnly}
        dataKey="resources"
      />
    </>
  );
};
