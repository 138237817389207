import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { snakifyKeys } from "../../../util/helpers";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { BenefitPullTypeForm } from "./BenefitPullTypeForm";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";

export const AddBenefitPullTypeModal = ({ onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((o) => !o);

  const handleCreate = async (values) => {
    setError(null);

    const benefit_pull_type = snakifyKeys(values);
    try {
      const res = await api.post("/admin/benefit_pull_types", { benefit_pull_type });

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Benefit Pull Type
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add benefit pull type</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{}}>
            {(props) => <BenefitPullTypeForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
