import React, { useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { formatDateTime } from "../../../util/formatters";
import { snakifyKeys } from "../../../util/helpers";
import { CaseTypeCptsForm } from "./CaseTypeCptsForm";

export const EditCaseTypeCptsModal = ({ mapping, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleUpdate = async (values) => {
    setError(null);

    try {
      const res = await api.patch(`/admin/case_type_test_mappings/${mapping.id}`, {
        case_type_test_mapping: snakifyKeys(values),
      });

      if (onUpdate) {
        onUpdate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Edit Case Type CPT Mapping</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <dl>
            <dt>Created At</dt>
            <dd>{formatDateTime(mapping.createdAt)}</dd>

            <dt>Last Updated At</dt>
            <dd>{formatDateTime(mapping.updatedAt)}</dd>
          </dl>

          <Form
            onSubmit={handleUpdate}
            initialValues={{ ...mapping, labId: mapping.test.labId, payorId: mapping.payor?.id, planTypeGroup: mapping.planTypeGroup }}
          >
            {(props) => <CaseTypeCptsForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
