import { requiredNotNull } from "../../util/validators";
import { ChoiceGroup } from "./final_form/ChoiceGroup";

const IS_INSURED_RADIO_VALUE = "1";
const IS_UNINSURED_RADIO_VALUE = "0";

const patientIsUninsuredChoices = [
  { value: IS_INSURED_RADIO_VALUE, label: "Patient is insured" },
  { value: IS_UNINSURED_RADIO_VALUE, label: "Patient is uninsured" },
];

export function PatientIsInsuredSelector({ onIsInsuredChangeCallback = null, isInsuredHtmlAttributes = {}, ...rest }) {
  const handleIsInsuredChange = (option) => {
    onIsInsuredChangeCallback(option);
  };

  return (
    <div className="mb-3 insured-patient">
      <ChoiceGroup
        required
        label="Is the patient insured?"
        name="insuranceInfo.insured"
        id="insuranceInfo.insured"
        validate={requiredNotNull}
        onChange={handleIsInsuredChange}
        choices={patientIsUninsuredChoices}
        initialValue={IS_INSURED_RADIO_VALUE}
      />
    </div>
  );
}
