import { createRoot } from "react-dom/client";
import { CaseSearch } from "../cases/components/search/CaseSearch";

export const initCaseSearch = () => {
  const container = document.getElementById("case-search");

  if (container) {
    const root = createRoot(container);
    const { isInternal, searchableStatuses, isPharmacyClinicUser, isOffShoreAccount } = container.dataset;

    const parsedIsInternal = isInternal ? JSON.parse(isInternal) : false;
    const parsedSearchableStatuses = searchableStatuses ? JSON.parse(searchableStatuses) : [];
    const parsedIsPharmacyClinicUser = isPharmacyClinicUser ? JSON.parse(isPharmacyClinicUser) : false;
    const parsedIsOffShoreAccount = isOffShoreAccount ? JSON.parse(isOffShoreAccount) : false;

    root.render(
      <CaseSearch
        isInternal={parsedIsInternal}
        searchableStatuses={parsedSearchableStatuses}
        isPharmacyClinicUser={parsedIsPharmacyClinicUser}
        isOffShoreAccount={parsedIsOffShoreAccount}
      />
    );
  }
};
