import { useMemo } from "react";
import { DateCell } from "../../../components/grid/cells/DateCell";
import { DateFilter } from "../../../components/inputs/DateFilter";
import { DESTROY, UPDATE, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { snakifyKeys } from "../../../util/helpers";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable } from "../../components/AdministrateTable";
import { CreateLabDocumentModal } from "../components/CreateLabDocumentModal";
import { LabDocumentActionCell } from "../components/LabDocumentActionCell";

const useColumns = makeColumns((labId) => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    maxWidth: 15,
  },
  {
    id: "filename",
    accessor: "filename",
    Header: "Filename",
  },
  {
    id: "description",
    accessor: "description",
    Header: "Description",
  },
  {
    id: "created_at",
    accessor: "createdAt",
    Header: "Created At",
    maxWidth: 25,
    Filter: (props) => <DateFilter {...props} />,
    Cell: ({ row }) => <DateCell value={row.values?.created_at} />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <LabDocumentActionCell labId={labId} {...row} />,
    disableFilters: true,
    disableSortBy: true,
    maxWidth: 40,
  },
]);

export const LabDocumentsTab = ({ lab, labId }) => {
  const [canCreate] = useCreatePermission("LabDocument");
  const columns = useColumns(labId);

  const extraParams = useMemo(() => snakifyKeys({ labId }), [labId]);
  const { tableRef, setTableFilters } = useAdministrateTable(extraParams);

  const onCreate = (newLabDocument) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created New Lab Document ${newLabDocument.filename}`);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        {canCreate && <CreateLabDocumentModal color="primary" onCreate={onCreate} labId={labId} />}
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/lab_documents.json?lab_id=${labId}`}
        resourceName={"lab_document"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
        striped
      />
    </>
  );
};
