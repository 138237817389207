import React, { useState } from "react";
import { LoadingButton } from "../../../components/LoadingButton";
import { useTableContext } from "../../components/AdministrateTable";
import { api, redirectTo, extractErrorMessage } from "../../../util/api";
import { History } from "../../tools/sandbox/release/History";

export const UsersActionCell = ({ row }) => {
  const { setError } = useTableContext();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      await api.post(`/users/${row.original.id}/impersonate.json`);
      redirectTo("/");
    } catch (err) {
      setError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingButton onClick={handleClick} loading={loading} color="link">
        Impersonate
      </LoadingButton>
      <History entityName="Account" subjectId={row.original.id ?? "unspecified"} />
    </>
  );
};
