import React from "react";
import { FormText } from "reactstrap";
import { Icd10Selector as BaseIcd10Selector } from "../Icd10Selector";
import { InputWrapper, serializeInput } from "./InputWrapper";

export const Icd10Selector = ({ children, input, label, meta, ...rest }) => {
  const invalid = Boolean(meta.touched && meta.error);

  return (
    <InputWrapper label={label} required={rest.required} data-testid={rest["data-testid"]}>
      <BaseIcd10Selector {...input} {...rest} valid={!invalid} />

      {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}

      {children}
    </InputWrapper>
  );
};

export const SerializedIcd10Selector = serializeInput(Icd10Selector);
