import { api } from "./api";
import { getNow } from "./helpers";

const SESSION_EXPIRY_KEY = "sessionTtl";

export const retrieveTtl = () => parseInt(window.localStorage.getItem(SESSION_EXPIRY_KEY), 10);
export const storeTtl = (ttl) => window.localStorage.setItem(SESSION_EXPIRY_KEY, ttl);

export const fetchTtl = async () => {
  const res = await api.get("/timeout.json");
  const ttl = res.data;
  const now = getNow();
  const timeRemaining = Math.floor(now + ttl);

  // We set to max here so that we can never set the session timeout to 0 from the server.
  storeTtl(Math.max(timeRemaining, 1));
};
