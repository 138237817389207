import { createRoot } from "react-dom/client";

import { PayorConfigGrid } from "../../admin/PayorConfigGrid/PayorConfigGrid";

export const initAdminPayorConfigGrid = () => {
  const container = document.getElementById("payor_config_grid");

  if (container != null) {
    const root = createRoot(container);
    const { payorOptions, payorGroupOptions, cptOptions } = container.dataset;

    root.render(
      <PayorConfigGrid
        payorOptions={JSON.parse(payorOptions)}
        payorGroupOptions={JSON.parse(payorGroupOptions)}
        cptOptions={JSON.parse(cptOptions)}
      />
    );
  }
};
