import React, { useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { DetailedAlert } from "../../../components/DetailedAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { required, atLeastOneFieldRequired } from "../../../util/validators";
import { TestConnectionButton } from "./TestConnectionButton";

export const LabSftpSettingModal = ({ labId, onAction, sftp, toggleButtonLabel, toggleButtonProps, actionTitle, keyfileInput }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleAction = async (values) => {
    setError(null);
    try {
      let result;

      if (sftp) {
        result = await api.put(`/admin/labs/${labId}/sftp_settings/${sftp.id}`, snakifyKeys({ labSftpSetting: values }));
      } else {
        result = await api.post(`/admin/labs/${labId}/sftp_settings`, snakifyKeys({ labSftpSetting: values }));
      }

      const { data } = result;

      if (data) {
        onAction(data);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const collectFormValue = (props, fieldName) => {
    return props.form.getFieldState(fieldName)?.value;
  };

  const passwordField = () => {
    let props = {
      required: false,
      validate: null,
    };

    if (actionTitle === "Add") {
      props.required = true;
      props.validate = atLeastOneFieldRequired("keyfile");
    }

    return (
      <Field component={TextInput} required={props.required} validate={props.validate} type="password" label="Password" name="password" />
    );
  };

  return (
    <>
      <Button onClick={toggleModal} {...toggleButtonProps}>
        {toggleButtonLabel}
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>{actionTitle} SFTP Server Configuration</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          {message && <DetailedAlert message={message} messageStyle="info" alertLinkClass="alert-link ms-2" />}

          <Form onSubmit={handleAction} initialValues={sftp ? { labId: labId, ...sftp } : {}}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field component={TextInput} required validate={required} label="Hostname" name="host" />
                  <Field component={TextInput} required validate={required} label="Port" name="port" type="number" min="1" max="65535" />
                  <Field component={TextInput} required validate={required} label="Username" name="username" />
                  {passwordField()}
                  {keyfileInput}
                  <FormSpy subscription={{ valid: true }}>
                    {(props) => (
                      <TestConnectionButton
                        onError={setError}
                        onSuccess={setMessage}
                        connection={{
                          lab_sftp_setting: {
                            host: collectFormValue(props, "host"),
                            port: collectFormValue(props, "port"),
                            username: collectFormValue(props, "username"),
                            password: collectFormValue(props, "password"),
                            keyfile: collectFormValue(props, "keyfile"),
                          },
                        }}
                      />
                    )}
                  </FormSpy>
                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting} className="me-2">
                    Save
                  </LoadingButton>

                  <Button outline onClick={toggleModal}>
                    Cancel
                  </Button>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
