import { useMemo } from "react";
import { DetailsList } from "../../../components/DetailsList";
import { useAdminEnumValue, useAdminResource } from "../../../utils";

const useFollowupTaskConfigDetails = (followupTaskConfig) => {
  const { payorId, daysUntilFollowup, toStatus, toSubstatus, fromStatus, title } = followupTaskConfig;
  const { resource: payor } = useAdminResource(`/admin/payors/${payorId}.json`);
  const toStatusVal = useAdminEnumValue("prior_auth_statuses", toStatus);
  const toSubstatusVal = useAdminEnumValue("prior_auth_substatuses", toSubstatus, "value");
  const fromStatusVal = useAdminEnumValue("prior_auth_statuses", fromStatus);

  return useMemo(
    () => [
      {
        label: "Payor",
        value: payor?.name,
      },
      {
        label: "Days Until Follow-up",
        value: daysUntilFollowup,
      },
      {
        label: "To Status",
        value: toStatusVal,
      },
      {
        label: "To Substatus",
        value: toSubstatusVal,
      },
      {
        label: "From Status",
        value: fromStatusVal,
      },
      {
        label: "Task Name",
        value: title,
      },
    ],
    [payor, daysUntilFollowup, toStatusVal, toSubstatusVal, fromStatusVal, title]
  );
};

export const FollowupTaskConfigReadOnly = ({ followupTaskConfig }) => {
  const values = useFollowupTaskConfigDetails(followupTaskConfig);
  return <DetailsList values={values} />;
};
