import _ from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { adminApi, useAdminAPI } from "../util/api";
import { snakifyKeys } from "../util/helpers";
import { encodeFilterParams } from "../util/table";
import { useEffect } from "react";
import { usePermissions } from "./PermissionContext";
import { useStableValue } from "../util/hooks";

export const useAdminResource = (resourceUrl) => {
  const { data, ...rest } = useAdminAPI(resourceUrl);
  return { resource: data?.resource, ...rest };
};

export const useAdminResources = (resourceUrl) => {
  const { data, ...rest } = useAdminAPI(resourceUrl);
  return { resource: data?.resources, ...rest };
};

export const useAdminEnum = (enumName) => {
  const { data, ...rest } = useAdminAPI(`/admin/enums/${enumName}.json`);
  return { enumData: data?.resources, ...rest };
};

export const useAdminEnumValue = (enumName, value, valueKey = "id", labelKey = "label", fallback = "None") => {
  const { enumData } = useAdminEnum(enumName);
  const enumEntry = enumData?.find((e) => e[valueKey] === value);
  return enumEntry ? enumEntry[labelKey] : fallback;
};

export const useAdministrateTable = (extraParams) => {
  const tableRef = useRef(null);
  const [tableFilters, setTableFilters] = useState({});
  const allFilters = useMemo(
    () => ({
      ...snakifyKeys(extraParams),
      ...encodeFilterParams(tableFilters),
    }),
    [extraParams, tableFilters]
  );

  return { tableRef, allFilters, tableFilters, setTableFilters };
};

// eslint does not need to be involved in this silly metaprogramming
// This is an alias of useCallback, but needs wrapping because hooks.
export const makeColumns =
  (columnFn) =>
  (deps = []) =>
    useCallback(columnFn(deps), _.castArray(deps)); // eslint-disable-line react-hooks/exhaustive-deps

export const CREATE = "create";
export const READ = "read";
export const UPDATE = "update";
export const DESTROY = "destroy";
export const DISCARD = "discard";
export const ADMINISTER_PERMISSION = "administer";

export const CHECK_PERMISSION = "check";

export const useReadPermission = (resource, resourceId = null) => usePermissionCheck(READ, resource, resourceId);
export const useCreatePermission = (resource, resourceId = null) => usePermissionCheck(CREATE, resource, resourceId);
export const useUpdatePermission = (resource, resourceId = null) => usePermissionCheck(UPDATE, resource, resourceId);
export const useDestroyPermission = (resource, resourceId = null) => usePermissionCheck(DESTROY, resource, resourceId);
export const useDiscardPermission = (resource, resourceId = null) => usePermissionCheck(DISCARD, resource, resourceId);

export const usePermissionCheck = (ability, resource, resourceId = null) => {
  const [can, setCan] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [complete, setComplete] = useState(false);

  const { checkPermission, insertPermission } = usePermissions();

  const doPermissionCheck = useCallback(async () => {
    setLoading(true);
    try {
      const localPermission = checkPermission(ability, resource, resourceId);

      if (localPermission === CHECK_PERMISSION) {
        const res = await adminApi.get("/account/permissions/check", { params: { resource, ability, id: resourceId ?? undefined } });
        const permitted = Boolean(res.data?.permitted);
        insertPermission({ permissionName: ability, resourceName: resource, resourceId, permitted });
        setCan(permitted);
      } else {
        setCan(Boolean(localPermission));
      }
    } catch (e) {
      setError(e);
      setCan(false);
    } finally {
      setLoading(false);
      setComplete(true);
    }
  }, [ability, resource, resourceId, checkPermission, insertPermission]);

  useEffect(() => {
    if (!complete && !loading) {
      doPermissionCheck();
    }
  }, [doPermissionCheck, complete, loading]);

  return [can, loading, error, doPermissionCheck];
};

export const useBulkPermissions = (abilities, resource, resourceIds = []) => {
  const stableAbilities = useStableValue(abilities);
  const stableResourceIds = useStableValue(resourceIds);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { insertGlobalPermission, insertResourcePermission, insertInstancePermission } = usePermissions();

  const doPermissionCheck = useCallback(async () => {
    setLoading(true);
    try {
      const res = await adminApi.get("/account/permissions/bulk_check", {
        params: { resource, ability: stableAbilities, id: stableResourceIds ?? undefined },
      });

      const resourcePermissions = res.data;

      if (resourcePermissions) {
        resourcePermissions.abilities.forEach((a) => {
          if (resourcePermissions.resource !== null) {
            insertResourcePermission({ resourceName: resourcePermissions.resource, permissionName: a.ability, permitted: a.permitted });
          } else {
            insertGlobalPermission({ permissionName: a.ability, permitted: a.permitted });
          }
        });
        resourcePermissions.instances.forEach((i) =>
          insertInstancePermission({
            resourceName: resourcePermissions.resource,
            instanceId: i.id,
            permissionName: i.ability,
            permitted: i.permitted,
          })
        );
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [stableAbilities, resource, stableResourceIds, insertResourcePermission, insertInstancePermission, insertGlobalPermission]);

  useEffect(() => {
    doPermissionCheck();
  }, [doPermissionCheck]);

  return [loading, error, doPermissionCheck];
};
