import classnames from "classnames";
import { useEffect, useState } from "react";
import { Button, Col, Input, InputGroup, InputGroupText, Row } from "reactstrap";

export const PaginationComponent = (props) => {
  const {
    page,
    pages,
    loading,
    showPageSizeOptions,
    pageSizeOptions,
    pageSize,
    showPageJump,
    canPrevious,
    canNext,
    onPageSizeChange,
    className,
  } = props;

  const [internalPage, setInternalPage] = useState(page);

  const getSafePage = (page) => {
    if (Number.isNaN(page)) {
      page = props.page;
    }

    return Math.min(Math.max(page, 0), props.pages - 1);
  };

  const changePage = (page) => {
    page = getSafePage(page);
    setInternalPage(page);

    if (props.page !== page) {
      props.onPageChange(page);
    }
  };

  const applyPage = (e) => {
    if (e) {
      e.preventDefault();
    }
    const page = internalPage;
    changePage(page === "" ? props.page : page);
  };

  const onPageJumpChange = (e) => {
    const val = e.target.value;
    const page = val - 1;
    if (val === "") {
      setInternalPage(val);
      return;
    }
    setInternalPage(getSafePage(page));
  };

  useEffect(() => {
    setInternalPage(page);
  }, [page]);

  return (
    <div className="mt-3">
      <Row className={classnames(className)} style={props.style}>
        <Col>
          <Button
            block
            outline
            size="sm"
            color="secondary"
            onClick={() => {
              if (!canPrevious) return;
              changePage(page - 1);
            }}
            disabled={!canPrevious || loading}
          >
            Previous
          </Button>
        </Col>
        <Col>
          {showPageJump && (
            <InputGroup size="sm">
              <InputGroupText>Page</InputGroupText>

              <Input
                aria-label={props.pageJumpText}
                type={internalPage === "" ? "text" : "number"}
                onChange={onPageJumpChange}
                disabled={loading}
                value={internalPage === "" ? "" : internalPage + 1}
                onBlur={applyPage}
                onKeyPress={(e) => {
                  if (e.which === 13 || e.keyCode === 13) {
                    applyPage();
                  }
                }}
              />

              <InputGroupText>of {pages > 0 ? pages : 1}</InputGroupText>
            </InputGroup>
          )}
        </Col>
        <Col>
          {showPageSizeOptions && (
            <Input
              bsSize="sm"
              type="select"
              aria-label={props.rowsSelectorText}
              onChange={(e) => onPageSizeChange(Number(e.target.value))}
              value={pageSize}
              disabled={loading}
            >
              {pageSizeOptions.map((option, i) => (
                <option key={i} value={option}>
                  {`${option} rows`}
                </option>
              ))}
            </Input>
          )}
        </Col>
        <Col>
          <Button
            block
            outline
            size="sm"
            color="secondary"
            onClick={() => {
              if (!canNext) return;
              changePage(page + 1);
            }}
            disabled={!canNext || loading}
          >
            Next
          </Button>
        </Col>
      </Row>
    </div>
  );
};
