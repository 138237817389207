import { Trash } from "lucide-react";
import React, { useState } from "react";
import { ConfirmDeleteModal } from "../components/ConfirmDeleteModal";
import { LoadingButton } from "../components/LoadingButton";
import { api } from "../util/api";

export const FaxDestroyButton = ({ id, size = "xs", onDeleted, ...props }) => {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);
    await api.delete(`/operations/fax/${id}`);
    window.location.reload();
  };

  return (
    <ConfirmDeleteModal
      Target={
        <LoadingButton loading={deleting} className="btn-icon" outline size={size} href="#" title="Destroy Fax" {...props}>
          <Trash className="text-danger" />
        </LoadingButton>
      }
      onDelete={handleDelete}
      confirmMessage={`Are you sure you want to delete this fax?`}
      confirmLabel={"Delete"}
      title={`Delete Fax`}
    />
  );
};
