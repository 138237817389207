import React, { useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ReportDeliveryForm } from "./ReportDeliveryForm";

export const AddReportDeliveryModal = ({ onAdd, labSettingId, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);
    try {
      const res = await api.post("/admin/report_delivery_configs", snakifyKeys(values));

      if (onAdd) {
        onAdd(res.data.resource.dashboard_display_name);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const onDeliveryMethodChange = (selected) => {
    setSelectedDeliveryMethod(selected.value);
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Report Delivery
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add Report Delivery</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{ labSettingId: labSettingId }}>
            {(props) => <ReportDeliveryForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
