import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { DateInput } from "../../../components/inputs/final_form/DateInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { LoadingButton } from "../../../components/LoadingButton";
import { api } from "../../../util/api";
import { camelizeKeys, snakifyKeys } from "../../../util/helpers";
import { composeValidators, naiveUrl, required } from "../../../util/validators";
import { DiscardButton } from "../../../components/buttons/DiscardButton";
import { useUpdatePermission, useDestroyPermission } from "../../utils";

export const PoliciesGeneralTab = ({ policy, policyId, refresh }) => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const [canUpdatePolicies] = useUpdatePermission("Policy", policyId);
  const [canDestroyPolicies] = useDestroyPermission("Policy", policyId);

  const handleUpdate = async (values) => {
    setError(null);
    setMessage(null);

    try {
      await api.put(`/admin/policies/${policyId}`, { policy: snakifyKeys(values) });
      setMessage("Updated Policy");
      refresh();
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <DetailedErrorAlert error={error} />
      {message && <Alert color="info">{message}</Alert>}

      <Form onSubmit={handleUpdate} initialValues={camelizeKeys(policy)}>
        {({ handleSubmit, submitting, pristine }) => {
          return (
            <>
              <Field component={TextInput} required validate={required} label="Policy Name" name="name" disabled={!canUpdatePolicies} />
              <Field
                component={TextInput}
                required
                validate={composeValidators(required, naiveUrl)}
                label="Reference Link"
                type="url"
                name="referenceLink"
                disabled={!canUpdatePolicies}
              />
              <Field
                component={DateInput}
                required
                validate={required}
                label="Effective Date"
                name="effectiveDate"
                disabled={!canUpdatePolicies}
              />
              <Field component={Switch} label="Is Active?" name="active" id="active" type="checkbox" disabled={!canUpdatePolicies} />

              {canDestroyPolicies && (
                <div>
                  <DiscardButton
                    className={"me-2"}
                    confirmDiscard={true}
                    confirmMessage={`Are you sure you want to discard ${policy.name}?`}
                    endpoint={`/admin/policies/${policy.id}/discard`}
                    redirectUrl={"/admin/system/policies"}
                  >
                    Discard
                  </DiscardButton>

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine}>
                    Save
                  </LoadingButton>
                </div>
              )}
            </>
          );
        }}
      </Form>
    </>
  );
};
