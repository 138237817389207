import React, { useCallback, useContext, useMemo } from "react";
import { Button } from "reactstrap";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { BootstrapGrid } from "../components/grid/BootstrapGrid";
import { EnumFilter } from "../components/inputs/EnumFilter";
import { BoolFilter } from "../components/inputs/BoolFilter";
import { BoolCell } from "../components/grid/cells/BoolCell";
import { makeColumns } from "../system_config/utils";
import { api } from "../util/api";
import { useAsync } from "../util/hooks";
import { UpdateTargetModal } from "./UpdateTargetModal";
import { CheckCircle, Circle, MinusCircle, XCircle } from "lucide-react";

const AssignmentListTableContext = React.createContext({});
const useAssignmentTableContext = () => useContext(AssignmentListTableContext);

export const useAssignees = (assignmentListPath) => {
  const load = useCallback(async (assignmentListPath) => {
    const res = await api.get(assignmentListPath);

    return _.sortBy(res.data.rows, "name");
  }, []);

  return useAsync(load, assignmentListPath);
};

export const getIconAndColorClasses = (assignedCaseCount) => {
  let icon = <Circle className="me-1" size={14} />;
  let colorClass = "text-subtle";

  if (assignedCaseCount < 25) {
    icon = <CheckCircle className="me-1" size={14} />;
    colorClass = "text-success";
  } else if (assignedCaseCount < 40) {
    colorClass = "text-warning";
    icon = <MinusCircle className="me-1" size={14} />;
  } else if (assignedCaseCount >= 40) {
    icon = <XCircle className="me-1" size={14} />;
    colorClass = "text-danger";
  }

  return { icon, colorClass };
};

const COLOR_FILTER_OPTIONS = [
  {
    value: [null, 25],
    label: "< 25",
  },
  {
    value: [25, 40],
    label: "25–40",
  },
  {
    value: [40, null],
    label: "> 40",
  },
];

const useColumns = makeColumns(({ groupOptions }) => {
  return [
    {
      Header: "PA Specialist",
      id: "name",
      accessor: "name",
      filter: "text",
      width: 260,
    },
    {
      Header: "Onshore",
      id: "is_offshore",
      accessor: "is_offshore",
      Cell: (props) => <BoolCell invert yesLabel="Offshore" noLabel="Onshore" {...props} />,
      Filter: BoolFilter,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          return row.original.is_offshore != Boolean(filterValue);
        });
      },
      sortType: "basic",
      width: 120,
    },
    {
      Header: "Team",
      id: "team_name",
      accessor: "primary_group_name",
      filter: "text",
      Filter: (props) => <EnumFilter {...props} options={groupOptions} />,
      width: 260,
    },
    {
      Header: <span title="Cases Escalated, Due, and New">Total Cases</span>,
      id: "total_non_backlog_cases",
      accessor: "total_non_backlog_cases",
      Filter: (props) => <EnumFilter {...props} options={COLOR_FILTER_OPTIONS} />,
      filter: (rows, id, filterValue) => {
        return rows.filter((row) => {
          if (!filterValue || !_.isArray(filterValue)) {
            return true;
          }

          const numValue = Number(row.values.total_non_backlog_cases);
          const [lowerBound, upperBound] = filterValue;

          if (lowerBound !== null && upperBound !== null) {
            return numValue < upperBound && numValue > lowerBound;
          } else if (lowerBound === null && upperBound !== null) {
            return numValue < upperBound;
          } else if (lowerBound !== null && upperBound === null) {
            return numValue > lowerBound;
          }

          return false;
        });
      },
      Cell: ({ row, value }) => {
        const totalCases = Number(value);

        const { icon, colorClass } = getIconAndColorClasses(totalCases);

        return (
          <span className={colorClass}>
            {icon} {value}
          </span>
        );
      },
      width: 120,
    },
    {
      Header: "Due",
      id: "due_cases_count",
      accessor: "due_cases_count",
      width: 120,
    },
    {
      Header: "Target",
      id: "target_case_assignment_count",
      accessor: "target_case_assignment_count",
      width: 120,
    },
    {
      Header: "Actions",
      id: "actions",
      disableFilters: false,
      disableSortBy: false,
      width: 480,
      minWidth: 480,
      Cell: ({ row }) => {
        const { refreshAssignees } = useAssignmentTableContext();
        return (
          <>
            <Button color="primary" outline className="ms-2" href={`/cases/assign/${row.original.id}`}>
              Assign Cases
            </Button>
            <Button color="link" outline className="ml-2 text-danger" href={`/cases/reassign/${row.original.id}`}>
              Reassign Cases
            </Button>
            <UpdateTargetModal
              currentTarget={row.original.target_case_assignment_count}
              userId={row.original.id}
              onUpdate={() => refreshAssignees()}
              buttonProps={{ color: "link" }}
            />
          </>
        );
      },
    },
  ];
});

const groupsToOptions = (groups) => groups.map((g) => ({ value: g.team_name, label: g.team_name }));

// This component turns off pagination when fetching, fetching all internal users from the database
// It doesn't paginate the resulting table either, instead displaying the first 100 users.
export const AssignmentList = ({ groups, assignmentListPath, defaultGroup }) => {
  const { value: assignees, fetch: refreshAssignees, loading, error } = useAssignees(assignmentListPath);
  const groupOptions = useMemo(() => groupsToOptions(groups), [groups]);
  const columns = useColumns({ groupOptions });

  return (
    <>
      <DetailedErrorAlert error={error} />

      <AssignmentListTableContext.Provider value={{ refreshAssignees, loading }}>
        <BootstrapGrid
          disableFilters={false}
          columns={columns}
          loading={loading}
          data={assignees ?? []}
          showPagination={false}
          defaultSorted={[{ id: "name" }]}
          // Bootstrap Grid doesn't actually have pageSize as a param, it has to be defaultPageSize
          defaultPageSize={assignees?.length > 0 ? assignees?.length : 100}
          filtered={[
            {
              id: "team_name",
              value: defaultGroup,
            },
          ]}
        />
      </AssignmentListTableContext.Provider>
    </>
  );
};
