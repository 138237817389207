import React from "react";
import { Alert } from "reactstrap";

export const SplitHistory = ({ parent, children, siblings }) => {
  const anyChildren = children && children.length > 0;
  const anySiblings = siblings && siblings.length > 0;

  // These hrefs will obviously have to be more explicit if we ever put this component anywhere other than the #show page.
  const parentLink = (
    <a href={parent} className="token">
      {parent}
    </a>
  );

  const parentNotice = <p>This request was split from request {parentLink}. Only a Supervisor may split it further.</p>;

  const siblingLinks = siblings.map((sib, ix) => [
    ix > 0 && ", ",
    <a key={ix} href={sib} className="token">
      {sib}
    </a>,
  ]);

  const siblingNotice = (
    <p>
      Other requests split from {parentLink} are: {siblingLinks}
    </p>
  );

  const childrenLinks = children.map((child, ix) => [
    ix > 0 && ", ",
    <a key={ix} href={child} className="token">
      {child}
    </a>,
  ]);

  const childrenNotice = (
    <p>This request was already split. It may not be split again. It was split into these requests: {childrenLinks}</p>
  );

  return (
    <Alert color="warning">
      {parent && parentNotice}
      {anySiblings && siblingNotice}
      {parent && anyChildren && <hr className="my-4" />}
      {anyChildren && childrenNotice}
    </Alert>
  );
};
