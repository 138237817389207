import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { useUpdatePermission } from "../../utils";
import { PayorReflexToPAToggle } from "./PayorReflexToPAToggle";

export const PayorReflexToPAActionCell = ({ row }) => {
  const [canUpdate] = useUpdatePermission("ClinicPayorPermission", row.original.id);

  const { refresh, setMessage, setError } = useTableContext();
  const { reflexToPa, payor } = row.original ?? {};

  const handleChange = (msg) => {
    setMessage(msg);
    refresh();
  };

  return (
    <PayorReflexToPAToggle
      clinicPayorPermission={row.original}
      payorName={payor.name}
      reflexToPa={reflexToPa}
      onChange={handleChange}
      readOnly={!canUpdate}
    />
  );
};
