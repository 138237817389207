import { createRoot } from "react-dom/client";
import { BIView } from "../cases/BIView";

export const initCaseView = () => {
  const container = document.getElementById("bi-details-section");

  if (container) {
    const root = createRoot(container);
    const dataset = container.dataset;
    const biState = dataset.biState;
    const biErrors = JSON.parse(dataset.biErrors);

    const testInfo = {
      name: dataset.testName,
      cptCodes: JSON.parse(dataset.cptCodes),
      icd10Codes: dataset.icd10Codes?.split(","),
      coverageType: dataset.insuranceCoverageType,
      labName: dataset.labName,
    };

    const practiceInfo = {
      name: dataset.providerName,
      practiceName: dataset.providerPracticeName,
      npi: dataset.providerNpi,
      enrolled: dataset.providerEnrolled,
    };

    const insuranceInfo = {
      name: dataset.insuranceName,
      verifiedName: dataset.insuranceVerifiedName,
      coverageType: dataset.insuranceCoverageType,
      planType: dataset.insurancePlanType || null,
      memberId: dataset.insuranceMemberId,
      activeCoverage: dataset.insuranceActiveCoverage,
      fundingType: dataset.insuranceFundingType,
    };

    const biInfo = JSON.parse(dataset.biInfo);
    if (biInfo && dataset.productBenefits) {
      biInfo.productBenefits = JSON.parse(dataset.productBenefits);
    }

    const hasVerifiedPayor = JSON.parse(dataset.hasVerifiedPayor);
    const hasVerifiedTests = JSON.parse(dataset.hasVerifiedTests);
    const verifiedCptCodes = JSON.parse(dataset.paRequirementsAndApprovals);
    const serviceType = dataset.serviceType;

    const paRequirementsConfigured = dataset.paRequirementsConfigured === "true";

    const hidePolicyLink = dataset.hideMedicalPolicy === "true";
    const noMoopProvided = dataset.noMoopProvidedByPayor === "true";
    const priceComparisonEnabled = dataset.priceComparisonEnabled === "true";
    const payorShowPriceComparison = dataset.payorShowPriceComparison === "true";
    const casePublicId = dataset.caseId || "";

    const trizettoEnabled = dataset.trizettoEnabled === "true";
    const coveredChargesEnabled = dataset.showCoveredCharges === "true";
    const showPaApprovalPercentage = dataset.showPaApprovedPercentage === "true";

    const isPharmacyOwnedByClinic = dataset.isPharmacyOwnedByClinic === "true";

    const labOrClinicUser = JSON.parse(dataset.labOrClinicUser);
    const clinicTypePharmacy = JSON.parse(dataset.clinicTypePharmacy);

    root.render(
      <BIView
        test={testInfo}
        practice={practiceInfo}
        insurance={insuranceInfo}
        bi={biInfo}
        configured={paRequirementsConfigured}
        hidePolicyLink={hidePolicyLink}
        biState={biState}
        biErrors={biErrors}
        priceComparisonEnabled={priceComparisonEnabled}
        payorShowPriceComparison={payorShowPriceComparison}
        noMoopProvided={noMoopProvided}
        caseId={casePublicId}
        hasVerifiedPayor={hasVerifiedPayor}
        hasVerifiedTests={hasVerifiedTests}
        verifiedCptCodes={verifiedCptCodes}
        serviceType={serviceType}
        trizettoEnabled={trizettoEnabled}
        showPaApprovalPercentage={showPaApprovalPercentage}
        isPharmacyOwnedByClinic={isPharmacyOwnedByClinic}
        labOrClinicUser={labOrClinicUser}
        clinicTypePharmacy={clinicTypePharmacy}
        coveredChargesEnabled={coveredChargesEnabled}
      />
    );
  }
};
