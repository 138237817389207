import $ from "jquery";
import Cleave from "cleave.js";

export function initCleave() {
  $(document).on("ready", function () {
    $(".money").each(function () {
      new Cleave(this, {
        prefix: "$",
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
        rawValueTrimPrefix: true,
      });
    });

    $(".percent").each(function () {
      new Cleave(this, {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      });
    });
  });
}
