import React, { useContext } from "react";
import { Field, Form } from "react-final-form";
import { Button, FormText } from "reactstrap";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../../components/LoadingButton";
import { api } from "../../../../util/api";
import { required } from "../../../../util/validators";
import { WizardContext } from "../../WizardContext";

export const SalesforceContactScreen = () => {
  const { recordMiSalesforceContactUrl, dispatch, setLoading, setError, state } = useContext(WizardContext);

  const onSubmit = async ({ salesforceMessage }) => {
    setLoading(false);
    setError(null);

    try {
      await api.post(recordMiSalesforceContactUrl, { salesforce_message: salesforceMessage });
      dispatch({ type: "gonext" });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleResolveMissingInfo = () => dispatch({ type: "setAnswer", key: "anyRemainingMissingInfo", value: true, doTransition: true });

  return (
    <>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, form }) => {
          const messageLength = form.getState().values?.salesforceMessage?.length || 0;
          return (
            <>
              <div className="mb-3">
                <Field
                  component={TextInput}
                  type="textarea"
                  label="Message"
                  required
                  validate={required}
                  name="salesforceMessage"
                  maxLength={255}
                >
                  <FormText>Please provide a description of what is missing to the lab. {messageLength}/255 characters.</FormText>
                </Field>
              </div>

              <div className="d-flex flex-row align-items-center">
                <LoadingButton loading={submitting} onClick={handleSubmit} className="me-2">
                  Send to Lab/{window.branding.brandName} Cares Team
                </LoadingButton>

                <Button color="link" onClick={handleResolveMissingInfo}>
                  Resolve MI Now
                </Button>
              </div>
            </>
          );
        }}
      </Form>
    </>
  );
};
