import React, { useMemo } from "react";
import { Alert } from "reactstrap";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { TabbedRoutes } from "../../components/TabbedRoutes";
import { BulkReprocessForm } from "./BulkReprocessForm";
import { SingleReprocessForm } from "./SingleReprocessForm";

const useTabs = () =>
  useMemo(
    () => [
      {
        path: "single",
        label: "Reprocess Case",
        Component: SingleReprocessForm,
      },
      {
        path: "bulk",
        label: "Bulk Reprocess Cases",
        Component: BulkReprocessForm,
      },
    ],
    []
  );

export const ReprocessBICasesPage = () => {
  const tabs = useTabs();

  return (
    <SystemConfigLayout title={["Tools", "Reprocess BI Cases"]}>
      <Alert color="warning">
        <strong>Note:</strong> Reprocessing a BI case will result in the original case to be discarded and a new case will be created with a
        new {window.branding.brandNameShort} Case ID.
      </Alert>

      <TabbedRoutes tabs={tabs} defaultTab={"single"} />
    </SystemConfigLayout>
  );
};
