import cn from "classnames";
import { FormGroup, Input, Label } from "reactstrap";

export const BootstrapRadio = ({ options, value, required, disabled, readonly, autofocus, onChange }) => {
  const name = `input_${parseInt(Math.random() * 10000).toString()}`;
  const { enumOptions, inline } = options;
  const classes = inline ? "d-flex flex-row align-items-center" : "d-flex flex-column";

  return (
    <div className={classes}>
      {enumOptions.map((option, i) => {
        const id = `${name}_${i}`;
        const checked = option.value === value;

        return (
          <div key={i} className={cn({ "me-2": inline })}>
            <FormGroup check>
              <Input
                type="radio"
                checked={checked}
                name={name}
                id={id}
                required={required}
                value={option.value}
                disabled={disabled || readonly}
                autoFocus={autofocus && i === 0}
                onChange={(_) => onChange(option.value)}
              />

              <Label check htmlFor={id}>
                {option.label}
              </Label>
            </FormGroup>
          </div>
        );
      })}
    </div>
  );
};
