import React, { useCallback } from "react";
import { Alert } from "reactstrap";
import { Field } from "react-final-form";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const ImportLabFeeScheduleByTestModal = ({ labId, onImport, ...rest }) => {
  const handleImport = useCallback(
    async (values) => {
      const params = {
        payorIds: values.payorIds,
        file: {
          bytes: values.file,
        },
      };

      const res = await api.post(`/admin/cpt_fee_schedule_by_tests/import.json?lab_id=${labId}`, snakifyKeys(params));

      if (onImport) {
        onImport(res.data?.meta?.message, res.data?.meta?.error_details);
      }
    },
    [labId, onImport]
  );

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Import Fee Schedule By Test..."
      exampleDownloadPath="/admin/cpt_fee_schedule_by_tests/example"
    >
      {(formProps) => (
        <>
          <Alert color="warning">
            <p>This import will add or update CPT Fee Schedule entries based on Payor and Test .</p>
          </Alert>
        </>
      )}
    </ImportModal>
  );
};
