import { useCallback, useState } from "react";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { AuthTokenForm } from "./AuthTokenForm";
import { Form } from "react-final-form";

export const CreateAuthTokenModal = ({ onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = useCallback(
    async (params) => {
      setError(null);
      try {
        const res = await api.post("/admin/auth_tokens", snakifyKeys({ auth_token: params }));

        if (onAdd) {
          onAdd(res.data?.meta?.message, res.data?.meta?.error_details);
        }

        setOpen(false);
      } catch (err) {
        setError(extractErrorMessage(err));
      }
    },
    [onAdd]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add New Auth Token
      </Button>

      <Modal size="lg" isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New Auth Token</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{}}>
            {(props) => <AuthTokenForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
