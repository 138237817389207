import { useMemo, useRef, useState } from "react";
import { CountCell } from "../../../components/grid/cells/CountCell";
import { Toolbar } from "../../../components/Toolbar";
import { deepCompact, snakifyKeys } from "../../../util/helpers";
import { encodeFilterParams } from "../../../util/table";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { BoolCell } from "../../../components/grid/cells/BoolCell";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { DESTROY, UPDATE, useCreatePermission } from "../../utils";
import { CreateLabIdentifierModal } from "../components/CreateLabIdentifierModal";
import { ImportLabIdentifierModal } from "../components/ImportLabIdentifierModal";
import { LabIdentifierActionCell } from "../components/LabIdentifierActionCell";

const useColumns = (labId) =>
  useMemo(
    () => [
      {
        id: "default_address",
        accessor: "defaultAddress",
        Header: "Default Address",
        Filter: BoolFilter,
        Cell: BoolCell,
        disableSortBy: true,
      },
      {
        id: "npi",
        accessor: "npi",
        Header: "NPI",
      },
      {
        id: "tin",
        accessor: "tin",
        Header: "TIN",
      },
      {
        id: "detailAddress",
        accessor: "detailAddress",
        Header: "Address",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        id: "benefit_management_portal.name",
        accessor: "benefitManagementPortal.name",
        Header: "Verified Portal",
        disableSortBy: true,
        Filter: (props) => (
          <AdministrateEnumFilter
            {...props}
            resourceUrl="/admin/benefit_management_portals.json"
            valueKey="name"
            labelKey="name"
            searchParam="name"
          />
        ),
      },
      {
        id: "payor.name",
        accessor: "payor.name",
        Header: "Verified Payor",
        disableSortBy: true,
        Filter: (props) => (
          <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="name" labelKey="name" searchParam="name" />
        ),
      },
      {
        id: "servicingLabNpi",
        accessor: "servicingLabNpi",
        Header: "Servicing Lab NPI",
      },
      {
        id: "tests",
        accessor: (x) => x.tests.length,
        Header: "Verified Test(s)",
        Cell: ({ value }) => <CountCell count={value} label="Tests" />,
        Filter: (props) => (
          <AdministrateEnumFilter
            {...props}
            resourceUrl={`/admin/tests.json?test[direction]=asc&test[order]=commercial_name&lab=${labId}&archived=false`}
            isMulti
            valueKey="commercial_name"
            labelKey="commercial_name"
          />
        ),
      },
      {
        id: "actions",
        Header: "Actions",
        Cell: LabIdentifierActionCell,
        filterable: false,
        sortable: false,
      },
    ],
    [labId]
  );

export const LabsIdentifiersTab = ({ labId, lab }) => {
  const tableRef = useRef();
  const columns = useColumns(labId);
  const [showArchivedTests, setShowArchivedTests] = useState(false);
  const [tableFilters, setTableFilters] = useState({});
  const [canCreate] = useCreatePermission("LabIdentifier");

  const extraParams = useMemo(
    () => deepCompact({ lab: { id: labId }, archived: showArchivedTests || undefined }),
    [labId, showArchivedTests]
  );

  const allFilters = useMemo(
    () => ({
      ...snakifyKeys(extraParams),
      ...encodeFilterParams(tableFilters),
    }),
    [extraParams, tableFilters]
  );

  const toggleArchivedTestFilter = () => setShowArchivedTests((x) => !x);

  const onCreate = (newLabIdentifier) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created Lab Identifier Mapping for npi ${newLabIdentifier.npi} and tin ${newLabIdentifier.tin}`);
    refresh();
  };

  const onImport = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/lab_identifiers/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreate && (
            <>
              <ImportLabIdentifierModal labId={labId} outline className="me-2" onImport={onImport} />
              <CreateLabIdentifierModal color="primary" lab={lab} onCreate={onCreate} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/lab_identifiers.json"}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
        resourceName={"lab_identifier"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
