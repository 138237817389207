import { createRoot } from "react-dom/client";

import { PartialClinicMatchesGrid } from "../../partial_clinic_matches_grid/index";

export const initPartialClinicMatchesGrid = () => {
  const gridContainer = document.getElementById("partial_clinic_mappings_grid");

  if (gridContainer) {
    const root = createRoot(gridContainer);
    root.render(<PartialClinicMatchesGrid />);
  }
};
