import React from "react";
import { DateFilter } from "../components/inputs/DateFilter";
import { formatDate, userNameOrEmail } from "../util/formatters";
import { TokenCell } from "../components/grid/cells/TokenCell";

const EditBillingButton = ({ id }) => (
  <a className="btn btn-sm btn-outline-primary" href={`/admin/billing_rates/${id}`}>
    Edit
  </a>
);

export const COLUMNS = [
  {
    Header: "",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => <EditBillingButton id={_.get(row, "original.id")} />,
    className: "action-cell",
    minWidth: 250,
    id: "edit_button",
  },
  {
    Header: "Lab",
    id: "lab_name",
    accessor: "lab_name",
    minWidth: 300,
  },
  {
    Header: "Account Number",
    id: "lab_internal_id",
    accessor: "lab_internal_id",
    Cell: TokenCell,
    minWidth: 300,
  },
  {
    Header: "Billing Records Count",
    id: "number_of_billing_rates",
    accessor: "number_of_billing_rates",
  },
  {
    Header: "Created",
    id: "created_at",
    accessor: (r) => formatDate(r.created_at),
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    Header: "Last Updated",
    id: "updated_at",
    accessor: (r) => formatDate(r.updated_at),
    Filter: (props) => <DateFilter {...props} />,
  },
];
