import React, { useCallback } from "react";
import { Alert } from "reactstrap";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";

export const ImportPayorGroupMedicaidScheduleModal = ({ payorGroup, onImport, ...rest }) => {
  const handleImport = useCallback(
    async (values) => {
      const params = {
        file: {
          bytes: values.file,
        },
      };
      const res = await api.post(`/admin/medicaid_fee_schedules/import.json?payor_group_id=${payorGroup.id}`, snakifyKeys(params));

      onImport?.(res.data?.meta?.message, res.data?.meta?.error_details);
    },
    [onImport, payorGroup.id]
  );

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Import Medicaid Fee Schedules..."
      exampleDownloadPath={`/admin/medicaid_fee_schedules/example`}
    >
      <Alert color="warning">
        <p>
          This import performs a <b>replacement</b> of the fee schedule for this Payor Group.
        </p>
        <p>Payor Group ID is validated to protect against accidental overwriting from the wrong file.</p>
      </Alert>
    </ImportModal>
  );
};
