import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../../components/AdministrateTable";
import { History } from "../../../tools/sandbox/release/History";
import { useDestroyPermission, useUpdatePermission } from "../../../utils";
import { EditMissingInfoCategoryModal } from "./EditMissingInfoCategoryModal";

export const MissingInfoCategoryActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("MissingInfoCategory", row.original.id);
  const [canUpdate] = useUpdatePermission("MissingInfoCategory", row.original.id);

  return (
    <>
      {canUpdate && (
        <EditMissingInfoCategoryModal
          color="link"
          missingInfoCategory={row.original}
          onUpdate={(record) => {
            refresh();
            setMessage(`Updated Missing Info Category ${record.id}`);
          }}
        />
      )}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/missing_info_categories/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Missing Info Category ${row.original.id}`);
          }}
          onError={setError}
        />
      )}

      <History entityName="MissingInfoCategory" subjectId={row.original.id} />
    </>
  );
};
