import { useMemo, useState } from "react";
import { Toolbar } from "../../../components/Toolbar";
import { deepCompact } from "../../../util/helpers";
import { AdministrateExportButton } from "../../components/AdministrateExportButton";
import { AdministrateSelect } from "../../components/AdministrateSelect";
import { AdministrateTable } from "../../components/AdministrateTable";
import { makeColumns, useAdministrateTable, useUpdatePermission } from "../../utils";
import { AddPayorsModal } from "../components/AddPayorsModal";
import { ImportPayorsModal } from "../components/ImportPayorsModal";
import { PolicyPayorActionCell } from "../components/PolicyPayorActionCell";

const useColumns = makeColumns((policyId) => [
  {
    id: "name",
    accessor: "name",
    Header: "Name",
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <PolicyPayorActionCell policyId={policyId} {...row} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PoliciesPayorsTab = ({ policy, policyId }) => {
  const [selectedPayorGroupId, setSelectedPayorGroupId] = useState(null);
  const [canUpdatePolicy] = useUpdatePermission("Policy", policyId);
  const columns = useColumns().filter((col) => canUpdatePolicy || col.id !== "actions");

  const extraParams = useMemo(
    () => deepCompact({ policies: { id: policyId }, payorGroups: { id: selectedPayorGroupId } }),
    [policyId, selectedPayorGroupId]
  );
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onAddPayors = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar>
          <div style={{ width: 300 }}>
            <AdministrateSelect
              placeholder="Filter by Payor Group..."
              resourceUrl="/admin/payor_groups.json"
              onChange={setSelectedPayorGroupId}
              value={selectedPayorGroupId}
              className="me-2"
            />
          </div>
        </Toolbar>

        <Toolbar className="ms-auto">
          <AdministrateExportButton outline className="me-2" exportUrl="/admin/payors.csv" filters={allFilters} />
          {canUpdatePolicy && <ImportPayorsModal outline className="me-2" onImport={onAddPayors} policyId={policyId} />}
          {canUpdatePolicy && <AddPayorsModal policy={policy} color="primary" onAdd={onAddPayors} />}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/payors.json"}
        extraParams={extraParams}
        resourceName={"payor"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
