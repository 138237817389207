import React from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Button, Row, Col, FormGroup, Container } from "reactstrap";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../../components/LoadingButton";
import { distinctValues, required } from "../../../../util/validators";
import { Fieldset } from "../../../../components/inputs/final_form/Fieldset";

export const InvalidMemberIdForm = ({ handleSubmit, submitting, pristine }) => {
  return (
    <>
      <Field component={TextInput} required label="Invalid Member Id" name="invalidMemberId" />
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine} className="mt-3">
        Save
      </LoadingButton>
    </>
  );
};
