import React from "react";
import cx from "classnames";

export const Toolbar = ({ children, className, start = false, ...props }) => {
  const classes = cx(
    "d-flex flex-row align-items-center",
    {
      "justify-content-end": !start,
      "justify-content-start": !!start,
    },
    className
  );
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};
