import _ from "lodash";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { camelizeKeys } from "../../util/helpers";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { TabbedRoutes } from "../components/TabbedRoutes";
import { Routes } from "../Routes";
import { useAdminResource } from "../utils";
import { CaseTypeCptsTab } from "./tabs/CaseTypeCptsTab";
import { LabsCptCodeListPricesTab } from "./tabs/LabsCptCodeListPricesTab";
import { LabsFeeScheduleByPayorTab } from "./tabs/LabsFeeScheduleByPayorTab";
import { LabsFeeScheduleByPlanTypeTab } from "./tabs/LabsFeeScheduleByPlanTypeTab";
import { LabsFeeScheduleByTestTab } from "./tabs/LabsFeeScheduleByTestTab";
import { LabsGeneralTab } from "./tabs/LabsGeneralTab";
import { LabsPayorsNetworkStatusTab } from "./tabs/LabsPayorsNetworkStatusTab";
import { LabsPlanTypeNetworkStatusTab } from "./tabs/LabsPlanTypeNetworkStatusTab";
import { LabsPortalManagementTab } from "./tabs/LabsPortalManagementTab";
import { LabsReportDeliveriesTab } from "./tabs/LabsReportDeliveriesTab";
import { LabsSweepSettingsTab } from "./tabs/LabsSweepSettingsTab";
import { LabsTestsTab } from "./tabs/LabsTestsTab";
import { LabsIdentifiersTab } from "./tabs/LabsIdentifiersTab";
import { LabNotesTab } from "./tabs/LabNotesTab";
import { LabDocumentsTab } from "./tabs/LabDocumentsTab";

const useBreadcrumbs = (pageTitle, labId) =>
  useMemo(
    () => [
      { url: Routes.labs.url(), label: Routes.labs.label },
      { url: Routes.lab.url(labId), label: pageTitle, active: true },
    ],
    [pageTitle, labId]
  );

const useLab = (labId) => camelizeKeys(useAdminResource(`/admin/labs/${labId}.json`));

const useTabs = (lab) => [
  {
    path: "general",
    label: "General",
    Component: LabsGeneralTab,
    resource: "Lab",
  },
  {
    path: "labs_identifiers",
    label: "NPI/ TIN Overrides",
    Component: LabsIdentifiersTab,
    resource: "LabsIdentifiers",
  },
  {
    path: "tests",
    label: "Tests",
    Component: LabsTestsTab,
    resource: "Test",
  },
  {
    path: "case_type_test_mappings",
    label: "Case Type CPT Overrides",
    Component: CaseTypeCptsTab,
    resource: "CaseTypeTestMapping",
  },
  {
    label: "Network Status",
    items: [
      {
        path: "payor_network_status",
        label: "Payor Network Status",
        Component: LabsPayorsNetworkStatusTab,
        resource: "PayorLabSetting",
      },
      {
        path: "plan_type_network_status",
        label: "Plan Type Network Status",
        Component: LabsPlanTypeNetworkStatusTab,
        resource: "PlanTypeNetworkSetting",
      },
    ],
  },
  {
    path: "report_deliveries",
    label: "Report Deliveries",
    Component: LabsReportDeliveriesTab,
    resource: "ReportDeliveryConfig",
  },
  {
    path: "portal_management",
    label: "Portal Management",
    Component: LabsPortalManagementTab,
    resource: "LabPortalMapping",
  },
  {
    label: "Lab Pricing",
    items: [
      {
        path: "fee_schedule_by_plan_type",
        label: "CPT Fee Schedule By Plan Type",
        Component: LabsFeeScheduleByPlanTypeTab,
        resource: "CptFeeScheduleByPlanType",
      },
      {
        path: "fee_schedule_by_payor",
        label: "CPT Fee Schedule By Payor",
        Component: LabsFeeScheduleByPayorTab,
        resource: "CptFeeSchedule",
      },
      {
        path: "fee_schedule_by_test",
        label: "CPT Fee Schedule By Test",
        Component: LabsFeeScheduleByTestTab,
        resource: "CptFeeScheduleByTest",
      },
      {
        path: "cpt_code_list_prices",
        label: "CPT Code List Pricing",
        Component: LabsCptCodeListPricesTab,
        resource: "LabCptCodeListPrice",
      },
    ],
  },
  {
    path: "sweep_settings",
    label: "Sweep Settings",
    Component: LabsSweepSettingsTab,
    resource: "Lab",
  },
  {
    path: "lab_notes",
    label: "Lab Notes",
    Component: LabNotesTab,
    resource: "LabNote",
  },
  {
    path: "lab_documents",
    label: "Lab Documents",
    Component: LabDocumentsTab,
    resource: "LabDocument",
  },
];

export const LabsShowPage = () => {
  const { labId } = useParams();
  const { resource: lab, error, isLoading, fetch } = useLab(labId);
  const pageTitle = lab?.labName || `Lab #${labId}`;
  const breadcrumbs = useBreadcrumbs(pageTitle, labId);

  return (
    <SystemConfigLayout title={pageTitle} showBreadcrumbs crumbs={breadcrumbs} loading={isLoading}>
      {error && <Alert color="danger">{error}</Alert>}

      {/* TODO: Check Tabbed route permissions filter correctly */}
      <TabbedRoutes tabs={useTabs(lab)} defaultTab={"general"} lab={lab} labId={labId} refresh={fetch} />
    </SystemConfigLayout>
  );
};
