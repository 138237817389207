import React from "react";
import { NavLink as Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

export const BreadCrumbs = ({ crumbs }) => {
  return (
    <Breadcrumb>
      {crumbs.map((r) => (
        <BreadcrumbItem key={r.url} active={r.active}>
          {r.active ? r.label : <Link to={r.url}>{r.label}</Link>}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
