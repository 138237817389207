import React from "react";
import { YesNoPanel } from "../../../components/wizards/YesNoPanel";
import { WizardContext } from "../WizardContext";

export const PayorFollowupScreen = () => (
  <WizardContext.Consumer>
    {({ dispatch }) => (
      <>
        <YesNoPanel
          question="Is additional followup required with the Payor?"
          yesAction={() => {
            dispatch({
              type: "setAnswer",
              key: "additionalPayorFollowup",
              value: true,
              doTransition: true,
            });
          }}
          noAction={() => {
            dispatch({
              type: "setAnswer",
              key: "additionalPayorFollowup",
              value: false,
              doTransition: true,
            });
          }}
        />
      </>
    )}
  </WizardContext.Consumer>
);
