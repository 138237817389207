import _ from "lodash";
import pluralize from "pluralize";
import React, { useCallback, useState } from "react";
import { Button } from "reactstrap";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { Drawer } from "../components/Drawer";
import { LoadingIcon } from "../components/LoadingIcon";
import { api, redirectTo } from "../util/api";
import { EventMiniBus } from "../util/event-bus";
import { useMountEffect } from "../util/hooks";
import { trackEvent } from "../util/track";
import { PriceComparisonCard } from "./PriceComparisonCard";

export const PriceComparison = ({ caseId, payorShowPriceComparison }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [testPrices, setTestPrices] = useState([]);
  const [generating, setGenerating] = useState(false);

  const fetchPriceComparisons = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const { data } = await api.get(`/cases/${caseId}/price_comparison.json`);
      setTestPrices(_.take(data.resources, 3));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [caseId, setLoading, setError, setTestPrices]);

  const handleGenerateSeeqer = useCallback(
    async (testIds) => {
      setGenerating(true);
      try {
        const { data } = await api.post(`/cases/${caseId}/price_comparison.json`, { test_ids: [testIds] });
        const { id } = data;

        // If we succeed, we don't set generating back to false
        // because we want to redirect away before allowing an additional click
        redirectTo(`/cases/${id}/`, "Successfully generated new seeQer Case", "success");
      } catch (err) {
        setError(err);
        setGenerating(false);
      }
    },
    [caseId, setGenerating, setError]
  );

  const handleOpen = () => {
    setSidebarOpen(true);

    // Track the event that we actually reviewed this price comparison
    trackEvent("price_comparison_reviewed", caseId);

    if (testPrices.length === 0) {
      fetchPriceComparisons();
    }
  };

  useMountEffect(() => {
    EventMiniBus.on("comparison:open", () => {
      handleOpen();
    });
  });

  return (
    <>
      <div className="d-flex flex-row justify-content-end">
        <Button
          outline
          color="info"
          onClick={handleOpen}
          className="btn-block"
          title="Click here to show seeQer Test Price Comparison data."
        >
          Show Other Tests
        </Button>
      </div>

      <Drawer
        data-is-open={false}
        className="bg-light"
        id="price-comparison-drawer"
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      >
        {loading ? (
          <div className="flex h-100 w-100 d-flex align-items-center justify-content-center text-muted">
            <LoadingIcon className="me-2" /> Loading
          </div>
        ) : (
          <>
            <h4 className="mb-3">
              {payorShowPriceComparison ? (
                <strong>We found {pluralize("similar tests", testPrices.length, true)}</strong>
              ) : (
                <strong>Contact patient health plan for alternate tests / in-network options.</strong>
              )}
            </h4>

            <DetailedErrorAlert error={error} />

            <div>
              {testPrices.length > 0 && payorShowPriceComparison ? (
                _.map(testPrices, (p) => {
                  const testIds = p.tests.map((t) => t.id);
                  const key = testIds.join("-");

                  return (
                    <PriceComparisonCard
                      key={key}
                      disabled={generating}
                      onClick={() => handleGenerateSeeqer(testIds)}
                      className="mb-3"
                      price={p}
                    />
                  );
                })
              ) : (
                <>{payorShowPriceComparison && <span className="text-muted">We found no matching tests in our system.</span>}</>
              )}
            </div>
          </>
        )}
      </Drawer>
    </>
  );
};
