import React, { useState } from "react";
import { Field } from "react-final-form";
import { BoolInput } from "../../../../components/inputs/final_form/SelectInput";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../../components/LoadingButton";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";
import { loadSubstatuses, SubstatusesList } from "./StatusMappingForm";

export const clearStatusMutator = ([], state, { changeValue }) => {
  changeValue(state, "status", () => null);
};

export const EditStatusMappingForm = ({
  handleSubmit,
  submitting,
  initialCptCodeOptions = [],
  initialTestOptions = [],
  statusMapping,
  form,
}) => {
  const [substatusOptions, setSubstatusOptions] = useState(null);
  const clearSubstatus = () => {
    form.mutators.clearSubstatusMutator();
    statusMapping.substatus = null;
    setSubstatusOptions(null);
  };

  const updateSubstatusOptions = (selectedStatus, skipOptionsReset = false) => {
    if (!skipOptionsReset) {
      clearSubstatus();
    }

    const status = selectedStatus?.value;
    statusMapping.status = status;

    if (status) {
      loadSubstatuses(status).then((options) => setSubstatusOptions(options));
    } else {
      form.mutators.clearStatusMutator();
    }
  };

  if (!substatusOptions && statusMapping.status) {
    const statusFieldState = form.getFieldState("status");

    if (!statusFieldState?.dirty) {
      updateSubstatusOptions({ value: statusMapping.status }, true);
    }
  }

  return (
    <>
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/enums/prior_auth_statuses.json"
        label="Status"
        name="status"
        required
        labelKey="label"
        onUpdate={updateSubstatusOptions}
      />
      {statusMapping.caseType === "type_prior_auth" && substatusOptions && <SubstatusesList options={substatusOptions} />}
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/cpt_codes.json"
        label="CPT Code Ids"
        name="cptCodeIds"
        initialOptions={initialCptCodeOptions}
        isMulti
      />
      <Field
        component={AdministrateSelectInput}
        resourceUrl={`/admin/tests.json?test[direction]=asc&test[order]=commercial_name&lab=${statusMapping.lab?.id}`}
        label="Tests"
        name="testIds"
        labelKey="commercial_name"
        initialOptions={initialTestOptions}
        isMulti
        isDisabled={!statusMapping.lab}
      />
      <Field component={TextInput} label="Group ID" name="groupId" />
      <Field component={BoolInput} label="Request Submitted" name="requestSubmitted" includeBlank />
      <Field component={BoolInput} label="Discard Case" name="discardCase" />
      <Field component={TextInput} label="Automated Comment" name="automatedComment" />
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
