import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { useUpdatePermission } from "../../../utils";
import { EditStatusMappingForm, clearStatusMutator } from "./EditStatusMappingForm";
import { StatusMappingReadOnly } from "./StatusMappingReadOnly";
import { clearSubstatusMutator } from "./StatusMappingForm";

const codeToOption = (c) => ({ label: `CPT ${c.code}`, value: c.id });
const testToOption = (t) => ({ label: t.commercial_name, value: t.id });

export const EditStatusMappingModal = ({ statusMapping, onEdit, ...rest }) => {
  const [canUpdate] = useUpdatePermission("StatusMapping", statusMapping.id);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (params) => {
    setError(null);
    try {
      const res = await api.patch(`/admin/status_mappings/${statusMapping.id}`, { status_mapping: snakifyKeys(params) });

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const initialCptCodeOptions = useMemo(() => statusMapping.cptCodes.map(codeToOption), [statusMapping]);
  const initialTestOptions = useMemo(() => statusMapping.tests.map(testToOption), [statusMapping]);
  const mode = canUpdate ? "Edit" : "View";
  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        {mode}
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{mode} Status Mapping</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          {canUpdate ? (
            <Form
              onSubmit={handleEdit}
              initialValues={{
                status: statusMapping.status,
                substatus: statusMapping.substatus,
                requestSubmitted: statusMapping.requestSubmitted,
                discardCase: statusMapping.discardCase,
                cptCodeIds: statusMapping.cptCodes.map((c) => c.id),
                testIds: statusMapping.tests.map((t) => t.id),
                automatedComment: statusMapping.automatedComment,
                groupId: statusMapping.groupId,
              }}
              mutators={{ clearSubstatusMutator, clearStatusMutator }}
            >
              {(props) => (
                <EditStatusMappingForm
                  showPrefixField={false}
                  {...props}
                  initialCptCodeOptions={initialCptCodeOptions}
                  initialTestOptions={initialTestOptions}
                  statusMapping={statusMapping}
                />
              )}
            </Form>
          ) : (
            <StatusMappingReadOnly statusMapping={statusMapping} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
