import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ReportDeliveryForm } from "./ReportDeliveryForm";

export const EditReportDeliveryModal = ({ reportDelivery, labSettingId, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleUpdate = async (values) => {
    setError(null);

    try {
      const res = await api.patch(`/admin/report_delivery_configs/${reportDelivery.id}`, snakifyKeys(values));

      if (onUpdate) {
        onUpdate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Edit Report Delivery</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleUpdate} initialValues={{ labSettingId: labSettingId, ...reportDelivery }}>
            {(props) => <ReportDeliveryForm {...props} deliveryMethod={reportDelivery.deliveryMethod} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
