import { Link } from "react-router-dom";
import { Toolbar } from "../../../components/Toolbar";
import { formatDateTimeWithMonthYear, formatDateTimeWithTz } from "../../../util/formatters";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { Routes } from "../../Routes";
import { makeColumns, useAdministrateTable, usePermissionCheck } from "../../utils";
import { BillingReportsActionCell } from "../components/BillingReportsActionCell";
import { MonthlyBillingReportModal } from "../components/MonthlyBillingReportModal";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "Report ID",
  },
  {
    id: "lab_internal_id",
    accessor: "lab.labInternalId",
    Header: "Internal Lab ID",
    disableSortBy: true,
    disableFilters: true,
  },
  {
    id: "associated_id",
    accessor: "lab.labName",
    Header: "Lab",
    disableSortBy: true,
    Cell: ({ row, value }) => (
      <Link to={Routes.lab.url(row.original.lab.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/labs.json?lab[direction]=asc&lab[order]=lab_name&per_page=100"
        valueKey="id"
        labelKey="lab_name"
      />
    ),
  },
  {
    id: "filename",
    accessor: "filename",
    Header: "Filename",
  },
  {
    id: "account_id",
    accessor: "account",
    Header: "Last Generated By",
    Cell: ({ row, value }) => {
      return value.globalRole === "system" ? "System" : value.fullNameOrEmail;
    },
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/accounts.json" valueKey="id" labelKey="full_name_or_email" />,
  },
  {
    id: "eom",
    accessor: "documentTypeMetaData.eom",
    Header: "EOM Report",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ value }) => formatDateTimeWithMonthYear(value),
  },
  {
    id: "created_at",
    accessor: "createdAt",
    Header: "Report Generated",
    disableFilters: true,
    Cell: ({ value }) => formatDateTimeWithTz(value),
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: BillingReportsActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const BillingReportsIndexPage = () => {
  const columns = useColumns();
  const [canAccessBilling] = usePermissionCheck("admin_billing");

  const onCreate = () => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Generating your billing report.`);
    refresh();
  };

  const extraParams = { document_type: "case_billing" };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  return (
    canAccessBilling && (
      <SystemConfigLayout title="Billing Reports">
        <Toolbar className="align-items-center mb-3">
          <Toolbar className="ms-auto">
            <MonthlyBillingReportModal color="primary" className="me-2" onCreate={onCreate} />
          </Toolbar>
        </Toolbar>

        <AdministrateTable
          resourceUrl={"/admin/exports.json"}
          resourceName={"export"}
          onFilteredChange={setTableFilters}
          extraParams={extraParams}
          columns={columns}
          ref={tableRef}
        />
      </SystemConfigLayout>
    )
  );
};
