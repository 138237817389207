import { useMemo } from "react";
import { DetailsList } from "../../components/DetailsList";
import { useAdminEnumValue } from "../../utils";

const usePortalMessageDetails = (portalMessage) => {
  const { payorId, cannedComment, status, substatus, taskName, taskDuration, submitRightDirection, payor, message } = portalMessage;
  const statusVal = useAdminEnumValue("prior_auth_statuses", status);
  const substatusVal = useAdminEnumValue("prior_auth_substatuses", substatus, "value");

  return useMemo(
    () => [
      {
        label: "Submit Right Direction Name",
        value: submitRightDirection,
      },
      {
        label: "Portal Message",
        value: message,
      },
      {
        label: "Payor",
        value: payor?.name,
      },
      {
        label: "Canned Comment",
        value: cannedComment,
      },
      {
        label: "Status",
        value: statusVal,
      },
      {
        label: "Substatus",
        value: substatusVal,
      },
      {
        label: "Task Name",
        value: taskName,
      },
      {
        label: "Task Duration",
        value: taskDuration,
      },
    ],
    [payor, submitRightDirection, statusVal, substatusVal, message, cannedComment, taskDuration, taskName]
  );
};

export const PortalMessageReadOnly = ({ portalMessage }) => {
  const values = usePortalMessageDetails(portalMessage);
  return <DetailsList values={values} />;
};
