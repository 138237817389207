export const reactSelectColors = (hasError) => {
  const dangerRed = getComputedStyle(document.documentElement).getPropertyValue("--bs-danger");
  const borderRadius = getComputedStyle(document.documentElement).getPropertyValue("--bs-border-radius");
  const borderColor = getComputedStyle(document.documentElement).getPropertyValue("--bs-border-color");

  return {
    menuPortal: (provided) => ({
      ...provided,
    }),
    control: (provided, _state) => ({
      ...provided,
      borderRadius: borderRadius,
      borderColor: hasError ? dangerRed || "red" : borderColor,
      "&:hover,&:focus": {
        borderColor: hasError ? dangerRed || "red" : borderColor,
      },
    }),
    placeholder: (provided, _state) => ({
      ...provided,
      color: hasError ? dangerRed || "red" : provided.color,
    }),
  };
};
