import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { DroppablePage } from "./DroppablePage";
import { updateField, selectFieldsByPageNumber, selectField, selectFieldById, removeField, updateCurrentPage } from "../store";

const SCROLL_OFFSET = 300;

class PageListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.pages = [];

    this.onScroll = _.throttle(this.onScroll, 100);
  }

  onUnload = (event) => {
    if (this.props.dirty) {
      event.preventDefault();
      event.returnValue = "";
      return false;
    }

    return true;
  };

  onScroll = (_e) => {
    if (this.ownElement && this.pages.length > 0) {
      _.forEachRight(this.pages, (p, i) => {
        if (this.ownElement.scrollTop > p.offsetTop - SCROLL_OFFSET) {
          if (this.props.currentPage != i) {
            this.props.onUpdateCurrentPage(i);
          }

          return false;
        }
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { selectedField } = this.props;
    const selectedFieldId = _.get(selectedField, "id");
    const previousSelectedId = _.get(prevProps, "selectedField.id");

    // Selected a new field, need to scroll it into view.
    if (selectedField && selectedFieldId !== previousSelectedId) {
      const pageIdx = selectedField.position.page;
      const pageElement = this.pages[pageIdx];

      this.ownElement.scrollTo({
        top: pageElement.offsetTop - 150,
        behavior: "smooth",
      });
    }
  };

  componentDidMount = () => {
    window.addEventListener("beforeunload", this.onUnload);
    this.ownElement.addEventListener("scroll", this.onScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this.onUnload);
    this.ownElement.removeEventListener("scroll", this.onScroll);
  };

  render() {
    const { pages, selectedField, onUpdateField, onRemoveField, onSelectField, onDeselectAll } = this.props;

    const displayedImages = _.map(pages, (page, idx) => {
      return (
        <div
          key={idx}
          ref={(page) => {
            this.pages[idx] = page;
          }}
        >
          <DroppablePage
            onUpdateField={onUpdateField}
            onSelectField={onSelectField}
            onRemoveField={onRemoveField}
            selectedField={selectedField}
            pageIdx={idx}
            fields={page.fields}
            imgSrc={page.image}
          />
        </div>
      );
    });

    return (
      <div className={"fde__page_list"} onClick={onDeselectAll} ref={(ownElement) => (this.ownElement = ownElement)}>
        {" "}
        {displayedImages}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const pages = _.map(state.pageImages, (image, i) => {
    const fields = selectFieldsByPageNumber(state, i);
    return { image, fields };
  });

  return {
    selectedField: selectFieldById(state, state.selectedField),
    pages: pages || [],
    dirty: state.dirty,
    currentPage: state.currentPage,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateCurrentPage: (newPage) => {
    dispatch(updateCurrentPage({ newPage }));
  },
  onUpdateField: (fieldId, data) => {
    dispatch(updateField({ fieldId, data }));
  },
  onSelectField: (fieldId) => {
    dispatch(selectField({ fieldId }));
  },
  onDeselectAll: () => {
    dispatch(selectField({ fieldId: null }));
  },
  onRemoveField: (fieldId) => {
    dispatch(removeField({ fieldId }));
  },
});

export const PageList = connect(mapStateToProps, mapDispatchToProps)(PageListComponent);
