import { createRoot } from "react-dom/client";
import { FillableWorkflowDocumentsGrid } from "../../fillable_workflow_documents_grid/index";

export const initFillableWorkflowDocumentsGrid = () => {
  const gridContainer = document.getElementById("fillable_workflow_docs_grid");

  if (gridContainer) {
    const root = createRoot(gridContainer);
    const { apiUrl, defaultPublished, formType } = gridContainer.dataset;

    root.render(<FillableWorkflowDocumentsGrid apiUrl={apiUrl} defaultPublished={defaultPublished} formType={formType} />);
  }
};
