import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CountCell } from "../../components/grid/cells/CountCell";
import { DateCell } from "../../components/grid/cells/DateCell";
import { LinkCell } from "../../components/grid/cells/LinkCell";
import { BoolFilter } from "../../components/inputs/BoolFilter";
import { DateFilter } from "../../components/inputs/DateFilter";
import { Switch } from "../../components/inputs/Switch";
import { Toolbar } from "../../components/Toolbar";
import { deepCompact } from "../../util/helpers";
import { AdministrateBackgroundExportButton } from "../components/AdministrateBackgroundExportButton";
import { AdministrateEnumFilter } from "../components/AdministrateEnumFilter";
import { AdministrateSelect } from "../components/AdministrateSelect";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { UpdateReviewCadenceModal } from "../components/UpdateReviewCadenceModal";
import { Routes } from "../Routes";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission, useUpdatePermission } from "../utils";
import { CreatePolicyModal } from "./components/CreatePolicyModal";
import { ImportPoliciesModal } from "./components/ImportPoliciesModal";
import { PolicyActionCell } from "./components/PolicyActionCell";
import { ReviewStatusCell } from "./components/ReviewStatusCell";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    maxWidth: 80,
  },
  {
    id: "name",
    accessor: "name",
    Header: "Name",
    Cell: ({ row, value }) => <Link to={Routes.policy.url(row.original.id)}>{value}</Link>,
  },
  {
    id: "payors.id",
    accessor: "payorCount",
    Header: "Payors",
    Cell: ({ value }) => <CountCell count={value} label="Payors" />,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="id" searchParam="name" />,
    maxWidth: 100,
  },
  {
    id: "reference_link",
    accessor: "referenceLink",
    Header: "URL",
    Cell: LinkCell,
  },
  {
    id: "cpt_codes",
    accessor: "cptCount",
    Header: "CPT Codes",
    Cell: ({ value }) => <CountCell count={value} label="CPT Codes" />,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" isMulti valueKey="code" labelKey="code" />,
    maxWidth: 120,
  },
  {
    id: "effective_date",
    accessor: "effectiveDate",
    Header: "Effective Date",
    Filter: (props) => <DateFilter {...props} />,
    maxWidth: 120,
  },
  {
    id: "policy_reviews.reviewed_on",
    accessor: "policyLastReviewedOn",
    Header: "Last Reviewed Date",
    Cell: DateCell,
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    id: "needs_policy_review",
    accessor: "needsPolicyReview",
    Header: "Review Status",
    Cell: ReviewStatusCell,
    Filter: (props) => <BoolFilter {...props} yesLabel="Needs Review" noLabel="Reviewed" />,
    disableSortBy: true,
    maxWidth: 100,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: PolicyActionCell,
    disableFilters: true,
    disableSortBy: true,
    width: 280,
  },
]);

export const PoliciesIndexPage = () => {
  const columns = useColumns();
  const [selectedPayorGroupId, setSelectedPayorGroupId] = useState(null);
  const [showInactivePolicies, setShowInactivePolicies] = useState(false);

  const [canUpdate] = useUpdatePermission("Policy");
  const [canUpdateCadence] = useUpdatePermission("ReviewCadence");
  const [canCreate] = useCreatePermission("Policy");

  const onCreate = (newPolicy) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created new policy ${newPolicy.name}`);
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onUpdateCadence = () => {
    const { refresh, setMessage } = tableRef.current;
    setMessage("Review cadence has been updated.", "success");
    refresh();
  };

  const onExportSuccess = () => {
    const { setMessage } = tableRef.current;
    setMessage("Export started. You will receive an email when your file is ready", "success");
  };

  const onExportError = (err) => {
    const { setError } = tableRef.current;
    setError(err);
  };

  const extraParams = useMemo(
    () => deepCompact({ payorGroupId: selectedPayorGroupId, showInactivePolicies: showInactivePolicies }),
    [selectedPayorGroupId, showInactivePolicies]
  );

  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  return (
    <SystemConfigLayout title="Policies">
      <Toolbar className="align-items-center mb-3">
        <Toolbar>
          <div style={{ width: 300 }}>
            <AdministrateSelect
              placeholder="Filter by Payor Group..."
              resourceUrl="/admin/payor_groups.json"
              onChange={setSelectedPayorGroupId}
              value={selectedPayorGroupId}
              className="me-2"
            />
          </div>

          {canUpdate && canUpdateCadence && (
            <UpdateReviewCadenceModal resourceType="policies" reviewType="policy" outline className="ms-2" onUpdate={onUpdateCadence} />
          )}
        </Toolbar>

        <Toolbar className="ms-auto">
          <Switch
            id="show-inactive-toggle"
            label="Include Inactive Policies"
            checked={showInactivePolicies}
            className="me-3"
            onChange={() => {
              setShowInactivePolicies(!showInactivePolicies);
            }}
          />
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/policies/export"
            filters={allFilters}
            onError={onExportError}
            onSuccess={onExportSuccess}
          />
          {canCreate && <ImportPoliciesModal outline className="me-2" onImport={onImport} />}
          {canCreate && <CreatePolicyModal color="primary" onCreate={onCreate} />}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/policies.json"}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
        resourceName={"policy"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
