import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { TabbedRoutes } from "../components/TabbedRoutes";
import { Routes } from "../Routes";
import { useAdminResource } from "../utils";
import { PayorsGeneralTab } from "./tabs/PayorsGeneralTab";
import { PayorsLabNetworkStatusTab } from "./tabs/PayorsLabNetworkStatusTab";
import { PayorsPlanTypeNetworkStatusTab } from "./tabs/PayorsPlanTypeNetworkStatusTab";
import { PayorsPoliciesTab } from "./tabs/PayorsPoliciesTab";
import { PayorsContactsTab } from "./tabs/PayorsContactsTab";
import { PayorsPortalSorterTab } from "./tabs/PayorsPortalSorterTab";
import { PayorsPortalManagementTab } from "./tabs/PayorsPortalManagementTab";
import { PayorsNonCoveredCodesTab } from "./tabs/PayorsNonCoveredCodesTab";
import { PayorsAutoApprovalsTab } from "./tabs/PayorsAutoApprovalsTab";
import { PayorNotesTab } from "./tabs/PayorNotesTab";

const useBreadcrumbs = (pageTitle, payorId) =>
  useMemo(
    () => [
      { url: Routes.payors.url(), label: Routes.payors.label },
      { url: Routes.payor.url(payorId), label: pageTitle, active: true },
    ],
    [pageTitle, payorId]
  );

const usePayor = (payorId) => useAdminResource(`/admin/payors/${payorId}.json`);

const TABS = [
  {
    path: "general",
    label: "General",
    Component: PayorsGeneralTab,
  },
  {
    label: "Network Status",
    items: [
      {
        path: "payor_network_status",
        label: "Payor Network Status",
        Component: PayorsLabNetworkStatusTab,
        resource: "PayorLabSetting",
      },
      {
        path: "plan_type_network_status",
        label: "Plan Type Network Status",
        Component: PayorsPlanTypeNetworkStatusTab,
        resource: "PlanTypeNetworkSetting",
      },
    ],
  },
  {
    path: "contacts",
    label: "Contacts",
    Component: PayorsContactsTab,
  },
  {
    path: "policies",
    label: "Policies",
    Component: PayorsPoliciesTab,
  },
  {
    path: "portals",
    label: "Portals",
    Component: PayorsPortalManagementTab,
  },
  {
    path: "sort_portals",
    label: "Sort Portals",
    Component: PayorsPortalSorterTab,
  },
  {
    path: "non_covered_codes",
    label: "Non-Covered CPT Codes",
    Component: PayorsNonCoveredCodesTab,
  },
  {
    path: "payor_auto_approvals",
    label: "Payor Auto Approvals",
    Component: PayorsAutoApprovalsTab,
  },
  {
    path: "payor_notes",
    label: "Payor Notes",
    Component: PayorNotesTab,
  },
];

export const PayorsShowPage = () => {
  const { payorId } = useParams();
  const { resource: payor, error, isLoading, isInitialLoad, fetch } = usePayor(payorId);
  const pageTitle = payor?.name || `Payor #${payorId}`;
  const breadcrumbs = useBreadcrumbs(pageTitle, payorId);

  return (
    <SystemConfigLayout title={pageTitle} showBreadcrumbs crumbs={breadcrumbs} loading={isLoading && !payor}>
      {error && <Alert color="danger">{error}</Alert>}

      <TabbedRoutes tabs={TABS} defaultTab={"general"} payor={payor} payorId={payorId} refresh={fetch} loading={isLoading} />
    </SystemConfigLayout>
  );
};
