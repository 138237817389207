import React from "react";
import { Field } from "react-final-form";
import { DropzoneInput } from "../../../components/inputs/final_form/DropzoneInput";
import { atLeastOneFieldRequired } from "../../../util/validators";
import { LabSftpSettingModal } from "./LabSftpSettingsModal";

export const AddLabSftpSettingModal = ({ labId, onAdd }) => {
  return (
    <LabSftpSettingModal
      labId={labId}
      onAction={onAdd}
      sftp={null}
      toggleButtonLabel="Add SFTP Server Configuration"
      actionTitle="Add"
      keyfileInput={
        <Field
          required
          validate={atLeastOneFieldRequired("password")}
          component={DropzoneInput}
          accept={null}
          hint="Drop Keyfile"
          label="Keyfile"
          name="keyfile"
        />
      }
    />
  );
};
