import React, { useState } from "react";
import { Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { useUpdatePermission } from "../../../utils";
import { FollowupTaskConfigForm } from "./FollowupTaskConfigForm";
import { FollowupTaskConfigReadOnly } from "./FollowupTaskConfigReadOnly";

export const EditFollowupTaskConfigModal = ({ followupTaskConfig, onEdit, ...rest }) => {
  const [canUpdate] = useUpdatePermission("FollowupTaskConfig", followupTaskConfig.id);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (params) => {
    setError(null);
    try {
      await api.patch(`/admin/followup_task_configs/${followupTaskConfig.id}`, snakifyKeys(params));

      if (onEdit) {
        onEdit();
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const mode = canUpdate ? "Edit" : "View";

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        {mode}
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{mode} Follow-up Task Config</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          {canUpdate ? (
            <Form onSubmit={handleEdit} initialValues={followupTaskConfig}>
              {(props) => <FollowupTaskConfigForm {...props} />}
            </Form>
          ) : (
            <FollowupTaskConfigReadOnly followupTaskConfig={followupTaskConfig} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
