import React, { useMemo } from "react";
import { Field, FormSpy } from "react-final-form";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { required } from "../../../../util/validators";
import { LoadingButton } from "../../../../components/LoadingButton";
import { Switch } from "../../../../components/inputs/final_form/Switch";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";
import { isBlank } from "../../../../util/helpers";

const labToOption = (lab) => (lab ? [{ label: lab.labName, value: lab.id }] : []);
const payorToOption = (payor) => (payor ? [{ label: payor.name, value: payor.id }] : []);
const testToOption = (test) => (test ? [{ label: test.commercialName, value: test.id }] : []);

export const PreprocessingCategoryForm = ({ handleSubmit, submitting, initialValues, values }) => {
  const initialLabOptions = useMemo(() => labToOption(initialValues.lab), [initialValues]);
  const initialPayorOptions = useMemo(() => payorToOption(initialValues.payor), [initialValues]);
  const initialTestOptions = useMemo(() => testToOption(initialValues.test), [initialValues]);

  return (
    <>
      <Field component={TextInput} label="Name" name="name" required validate={required} />

      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/labs.json?scope=with_servicing_provider"
        searchParam="lab_name"
        label="Lab"
        kept={true}
        name="labId"
        required={isBlank(values?.payorId)}
        initialOptions={initialLabOptions}
        initialValue={initialValues.lab?.id}
      />

      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/payors.json"
        label="Payor"
        name="payorId"
        required={isBlank(values?.labId)}
        initialOptions={initialPayorOptions}
        initialValue={initialValues.payor?.id}
        searchParam="name"
      />

      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          return values.labId ? (
            <>
              <Field
                component={AdministrateSelectInput}
                resourceUrl={`/admin/tests.json?test[direction]=asc&test[order]=commercial_name&lab=${values.labId}&archived=false`}
                label="Test"
                name="testId"
                initialOptions={initialTestOptions}
                initialValue={initialValues.test?.id}
              />
            </>
          ) : (
            <>
              <Field
                component={AdministrateSelectInput}
                label="Test"
                resourceUrl={"/admin/tests.json?archived=false"}
                name="testId"
                initialOptions={initialTestOptions}
                initialValue={initialValues.test?.id}
                isDisabled={true}
                key="test-disabled"
              />
            </>
          );
        }}
      </FormSpy>

      <Field
        className="mb-3"
        component={Switch}
        type="checkbox"
        defaultValue={true}
        required
        validate={required}
        label="Active"
        name="active"
      />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
