import React, { useState } from "react";
import { Row } from "reactstrap";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi, extractErrorMessage } from "../../../util/api";

export const TestConnectionButton = ({ onError, onSuccess, connection }) => {
  const [testing, setTesting] = useState(false);

  const handleTesting = async () => {
    setTesting(true);

    try {
      await adminApi.post(`/admin/labs/test_connection`, connection); // Need form values
      onSuccess("Connection Successful");
      setTesting(false);
    } catch (err) {
      onError(extractErrorMessage(err));
    } finally {
      setTesting(false);
    }
  };

  return (
    <Row>
      <LoadingButton loading={testing} color="link" className="text-danger" onClick={handleTesting}>
        Test Connection
      </LoadingButton>
    </Row>
  );
};
