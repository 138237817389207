import React, { useMemo, useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Alert } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { Fieldset } from "../../../components/inputs/final_form/Fieldset";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { BlankableField } from "../../../components/inputs/final_form/BlankableField";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { camelizeKeys, snakifyKeys, isBlank } from "../../../util/helpers";
import { required, naiveUrl, numericInRange } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

const rekeyValues = (values) => {
  const newPayload = _.cloneDeep(values);
  newPayload.payorSettingAttributes = values.payorSetting;
  return _.omit(newPayload, "payorSetting");
};

const billingTypes = [
  { label: "Simple", value: "simple" },
  { label: "Complex", value: "complex" },
  { label: "Reduced Rate", value: "reduced_rate" },
];

export const PayorsGeneralTab = ({ payor, payorId, refresh }) => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [canUpdate] = useUpdatePermission("Payor", payorId);

  const handleUpdate = async (values) => {
    setError(null);
    setMessage(null);

    try {
      await api.put(`/admin/payors/${payorId}`, { payor: snakifyKeys(rekeyValues(values)) });
      setMessage("Updated Payor");
      refresh();
    } catch (err) {
      setError(err);
    }
  };

  const initialValues = useMemo(() => camelizeKeys(payor), [payor]);
  const readOnly = !canUpdate;

  return (
    <>
      <DetailedErrorAlert error={error} />
      {message && <Alert color="info">{message}</Alert>}

      <Form onSubmit={handleUpdate} initialValues={initialValues}>
        {({ values, handleSubmit, submitting, pristine, form }) => {
          return (
            <>
              <Field component={TextInput} required validate={required} label="ID" name="id" readOnly />
              <Field component={TextInput} required validate={required} label="Public ID" name="publicId" readOnly />
              <Field component={TextInput} required validate={required} label="Payor Name" name="name" readOnly={readOnly} />
              <Fieldset legend="Settings">
                <Field type="checkbox" component={Switch} required label="Supports Paper?" name="paper" id="paper" readOnly={readOnly} />
                {form.getState().values.paper && (
                  <Field
                    type="checkbox"
                    component={Switch}
                    label="Paper Hard Followup?"
                    name="paperHardFollowup"
                    id="paperHardFollowup"
                    readOnly={readOnly}
                  />
                )}
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="No Retro Auths?"
                  name="noRetroAuths"
                  id="noRetroAuths"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Ribbon Payor Enabled?"
                  name="ribbonPayorEnabled"
                  id="ribbonPayorEnabled"
                />
                <Field type="checkbox" component={Switch} required label="Supports Phone?" name="phone" id="phone" readOnly={readOnly} />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Contracted?"
                  name="contracted"
                  id="contracted"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="No MOOP Provided by Payor"
                  name="noMoopProvided"
                  id="noMoopProvided"
                />
                <Field component={SelectInput} label="Billing Type" name="billingType" options={billingTypes} readOnly={readOnly} />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Enrollment Required?"
                  name="payorSetting.enrollmentRequired"
                  id="payorSetting.enrollmentRequired"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Configured With PA Requirements?"
                  name="payorSetting.configuredWithPaRequirements"
                  id="payorSetting.configuredWithPaRequirements"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Configured With PA Requirements - J Codes?"
                  name="payorSetting.configuredWithPaRequirementsJCodes"
                  id="payorSetting.configuredWithPaRequirementsJCodes"
                  readOnly={readOnly}
                />
                <BlankableField
                  component={TextInput}
                  maxLength={500}
                  validate={(v) => (!isBlank(v) ? naiveUrl(v) : undefined)}
                  label="PA Requirement URL"
                  name="payorSetting.paRequirementUrl"
                  readOnly={readOnly}
                />
                <Field
                  component={TextInput}
                  label="Number of day(s) for timely filing"
                  validate={numericInRange(1, 90)}
                  name="payorSetting.numberOfDaysForTimelyFiling"
                  id="payorSetting.numberOfDaysForTimelyFiling"
                  type="number"
                  min="1"
                  max="90"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Auto Fax PA"
                  name="payorSetting.autoFax"
                  id="payorSetting.autoFax"
                  readOnly={readOnly}
                />

                {values.payorSetting.autoFax && (
                  <Fieldset legend="Auto Fax Settings">
                    <Field
                      component={AdministrateSelectInput}
                      resourceUrl={`/admin/payor_contacts.json?payor_id=${payorId}`}
                      label="Payor Contact"
                      name="payorSetting.autoFaxPayorContactId"
                      readOnly={readOnly}
                    />
                    <Field
                      component={AdministrateSelectInput}
                      resourceUrl={`/admin/fillable_insurance_documents.json?payors[id]=${payorId}`}
                      label="PA Form"
                      name="payorSetting.autoFaxFillableInsuranceDocumentId"
                      readOnly={readOnly}
                    />
                  </Fieldset>
                )}

                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Edith Enabled"
                  name="payorSetting.edithEnabled"
                  id="payorSetting.edithEnabled"
                  readOnly={readOnly}
                />

                {values.payorSetting.edithEnabled && (
                  <Fieldset legend="Edith Enabled Settings">
                    <Field
                      component={AdministrateSelectInput}
                      resourceUrl={`/admin/payor_contacts.json?payor_id=${payorId}`}
                      label="Payor Contact"
                      name="payorSetting.edithPrimaryContactId"
                      labelKey="display_name_with_phone_number"
                      readOnly={readOnly}
                    />
                  </Fieldset>
                )}

                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Do not allow Reopen"
                  name="payorSetting.doNotAllowReopen"
                  id="payorSetting.doNotAllowReopen"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Payor Network Status By Plan Type"
                  name="payorSetting.payorNetworkStatusByPlanType"
                  id="payorSetting.payorNetworkStatusByPlanType"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Prioritize Medical Benefits"
                  name="payorSetting.prioritizeMedicalBenefits"
                  id="payorSetting.prioritizeMedicalBenefits"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Lab Pricing Allowed"
                  name="payorSetting.labPricingAllowed"
                  id="payorSetting.labPricingAllowed"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Show Price Comparison"
                  name="payorSetting.showPriceComparison"
                  id="payorSetting.showPriceComparison"
                  readOnly={readOnly}
                />
              </Fieldset>

              <Fieldset legend="Integrations">
                <BlankableField component={TextInput} label="Availity ID" name="availityId" readOnly={readOnly} />
                <BlankableField component={TextInput} label="PVerify ID" name="pverifyPayorId" readOnly={readOnly} />
                <BlankableField component={TextInput} label="Tevix ID" name="tevixmdPayorId" readOnly={readOnly} />
                <BlankableField component={TextInput} label="TransUnion ID" name="transunionPayorId" readOnly={readOnly} />
                <BlankableField component={TextInput} label="Change Healthcare ID" name="changeHealthcarePayorId" readOnly={readOnly} />
              </Fieldset>

              <Fieldset legend="Payor Groups">
                <ul>
                  {initialValues.payorGroups.map((payorGroup) => (
                    <li key={payorGroup.id}>
                      <a href={`/admin/system/payor_groups/${payorGroup.id}`}>{payorGroup.name}</a>
                    </li>
                  ))}
                </ul>
              </Fieldset>

              <Fieldset legend="Payor Tips">
                <BlankableField component={TextInput} maxLength={500} type="textarea" rows="4" name="payorTips" readOnly={readOnly} />
              </Fieldset>

              <BlankableField component={TextInput} label="Payor SOP" name="sop" type="text" readOnly={readOnly} />

              {canUpdate && (
                <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine}>
                  Save
                </LoadingButton>
              )}

              <History className="ms-4" entityName="Payor" subjectId={payorId} />
            </>
          );
        }}
      </Form>
    </>
  );
};
