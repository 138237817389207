import _ from "lodash";
import pluralize from "pluralize";
import React, { useMemo, useState } from "react";
import { BoolFilter } from "../../../../components/inputs/BoolFilter";
import { EnumFilter } from "../../../../components/inputs/EnumFilter";
import { TokenCell } from "../../../../components/grid/cells/TokenCell";
import { BoolCell } from "../../../../components/grid/cells/BoolCell";
import { Toolbar } from "../../../../components/Toolbar";
import { AdministrateEnumFilter } from "../../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../../components/AdministrateTable";
import {
  DESTROY,
  makeColumns,
  UPDATE,
  useAdministrateTable,
  useCreatePermission,
  useDestroyPermission,
  useUpdatePermission,
} from "../../../utils";
import { extractNameFromDataURL } from "../../../../util/helpers";
import { CreatePaRequirementModal } from "../../components/CreatePaRequirementModal";
import { GcRequirementActionCell } from "../../components/GcRequirementActionCell";
import { ImportPayorCptCodeSettingModal } from "../../components/ImportPayorCptCodesSettingsModal";
import { InNetworkPaRequiredActionCell } from "../../components/InNetworkPaRequiredActionCell";
import { OutOfNetworkPaRequiredActionCell } from "../../components/OutOfNetworkPaRequiredActionCell";
import { PaRequirementsActionCell } from "../../components/PaRequirementsActionCell";
import { History } from "../../../../system_config/tools/sandbox/release/History";

export const GC_REQUIREMENT_OPTIONS = [
  { value: "physician", label: "Physician" },
  { value: "certified_genetic_counselor", label: "Certified Genetic Counselor" },
];

const defaultColumns = [
  {
    id: "payor.id",
    accessor: "payor.name",
    Header: "Payor",
    disableSortBy: true,
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" isMulti valueKey="id" labelKey="name" searchParam="name" />
    ),
  },
  {
    id: "cpt_code",
    accessor: "cptCode.code",
    Header: "CPT Code",
    disableSortBy: true,
    Cell: TokenCell,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" valueKey="code" labelKey="code" />,
  },
  {
    id: "requires_pa_in_network",
    accessor: "requiresPaInNetwork",
    Header: "In Network PA Required",
    disableSortBy: true,
    className: "action-cell",
    Cell: (props) => {
      const [canUpdate] = useUpdatePermission("PayorCptCodesSetting", props.row.original.id);

      return canUpdate ? <InNetworkPaRequiredActionCell {...props} /> : <BoolCell {...props} />;
    },
    Filter: BoolFilter,
  },
  {
    id: "requires_pa_out_of_network",
    accessor: "requiresPaOutOfNetwork",
    Header: "Out of Network PA Required",
    disableSortBy: true,
    className: "action-cell",
    Cell: (props) => {
      const [canUpdate] = useUpdatePermission("PayorCptCodesSetting", props.row.original.id);

      return canUpdate ? <OutOfNetworkPaRequiredActionCell {...props} /> : <BoolCell {...props} />;
    },
    Filter: BoolFilter,
  },
  {
    id: "gcRequirement",
    accessor: "gcRequirement",
    Header: "Genetic Counseling Requirement",
    disableSortBy: true,
    className: "action-cell",
    Cell: (props) => {
      const [canUpdate] = useUpdatePermission("PayorCptCodesSetting", props.row.original.id);

      return canUpdate ? <GcRequirementActionCell {...props} /> : <>{props.value}</>;
    },
    Filter: (props) => <EnumFilter {...props} options={[{ value: "__null__", label: "None" }, ..._.clone(GC_REQUIREMENT_OPTIONS)]} />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: (props) => {
      const [canDelete] = useDestroyPermission("PayorCptCodesSetting", props.row.original.id);
      return canDelete ? <PaRequirementsActionCell {...props} /> : null;
    },
    disableFilters: true,
    disableSortBy: true,
  },
];

const labColumn = {
  id: "lab.id",
  accessor: "lab.labName",
  Header: "Lab",
  disableSortBy: true,
  Filter: (props) => (
    <AdministrateEnumFilter {...props} resourceUrl="/admin/labs.json" isMulti valueKey="id" labelKey="lab_name" searchParam="lab_name" />
  ),
};

const payorGroupColumn = {
  id: "groupid",
  accessor: "groupid",
  Header: "Group ID",
  disableSortBy: true,
  filter: "text",
};

const hideExtraColumn = (condition, columns) => {
  // hide the second table column if the condition is truth
  if (condition) {
    columns.splice(1, 1);
  }
};

const tableColumns = ([labSpecific, groupSpecific]) => {
  let columns = defaultColumns;
  let shouldHideLab = labSpecific === false && columns.includes(labColumn);
  let shoudlHideGroup = groupSpecific === false && columns.includes(payorGroupColumn);

  if (labSpecific === true && !columns.includes(labColumn)) {
    hideExtraColumn(shoudlHideGroup, columns);
    columns.splice(1, 0, labColumn);
  } else if (groupSpecific === true && !columns.includes(payorGroupColumn)) {
    hideExtraColumn(shouldHideLab, columns);
    columns.splice(1, 0, payorGroupColumn);
  } else {
    let condition = shouldHideLab || shoudlHideGroup;
    hideExtraColumn(condition, columns);
  }

  return columns;
};

const useColumns = makeColumns(tableColumns);

export const PaRequirementsList = ({ labSpecific = false, groupSpecific = false }) => {
  const [canCreate] = useCreatePermission("PayorCptCodesSetting");
  const columns = useColumns([labSpecific, groupSpecific]);

  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  const onImport = ({ failures, successes }) => {
    const { refresh, setMessage } = tableRef.current;

    const details = [];

    failures.forEach((p) => {
      const filename = extractNameFromDataURL(p.reason?.config?.data);
      details.push(`${filename} - ${p.reason?.response?.data?.errors.join(",")}`);
    });

    successes.forEach((p) => {
      if (p.value?.data?.meta?.error_details && p.value?.data?.meta?.error_details.length) {
        const filename = extractNameFromDataURL(p.value?.config?.data);
        details.push(`${filename} - ${p.value?.data?.meta?.error_details.join(",")}`);
      }
    });

    const message = `Imported: ${pluralize("file", successes.length, true)} succeeded, ${pluralize("file", failures.length, true)} failed.`;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onCreate = (newSetting) => {
    const { refresh, setMessage } = tableRef.current;
    if (_.isEmpty(newSetting)) {
      setMessage(`Started creating new settings for all CPT Codes.`);
    } else {
      setMessage(`Created new setting for Payor ${newSetting?.payor?.name} and CPT Code ${newSetting?.cptCode?.code}.`);
    }
    refresh();
  };

  const tableResourceUrl = () => {
    let resourceUrl = "/admin/payor_cpt_codes_settings.json";

    if (labSpecific === true) {
      resourceUrl += "?lab_specific=true";
    } else if (groupSpecific === true) {
      resourceUrl += "?group_specific=true";
    }

    return resourceUrl;
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl={`/admin/payor_cpt_codes_settings/export?lab_specific=${!!labSpecific}&group_specific=${!!groupSpecific}`}
            filters={allFilters}
            ref={tableRef}
          />

          <History entityName={"PayorCptCodesSetting"} />

          {canCreate && (
            <>
              <ImportPayorCptCodeSettingModal outline className="mx-2" onImport={onImport} />
              <CreatePaRequirementModal onCreate={onCreate} labSpecific={labSpecific} groupSpecific={groupSpecific} color="primary" />
            </>
          )}
        </Toolbar>
      </Toolbar>
      <AdministrateTable
        resourceUrl={tableResourceUrl()}
        resourceName={"payor_cpt_codes_settings"}
        columns={columns}
        ref={tableRef}
        permissions={[UPDATE, DESTROY]}
        onFilteredChange={setTableFilters}
      />
    </>
  );
};
