import React, { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { ListGroup } from "reactstrap";
import { arrayMove } from "../../util/helpers";
import { SortingRow } from "./SortingRow";

export const Sorter = ({ onChange, Label, Description, initialItems = [], disabled = false, idAttribute = "id" }) => {
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const handlePositionChanged = (newIndex, item) => {
    onChange({
      id: item[idAttribute],
      newIndex: newIndex,
    });
  };

  const handleManualIndexSet = (to, from) => {
    const item = items[from];
    const clampedTo = _.clamp(to, 0, items.length - 1);
    const clampedFrom = _.clamp(from, 0, items.length - 1);

    setItems(arrayMove(items, clampedFrom, clampedTo));
    handlePositionChanged(to, item);
  };

  return (
    <ListGroup>
      <ReactSortable
        list={items}
        setList={setItems}
        onEnd={(e) => handlePositionChanged(e.newIndex, items[e.newIndex])}
        disabled={disabled}
      >
        {items.map((m, idx) => (
          <SortingRow
            label={<Label item={m} />}
            description={Description && <Description item={m} />}
            key={`${m.id}_${idx}`}
            idx={idx}
            disabled={disabled}
            onIndexUpdate={handleManualIndexSet}
          />
        ))}
      </ReactSortable>
    </ListGroup>
  );
};
