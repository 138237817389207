import { api } from "../../util/api";

export const initAdminLab = () => {
  let isLoading = false;

  const $labNpi = $("#lab_npi");
  const $labNpiName = $("#lab_npi_registry_name");
  const $help = $labNpiName.siblings(".invalid-feedback");
  const $updateNpiName = $("#update-npi-name");

  function getNpi() {
    return $labNpi.val().replace(/[^0-9]/g, "");
  }

  function setButtonEnabled() {
    $updateNpiName.prop("disabled", isLoading || getNpi().length !== 10);
  }
  setButtonEnabled();

  function clearError() {
    $labNpiName.toggleClass("is-invalid", false);
    $help.toggle(false);
    $help.text("");
  }

  function setError(str) {
    $labNpiName.toggleClass("is-invalid", true);
    $help.toggle(true);
    $help.text(str);
  }

  function setLoading() {
    isLoading = true;
    setButtonEnabled();
  }

  function clearLoading() {
    isLoading = false;
    setButtonEnabled();
  }

  $labNpi.on("input", setButtonEnabled);
  $labNpi.on("input", clearError);

  $updateNpiName.on("click", async () => {
    clearError();

    try {
      setLoading();
      const response = await api.get(`/clinics/npi?number=${getNpi()}`);
      if (response.status !== 200) {
        setError("Request for NPI Number Failed");
      }
      const officialName = response.data.results.find((x) => x.enumeration_type === "NPI-2")?.basic?.organization_name;
      if (officialName != null) {
        $labNpiName.val(officialName);
      } else {
        setError("Did not find an Lab name for NPI Number " + getNpi());
      }
    } catch (err) {
      setError("Error getting name for NPI Number " + getNpi());
    } finally {
      clearLoading();
    }
  });
};
