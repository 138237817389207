import _ from "lodash";
import { Link } from "react-router-dom";
import { BoolFilter } from "../../../../components/inputs/BoolFilter";
import React, { useMemo, useState } from "react";
import { Toolbar } from "../../../../components/Toolbar";
import { deepCompact } from "../../../../util/helpers";
import { DateFilter } from "../../../../components/inputs/DateFilter";
import { DateCell } from "../../../../components/grid/cells/DateCell";
import { ReviewStatusCell } from "../../../policies/components/ReviewStatusCell";
import { AdministrateEnumFilter } from "../../../components/AdministrateEnumFilter";
import { AdministrateSelect } from "../../../components/AdministrateSelect";
import { makeColumns, UPDATE, useAdministrateTable, useUpdatePermission } from "../../../utils";
import { UpdateReviewCadenceModal } from "../../../components/UpdateReviewCadenceModal";
import { AdministrateTable } from "../../../components/AdministrateTable";
import { PaRequirementActionCell } from "../components/PaRequirementActionCell";
import { Alert } from "reactstrap";
import { Routes } from "../../../Routes";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "name",
    Header: "Verified Payor",
    disableSortBy: false,
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" isMulti valueKey="id" labelKey="name" searchParam="name" />
    ),
    Cell: ({ row, value }) => (
      <Link to={Routes.payor.url(row.original.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
  },
  {
    id: "reviewed_on",
    accessor: "lastReviewedAt",
    Header: "Last Reviewed Date",
    Cell: (props) =>
      !props.row.original.hasCptCodeSettings ? (
        "No CPT Requirements for this Payor"
      ) : props.value == null ? (
        "Never Reviewed"
      ) : (
        <DateCell {...props} />
      ),
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    id: "needs_requirement_review",
    accessor: "needsReview",
    Header: "Review Status",
    Cell: (props) => (!props.row.original.hasCptCodeSettings ? "No CPT Requirements for this Payor" : <ReviewStatusCell {...props} />),
    Filter: (props) => <BoolFilter {...props} yesLabel="Needs Review" noLabel="Reviewed" />,
    disableSortBy: true,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: (props) =>
      !props.row.original.hasCptCodeSettings ? "No CPT Requirements for this Payor" : <PaRequirementActionCell {...props} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PaRequirementsReviewTab = () => {
  const columns = useColumns();

  const [selectedPayorGroupId, setSelectedPayorGroupId] = useState(null);

  const [canUpdate] = useUpdatePermission("PayorCptCodesSetting");
  const [canUpdateCadence] = useUpdatePermission("ReviewCadence");

  const extraParams = useMemo(() => deepCompact({ payorGroupId: selectedPayorGroupId }), [selectedPayorGroupId]);
  const { tableRef, setTableFilters } = useAdministrateTable(extraParams);

  const onUpdateCadence = () => {
    const { refresh, allFilters, setMessage } = tableRef.current;
    setMessage("Review cadence has been updated.", "success");
    refresh();
  };

  if (!canUpdate) {
    return <Alert color="warning">You do not have permissions to review PA requirements</Alert>;
  }

  return (
    <>
      <Toolbar start className="align-items-center mb-3">
        <Toolbar>
          <div style={{ width: 300 }}>
            <AdministrateSelect
              placeholder="Filter by Payor Group..."
              resourceUrl="/admin/payor_groups.json"
              onChange={setSelectedPayorGroupId}
              className="me-2"
            />
          </div>
          {canUpdateCadence && (
            <div style={{ width: 300 }}>
              <UpdateReviewCadenceModal
                resourceType="payor_cpt_codes_settings"
                reviewType="requirement"
                outline
                className="ms-2"
                onUpdate={onUpdateCadence}
              />
            </div>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/payors/with_cpt_codes.json`}
        resourceName={"payor"}
        columns={columns}
        ref={tableRef}
        permissions={[UPDATE]}
        onFilteredChange={setTableFilters}
        extraParams={extraParams}
      />
    </>
  );
};
