import { createRoot } from "react-dom/client";
import { PaperFollowupWizard } from "../../workflow/wizards/PaperFollowupWizard";

export function initPaperFollowup() {
  const container = document.getElementById("paper_followup");

  if (container) {
    const root = createRoot(container);

    const { caseId, caseData, payorContacts, commentsUrl, snoozeUrl, tasksUrl, nextStepUrl, statusUrl, miEnabled } = container.dataset;

    root.render(
      <PaperFollowupWizard
        payorContacts={JSON.parse(payorContacts)}
        caseData={JSON.parse(caseData)}
        caseId={caseId}
        snoozeUrl={snoozeUrl}
        commentsUrl={commentsUrl}
        tasksUrl={tasksUrl}
        nextStepUrl={nextStepUrl}
        statusUrl={statusUrl}
        miEnabled={miEnabled === "true"}
      />
    );
  }
}
