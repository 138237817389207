import classNames from "classnames";
import React from "react";
import { Badge } from "reactstrap";
import Icon from "./Icon";

export const Chip = ({ label, color = "chip", pill = true, icon, iconClass, monospace = false, className, ...rest }) => {
  return (
    <Badge
      pill={pill}
      color={`chip chip-${color}`}
      className={classNames(className, `chip d-inline-flex align-items-center`, { "font-monospace": monospace })}
      {...rest}
    >
      {icon && <Icon name={icon} size={14} className={classNames(iconClass, "me-1")} />}
      {label}
    </Badge>
  );
};
