import React from "react";
import { FormText } from "reactstrap";
import { TestSelector as BaseTestSelector } from "../TestSelector";
import { InputWrapper } from "./InputWrapper";

export const TestSelector = ({ children, input, label, meta, ...rest }) => {
  const invalid = Boolean(meta.touched && meta.error);

  return (
    <InputWrapper label={label} required={rest.required} data-testid={rest["data-testid"]}>
      <BaseTestSelector {...input} {...rest} valid={!invalid} />

      {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}

      {children}
    </InputWrapper>
  );
};
