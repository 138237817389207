import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { GLOBAL_ROLES } from "../../../util/data/global_roles";
import { objectToOptions } from "../../../util/formatters";
import _ from "lodash";

export const CreateUserModal = ({ onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);
    try {
      const res = await api.post("/users/invitation", { user: snakifyKeys(values) });

      if (onCreate) {
        onCreate(res.data);
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add User
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New Account</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field component={TextInput} required validate={required} label="Email" name="email" />
                  <Field component={TextInput} required validate={required} label="First Name" name="firstName" />
                  <Field component={TextInput} required validate={required} label="Last Name" name="lastName" />
                  <Field
                    component={SelectInput}
                    validate={required}
                    required
                    label="Role"
                    name="global_role"
                    options={objectToOptions(GLOBAL_ROLES)}
                  />
                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
