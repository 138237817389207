import React from "react";
import moment from "moment-timezone";
import { Field, Form } from "react-final-form";
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import { DateInput } from "../../../components/inputs/final_form/DateInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { SerializedPayorSelector } from "../../../components/inputs/final_form/PayorSelector";
import { SerializedLabSelector } from "../../../components/inputs/final_form/LabSelector";
import { LoadingButton } from "../../../components/LoadingButton";

const CaseSearchFormBody = ({ handleSubmit, submitting, form, isPharmacyClinicUser, isOffshoreAccount, searchableStatuses }) => {
  const labChange = (labs) => {
    form.change("labs", labs);
  };

  const payorChange = (payors) => {
    form.change("payors", payors);
  };

  const handleFormReset = () => {
    form.reset();
  };

  const colClass = "col-md-4";

  return (
    <>
      <Row>
        <Col className="col-lg-8">
          <Card className="mt-3" id="case-search-form">
            <CardBody>
              <Row>
                <Col className={colClass}>
                  <Field component={TextInput} label="Patient First" name="patientFirst" />
                </Col>
                <Col className={colClass}>
                  <Field component={TextInput} label="Patient Last" name="patientLast" />
                </Col>
                <Col className={colClass}>
                  <Field component={DateInput} label="Patient DOB" name="patientDob" />
                </Col>
              </Row>
              <Row>
                <Col className={colClass}>
                  <Field
                    onChange={labChange}
                    render={SerializedLabSelector}
                    isMulti
                    label={isPharmacyClinicUser ? "Provider" : "Labs"}
                    name="labs"
                  />
                </Col>
                <Col className={colClass}>
                  <Field component={TextInput} label={isPharmacyClinicUser ? "Order ID" : "Lab Order ID"} name="orderId" />
                </Col>
                <Col className={colClass}>
                  <Field component={TextInput} label={isPharmacyClinicUser ? "Patient ID" : "Lab Patient ID"} name="patientId" />
                </Col>
              </Row>
              <Row>
                <Col className={colClass}>
                  <Field
                    onChange={payorChange}
                    render={SerializedPayorSelector}
                    isMulti
                    label="Payors"
                    name="payors"
                    valueSelector="id"
                    usOnly={isOffshoreAccount}
                  />
                </Col>
                <Col className={colClass}>
                  <Field component={TextInput} label={"Member ID"} name="memberId" />
                </Col>
                <Col className={colClass}>
                  <Field component={SelectInput} options={searchableStatuses} isMulti label="Statuses" name="statuses" />
                </Col>
              </Row>
              <Row>
                <Col className={colClass}>
                  <Field component={TextInput} label={"Submission Reference Number"} name="referenceNumber" />
                </Col>
                <Col className={colClass}>
                  <Field
                    component={DateInput}
                    label={"Start Date"}
                    name="startDate"
                    initialValue={moment().subtract(30, "days").format("MM/DD/YYYY")}
                  />
                </Col>
                <Col className={colClass}>
                  <Field component={DateInput} label={"End Date"} name="endDate" initialValue={moment().format("MM/DD/YYYY")} />
                </Col>
              </Row>
            </CardBody>

            <CardFooter>
              <Row>
                <Col>
                  <LoadingButton size="sm" color="primary" loading={submitting} onClick={handleSubmit} className="me-2">
                    Search Cases
                  </LoadingButton>
                  <Button color="danger" disabled={submitting} size="sm" outline onClick={handleFormReset}>
                    Clear Search
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>

        <Col className="col-lg-4">
          <Card className="mt-3">
            <CardBody>
              <Row>
                <Field component={TextInput} label="Case ID" className="col-md-6" name="caseId" />
              </Row>
            </CardBody>

            <CardFooter>
              <LoadingButton size="sm" color="primary" loading={submitting} onClick={handleSubmit}>
                Find Case
              </LoadingButton>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export const CaseSearchForm = ({ handleSubmit, initialValues = {}, ...props }) => {
  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, submitting, form }) => {
        return <CaseSearchFormBody handleSubmit={handleSubmit} submitting={submitting} form={form} {...props} />;
      }}
    </Form>
  );
};
