import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../components/AdministrateTable";
import { History } from "../../tools/sandbox/release/History";

export const PaRequirementsActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = (msg) => {
    setMessage(`Updated ${row.original.dashboardDisplayName}`);
    refresh();
  };

  return (
    <>
      <AdministrateResourceDeleteButton
        label="Delete"
        deleteUrl={`/admin/payor_cpt_codes_settings/${row.original.id}`}
        onSuccess={() => {
          refresh();
          setMessage(`Deleted setting for Payor ${row.original.payor?.name} and CPT Code ${row.original.cptCode?.code}.`);
        }}
        onError={(msg) => setError(msg)}
      />

      <History entityName={"PayorCptCodesSetting"} subjectId={row.original.id} />
    </>
  );
};
