import React, { useState } from "react";
import { Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { useUpdatePermission } from "../../utils";
import { InsurancePayorMappingForm } from "./InsurancePayorMappingForm";
import { InsurancePayorMappingReadOnly } from "./InsurancePayorMappingReadOnly";

export const EditInsurancePayorMappingModal = ({ insurancePayorMapping, onEdit, ...rest }) => {
  const [canUpdate] = useUpdatePermission("InsurancePayorMapping", insurancePayorMapping.id);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (params) => {
    setError(null);
    try {
      const res = await api.patch(`/admin/insurance_payor_mappings/${insurancePayorMapping.id}`, {
        insurance_payor_mapping: snakifyKeys(params),
      });

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const mode = canUpdate ? "Edit" : "View";

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        {mode}
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{mode} Insurance Payor Mapping</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          {canUpdate ? (
            <Form
              onSubmit={handleEdit}
              initialValues={{
                labId: insurancePayorMapping.lab.id,
                payorId: insurancePayorMapping.payor.id,
                insuranceName: insurancePayorMapping.insuranceName,
              }}
            >
              {(props) => <InsurancePayorMappingForm {...props} />}
            </Form>
          ) : (
            <InsurancePayorMappingReadOnly insurancePayorMapping={insurancePayorMapping} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
