import React from "react";
import _ from "lodash";
import { StyledSelect as Select } from "../../components/inputs/StyledSelect";

export const ValuesSelect = ({ onChange, value, defaultFields = {}, customFields = {}, isDisabled = false, placeholder = null }) => {
  const defaultOptions = _.map(defaultFields, (f, k) => {
    return {
      label: f.label,
      value: k,
    };
  });
  const customOptions = _.map(customFields, (f, k) => {
    return {
      label: f.label,
      value: `custom:${k}`,
    };
  });
  const options = _.concat(defaultOptions, customOptions);
  const selectedValues = _.castArray(value);
  const selectedOptions = _.map(selectedValues, (v) => _.find(options, (o) => o.value === v));
  return (
    <Select
      value={selectedOptions}
      onChange={(values) => {
        onChange(_.map(values, (v) => v.value));
      }}
      options={options}
      isMulti={true}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};
