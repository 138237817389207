import React, { useCallback } from "react";
import { adminApi as api, handleRequestSequence } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { ImportModal } from "../../../components/ImportModal";

export const ImportFollowupTaskConfigsModal = ({ onImport, ...rest }) => {
  const handleImport = useCallback(
    async (values) => {
      const params = {
        file: {
          bytes: values.file,
        },
      };

      const res = await api.post(`/admin/followup_task_configs/import`, snakifyKeys(params));

      if (onImport) {
        onImport(res.data?.meta?.message, res.data?.meta?.error_details);
      }
    },
    [onImport]
  );

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Import"
      exampleDownloadPath="/admin/followup_task_configs/example"
      fileInputProps={{ multiple: false }}
    />
  );
};
