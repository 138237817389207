import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { ChevronDown, ChevronRight } from "lucide-react";
import { LoadingIcon } from "./LoadingIcon";

export const BIReceipt = ({ headerMessage, headerSubMessage, headerValue, effectiveDate, pendingProcessing = false, receiptItems }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const ArrowIcon = showDetails ? ChevronDown : ChevronRight;

  return (
    <>
      <div className="bi_receipt-react">
        <div className="bi_receipt-header">
          <div className="header-info">
            {pendingProcessing && (
              <div className="header-section w-50">
                <div className="header-label">Estimated Total Patient Responsibility</div>
                <div className="header-value">
                  <div className="pr-4">
                    <LoadingIcon />
                    <span style={{ paddingLeft: 5 }}>Results Pending Processing...</span>
                  </div>
                </div>
              </div>
            )}
            {!pendingProcessing && (
              <div className="header-section w-50">
                {headerMessage && <div className="header-label">{headerMessage}</div>}
                {headerSubMessage && <div className="header-sub-label">{headerSubMessage}</div>}
                {headerValue && <div className="header-value">{headerValue}</div>}
              </div>
            )}
            {!pendingProcessing && receiptItems && (
              <>
                <div className="header-section w-25">
                  <div className="header-label">Effective Date</div>
                  <div className="header-value">{effectiveDate}</div>
                </div>
                <div className="header-details w-25">
                  <div className="details-text">{showDetails ? "Hide " : "Show "} Details</div>
                  <div className="details-arrow-container" onClick={toggleDetails}>
                    <ArrowIcon className="details-arrow" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Collapse isOpen={showDetails}>
          <div className="bi_receipt-body">
            {receiptItems?.map((receiptItem, index) => {
              return (
                <div className="body-row" key={index}>
                  <div className="row-label">{receiptItem.label}</div>
                  <div className="row-value">{receiptItem.value}</div>
                </div>
              );
            })}
          </div>
        </Collapse>
      </div>
    </>
  );
};
