import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { CptSelector } from "../../../components/inputs/final_form/CptSelector";
import { DateInput } from "../../../components/inputs/final_form/DateInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { composeValidators, naiveUrl, required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const CreatePolicyModal = ({ onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);
    try {
      const res = await api.post("/admin/policies", { policy: snakifyKeys(values) });

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Policy
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Policy</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field component={TextInput} required validate={required} label="Policy Name" name="name" />
                  <Field
                    component={TextInput}
                    required
                    validate={composeValidators(required, naiveUrl)}
                    label="Reference Link"
                    type="url"
                    name="referenceLink"
                  />
                  <Field component={DateInput} required validate={required} label="Effective Date" name="effectiveDate" />
                  <Field component={CptSelector} label="CPT Codes" name="cptCodes" showQuantities={false} />
                  <Field
                    isMulti
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/payors.json"
                    label="Payors"
                    name="payorIds"
                    searchParam="name"
                  />
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/payor_groups.json"
                    label="Payor Group"
                    name="payorGroupId"
                  />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
