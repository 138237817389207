import React, { useCallback } from "react";
import { Container, Row } from "reactstrap";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { TooltipCell } from "../../../components/grid/cells/TooltipCell";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { AddCaseTipModal } from "./components/AddCaseTipModal";
import { CaseTipActionCell } from "./components/CaseTipActionCell";
import { ImportCaseTipsModal } from "./components/ImportCaseTipsModal";
import { History } from "../../tools/sandbox/release/History";

const useColumns = makeColumns(() => [
  {
    id: "lab",
    accessor: "labName",
    Header: "Lab",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/labs.json" valueKey="id" labelKey="lab_name" searchParam="lab_name" />
    ),
  },
  {
    id: "payor",
    accessor: "payorName",
    Header: "Payor",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="id" labelKey="name" searchParam="name" />
    ),
  },
  {
    id: "test",
    accessor: "testName",
    Header: "Test",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/tests.json"
        valueKey="id"
        labelKey="commercial_name"
        searchParam="commercial_name"
      />
    ),
  },
  {
    id: "physician_npi",
    accessor: "physicianNpi",
    Header: "Physician NPI",
  },
  {
    id: "benefit_management_portal",
    accessor: "portalName",
    Header: "Benefit Management Portal",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/benefit_management_portals.json"
        valueKey="id"
        labelKey="name"
        searchParam="name"
      />
    ),
  },
  {
    id: "text",
    accessor: "text",
    Header: "Case Tip",
    Cell: TooltipCell,
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: CaseTipActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const CaseTipsIndex = () => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const columns = useColumns();
  const [canCreate] = useCreatePermission("CaseTip");

  const onAdd = useCallback(
    (message, errorDetails = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, errorDetails.length ? "warning" : "success", errorDetails);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Case Tips">
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Toolbar className="ml-auto">
            <History entityName="CaseTip" className="me-2" />
            {canCreate && (
              <AdministrateBackgroundExportButton
                outline
                className="me-2"
                exportUrl="/admin/case_tips/export"
                filters={allFilters}
                ref={tableRef}
              />
            )}
            {canCreate && <ImportCaseTipsModal outline className="me-2" onImport={onAdd} />}
            {canCreate && <AddCaseTipModal onAdd={onAdd} color="primary" />}
          </Toolbar>
        </Row>
      </Container>

      <AdministrateTable
        resourceUrl={"/admin/case_tips.json"}
        resourceName={"case_tip"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
