import React from "react";
import { Field } from "react-final-form";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const ImportLabFeeScheduleByPlanTypeModal = ({ labId, onImport, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      payorId: values.payorId,
      file: {
        bytes: values.file,
      },
    };

    const res = await api.post(`/admin/cpt_fee_schedule_by_plan_types/import.json?lab_id=${labId}`, snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message, res.data?.meta?.error_details);
    }
  };

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Import Fee Schedule By Plan Type..."
      exampleDownloadPath="/admin/cpt_fee_schedule_by_plan_types/example"
    >
      {(formProps) => (
        <Field component={AdministrateSelectInput} resourceUrl="/admin/payors.json" label="Payor" name="payorId" searchParam="name" />
      )}
    </ImportModal>
  );
};
