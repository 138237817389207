import { useEffect, useRef, useState } from "react";
import { api } from "../../../../util/api";
import { Field, FormSpy } from "react-final-form";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { BlankableField } from "../../../../components/inputs/final_form/BlankableField";
import { composeValidators, numericInRange, required, requiredNotNull } from "../../../../util/validators";
import { LoadingButton } from "../../../../components/LoadingButton";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";
import { SelectInput } from "../../../../components/inputs/final_form/SelectInput";
import { OnChange } from "react-final-form-listeners";
import { Switch } from "../../../../components/inputs/final_form/Switch";
import { DENIED_STATUSES } from "../../../../util/data/statuses";

const getSubstatusOptions = async (status, setSubstatusOptions) => {
  try {
    const { data } = await api.get(`/admin/enums/prior_auth_substatuses.json?status=${status}`);

    setSubstatusOptions(data.resources);
  } catch (err) {
    console.err(err);
  }
};

export const PaperResponseForm = ({ form, handleSubmit, submitting, values, ...rest }) => {
  const [status, setStatus] = useState(values.status);
  const [substatusOptions, setSubstatusOptions] = useState([]);

  const handleStatusChange = (selected) => {
    form.change("substatus", "");

    setStatus(selected?.value);
  };

  const handleAllPayorsChange = () => {
    form.change("payorIds", "");
  };

  useEffect(() => {
    if (status) {
      getSubstatusOptions(status, setSubstatusOptions);
    }
  }, [status]);

  return (
    <>
      <Field component={TextInput} label="Message" name="message" validate={required} required />

      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/enums/paper_responses_submit_right_direction_options.json"
        labelKey="label"
        valueKey="id"
        label="Submit Right Direction"
        name="submitRightDirection"
        validate={required}
        required
      />

      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          return (
            <>
              {values.submitRightDirection == "status_the_case" && (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/enums/prior_auth_statuses.json"
                    label="Status"
                    name="status"
                    labelKey="label"
                    valueKey="id"
                    onUpdate={handleStatusChange}
                    validate={requiredNotNull}
                    required
                  />
                  <OnChange name="status">{handleStatusChange}</OnChange>

                  {substatusOptions.length >= 1 && (
                    <Field
                      component={SelectInput}
                      label="Substatus"
                      name="substatus"
                      options={substatusOptions}
                      required
                      validate={required}
                      includeBlank
                    />
                  )}

                  {DENIED_STATUSES.includes(values.status) && (
                    <Field
                      component={AdministrateSelectInput}
                      resourceUrl="/admin/enums/denial_reason_options.json"
                      label="Denial Reason"
                      name="denialReasonCode"
                      labelKey="label"
                    />
                  )}
                </>
              )}

              {values.submitRightDirection == "task_out_for_n_number_of_days_to_try_again" && (
                <>
                  <Field component={TextInput} label="Set Task Name" name="taskName" required validate={requiredNotNull} />
                  <Field
                    component={TextInput}
                    type="number"
                    name="taskDuration"
                    label="Set Task Duration(Days)"
                    validate={composeValidators(required, numericInRange(0, 90))}
                    min="0"
                    max="90"
                    required
                  />
                </>
              )}

              {values.submitRightDirection == "resubmit_to_portal" && (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/benefit_management_portals.json"
                    label="Portal"
                    name="benefitManagementPortalId"
                    valueKey="id"
                    labelKey="name"
                    searchParam="name"
                    validate={required}
                  />
                </>
              )}

              <Field name="allPayors" subscription={{ value: true }}>
                {({ input: { value } }) =>
                  !value && (
                    <Field
                      component={AdministrateSelectInput}
                      isMulti
                      resourceUrl="/admin/payors.json"
                      label="Payors"
                      name="payorIds"
                      searchParam="name"
                      validate={required}
                      required
                      disabled={values.allPayors}
                    />
                  )
                }
              </Field>

              <Field component={Switch} name="allPayors" label="All Payors" type="checkbox" />
              <OnChange name="allPayors">{handleAllPayorsChange}</OnChange>
            </>
          );
        }}
      </FormSpy>

      <Field component={TextInput} name="cannedComment" label="Canned Comment" validate={required} required />

      <LoadingButton loading={submitting} onClick={handleSubmit}>
        Submit
      </LoadingButton>
    </>
  );
};
