import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { atLeastOneFieldRequired } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const AddPoliciesModal = ({ payor, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleAdd = async (params) => {
    setError(null);

    try {
      const { data } = await api.post(`/admin/payors/${payor.id}/policies`, snakifyKeys(params));

      if (onAdd) {
        onAdd(data.meta.message);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Policies...
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Link {payor.name} To...</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={handleAdd}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    isMulti
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/policies.json"
                    label="Policies"
                    name="policyIds"
                    distinct={true}
                    validate={atLeastOneFieldRequired("policyIds")}
                  />
                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
