import React, { useCallback, useMemo } from "react";
import { DetailedAlert } from "../../../components/DetailedAlert";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";

export const ImportPayorGroupMembersModal = ({ payorGroup, onImport, ...rest }) => {
  const handleImport = useCallback(
    async (values) => {
      const params = {
        file: {
          bytes: values.file,
        },
      };
      const res = await api.post(`/admin/payor_groups/${payorGroup.id}/payors/import.json`, snakifyKeys(params));

      onImport?.(res.data?.meta?.message, res.data?.meta?.error_details);
    },
    [onImport, payorGroup.id]
  );

  const details = [
    <ul>
      <li>
        <b>Imports are add-only:</b> they will not remove payors, they will not update payor names.
      </li>
      <li>
        <b>Imports validate the group id:</b> the payor_group_id column must match this payor group's id: {payorGroup.id}.
      </li>
    </ul>,
  ];

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Import Payors..."
      exampleDownloadPath={`/admin/payor_groups/${payorGroup.id}/payors/example_csv`}
    >
      <DetailedAlert message="Payor Import Info" details={details} />
    </ImportModal>
  );
};
