import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailsList } from "../../components/DetailsList";

export const ViewPayorAutoApprovalModal = ({ payorId, autoApproval, onCreate, ...rest }) => {
  const [openTestBundle, setOpenTestBundle] = useState(false);

  const toggleTestBundleModal = () => setOpenTestBundle(!openTestBundle);

  const values = [
    {
      label: "Test Bundle Name",
      value: autoApproval.name,
    },
    {
      label: "Tests",
      value: autoApproval.testList,
    },
  ];

  return (
    <>
      <Button onClick={toggleTestBundleModal} {...rest}>
        View
      </Button>

      <Modal isOpen={openTestBundle} toggle={toggleTestBundleModal}>
        <ModalHeader toggle={toggleTestBundleModal}>View Test Bundle</ModalHeader>

        <ModalBody>
          <DetailsList values={values} />

          {autoApproval.patientCriteria.map((a) => (
            <React.Fragment key={a.id}>
              <div>
                <strong>{a.dashboard_display_name}</strong>
              </div>
              <div className="ms-3">
                <DetailsList
                  values={[
                    { label: "Gender", value: a.gender },
                    { label: "Age", value: `${a.age_symbol} ${a.age}` },
                    { label: "ICD10 Codes", value: a?.initial_icd10_code_options?.map((o) => o.label).join(", ") },
                  ]}
                />
              </div>
            </React.Fragment>
          ))}
        </ModalBody>
      </Modal>
    </>
  );
};
