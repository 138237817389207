import React, { useCallback, useMemo, useState } from "react";
import { StyledAsyncSelect as AsyncSelect } from "./StyledSelect";
import { useDebounced } from "../../util/hooks";

export const RemoteSelector = ({ loadOptions, value, onChange, isMulti = false, ...rest }) => {
  const [cachedOptions, setCachedOptions] = useState([]);

  const selectedOptions = useMemo(
    () => cachedOptions.filter((o) => (Array.isArray(value) ? value.includes(o.value) : value === o.value)),
    [value, cachedOptions]
  );

  const handleChange = useCallback(
    (selected) => onChange(isMulti ? _.map(selected, (s) => s.value) : [selected.value]),
    [isMulti, onChange]
  );

  const fetchOptions = useCallback(
    async (q) => {
      // Get the options from the server
      const options = await loadOptions(q);

      // Cache the options
      setCachedOptions((prev) => _.uniqBy([...prev, ...options], "value"));

      // Always include the currently selected options in the rendered options
      return _.uniqBy([...selectedOptions, ...options], "value");
    },
    [loadOptions, selectedOptions]
  );

  const debouncedLoad = useDebounced(fetchOptions, 1000);

  return (
    <AsyncSelect
      {...rest}
      searchable
      onChange={handleChange}
      loadOptions={debouncedLoad}
      value={selectedOptions}
      isMulti={isMulti}
      defaultOptions
      cacheOptions
      hideSelectedOptions={false}
    />
  );
};
