import { Field } from "react-final-form";
import { SelectInput } from "./final_form/SelectInput";
import { OnChange } from "react-final-form-listeners";
import { DetailedErrorAlert } from "../DetailedErrorAlert";
import { useEnum } from "../../util/hooks";

function createOptions(objectToMap) {
  return Object.entries(objectToMap).map(([key, value]) => ({
    label: value,
    value: key,
  }));
}

export function CollectionTypeSelector({ onCollectionChangeCallback = null, collectionHtmlAttributes = {}, ...rest }) {
  const { loaded, collection: collectionTypes, error } = useEnum("collection_types");

  let collectionTypesOptions = [];
  if (loaded && collectionTypes) {
    collectionTypesOptions = createOptions(collectionTypes);
  }

  const handleCollectionChange = (option) => {
    onCollectionChangeCallback(option);
  };

  return (
    <>
      {loaded && <DetailedErrorAlert error={error} />}

      <Field
        name="testInfo.collectionType"
        label="Collection Type"
        id={collectionHtmlAttributes?.id || rest?.input?.id}
        component={SelectInput}
        options={collectionTypesOptions}
        {...rest}
      />

      <OnChange name="testInfo.collectionType">{handleCollectionChange}</OnChange>
    </>
  );
}
