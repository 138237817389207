import _ from "lodash";
import React, { useCallback, useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { LoadingButton } from "../components/LoadingButton";
import { api } from "../util/api";
import { snakifyKeys } from "../util/helpers";
import { composeValidators, numericInRange, required } from "../util/validators";

export const UpdateTargetModal = ({ currentTarget, userId, onUpdate, buttonProps = {}, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = useCallback(() => setOpen(!open), [open, setOpen]);

  const onSubmit = async (values) => {
    try {
      await api.put(`/accounts/${userId}/assignment_count`, snakifyKeys(values));

      if (_.isFunction(onUpdate)) {
        setOpen(false);
        onUpdate(values);
      }
    } catch (err) {
      setError(err);
    }
  };
  return (
    <>
      <Button onClick={toggleModal} {...buttonProps}>
        Update Target
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Update User Target</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={onSubmit} initialValues={{ targetCaseAssignmentCount: currentTarget }}>
            {({ submitting, handleSubmit }) => {
              return (
                <>
                  <Field
                    required
                    name="targetCaseAssignmentCount"
                    validate={composeValidators(required, numericInRange(0, 120))}
                    component={TextInput}
                    type="number"
                    min={0}
                    max={120}
                    step={1}
                  />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting} className="me-2">
                    Update
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
