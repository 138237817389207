import React, { useState } from "react";
import classnames from "classnames";
import { ButtonToolbar, ButtonGroup, Button, TabContent, TabPane } from "reactstrap";
import { LabSftpSettings } from "../components/LabSftpSettings";
import { LabBoxSettings } from "../components/LabBoxSettings";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";

const ACTIVE_TAB_KEY = "active_tab";

export const LabsSweepSettingsTab = ({ lab, refresh }) => {
  const tabs = [
    { name: "SFTP", component: LabSftpSettings },
    { name: "Box", component: LabBoxSettings },
  ];

  // check for an active tab in local storage after refresh()
  const storedTabName = window.localStorage.getItem(ACTIVE_TAB_KEY);
  window.localStorage.removeItem(ACTIVE_TAB_KEY);

  const [active, setActive] = useState(storedTabName || tabs[0].name);

  const toggle = (tab) => {
    if (active !== tab) setActive(tab);
  };

  return (
    <>
      <SystemConfigLayout title="Sweep Settings">
        <ButtonToolbar>
          <ButtonGroup>
            {tabs.map((tab) => {
              return (
                <Button
                  key={tab.name}
                  className={classnames({ active: active === tab.name })}
                  onClick={() => {
                    toggle(tab.name);
                  }}
                >
                  {tab.name}
                </Button>
              );
            })}
          </ButtonGroup>
        </ButtonToolbar>

        <TabContent activeTab={active}>
          {tabs.map((tab) => {
            return (
              <TabPane key={tab.name} tabId={tab.name}>
                <tab.component lab={lab} refresh={refresh} toggle={toggle} tabName={tab.name} tab_key={ACTIVE_TAB_KEY} />
              </TabPane>
            );
          })}
        </TabContent>
      </SystemConfigLayout>
    </>
  );
};
