import React from "react";
import { makeColumns, useAdministrateTable } from "../../utils";
import { AdministrateTable } from "../../components/AdministrateTable";

const useColumns = makeColumns(() => [
  {
    id: "provider.name",
    accessor: "provider.name",
    Header: "Provider",
  },
  {
    id: "provider.npi",
    accessor: "provider.npi",
    Header: "Provider NPI",
  },
  {
    id: "supervising_physician.name",
    accessor: "supervisingPhysician.name",
    Header: "Supervising Physician",
  },
]);

export const ClinicsProvidersTab = ({ clinic, clinicId }) => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const clinicName = clinic.name;
  const columns = useColumns();

  return (
    <>
      <AdministrateTable
        resourceUrl={`/admin/clinic_provider_mappings.json?clinic[id]=${clinicId}`}
        resourceName={"clinic_provider_mappings"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
