import _ from "lodash";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { TabbedRoutes } from "../../components/TabbedRoutes";
import { PaRequirementsReviewTab } from "./tabs/PaRequirementsReviewTab";
import { PaRequirementsGeneralTab } from "./tabs/PaRequirementsGeneralTab";
import { LabSpecificPaRequirementsTab } from "./tabs/LabSpecificPaRequirementsTab";
import { GroupIdSpecificPaRequirementsTab } from "./tabs/GroupIdSpecificPaRequirementsTab";

const useTabs = () => {
  return _.compact([
    {
      path: "general",
      label: "General",
      Component: PaRequirementsGeneralTab,
    },
    {
      path: "review",
      label: "Review",
      Component: PaRequirementsReviewTab,
    },
    {
      path: "lab_specific",
      label: "Lab Specific",
      Component: LabSpecificPaRequirementsTab,
    },
    {
      path: "group_id_specific",
      label: "Group ID Specific",
      Component: GroupIdSpecificPaRequirementsTab,
    },
  ]);
};

export const PaRequirementsIndexPage = () => {
  const tabs = useTabs();

  return (
    <SystemConfigLayout title={["Automation", "PA Requirements"]}>
      <TabbedRoutes tabs={tabs} defaultTab={"general"} />
    </SystemConfigLayout>
  );
};
