import _ from "lodash";
import moment from "moment-timezone";
import React, { useMemo, useState } from "react";
import { StyledSelect as Select } from "../StyledSelect";
import { Col, FormText, Input, Row } from "reactstrap";
import { InputWrapper } from "./InputWrapper";

const durationUnitOptions = [
  {
    value: "months",
    label: "Months",
  },
  {
    value: "days",
    label: "Days",
  },
];

const getLargestUnit = (duration) => {
  if (duration) {
    const months = duration.asMonths();

    if (months > 1 && _.isInteger(months)) {
      return "months";
    } else {
      return "days";
    }
  }

  return "months";
};

const getValueInUnit = (duration, unit) => {
  if (duration && unit) {
    if (unit === "months") {
      return duration.asMonths();
    } else if (unit === "days") {
      return duration.asDays();
    }
  }

  return "";
};

export const DurationInput = ({ input, label, meta, ...rest }) => {
  const { value, onChange, ...inputRest } = input;

  const parsedValue = moment.duration(value);
  const initialUnit = getLargestUnit(parsedValue);
  const initialValue = getValueInUnit(parsedValue, initialUnit);

  const [internalValue, setInternalValue] = useState(initialValue);
  const [internalUnit, setInternalUnit] = useState(initialUnit);

  const selectedUnit = useMemo(() => durationUnitOptions.find((o) => o.value === internalUnit), [internalUnit]);

  const handleChange = (newValue, newUnit) => {
    if (newValue && newUnit) {
      const newDuration = moment.duration(newValue, newUnit);
      onChange(newDuration.toISOString());
    } else {
      onChange(null);
    }
  };

  const handleUnitChange = (o) => {
    const newUnit = o.value;
    setInternalUnit(newUnit);
    handleChange(internalValue, newUnit);
  };

  const handleValueChange = (e) => {
    const newValue = Number(e.target.value);
    setInternalValue(newValue);
    handleChange(newValue, internalUnit);
  };

  return (
    <InputWrapper label={label} required={rest.required}>
      <Row>
        <Col sm="3">
          <Input type="number" value={internalValue} onChange={handleValueChange} {...inputRest} min={0} step={1} />
        </Col>

        <Col sm="9">
          <Select value={selectedUnit} options={durationUnitOptions} onChange={handleUnitChange} />
        </Col>
      </Row>

      {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
    </InputWrapper>
  );
};
