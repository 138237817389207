import { createRoot } from "react-dom/client";
import { SubmitRightPhoneForm } from "../../submit_right_phone/SubmitRightPhoneForm";

export const initSubmitRightPhoneForm = () => {
  const submitRightPhoneFormContainer = document.getElementById("submit_right_phone");

  if (submitRightPhoneFormContainer) {
    const root = createRoot(submitRightPhoneFormContainer);
    const {
      caseId,
      request,
      defaultFaxParams,
      phoneResponses,
      phoneResponseOptions,
      denialReasonCodeOptions,
      verifiedCptCodeOptions,
      paStatuses,
      saveUrl,
    } = submitRightPhoneFormContainer.dataset;

    root.render(
      <SubmitRightPhoneForm
        caseId={caseId}
        request={JSON.parse(request)}
        defaultFaxParams={JSON.parse(defaultFaxParams)}
        phoneResponses={JSON.parse(phoneResponses)}
        phoneResponseOptions={JSON.parse(phoneResponseOptions)}
        denialReasonCodeOptions={JSON.parse(denialReasonCodeOptions)}
        verifiedCptCodeOptions={JSON.parse(verifiedCptCodeOptions)}
        paStatuses={JSON.parse(paStatuses)}
        saveUrl={saveUrl}
      />
    );
  }
};
