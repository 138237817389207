import { Info } from "lucide-react";
import moment from "moment-timezone";
import React, { useContext } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { formatDate } from "../../../../util/formatters";
import { WizardContext } from "../../WizardContext";

export const AlreadyContactedLabScreen = () => {
  const { caseData, dispatch } = useContext(WizardContext);

  const { labMiCloseDate, previousContactAttempts, autoRejectLabMi } = caseData.workflowInfo.priorAuthRequest;
  const mostRecentAttempt = previousContactAttempts[previousContactAttempts.length - 1];
  const mostRecentAttemptTimestamp = moment(mostRecentAttempt.createdAt);

  return (
    <Card>
      <CardBody className="my-5 text-center">
        <h5 className="mb-3">
          <strong>{window.branding.brandName} Cares Team</strong> was notified about this Missing Information on{" "}
          {formatDate(mostRecentAttemptTimestamp)}
        </h5>

        {autoRejectLabMi && mostRecentAttempt && (
          <p>
            <Info className="text-info me-2" />
            Case will be closed on <strong>{formatDate(moment(labMiCloseDate))}</strong> if MI is not resolved.
          </p>
        )}

        <p>Check with {window.branding.brandName} Cares Team for an update</p>

        <Button color="primary" style={{ minWidth: 200 }} onClick={() => dispatch({ type: "gonext" })}>
          Resolve Missing Info Now
        </Button>
      </CardBody>
    </Card>
  );
};
