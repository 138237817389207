import axios from "axios";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PersistantReactTable } from "../components/grid/PersistantReactTable";
import { api } from "../util/api";
import { encodeTableState } from "../util/table";

import { TokenCell } from "../components/grid/cells/TokenCell";
import { DateFilter } from "../components/inputs/DateFilter";
import { formatDate, inlineAddress } from "../util/formatters";
import { useDebounced } from "../util/hooks";

const COLUMNS = [
  {
    Header: "Account Number",
    accessor: "clinic_internal_id",
    width: 180,
    Cell: TokenCell,
  },
  {
    Header: "Name",
    width: 400,
    accessor: "clinic_name",
    Cell: ({ row }) => (
      <>
        {row.original.clinic_name}
        {row.original.primary_or_first_address && (
          <>
            <br />
            <span className="small text-secondary">{inlineAddress(row.original.primary_or_first_address)}</span>
          </>
        )}
      </>
    ),
  },
  {
    Header: "Tax ID",
    accessor: "tax_id",
    width: 115,
    Cell: TokenCell,
  },
  {
    Header: "Clinic NPI",
    accessor: "npi",
    width: 125,
    Cell: TokenCell,
  },
  {
    Header: "Phone Number",
    accessor: "contact_phone_number",
    width: 200,
  },
  {
    Header: "Number of Providers",
    accessor: "provider_count",
    width: 210,
  },
  {
    Header: "Created At",
    id: "created_at",
    width: 180,
    accessor: (r) => formatDate(r.created_at),
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    Header: "",
    id: "actions",
    Cell: ({ row }) => (
      <a href={row.original.clinic_path} className="btn btn-info btn-sm btn-block">
        View Clinic
      </a>
    ),
    filterable: false,
    width: 120,
  },
];

const DownloadReportButton = () => {
  return (
    <div className="btn-group">
      <div className="dropdown">
        <a
          className="btn btn-sm btn-outline-secondary dropdown-toggle"
          href="#"
          role="button"
          id="downloads_menu"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Downloads
        </a>

        <div className="dropdown-menu" aria-labelledby="downloads_menu">
          <a href="/clinics/download" className="dropdown-item">
            Export Clinics CSV
          </a>
          <a href="/clinics/download_providers" className="dropdown-item">
            Download Master Providers CSV
          </a>
          <a href="/clinics/download_master_clinics" className="dropdown-item">
            Download Master Clinics XLSX
          </a>
        </div>
      </div>
    </div>
  );
};

const getSearchFromParams = () => {
  // window.location.search is the entire query string
  const params = new URLSearchParams(window.location.search);
  return params.get("search") || "";
};

export default function ClinicsGrid({ dataUrl }) {
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(getSearchFromParams());

  const tableRef = useRef();

  // Takes an unencoded table state
  // Sets table state to loading, and then attempts to fetch the data based
  // on the data url
  const fetchData = useDebounced(
    useCallback(
      async ({ params }) => {
        setLoading(true);

        try {
          let dataReq = await api.get(dataUrl, { params });

          setData(dataReq.data.rows);
          setPages(dataReq.data.pages);
        } catch (e) {
          if (!axios.isCancel(e)) {
            console.error(e);
          }
        } finally {
          setLoading(false);
        }
      },
      [dataUrl]
    ),
    400
  );

  const onFetchData = useCallback(
    (rawTableState) => {
      const params = encodeTableState(rawTableState, { search });
      fetchData({ params });
    },
    [fetchData, search]
  );

  // Make sure to call updateTableData on search change
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.updateTableData();
    }
  }, [search]);

  const onSearchChange = (e) => {
    const search = e.target.value;
    setSearch(search);
  };

  const toolbar = (
    <>
      <DownloadReportButton />
      <a className="btn btn-sm btn-outline-info ms-2" href="/exports">
        My Downloads
      </a>
    </>
  );

  return (
    <PersistantReactTable
      ref={tableRef}
      columns={COLUMNS}
      data={data}
      pages={pages}
      onSearch={onSearchChange}
      search={search}
      title="Clinics"
      loading={loading}
      onFetchData={onFetchData}
      toolbarItems={toolbar}
    />
  );
}
