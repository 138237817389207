import React from "react";
import { Card, Col, Row } from "reactstrap";
import { StatCard } from "../../components/StatCard";

export const AssigneeStats = ({ account }) => {
  return (
    <Row>
      <Col>
        <StatCard icon="chevron-up" value={account?.escalated_cases_count} label="Escalated" />
      </Col>
      <Col>
        <StatCard icon="clock" value={account?.due_cases_count} label="Due" />
      </Col>
      <Col>
        <StatCard icon="baby" value={account?.new_cases_count} label="New" />
      </Col>
      <Col>
        <StatCard icon="calendar" value={account?.backlog_cases_count} label="Backlog" />
      </Col>
      <Col>
        <StatCard icon="target" value={account?.target_case_assignment_count} label="Target" />
      </Col>
    </Row>
  );
};
