import Case from "case";
import { Trash } from "lucide-react";
import React, { useState } from "react";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { ReactSortable } from "react-sortablejs";
import { Button, Card, CardBody, CardFooter, ListGroupItem } from "reactstrap";
import { InputWrapper } from "../../../components/inputs/final_form/InputWrapper";
import { AdministrateSelect } from "../../components/AdministrateSelect";

const FieldSorter = ({ fields }) => {
  // React Sortable requires that we pass a function to
  // setList, but we don't actually want to manage the
  // sortable list in that way; so we pass this noop
  // function instead
  const noop = () => {};

  return (
    <ReactSortable
      tag="ul"
      list={fields.value || []}
      setList={noop}
      onEnd={(e) => fields.move(e.oldIndex, e.newIndex)}
      className="list-group list-group-flush"
    >
      {fields.map((name, index) => (
        <ListGroupItem key={name} className="d-flex flex-row align-items-center grabbable">
          <Field name={name} component="input" type="hidden" />

          {Case.title(fields.value[index])}

          <Button size="xs" color="danger" outline className="ms-auto" onClick={() => fields.remove(index)}>
            <Trash />
          </Button>
        </ListGroupItem>
      ))}
    </ReactSortable>
  );
};

export const VerifiedImportSorter = (props) => {
  const [selection, setSelection] = useState(null);

  return (
    <InputWrapper {...props}>
      <Card style={{ width: 400 }}>
        <FieldArray name="labSetting.verifiedDateOfServiceFields">
          {({ fields }) => {
            const handleAdd = () => {
              fields.push(selection);
              setSelection(null);
            };

            const disabled = props.disabled || props.readOnly;
            const addDisabled = disabled || !Boolean(selection) || fields.value.includes(selection);

            return (
              <>
                {fields.value && fields.value.length > 0 ? (
                  <FieldSorter fields={fields} />
                ) : (
                  <CardBody>
                    <span className="text-muted">No Fields Set. Default set will be used.</span>
                  </CardBody>
                )}

                <CardFooter>
                  <div className="d-flex flex-row align-items-start">
                    <div style={{ flex: 1 }}>
                      <AdministrateSelect
                        resourceUrl="/admin/enums/verified_date_of_service_options.json"
                        required
                        labelKey="label"
                        onChange={setSelection}
                        excludeOptions={fields.value}
                        value={selection}
                        disabled={disabled}
                      />
                    </div>

                    <Button onClick={handleAdd} disabled={addDisabled} className="ms-2">
                      Add
                    </Button>
                  </div>
                </CardFooter>
              </>
            );
          }}
        </FieldArray>
      </Card>
    </InputWrapper>
  );
};
