import cn from "classnames";
import _ from "lodash";
import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import { deepCompact } from "../../util/helpers";
import { useStateParams } from "../../util/hooks";
import { BootstrapGrid, CheckboxGrid } from "./BootstrapGrid";

const useTrProps = (keyField, getTrProps, isSelected) =>
  useCallback(
    (rowInfo) => {
      if (!rowInfo) return {};

      const { original } = rowInfo;

      // Process the passed in getTrProps
      const passedInTrProps = _.isFunction(getTrProps) ? getTrProps(rowInfo) : {};

      // Add our own selected tr props
      const selectedClasses = {
        className: cn({
          selected: isSelected(original[keyField]),
        }),
      };

      // Return a merged object
      const newProps = _.mergeWith({}, passedInTrProps, selectedClasses, (objValue, srcValue, key) => {
        if (key === "className") {
          return cn(objValue, srcValue);
        }
      });

      return newProps;
    },
    [keyField, getTrProps, isSelected]
  );

const toggleInArray = (arr, item) => {
  let newArr = _.clone(arr);
  const idx = newArr.indexOf(item);

  if (idx >= 0) {
    newArr.splice(idx, 1);
  } else {
    newArr.push(item);
  }

  return newArr;
};

const useTableState = ({
  keyField,
  onSelectionChange,
  tableRef,
  defaultFiltered,
  defaultSorted,
  onFetchData,
  afterFilterChanged,
  afterSortedChanged,
  afterPageChange,
  afterPageSizeChange,
  defaultPageSize = 20,
  getTrPropsFn,
}) => {
  const [selection, setSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [tableState, setTableState] = useStateParams(
    {
      filtered: defaultFiltered,
      sorted: defaultSorted,
      workflowGroup: null,
      group: null,
      page: 0,
      pageSize: defaultPageSize,
    },
    "table"
  );

  const toggleSelection = useCallback(
    (_key, _shift, row) => {
      const newSelection = toggleInArray(selection, row[keyField]);

      setSelection(newSelection);
      setSelectAll(false);

      if (_.isFunction(onSelectionChange)) {
        onSelectionChange(newSelection);
      }
    },
    [keyField, selection, onSelectionChange]
  );

  const resetSelection = useCallback(() => {
    setSelection([]);
    setSelectAll(false);
  }, []);

  const toggleAll = useCallback(() => {
    const newSelectAll = !selectAll;

    let newSelection = [];

    if (newSelectAll) {
      const wrappedInstance = tableRef.current.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      newSelection = currentRecords.map((item) => item._original[keyField]);
    }

    setSelection(newSelection);
    setSelectAll(newSelectAll);

    if (_.isFunction(onSelectionChange)) {
      onSelectionChange(newSelection);
    }
  }, [selectAll, keyField, tableRef, onSelectionChange]);

  const isSelected = useCallback((id) => selection.includes(id), [selection]);
  const getTrProps = useTrProps(keyField, getTrPropsFn, isSelected);

  const updateTableData = useCallback(() => {
    if (_.isFunction(onFetchData)) {
      onFetchData(tableState);
    }
  }, [onFetchData, tableState]);

  const persistTableState = useCallback(
    (newState, cb) => {
      setTableState((currentState) => deepCompact({ ...currentState, ...newState }));

      if (_.isFunction(cb)) {
        cb();
      }
    },
    [setTableState]
  );

  const onFilteredChange = useCallback(
    (filtered) => {
      persistTableState({ filtered });

      if (_.isFunction(afterFilterChanged)) {
        afterFilterChanged(filtered);
      }
    },
    [persistTableState, afterFilterChanged]
  );

  const onSortedChange = useCallback(
    (sorted) => {
      persistTableState({ sorted });

      if (_.isFunction(afterSortedChanged)) {
        afterSortedChanged(sorted);
      }
    },
    [persistTableState, afterSortedChanged]
  );

  const onPageChange = useCallback(
    (page) => {
      persistTableState({ page });

      if (_.isFunction(afterPageChange)) {
        afterPageChange(page);
      }
    },
    [persistTableState, afterPageChange]
  );

  const onPageSizeChange = useCallback(
    (pageSize, page) => {
      persistTableState({ pageSize, page });

      if (_.isFunction(afterPageSizeChange)) {
        afterPageSizeChange(pageSize);
      }
    },
    [persistTableState, afterPageSizeChange]
  );

  // TODO: Resetting the page doesn't seem to work
  const resetPage = useCallback(() => onPageChange(0), [onPageChange]);

  const clearFilters = useCallback(() => persistTableState({ filtered: [] }), [persistTableState]);

  return {
    tableState,
    selection,
    selectAll,
    toggleSelection,
    resetSelection,
    toggleAll,
    isSelected,
    getTrProps,
    updateTableData,
    onFilteredChange,
    onSortedChange,
    onPageChange,
    onPageSizeChange,
    clearFilters,
    resetPage,
  };
};

export const PersistantReactTable = React.forwardRef((props, ref) => {
  const {
    keyField,
    defaultPageSize = 20,
    checkboxGrid = false,
    onFetchData,
    onSelectionChange,
    onFilteredChange: afterFilterChanged,
    onSortedChange: afterSortedChanged,
    onPageChange: afterPageChange,
    onPageSizeChange: afterPageSizeChange,
    getDefaultFiltered,
    getDefaultSorted,
    defaultFiltered = [],
    defaultSorted = [],
    getTrProps: getTrPropsFn,
  } = props;

  const tableRef = useRef();
  const RenderComponent = useMemo(() => (checkboxGrid ? CheckboxGrid : BootstrapGrid), [checkboxGrid]);

  const finalDefaultFiltered = useMemo(
    () => (_.isFunction(getDefaultFiltered) ? getDefaultFiltered() : defaultFiltered),
    [defaultFiltered, getDefaultFiltered]
  );

  const finalDefaultSorted = useMemo(
    () => (_.isFunction(getDefaultSorted) ? getDefaultSorted() : defaultSorted),
    [defaultSorted, getDefaultSorted]
  );

  const {
    tableState,
    selection,
    selectAll,
    toggleSelection,
    resetSelection,
    toggleAll,
    isSelected,
    getTrProps,
    updateTableData,
    onFilteredChange,
    onSortedChange,
    onPageChange,
    onPageSizeChange,
    clearFilters,
    resetPage,
  } = useTableState({
    keyField,
    onSelectionChange,
    tableRef,
    onFetchData,
    defaultFiltered: finalDefaultFiltered,
    defaultSorted: finalDefaultSorted,
    afterFilterChanged,
    afterSortedChanged,
    afterPageChange,
    afterPageSizeChange,
    defaultPageSize,
    getTrPropsFn,
  });

  const defaultCheckboxProps = useMemo(
    () =>
      checkboxGrid
        ? {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            selectWidth: 40,
          }
        : {},
    [checkboxGrid, selectAll, isSelected, toggleSelection, toggleAll]
  );

  //
  // Pass handlers to parent ref
  //
  useImperativeHandle(
    ref,
    () => ({
      selection,
      toggleSelection,
      resetSelection,
      resetPage,
      toggleAll,
      isSelected,
      updateTableData,
      clearFilters,
      tableState,
    }),
    [selection, toggleSelection, resetSelection, tableState, toggleAll, isSelected, updateTableData, resetPage, clearFilters]
  );

  //
  // Render
  //
  return (
    <RenderComponent
      {...defaultCheckboxProps}
      manual
      resizable
      {...props}
      ref={tableRef}
      defaultPageSize={defaultPageSize}
      pageSize={tableState.pageSize}
      filtered={tableState.filtered}
      sorted={tableState.sorted}
      onSortedChange={onSortedChange}
      onFilteredChange={onFilteredChange}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
      page={tableState.page}
      getTrProps={getTrProps}
    />
  );
});

PersistantReactTable.name = "PersistantReactTable";
