import { createRoot } from "react-dom/client";
import { FaxForm } from "../components/FaxForm";
import { FaxModal } from "../components/FaxModal";

export function initFax() {
  initReactFaxModal();
  initReactFaxForm();
}

function initReactFaxForm() {
  const container = document.getElementById("fax_form");

  if (container) {
    const root = createRoot(container);
    const { casePublicId, caseId, faxSendPart, initialValues } = container.dataset;

    const onSend = () => {};

    root.render(
      <FaxForm
        casePublicId={casePublicId}
        caseId={caseId}
        faxSendPart={faxSendPart === "true"}
        initialValues={JSON.parse(initialValues)}
        onSend={onSend}
        isSubmission={true}
      />
    );
  }
}

function initReactFaxModal() {
  const container = document.getElementById("fax_modal");

  if (container) {
    const root = createRoot(container);
    const { caseId, initialValues, buttonProps } = container.dataset;

    const onSend = () => {};

    root.render(
      <FaxModal caseId={caseId} initialValues={JSON.parse(initialValues)} buttonProps={JSON.parse(buttonProps)} onSend={onSend} />
    );
  }
}
