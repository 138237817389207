import React from "react";
import { Field } from "react-final-form";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const ImportPoliciesModal = ({ onImport, defaultPayor = null, disabled = false, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      payorGroupId: values.payorGroupId,
      payorIds: values.payorIds,
      file: {
        bytes: values.file,
      },
    };

    const res = await api.post("/admin/policies/import", snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message, res.data?.meta?.error_details);
    }
  };

  return (
    <ImportModal buttonProps={rest} onImport={handleImport} title="Import Policies..." exampleDownloadPath="/admin/policies/example">
      {(formProps) => (
        <>
          <Field
            isMulti
            component={AdministrateSelectInput}
            defaultValue={parseInt(defaultPayor)}
            isDisabled={disabled}
            resourceUrl="/admin/payors.json"
            label="Payors"
            name="payorIds"
            searchParam="name"
          />
          <Field
            component={AdministrateSelectInput}
            resourceUrl="/admin/payor_groups.json"
            label="Payor Group"
            name="payorGroupId"
            isDisabled={disabled}
          />
        </>
      )}
    </ImportModal>
  );
};
