import React, { useCallback, useEffect, useState } from "react";
import { FormText, Input, InputGroup } from "reactstrap";
import { camelizeKeys } from "../../../util/helpers";
import { InputWrapper } from "./InputWrapper";
import { TextInput } from "./TextInput";
import { LoadingIcon } from "../../../components/LoadingIcon";
import { api } from "../../../util/api";
import { useDebounced, useMountEffect } from "../../../util/hooks";
import { FormSpy } from "react-final-form";

const fetchCase = async (val) => {
  try {
    const response = await api.get(`/cases/${val}.json`);
    return camelizeKeys(response.data);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const CaseDetailsField = ({ input, children, onLoadDetails, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [caseDetails, setCaseDetails] = useState(null);
  const [error, setError] = useState(null);
  const { onChange } = input;

  const debouncedFetchCase = useDebounced(fetchCase, 300);

  const handleChange = useCallback(
    async (val) => {
      setError(null);

      if (onChange) {
        onChange(val);
      }

      // The shortest Case ID in our system is 6 characters long
      // so don't even try until we get at least 6 characters
      if (!val || val.length < 6) {
        setCaseDetails(null);
        return;
      }

      setLoading(true);
      const details = await debouncedFetchCase(val);

      if (details) {
        setCaseDetails(details);
      } else {
        setCaseDetails(null);
        setError("No Case found with that ID");
      }

      if (onLoadDetails) {
        onLoadDetails(details);
      }

      setLoading(false);
    },
    [onChange, onLoadDetails, debouncedFetchCase]
  );

  // This is a spy function for when our values are manipulated
  // by an outside component; in that case, if the value is
  // different, we want to be sure we trigger our case load
  // and all related callbacks
  const handleExternalValueUpdate = ({ values: newValues }) => {
    if (newValues[input.name] !== input.value) {
      handleChange(newValues[input.name]);
    }
  };

  // On mount, if we were provided a value
  // we want to trigger our on-change callback
  // to load case data and present values
  useMountEffect(() => {
    if (input.value) {
      handleChange(input.value);
    }
  });

  return (
    <>
      <TextInput {...props} input={{ ...input, onChange: (e) => handleChange(e.target.value) }}>
        {loading ? (
          <FormText>
            <LoadingIcon /> Fetching Case Details...
          </FormText>
        ) : (
          children
        )}

        {error && <FormText color="danger">{error}</FormText>}
      </TextInput>

      <FormSpy onChange={handleExternalValueUpdate} />

      {caseDetails && (
        <>
          <InputWrapper label="Lab Name">
            <InputGroup>
              <Input readOnly disabled value={caseDetails?.paRequest?.labInfo?.labName} />
            </InputGroup>
          </InputWrapper>

          <InputWrapper label="Patient Name">
            <InputGroup>
              <Input
                readOnly
                disabled
                value={`${caseDetails?.paRequest?.patientInfo?.firstName} ${caseDetails?.paRequest?.patientInfo?.lastName}`}
              />
            </InputGroup>
          </InputWrapper>

          <InputWrapper label="Patient DOB">
            <InputGroup>
              <Input readOnly disabled value={caseDetails?.paRequest?.patientInfo?.dob ?? ""} />
            </InputGroup>
          </InputWrapper>

          <InputWrapper label="Member ID">
            <InputGroup>
              <Input readOnly disabled value={caseDetails?.paRequest?.insuranceInfo?.primaryInsurance?.memberId} />
            </InputGroup>
          </InputWrapper>

          <InputWrapper label="Lab Order ID">
            <InputGroup>
              <Input readOnly disabled value={caseDetails?.paRequest?.orderInfo?.externalOrderId} />
            </InputGroup>
          </InputWrapper>
        </>
      )}
    </>
  );
};
