import React from "react";
import { PageHeader } from "../../components/PageHeader";
import { LoadingIcon } from "../../components/LoadingIcon";
import { BreadCrumbs } from "./BreadCrumbs";

export const SystemConfigLayout = ({ title, children, showBreadcrumbs = false, crumbs = [], loading = false, ...rest }) => {
  return (
    <>
      {showBreadcrumbs && crumbs.length > 0 && <BreadCrumbs crumbs={crumbs} />}

      <PageHeader header={title} simple />

      <main className="card" {...rest}>
        <section className="card-body">{loading ? <LoadingIcon /> : children}</section>
      </main>
    </>
  );
};
