import React from "react";
import { formatCentsAsDollars } from "../../../util/formatters";

export const EstimateInfoList = ({ biInfo, noMoopProvided = false }) => {
  const estimateData = biInfo;
  const cptCodes = Object.keys(estimateData?.cpt_contracted_rates || {});
  let cptCodeRatings = [];

  cptCodes?.map((code) => {
    cptCodeRatings.push({
      code: code,
      rate: estimateData.cpt_contracted_rates[code],
    });
  });

  return (
    <>
      {cptCodeRatings.map((cptCode) => (
        <li className="d-flex flex-row justify-content-between align-items-center mb-2">
          <h5 className="text-muted fw-semibold">{cptCode.code}</h5>
          <h5 className="text-dark fw-bold">{formatCentsAsDollars(cptCode.rate)}</h5>
        </li>
      ))}

      <li className="d-flex flex-row justify-content-between align-items-center mb-2" key={"patient-covered"}>
        <h5 className="text-muted fw-semibold">Total Covered Charges</h5>
        <h5 className="text-dark fw-bold">{formatCentsAsDollars(estimateData.estimated_patient_responsibility_covered)}</h5>
      </li>

      <li className="d-flex flex-row justify-content-between align-items-center mb-2" key={"patient-responsibility"}>
        <h5 className="text-muted fw-semibold">Total Non Covered Charges</h5>
        <h5 className="text-dark fw-bold">{formatCentsAsDollars(estimateData.estimated_patient_responsibility_noncovered)}</h5>
      </li>

      <li className="d-flex flex-row justify-content-between align-items-center mb-2" key={"deductible-remaining"}>
        <h5 className="text-muted fw-semibold">Current Remaining Deductible</h5>
        <h5 className="text-dark fw-bold">{formatCentsAsDollars(estimateData.deductible_remaining_cents)}</h5>
      </li>

      <li className="d-flex flex-row justify-content-between align-items-center mb-2" key={"moop"}>
        <h5 className="text-muted fw-semibold">Current Max Out of Pocket</h5>
        <h5 className="text-dark fw-bold">{noMoopProvided ? "NA" : formatCentsAsDollars(estimateData.moop_remaining_cents)}</h5>
      </li>

      <li className="d-flex flex-row justify-content-between align-items-center mb-2" key={"copay"}>
        <h5 className="text-muted fw-semibold">Copay</h5>
        <h5 className="text-dark fw-bold">{formatCentsAsDollars(estimateData.copay_cents)}</h5>
      </li>

      <li className="d-flex flex-row justify-content-between align-items-center mb-2" key={"coinsurance"}>
        <h5 className="text-muted fw-semibold">Coinsurance</h5>
        <h5 className="text-dark fw-bold">{estimateData.coinsurance}%</h5>
      </li>

      <li className="d-flex flex-row justify-content-between align-items-center mb-2">
        <h5 className="text-muted fw-semibold">Total Estimated Patient Responsibility</h5>
        <h5 className="text-dark fw-bold">{formatCentsAsDollars(estimateData.estimated_patient_responsibility_total)}</h5>
      </li>

      <li className="d-flex flex-row justify-content-between align-items-center mb-2">
        <h5 className="text-muted fw-semibold">Total Charges Covered by HealthPlan</h5>
        <h5 className="text-dark fw-bold">{formatCentsAsDollars(estimateData.total_charges_covered_by_healthplan)}</h5>
      </li>
    </>
  );
};
