import $ from "jquery";
import _ from "lodash";
import "@selectize/selectize";

export function initSelectize() {
  $(document).ready(function () {
    attachSelectize(document);
  });

  $(document).on("repeater.add", function (_e, $new) {
    attachSelectize($new);
  });
}

function attachSelectize($container) {
  $("select.selectize", $container).selectize({ sortField: "text" });

  $("select[multiple].selectize, select[multiple].selectize-multiple", $container).selectize({
    plugins: ["remove_button"],
  });

  $("select[data-remote]", $container).each(function () {
    const $select = $(this);

    const remoteUrl = $select.data("remote-url");
    const labelField = $select.data("remote-label");
    const valueField = $select.data("remote-value");
    const searchField = $select.data("remote-search") || labelField;

    $select.selectize({
      valueField: valueField,
      labelField: labelField,
      searchField: searchField,
      preload: "focus",
      plugins: ["remove_button"],
      maxItems: null,
      create: false,
      load: _.debounce(function (query, callback) {
        $.ajax({
          url: `${remoteUrl}`,
          data: {
            q: encodeURIComponent(query),
            per: 25,
          },
          type: "GET",
          error: function () {
            callback();
          },
          success: function (res) {
            // New endpoints return { resources: [], meta: {} }
            //  because new front-end code makes use of it
            if (Object.hasOwn(res, "resources")) {
              callback(res["resources"]);
            } else {
              callback(res);
            }
          },
        });
      }, 300),
    });
  });

  $("input[type=checkbox].selectize-all", function () {
    $(this).on("input", function (e) {
      const selected = e.target.checked;
      const name = e.target.dataset.fieldName;
      if (name) {
        const escapedName = $.escapeSelector(`${name}[]`);
        $(`[name=${escapedName}].selectized`)
          .selectize()
          .each(function () {
            const selectedKeys = selected ? Object.keys(this.selectize.options) : [];
            this.selectize.setValue(selectedKeys);
          });
      }
    });
  });
}
