import React from "react";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { EditReportDeliveryModal } from "./EditReportDeliveryModal";

export const LabsReportDeliveryActionCell = ({ labSettingId, ...row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("ReportDeliveryConfig", row.original.id);
  const [canUpdate] = useUpdatePermission("ReportDeliveryConfig", row.original.id);

  const handleUpdate = () => {
    setMessage(`Updated ${row.original.dashboardDisplayName}`);
    refresh();
  };

  return (
    <>
      {canUpdate && (
        <EditReportDeliveryModal color="link" onUpdate={handleUpdate} labSettingId={labSettingId} reportDelivery={row.original} />
      )}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          label="Remove"
          deleteUrl={`/admin/report_delivery_configs/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Report Delivery Config ${row.original.dashboardDisplayName}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
    </>
  );
};
