import cx from "classnames";
import { FormGroup, Input, Label } from "reactstrap";

export const Switch = ({ label, className, ...rest }) => {
  return (
    <FormGroup switch className={cx("mb-0", className)}>
      <Input type="switch" role="switch" {...rest} />
      <Label className="mb-0" check>
        {label}
      </Label>
    </FormGroup>
  );
};
