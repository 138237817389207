import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { BoolIcon } from "./BoolIcon";
import { CollapsePanel } from "./CollapsePanel";
import { DetailGrid, DetailGridItem } from "./DetailGrid";
import { Token } from "./Token";

export const PracticeInfo = ({ practiceInfo }) => {
  const enrolled = practiceInfo.enrolled === "true" || practiceInfo.enrolled === true;
  const hasNpi = Boolean(practiceInfo.npi);
  return (
    <>
      <CollapsePanel labelText="Practice Info">
        <DetailGrid>
          <DetailGridItem title="Practice Name" value={practiceInfo.practiceName} />
          <DetailGridItem title="Provider Name" value={practiceInfo.name} />
          <DetailGridItem
            title="NPI"
            hasValue={Boolean(practiceInfo.npi)}
            value={
              <span className="d-inline-flex align-items-center">
                <Token className="me-2">{practiceInfo.npi}</Token>
                <BoolIcon id="enrollment_icon" size={16} value={enrolled} />
              </span>
            }
          />
        </DetailGrid>
      </CollapsePanel>
      {hasNpi && <UncontrolledTooltip target="enrollment_icon">{enrolled ? "NPI is enrolled" : "NPI is not enrolled"}</UncontrolledTooltip>}
    </>
  );
};
