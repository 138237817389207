import React from "react";
import moment from "moment-timezone";
import { map } from "lodash";
import Case from "case";

export const humanizeString = (str) => {
  return Case.title(str.replaceAll("_", " ").replace(".", " > "));
};

export const humanizeOr = (arr) => {
  return humanizeList(arr, true);
};

export const humanizeList = (arr, disjunct = false) => {
  const lf = new Intl.ListFormat("en", { type: disjunct ? "disjunction" : undefined });
  return lf.format(arr.map((str) => humanizeString(str)));
};

export function objectToOptions(obj) {
  return map(obj, (v, k) => ({ label: Case.title(v), value: k }));
}

export function formatDate(dateString, def = "") {
  if (dateString == null) return def;
  return moment(dateString).tz("America/New_York").format("MM/DD/Y");
}

export function formatIsoDate(dateString, def = "") {
  if (dateString == null) return def;
  return moment(dateString).tz("America/New_York").toISOString();
}

// hard-coded to Eastern Time for now - Dan
export function formatDateTime(dateString, def = "") {
  if (dateString == null) return def;
  return moment(dateString).tz("America/New_York").format("MM/DD/Y h:mm A");
}

export function formatDateTimeWithTz(dateString, def = "") {
  if (dateString == null) return def;
  return moment(dateString).tz("America/New_York").format("MM/DD/Y h:mm A z");
}

export function formatDateTimeWithMonthYear(dateString, def = "") {
  if (dateString == null) return def;
  return moment(dateString).tz("America/New_York").format("MMMM YYYY");
}

export const formatPercent = (value) => {
  const val = parseFloat(Math.round(value * 100));
  return `${val}%`;
};

export const formatDisplayNumber = (value) => {
  var nf = new Intl.NumberFormat();
  return nf.format(parseInt(value, 10));
};

export function formatName(person, prefix = null, isInternalUser = null) {
  if (!person) {
    return "";
  }

  const defaultLastNameField = isInternalUser === false ? "LastName" : "lastName";
  const lastNameField = prefix ? prefix + "LastName" : defaultLastNameField;

  const defaultFirstNameField = isInternalUser === false ? "FirstName" : "firstName";
  const firstNameField = prefix ? prefix + "FirstName" : defaultFirstNameField;

  const last = person[lastNameField] || "NO_LAST_NAME";
  const first = person[firstNameField] || "NO_FIRST_NAME";
  return `${last}, ${first}`;
}

// This gets used to format "assignee" and other user-y object that don't have
// separate first/last names, but may not have names at all.
// Falls back to email if no name is available.
export function userNameOrEmail(user) {
  if (!user) {
    return "";
  }

  const name = user.name && user.name.trim();
  return name ? name : user.email;
}

export function inlineAddress(address) {
  const { street, street2, city, state, zip, country } = address;

  return [street, street2, city, state, country].filter((el) => !!el).join(", ");
}

export function formatHL7(fields) {
  if (!fields) return "";
  if (!Array.isArray(fields)) {
    fields = fields.split("|");
  }
  return fields.map((x) => x.trim()).join(", ");
}

export const formatLabName = (r) => {
  const labInfo = r?.labInfo || r?.lab_info;
  if (!labInfo) {
    return "";
  }

  const labName = labInfo?.labName || labInfo?.lab_name;

  if (labName == "LabCorp") {
    const orderInfo = r?.orderInfo || r?.order_info;
    const externalOrderId = orderInfo?.externalOrderId || orderInfo?.external_order_id;

    if (externalOrderId && externalOrderId.length == 10) {
      return "LabCorp - Sequenom";
    }
  }

  return labName;
};

export const formatCentsAsDollars = (cents) => {
  if (cents === null) {
    return "";
  }
  cents /= 100;
  return cents.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

export const parseCurrency = (currency) => (_.isString(currency) ? Number(currency.replace(/[^0-9\.-]+/g, "")) : currency);
