import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { atLeastOneFieldRequired, required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { Checkbox } from "../../../components/inputs/final_form/Checkbox";

export const PayorContactForm = ({ handleSubmit, submitting }) => {
  return (
    <>
      <Field component={TextInput} required validate={required} label="Contact Name" name="title" />
      <Field component={TextInput} required validate={atLeastOneFieldRequired("faxNumber")} label="Phone Number" name="phoneNumber" />
      <Field component={TextInput} required validate={atLeastOneFieldRequired("phoneNumber")} label="Fax Number" name="faxNumber" />
      <Field component={TextInput} type="textarea" label="Description" name="description" />

      <Field id="allLabs" component={Checkbox} label="All Labs" name="allLabs" type="checkbox" />
      <Field component={AdministrateSelectInput} resourceUrl="/admin/labs.json" label="Labs" name="labIds" isMulti valueKey="id" />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
