import { ProgressListItemEntry as ItemEntry } from "./ProgressListItemEntry";

export const ProgressList = ({ items }) => {
  return (
    <div className="progress-list">
      <ul className="list-group ml-0">
        {items &&
          items.map((item, idx) => {
            return (
              <ItemEntry
                action={item.action}
                actionIcon={item.actionIcon}
                historyStepIcon={item.historyStepIcon}
                content={item.content}
                item={item}
                idx={idx + 1}
                key={idx}
                label={item.label}
                secondaryLabel={item.secondaryLabel}
                lastIdx={items.length}
                status={item.status}
              />
            );
          })}
      </ul>
    </div>
  );
};
