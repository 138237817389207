import { createRoot } from "react-dom/client";
import { AssignmentModalButton } from "../components/AssignmentModal";
import { redirectTo } from "../util/api";

export function initAssignmentModal() {
  const container = document.getElementById("assignment_modal");

  if (container) {
    const root = createRoot(container);
    const { caseId, currentAssignee, buttonProps, assignableUsers, previousAssignee, assignmentUrl } = container.dataset;

    root.render(
      <AssignmentModalButton
        caseIds={[caseId]}
        onAssign={(assignee) => redirectTo(window.location, `Case assigned to ${assignee}`, "success")}
        currentAssignee={currentAssignee}
        assignableUsers={JSON.parse(assignableUsers)}
        previousAssignee={previousAssignee}
        assignmentUrl={assignmentUrl}
        buttonProps={JSON.parse(buttonProps)}
      />
    );
  }
}
