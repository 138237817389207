import { useState } from "react";
import { Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { useUpdatePermission } from "../../utils";
import { PayorMemberPrefixForm } from "./PayorMemberPrefixForm";
import { PayorMemberPrefixReadOnly } from "./PayorMemberPrefixReadOnly";

export const EditPayorMemberPrefixModal = ({ payorMemberPrefix, onEdit, ...rest }) => {
  const [canUpdate] = useUpdatePermission("PayorPrefixMapping", payorMemberPrefix.id);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (params) => {
    setError(null);
    try {
      const res = await api.patch(`/admin/payor_prefix_mappings/${payorMemberPrefix.id}`, { payor_prefix_mapping: snakifyKeys(params) });

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const mode = canUpdate ? "Edit" : "View";

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        {mode}
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{mode} Payor Member Prefix</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          {canUpdate ? (
            <Form
              onSubmit={handleEdit}
              initialValues={{ memberIdPrefixRegex: payorMemberPrefix.memberIdPrefixRegex, payorId: payorMemberPrefix.payor.id }}
            >
              {(props) => <PayorMemberPrefixForm showPrefixField={false} {...props} />}
            </Form>
          ) : (
            <PayorMemberPrefixReadOnly payorMemberPrefix={payorMemberPrefix} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
