import { Container, Row } from "reactstrap";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { Toolbar } from "../../../components/Toolbar";
import { useCallback } from "react";
import { DESTROY, UPDATE, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SuperDialScriptActionCell } from "./components/SuperDialScriptActionCell";
import { AddSuperDialScriptModal } from "./components/AddSuperDialScriptModal";
import { DateFilter } from "../../../components/inputs";
import { DateCell } from "../../../components/grid/cells/DateCell";

const useColumns = makeColumns(() => [
  {
    id: "script_id",
    accessor: "scriptId",
    Header: "Script ID",
  },
  {
    id: "script_name",
    accessor: "scriptName",
    Header: "Script Name",
  },
  {
    id: "date_added",
    accessor: "dateAdded",
    Header: "Date Added",
    Cell: DateCell,
    Filter: (props) => <DateFilter {...props} />,
    maxWidth: 90,
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: SuperDialScriptActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const SuperDialScripts = () => {
  const { tableRef, setTableFilters } = useAdministrateTable();
  const columns = useColumns();
  const [canCreate] = useCreatePermission("SuperDialScript");

  const onAdd = useCallback(
    (message, errorDetails = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, errorDetails.length ? "warning" : "success", errorDetails);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="SuperDial Script IDs">
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Toolbar className="ml-auto">{canCreate && <AddSuperDialScriptModal onAdd={onAdd} color="primary" />}</Toolbar>
        </Row>
      </Container>

      <AdministrateTable
        resourceUrl={"/admin/super_dial_scripts.json"}
        resourceName={"super_dial_script"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
