import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { atLeastOneFieldRequired, required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const PayorAutoApprovalForm = ({ handleSubmit, submitting, initialTestOptions = [] }) => (
  <>
    <Field component={TextInput} required validate={required} name="payorId" readOnly type="hidden" />
    <Field component={TextInput} required validate={required} label="Test Bundle" name="name" />
    <Field
      isMulti
      component={AdministrateSelectInput}
      resourceUrl="/admin/tests.json"
      label="Tests"
      name="testIds"
      initialOptions={initialTestOptions}
      distinct={true}
      validate={atLeastOneFieldRequired("testIds")}
    />

    <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
      Submit
    </LoadingButton>
  </>
);
