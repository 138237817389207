import React, { useCallback } from "react";
import { api } from "../../util/api";
import { RemotePaginateSelector } from "./RemotePaginateSelector";

export const PAYOR_URL = "/payors.json";

export const PayorSelector = ({
  resourceUrl = PAYOR_URL,
  scope,
  valueSelector = "id",
  labelSelector = "name",
  usOnly = false,
  ...rest
}) => {
  const loadDefaultOptions = useCallback(async () => {
    if (!rest.value) {
      return [];
    }

    try {
      const res = await api.get(`/payors/find_by_ids`, { params: { id: rest.value } });
      return res.data.resources.map((p) => ({ value: p[valueSelector], label: p[labelSelector] }));
    } catch (error) {
      console.error("Failed to fetch default options for payor selector:", error);
      return [];
    }
  }, [rest.value, labelSelector, valueSelector]);

  return (
    <>
      <RemotePaginateSelector
        {...rest}
        valueKey={valueSelector}
        labelKey={labelSelector}
        scope={scope}
        loadDefaultOptions={rest.value ? loadDefaultOptions : undefined}
        resourceUrl={resourceUrl}
        extraParams={{ usOnly: usOnly }}
        dataKey="resources"
      />
    </>
  );
};
