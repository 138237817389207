import React, { useState } from "react";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission } from "../../utils";

export const PortalLabActionCell = ({ portalId, ...row }) => {
  const [removing, setRemoving] = useState(false);
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("LabPortalMapping", row.original.id);

  const handleRemoveLab = async () => {
    setRemoving(true);
    try {
      const { data } = await api.delete(`/admin/benefit_management_portals/${portalId}/labs`, {
        params: { lab_ids: [row.original.id] },
      });
      refresh();
      setMessage(data.meta.message);
    } catch (err) {
      setError(extractErrorMessage(err));
    } finally {
      setRemoving(false);
    }
  };

  return (
    canDestroy && (
      <LoadingButton loading={removing} color="link" className="text-danger" onClick={handleRemoveLab}>
        Remove
      </LoadingButton>
    )
  );
};
