import React from "react";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { useTableContext } from "../../components/AdministrateTable";
import { CreateTestAliasModal } from "./CreateTestAliasModal";
import { EditTestModal } from "./EditTestModal";
import { Button } from "reactstrap";
import { useCreatePermission, usePermissionCheck, useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

const getArchivedMessage = (testName, archived) => (archived ? `Archived ${testName}` : `Unarchived ${testName}`);

export const LabTestActionCell = ({ row }) => {
  const [canUpdate] = useUpdatePermission("Test", row.original.id);
  const [canArchive] = usePermissionCheck("archive", "Test", row.original.id);
  const [canCreateTestMapping] = useCreatePermission("LabTestMapping");
  const { refresh, setMessage, setError } = useTableContext();
  const testId = row.original.id;
  const testName = row.original.commercialName;
  const isArchived = row.original.archived;

  const handleArchive = async () => {
    setError(null);

    try {
      const archived = !isArchived;
      await adminApi.put(`/admin/tests/${testId}`, { archived });
      setMessage(getArchivedMessage(testName, archived));
      refresh();
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const handleUpdate = () => {
    setMessage(`Updated ${row.original.commercialName}`);
    refresh();
  };

  const handleTestAliasCreate = (alias) => {
    setMessage(`Created ${row.original.commercialName} alias: ${alias}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditTestModal color="link" onUpdate={handleUpdate} test={row.original} />}
      {canCreateTestMapping && <CreateTestAliasModal color="link" test={row.original} handleTestAliasCreate={handleTestAliasCreate} />}
      {canArchive && (
        <Button onClick={handleArchive} color="link">
          {isArchived ? "Unarchive" : "Archive"}
        </Button>
      )}
      <History entityName="Test" subjectId={testId} />
    </>
  );
};
