import { Info } from "lucide-react";
import React from "react";
import { Button, Card, CardBody, ListGroup, ListGroupItem } from "reactstrap";
import { formatDate } from "../../../../util/formatters";
import { WizardContext } from "../../WizardContext";

export const AlreadyContactedClinicScreen = () => (
  <WizardContext.Consumer>
    {({ caseData, dispatch }) => {
      const { clinicInfo } = caseData;
      const previousAttempts = caseData.workflowInfo.priorAuthRequest.previousContactAttempts;
      const nextAttemptAt = caseData.workflowInfo.priorAuthRequest.nextClinicMiContactAttempt;

      return (
        <Card>
          <CardBody className="my-5 text-center">
            <h5>
              <strong>{clinicInfo.clinicName}</strong> has already been contacted about this Missing Information
            </h5>

            {nextAttemptAt && (
              <p>
                <Info className="me-2 text-info" />
                Next contact attempt will be made on <strong>{formatDate(nextAttemptAt)}</strong>
              </p>
            )}

            <Button color="primary" style={{ minWidth: 200 }} onClick={() => dispatch({ type: "gonext" })}>
              Resolve Missing Info Now
            </Button>
          </CardBody>

          <CardBody className="border-top bg-light">
            <strong>Previous Attempts</strong>
          </CardBody>

          <ListGroup flush className="text-start">
            {previousAttempts.map((a, i) => {
              return (
                <ListGroupItem key={i}>
                  <span className="me-3 text-muted">{formatDate(a.createdAt)}</span>
                  <strong>{a.contactType.toUpperCase()}</strong> contact attempt made by <strong>{a.contactMethod.toUpperCase()}</strong>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </Card>
      );
    }}
  </WizardContext.Consumer>
);
