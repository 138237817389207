export const formatFieldPosition = (page, x, y) => ({
  position: {
    page,
    at: { x, y },
  },
});

export const uniqid = () => "_" + Math.random().toString(36).substr(2, 9);

// Calculate New Position
//
// Given our item, bounds of our page, the movement delta, and the absolute movement
// we calculate the best candidate for a new (x,y) coordinates
export const calculateNewPosition = (item, bounds, delta, absolute) => {
  let newX = Math.round(item.x + delta.x);
  let newY = Math.round(item.y + delta.y);

  // Handle overflow issues, usually caused by switching
  // between pages.
  if (newX <= 0 || newX >= bounds.width) {
    newX = Math.round(absolute.x - bounds.x);
  }

  if (newY <= 0 || newY >= bounds.height) {
    newY = Math.round(absolute.y - bounds.y);
  }

  // Clamp to min/max
  newX = _.clamp(newX, 0, bounds.width);
  newY = _.clamp(newY, 0, bounds.height);

  return { newX, newY };
};

// Calculate Natural Position
//
// The coordinates we have been given are the (x,y) coords according
// to the dom, and are placed relative to the client size of the image.
// In order to store a proper (x,y) position, we need to figure out where
// on the full-size image to place this.
//
// We do so by calculating the percentage basis for the new (x,y)
// position, and then finding the pixel at that (x,y) for the natural width
// and height of our page image.
export const calculateNaturalPosition = (clientX, clientY, pageDefinition, pageSize) => {
  if (!pageDefinition) return;

  const { naturalWidth, naturalHeight, clientWidth, clientHeight } = pageDefinition;

  const xPercent = clientX / clientWidth;
  const yPercent = clientY / clientHeight;

  const naturalX = Math.round(pageSize.width * xPercent);
  const naturalY = Math.round(pageSize.height * yPercent);

  return { naturalX, naturalY };
};

// Calculate Client Position
//
// Inverse of the above function
export const calculateClientPosition = (x, y, pageDefinition, pageSize) => {
  if (!pageDefinition) return;

  const { naturalWidth, naturalHeight, clientWidth, clientHeight } = pageDefinition;

  const xPercent = x / pageSize.width;
  const yPercent = y / pageSize.height;

  const clientX = Math.round(clientWidth * xPercent);
  const clientY = Math.round(clientHeight * yPercent);

  return { clientX, clientY };
};

// Calculate Client Scale Factor
export const calculateClientScaleFactor = (pageDefinition, pageSize) => {
  if (!pageDefinition) return;

  const { naturalWidth, naturalHeight, clientWidth, clientHeight } = pageDefinition;

  return {
    vertical: clientHeight / pageSize.height,
    horizontal: clientWidth / pageSize.width,
  };
};
