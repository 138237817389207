import pluralize from "pluralize";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert, Col, Row } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { BlankableField } from "../../../components/inputs/final_form/BlankableField";
import { Fieldset } from "../../../components/inputs/final_form/Fieldset";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { camelizeKeys, snakifyKeys } from "../../../util/helpers";
import { useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";

export const ClinicsGeneralTab = ({ clinic, clinicId }) => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [canUpdate] = useUpdatePermission("Clinic", clinicId);

  const handleUpdate = async (values) => {
    setError(null);
    setMessage(null);

    try {
      await api.put(`/admin/clinics/${clinicId}`, { clinic: snakifyKeys(values) });
      setMessage("Updated Clinic");
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Form onSubmit={handleUpdate} initialValues={camelizeKeys(clinic)}>
        {({ handleSubmit, submitting, pristine }) => {
          return (
            <>
              <Field component={TextInput} label="ID" name="id" readOnly />
              <Field component={TextInput} label="Clinic Internal ID" name="clinicInternalId" readOnly />
              <Field component={TextInput} label="NPI" name="npi" readOnly />
              <Field component={TextInput} label="Tax ID" name="taxId" readOnly />
              <Field
                component={SelectInput}
                label="Requesting Provider Type"
                name="requestingProviderType"
                options={[
                  { value: "Lab", label: "Lab" },
                  { value: "Pharmacy", label: "Pharmacy" },
                ]}
              />

              <Fieldset legend={`Clinic ${pluralize("Address", clinic.addresses.length)}`}>
                {clinic.addresses.map((address, index) => {
                  return (
                    <div key={index}>
                      <h5>{`Address ${index + 1}`}</h5>
                      <Field component={TextInput} label="Street 1" name={`addresses[${index}].street`} readOnly />
                      <Field component={TextInput} label="Street 2" name={`addresses[${index}].street2`} readOnly />
                      <Row>
                        <Col>
                          <Field component={TextInput} label="City" name={`addresses[${index}].city`} readOnly />
                        </Col>
                        <Col>
                          <Field component={TextInput} label="State" name={`addresses[${index}].state`} readOnly />
                        </Col>
                        <Col>
                          <Field component={TextInput} label="Zip" name={`addresses[${index}].zip`} readOnly />
                        </Col>
                      </Row>
                    </div>
                  );
                })}
                <Field component={TextInput} label="Contact Phone Number" name="contactPhoneNumber" readOnly />
                <Field component={TextInput} label="Contact Email" name="contactEmail" readOnly />
                <Field component={TextInput} label="Contact Fax Number" name="contactFaxNumber" readOnly />
              </Fieldset>
              {canUpdate ? (
                <Fieldset legend="Clinic Controls">
                  <DetailedErrorAlert error={error} />
                  {message && <Alert color="info">{message}</Alert>}

                  <Field component={Switch} label="seeQer Enabled" name="biCreationEnabled" id="seeqer-enabled" type="checkbox" />
                  <Field component={Switch} label="Prior Auth Enabled" name="paCreationEnabled" id="seerez-enabled" type="checkbox" />
                  <Field component={Switch} label="US Employees Only" name="usOnly" id="us-only" type="checkbox" />
                  <Field component={Switch} label="Ops Do Not Contact" name="opsDoNotContact" id="no-contact" type="checkbox" />
                  <Field
                    component={Switch}
                    label="Notify for Clinic Info Needed"
                    name="notifyForClinicInfoNeeded"
                    id="notify-info"
                    type="checkbox"
                  />
                  <Field component={Switch} label="Show Price Comparison" name="showPriceComparison" id="no-contact" type="checkbox" />
                  <Field component={Switch} label="Shadowbox Enabled" name="shadowboxEnabled" id="shadowbox-enabled" type="checkbox" />
                  <Field
                    component={Switch}
                    label="Display Pa Approval %"
                    name="displayPaApproval"
                    id="display-pa-approval"
                    type="checkbox"
                  />
                  <Field
                    component={Switch}
                    label="Create PA Result Document"
                    name="createPaResultDocument"
                    id="create-pa-result-document"
                    type="checkbox"
                  />
                  <Field component={Switch} label="Enable Discovery" name="discoveryEnabled" id="discovery-enabled" type="checkbox" />
                  <BlankableField component={TextInput} label="Athenahealth Practice ID" name="athenahealthPracticeId" />
                  <BlankableField component={TextInput} label="SSO Redirect URL" name="ssoRedirectUrl" />
                  <Field component={Switch} label="Use Abbreviated PDF" name="useAbbreviatedPdf" id="use-abbreviated-pdf" type="checkbox" />
                </Fieldset>
              ) : null}

              <div className="d-flex flex-row gap-2">
                <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine}>
                  Save
                </LoadingButton>

                <History entityName={"Clinic"} subjectId={clinicId} />
              </div>
            </>
          );
        }}
      </Form>
    </>
  );
};
