import React, { useMemo } from "react";
import { Alert } from "reactstrap";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { TabbedRoutes } from "../../components/TabbedRoutes";
import { RibbonNpiUploadForm } from "./RibbonNpiUploadForm";

const useTabs = () =>
  useMemo(
    () => [
      {
        path: "ribbon",
        label: "Ribbon NPI Upload",
        Component: RibbonNpiUploadForm,
      },
    ],
    []
  );

export const RibbonNpiUploadPage = () => {
  const tabs = useTabs();

  return (
    <SystemConfigLayout title={["Tools", "Ribbon NPI Upload"]}>
      <TabbedRoutes tabs={tabs} defaultTab={"ribbon"} />
    </SystemConfigLayout>
  );
};
