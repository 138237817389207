import { FormText } from "reactstrap";
import { PillToggle } from "../PillToggle";
import { InputWrapper } from "./InputWrapper";

export const PillToggleInput = ({ children, input, label, meta, ...rest }) => (
  <InputWrapper label={label} required={rest.required}>
    <PillToggle {...input} {...rest} invalid={Boolean(meta.touched && meta.error)} />
    {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
    {children}
  </InputWrapper>
);
