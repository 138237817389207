import React from "react";
import { Button } from "reactstrap";

export const DocumentViewer = ({ document }) => {
  const { documentId, mimeType, downloadPath } = document;

  if (mimeType === "application/pdf") {
    return (
      <div className="ratio ratio-4x3">
        <iframe className="embed-responsive-item" src={downloadPath} />
      </div>
    );
  } else if (mimeType.startsWith("image/")) {
    return <img src={downloadPath} className="mw-100" />;
  } else {
    return (
      <Button download herf={downloadPath}>
        Download {documentId}
      </Button>
    );
  }
};
