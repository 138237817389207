import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { useUpdatePermission } from "../../utils";
import { UseLabPricingToggle } from "./UseLabPricingToggle";

export const UseLabPricingActionCell = ({ row }) => {
  const [canUpdate] = useUpdatePermission("ClinicLabPermission", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();
  const { useLabPricing, lab } = row.original ?? {};

  const handleChange = (msg) => {
    setMessage(msg);
    refresh();
  };

  return (
    <UseLabPricingToggle
      clinicLabPermission={row.original}
      labName={lab.labName}
      useLabPricing={useLabPricing}
      onChange={handleChange}
      readOnly={!canUpdate}
    />
  );
};
