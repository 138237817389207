import $ from "jquery";

export function initChangePayor() {
  const priorAuthInput = $("#prior_auth_request_payor_id");
  const changePayorForm = priorAuthInput.closest("form");

  if (changePayorForm) {
    changePayorForm.on("submit", () => {
      const resetAllowed = priorAuthInput.data("resetAllowed");
      const originalId = priorAuthInput.data("originalPayorId");
      const newId = priorAuthInput.val();

      if (resetAllowed && originalId != newId) {
        return confirm(
          "Are you sure?\n\n" +
            "Changing the payor will reset the prior auth workflow and will remove insurance forms currently attached to the case."
        );
      } else {
        return true;
      }
    });
  }
}
