import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../../components/LoadingButton";
import { required } from "../../../../util/validators";
import { adminApi, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";

export const SearchCaseIdForm = ({ onSubmit }) => {
  const [error, setError] = useState(null);

  const onFormSubmit = async ({ id }, form) => {
    try {
      const { data } = await adminApi.get(`/admin/cases/${id}`);

      if (onSubmit) {
        onSubmit(id);
      }

      form.restart();
    } catch (err) {
      setError(err?.response?.status + " " + err?.response?.statusText);
    }
  };

  return (
    <>
      <DetailedErrorAlert error={error} />

      <Form onSubmit={onFormSubmit}>
        {({ handleSubmit, submitting, pristine }) => {
          return (
            <>
              <Field component={TextInput} required validate={required} label={`${window.branding.brandNameShort} Case ID`} name="id" />

              <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine}>
                Search Benefits
              </LoadingButton>
            </>
          );
        }}
      </Form>
    </>
  );
};
