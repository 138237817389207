import React from "react";
import { Field } from "react-final-form";
import { MoneyInput } from "../../../components/inputs/final_form/MoneyInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { PercentInput } from "../../../components/inputs/final_form/PercentInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { atLeastOneFieldWithMoneyRequired, required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const FeeScheduleByPayorForm = ({ handleSubmit, submitting, create = true }) => {
  return (
    <>
      <Field component={TextInput} type="hidden" name="labId" />

      {create && (
        <>
          <Field
            component={AdministrateSelectInput}
            resourceUrl="/admin/payors.json"
            label="Payor"
            name="payorId"
            searchParam="name"
            required
            validate={required}
          />
          <Field
            component={AdministrateSelectInput}
            resourceUrl="/admin/cpt_codes.json"
            label="CPT Code"
            name="cptCodeId"
            required
            validate={required}
          />
        </>
      )}
      <Field component={MoneyInput} validate={atLeastOneFieldWithMoneyRequired("medicarePercentage")} label="Price" name="priceCents" />
      <Field
        component={PercentInput}
        validate={atLeastOneFieldWithMoneyRequired("priceCents")}
        label="Medicare Percentage"
        name="medicarePercentage"
      />
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
