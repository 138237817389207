import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { LoadingButton } from "../../../components/LoadingButton";

export const EditQuickActionModal = ({ clinicOpsManagement, onEdit, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (params) => {
    setError(null);
    try {
      const res = await api.patch(`/admin/clinic_ops_managements/${clinicOpsManagement.id}`, {
        clinic_ops_management: snakifyKeys(params),
      });

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Quick Action Management</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          <Form
            onSubmit={handleEdit}
            initialValues={{
              accountIds: clinicOpsManagement.accounts.map((a) => a.id),
              groupName: clinicOpsManagement.groupName,
            }}
          >
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/accounts.json"
                    label="Users"
                    name="accountIds"
                    labelKey="full_name_or_email"
                    isMulti
                  />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Save
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
