import { Field, Form } from "react-final-form";
import { LoadingButton } from "../../../../components/LoadingButton";
import { required } from "../../../../util/validators";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { useState } from "react";
import { Switch } from "../../../../components/inputs/final_form/Switch";
import { snakifyKeys } from "../../../../util/helpers";
import { Container } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { DetailedAlert } from "../../../../components/DetailedAlert";
import { adminApi, extractErrorMessage } from "../../../../util/api";

export const UIPathSubmitForm = () => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageDetails, setMessageDetails] = useState(null);

  const onSubmit = async (values, form) => {
    const params = {
      caseId: values.caseId,
      followUp: values.followUp,
    };

    setError(null);
    setMessage(null);
    setMessageDetails(null);

    try {
      const { data } = await adminApi.post("/admin/system/tools/send_to_ui_path", snakifyKeys(params));

      setMessage(data.meta.message);
      setMessageDetails(data.meta.error_details);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <Container fluid>
      {!error && (
        <div className="bg-info bg-opacity-10 p-3 rounded-2 mb-3">
          <h4>Hint:</h4>
          <p className="mb-0">Type a Case ID to send it to UIPath Orchestrator.</p>
        </div>
      )}

      <DetailedErrorAlert error={error} />

      <DetailedAlert
        message={message}
        details={messageDetails}
        messageStyle={messageDetails && messageDetails.length > 0 ? "warning" : "info"}
      />

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, pristine }) => {
          return (
            <>
              <Field required validate={required} component={TextInput} label="Case ID" name="caseId" />

              <Field component={Switch} name="followUp" type="checkbox" label="Follow Up" />

              <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine} className="me-2">
                Send to UIPath
              </LoadingButton>
            </>
          );
        }}
      </Form>
    </Container>
  );
};
