import $ from "jquery";
import _ from "lodash";

const getValidSubstatuses = (status, substatuses) => {
  return _.pickBy(substatuses, (subtatusDefinition, _name) => _.includes(subtatusDefinition.include_for_statuses, status));
};

const $emptyOption = $('<option value=""></option>').text("-- Select a Substatus --");

export function initSubstatusSelect() {
  const $statusSelectors = $(".request_status_dropdown");

  if ($statusSelectors.length > 0) {
    $statusSelectors.each(function () {
      attachSubstatusSelect(this);
    });
  }
  $(document).on("repeater.add", function (_e, $new) {
    attachSubstatusSelect($new);
  });
}

function attachSubstatusSelect($container) {
  const $statusDropdown = $(".request_status", $container);
  const $substatusDropdown = $(".request_substatus", $container);
  const $substatusParent = $substatusDropdown.parent();
  const availableSubstatuses = $substatusDropdown.data("allSubstatuses");

  $statusDropdown.on("change", function () {
    const currentStatus = $statusDropdown.val();
    const currentSubstatus = $substatusDropdown.val();

    const validSubstatuses = getValidSubstatuses(currentStatus, availableSubstatuses);

    $substatusDropdown.empty();
    $substatusDropdown.append($emptyOption);

    if (!_.isEmpty(validSubstatuses)) {
      $substatusParent.show();

      // Add options for all valid substatuses
      _.forEach(validSubstatuses, (substatusDefinition, key) => {
        const { name = key } = substatusDefinition;

        const $newOption = $(`<option></option>`, { value: key }).text(name);

        $substatusDropdown.append($newOption);
      });

      // Preserve selection
      $substatusDropdown.val(currentSubstatus);
    } else {
      $substatusDropdown.val("");
      $substatusParent.hide();
    }
  });

  $statusDropdown.trigger("change");
}
