import axios from "axios";
import { debounce } from "lodash";
import React, { Component } from "react";
import { PersistantReactTable } from "../components/grid/PersistantReactTable";
import { api } from "../util/api";
import { encodeTableState } from "../util/table";
import { COLUMNS } from "./columns";

export class PartialClinicMatchesGrid extends Component {
  state = {
    data: [],
    pages: -1,
    loading: false,
    search: "",
  };

  constructor(props) {
    super(props);
    this.table = React.createRef();
    this.fetchData = debounce(this.fetchData, 300);
  }

  updateTableData = () => this.table.updateTableData();

  fetchData = async (tableState = null, _instance) => {
    // show the loading overlay
    this.setState({ loading: true });

    try {
      let dataReq = await api.get("/operations/prior_authorize_requests/clinic_mappings/partial.json", {
        params: tableState,
      });

      this.setState({
        data: dataReq.data.rows,
        pages: dataReq.data.pages,
      });
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e);
      }
    } finally {
      this.setState({ loading: false, tableState });
    }
  };

  onFetchData = async (rawTableState, _instance) => {
    const tableState = encodeTableState(rawTableState, this.state);

    try {
      await this.fetchData(tableState);
    } catch (e) {
      console.error(e);
    }
  };

  onSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => this.updateTableData());
  };

  render() {
    const { data, pages, loading, search } = this.state;

    return (
      <PersistantReactTable
        ref={(i) => (this.table = i)}
        columns={COLUMNS}
        data={data}
        pages={pages}
        loading={loading}
        onFetchData={this.onFetchData}
        defaultSorted={[{ id: "created_at", desc: true }]}
        search={search}
        onSearch={this.onSearchChange}
        title="Partial Clinic Matches"
      />
    );
  }
}
