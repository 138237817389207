import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../../components/AdministrateTable";
import { useDestroyPermission, useUpdatePermission } from "../../../utils";
import { History } from "../../sandbox/release/History";
import { EditSuperDialScriptModal } from "./EditSuperDialScriptModal";

export const SuperDialScriptActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("SuperDialScript", row.original.id);
  const [canUpdate] = useUpdatePermission("SuperDialScript", row.original.id);

  return (
    <>
      {canUpdate && (
        <EditSuperDialScriptModal
          color="link"
          superDialScript={row.original}
          onUpdate={(row) => {
            refresh();
            setMessage(`Updated ${row.script_name} script.`);
          }}
        />
      )}

      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/super_dial_scripts/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Super Dial Script ${row.original.scriptName} script.`);
          }}
          onError={setError}
        />
      )}

      <History entityName="SuperDialScript" subjectId={row.original.id} />
    </>
  );
};
