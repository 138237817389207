import Form from "@rjsf/core";
import React, { useCallback, useEffect, useState } from "react";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { StyledSelect as Select } from "../components/inputs/StyledSelect";
import { LoadingIcon } from "../components/LoadingIcon";
import { api } from "../util/api";
import { getPrecompiledValidator } from "./precompiled_validator";

const defaultSchema = {
  title: "Custom Fields",
  description: "This form has no custom fields",
  type: "object",
  properties: {}, //{ "_p3qcnd3wh": { "type": "string", "title": "New Custom Field" } }
};

const FillForm = ({ schema, uiSchema, schemaFixed, formData, onDataSubmit, onDataDelete, validator }) => (
  <>
    {schemaFixed && (
      <div className="alert alert-info">
        This form has custom fields and data associated with it. To change the form, you need to clear the existing data.{" "}
        <a href="#" className="alert-link" onClick={onDataDelete}>
          Clear Custom Data
        </a>
      </div>
    )}

    <Form
      schema={schema || defaultSchema}
      uiSchema={{
        ...uiSchema,
        "ui:submitButtonOptions": {
          props: {
            className: "mt-3",
          },
        },
      }}
      formData={formData}
      onSubmit={onDataSubmit}
      validator={validator}
    />
  </>
);

export const InsuranceFormFiller = ({
  schemaUrl,
  documentFilled,
  fillableDocuments,
  createDocumentUrl,
  schemaPrecompiledValidatorUrl,
  documentUrl,
  requestId,
  documentId,
  formId,
}) => {
  const schemaFixed = documentFilled;
  const defaultFormConfig = { schema: {}, uiSchema: {}, formData: {} };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFormId, setSelectedFormId] = useState(formId);
  const [formConfig, setFormConfig] = useState(defaultFormConfig);

  const { schema, uiSchema, formData } = formConfig;

  const [precompiledValidator, setPrecompiledValidator] = useState(undefined);

  useEffect(() => {
    const fetchSchema = async () => {
      setLoading(true);
      setError(null);

      try {
        const { data } = await api.get(schemaUrl.replace("__ID__", selectedFormId));
        setFormConfig(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (selectedFormId) {
      fetchSchema();
    }
  }, [selectedFormId, schemaUrl]);

  useEffect(() => {
    const fetchPrecompiledValidator = async () => {
      const newValidator = await getPrecompiledValidator(selectedFormId, schemaPrecompiledValidatorUrl, schema);
      setPrecompiledValidator(newValidator);
    };

    if (selectedFormId) {
      fetchPrecompiledValidator();
    }
  }, [selectedFormId, schemaPrecompiledValidatorUrl, schema]);

  const onDataSubmit = async ({ formData = {} }) => {
    let url, method;
    if (documentId) {
      url = documentUrl.replace("__ID__", documentId);
      method = "put";
    } else {
      url = createDocumentUrl.replace("__ID__", requestId);
      method = "post";
    }

    try {
      await api[method](url, {
        data: formData,
        fillable_document_id: selectedFormId,
      });

      window.location.reload();
    } catch (err) {
      setError(err);
    }
  };

  const onDataDelete = useCallback(async () => {
    if (confirm("Are you sure you want to change forms? All entered form data will be lost.")) {
      setLoading(true);
      try {
        await api.delete(documentUrl.replace("__ID__", documentId));
        window.location.reload();
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
  }, [documentId, setError, setLoading, documentUrl]);

  return (
    <div>
      <DetailedErrorAlert error={error} />

      <div className="mb-2">
        <label htmlFor="fillable">Form</label>

        <Select
          name="fillable"
          placeholder="Insurance Form"
          isSearchable
          value={fillableDocuments.find((d) => d.value == Number(selectedFormId))}
          options={fillableDocuments}
          onChange={(v) => setSelectedFormId(v.value)}
          isDisabled={schemaFixed}
        />
      </div>

      {loading && <LoadingIcon />}

      {schema && !loading && precompiledValidator && (
        <FillForm
          schema={schema}
          uiSchema={uiSchema}
          formData={formData}
          onDataSubmit={onDataSubmit}
          schemaFixed={schemaFixed}
          onDataDelete={onDataDelete}
          validator={precompiledValidator}
        />
      )}
    </div>
  );
};
