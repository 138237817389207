import React from "react";
import { ActionAlert } from "../../../../components/ActionAlert";
import { LoadingButton } from "../../../../components/LoadingButton";
import { WizardContext } from "../../WizardContext";

export const ExitClinicWorkflowScreen = () => (
  <WizardContext.Consumer>
    {({ loading, exitWorkflow, state, createMiContactAttempt, setError, caseId }) => {
      const handleContinue = async () => {
        setError(null);

        try {
          await createMiContactAttempt(state.answers.miType);
          await exitWorkflow(`Automatic MI Followup Started for ${caseId}`);
        } catch (err) {
          console.error(err);
          setError(`Unexpected Error: ${err.message}`);
        }
      };

      return (
        <ActionAlert
          color="warning"
          actions={
            <>
              <LoadingButton color="warning" loading={loading} onClick={handleContinue}>
                Continue
              </LoadingButton>
            </>
          }
        >
          This case still has unresolved missing information. Automated followup emails will be generated for the next 5 business days.
        </ActionAlert>
      );
    }}
  </WizardContext.Consumer>
);
