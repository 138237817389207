import React, { useCallback } from "react";
import { Container, Row } from "reactstrap";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable } from "../../components/AdministrateTable";
import { DESTROY, UPDATE, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { PaperResponseActionCell } from "./components/PaperResponseActionCell";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AddPaperResponseModal } from "./components/AddPaperResponseModal";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { ImportPaperResponseModal } from "./components/ImportPaperResponseModal";
import { StatusDisplayer, SubstatusDisplayer } from "../phone_responses/PhoneResponsesIndex";

const PayorNamesDisplayer = ({ payors }) => {
  return (
    <>
      {payors &&
        payors.map((payor) => (
          <li key={`payor-${payor.id}`} style={{ listStyle: "none" }} className="token">
            <a target="_blank" rel="noopener noreferrer" href={`/admin/system/payors/${payor.id}/general`}>
              {payor.name}
            </a>
          </li>
        ))}
      {_.isEmpty(payors) && (
        <li style={{ listStyle: "none" }} className="token">
          ALL PAYORS
        </li>
      )}
    </>
  );
};

const PortalNameDisplayer = ({ portal }) => {
  return (
    <>
      {portal && (
        <li style={{ listStyle: "none" }} className="token">
          <a target="_blank" rel="noopener noreferrer" href={`/admin/system/portals/${portal.id}/general`}>
            {portal.name}
          </a>
        </li>
      )}
      {!portal && (
        <li style={{ listStyle: "none" }} className="token">
          No Portal
        </li>
      )}
    </>
  );
};

const useColumns = makeColumns(() => [
  {
    id: "message",
    accessor: "message",
    Header: "Message",
    filter: "text",
  },
  {
    id: "submit_right_direction",
    accessor: (row) => {
      switch (row.submitRightDirection) {
        case "re_send_fax":
          return "Re Send Fax";
        case "status_the_case":
          return "Status The Case";
        case "task_out_for_n_number_of_days_to_try_again":
          return "Task Out For N Number of Days To Try Again";
        case "resubmit_to_portal":
          return "Re Submit To Portal";
      }
    },
    Header: "Submit Right Direction",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl={"/admin/enums/paper_responses_submit_right_direction_options.json"}
        valueKey="id"
        labelKey="label"
      />
    ),
  },
  {
    id: "payors",
    accessor: "payors",
    Header: "Verified Payor Name",
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" isMulti valueKey="id" searchParam="name" />,
    Cell: (props) => <PayorNamesDisplayer payors={props.value} />,
  },
  {
    id: "benefitManagementPortal",
    accessor: "benefitManagementPortal",
    Header: "Portal",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/benefit_management_portals.json" valueKey="id" searchParam="name" />
    ),
    Cell: (props) => <PortalNameDisplayer portal={props.value} />,
  },
  {
    id: "status",
    accessor: "status",
    Header: "Status",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/enums/prior_auth_statuses.json" valueKey="id" labelKey="label" />
    ),
    Cell: (props) => <StatusDisplayer status={props.value || "none"} />,
  },
  {
    id: "substatus",
    accessor: "substatus",
    Header: "Substatus",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/enums/prior_auth_substatuses.json" valueKey="value" labelKey="label" />
    ),
    Cell: (props) => <SubstatusDisplayer substatus={props.value || "none"} />,
  },
  {
    id: "canned_comment",
    accessor: "cannedComment",
    Header: "Canned Comment",
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: PaperResponseActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PaperResponsesIndex = () => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  const columns = useColumns();
  const [canCreate] = useCreatePermission("PaperResponse");

  const onAdd = useCallback(
    (message, errorDetails = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, errorDetails.length ? "warning" : "success", errorDetails);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Paper Responses">
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Toolbar className="ml-auto">
            {canCreate && (
              <AdministrateBackgroundExportButton
                outline
                className="me-2"
                exportUrl="/admin/paper_responses/export"
                filters={allFilters}
                ref={tableRef}
              />
            )}

            {canCreate && <ImportPaperResponseModal outline className="me-2" onImport={onAdd} />}

            {canCreate && <AddPaperResponseModal onAdd={onAdd} color="primary" />}
          </Toolbar>
        </Row>
      </Container>

      <AdministrateTable
        resourceUrl={"/admin/paper_responses.json"}
        resourceName={"paper_response"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
