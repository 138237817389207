import axios from "axios";
import { debounce } from "lodash";
import React, { Component } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown as Dropdown } from "reactstrap";
import { AssignmentModal } from "../components/AssignmentModal";
import { DetailedAlert } from "../components/DetailedAlert";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { PersistantReactTable } from "../components/grid/PersistantReactTable";
import { Switch } from "../components/inputs/Switch";
import { api } from "../util/api";
import { encodeTableState } from "../util/table";

export class WorkflowGrid extends Component {
  state = {
    data: [],
    pages: -1,
    loading: false,
    search: "",
    selection: [],
    hideAssigned: false,
    message: null,
    messageDetails: null,
    totalItems: 0,
  };

  constructor(props) {
    super(props);
    this.table = React.createRef();
    this.fetchData = debounce(this.fetchData, 300);
  }

  updateTableData = () => this.table.updateTableData();

  encodeTableState = (state) => {
    const baseTableState = encodeTableState(state, this.state);
    const { hideAssigned } = this.state;

    const tableState = {
      ...baseTableState,
      hide_done: true,
      hide_assigned: hideAssigned,
    };

    return tableState;
  };

  onFetchData = async (rawTableState, _instance) => {
    const tableState = this.encodeTableState(rawTableState);

    try {
      await this.fetchData(tableState);
    } catch (e) {
      console.error(e);
    }
  };

  fetchData = async (tableState) => {
    // show the loading overlay
    this.setState({ loading: true, error: null });

    try {
      let dataReq = await api.get(this.props.apiUrl, {
        params: tableState,
      });

      this.setState({
        data: dataReq.data.rows,
        pages: dataReq.data.pages,
        totalItems: dataReq.data.total,
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.setState({ error });
      }
    } finally {
      this.setState({ loading: false, tableState });
    }
  };

  onSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => this.updateTableData());
  };

  onToggleHideAssigned = () => {
    const { hideAssigned } = this.state;
    this.setState({ hideAssigned: !hideAssigned }, () => this.updateTableData());
  };

  // Assignment

  assignCases = () => {
    this.setState({ assigningUsers: true });
  };

  assignmentComplete = (_assignee, message, messageDetails) => {
    this.updateTableData();

    // Reset Selected Objects
    this.setState({ selection: [], message, messageDetails }, () => {
      this.table.resetSelection();
    });
  };

  bulkClose = async (closed) => {
    const { selection } = this.state;
    this.setState({ loading: true, error: null });

    try {
      await api.post(this.props.bulkCloseUrl, { case_ids: selection, closed });

      this.updateTableData();

      // Reset Selected Objects
      this.setState({ selection: [] }, () => {
        this.table.resetSelection();
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  };

  bulkEscalate = async (escalate) => {
    const { selection } = this.state;
    this.setState({ loading: true, error: null });
    try {
      await api.post(this.props.bulkEscalateUrl, {
        case_ids: selection,
        escalate,
      });

      this.updateTableData();

      // Reset Selected Objects
      this.setState({ selection: [] }, () => {
        this.table.resetSelection();
      });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  };

  onSelectionChange = (selection) => {
    this.setState({ selection });
  };

  hasSelection = () => {
    return Array.isArray(this.state.selection) && this.state.selection.length > 0;
  };

  getDefaultFiltered = () => {
    const { defaultAssignee } = this.props;
    return defaultAssignee ? [{ id: "assignee", value: defaultAssignee }] : [];
  };

  render() {
    const { data, pages, loading, search, assigningUsers, hideAssigned, message, messageDetails, totalItems } = this.state;
    const { filterOptions, canAssign, columns, canEscalate } = this.props;
    const canEscalateBool = canEscalate.toLowerCase() === "true";

    return (
      <>
        <DetailedAlert message={message} details={messageDetails} messageStyle={messageDetails ? "warning" : "info"} />
        <DetailedErrorAlert error={this.state.error} />

        <PersistantReactTable
          checkboxGrid
          keyField="public_id"
          ref={(i) => (this.table = i)}
          columns={columns(filterOptions)}
          data={data}
          pages={pages}
          loading={loading}
          getTrProps={this.rowProps}
          onFetchData={this.onFetchData}
          onSelectionChange={this.onSelectionChange}
          getDefaultFiltered={this.getDefaultFiltered}
          onSearch={this.onSearchChange}
          search={search}
          title="Active Cases"
          totalItems={totalItems}
          toolbarItems={
            <>
              {canAssign && (
                <Dropdown>
                  <DropdownToggle color="primary" size="sm" outline disabled={!this.hasSelection()} caret>
                    Bulk Actions
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.assignCases}>Assign Selected Cases</DropdownItem>
                    <DropdownItem divider></DropdownItem>
                    <DropdownItem onClick={() => this.bulkClose(true)}>Close Selected Cases</DropdownItem>
                    <DropdownItem divider></DropdownItem>
                    <DropdownItem disabled={!canEscalateBool} onClick={() => this.bulkEscalate(true)}>
                      Escalate Selected Cases
                    </DropdownItem>
                    <DropdownItem disabled={!canEscalateBool} onClick={() => this.bulkEscalate(false)}>
                      Deescalate Selected Cases
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}

              {canAssign && (
                <Switch
                  id="hide-assigned-toggle"
                  label="Hide Assigned"
                  checked={hideAssigned}
                  className="ms-2"
                  disabled={loading}
                  onChange={this.onToggleHideAssigned}
                />
              )}
            </>
          }
        />

        <AssignmentModal
          open={assigningUsers}
          caseIds={this.state.selection}
          assignmentUrl={this.props.assignmentUrl}
          toggleModal={() => this.setState({ assigningUsers: !assigningUsers })}
          assignableUsers={filterOptions.assignableUsers}
          onAssign={this.assignmentComplete}
        />
      </>
    );
  }
}
