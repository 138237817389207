import { Link } from "react-router-dom";
import { EnumFilter } from "../../../components/inputs";
import { Toolbar } from "../../../components/Toolbar";
import { LAB_ROLES } from "../../../util/data/global_roles";
import { humanizeString, objectToOptions } from "../../../util/formatters";
import { AdministrateTable } from "../../components/AdministrateTable";
import { Routes } from "../../Routes";
import { makeColumns, useAdministrateTable } from "../../utils";
import { AddMembershipModal } from "../components/AddMembershipModal";
import { MembershipsActionCell } from "../components/MembershipsActionCell";
import { useUpdatePermission } from "../../utils";

const useColumns = makeColumns(() => [
  {
    id: "type",
    accessor: "type",
    Header: "Type",
    Cell: ({ value }) => humanizeString(value),
    disableSortBy: true,
    disableFilters: true,
  },
  {
    id: "name",
    accessor: "name",
    Header: "Name",
    Cell: ({ row, value }) => (
      <Link to={row.original.type === "lab" ? Routes.lab.url(row.original.belongsToId) : Routes.clinic.url(row.original.belongsToId)}>
        {value}
      </Link>
    ),
    disableSortBy: true,
  },
  {
    id: "role",
    accessor: "role",
    Header: "Role",
    Cell: ({ value }) => humanizeString(value ?? "None"),
    Filter: (props) => <EnumFilter {...props} options={objectToOptions(LAB_ROLES)} />,
    disableSortBy: true,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: MembershipsActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const UsersMembershipsTab = ({ accountId }) => {
  const [canUpdate] = useUpdatePermission("Account", accountId);

  const columns = useColumns().filter((col) => canUpdate || col.id !== "actions");
  const { tableRef, setTableFilters } = useAdministrateTable();

  const onAddMembership = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  return (
    <>
      {canUpdate && (
        <Toolbar className="align-items-center mb-3">
          <Toolbar className="ms-auto">
            <AddMembershipModal color="primary" accountId={accountId} onAdd={onAddMembership} />
          </Toolbar>
        </Toolbar>
      )}

      <AdministrateTable
        resourceUrl={`/admin/accounts/${accountId}/memberships.json`}
        resourceName={"memberships"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
