import { createRoot } from "react-dom/client";
import { ExportForm } from "../dashboard/export_modal/ExportForm";

export function initDashboardExportModal() {
  const container = document.getElementById("export-modal-body");

  if (container) {
    const { exportUrl, initialValues, statusesOptions, sourcesOptions } = container.dataset;

    const parsedInitialValues = initialValues ? JSON.parse(initialValues) : {};
    const parsedStatusesOptions = statusesOptions ? JSON.parse(statusesOptions) : [];
    const parsedSourcesOptions = sourcesOptions ? JSON.parse(sourcesOptions) : [];

    const root = createRoot(container);

    root.render(
      <ExportForm
        exportUrl={exportUrl}
        initialValues={parsedInitialValues}
        statusesOptions={parsedStatusesOptions}
        sourcesOptions={parsedSourcesOptions}
      />
    );
  }
}
