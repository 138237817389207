import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { TabbedRoutes } from "../components/TabbedRoutes";
import { Routes } from "../Routes";
import { useAdminResource } from "../utils";
import { PoliciesGeneralTab } from "./tabs/PoliciesGeneralTab";
import { PoliciesCodesTab } from "./tabs/PoliciesCodesTab";
import { PoliciesPayorsTab } from "./tabs/PoliciesPayorsTab";

const useBreadcrumbs = (pageTitle, policyId) =>
  useMemo(
    () => [
      { url: Routes.policies.url(), label: Routes.policies.label },
      { url: Routes.policy.url(policyId), label: pageTitle, active: true },
    ],
    [pageTitle, policyId]
  );

const usePolicy = (policyId) => useAdminResource(`/admin/policies/${policyId}.json`);

const TABS = [
  {
    path: "general",
    label: "General",
    Component: PoliciesGeneralTab,
  },
  {
    path: "codes",
    label: "Codes",
    Component: PoliciesCodesTab,
  },
  {
    path: "payors",
    label: "Payors",
    Component: PoliciesPayorsTab,
  },
];

export const PoliciesShowPage = () => {
  const { policyId } = useParams();
  const { resource: policy, error, isLoading, fetch } = usePolicy(policyId);
  const pageTitle = policy ? policy.name : `Policy #${policyId}`;
  const breadcrumbs = useBreadcrumbs(pageTitle, policyId);

  return (
    <SystemConfigLayout title={pageTitle} showBreadcrumbs crumbs={breadcrumbs} loading={isLoading}>
      {error && <Alert color="danger">{error}</Alert>}

      <TabbedRoutes tabs={TABS} defaultTab={"general"} policy={policy} policyId={policyId} refresh={fetch} />
    </SystemConfigLayout>
  );
};
