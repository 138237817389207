import classNames from "classnames";
import React from "react";

export const Token = ({ children, className, ...rest }) => {
  return (
    <span className={classNames(className, "token")} {...rest}>
      {children}
    </span>
  );
};
