import _ from "lodash";
import { Button, Card, CardBody, Collapse } from "reactstrap";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { LoadingButton } from "../../../components/LoadingButton";
import { mapStringsToOptions } from "../../../util/forms";
import { ChevronDown, ChevronUp, MinusIcon } from "lucide-react";
import { required } from "../../../util/validators";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { useMemo, useState } from "react";

const payorPaths = window.payorPaths;

export const PayorPathForm = ({ letterOptions, onSubmit, subject = null, mode = null }) => {
  const [collapse, setCollapse] = useState(false);
  const initialValues = useMemo(() => (subject ? { letters: subject.path.split("") } : { letters: [""] }), [subject]);

  const toggle = () => setCollapse(!collapse);
  const toggleIcon = collapse ? <ChevronUp /> : <ChevronDown />;

  const addFields = (fields) => {
    if (fields.length <= 9) {
      fields.push("");
    }
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <h5>Choose Letters</h5>

            <blockquote>
              The max path <u>length</u> is <strong>10</strong>.
            </blockquote>

            <FieldArray name="letters">
              {({ fields }) => (
                <>
                  <div className="d-flex flex-row justify-content-center bg-light p-2 mb-3 rounded-2 gap-1">
                    {fields.map((name, index) => (
                      <div className="d-flex flex-column align-items-center" key={`letters[${index}]`}>
                        <Field
                          component={(props) => <SelectInput options={mapStringsToOptions(letterOptions.current)} {...props} />}
                          name={name}
                          defaultValue={name}
                          validate={required}
                          required
                        />

                        <button
                          type="button"
                          onClick={() => fields.remove(index)}
                          className="mb-0 border-0 w-full rounded-pill bg-danger pa-1"
                        >
                          <MinusIcon size={16} color="white" />
                        </button>
                      </div>
                    ))}
                    {fields.length === 0 && <div>No Letters for now...</div>}
                  </div>

                  <Collapse isOpen={collapse}>
                    <Card>
                      <CardBody>
                        <ul className="d-flex flex-column align-items-between flex-wrap mb-0" style={{ maxHeight: "100px" }}>
                          {payorPaths.map((path) => (
                            <li>
                              {path.label} - {path.abbreviation}
                            </li>
                          ))}
                        </ul>
                      </CardBody>
                    </Card>
                  </Collapse>

                  <div className="d-flex flex-row justify-content-between mt-3">
                    <Button title="Add a Letter to this combination." onClick={() => addFields(fields)}>
                      Add Letter
                    </Button>

                    <Button onClick={toggle} title={collapse ? "Hide Options" : "Show options"} size="xs">
                      {toggleIcon}
                    </Button>

                    <div className="ms-4">
                      <LoadingButton title="Save this Path" loading={submitting} onClick={handleSubmit} disabled={submitting}>
                        Save
                      </LoadingButton>
                    </div>
                  </div>
                </>
              )}
            </FieldArray>
          </form>
        );
      }}
    </Form>
  );
};
