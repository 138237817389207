import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { atLeastOneFieldRequired, required } from "../../../util/validators";
import { ImportModal } from "../../components/ImportModal";

export const ImportPlanTypeNetworkStatusSettingsModal = ({ onImport, labId, payorId, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      labId: values.labId,
      payorId: values.payorId,
      file: {
        bytes: values.file,
      },
    };

    const res = await api.post(`/admin/plan_type_network_settings/import.json`, snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message, res.data?.meta?.error_details);
    }
  };

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Add Plan Type Network Statuses from CSV..."
      initialValues={{ labId, payorId }}
      exampleDownloadPath="/admin/plan_type_network_settings/example"
    >
      {(formProps) => (
        <>
          <Field component={TextInput} name="labId" type="hidden" validate={atLeastOneFieldRequired("payorId")} />
          <Field component={TextInput} name="payorId" type="hidden" validate={atLeastOneFieldRequired("labId")} />
        </>
      )}
    </ImportModal>
  );
};
