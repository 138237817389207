import flatpickr from "flatpickr";
import moment from "moment-timezone";
import $ from "jquery";

export function initDatepicker() {
  flatpickr(".datepicker", {
    altInput: true,
    altFormat: "m/d/Y",
    dateFormat: "Y-m-d",
  });

  flatpickr(".datepicker-input", {
    allowInput: true,
    parseDate: (str) => moment(str).toDate(),
    altInput: true,
    altFormat: "m/d/Y",
    dateFormat: "Y-m-d",
  });

  flatpickr(".task-datepicker", {
    allowInput: false,
    parseDate: (str) => moment(str).toDate(),
    altInput: true,
    altFormat: "m/d/Y",
    dateFormat: "Y-m-d",
    minDate: "today",
    maxDate: moment().add(1, "years").tz("America/New_York").toDate(),
  });

  var startDatePicker = flatpickr(".case-search-start-date", {
    allowInput: false,
    parseDate: (str) => moment(str).toDate(),
    altInput: true,
    altFormat: "m/d/Y",
    dateFormat: "Y-m-d",
    onReady: (dates, currentdatestring, picker) => {
      if (dates.length < 1) {
        picker.setDate(moment().subtract(30, "days").tz("America/New_York").toDate());
      }
    },
    onChange: (val, str) => {
      const validEndDateRange = moment(str).add(90, "days").format("YYYY-MM-DD");
      const endDate = $(".case-search-end-date").val();

      if (moment(endDate).isAfter(validEndDateRange)) {
        endDatePicker.setDate(validEndDateRange);
      }

      if (moment(str).isAfter(endDate)) {
        startDatePicker.setDate(endDate);
      }
    },
  });

  var endDatePicker = flatpickr(".case-search-end-date", {
    allowInput: false,
    parseDate: (str) => moment(str).toDate(),
    altInput: true,
    altFormat: "m/d/Y",
    dateFormat: "Y-m-d",
    onReady: (dates, currentdatestring, picker) => {
      if (dates.length < 1) {
        picker.setDate("today");
      }
    },
    onChange: (val, str) => {
      const validStartDateRange = moment(str).subtract(90, "days").format("YYYY-MM-DD");
      const startDate = $(".case-search-start-date").val();

      if (moment(startDate).isBefore(validStartDateRange)) {
        startDatePicker.setDate(validStartDateRange);
      }

      if (moment(str).isBefore(startDate)) {
        endDatePicker.setDate(startDate);
      }
    },
  });
}

export function initDatepickerForElement(e) {
  flatpickr(e, {
    allowInput: true,
    parseDate: (str) => moment(str).toDate(),
  });
}
