import { useCallback } from "react";
import { Link } from "react-router-dom";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateExportButton } from "../../components/AdministrateExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { Routes } from "../../Routes";
import { makeColumns, useAdministrateTable, useUpdatePermission } from "../../utils";
import { AddPayorGroupMemberModal } from "../components/AddPayorGroupMemberModal";
import { ImportPayorGroupMembersModal } from "../components/ImportPayorGroupMembersModal";
import { PayorGroupPayorActionCell } from "../components/PayorGroupPayorActionCell";

const useColumns = makeColumns((payorGroupId) => [
  {
    id: "public_id",
    accessor: "publicId",
    Header: "Public ID",
    Cell: TokenCell,
  },
  {
    id: "name",
    accessor: "name",
    Header: "Name",
    Cell: ({ row, value }) => (
      <Link to={Routes.payor.url(row.original.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <PayorGroupPayorActionCell payorGroupId={payorGroupId} {...row} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PayorGroupsPayorsTab = ({ payorGroup, payorGroupId }) => {
  const columns = useColumns(payorGroupId);
  const [canUpdate] = useUpdatePermission("PayorGroup", payorGroupId);

  const extraParams = { payor_groups: { id: payorGroupId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onImport = useCallback(
    (message, details = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, details.length ? "warning" : "success", details);
    },
    [tableRef]
  );

  const onAdd = useCallback(() => {
    tableRef.current?.refresh?.();
    tableRef.current?.setMessage?.(`New Payors Added.`);
  }, [tableRef]);

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateExportButton outline className="me-2" exportUrl={`/admin/payor_groups/${payorGroupId}/payors.csv`} />
          {canUpdate && (
            <>
              <ImportPayorGroupMembersModal payorGroup={payorGroup} outline className="me-2" onImport={onImport} />
              <AddPayorGroupMemberModal payorGroup={payorGroup} onAdd={onAdd} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/payors.json"}
        extraParams={extraParams}
        resourceName={"payor"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
