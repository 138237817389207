import React, { useMemo } from "react";
import { UncontrolledTooltip as Tooltip } from "reactstrap";

export const TooltipCell = ({ row, value, column }) => {
  const { index } = row;
  const id = useMemo(() => `_${column.id}_${index}`, [column.id, index]);

  return (
    <>
      <span id={id}>{value}</span>

      {value && (
        <Tooltip trigger="hover" target={id} delay={300} offset={[0, 7]}>
          {value}
        </Tooltip>
      )}
    </>
  );
};
