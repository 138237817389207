import React, { useState } from "react";
import { Field } from "react-final-form";
import { Col, Row } from "reactstrap";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required, atLeastOneFieldRequired } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { DropzoneInput } from "../../../components/inputs/final_form/DropzoneInput";
import { Switch } from "../../../components/inputs/final_form/Switch";

export const ReportDeliveryForm = ({ handleSubmit, submitting, deliveryMethod = null }) => {
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(deliveryMethod);

  const onDeliveryMethodChange = (selected) => {
    setSelectedDeliveryMethod(selected.value);
  };

  return (
    <>
      <Row>
        <Col>
          <Field component={TextInput} required validate={required} name="labSettingId" readOnly type="hidden" />
          <Field
            component={AdministrateSelectInput}
            resourceUrl="/admin/report_delivery_configs/report_type_options.json"
            label="Report Type"
            name="reportType"
            valueKey="value"
            labelKey="label"
            required
            validate={required}
          />
          <Field
            component={AdministrateSelectInput}
            resourceUrl="/admin/report_delivery_configs/delivery_method_options.json"
            label="Delivery Method"
            name="deliveryMethod"
            valueKey="value"
            labelKey="label"
            required
            validate={required}
            onUpdate={onDeliveryMethodChange}
          />
          <Field
            type="checkbox"
            component={Switch}
            required
            label="Auto delivery Enabled?"
            name="autoDeliveryEnabled"
            id="autoDeliveryEnabled"
          />
          {selectedDeliveryMethod === "email" && (
            <Field component={TextInput} required validate={required} label="Email Address" name="emailAddress" />
          )}
          {selectedDeliveryMethod === "sftp" && (
            <>
              <Field component={TextInput} required validate={required} label="Hostname" name="sftpHost" />
              <Field component={TextInput} required validate={required} label="Port" name="sftpPort" type="number" min="1" max="65535" />
              <Field component={TextInput} required validate={required} label="Username" name="sftpUsername" />
              <Field
                component={TextInput}
                required
                validate={atLeastOneFieldRequired("sftpKeyfile")}
                type="password"
                label="Password"
                name="sftpPassword"
              />
              <Field component={TextInput} required validate={required} label="Path" name="sftpPath" />
              <Field
                required
                validate={atLeastOneFieldRequired("sftpPassword")}
                component={DropzoneInput}
                accept={null}
                hint="Drop Keyfile"
                label="Keyfile"
                name="sftpKeyfile"
              />
            </>
          )}
          {selectedDeliveryMethod === "account" && (
            <Field
              component={AdministrateSelectInput}
              resourceUrl="/admin/accounts.json"
              valueKey="id"
              labelKey="full_name_or_email"
              label="Recipient"
              name="recipientId"
              required
              validate={required}
            />
          )}
          {selectedDeliveryMethod === "box" && (
            <Field component={TextInput} required validate={required} label="Box Folder Id" name="boxFolderid" />
          )}
        </Col>
      </Row>
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
