import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { useCreatePermission, useDestroyPermission } from "../../utils";
import { ClonePayorButton } from "./ClonePayorButton";
import { DiscardPayorModal } from "./DiscardPayorModal";

export const PayorActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canCreate] = useCreatePermission("Payor");
  const [canDestroy] = useDestroyPermission("Payor", row.original.id);

  const onPayorDelete = () => {
    setMessage(`Deleted ${row.original.name}`);
    refresh();
  };

  const handleCloneSuccess = () => {
    setMessage(`Started Cloning Payor ${row.original.name} and you will receive an email for cloned payor`);
    refresh();
  };

  return (
    <>
      {canCreate && <ClonePayorButton color="link" onClone={handleCloneSuccess} onError={setError} payorId={row.original.id} />}
      {canDestroy && <DiscardPayorModal payorId={row.original.id} onDelete={onPayorDelete} color="link" className="text-danger" />}
    </>
  );
};
