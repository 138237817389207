import React from "react";

import { Col, Row } from "reactstrap";

import AddressesGrid from "./AddressesGrid";
import DetailCard from "./DetailCard";
import ProviderGrid from "./ProviderGrid";

import { createRoot } from "react-dom/client";
import { PageHeader } from "../components/PageHeader";
import { api } from "../util/api";
import { inlineAddress } from "../util/formatters";

class Clinic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      clinicData: null,
    };
  }

  componentDidMount = async () => {
    try {
      const data = await api.get(this.props.clinicUrl);
      this.setState({
        loaded: true,
        clinicData: data.data,
      });
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <>
        <PageHeader
          header={this.state.loaded ? this.state.clinicData.clinic_name : "Loading..."}
          subContent={
            this.state.loaded &&
            this.state.clinicData.primary_or_first_address && (
              <>Primary Address: {inlineAddress(this.state.clinicData.primary_or_first_address)}</>
            )
          }
        />
        <div>
          <Row>
            <Col className="col-9">
              <DetailCard clinicData={this.state.clinicData} loaded={this.state.loaded} />
            </Col>
          </Row>
          <Row>
            <ProviderGrid dataUrl={this.props.clinicProvidersUrl} />
          </Row>
          <Row>
            <AddressesGrid dataUrl={this.props.clinicAddressesUrl} />
          </Row>
        </div>
      </>
    );
  }
}

export const initClinicShow = () => {
  const container = document.getElementById("clinic_show");

  if (container) {
    const root = createRoot(container);
    const clinicUrl = container.dataset.clinicUrl || "";
    const clinicProvidersUrl = container.dataset.clinicProvidersUrl || "";
    const clinicAddressesUrl = container.dataset.clinicAddressesUrl || "";

    root.render(<Clinic clinicUrl={clinicUrl} clinicProvidersUrl={clinicProvidersUrl} clinicAddressesUrl={clinicAddressesUrl} />);
  }
};
