import { useCallback } from "react";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { ImportModal } from "../../components/ImportModal";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { makeColumns, useAdministrateTable } from "../../utils";
import { AddGlossaryModal } from "./components/AddGlossaryModal";
import { GlossaryActionCell } from "./components/GlossaryActionCell";

const useColumns = makeColumns(() => [
  {
    id: "term",
    accessor: "term",
    Header: "Glossary Term",
  },
  {
    id: "definition",
    accessor: "definition",
    Header: "Definition",
  },
  {
    id: "clinical_glossary_aliases",
    accessor: "clinicalGlossaryAliases",
    Header: "Aliases",
    Cell: ({ row, value }) => {
      return (
        <ul>
          {value.map((r) => (
            <li key={r.id}>{r.term}</li>
          ))}
        </ul>
      );
    },
    disableSortBy: true,
  },
  {
    id: "actions",
    accessor: "Actions",
    Cell: GlossaryActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const ClinicalGlossaryIndex = () => {
  const columns = useColumns();
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  const onImport = useCallback(
    (message, details = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, details.length ? "warning" : "success", details);
    },
    [tableRef]
  );

  const onAdd = useCallback(
    (values) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`New Glossary Term "${values.term}" Added.`);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Clinical Glossary Management">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/clinical_glossaries/export"
            filters={allFilters}
            ref={tableRef}
          />
          <ImportModal
            buttonProps={{ className: "me-2", outline: true }}
            onImport={onImport}
            exampleDownloadPath="/admin/clinical_glossaries/example"
          />
          <AddGlossaryModal onAdd={onAdd} color="primary" />
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/clinical_glossaries.json"}
        resourceName={"clinical_glossary"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
