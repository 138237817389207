import { Link } from "react-router-dom";
import { CountCell } from "../../../components/grid/cells/CountCell";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { Routes } from "../../Routes";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreateSeeqerReflexMappingModal } from "./components/CreateSeeqerReflexMappingModal";
import { ImportSeeqerReflexMappingsModal } from "./components/ImportSeeqerReflexMappingsModal";
import { SeeqerReflexMappingActionCell } from "./components/SeeqerReflexMappingActionCell";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    Cell: TokenCell,
  },
  {
    id: "lab",
    accessor: "lab",
    Header: "Lab",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/labs.json?lab[direction]=asc&lab[order]=lab_name&per_page=100"
        valueKey="lab_name"
        labelKey="lab_name"
      />
    ),
    Cell: ({ row, value }) => (
      <Link to={Routes.lab.url(value.id)}>
        <span className="token">{value.labName}</span>
      </Link>
    ),
  },
  {
    id: "payor",
    accessor: "payor.name",
    Header: "Payor",
    disableSortBy: true,
    Cell: ({ row, value }) =>
      value ? (
        <Link to={Routes.payor.url(row.original.payor?.id)}>
          <span className="token">{value}</span>
        </Link>
      ) : (
        <span className="token">ALL PAYORS</span>
      ),
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" isMulti valueKey="id" labelKey="name" searchParam="name" />
    ),
  },
  {
    id: "tests",
    accessor: (x) => x.tests.length,
    Header: "Verified Test Names",
    Cell: ({ value }) => (value ? <CountCell count={value} label="Tests" /> : <span className="token">ALL TESTS</span>),
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/tests.json?test[direction]=asc&test[order]=commercial_name"
        isMulti
        valueKey="commercial_name"
        labelKey="commercial_name"
      />
    ),
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: SeeqerReflexMappingActionCell,
    disableFilters: true,
    disableSortBy: true, //action cell
  },
]);

export const SeeqerReflexMappingIndexPage = () => {
  const [canCreate] = useCreatePermission("SeeqerReflexMapping");

  const columns = useColumns();

  const onCreate = (newSeeqerReflexMapping) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created ${newSeeqerReflexMapping.dashboard_display_name}`);
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  return (
    <SystemConfigLayout title="seeQer Reflex Status Mappings">
      <i>
        If a path is met by one of the seeQer reflex mappings listed, do not reflex to PA. Path examples: Lab + Specific Payor, Lab +
        Specific Test, All Payors + Specific Test. Even if a lab is configured for "Always Requires PA" - if this mapping exists, we will
        still prevent reflexing to PA.
      </i>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/seeqer_reflex_mappings/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreate && (
            <>
              <ImportSeeqerReflexMappingsModal outline className="me-2" onImport={onImport} />
              <CreateSeeqerReflexMappingModal color="primary" onCreate={onCreate} className="me-2" />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/seeqer_reflex_mappings.json"}
        resourceName={"seeqer_reflex_mapping"}
        onFilteredChange={setTableFilters}
        permissions={[UPDATE, DESTROY]}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
