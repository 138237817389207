import axios from "axios";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { PersistantReactTable } from "../components/grid/PersistantReactTable";
import { Switch } from "../components/inputs/Switch";
import { api } from "../util/api";
import { encodeTableState } from "../util/table";
import { DASHBOARD_COLUMNS } from "./columns";
import { useDebounced } from "../util/hooks";

export const DashboardGrid = ({
  startDate,
  endDate,
  archivedCases,
  caseType,
  apiUrl,
  payors,
  searchableStatuses,
  showProvidedPayor,
  isPharmacyClinicUser,
  totalItems: defaultTotalItems,
}) => {
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [needsAction, setNeedsAction] = useState(false);
  const [hideDone, setHideDone] = useState(false);
  const [totalItems, setTotalItems] = useState(defaultTotalItems);

  const table = useRef();

  const onToggleNeedsAction = () => {
    setNeedsAction((needsAction) => !needsAction);
  };

  const onFetchData = useDebounced(
    useCallback(
      async (rawTableState, _instance) => {
        setLoading(true);

        const tableState = {
          ...encodeTableState(rawTableState),
          needs_action: needsAction ? needsAction : undefined,
          hide_done: hideDone,
          hide_archived: !archivedCases,
          only_archived: archivedCases,
          start_date: startDate ? startDate : undefined,
          end_date: endDate ? endDate : undefined,
          case_type: caseType ? caseType : undefined,
        };

        try {
          let dataReq = await api.get(apiUrl, {
            params: {
              response_format: "api",
              ...tableState,
            },
          });

          setData(dataReq.data.rows);
          setPages(dataReq.data.pages);
          setTotalItems(dataReq.data.total);
        } catch (e) {
          if (!axios.isCancel(e)) {
            console.error(e);
          }
        } finally {
          setLoading(false);
        }
      },
      [needsAction, hideDone, archivedCases, startDate, endDate, caseType, apiUrl]
    ),
    300
  );

  useEffect(() => {
    table.current.updateTableData();
  }, [onFetchData]);

  const onToggleHideDoneHideArchived = () => {
    setHideDone((hideDone) => !hideDone);
  };

  return (
    <PersistantReactTable
      keyField="public_id"
      ref={table}
      columns={DASHBOARD_COLUMNS(payors, searchableStatuses, showProvidedPayor, caseType, isPharmacyClinicUser)}
      data={data}
      pages={pages}
      loading={loading}
      pageSize={10}
      totalItems={totalItems}
      title="All Cases"
      onFetchData={onFetchData}
      showSearch={false}
      toolbarItems={
        <>
          <Switch
            id="hide-done-toggle"
            className={"me-3"}
            checked={hideDone}
            disabled={loading}
            onChange={onToggleHideDoneHideArchived}
            label="Hide Done"
          />

          <Switch
            className={"me-3"}
            id="needs-action-toggle"
            checked={needsAction}
            disabled={loading}
            onChange={onToggleNeedsAction}
            label="Show Needs Action"
          />
        </>
      }
    />
  );
};
