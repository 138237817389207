import React, { useCallback } from "react";
import { BoolCell } from "../../components/grid/cells/BoolCell";
import { MoneyCell } from "../../components/grid/cells/MoneyCell";
import { TokenCell } from "../../components/grid/cells/TokenCell";
import { BoolFilter } from "../../components/inputs/BoolFilter";
import { Toolbar } from "../../components/Toolbar";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../utils";
import { AddBenefitPullTypeModal } from "./components/AddBenefitPullTypeModal";
import { BenefitPullTypeActionCell } from "./components/BenefitPullTypeActionCell";
import { AdministrateBackgroundExportButton } from "../components/AdministrateBackgroundExportButton";
import { ImportBenefitPullTypeModal } from "./components/ImportBenefitPullTypeModal";
import { AdministrateEnumFilter } from "../components/AdministrateEnumFilter";
import { EnumFilter } from "../../components/inputs/EnumFilter";
import { CountCell } from "../../components/grid/cells/CountCell";
import { History } from "../tools/sandbox/release/History";
import { SPECIALIES } from "../../util/data/specialities";

export const SITE_OF_SERVICE_OPTIONS = [
  { value: "ambulatory_surgical_center", label: "Ambulatory Surgical Center" },
  { value: "physician_office", label: "Physician office" },
  { value: "hospital_outpatient", label: "Hospital Outpatient" },
  { value: "not_applicable", label: "Not Applicable" },
];

function createOptions(objectToMap) {
  return Object.entries(objectToMap).map(([key, value]) => ({
    label: value,
    value: key,
  }));
}

const SPECIALITIES = createOptions(SPECIALIES);

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    disableSortBy: true,
    disableFilters: true,
  },
  {
    id: "site_of_service",
    accessor: (x) => {
      let sos = SITE_OF_SERVICE_OPTIONS.find((el) => el.value === x.siteOfService);
      return sos.label || "None";
    },
    Header: "Site of Service",
    Filter: (props) => <EnumFilter {...props} options={SITE_OF_SERVICE_OPTIONS} />,
  },
  {
    id: "provider_speciality",
    accessor: (x) => {
      return SPECIALIES[x?.providerSpeciality] || "None";
    },
    Header: "Provider Speciality",
    Filter: (props) => <EnumFilter {...props} options={SPECIALITIES} />,
  },
  {
    id: "test.id",
    accessor: "test.commercialName",
    Header: "Test",
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/tests.json" valueKey="id" labelKey="commercial_name" />,
  },
  {
    id: "payors",
    accessor: (x) => x.payors.length,
    Header: "Payors",
    Cell: ({ value }) => (value ? <CountCell count={value} label="Payors" /> : <span className="token">ALL PAYORS</span>),
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" isMulti valueKey="id" labelKey="name" />,
  },
  {
    id: "cpt_codes",
    accessor: (x) => x.cptCodes.length,
    Header: "Procedure Codes",
    Cell: ({ value }) => <CountCell count={value} label="Procedure Codes" />,
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" isMulti valueKey="code" labelKey="code" searchParam="code" />
    ),
  },
  {
    id: "practiceTypeCode",
    accessor: "practiceTypeCode",
    Header: "Practice Type Code",
  },
  {
    id: "practiceTypeObject",
    accessor: "practiceTypeObject",
    Header: "Practice Type Object",
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: BenefitPullTypeActionCell,
    disableFilters: true,
    disableSortBy: false,
  },
]);

export const BenefitPullTypePage = () => {
  const columns = useColumns();
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  const [canCreate] = useCreatePermission("BenefitPullType");

  const onImport = useCallback(
    (message, details = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, details.length ? "warning" : "success", details);
    },
    [tableRef]
  );

  const onCreate = useCallback(
    (values) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`New Benefit Pull Type Added.`);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Benefit Pull Types">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <History entityName="BenefitPullType" className="me-2" />
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/benefit_pull_types/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreate && (
            <>
              <ImportBenefitPullTypeModal outline className="me-2" onImport={onImport} />
              <AddBenefitPullTypeModal color="primary" onCreate={onCreate} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/benefit_pull_types.json"}
        resourceName={"benefit_pull_type"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
