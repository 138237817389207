import React from "react";
import { createRoot } from "react-dom/client";
import CasesGrid from "./shared/CasesGrid";
import { ArchiveButton } from "./shared/ArchiveButton";
import { ACTION_COLUMN } from "./shared/columns";

export const initMissingInfoCases = () => {
  const container = document.getElementById("missing-info-cases");

  if (container) {
    const root = createRoot(container);
    const dataUrl = "missing_info_cases.json";

    const { searchableStatuses, isInternalUser, missingInfoCategories } = container.dataset;
    const statusOptions = searchableStatuses ? JSON.parse(searchableStatuses) : null;
    const missingInfoCategoryOptions = missingInfoCategories ? JSON.parse(missingInfoCategories) : null;

    const filterOptions = { statusOptions, missingInfoCategoryOptions };

    const actions = (row) => {
      return [
        <a key="update_action" href={`${row.original.public_id}/lab_fix`} className="text-secondary btn btn-block border border-0 ps-0">
          Update
        </a>,
        row.original.archived === false && <ArchiveButton id={row.original.public_id} classNames="ps-1" key="archive-action" />,
      ];
    };

    root.render(
      <CasesGrid
        dataUrl={dataUrl}
        title="Missing Info"
        pageContext="missing information"
        filterOptions={filterOptions}
        actionColumn={ACTION_COLUMN(actions)}
        isInternalUser={isInternalUser}
      />
    );
  }
};
