import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { DetailedAlert } from "../../components/DetailedAlert";
import { DetailedErrorAlert } from "../../components/DetailedErrorAlert";
import { LoadingButton } from "../../components/LoadingButton";
import { Toolbar } from "../../components/Toolbar";
import { adminApi, api, extractErrorMessage, redirectTo } from "../../util/api";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { TabbedRoutes } from "../components/TabbedRoutes";
import { Routes } from "../Routes";
import { useAdminResource, useUpdatePermission } from "../utils";
import { UsersGeneralTab } from "./tabs/UsersGeneralTab";
import { UsersMembershipsTab } from "./tabs/UsersMembershipsTab";

const useBreadcrumbs = (pageTitle, accountId) =>
  useMemo(
    () => [
      { url: Routes.users.url(), label: Routes.users.label },
      { url: Routes.user.url(accountId), label: pageTitle, active: true },
    ],
    [pageTitle, accountId]
  );

const useAccount = (accountId) => useAdminResource(`/admin/accounts/${accountId}.json`);

const TABS = [
  {
    path: "general",
    label: "General",
    Component: UsersGeneralTab,
  },
  {
    path: "memberships",
    label: "Memberships",
    Component: UsersMembershipsTab,
  },
];

const Reset2faButton = ({ accountId, onError, onSuccess, ...rest }) => {
  const [loading, setLoading] = useState(false);

  const handleReset = async () => {
    if (!confirm("Are you sure?")) {
      return false;
    }

    setLoading(true);
    onError(null);
    onSuccess(null);

    try {
      await adminApi.post(`/admin/accounts/${accountId}/reset_otp`);
      onSuccess("2FA Reset Successfully");
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton onClick={handleReset} loading={loading} disabled={loading} color="danger" outline {...rest}>
      Reset 2FA
    </LoadingButton>
  );
};

const ImpersonateButton = ({ accountId, ...rest }) => {
  const [loading, setLoading] = useState(false);

  const handleImpersonate = async () => {
    setLoading(true);
    try {
      await api.post(`/users/${accountId}/impersonate.json`);
      redirectTo("/");
    } catch (err) {
      setError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };
  return (
    <LoadingButton onClick={handleImpersonate} loading={loading} disabled={loading} color="primary" outline {...rest}>
      Impersonate
    </LoadingButton>
  );
};

const ReinviteButton = ({ accountId, onError, onSuccess, ...rest }) => {
  const [loading, setLoading] = useState(false);

  const handleReinvite = async () => {
    setLoading(true);
    onError(null);
    onSuccess(null);
    try {
      await adminApi.post(`/admin/accounts/${accountId}/reinvite`);
      onSuccess("User has been reinvited");
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton onClick={handleReinvite} loading={loading} disabled={loading} color="primary" outline {...rest}>
      Reinvite
    </LoadingButton>
  );
};

export const UsersShowPage = () => {
  const { accountId } = useParams();
  const { resource: account, error, isLoading, fetch } = useAccount(accountId);
  const [resetError, setResetError] = useState(null);
  const [message, setMessage] = useState(null);
  const pageTitle = account?.email || `User #${accountId}`;
  const breadcrumbs = useBreadcrumbs(pageTitle, accountId);
  const [canUpdate] = useUpdatePermission("Account", accountId);

  return (
    <SystemConfigLayout title={pageTitle} showBreadcrumbs crumbs={breadcrumbs} loading={isLoading}>
      <DetailedErrorAlert error={error ?? resetError} />
      <DetailedAlert message={message} messageStyle="success" />

      {canUpdate && (
        <Toolbar>
          <Reset2faButton className="me-2" accountId={accountId} onError={setResetError} onSuccess={setMessage} />
          <ReinviteButton className="me-2" accountId={accountId} onError={setResetError} onSuccess={setMessage} />
          <ImpersonateButton className="me-2" accountId={accountId} />
        </Toolbar>
      )}

      <TabbedRoutes tabs={TABS} defaultTab={"general"} account={account} accountId={accountId} refresh={fetch} />
    </SystemConfigLayout>
  );
};
