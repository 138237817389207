import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { FaxForm } from "./FaxForm";

export const FaxModal = ({
  caseId,
  buttonLabel = "Send Fax",
  modalHeader = "Send Fax",
  buttonProps = {},
  modalProps = {},
  disabled = false,
  requireRecipients = true,
  requireAttachments = true,
  initialValues = {},
  onSend,
}) => {
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  return (
    <>
      <Button {...buttonProps} onClick={toggleModal} disabled={disabled}>
        {buttonLabel}
      </Button>

      <Modal isOpen={modal} toggle={toggleModal} {...modalProps} size="lg" backdrop={"static"}>
        <ModalHeader toggle={toggleModal}>{modalHeader}</ModalHeader>
        <ModalBody>
          <FaxForm
            casePublicId={caseId}
            requireRecipients={requireRecipients}
            requireAttachments={requireAttachments}
            initialValues={initialValues}
            onCancel={toggleModal}
            onSend={() => {
              if (onSend) {
                onSend();
              }
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
