import _ from "lodash";
import { isBlank, isNumeric } from "./helpers";

export const required = (value) => (isBlank(value) ? "Required" : undefined);
export const requiredTrue = (value) => (value === true ? undefined : "Required");

export const requiredMoneyPresent = (value) => {
  const req = required(value);
  if (req) {
    return req;
  }

  if (value == "$") {
    return "Required";
  }
};
export const requiredNotNull = (value) => (value != null ? undefined : "Required");
export const numeric = (value) => (isNumeric(value) ? undefined : "Value must be a number");
export const numericOrNull = (value) => (_.isEmpty(value) || isNumeric(value) ? undefined : "Value must be a number");
export const numericInRange = (min, max) => (value) =>
  _.isEmpty(value) || ([value, min, max].every((v) => isNumeric(v)) && value >= min && value <= max)
    ? undefined
    : `Value must be between ${min} and ${max}`;
export const naiveUrl = (value) => (value.startsWith("http") ? undefined : "Your URL must start with http:// or https://");

export const atLeastOneFieldRequired = (other) => (value, allValues) =>
  !value && !allValues[other] ? "At least one field is required" : undefined;

export const atLeastOneOfMultipleFieldsRequired = (other) => (value, allValues) =>
  !value && !other.some((k) => k in allValues) ? "At least one field is required" : undefined;

export const atLeastOneFieldWithMoneyRequired = (other) => (value, allValues) =>
  !(!requiredMoneyPresent(value) || !requiredMoneyPresent(allValues[other])) ? "At least one field is required" : undefined;

export const atLeastTwoValuesMustBeSelected = (value) => (value?.length >= 2 ? undefined : "At least two values must be selected");

export const distinctValues = (path, key) => (value, allValues) => {
  const collection = _.get(allValues, path, []);
  const countsByKey = _.countBy(collection, key);
  const count = _.get(countsByKey, value, 0);
  return count > 1 ? "Value must be unique." : undefined;
};

export const valueInOptions = (options) => (value) =>
  _.some(options, (o) => o.value === value) ? undefined : `Please select from available options`;

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);
