import React, { useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { api, extractErrorMessage } from "../../../../util/api";
import { camelizeKeys, snakifyKeys } from "../../../../util/helpers";
import { useUpdatePermission } from "../../../utils";
import { ProviderPayorNumbersForm } from "./ProviderPayorNumbersForm";
import { ProviderPayorNumberReadOnly } from "./ProviderPayorNumberReadOnly";

export const EditProviderPayorNumberModal = ({ providerPayorNumber, onUpdate, ...rest }) => {
  const [canUpdate] = useUpdatePermission("ProviderPayorNumber", providerPayorNumber.id);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleUpdate = async (params) => {
    setError(null);
    try {
      const res = await api.patch(`/admin/provider_payor_numbers/${params.id}`, snakifyKeys(params));

      if (onUpdate) {
        onUpdate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const mode = canUpdate ? "Edit" : "View";

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        {mode}
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{mode} Provider Payor Number</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          {canUpdate ? (
            <Form onSubmit={handleUpdate} initialValues={camelizeKeys({ ...providerPayorNumber })}>
              {(props) => <ProviderPayorNumbersForm {...props} />}
            </Form>
          ) : (
            <ProviderPayorNumberReadOnly providerPayorNumber={providerPayorNumber} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
