import React, { useMemo, useEffect } from "react";
import { Field } from "react-final-form";
import { LoadingButton } from "../components/LoadingButton";
import { required } from "../util/validators";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { DateInput } from "../components/inputs/final_form/DateInput";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { Checkbox } from "../components/inputs/final_form/Checkbox";
import { useState } from "react";
import { OnChange } from "react-final-form-listeners";
import { StatusSelector } from "../components/inputs/StatusSelector";
import { useCase } from "../util/hooks";
import { LoadingIcon } from "../components/LoadingIcon";

export const SubmissionAttemptForm = ({
  cannedResponses,
  handleSubmit,
  initialValues,
  kasePublicId,
  portalId,
  readonlyStatus,
  remainingPortals,
  selectedStatus,
  selectedSubstatus,
  denialReasonCodeOptions,
  skipNoHcpEnrollment,
  submissionType,
  submitPath,
  submitting,
  ...rest
}) => {
  const { value: caseData, loading: loadingCaseData } = useCase(kasePublicId);

  const verifiedCptCodeOptions = useMemo(() => {
    return caseData?.paRequest?.testInfo?.cptCodes?.map((c) => ({ value: c, label: c })) ?? [];
  }, [caseData]);

  // STATE
  const [selectedSuccessfulSubmission, setSelectedSuccessfulSubmission] = useState(null);
  const [selectedStatusOption, setSelectedStatusOption] = useState(null);
  const [approvedCodes, setApprovedCodes] = useState(null);
  const [deniedCodes, setDeniedCodes] = useState(null);

  useEffect(() => {
    setApprovedCodes(verifiedCptCodeOptions);
    setDeniedCodes(verifiedCptCodeOptions);
  }, [verifiedCptCodeOptions]);

  // HANDLERS
  const handleSelectSuccessfulSubmission = (option) => {
    if (option === "Yes") {
      setSelectedSuccessfulSubmission(true);
    } else if (option === "No") {
      setSelectedSuccessfulSubmission(false);
    } else {
      setSelectedSuccessfulSubmission(null);
    }
  };

  // CALLBACK
  const onStatusChangeCallback = (status) => {
    setSelectedStatusOption(status);
  };

  // DATA
  const statusHtmlAttributes = {
    name: "prior_auth_requests_submission_attempt_form[status]",
    id: "prior_auth_requests_submission_attempt_form_status",
  };
  const substatusHtmlAttributes = {
    name: "prior_auth_requests_submission_attempt_form[substatus]",
    id: "prior_auth_requests_submission_attempt_form_substatus",
  };

  if (loadingCaseData) {
    return <LoadingIcon />;
  }

  const handleApprovedChange = (selectedCodes) => {
    const selectedValues = selectedCodes.map((code) => code.value);

    // Filter out these values from the denied codes
    setDeniedCodes(verifiedCptCodeOptions.filter((code) => !selectedValues.includes(code.value)));
  };

  const handleDeniedChange = (selectedCodes) => {
    const selectedValues = selectedCodes.map((code) => code.value);
    // Filter out these values from the approved codes
    setApprovedCodes(verifiedCptCodeOptions.filter((code) => !selectedValues.includes(code.value)));
  };

  return (
    <>
      {/* Successful submission */}
      <Field
        required
        validate={required}
        name="prior_auth_requests_submission_attempt_form[success]"
        id="prior_auth_requests_submission_attempt_form_success"
        component={SelectInput}
        options={[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ]}
        label="Were you able to submit the authorization successfully?"
      />
      <OnChange name="prior_auth_requests_submission_attempt_form[success]">{handleSelectSuccessfulSubmission}</OnChange>

      <hr />

      {selectedSuccessfulSubmission && (
        <>
          {/* Status and Substatus (see StatusSelector component) */}
          <StatusSelector
            caseId={kasePublicId}
            onStatusChangeCallback={onStatusChangeCallback}
            statusHtmlAttributes={statusHtmlAttributes}
            substatusHtmlAttributes={substatusHtmlAttributes}
            required={selectedSuccessfulSubmission}
            validate={selectedSuccessfulSubmission ? required : undefined}
          />
          {(selectedStatusOption === "approved" || selectedStatusOption === "partially_approved") && (
            <>
              <hr />
              {/* Authorization Number */}
              <Field
                name="prior_auth_requests_submission_attempt_form[authorization_number]"
                id="prior_auth_requests_submission_attempt_form_authorization_number"
                component={TextInput}
                label="Authorization Number"
                required
                validate={required}
              />
              {/* Authorization Date */}
              <Field
                name="prior_auth_requests_submission_attempt_form[authorization_date]"
                id="prior_auth_requests_submission_attempt_form_authorization_date"
                component={DateInput}
                label="Authorization Date"
                required
                validate={required}
              />
              {/* Authorization Effective Date */}
              <Field
                name="prior_auth_requests_submission_attempt_form[authorization_effective_date]"
                id="prior_auth_requests_submission_attempt_form_authorization_effective_date"
                component={DateInput}
                label="Authorization Start Date"
                required
                validate={required}
              />
              {/* Authorization Expiration Date */}
              <Field
                name="prior_auth_requests_submission_attempt_form[authorization_expiration_date]"
                id="prior_auth_requests_submission_attempt_form_authorization_expiration_date"
                component={DateInput}
                label="Authorization End Date"
                required
                validate={required}
              />
            </>
          )}

          {selectedStatusOption === "partially_approved" && (
            <>
              {/* Denial Code */}
              <Field
                name="prior_auth_requests_submission_attempt_form[denial_reason_code]"
                id="prior_auth_requests_submission_attempt_form_denial_reason_code"
                label="Denial Reason"
                component={SelectInput}
                options={denialReasonCodeOptions}
                required
                validate={required}
              />
              {/* Approved CPT Codes */}
              <Field
                name="prior_auth_requests_submission_attempt_form[approved_cpt_codes]"
                id="prior_auth_requests_submission_attempt_form_approved_cpt_codes"
                component={SelectInput}
                isMulti
                options={approvedCodes}
                onUpdate={handleApprovedChange}
                label="Approved CPT Codes"
                required
                validate={required}
              />
              {/* Denied CPT Codes */}
              <Field
                name="prior_auth_requests_submission_attempt_form[denied_cpt_codes]"
                id="prior_auth_requests_submission_attempt_form_denied_cpt_codes"
                component={SelectInput}
                isMulti
                options={deniedCodes}
                onUpdate={handleDeniedChange}
                label="Denied CPT Codes"
                validate={required}
              />
            </>
          )}

          {selectedStatusOption === "denied" && (
            <>
              <hr />
              {/* Denial Code */}
              <Field
                name="prior_auth_requests_submission_attempt_form[denial_reason_code]"
                id="prior_auth_requests_submission_attempt_form_denial_reason_code"
                label="Denial Reason"
                component={SelectInput}
                options={denialReasonCodeOptions}
                required
                validate={required}
              />
            </>
          )}
          <hr />
        </>
      )}

      {/* Comments */}
      <Field
        name="prior_auth_requests_submission_attempt_form[comments]"
        id="prior_auth_requests_submission_attempt_form_comments"
        component={TextInput}
        type="textarea"
        label="Comments"
      />
      {cannedResponses.length > 0 && (
        <>
          {/* Canned Response */}
          <Field
            name="prior_auth_requests_submission_attempt_form[canned_response_id]"
            id="prior_auth_requests_submission_attempt_form_canned_response_id"
            component={SelectInput}
            options={cannedResponses}
            includeBlank
            label="Add Canned Response"
          />
        </>
      )}
      {/* Mark Comment Public */}
      <Field
        name="prior_auth_requests_submission_attempt_form[public_comments]"
        id="prior_auth_requests_submission_attempt_form_public_comments"
        component={Checkbox}
        type="checkbox"
        label="Mark Comment Public"
      />
      {/* Reference Number */}
      <Field
        name="prior_auth_requests_submission_attempt_form[reference_number]"
        id="prior_auth_requests_submission_attempt_form_reference_number"
        component={TextInput}
        label="Reference Number"
      />
      {/* Benefit Management Portal (Hidden Input or Select) */}
      {portalId && (
        <Field
          name="prior_auth_requests_submission_attempt_form[benefit_management_portal]"
          id="prior_auth_requests_submission_attempt_form_benefit_management_portal"
          component={TextInput}
          type="hidden"
          initialValue={portalId}
        />
      )}
      {remainingPortals.length > 0 && (
        <Field
          name="prior_auth_requests_submission_attempt_form[benefit_management_portal]"
          id="prior_auth_requests_submission_attempt_form_benefit_management_portal"
          component={SelectInput}
          options={remainingPortals}
          includeBlank
          label="Benefit Management Portal"
        />
      )}
      {/* Submission Type */}
      <Field
        name="prior_auth_requests_submission_attempt_form[submission_type]"
        id="prior_auth_requests_submission_attempt_form_submission_type"
        component={TextInput}
        type="hidden"
        initialValue={submissionType}
      />

      <hr />

      <LoadingButton color="primary" loading={submitting} onClick={handleSubmit} disabled={!rest.valid || submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
