import React from "react";
import { RejectMatchButton } from "./RejectMatchButton";
import { AcceptMatchButton } from "./AcceptMatchButton";
import { formatLabName } from "../util/formatters";

export const PartialClinicMatchActionsCell = ({ row }) => {
  return (
    <div className="text-center">
      <div className="d-flex flex-row">
        <div className="me-2">
          <AcceptMatchButton
            id={row.original.id}
            publicId={row.original.public_id}
            lab={formatLabName(row.original)}
            externalClinicId={row.original.external_clinic_id}
            clinic={row.original.potential_clinic_name}
            clinicId={row.original.potential_clinic_id}
          />
        </div>
        <div>
          <RejectMatchButton id={row.original.id} />
        </div>
      </div>
    </div>
  );
};
