import React from "react";
import { YesNoToggle } from "../../../../components/wizards/YesNoToggle";
import { formatDate } from "../../../../util/formatters";
import { WizardContext } from "../../WizardContext";
import { FollowupAlertScreen } from "../FollowupAlertScreen";

export const MedicalReviewMissingInfoBranch = (props) => (
  <WizardContext.Consumer>
    {({ state, updateStatus, createFollowupTask, loading }) => (
      <FollowupAlertScreen
        days={1}
        loading={loading}
        onClick={async () => {
          await updateStatus("medicalreview", "missinginformation", false);
          await createFollowupTask(1, "Follow up on Digital Submission - Missing Information");
        }}
      >
        Case will be moved to <strong>Missing Information</strong>
      </FollowupAlertScreen>
    )}
  </WizardContext.Consumer>
);
