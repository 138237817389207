import _ from "lodash";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { camelizeKeys } from "../../util/helpers";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { TabbedRoutes } from "../components/TabbedRoutes";
import { Routes } from "../Routes";
import { useAdminResource } from "../utils";
import { ClinicsGeneralTab } from "./tabs/ClinicsGeneralTab";
import { ClinicsProvidersTab } from "./tabs/ClinicsProvidersTab";
import { ClinicsSupportedLabsTab } from "./tabs/ClinicsSupportedLabsTab";
import { ClinicsSupportedPayorsTab } from "./tabs/ClinicsSupportedPayorsTab";
import { ClinicsPayorMappingsTab } from "./tabs/ClinicsPayorMappingsTab";
import { ClinicsTestMappingsTab } from "./tabs/ClinicsTestMappingsTab";
import { ClinicsLabRedirectionTab } from "./tabs/ClinicsLabRedirectionTab";

const useBreadcrumbs = (pageTitle, clinicId) =>
  useMemo(
    () => [
      { url: Routes.clinics.url(), label: Routes.clinics.label },
      { url: Routes.clinic.url(clinicId), label: pageTitle, active: true },
    ],
    [pageTitle, clinicId]
  );

const useClinic = (clinicId) => camelizeKeys(useAdminResource(`/admin/clinics/${clinicId}.json`));

const useTabs = (clinic) =>
  _.compact([
    {
      path: "general",
      label: "General",
      Component: ClinicsGeneralTab,
    },
    {
      path: "providers",
      label: "Providers",
      Component: ClinicsProvidersTab,
    },
    {
      path: "supported-payors",
      label: "Supported Payors",
      Component: ClinicsSupportedPayorsTab,
    },
    {
      path: "supported-labs",
      label: "Supported Labs",
      Component: ClinicsSupportedLabsTab,
    },
    {
      path: "payor-mappings",
      label: "Clinic Payor Mapping",
      Component: ClinicsPayorMappingsTab,
    },
    {
      path: "test-mappings",
      label: "Test Mapping",
      Component: ClinicsTestMappingsTab,
    },
    {
      path: "lab-redirections",
      label: "Redirections",
      Component: ClinicsLabRedirectionTab,
    },
  ]);

export const ClinicsShowPage = () => {
  const { clinicId } = useParams();
  const { resource: clinic, error, isLoading, fetch } = useClinic(clinicId);
  const pageTitle = clinic?.clinicName || `Clinic #${clinicId}`;
  const breadcrumbs = useBreadcrumbs(pageTitle, clinicId);

  return (
    <SystemConfigLayout title={pageTitle} showBreadcrumbs crumbs={breadcrumbs} loading={isLoading}>
      <TabbedRoutes tabs={useTabs(clinic)} defaultTab={"general"} clinic={clinic} clinicId={clinicId} refresh={fetch} />
    </SystemConfigLayout>
  );
};
