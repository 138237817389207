import React from "react";
import { ActionAlert } from "../../../components/ActionAlert";
import { LoadingButton } from "../../../components/LoadingButton";

export const FollowupAlertScreen = ({ days, onClick, loading, children }) => (
  <ActionAlert
    color="warning"
    actions={
      <LoadingButton color="warning" loading={loading} onClick={onClick}>
        Ok
      </LoadingButton>
    }
  >
    {children}
    {children && children.length > 0 && <hr />}A follow-up task will be created for <strong>{days} days from today</strong>.
  </ActionAlert>
);
