import React from "react";
import { Button, ListGroup, ListGroupItem } from "reactstrap";

export const AttachmentLabel = ({ attachment, allowRemove = false, onRemove }) => (
  <ListGroupItem className="d-flex align-items-center">
    <div className="w-100 target-me">{attachment.name}</div>

    {allowRemove && <Button close onClick={onRemove} />}
  </ListGroupItem>
);

export const AttachmentsListGroup = ({ attachments, allowRemove = false, onRemove }) => {
  return (
    <>
      {attachments && (
        <ListGroup flush>
          {attachments.map((a, idx) => (
            <AttachmentLabel allowRemove={allowRemove} onRemove={() => onRemove(a, idx)} attachment={a} key={idx} />
          ))}
        </ListGroup>
      )}
    </>
  );
};
