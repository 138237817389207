import React, { useState, useEffect } from "react";
import { LoadingIcon } from "../components/LoadingIcon";
import { Card, CardHeader } from "reactstrap";
import ReactPaginate from "react-paginate";
import { api } from "../util/api";
import { SearchInput } from "../components/SearchInput";

const getClinicalGlossaries = async (setPostData, currentPage, setPageCount, search, setLoading) => {
  setLoading(true);
  try {
    const { data } = await api.get(`/clinical_glossaries.json`, { params: { page: currentPage, per: 10, search: search } });

    setPageCount(data.meta.total_pages);
    setPostData(data.resources);
    setLoading(false);
  } catch (err) {
    console.error(err);
  }
};

const ClinicalGlossariesList = (props) => {
  return (
    <div className="mt-4">
      <strong>{props.item.term}</strong> - {props.item.definition}
      <br />
      <em>Aliases: {props.item.clinical_glossary_aliases.map((c) => c.term).join(", ")}</em>
    </div>
  );
};

const handlePageClick = (e, setCurrentPage) => {
  const selectedPage = e.selected;
  setCurrentPage(selectedPage + 1);
};

const Loading = () => {
  return (
    <div className="text-muted p-4">
      <LoadingIcon /> Loading Clinical Glossary Data...
    </div>
  );
};

const handleKeypress = (e, text, setSearch) => {
  if (e.charCode === 13) {
    setSearch(text);
  }
};

export const ClinicalGlossaries = () => {
  const [loading, setLoading] = useState(false);
  const [postData, setPostData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [search, setSearch] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    getClinicalGlossaries(setPostData, currentPage, setPageCount, search, setLoading);
  }, [currentPage, search]);

  return (
    <Card className="border-0 rounded-0">
      <CardHeader>
        <SearchInput
          bsSize="md"
          className={"search-input"}
          value={text}
          onClick={() => setSearch(text)}
          onChange={(e) => setText(e.target.value)}
          onKeyPress={(e) => handleKeypress(e, text, setSearch)}
          icon="Search"
        />
        {loading ? (
          <Loading />
        ) : (
          <div className="definitions">
            {postData.length ? (
              postData.map((cg) => <ClinicalGlossariesList item={cg} key={cg.id} />)
            ) : (
              <p className="alert alert-warning mt-4">No results were found</p>
            )}
          </div>
        )}
        <ReactPaginate
          containerClassName={"pagination justify-content-center"}
          subContainerClassName={"pages pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          previousLabel={"Prev"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={(e) => handlePageClick(e, setCurrentPage)}
          activeClassName={"active"}
        />
      </CardHeader>
    </Card>
  );
};
