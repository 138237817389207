import $ from "jquery";
import "../../registration/jquery.zxcvbn_progress_bar";

export const initPasswordStrength = () => {
  $(() => {
    $("#password-strength-bar").zxcvbnProgressBar({
      passwordInput: "#user_password",
    });
  });
};
