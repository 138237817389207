import $ from "jquery";
import _ from "lodash";

const onDenialReasonChange = (e) => {
  updateDenialReason($(e.target), true);
};

const updateDenialReason = ($select, clearOther = false) => {
  const val = $select.val();
  const label = $("option:selected", $select).text();
  const $dropdown = $select.closest(".denial_reason_dropdown");

  if (val === "0") {
    showOther($dropdown, clearOther);
  } else {
    showNormal($dropdown, label);
  }
};

const showOther = ($parent, clearOther) => {
  const $el = $(".denial_reason", $parent);
  const val = clearOther ? "" : $el.val();

  // Note: You can't just change the `type` attribute on existing elements, because
  // some older IEs are jerks and don't allow this.
  $el.clone().attr("type", "text").val(val).insertAfter($el).prev().remove();
};

const showNormal = ($parent, label) => {
  const $el = $(".denial_reason", $parent);
  // Note: You can't just change the `type` attribute on existing elements, because
  // some older IEs are jerks and don't allow this.
  $el.clone().attr("type", "hidden").val(label).insertAfter($el).prev().remove();
};

export const initDenialReason = () => {
  const $denialReasonDropdowns = $(".denial_reason_dropdown .denial_reason_code");

  if ($denialReasonDropdowns.length > 0) {
    $denialReasonDropdowns.on("change", onDenialReasonChange);
    updateDenialReason($denialReasonDropdowns);
  }
};
