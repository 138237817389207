import setFieldData from "final-form-set-field-data";
import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { Alert, Button, Col, FormGroup, FormText, Row } from "reactstrap";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { CptSelector } from "../components/inputs/final_form/CptSelector";
import { DateInput } from "../components/inputs/final_form/DateInput";
import { DropzoneInput } from "../components/inputs/final_form/DropzoneInput";
import { Fieldset } from "../components/inputs/final_form/Fieldset";
import { Icd10Selector } from "../components/inputs/final_form/Icd10Selector";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { WarningEngine, WarningField } from "../components/inputs/final_form/WarningEngine";
import { LoadingButton } from "../components/LoadingButton";
import { LoadingIcon } from "../components/LoadingIcon";
import { api } from "../util/api";
import { isBlank } from "../util/helpers";

const warningValidator = (values) => {
  const warnings = {};

  const generateBlankWarning = (field) => `${field} must be filled to resolve missing information`;

  if (isBlank(values.patient.patientId)) {
    warnings["patient.patientId"] = generateBlankWarning("Patient ID");
  }

  if (isBlank(values.patient.firstName)) {
    warnings["patient.firstName"] = generateBlankWarning("First Name");
  }

  if (isBlank(values.patient.lastName)) {
    warnings["patient.lastName"] = generateBlankWarning("Last Name");
  }

  if (isBlank(values.patient.dob)) {
    warnings["patient.dob"] = generateBlankWarning("Date of Birth");
  }

  if (isBlank(values.patient.gender)) {
    warnings["patient.gender"] = generateBlankWarning("Gender");
  }

  if (isBlank(values.primaryLabInsurance.insuranceName)) {
    warnings["primaryLabInsurance.insuranceName"] = generateBlankWarning("Insurance Name");
  }

  if (isBlank(values.primaryLabInsurance.memberId)) {
    warnings["primaryLabInsurance.memberId"] = generateBlankWarning("Member ID");
  }

  if (isBlank(values.labOrder.collectionDate) && isBlank(values.labOrder.serviceDate)) {
    warnings["labOrder.collectionDate"] = "One of Collection Date or Service Date is required to resolve missing information";
    warnings["labOrder.serviceDate"] = "One of Collection Date or Service Date is required to resolve missing information";
  }

  if (isBlank(values.provider.npi)) {
    warnings["provider.npi"] = generateBlankWarning("NPI");
  }

  if (isBlank(values.icd10Codes)) {
    warnings["icd10Codes"] = generateBlankWarning("Provided ICD 10 Codes");
  }

  if (isBlank(values.cptCodes)) {
    warnings["cptCodes"] = generateBlankWarning("Provided CPT Codes");
  }

  if (isBlank(values.testNames)) {
    warnings["testNames"] = generateBlankWarning("Provided Test Names");
  }

  return warnings;
};

export const MissingInfoForm = ({ caseId, caseUrl, labTests, afterSave, saveButtonText = "Save", allowReset = true, ExtraButtons }) => {
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const [caseData, setCaseData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCaseData = async () => {
      setError(null);
      setLoading(true);

      try {
        const { data } = await api.get(caseUrl);
        setCaseData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCaseData();
  }, [caseId, caseUrl]);

  const onSubmit = async (formData) => {
    setSaving(true);
    setError(null);
    setMessage(null);

    try {
      const { newSupplementalDocuments, ...rest } = formData;
      const { data } = await api.patch(caseUrl, { ...rest, supplementalDocuments: { newFiles: newSupplementalDocuments } });

      setCaseData(data);
      setMessage(`Saved Case #${caseId} successfully`);

      if (_.isFunction(afterSave)) {
        await afterSave(data);
      }
    } catch (err) {
      setError(err);
    } finally {
      setSaving(false);
    }
  };

  if (loading || !caseData) {
    return (
      <div className="text-muted text-center">
        <LoadingIcon className="me-2" /> Loading Case Data...
      </div>
    );
  }

  return (
    <>
      <DetailedErrorAlert className="mb-3" error={error} />

      {message && (
        <Alert color="success" className="mb-3">
          {message}
        </Alert>
      )}

      <Form onSubmit={onSubmit} mutators={{ setFieldData }} initialValues={caseData.pa_request}>
        {({ handleSubmit, form, submitting, pristine, values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Fieldset legend="Patient Info" highlight="red">
                <WarningField
                  required
                  name="patient.patientId"
                  component={TextInput}
                  type="text"
                  label="Patient ID"
                  placeholder="Patient ID"
                />

                <Row>
                  <Col>
                    <WarningField
                      required
                      name="patient.firstName"
                      component={TextInput}
                      type="text"
                      label="First Name"
                      placeholder="First Name"
                    />
                  </Col>
                  <Col>
                    <WarningField
                      required
                      name="patient.lastName"
                      component={TextInput}
                      type="text"
                      label="Last Name"
                      placeholder="Last Name"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <WarningField required name="patient.dob" component={DateInput} label="Date of Birth" placeholder="Date of Birth" />
                  </Col>
                  <Col>
                    <WarningField
                      required
                      name="patient.gender"
                      component={SelectInput}
                      options={[
                        { label: "Male", value: "M" },
                        { label: "Female", value: "F" },
                      ]}
                      label="Gender"
                      placeholder="Gender"
                    />
                  </Col>
                </Row>
              </Fieldset>

              <Fieldset legend="Insurance Information" highlight="red">
                <WarningField
                  required
                  name="primaryLabInsurance.insuranceName"
                  component={TextInput}
                  type="text"
                  label="Insurance Name"
                  placeholder="Insurance Name"
                />

                <WarningField
                  required
                  name="primaryLabInsurance.memberId"
                  component={TextInput}
                  type="text"
                  label="Member ID"
                  placeholder="Member ID"
                />
              </Fieldset>

              <Fieldset legend="Order Information" highlight="green">
                <Row>
                  <Col>
                    <WarningField
                      required
                      name="labOrder.collectionDate"
                      component={DateInput}
                      type="text"
                      label="Collection Date"
                      placeholder="Collection Date"
                    />
                  </Col>
                  <Col>
                    <WarningField
                      required
                      name="labOrder.serviceDate"
                      component={DateInput}
                      type="text"
                      label="Service Date"
                      placeholder="Service Date"
                    />
                  </Col>
                </Row>
              </Fieldset>

              <Fieldset legend="Provider Information" highlight="orange">
                <Row>
                  <Col>
                    <WarningField
                      required
                      name="provider.npi"
                      component={TextInput}
                      type="text"
                      label="Provider NPI"
                      placeholder="Provider NPI"
                    />
                  </Col>
                </Row>
              </Fieldset>

              <Fieldset legend="Tests and Codes" highlight="green">
                <WarningField
                  required
                  name="icd10Codes"
                  disabled={caseData.pa_request.icd10Codes?.length > 0}
                  component={Icd10Selector}
                  initialOptions={caseData.pa_request.icd10Codes?.map((code) => ({
                    label: code,
                    value: code,
                  }))}
                  label="ICD-10 Codes"
                  placeholder="ICD10 Diagnosis Codes"
                >
                  <FormText>
                    Raw Provided ICD-10 Codes:{" "}
                    {caseData.pa_request.icd10Codes?.map((c, idx) => (
                      <span key={`${c}-${idx}`}>
                        <code>{c}</code>{" "}
                      </span>
                    ))}
                  </FormText>
                </WarningField>

                <WarningField
                  required
                  disabled={caseData.pa_request.cptCodes?.length > 0}
                  name="cptCodes"
                  component={CptSelector}
                  label="Provided CPT Codes"
                  placeholder="CPT Procedure Codes"
                >
                  <FormText>
                    Raw Provided CPT Codes:{" "}
                    {caseData.pa_request.cptCodes?.map((c, idx) => (
                      <span key={`${c}-${idx}`}>
                        <code>{c}</code>{" "}
                      </span>
                    ))}
                  </FormText>
                </WarningField>

                <WarningField
                  label="Provided Test Names"
                  required
                  disabled={caseData.pa_request.testNames?.length > 0}
                  name="testNames"
                  component={SelectInput}
                  options={labTests}
                  isMulti
                >
                  <FormText>Raw Provided Tests: {caseData.pa_request.testNames?.join(", ")}</FormText>
                </WarningField>
              </Fieldset>

              <Fieldset legend="Supplemental Documents">
                <FormGroup>
                  {/*
                    This explicitly does not exist in the initial values because it is
                    exclusively for uploading new files.
                  */}
                  <Field name="newSupplementalDocuments" component={DropzoneInput} multiple />
                </FormGroup>
              </Fieldset>

              <FormGroup className="mt-3">
                <div className="d-flex align-items-center">
                  <LoadingButton loading={saving || submitting} type="submit" color="primary" disabled={submitting} className="me-2">
                    {saveButtonText}
                  </LoadingButton>
                  {allowReset && (
                    <Button outline type="button" onClick={form.reset} disabled={submitting || pristine}>
                      Reset
                    </Button>
                  )}

                  {!!ExtraButtons && (
                    <span className="ms-auto">
                      <ExtraButtons submitting={submitting} pristine={pristine} form={form} />
                    </span>
                  )}
                </div>
              </FormGroup>

              <WarningEngine mutators={form.mutators} validate={warningValidator} />
            </form>
          );
        }}
      </Form>
    </>
  );
};
