import React from "react";
import { RemotePaginateSelector } from "../../components/inputs/RemotePaginateSelector";

const isOptionSelected = (option, values) => {
  return values.some(({ value }) => option.value === value);
};

export const AdministrateSelect = (props) => {
  return (
    <RemotePaginateSelector
      isClearable
      dataKey="resources"
      isOptionSelected={isOptionSelected}
      valueKey="id"
      searchParam="search"
      labelKey="dashboard_display_name"
      {...props}
    />
  );
};
