import { createRoot } from "react-dom/client";
import { SeerezCaseDetails } from "../components/SeerezCaseDetails";

export function initSeerezDetails() {
  const container = document.getElementById("seerez-details");

  if (container) {
    const root = createRoot(container);
    const dataset = container.dataset;

    const testInfo = {
      name: dataset.testName,
      icd10Codes: dataset.icd10Codes?.split(","),
      cptCodes: JSON.parse(dataset.cptCodes),
      labName: dataset.labName,
    };

    const practiceInfo = {
      name: dataset.providerName,
      practiceName: dataset.providerPracticeName,
      npi: dataset.providerNpi,
      enrolled: dataset.providerEnrolled,
    };

    const insuranceInfo = {
      name: dataset.insuranceName,
      verifiedName: dataset.insuranceVerifiedName,
      planType: dataset.insuranceVerifiedPlanType,
      memberId: dataset.insuranceMemberId,
      activeCoverage: dataset.insuranceActiveCoverage,
      fundingType: dataset.insuranceFundingType,
    };

    const otherInfo = {
      authorizationNumber: dataset.authorizationNumber,
      denialNumber: dataset.denialNumber,
      authorizationDate: dataset.authorizationDate,
      authorizationEffectiveDate: dataset.authorizationEffectiveDate,
      authorizationExpirationDate: dataset.authorizationExpirationDate,
      uhcSrn: dataset.uhcSrn,
      claimNumber: dataset.claimNumber,
      serviceDate: dataset.serviceDate,
      collectionDate: dataset.collectionDate,
    };

    root.render(<SeerezCaseDetails test={testInfo} practice={practiceInfo} insurance={insuranceInfo} otherInfo={otherInfo} />);
  }
}
