import React from "react";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import { WizardContext } from "../../WizardContext";
import { YesNoPanel } from "../../../../components/wizards/YesNoPanel";
import { Phone } from "lucide-react";

const PhoneListGroupItem = ({ num, errorMessage }) =>
  num ? (
    <ListGroupItem tag="a" href={`tel:${num}`} className="text-start">
      <Phone className="me-3" />
      {num}
    </ListGroupItem>
  ) : (
    <ListGroupItem>
      <span className="text-muted">{errorMessage}</span>
    </ListGroupItem>
  );

export const CallClinicScreen = () => (
  <WizardContext.Consumer>
    {({ dispatch, caseData }) => {
      const { clinicInfo } = caseData;

      const handleYes = () => {
        dispatch({
          type: "setAnswer",
          key: "clinicCallAnswered",
          value: true,
          doTransition: true,
        });
      };

      const handleNo = () => {
        dispatch({
          type: "setAnswer",
          key: "clinicCallAnswered",
          value: false,
          doTransition: true,
        });
      };

      return (
        <>
          <YesNoPanel yesAction={handleYes} noAction={handleNo} yesLabel="Missing info obtained" noLabel="Missing info not obtained">
            <h5 className="mb-5">
              Call Clinic <strong>{clinicInfo?.clinicName}</strong>
            </h5>
            <ListGroup>
              <PhoneListGroupItem
                num={clinicInfo.clinicPhone}
                errorMessage={`No phone number for ${
                  clinicInfo?.clinicName ?? "clinic"
                }. Please call clinic based on contact information found in attachments.`}
              />
            </ListGroup>

            <div className="d-flex flex-row align-items-center justify-content-center mb-3">
              Provided info?{" "}
              <Button color="link" onClick={handleYes}>
                Resolve MI Now
              </Button>
            </div>
          </YesNoPanel>
        </>
      );
    }}
  </WizardContext.Consumer>
);
