import axios from "axios";
import React, { useState, useCallback, useMemo } from "react";
import { Col } from "reactstrap";

import { BootstrapGrid } from "../components/grid/BootstrapGrid";
import { api } from "../util/api";
import { encodeTableState } from "../util/table";
import { useDebounced } from "../util/hooks";
import { TokenCell } from "../components/grid/cells/TokenCell";

const COLUMNS = [
  {
    Header: "NPI",
    Cell: TokenCell,
    accessor: "npi",
    width: 125,
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Provider Payor Number",
    id: "ppn",
    Cell: ({ value }) =>
      value.map((ppn) => (
        <div key={ppn.provider_payor_number}>
          {ppn.payor_name} - {ppn.provider_payor_number}
        </div>
      )),
    accessor: (provider) => provider.provider_payor_numbers || [],
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: "Medicaid ID/State",
    id: "provider_medicaid_details",
    Cell: (row) =>
      row.value.map((m) => (
        <div key={m.medicaid_id}>
          {m.medicaid_id}/{m.state}
        </div>
      )),
    accessor: (provider) => provider.provider_medicaid_details || [],
    disableFilters: true,
    disableSortBy: true,
  },
];

const ProviderGrid = ({ dataUrl }) => {
  //instatiating the table state
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(-1);
  const [loading, setLoading] = useState(false);

  // Takes an unencoded table state
  // Sets table state to loading, and then attempts to fetch the data based
  // on the data url
  const onFetchData = useDebounced(
    useCallback(
      async (rawTableState) => {
        setLoading(true);

        try {
          let dataReq = await api.get(dataUrl, {
            params: encodeTableState(rawTableState, {}),
          });

          setData(dataReq.data.rows);
          setPages(dataReq.data.pages);
        } catch (e) {
          if (!axios.isCancel(e)) {
            console.error(e);
          }
        } finally {
          setLoading(false);
        }
      },
      [dataUrl]
    ),
    400
  );

  return (
    <Col className="py-3">
      <h5>Clinic Providers</h5>
      <BootstrapGrid
        manual
        disableFilters={false}
        data={data}
        pages={pages}
        loading={loading}
        onFetchData={onFetchData}
        columns={COLUMNS}
        defaultPageSize={10}
      />
    </Col>
  );
};

export default ProviderGrid;
