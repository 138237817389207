import { useCallback } from "react";
import { api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";

export const ImportPayorPathsModal = ({ onImport, ...rest }) => {
  const handleImport = useCallback(
    async (values) => {
      const params = {
        file: {
          bytes: values.file,
        },
      };
      const res = await api.post("/admin/payor_paths/import.json", snakifyKeys(params));

      onImport?.(res.data?.meta?.message, res.data?.meta?.error_details);
    },
    [onImport]
  );

  return (
    <ImportModal buttonProps={rest} onImport={handleImport} title="Import Payor Paths" exampleDownloadPath="/admin/payor_paths/example" />
  );
};
