import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Form, Field } from "react-final-form";
import { DetailedErrorAlert } from "./DetailedErrorAlert";
import { PayorSelector } from "../components/inputs/final_form/PayorSelector";
import { required } from "../util/validators";
import { api } from "../util/api";
import { LoadingButton } from "./LoadingButton";

export const PayorModal = ({ open, toggleModal, payor, caseId, modalProps }) => {
  const [error, setError] = useState(null);

  const handleSubmit = async (payor) => {
    setError(null);
    try {
      await api.put(`/cases/${caseId}/payor`, { case: { payor_id: payor.payorId } });

      window.location.reload();
    } catch (e) {
      console.error(e);
      setError(e);
    }
  };

  return (
    <>
      <Modal isOpen={open} toggle={toggleModal} {...modalProps}>
        <ModalHeader toggle={toggleModal}>{payor ? "Change Payor" : "Set Payor"}</ModalHeader>
        {payor && (
          <ModalBody className="pb-0">
            <p>
              Current Payor <strong>{payor.name}</strong>
            </p>
          </ModalBody>
        )}

        <ModalBody className="pt-0">
          <DetailedErrorAlert error={error} />
          <Form onSubmit={handleSubmit} initialValues={{ payorId: payor.id }}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field render={PayorSelector} label="Payor" name="payorId" valueSelector="id" required={required} />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting} className="me-2">
                    Update
                  </LoadingButton>

                  <Button outline onClick={toggleModal}>
                    Cancel
                  </Button>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export const PayorModalButton = ({ buttonProps, disabled, open, ...props }) => {
  const [modalOpen, setModalOpen] = useState(open);
  const toggleModal = () => setModalOpen(!modalOpen);

  return (
    <>
      <Button {...buttonProps} onClick={toggleModal} disabled={disabled}>
        {props.payor ? "Change Payor" : "Set Payor"}
      </Button>

      <PayorModal {...props} open={modalOpen} toggleModal={toggleModal} />
    </>
  );
};
