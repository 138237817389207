import React from "react";
import { ActionAlert } from "../../../components/ActionAlert";
import { WizardContext } from "../WizardContext";
import { redirectToStep } from "../wizard_utils";
import { LoadingButton } from "../../../components/LoadingButton";
import { Alert } from "reactstrap";

export const MoveToMissingInfoScreen = () => (
  <WizardContext.Consumer>
    {({ updateStatus, createFollowupTask, caseId, loading, miEnabled }) => (
      <>
        <ActionAlert
          color="warning"
          actions={
            <LoadingButton
              color="warning"
              loading={loading}
              onClick={async () => {
                // We have to await these in order because createFollowupTask
                // optionally does a redirect, and so if it resolves first, may
                // cancel the other request.
                await updateStatus("missinginfo", undefined, false);
                await createFollowupTask(
                  2,
                  "Follow up on Missing Info",
                  !miEnabled,
                  `Follow up task created. Please complete the initial Missing Information outreach for Case ${caseId}`
                );

                // Redirect to missinginfo_followup _if_ Flipper has
                // enabled MI Followup
                if (miEnabled) {
                  redirectToStep("prior_auth", "missinginfo_followup", caseId);
                }
              }}
            >
              Ok
            </LoadingButton>
          }
        >
          The case will be moved to <strong>Missing Information</strong> and a follow up task will be created for <strong>2 days</strong>{" "}
          from today.
        </ActionAlert>

        <Alert color="danger">
          <strong>Note:</strong> please complete the initial Missing Information outreach
        </Alert>
      </>
    )}
  </WizardContext.Consumer>
);
