export const EventMiniBus = {
  on(evtType, callback) {
    document.addEventListener(evtType, (evt) => callback(evt?.detail));
  },
  dispatch(evtType, data) {
    const evt = new CustomEvent(evtType, { detail: data, bubbles: true });
    document.dispatchEvent(evt);
  },
  remove(evtType, callback) {
    document.removeEventListener(evtType, callback);
  },
};
