import _ from "lodash";
import React, { useMemo } from "react";
import { CptCodeRow } from "./CptCodeRow";

export const PayorGroupRow = ({ group, selectedCptCodes, selectedPayor, paRequired, gcRequired }) => {
  // Filter out any CPT codes that don't match the
  // selected CPT codes --- if any CPT codes are selected
  let filteredCptCodes = useMemo(
    () => (selectedCptCodes ? group.covered_cpt_codes.filter((c) => _.includes(selectedCptCodes, c.id)) : group.covered_cpt_codes),
    [selectedCptCodes, group]
  );

  let filteredPayors = useMemo(
    () => (selectedPayor ? group.payors.filter((p) => p.id === selectedPayor) : group.payors),
    [selectedPayor, group]
  );

  if (paRequired) {
    // Filter out any CPT codes that don't have at least one setting
    // that requires PA if the PA required toggle is on
    filteredCptCodes = _.filter(filteredCptCodes, (c) => {
      const groupPayorSettings = _.flatMap(group.payors, (p) => p.payor_cpt_codes_settings);
      const cptSettings = _.filter(groupPayorSettings, (cc) => cc.cpt_code_id === c.id);
      return _.some(cptSettings, (c) => c.requires_pa === true);
    });
  }

  if (gcRequired) {
    // Filter out any CPT codes that don't have at least one setting
    // that requires GC if the GC required toggle is on
    filteredCptCodes = _.filter(filteredCptCodes, (c) => {
      const groupPayorSettings = _.flatMap(group.payors, (p) => p.payor_cpt_codes_settings);
      const cptSettings = _.filter(groupPayorSettings, (cc) => cc.cpt_code_id === c.id);
      return _.some(cptSettings, (c) => c.gc_requirement !== null);
    });
  }

  return (
    <>
      {filteredCptCodes.map((code, j) => {
        // Filter out any Payors that do not require PA for at least
        // one of the available CPT code we are rendering
        if (paRequired) {
          filteredPayors = _.filter(filteredPayors, (p) => {
            return _.some(p.payor_cpt_codes_settings, (c) => {
              return c.requires_pa === true && c.cpt_code_id === code.id;
            });
          });
        }

        if (gcRequired) {
          filteredPayors = _.filter(filteredPayors, (p) => {
            return _.some(p.payor_cpt_codes_settings, (c) => {
              return c.gc_requirement !== null && c.cpt_code_id === code.id;
            });
          });
        }

        return <CptCodeRow key={j} code={code} group={group} payors={filteredPayors} />;
      })}
    </>
  );
};
