import { useCallback, useState } from "react";
import { Switch } from "../../../components/inputs/Switch";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";

export const LimitCoinsuranceToggle = ({ test, limitCoinsurance, onChange, readOnly, onError }) => {
  const [loading, setLoading] = useState(false);
  const label = loading ? "Loading..." : limitCoinsurance ? "Yes" : "No";

  const getUpdateMessage = useCallback(
    (limitCoinsurance) => `Limited coinsurance to 20% for ${test.commercialName} to ${limitCoinsurance ? "Yes" : "No"}`,
    [test.commercialName]
  );

  const handleUpdate = useCallback(
    async (e) => {
      setLoading(true);
      const limitCoinsurance = e.target.checked;

      try {
        await adminApi.put(`/admin/tests/${test.id}`, snakifyKeys({ limitCoinsurance: limitCoinsurance }));

        onChange(getUpdateMessage(limitCoinsurance));
      } catch (err) {
        onError(extractErrorMessage(err));
      } finally {
        setLoading(false);
      }
    },
    [test.id, onChange, getUpdateMessage, onError]
  );

  return (
    <Switch
      disabled={readOnly || loading}
      id={`limit_coinsurance${test.id}`}
      checked={Boolean(limitCoinsurance)}
      label={label}
      onChange={handleUpdate}
    />
  );
};
