import { useState } from "react";
import { createRoot } from "react-dom/client";
import { PayorSelector } from "../../../components/inputs/PayorSelector";
import { InputWrapper } from "../../../components/inputs/final_form/InputWrapper";
import { safeJSONParse } from "../../../util/helpers";

const PayorSelectApp = ({ initialPayors, isMulti = false, disabled = false, inputName, label, required = false }) => {
  const [selectedPayors, setSelectedPayors] = useState(initialPayors);

  return (
    <InputWrapper label={label} required={required}>
      <PayorSelector isMulti={isMulti} onChange={setSelectedPayors} value={selectedPayors} disabled={disabled} />

      {isMulti ? (
        <select multiple="multiple" style={{ display: "none" }} value={selectedPayors} readOnly>
          {selectedPayors.map((p) => (
            <option value={p} key={p}>
              {p}
            </option>
          ))}
        </select>
      ) : (
        <input type="hidden" name={inputName} value={selectedPayors} />
      )}
    </InputWrapper>
  );
};

export const initPayorSelect = () => {
  const selectContainers = Array.from(document.getElementsByClassName("payor_selector"));

  selectContainers.forEach(function (container) {
    const root = createRoot(container);
    const { initialPayors, inputName, isMulti, disabled, label, required } = container.dataset;

    root.render(
      <PayorSelectApp
        initialPayors={safeJSONParse(initialPayors)}
        isMulti={safeJSONParse(isMulti)}
        disabled={safeJSONParse(disabled)}
        required={safeJSONParse(required)}
        inputName={inputName}
        label={label}
      />
    );
  });
};
