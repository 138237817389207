import { ModalBody, Modal, ModalHeader } from "reactstrap";
import React from "react";

export const FullScreenModal = ({ headerContent, bodyContent, isOpen, toggle }) => {
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} className="full-screen m-5" scrollable contentClassName="bg-light p-3 pt-2">
        <ModalHeader toggle={toggle} className="border-bottom-0 p-0 ms-4">
          {headerContent}
        </ModalHeader>
        <ModalBody className="bg-white rounded-4 m-4 mt-0">{bodyContent}</ModalBody>
      </Modal>
    </>
  );
};
