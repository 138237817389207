import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { EditBenefitPullTypeModal } from "./EditBenefitPullTypeModal";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { AdministrateResourceDiscardButton } from "../../components/AdministrateResourceDiscardButton";
import { History } from "../../tools/sandbox/release/History";

export const BenefitPullTypeActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();

  const [canUpdate] = useUpdatePermission("BenefitPullType", row.original.id);
  const [canDestroy] = useDestroyPermission("BenefitPullType", row.original.id);

  return (
    <>
      {canUpdate && (
        <EditBenefitPullTypeModal
          color="link"
          benefitPullType={row.original}
          onUpdate={(pullType) => {
            refresh();
            setMessage(`Added Benefit Pull Type ${pullType.id}`);
          }}
        />
      )}
      {canDestroy && (
        <AdministrateResourceDiscardButton
          label="Delete"
          discardUrl={`/admin/benefit_pull_types/${row.original.id}/discard`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Benefit Pull Type ${row.original.id}.`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
      <History entityName="BenefitPullType" subjectId={row.original.id ?? "unspecified"} />
    </>
  );
};
