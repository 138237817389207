import React, { useRef } from "react";
import { Button } from "reactstrap";
import { useClickOutsideHandler } from "../util/hooks";
import cn from "classnames";

export const Drawer = ({ className, open, onClose, children, ...rest }) => {
  const drawerRef = useRef();
  useClickOutsideHandler(drawerRef, onClose);

  return (
    <div ref={drawerRef} className={cn("drawer", !open && "d-none", className)} {...rest}>
      {onClose && (
        <div className="d-flex flex-row px-3 pt-3">
          <Button onClick={onClose} close />
        </div>
      )}

      <div className="p-3">{children}</div>
    </div>
  );
};
