import { Badge, UncontrolledTooltip as Tooltip } from "reactstrap";
import { useId } from "react";

// I'm making this comment to make easier in case of revert
// const PAYOR_PATHS = window.payorPaths;
const CONFIGURED_PAYOR_PATHS = window.configuredPayorPaths;

export const PAYOR_PATH_OPTIONS = CONFIGURED_PAYOR_PATHS.map((el) => ({
  label: el.path,
  value: el.path,
}));

function PayorPath({ path, parentId }) {
  const elementId = parentId + "-" + path.abbreviation;
  return (
    <span className="me-1">
      <Badge pill color={path.color || "secondary"} id={elementId} style={{ width: "30px" }}>
        {path.abbreviation}
      </Badge>
      <Tooltip target={elementId}>{path.label}</Tooltip>
    </span>
  );
}

export function PayorPaths({ paths }) {
  // React generates Ids with colons, but bootstrap errors on ids with colons
  const elementId = useId().replace(/:/g, "");
  // Assumes every .abbreviation is unique
  return (
    <div className="my-1">
      {paths && paths.map((path, index) => <PayorPath path={path} parentId={elementId} key={path.abbreviation} />)}
    </div>
  );
}
