import React, { useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { adminApi as api } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { SeeqerReflexMappingForm } from "./SeeqerReflexMappingForm";

export const CreateSeeqerReflexMappingModal = ({ onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);

    try {
      const res = await api.post("/admin/seeqer_reflex_mappings", snakifyKeys({ seeqerReflexMapping: values }));

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add New seeQer Reflex Status Mapping
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add seeqer Reflex Status Mapping</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate}>{(props) => <SeeqerReflexMappingForm {...props} />}</Form>
        </ModalBody>
      </Modal>
    </>
  );
};
