import { createRoot } from "react-dom/client";

import { SystemConfigApp } from "../system_config/SystemConfigApp";

export const initSystemConfig = () => {
  const appMount = document.getElementById("system_config_app");

  if (appMount) {
    const root = createRoot(appMount);
    root.render(<SystemConfigApp showSandbox={appMount.dataset.showSandbox === "true"} />);
  }
};
