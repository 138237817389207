import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../../components/AdministrateTable";
import { useDestroyPermission, useUpdatePermission } from "../../../utils";
import { EditPaperResponseModal } from "./EditPaperResponseModal";
import { History } from "../../../tools/sandbox/release/History";

export const PaperResponseActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("PaperResponse", row.original.id);
  const [canUpdate] = useUpdatePermission("PaperResponse", row.original.id);

  return (
    <>
      {canUpdate && (
        <EditPaperResponseModal
          color="link"
          paperResponse={row.original}
          onUpdate={(response) => {
            refresh();
            setMessage(`Updated Paper Response ${response.id}.`);
          }}
        />
      )}

      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/paper_responses/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Paper Response ${row.original.id}.`);
          }}
          onError={setError}
        />
      )}

      <History entityName="PaperResponse" subjectId={row.original.id} />
    </>
  );
};
