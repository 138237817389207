import React, { useState } from "react";
import { CollapsePanel } from "./CollapsePanel";

export const SeerezOverview = ({ attachments, associations, comments }) => {
  const [isAttachmentsOpen, setAttachmentsIsOpen] = useState(false);
  const [isLinkedCasesOpen, setLinkedCasesIsOpen] = useState(false);
  const toogleAttachments = () => setAttachmentsIsOpen(!isAttachmentsOpen);

  return (
    <>
      <h3 className="text-dark mb-3 fw-medium">Overview</h3>
      <div className="d-flex flex-column gap-3">
        <CollapsePanel labelText="Attachments">
          {attachments &&
            attachments.map((attachment) => (
              <div key={attachment.id}>
                <a href={attachment.url}>{attachment.label}</a>
                <br />
                <mark>Uploaded At{attachment.uploaded_at.substr(0, 10)}</mark>
              </div>
            ))}
        </CollapsePanel>
        <CollapsePanel labelText="Linked Cases"></CollapsePanel>
        <CollapsePanel labelText="Case Comments">
          {comments &&
            comments.map((comment) => (
              <div key={comment.id}>
                <span>{comment.content} </span>
                <br />
                <span className="text-secondary">{comment.updated_at}</span>
              </div>
            ))}
        </CollapsePanel>
      </div>
    </>
  );
};
