import React from "react";
import { TokenCell } from "../components/grid/cells/TokenCell";
import { VIEW_REQUEST } from "../components/grid/columns";
import { DateFilter } from "../components/inputs";
import { formatDateTime, formatLabName } from "../util/formatters";
import { UnmappedClinicMatchActionsCell } from "./UnmappedClinicMatchActionsCell";

export const COLUMNS = [
  VIEW_REQUEST,
  {
    Header: "ID",
    id: "id",
    accessor: "id",
    Cell: TokenCell,
    width: 75,
  },
  {
    Header: "Public ID",
    id: "public_id",
    accessor: "public_id",
    Cell: TokenCell,
  },
  {
    Header: "Lab",
    id: "lab",
    accessor: formatLabName,
  },
  {
    Header: "Clinic ID",
    id: "external_clinic_id",
    accessor: "external_clinic_id",
    Cell: TokenCell,
  },
  {
    Header: "External Clinic Name",
    id: "external_clinic_name",
    accessor: "external_clinic_name",
  },
  {
    Header: "Uploaded Date",
    id: "created_at",
    accessor: (r) => formatDateTime(r.created_at),
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    Header: "",
    disableFilters: true,
    disableSortBy: true,
    Cell: UnmappedClinicMatchActionsCell,
  },
];
