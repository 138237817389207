import axios from "axios";
import { isMatch } from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { CaseSearchForm } from "./CaseSearchForm";
import { encodeTableState } from "../../../util/table";
import { isBlank } from "../../../util/helpers";
import { api } from "../../../util/api";
import { PersistantReactTable } from "../../../components/grid/PersistantReactTable";
import { INTERNAL_COLUMNS } from "./internal_columns";
import { EXTERNAL_COLUMNS } from "./external_columns";
import { makeColumns } from "../../../system_config/utils";

const serializeFilters = ({
  patientFirst,
  patientLast,
  patientDob,
  labs,
  orderId,
  patientId,
  payors,
  memberId,
  statuses,
  referenceNumber,
  startDate,
  endDate,
  caseId,
}) => {
  let filters = [];
  if (!caseId) {
    filters.push(
      {
        id: "patient_first",
        value: patientFirst,
      },
      {
        id: "patient_last",
        value: patientLast,
      },
      {
        id: "patient_dob",
        value: patientDob,
      },
      {
        id: "order_id",
        value: orderId,
      },
      {
        id: "patient_id",
        value: patientId,
      },
      {
        id: "member_id",
        value: memberId,
      },
      {
        id: "reference_number",
        value: referenceNumber,
      },
      {
        id: "created_at",
        value: {
          operation: "between",
          args: [startDate, endDate],
        },
      },
      {
        id: "lab",
        value: labs,
      },
      {
        id: "payor",
        value: payors,
      },
      {
        id: "status",
        value: statuses,
      }
    );
  } else {
    filters.push({
      id: "public_id",
      value: caseId,
    });
  }

  return { filtered: filters.filter((f) => !isBlank(f?.value)) };
};

const useColumns = makeColumns((isInternal) => {
  return isInternal ? INTERNAL_COLUMNS : EXTERNAL_COLUMNS;
});

export const CaseSearch = ({ isInternal, isPharmacyClinicUser, isOffshoreAccount, searchableStatuses }) => {
  const tableRef = useRef();

  const [pages, setPages] = useState(0);
  const [pageLength, setPageLength] = useState(0);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(null);

  const pageSize = pageLength.length > 0 ? pageLength.length : 20;

  const columns = useColumns(isInternal);

  const searchCases = (filters) => {
    setFilters((prevFilters) => {
      if (isMatch(prevFilters, filters)) {
        return;
      }

      return filters;
    });

    tableRef.current.resetPage();
  };

  const fetchData = useCallback(
    async (rawTableState) => {
      if (!filters) {
        return;
      }

      setLoading(true);

      try {
        let dataReq = await api.get("/cases.json", {
          params: {
            ...encodeTableState(rawTableState),
            ...serializeFilters(filters),
            case_search: true,
          },
        });

        setResults(dataReq.data.rows);
        setPages(dataReq.data.pages);
        setPageLength(dataReq.data.rows.length);
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.error(e);
        }
      } finally {
        setLoading(false);
      }
    },
    [filters]
  );

  return (
    <>
      <CaseSearchForm
        handleSubmit={searchCases}
        isPharmacyClinicUser={isPharmacyClinicUser}
        isOffshoreAccount={isOffshoreAccount}
        searchableStatuses={searchableStatuses}
      />

      <div className="mt-5">
        <PersistantReactTable
          ref={tableRef}
          columns={columns}
          data={results || []}
          disableFilters={true}
          showPagination={true}
          onFetchData={fetchData}
          pages={pages}
          defaultPageSize={pageSize}
          defaultSorted={[]}
          loading={loading}
        />
      </div>
    </>
  );
};
