import React, { useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { PayorMemberPrefixForm } from "./PayorMemberPrefixForm";

export const CreatePayorMemberPrefixModal = ({ onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleAdd = async (params) => {
    setError(null);

    try {
      const { data } = await api.post("/admin/payor_prefix_mappings", { payor_prefix_mapping: snakifyKeys(params) });

      if (onAdd) {
        onAdd(data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Prefix...
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Prefix</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleAdd}>{(props) => <PayorMemberPrefixForm {...props} />}</Form>
        </ModalBody>
      </Modal>
    </>
  );
};
