import React, { useState } from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { EditClinicPayorMappingModal } from "./EditClinicPayorMappingModal";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const ClinicPayorMappingActionCell = ({ clinicId, ...row }) => {
  const [canUpdate] = useUpdatePermission("ClinicPayorMapping", row.original.id);
  const [canDestroy] = useDestroyPermission("ClinicPayorMapping", row.original.id);
  const [saving, setSaving] = useState(false);
  const { refresh, setMessage, setError } = useTableContext();

  const mapping = row.original;
  const id = mapping.id;

  const onSave = (msg) => {
    setSaving(true);
    try {
      refresh();
      setMessage(msg);
    } catch (err) {
      setError(msg);
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      {canUpdate && (
        <EditClinicPayorMappingModal clinicId={clinicId} mapping={mapping} onSave={onSave} color="link" className="secondary" />
      )}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          label="Remove"
          deleteUrl={`/admin/clinic_payor_mappings/${id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Payor mapping ${row.original.dashboardDisplayName}.`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}

      <History entityName={"ClinicPayorMapping"} subjectId={row.original.id} />
    </>
  );
};
