import React, { useState } from "react";
import { ConfirmDeleteModal } from "../../../components/ConfirmDeleteModal";
import { snakifyKeys } from "../../../util/helpers";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi } from "../../../util/api";

export const MassArchiveTestsButton = ({ labName, labId, onArchive, onError, archive = true, ...rest }) => {
  const [archiving, setArchiving] = useState(false);
  const action = archive ? "Archive" : "Unarchive";

  const handleArchive = async () => {
    setArchiving(true);
    try {
      await adminApi.post("/admin/tests/mass_archive_tests", snakifyKeys({ massArchiveTests: { labId, archive } }));

      if (onArchive) {
        onArchive(archive);
      }
    } catch (err) {
      if (onError) {
        onError(err);
      }
    } finally {
      setArchiving(false);
    }
  };

  return (
    <ConfirmDeleteModal
      Target={
        <LoadingButton loading={archiving} {...rest}>
          {`${action} All`}
        </LoadingButton>
      }
      onDelete={handleArchive}
      confirmMessage={`Are you sure you want to ${action} all tests?`}
      confirmLabel={action}
      title={`${action} all tests for ${labName}`}
    />
  );
};
