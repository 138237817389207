import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { SelectInput } from "../../../../components/inputs/final_form/SelectInput";
import { LoadingButton } from "../../../../components/LoadingButton";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";
import { adminApi as api } from "../../../../util/api";
import { FormGroup } from "reactstrap";
import { FormRow } from "../../../../components/forms/FormRow";
import { OnChange } from "react-final-form-listeners";

const getSubstatusOptions = async (status, setSubstatusOptions) => {
  try {
    const { data } = await api.get(`/admin/enums/prior_auth_substatuses.json?status=${status}`);
    setSubstatusOptions(data.resources);
  } catch (err) {
    console.error(err);
  }
};

export const FollowupTaskConfigForm = ({ handleSubmit, submitting }) => {
  const [status, setStatus] = useState("");
  const [substatusOptions, setSubstatusOptions] = useState([]);

  const handleStatusChange = (selected) => {
    setStatus(selected);
    getSubstatusOptions(selected, setSubstatusOptions);
  };

  useEffect(() => {
    getSubstatusOptions(status, setSubstatusOptions);
  }, [status]);

  return (
    <FormGroup>
      <FormRow>
        <Field component={TextInput} type="hidden" name="workflow" />
      </FormRow>

      <FormRow>
        <Field component={AdministrateSelectInput} resourceUrl="/admin/payors.json" label="Payor" name="payorId" searchParam="name" />
      </FormRow>

      <FormRow>
        <Field component={TextInput} type="number" name="daysUntilFollowup" label="Days Until Follow-up" required />
      </FormRow>

      <FormRow>
        <Field
          component={AdministrateSelectInput}
          resourceUrl="/admin/enums/prior_auth_statuses.json"
          label="To Status"
          name="toStatus"
          required
          labelKey="label"
        />

        <OnChange name="toStatus">{handleStatusChange}</OnChange>
      </FormRow>

      <FormRow>
        <Field
          component={(props) => <SelectInput options={substatusOptions} {...props} />}
          label="To Substatus"
          name="toSubstatus"
          includeBlank
        />
      </FormRow>

      <FormRow>
        <Field
          component={AdministrateSelectInput}
          resourceUrl="/admin/enums/prior_auth_statuses.json"
          label="From Status"
          name="fromStatus"
          labelKey="label"
        />
      </FormRow>

      <FormRow>
        <Field name="title" component={TextInput} type="text" label="Task Name" required />
      </FormRow>

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </FormGroup>
  );
};
