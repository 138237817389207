import React, { useCallback } from "react";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";

export const ImportPayorGroupsModal = ({ onImport, ...rest }) => {
  const handleImport = useCallback(
    async (values) => {
      const params = {
        file: {
          bytes: values.file,
        },
      };
      const res = await api.post("/admin/payor_groups/import.json", snakifyKeys(params));

      onImport?.(res.data?.meta?.message, res.data?.meta?.error_details);
    },
    [onImport]
  );

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Import Payor Groups..."
      exampleDownloadPath="/admin/payor_groups/example"
    />
  );
};
