import _ from "lodash";
import { BootstrapRadio } from "./BootstrapRadio";
import React, { useCallback, useMemo } from "react";

export const RadioFilter = ({ options, column: { filterValue, setFilter } }) => {
  const enumOptions = options.enumOptions;

  const handleChange = useCallback(
    (e) => {
      setFilter(e);
    },
    [setFilter]
  );

  const selected = useMemo(() => {
    return !_.isNil(filterValue) ? _.find(enumOptions, (o) => o.value === filterValue).value : "all";
  }, [filterValue, enumOptions]);

  return <BootstrapRadio value={selected} onChange={handleChange} options={options} />;
};
