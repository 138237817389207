import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { MyCasesPriority } from "./my_cases_priority/MyCasesPriority";

export const HomePage = () => {
  return (
    <>
      <SystemConfigLayout title="System Settings">
        <MyCasesPriority />
      </SystemConfigLayout>
    </>
  );
};
