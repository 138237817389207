import React from "react";
import {
  CLINIC_FOLLOWUP_STEPS,
  MI_DOCUMENT_REVIEW_STEP,
  MI_CALL_CLINIC_STEP,
  MI_ALREADY_CONTACTED_CLINIC_STEP,
  MISSING_PATIENT_DEMO_SUBSTATUS,
  NO_DOCS_CLINICAL_INFO_OR_DEMOGRAPHICS_SUBSTATUS,
} from "./screens/missinginfo_steps";
import { Wizard } from "./Wizard";
import { initialClinicAnswers } from "./MissingInfoFollowupWizard";

export const ClinicFollowupWizard = ({
  caseId,
  caseUrl,
  commentsUrl,
  labTests,
  caseData,
  statusUrl,
  assigneeUrl,
  snoozeUrl,
  tasksUrl,
  nextStepUrl,
  submitUrl,
  miInfoUrl,
  missingFields,
  createMiContactAttempt,
  updateCase,
  loading,
  moveToStepUrl,
  substatus,
  hasSupplementalDocuments,
  alreadyContacted,
}) => {
  let initialAnswers = _.clone(initialClinicAnswers);
  let initialStep = MI_CALL_CLINIC_STEP;

  if (missingFields) {
    initialAnswers.missingInfoFields = missingFields;
  }

  if (substatus) {
    initialAnswers.substatus = substatus;
  }

  if (
    (substatus === MISSING_PATIENT_DEMO_SUBSTATUS || substatus === NO_DOCS_CLINICAL_INFO_OR_DEMOGRAPHICS_SUBSTATUS) &&
    hasSupplementalDocuments
  ) {
    initialStep = MI_DOCUMENT_REVIEW_STEP;
  }

  if (alreadyContacted) {
    initialStep = MI_ALREADY_CONTACTED_CLINIC_STEP;
  }

  return (
    <Wizard
      name="Missing Info Clinic Followup"
      initialStep={initialStep}
      initialAnswers={initialAnswers}
      caseId={caseId}
      steps={CLINIC_FOLLOWUP_STEPS}
      caseUrl={caseUrl}
      commentsUrl={commentsUrl}
      labTests={labTests}
      caseData={caseData}
      statusUrl={statusUrl}
      assigneeUrl={assigneeUrl}
      snoozeUrl={snoozeUrl}
      tasksUrl={tasksUrl}
      nextStepUrl={nextStepUrl}
      submitUrl={submitUrl}
      miInfoUrl={miInfoUrl}
      missingFields={missingFields}
      createMiContactAttempt={createMiContactAttempt}
      updateCase={updateCase}
      loading={loading}
      moveToStepUrl={moveToStepUrl}
    />
  );
};
