import { Download } from "lucide-react";
import React from "react";
import { Button } from "reactstrap";

export const DownloadButton = ({ id, size = "xs", ...props }) => {
  return (
    <Button {...props} className="btn-icon" outline size={size} href={`/operations/fax/${id}/download`} download title="Download Fax">
      <Download />
    </Button>
  );
};
