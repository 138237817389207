import { useMemo } from "react";
import { DetailsList } from "../../../components/DetailsList";

const useUiPathBotAutomationDetails = (automation) => {
  return useMemo(
    () => [
      { label: "Payor names", value: automation.payorNames },
      { label: "Benefit Management Portal", value: automation.benefitManagementPortal.name },
      { label: "Bot Type", value: automation.uiPathBotType },
      { label: "Folder Name", value: automation.uiPathFolderName },
      { label: "Release Name", value: automation.uiPathReleaseName },
      { label: "Enabled", value: automation.enabled },
    ],
    [
      automation.payorNames,
      automation.benefitManagementPortal.name,
      automation.uiPathBotType,
      automation.uiPathFolderName,
      automation.uiPathReleaseName,
      automation.enabled,
    ]
  );
};

export const UiPathBotAutomationReadOnly = ({ automation }) => {
  const values = useUiPathBotAutomationDetails(automation);
  return <DetailsList values={values} />;
};
