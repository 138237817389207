import * as ajvFormats from "ajv-formats/dist/formats";

export const initPrecompileValidatorsDependencies = () => {
  // we need to add the ajv-formats library to the window because
  // this is a dependency of the precompiled code that comes from the server
  window.requirements = {
    "ajv-formats/dist/formats": ajvFormats,
  };

  window.old_require = window.require;
  window.require = (src) => {
    if (window.requirements[src]) return window.requirements[src];
    if (window.old_require) return window.old_require(src);
    return null;
  };
};
