import $ from "jquery";

export const initWebhookOptions = () => {
  $(() => {
    const $webhookForm = $("#new_webhook"),
      availableEvents = $webhookForm.data().availableEvents,
      $resourceSelect = $("#webhook_resource_type"),
      $eventSelect = $("#webhook_event_type"),
      $authorizationTypeField = $("#webhook_authorization_type"),
      $requestHeadersField = $("#webhook_request_headers"),
      $tokenSelect = $("#webhook_token"),
      $secretSelect = $("#webhook_secret"),
      $serviceNameSelect = $("#webhook_service_name");

    const enableHeaderAuthFields = () => {
      setFieldState([$requestHeadersField], false);
      setFieldState([$tokenSelect, $secretSelect, $serviceNameSelect], true);
    };

    const enableSignatureAuthFields = () => {
      setFieldState([$requestHeadersField, $tokenSelect, $secretSelect, $serviceNameSelect], false);
    };

    const handleResourceChange = () => {
      const options = availableEvents[$resourceSelect.val()];
      $eventSelect.empty().append($("<option></option>"));
      $.each(options, (_i, value) => {
        $eventSelect.append($("<option></option>").attr("value", value).text(value));
      });
    };

    const setFieldState = (fields, state) => {
      fields.forEach((field) => field.prop("disabled", state));
    };

    const handleAuthorizationChange = () => {
      const selectedValue = $authorizationTypeField.val();
      if (selectedValue === "header_auth") {
        enableHeaderAuthFields();
      } else if (selectedValue === "signature_auth") {
        enableSignatureAuthFields();
      }
    };

    $resourceSelect.on("change", handleResourceChange);
    $authorizationTypeField.on("change", handleAuthorizationChange);
  });
};
