import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { BlankableField } from "../../../components/inputs/final_form/BlankableField";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { GLOBAL_ROLES } from "../../../util/data/global_roles";
import { formatDateTime, objectToOptions } from "../../../util/formatters";
import { camelizeKeys, snakifyKeys } from "../../../util/helpers";
import { composeValidators, numericInRange, required } from "../../../util/validators";
import { useUpdatePermission, usePermissionCheck } from "../../utils";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput.js";

const globalRoleOptions = objectToOptions(GLOBAL_ROLES);

const payorPathOptions = window.configuredPayorPaths.map((el) => ({
  label: el.path,
  value: el.id,
}));

const labToOption = (lab) => ({ value: lab.id, label: lab.dashboard_display_name });

// This function essentially manually filters and updates
// the resulting json object into something consumable
// serverside. We grab the values we are sending to the api. We filter out the trainedLabTraining objects
// to be just the labs with ids from the trainedLabIds(react field)
// Then we check the activatedLabs, if there are any, we set the activated to true on the trainedLabTraining objects
const transformValues = (values) => {
  const newValues = _.cloneDeep(values);
  newValues.trainedLabTrainings = newValues.trainedLabTrainings.filter((lab) => newValues.trainedLabIds.includes(lab.lab_id));

  newValues.trainedLabTrainings.map((trainingObject) => {
    handleActivation(trainingObject, newValues.activatedLabIds);
  });

  newValues.trainedLabTrainingsAttributes = newValues.trainedLabTrainings;
  delete newValues.activatedLabIds;

  // Hard set sales lead to false if we are not a sales manager
  if (newValues.globalRole !== "sales_manager" && newValues.salesLead) {
    newValues.salesLead = false;
  }

  return newValues;
};

const handleActivation = (trainingObject, activatedLabIds) => {
  activatedLabIds.includes(trainingObject.lab_id) ? (trainingObject.activated = true) : (trainingObject.activated = false);
};

export const UsersGeneralTab = ({ account, accountId, refresh }) => {
  const [canUpdate] = useUpdatePermission("Account", accountId);
  const [canUpdateLabs] = usePermissionCheck("update_trained_activated_labs", "Account", accountId);
  const [canUpdateUserNotes] = usePermissionCheck("update_user_notes", "Account", accountId);

  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [trainingLabOptions, setTrainingLabOptions] = useState(null);

  const handleUpdate = async (values) => {
    setError(null);
    setMessage(null);

    try {
      await api.put(`/admin/accounts/${accountId}/update_with_user`, { account: snakifyKeys(transformValues(values)) });

      setMessage(`Updated user account.`);
      refresh();
    } catch (err) {
      setError(err);
    }
  };

  const initialValues = useMemo(() => camelizeKeys(account), [account]);

  // Grabbing the initial trained Lab options
  const initialTrainedLabOptions = useMemo(() => {
    return account.trained_labs?.map(labToOption) ?? [];
  }, [account]);

  const readOnly = !canUpdate;
  const readOnlyLabs = !canUpdateLabs;
  const readOnlyUserNotes = !canUpdateUserNotes;

  return (
    <>
      <DetailedErrorAlert error={error} />
      {message && <Alert color="info">{message}</Alert>}

      <Form onSubmit={handleUpdate} initialValues={initialValues}>
        {({ handleSubmit, values, submitting, pristine }) => {
          // NOTE: These happen on every render, we can probably optimize with useMemo, but #hotfix
          const showInternalInputs = Boolean(values.globalRole);
          const activatedLabOptions = trainingLabOptions?.filter((lab) => values.trainedLabIds.includes(lab.value));

          return (
            <>
              <Field component={TextInput} required validate={required} label="ID" name="id" readOnly />
              <Field component={TextInput} label="Email/Username" name="email" readOnly />
              <Field component={TextInput} required validate={required} label="First Name" name="firstName" readOnly={readOnly} />
              <Field component={TextInput} required validate={required} label="Last Name" name="lastName" readOnly={readOnly} />
              <BlankableField
                component={SelectInput}
                options={globalRoleOptions}
                includeBlank={true}
                label="Global Role"
                name="globalRole"
                readOnly={readOnly}
              />

              {values.globalRole === "sales_manager" && (
                <Field type="checkbox" component={Switch} label={"Sales Lead"} name="salesLead" readOnly={readOnly} />
              )}

              <Field type="checkbox" component={Switch} label="Quarantine" name="quarantine" id="quarantine" readOnly={readOnly} />
              <Field type="checkbox" component={Switch} label="Active" name="active" id="active" readOnly={readOnly} />
              <Field type="checkbox" component={Switch} label="Offshore" name="offshore" id="offshore" readOnly={readOnly} />

              {showInternalInputs && (
                <>
                  <BlankableField
                    component={TextInput}
                    type="number"
                    min={1}
                    max={120}
                    step={1}
                    required
                    validate={composeValidators(numericInRange(1, 120))}
                    label="Target Number of Assigned Cases"
                    name="targetCaseAssignmentCount"
                    readOnly={readOnly}
                  />

                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/labs.json"
                    onOptionsChange={setTrainingLabOptions}
                    per={100}
                    kept={true}
                    label="Trained Labs"
                    name="trainedLabIds"
                    initialOptions={initialTrainedLabOptions}
                    isMulti
                    readOnly={readOnlyLabs}
                  />
                  <Field
                    component={SelectInput}
                    label="Activated Labs"
                    name="activatedLabIds"
                    options={activatedLabOptions}
                    isMulti
                    readOnly={readOnlyLabs}
                  />
                  <Field
                    component={SelectInput}
                    label="Payor Path Defaults"
                    name="payorPathIds"
                    options={payorPathOptions}
                    isMulti
                    readOnly={readOnlyLabs}
                  />
                  <Field
                    type="checkbox"
                    component={Switch}
                    label={"Process New Cases first in My Cases"}
                    name="processNewCasesFirst"
                    readOnly={readOnly}
                  />
                </>
              )}

              <Field type="checkbox" component={Switch} label={"Show on Assign/Reassign Queue"} name="showInQueue" readOnly={readOnly} />

              <BlankableField component={TextInput} label="User Notes" name="notes" readOnly={readOnlyUserNotes} />
              <Field component={TextInput} label="Created At" name="createdAt" readOnly format={(v) => formatDateTime(v)} />
              <Field component={TextInput} label="Last Logged In" name="lastSignInAt" readOnly format={(v) => formatDateTime(v)} />

              <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine}>
                Save
              </LoadingButton>
            </>
          );
        }}
      </Form>
    </>
  );
};
