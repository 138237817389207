import { useState } from "react";
import { api, extractErrorMessage } from "../../../../util/api";
import { camelizeKeys, snakifyKeys } from "../../../../util/helpers";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { Form } from "react-final-form";
import { MissingInfoCategoryForm } from "./MissingInfoCategoryForm";

export const EditMissingInfoCategoryModal = ({ missingInfoCategory, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleUpdate = async (params) => {
    try {
      const res = await api.patch(`/admin/missing_info_categories/${params.id}`, snakifyKeys(params));

      if (onUpdate) {
        onUpdate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Missing Info Category</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleUpdate} initialValues={camelizeKeys({ ...missingInfoCategory })}>
            {(props) => <MissingInfoCategoryForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
