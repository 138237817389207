import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../../components/AdministrateTable";
import { History } from "../../../tools/sandbox/release/History";
import { useDestroyPermission, useUpdatePermission } from "../../../utils";
import { EditPhoneResponseModal } from "./EditPhoneResponseModal";

export const PhoneResponseActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("PhoneResponse", row.original.id);
  const [canUpdate] = useUpdatePermission("PhoneResponse", row.original.id);

  return (
    <>
      {canUpdate && (
        <EditPhoneResponseModal
          color="link"
          phoneResponse={row.original}
          onUpdate={(response) => {
            refresh();
            setMessage(`Updated Phone Response ${response.id}.`);
          }}
        />
      )}

      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/phone_responses/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Phone Response ${row.original.id}`);
          }}
          onError={setError}
        />
      )}

      <History entityName="PhoneResponse" subjectId={row.original.id} />
    </>
  );
};
