import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { required } from "../../../../util/validators";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";
import { DropzoneInput } from "../../../../components/inputs/final_form/DropzoneInput";
import { LoadingButton } from "../../../../components/LoadingButton";

export const VisualForm = ({ handleSubmit, submitting }) => {
  const maxSize = 5242880;
  return (
    <>
      <Field
        component={DropzoneInput}
        accept="image/jpeg, image/png, image/gif"
        hint="Upload Image (Size limit - 5MB)"
        label="Image/GIF"
        name="completionScreenVisual"
        maxSize={maxSize}
        required
      />

      <Field component={TextInput} required validate={required} label="Image/GIF Description" name="description" />
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/enums/prior_auth_statuses.json"
        label="Status Driving Image/GIF"
        name="status"
        validate={required}
        required
        labelKey="label"
      />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Save
      </LoadingButton>
    </>
  );
};
