import { AlertCircle } from "lucide-react";
import React from "react";
import { Field, Form } from "react-final-form";
import { FormText, ListGroup, ListGroupItem } from "reactstrap";
import { ActionAlert } from "../../../../components/ActionAlert";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../../components/LoadingButton";
import { required } from "../../../../util/validators";
import { WizardContext } from "../../WizardContext";

export const PatientDemographicsNoAnswerScreen = () => {
  return (
    <WizardContext.Consumer>
      {({ exitWorkflow, state, caseData, updateCase, loading, createMiContactAttempt, updateStatus, caseId, setError }) => {
        const onSubmit = async ({ miClinicFaxNumber, miMessage }) => {
          setError(null);

          try {
            // We await these in sequence because we need the miClinicFaxNumber to be
            // saved on the Case _before_ we create the MI Contact Attempt
            await updateCase({ miClinicFaxNumber });
            await createMiContactAttempt(state.answers.miType, miMessage);
            await exitWorkflow(`Automatic MI Followup Started for ${caseId}. Sending faxes to ${miClinicFaxNumber}.`);
          } catch (err) {
            console.error(err);
            setError(`Unexpected Error: ${err.message}`);
          }
        };

        const onReject = async () => {
          setError(null);

          try {
            await updateCase({ closed: true });
            await updateStatus("rejected", "missing_information_not_provided", false);
            await exitWorkflow(`Rejected Case ${caseId}`);
          } catch (err) {
            console.error(err);
            setError(`Unexpected Error: ${err.message}`);
          }
        };

        const missingFields = state.answers.missingInfoFields.filter((f) => f.missing);

        const supplementalDocuments = state.caseData?.supplementalDocuments ?? [];
        const priorAuthRequestAttachments = state.caseData?.priorAuthRequestAttachments ?? [];

        const hasMissingDocuments = supplementalDocuments.length === 0 && priorAuthRequestAttachments.length === 0;

        return missingFields.length == 0 && !hasMissingDocuments ? (
          <NoMissingInfo />
        ) : (
          <>
            <p>
              Two faxes will be sent to the physician's office to collect missing information. Please write a message of what is missing for
              the physician's office below.
            </p>

            <ListGroup className="mb-3">
              {missingFields.map((f) => {
                return (
                  <ListGroupItem key={f.field_key}>
                    <AlertCircle className="me-2 text-warning" />
                    {f.label}
                  </ListGroupItem>
                );
              })}
              {hasMissingDocuments && (
                <ListGroupItem>
                  <AlertCircle className="me-2 text-warning" />
                  Supporting Documents
                </ListGroupItem>
              )}
            </ListGroup>

            <Form
              onSubmit={onSubmit}
              initialValues={{
                miClinicFaxNumber: caseData.workflowInfo.priorAuthRequest.miClinicFaxNumber ?? caseData.clinicInfo.clinicFax,
              }}
            >
              {({ handleSubmit, submitting, values }) => {
                const hasFaxNumber = Boolean(values.miClinicFaxNumber);
                const showRejectButton = !hasFaxNumber || (hasFaxNumber && values.miClinicFaxNumber.length < 10);

                return (
                  <>
                    <div className="mb-3">
                      <Field component={TextInput} type="tel" label="Fax Number" required validate={required} name="miClinicFaxNumber">
                        <FormText>Please provide a fax number for the automated followup contact attempts.</FormText>
                      </Field>
                    </div>

                    <div className="mb-3">
                      <Field component={TextInput} type="textarea" label="Cover Page Comment" required validate={required} name="miMessage">
                        {" "}
                        <FormText className="fw-bold" color="danger">
                          DO NOT PUT PHI IN THIS TEXT BOX.
                        </FormText>
                      </Field>
                    </div>

                    <div className="d-flex flex-row align-items-center">
                      <LoadingButton loading={submitting} disabled={loading || submitting} onClick={handleSubmit} className="me-2">
                        Confirm
                      </LoadingButton>

                      {showRejectButton && (
                        <LoadingButton outline color="danger" disabled={loading || submitting} loading={loading} onClick={onReject}>
                          No Available Fax Number - Reject Case
                        </LoadingButton>
                      )}
                    </div>
                  </>
                );
              }}
            </Form>
          </>
        );
      }}
    </WizardContext.Consumer>
  );
};

const NoMissingInfo = () => {
  return (
    <WizardContext.Consumer>
      {({ setError, loading, continueWithStep, updateStep, updateStatus, state }) => {
        return (
          <ActionAlert
            color="success"
            actions={
              <>
                <LoadingButton
                  color="success"
                  loading={loading}
                  onClick={async () => {
                    setError(null);

                    try {
                      // Wait for all data updates before doing redirect
                      await Promise.all([updateStatus("processing", null, false), updateStep("select_payor", false)]);

                      await continueWithStep("prior_auth", "select_payor");
                    } catch (err) {
                      console.error(err);
                      setError(`Unexpected error: ${err.message}`);
                    }
                  }}
                >
                  Continue
                </LoadingButton>
              </>
            }
          >
            There appear to be no more missing patient demographic fields.
          </ActionAlert>
        );
      }}
    </WizardContext.Consumer>
  );
};
