import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { useUpdatePermission } from "../../utils";
import { NetworkStatusToggle } from "./NetworkStatusToggle";
import { History } from "../../tools/sandbox/release/History";

export const PayorLabNetworkStatusActionCell = ({ payorId, payorName, ...row }) => {
  const [canUpdate] = useUpdatePermission("Payor", payorId);
  const { refresh, setMessage, setError } = useTableContext();
  const { id: labId, labName, payorLabSettingId, inNetwork } = row.original;

  const handleChange = (msg) => {
    setMessage(msg);
    refresh();
  };

  const handleError = (msg) => {
    setError(msg);
    refresh();
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <NetworkStatusToggle
        payorId={payorId}
        payorName={payorName}
        inNetwork={inNetwork}
        payorLabSettingId={payorLabSettingId}
        labId={labId}
        labName={labName}
        onChange={handleChange}
        onError={handleError}
        disabled={!canUpdate}
      />

      <History entityName="PayorLabSetting" subjectId={payorLabSettingId ?? "unspecified"} />
    </div>
  );
};
