import axios from "axios";
import React, { useState, useCallback } from "react";
import { Col } from "reactstrap";

import { BootstrapGrid } from "../components/grid/BootstrapGrid";
import { api } from "../util/api";
import { encodeTableState } from "../util/table";
import { useDebounced } from "../util/hooks";
import { TokenCell } from "../components/grid/cells/TokenCell";

const COLUMNS = [
  {
    Header: "Address Line 1",
    accessor: "street",
  },
  {
    Header: "Address Line 2",
    accessor: "street2",
  },
  {
    Header: "City",
    accessor: "city",
  },
  {
    Header: "State",
    accessor: "state",
  },
  {
    Header: "Zip",
    Cell: TokenCell,
    accessor: "zip",
  },
  {
    Header: "Country",
    accessor: "country",
  },
];

const AddressesGrid = ({ dataUrl }) => {
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(-1);
  const [loading, setLoading] = useState(false);

  const onFetchData = useDebounced(
    useCallback(
      async (rawTableState) => {
        setLoading(true);

        try {
          let dataReq = await api.get(dataUrl, {
            params: encodeTableState(rawTableState, {}),
          });

          setData(dataReq.data.rows);
          setPages(dataReq.data.pages);
        } catch (e) {
          if (!axios.isCancel(e)) {
            console.error(e);
          }
        } finally {
          setLoading(false);
        }
      },
      [dataUrl]
    ),
    400
  );

  return (
    <Col className="py-3">
      <h5>Clinic Addresses</h5>
      <BootstrapGrid
        manual
        disableFilters={false}
        data={data}
        pages={pages}
        loading={loading}
        onFetchData={onFetchData}
        columns={COLUMNS}
        defaultPageSize={10}
      />
    </Col>
  );
};

export default AddressesGrid;
