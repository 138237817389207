import { createRoot } from "react-dom/client";
import { PayorModalButton } from "../components/PayorModal";

export const initPayorModal = () => {
  const container = document.getElementById("payor-modal");

  if (container) {
    const root = createRoot(container);
    const { caseId, payor, buttonProps } = container.dataset;

    root.render(<PayorModalButton caseId={caseId} payor={JSON.parse(payor)} buttonProps={JSON.parse(buttonProps)} />);
  }
};
