import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { formatDateTime, parseCurrency } from "../../../util/formatters";
import { snakifyKeys } from "../../../util/helpers";
import { Icd10CodeForm } from "./Icd10CodeForm";

export const EditIcd10CodeModal = ({ icd10Code, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);

    try {
      const res = await api.patch(`/admin/icd10_codes/${icd10Code.id}`, snakifyKeys(values));

      if (onUpdate) {
        onUpdate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const initialValues = useMemo(
    () => ({
      ...icd10Code,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(icd10Code)]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Edit Icd10 Code</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <dl>
            <dt>Created At</dt>
            <dd>{formatDateTime(icd10Code.createdAt)}</dd>

            <dt>Last Updated At</dt>
            <dd>{formatDateTime(icd10Code.updatedAt)}</dd>
          </dl>

          <Form onSubmit={handleCreate} initialValues={initialValues}>
            {(props) => <Icd10CodeForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
