import { useState } from "react";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Form } from "react-final-form";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { LabNoteForm } from "./LabNotesForm";
import { snakifyKeys } from "../../../util/helpers";

export const EditLabNoteModal = ({ labId, note, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleUpdate = async (values) => {
    setError(null);
    try {
      values.lab_id = labId;
      const res = await api.patch(`/admin/lab_notes/${note.id}`, { lab_note: snakifyKeys(values) });

      if (onUpdate) {
        onUpdate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Note</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={handleUpdate} initialValues={note}>
            {(props) => <LabNoteForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
