import { useMemo } from "react";
import { Link } from "react-router-dom";
import { deepCompact } from "../../../util/helpers";
import { AdministrateTable } from "../../components/AdministrateTable";
import { Routes } from "../../Routes";
import { makeColumns, useAdministrateTable } from "../../utils";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    disableFilters: true,
  },
  {
    id: "source",
    accessor: "source",
    Header: "Source",
    Cell: ({ row, value }) => <Link to={Routes.benefitsDetail.url(row.original.caseType, row.original.id)}>{value}</Link>,
    disableFilters: true,
  },
  {
    id: "status_code",
    accessor: "statusCode",
    Header: "Status Code",
    disableFilters: true,
  },
  {
    id: "source_transaction_id",
    accessor: "sourceTransactionId",
    Header: "Transaction ID",
    disableFilters: true,
  },
]);

export const PullBenefitsLogListing = ({ caseId }) => {
  const columns = useColumns();

  const extraParams = useMemo(() => deepCompact({ public_id: caseId }), [caseId]);

  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  return (
    <AdministrateTable
      resourceUrl={"/admin/benefits_log.json"}
      extraParams={extraParams}
      resourceName={"benefits_logs"}
      onFilteredChange={setTableFilters}
      columns={columns}
      ref={tableRef}
    />
  );
};
