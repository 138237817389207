import React, { useState } from "react";
import { ActionAlert } from "../../../components/ActionAlert";
import { FaxModal } from "../../../components/FaxModal";
import { WizardContext } from "../WizardContext";
import { LoadingButton } from "../../../components/LoadingButton";
import { Check } from "lucide-react";

export const ConfirmFaxScreen = () => {
  const [faxSent, setFaxSent] = useState(false);

  return (
    <WizardContext.Consumer>
      {({ caseId, createFollowupTask, loading }) => (
        <>
          <ActionAlert
            color="warning"
            actions={
              <LoadingButton
                color="warning"
                loading={loading}
                onClick={async () => createFollowupTask(3, "Follow up on Paper Submission - Confirm Fax")}
              >
                Ok
              </LoadingButton>
            }
          >
            Confirm Fax and Department Details. A follow-up task will be created for <strong>3 days from today</strong>.
          </ActionAlert>

          <div className="text-end">
            <FaxModal
              caseId={caseId}
              disabled={faxSent}
              onSend={() => setFaxSent(true)}
              requireAttachments={true}
              buttonProps={{ outline: true }}
              buttonLabel={
                faxSent ? (
                  <>
                    <Check className="me-2" /> Fax Sent
                  </>
                ) : (
                  "Re-send Fax"
                )
              }
            />
          </div>
        </>
      )}
    </WizardContext.Consumer>
  );
};
