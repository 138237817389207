import { Card, CardBody } from "reactstrap";
import { ProgressList } from "../../../../components/ProgressList";
import { InfoGrid } from "../../../../components/grid/InfoGrid";

export const ProgressListDemo = () => {
  const contentItems = [
    { label: "Order Type", content: "Prior Authorization" },
    { label: "Member ID", content: "922137101" },
    { label: "Primary Insurance", content: "United Healthcare" },
    { label: "James, Smith", content: "12/12/1993, Male", labelClassNames: "fw-bolder" },
  ];

  const secondContentItems = [
    { label: "Physician NPI", content: "12345678480", copyable: true },
    { label: "Physician Practice Name", content: "Helpline Hospital, 3021932" },
    { label: "Physician Name", content: "James, Parker" },
    { label: "Generic Counseler Name", content: "Danial, Sams", copyable: true },
  ];

  const defaultContent = () => {
    return (
      <Card className="bg-success bg-opacity-10 border border-0">
        <CardBody>
          <InfoGrid items={contentItems} loaded={true} />
        </CardBody>
      </Card>
    );
  };

  const secondExampleContent = () => {
    return (
      <Card className="bg-success bg-opacity-10 border border-0">
        <CardBody>
          <InfoGrid items={secondContentItems} loaded={true} />
        </CardBody>
      </Card>
    );
  };

  const firstExampleItems = [
    { label: "Patient & Insurance Info", content: defaultContent(), status: "done", action: "#", actionIcon: "edit" },
    { label: "Provider & Practice Info", status: "inProgress" },
    { label: "Test Info & Request Info", status: "pending" },
  ];

  const secondExampleItems = [
    { label: "Patient & Insurance Info", content: defaultContent(), status: "done", action: "#", actionIcon: "edit" },
    { label: "Provider & Practice Info", content: secondExampleContent(), status: "done", action: "#", actionIcon: "edit" },
    { label: "Test Info & Request Info", status: "inProgress" },
  ];

  const thirdExampleItems = [
    { label: "Patient & Insurance Info", content: defaultContent(), status: "done", action: "#", actionIcon: "edit" },
    { label: "Provider & Practice Info", status: "edit", action: "#", actionIcon: "edit" },
    { label: "Test Info & Request Info", status: "done", action: "#", actionIcon: "edit" },
  ];

  return (
    <>
      <div className="mt-4">
        <Card className="d-flex flex-row mb-3 border border-0">
          <CardBody className="h-50 border rounded-2">
            <h5 className="mb-4">Progress List (Example 1)</h5>
            <ProgressList items={firstExampleItems} />
          </CardBody>

          <CardBody className="border rounded-2 ms-3">
            <h5 className="mb-4">Progress List (Example 2)</h5>
            <ProgressList items={secondExampleItems} />
          </CardBody>

          <CardBody className="h-50 border rounded-2 ms-3">
            <h5 className="mb-4">Progress List (Example 3)</h5>
            <ProgressList items={thirdExampleItems} />
          </CardBody>
        </Card>
      </div>
    </>
  );
};
