import { useState } from "react";
import { Link } from "react-router-dom";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { Switch } from "../../../components/inputs/Switch";
import { Toolbar } from "../../../components/Toolbar";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { Routes } from "../../Routes";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission, useUpdatePermission } from "../../utils";
import { AddLabsModal } from "../components/AddLabsModal";
import { ClinicSupportedLabActionCell } from "../components/ClinicSupportedLabActionCell";
import { ImportClinicLabsModal } from "../components/ImportClinicLabsModal";
import { LabReflexToPAActionCell } from "../components/LabReflexToPAActionCell";
import { UseLabPricingActionCell } from "../components/UseLabPricingActionCell";

const useColumns = makeColumns((clinicId) => [
  {
    id: "lab",
    accessor: "lab",
    Header: "Lab",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/labs.json?lab[direction]=asc&lab[order]=lab_name&per_page=100"
        valueKey="lab_name"
        labelKey="lab_name"
        kept={true}
      />
    ),
    Cell: ({ row, value }) => (
      <Link to={Routes.lab.url(value.id)}>
        <span className="token">{value.labName}</span>
      </Link>
    ),
  },
  {
    id: "reflex_to_pa",
    accessor: "reflexToPa",
    Header: "Reflex To PA",
    disableSortBy: true,
    className: "action-cell",
    Cell: LabReflexToPAActionCell,
    Filter: BoolFilter,
  },
  {
    id: "use_lab_pricing",
    accessor: "useLabPricing",
    Header: "Use Lab Pricing",
    disableSortBy: true,
    className: "action-cell",
    Cell: UseLabPricingActionCell,
    Filter: BoolFilter,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ClinicSupportedLabActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const ClinicsSupportedLabsTab = ({ clinic, clinicId }) => {
  const [canCreate] = useCreatePermission("ClinicLabPermission", clinicId);
  const [canUpdate] = useUpdatePermission("Clinic", clinicId);
  const columns = useColumns(clinicId);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const extraParams = { clinic: { id: clinicId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onAdd = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/clinic_lab_permissions/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreate && (
            <>
              <ImportClinicLabsModal clinicId={clinicId} outline className="me-2" onImport={onImport} />
              <AddLabsModal clinicId={clinicId} color="primary" onAdd={onAdd} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/clinic_lab_permissions.json"}
        extraParams={extraParams}
        resourceName={"clinic_lab_permission"}
        permissions={[UPDATE, DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
