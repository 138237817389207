import { Link } from "react-router-dom";
import _ from "lodash";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateExportButton } from "../../components/AdministrateExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { Routes } from "../../Routes";
import { makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { AddPortalPayorModal } from "../components/AddPortalPayorModal";
import { ImportPayorsModal } from "../components/ImportPayorsModal";
import { PortalPayorActionCell } from "../components/PortalPayorActionCell";
import { EnumFilter } from "../../../components/inputs/EnumFilter";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";

export const TIER_OPTIONS = [
  { value: "tier_1", label: "Tier 1" },
  { value: "tier_2", label: "Tier 2" },
];

const useColumns = makeColumns((portal) => [
  {
    id: "payor.id",
    accessor: "payor.name",
    Header: "Payor",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/payors.json?payor[direction]=asc&payor[order]=payor_name&per_page=100"
        valueKey="id"
        searchParam="name"
        labelKey="name"
        kept={true}
      />
    ),
    Cell: ({ row, value }) => (
      <Link to={Routes.payor.url(row.original.payor?.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
  },
  {
    id: "tier",
    accessor: (x) => _.find(TIER_OPTIONS, { value: x.tier })?.label,
    Header: "Tier",
    Filter: (props) => <EnumFilter {...props} options={TIER_OPTIONS} />,
  },
  {
    id: "actions",
    Header: "Actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => <PortalPayorActionCell portal={portal} {...row} />,
  },
]);

export const PortalPayorsTab = ({ portal, portalId }) => {
  const [canCreate] = useCreatePermission("PayorPortalMapping");
  const columns = useColumns(portal);
  const hiddenColumn = [];
  const supportsTiers = portal.supports_tiers;

  if (!supportsTiers) {
    hiddenColumn.push("tier");
  }

  const extraParams = { benefit_management_portal: { id: portalId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onCreate = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateExportButton outline className="me-2" exportUrl="/admin/payor_portal_mappings.csv" filters={allFilters} />
          {canCreate && (
            <>
              <ImportPayorsModal outline className="me-2" onImport={onImport} portalId={portalId} />
              <AddPortalPayorModal color="primary" portal={portal} onAdd={onCreate} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/payor_portal_mappings.json"}
        extraParams={extraParams}
        resourceName={"payor_portal_mapping"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
        hiddenColumns={hiddenColumn}
      />
    </>
  );
};
