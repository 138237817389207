import React, { useState, useCallback } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { api, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { CaseTipForm } from "./CaseTipForm";
import { ImportModal } from "../../../components/ImportModal";

export const ImportCaseTipsModal = ({ onImport, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      file: {
        bytes: values.file,
      },
    };

    const res = await api.post(`/admin/case_tips/import`, snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message, res.data?.meta?.error_details);
    }
  };

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Add Case Tips from CSV..."
      exampleDownloadPath="/admin/case_tips/example"
    />
  );
};
