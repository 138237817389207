import React from "react";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { EditCaseTypeCptsModal } from "./EditCaseTypeCptsModal";
import { History } from "../../tools/sandbox/release/History";

export const CaseTypeCptsActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("CaseTypeTestMapping", row.original.id);
  const [canUpdate] = useUpdatePermission("CaseTypeTestMapping", row.original.id);

  const handleUpdate = () => {
    setMessage(`Updated ${row.original.commercialName}`);
    refresh();
  };

  const handleDelete = () => {
    setMessage(`Updated ${row.original.commercialName}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditCaseTypeCptsModal color="link" onUpdate={handleUpdate} mapping={row.original} />}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          label="Delete"
          deleteUrl={`/admin/case_type_test_mappings/${row.original.id}`}
          onSuccess={handleDelete}
          onError={(msg) => setError(msg)}
        />
      )}
      <History entityName={"CaseTypeTestMapping"} subjectId={row.original.id} />
    </>
  );
};
