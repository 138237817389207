import _ from "lodash";
import React from "react";
import { StyledSelect as Select, StyledAsyncSelect as AsyncSelect } from "../StyledSelect";
import { FormText } from "reactstrap";
import { reactSelectColors } from "../ReactSelectWidget";
import { InputWrapper, serializeInput } from "./InputWrapper";
import { useRef } from "react";

export const useSelectInputHandlers = (isMulti, value, onChange) => {
  const filterFunc = isMulti ? _.filter : _.find;
  const selectFunc = isMulti ? (o) => _.includes(value, o.value) : (o) => o.value === value;

  const handleChange = isMulti
    ? (selected) => {
        if (selected) {
          onChange(selected.map((item) => item.value));
        } else {
          onChange([]);
        }
      }
    : (selected) => {
        if (selected) {
          onChange(selected.value);
        } else {
          onChange(null);
        }
      };

  return { filterFunc, selectFunc, handleChange };
};

export const AsyncSelectInput = ({ children, input, label, meta, loadOptions, defaultOptions, disabled, readOnly, ...rest }) => {
  const ref = useRef();
  const { filterFunc, selectFunc, handleChange } = useSelectInputHandlers(rest.isMulti, input.value, input.onChange);
  const invalid = Boolean(meta.touched && meta.error);

  return (
    <InputWrapper label={label} required={rest.required}>
      <AsyncSelect
        {...input}
        {...rest}
        ref={ref}
        searchable
        styles={reactSelectColors(invalid)}
        value={filterFunc(defaultOptions, selectFunc)}
        onChange={handleChange}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        isDisabled={disabled || readOnly}
        cacheOptions
      />
      {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
      {children}
    </InputWrapper>
  );
};

export const SelectInput = React.forwardRef(
  ({ children, input, label, meta, disabled, readOnly, includeBlank = false, onFocus, onUpdate, ...rest }, ref) => {
    const { filterFunc, selectFunc, handleChange: baseHandleChange } = useSelectInputHandlers(rest.isMulti, input.value, input.onChange);
    const invalid = Boolean(meta.touched && meta.error);

    const handleChange = (selected) => {
      baseHandleChange(selected);

      if (onUpdate) {
        onUpdate(selected);
      }
    };

    if (includeBlank) {
      rest.options = [{ label: "Please Select...", value: null }, ...rest.options];
    }

    return (
      <InputWrapper label={label} required={rest.required} data-testid={rest["data-testid"]}>
        <Select
          {...input}
          {...rest}
          styles={reactSelectColors(invalid)}
          value={filterFunc(rest.options, selectFunc)}
          onChange={handleChange}
          onFocus={onFocus}
          isDisabled={disabled || readOnly}
          ref={ref}
          searchable
        />

        {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
        {children}
      </InputWrapper>
    );
  }
);

export const SerializedSelectInput = serializeInput(SelectInput);

export const BoolInput = (props) => {
  return (
    <SelectInput
      {...props}
      options={[
        { label: "Yes", value: true },
        { label: "No", value: false },
      ]}
    />
  );
};
