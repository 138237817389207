import { createRoot } from "react-dom/client";
import { DigitalFollowupWizard } from "../../workflow/wizards/DigitalFollowupWizard";

export function initDigitalFollowup() {
  const container = document.getElementById("digital_followup");

  if (container) {
    const root = createRoot(container);

    const {
      caseId,
      caseData,
      benefitsPortal,
      successfulAttempt,
      payorContacts,
      commentsUrl,
      snoozeUrl,
      tasksUrl,
      nextStepUrl,
      statusUrl,
      submitUrl,
    } = container.dataset;

    const parsedPayorContacts = payorContacts ? JSON.parse(payorContacts) : null;
    const parsedCaseData = caseData ? JSON.parse(caseData) : null;
    const parsedBenefitsPortal = benefitsPortal ? JSON.parse(benefitsPortal) : null;
    const parsedSuccessfulAttempt = successfulAttempt ? JSON.parse(successfulAttempt) : null;

    root.render(
      <DigitalFollowupWizard
        payorContacts={parsedPayorContacts}
        caseData={parsedCaseData}
        benefitsPortal={parsedBenefitsPortal}
        successfulAttempt={parsedSuccessfulAttempt}
        caseId={caseId}
        snoozeUrl={snoozeUrl}
        commentsUrl={commentsUrl}
        tasksUrl={tasksUrl}
        nextStepUrl={nextStepUrl}
        statusUrl={statusUrl}
        submitUrl={submitUrl}
      />
    );
  }
}
