import qs from "qs";
import axios, { CancelToken } from "axios";
import _ from "lodash";
import hash from "object-hash";
import { useCallback, useEffect, useState } from "react";
import { isBlank } from "./helpers";

export const CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
export const CURRENT_PATH = window.location.pathname;
export const FLASH_MESSAGE_KEY = "flash_message";
export const FLASH_TYPE_KEY = "flash_type";

export const stringifyParams = (params, encode = false) =>
  qs.stringify(params, {
    arrayFormat: "brackets",
    encode: encode,
  });

export const parseParams = (str) => qs.parse(str);

/**
 * Encodes a single query param
 */
export const encodeAxiosParam = (value) => {
  let v = value;
  if (toString.call(value) === "[object Date]") {
    v = value.toISOString();
  } else if (typeof value === "object") {
    v = JSON.stringify(value);
  }
  return encodeURIComponent(v);
};

/**
 * Serializes params to a query string like axios pre-v1
 *
 * {
 *   object: { a: 1 },                        // object=%7B%22a%22:1%7D
 *   arrayOfArray: [[1, 2, ","], [2]],        // arrayOfArray[]=[1,2,%22,%22]&arrayOfArray[]=[2]
 *   arrayOfNullUndefined: [null, undefined], // arrayOfNullUndefined[]=null&arrayOfNullUndefined[]=undefined
 *   arrayOfObject: [{ id: 1 }, { id: 12 }],  // arrayOfObject[]=%7B%22id%22:1%7D&arrayOfObject[]=%7B%22id%22:12%7D
 *   arrayOfString: ["a", "b", "c"],          // arrayOfString[]=a&arrayOfString[]=b&arrayOfString[]=c
 *   undefinable: undefined,                  // X
 *   nullable: null,                          // X
 *   emptyArray: [],                          // X
 *   emptyString: "",                         // emptyString=
 *   emptyObject: {},                         // emptyObject=%7B%7D
 *   date: new Date("1970-01-01"),            // date=1970-01-01T00:00:00.000Z
 * }
 */
export const serializeQueryParams = (params) => {
  return Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null && (Array.isArray(value) ? value.length > 0 : true))
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${key}[]=${encodeAxiosParam(v)}`).join("&");
      }
      return `${key}=${encodeAxiosParam(value)}`;
    })
    .join("&");
};

/**
 * The default axios serialization changed when we upgraded from 0.21.2 to 1.6.0. In order to maintain the expected
 * format of our query params, we must now pass in a custom method to paramsSerializer for our default api. adminApi
 * seems to be unaffected since we typically already customize the params in the encodeTableStateForAdmin method inside
 * util/table/encodeTableStateForAdmin before sending that through stringifyParams here.
 *
 * The encodeAxiosParam and serializeQueryParams methods above come from this GH comment:
 * https://github.com/axios/axios/issues/5630#issuecomment-1859776798
 *
 */
export const api = axios.create({
  headers: { "X-CSRF-Token": CSRF_TOKEN, "Content-Type": "application/json", Accept: "application/json" },
  paramsSerializer: serializeQueryParams,
});

export const adminApi = axios.create({
  headers: { "X-CSRF-Token": CSRF_TOKEN, "Content-Type": "application/json", Accept: "application/json" },
  paramsSerializer: stringifyParams,
});

export const extractErrorMessage = (error, fallback = "") => {
  if (typeof error === "string") {
    return isBlank(error) ? fallback : error;
  }

  if (error?.response) {
    if (_.has(error, "response.data.message")) {
      return _.get(error, "response.data.message");
    }

    if (_.has(error, "response.data.errors")) {
      const errors = _.get(error, "response.data.errors");
      return _.castArray(errors).join(", ");
    }
  }

  return error?.message ?? fallback;
};

export const extractErrorMeta = (error) => error?.response?.data?.meta ?? {};
export const extractErrorDetails = (error) => extractErrorMeta(error)?.error_details ?? [];

export const mergeApiErrors = (errors) => {
  const messages = _.flatten(_.compact(errors.map((e) => extractErrorMessage(e))));
  const details = _.flatten(_.compact(errors.map((e) => extractErrorDetails(e))));

  const mergedError = Error(messages.join(". "));
  mergedError.response = {
    data: {
      errors: isBlank(messages) ? undefined : messages,
      meta: { error_details: isBlank(details) ? undefined : details },
    },
  };

  return mergedError;
};

export const handleRequestSequence = async (promises) => {
  const resValues = await Promise.allSettled(promises);

  const failures = resValues.filter((v) => v.status === "rejected");
  const successes = resValues.filter((v) => v.status !== "rejected");

  // If everything failed, just throw the combined error
  // instead of waiting.
  if (successes.length === 0) {
    throw mergeApiErrors(failures.map((f) => f.reason));
  }

  return { failures, successes };
};

export const redirectTo = (url, flash = "", flashType = "") => {
  if (flash) {
    switch (flashType) {
      case "alert":
        flashType = "warning";
        break;
      case "notice":
      case "success":
        flashType = "success";
        break;
      case "error":
        flashType = "danger";
        break;
      case "info":
        flashType = "";
        break;
      default:
        flashType = "primary";
        break;
    }

    window.localStorage.setItem(FLASH_MESSAGE_KEY, flash);
    window.localStorage.setItem(FLASH_TYPE_KEY, flashType);
  } else {
  }
  window.location.href = url;
};

export const get = async (url) => processResponse(await fetch(url, { credentials: "include" }));

export const put = makeUpdater("put");
export const post = makeUpdater("post");
export const patch = makeUpdater("patch");

function makeUpdater(method) {
  return async (url, body) =>
    processResponse(
      await fetch(url, {
        method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": CSRF_TOKEN,
        },
        body: JSON.stringify(body),
      })
    );
}

async function processResponse(response) {
  if (response.ok) {
    return await response.json();
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    try {
      error.json = await response.json();
    } catch (e) {
      console.error("not a json body", e);
    }
    throw error;
  }
}

// exhaustive deps are not suited for this sort of situation
const buildUseAPI =
  (api) =>
  (url, config = {}, initialFetch = true) => {
    const [state, setState] = useState({
      response: undefined,
      error: undefined,
      isLoading: true,
    });

    // Create cancel token
    const source = CancelToken.source();
    const configHash = hash(config);

    // Define fetch method
    const fetch = useCallback(() => {
      setState({ ...state, isLoading: true });

      api(url, { ...config, cancelToken: source.token })
        .then((response) => setState({ error: undefined, response, isLoading: false }))
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log(`Request cancelled by cleanup: ${error.message}`);
          } else {
            console.error(error);
            setState({ error, response: undefined, isLoading: false });
          }
        });
    }, [configHash, state]); // eslint-disable-line react-hooks/exhaustive-deps

    // Apply effect
    useEffect(() => {
      if (initialFetch) {
        fetch();
      }

      return () => {
        source.cancel("useEffect cleanup");
      };
    }, [url, configHash]); // eslint-disable-line react-hooks/exhaustive-deps

    // Return values
    const { response, error, isLoading } = state;

    function setData(newData) {
      const newResponse = { ...response, data: newData };
      setState({ ...state, response: newResponse });
    }

    // Return either the response data or nothing
    const data = response ? response.data : undefined;
    return { data, response, error, isLoading, setData, fetch };
  };

export const useAPI = buildUseAPI(api);
export const useAdminAPI = buildUseAPI(adminApi);
