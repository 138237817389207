import $ from "jquery";
import ClipboardJS from "clipboard";

const SUCCESS_CLASSES = "text-success";
const DEFAULT_CLASSES = "text-muted";

const BTN_SELECTOR = ".copy";
const ICON_SELECTOR = ".copy-icon";

export const initClipboard = () => {
  const clipboard = new ClipboardJS(BTN_SELECTOR);

  clipboard.on("success", function (e) {
    const $trigger = $(e.trigger);
    const $icon = $(ICON_SELECTOR, $trigger);

    toggleClasses($trigger, $icon, true);

    setTimeout(() => {
      toggleClasses($trigger, $icon, false);
    }, 3000);
    e.clearSelection();
  });
};

const toggleClasses = ($trigger, $icon, success = true) => {
  if (success) {
    $trigger.removeClass(DEFAULT_CLASSES);
    $trigger.addClass(SUCCESS_CLASSES);
  } else {
    $trigger.removeClass(SUCCESS_CLASSES);
    $trigger.addClass(DEFAULT_CLASSES);
  }
};
