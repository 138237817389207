import React from "react";
import { useTableContext } from "../../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useDestroyPermission, useUpdatePermission } from "../../../utils";
import { EditProviderPayorNumberModal } from "./EditProviderPayorNumberModal";
import { History } from "../../../tools/sandbox/release/History";

export const ProviderPayorNumbersActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("ProviderPayorNumber", row.original.id);
  const [canUpdate] = useUpdatePermission("ProviderPayorNumber", row.original.id);

  return (
    <>
      {canUpdate && (
        <EditProviderPayorNumberModal
          color="link"
          providerPayorNumber={row.original}
          onUpdate={(providerPayorNumber) => {
            refresh();
            setMessage(`Updated Provider Payor Number ${row.original.id}.`);
          }}
        />
      )}

      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/provider_payor_numbers/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Provider Payor Number.`);
          }}
          onError={setError}
        />
      )}

      <History entityName={"ProviderPayorNumber"} subjectId={row.original.id} />
    </>
  );
};
