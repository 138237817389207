import { Button, Input, InputGroup } from "reactstrap";

export const SearchInput = ({ icon, value, placeholder = "Search...", disabled, onChange, onClick, className, onKeyPress, ...props }) => {
  return (
    <InputGroup className={className}>
      <Input
        className="me-2"
        type="search"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        {...props}
      />
      <Button color="primary" onClick={onClick}>
        Search
      </Button>
    </InputGroup>
  );
};
