import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { FormGroup } from "reactstrap";

import { api, redirectTo } from "../util/api";
import { composeValidators, required, valueInOptions } from "../util/validators";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { LoadingButton } from "../components/LoadingButton";
import { SelectInput } from "../components/inputs/final_form/SelectInput";

export const ActiveClinicSelector = ({ submitUrl, clinicOptions, redirectUrl }) => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async ({ clinicId }) => {
    setSaving(true);

    try {
      await api.put(submitUrl, { clinic_id: clinicId });

      redirectTo(redirectUrl);
    } catch (err) {
      setError(err?.response?.data?.error);
      setSaving(false);
    }
  };

  return (
    <>
      <DetailedErrorAlert error={error} />

      <Form onSubmit={handleSubmit}>
        {({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormGroup tag="fieldset">
                <Field
                  label="Clinic"
                  name="clinicId"
                  required
                  validate={composeValidators(required, valueInOptions(clinicOptions))}
                  render={(props) => <SelectInput {...props} options={clinicOptions} />}
                />
              </FormGroup>

              <LoadingButton loading={saving || submitting} disabled={submitting} type="submit" color="primary">
                Confirm
              </LoadingButton>
            </form>
          );
        }}
      </Form>
    </>
  );
};
