import React, { useState } from "react";
import { LoadingButton } from "../../../components/LoadingButton";
import { useTableContext } from "../../components/AdministrateTable";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { EditPortalPayorModal } from "./EditPortalPayorModal";

export const PortalPayorActionCell = ({ portal, ...row }) => {
  const [removing, setRemoving] = useState(false);
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("PayorPortalMapping", row.original.id);
  const [canUpdate] = useUpdatePermission("PayorPortalMapping", row.original.id);
  const editPortal = portal?.supports_tiers && canUpdate;

  const handleDelete = async () => {
    setMessage(`Deleted payor ${row.original.payor.name}`);
    refresh();
  };

  const handleUpdatePayor = () => {
    setMessage(`Updated payor ${row.original.payor.name}`);
    refresh();
  };

  return (
    <>
      {editPortal && <EditPortalPayorModal color="link" payorPortalMapping={row.original} onUpdate={handleUpdatePayor} portal={portal} />}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          label="Remove"
          deleteUrl={`/admin/payor_portal_mappings/${row.original.id}`}
          onSuccess={handleDelete}
          onError={(msg) => setError(msg)}
        />
      )}
    </>
  );
};
