import React from "react";

import { AttachButton } from "../components/AttachButton";
import { useFaxAttachmentForm } from "./useFaxAttachmentForm";

export const FaxAttachButton = ({ faxId, fax, size, ...props }) => {
  const { onSubmit, error, acknowledgements, onLoadCase } = useFaxAttachmentForm(fax);
  return (
    <AttachButton
      size={size}
      type="fax"
      onSubmit={onSubmit}
      error={error}
      withCategory={true}
      acknowledgements={acknowledgements}
      onLoadCase={onLoadCase}
      {...props}
    />
  );
};
