import $ from "jquery";

export const initCustomFileInputs = () => {
  $(".custom-file-input").on("change", (e) => {
    const hasFile = e.target.files.length > 0;
    const enableButton = $(e.target).data("enable-button");
    const formControl = $(e.target).next(".form-control-file");

    if (hasFile) {
      if (enableButton) {
        $("#" + enableButton).removeAttr("disabled");
      }
      const fileName = e.target.files[0].name;
      formControl.addClass("selected").html(fileName);
    } else {
      if (enableButton) {
        $("#" + enableButton).attr("disabled", true);
      }
      formControl.addClass("selected").html("Select File....");
    }
  });
};
