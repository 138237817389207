import React, { useState, useCallback } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";

export const AddClinicPayorMappingModal = ({ clinicId, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleAdd = useCallback(
    async (params) => {
      setError(null);

      try {
        const { data } = await api.post(`/admin/clinic_payor_mappings`, snakifyKeys({ ...params, clinicId }));
        // Figure out why we're fetching data but not using it
        if (onAdd) {
          onAdd("Added Clinic Payor Mapping!");
        }

        setOpen(false);
      } catch (err) {
        setError(err);
      }
    },
    [clinicId, onAdd]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Clinic Payor Mapping
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Clinic Payor Mapping</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleAdd}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl={`/admin/payors.json?supported_clinics[id]=${clinicId}`}
                    label="Payor"
                    name="payorId"
                    searchParam="name"
                    required
                  />

                  <Field label="Provided Payor Name" name="insuranceName" component={TextInput} required />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
