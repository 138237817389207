import { Link } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable } from "../../components/AdministrateTable";
import { Routes } from "../../Routes";
import { UPDATE, makeColumns, DESTROY, useAdministrateTable, useUpdatePermission, useCreatePermission } from "../../utils";
import { ImportPortalMessageModal } from "../components/ImportPortalMessageModal";
import { AddPortalMessageModal } from "../components/AddPortalMessageModal";
import { PortalMessageActionCell } from "../components/PortalMessageActionCell";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { StatusDisplayer, SubstatusDisplayer } from "../../submit_right/phone_responses/PhoneResponsesIndex";

const useColumns = makeColumns(() => [
  {
    id: "message",
    accessor: "message",
    Header: "Message",
  },
  {
    id: "submit_right_direction",
    accessor: (x) => {
      switch (x.submitRightDirection) {
        case "status_the_case":
          return "Status The Case";
        case "task_out_for_n_number_of_days_to_try_again":
          return "Task Out For N Number of Days To Try Again";
        default:
          return "Proceed To The Next Portal";
      }
    },
    Header: "Submit Right direction",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/enums/submit_right_direction_options.json" valueKey="id" labelKey="label" />
    ),
  },
  {
    id: "payor",
    accessor: "payor.name",
    Header: "Verified Payor Name",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/payors.json?payor[direction]=asc&payor[order]=payor_name&per_page=100"
        valueKey="name"
        searchParam="name"
        labelKey="name"
        kept={true}
      />
    ),
    Cell: ({ row, value }) => (
      <Link target="_blank" to={Routes.payor.url(row.original.payor?.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
  },
  {
    id: "status",
    accessor: "status",
    Header: "Status",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/enums/prior_auth_statuses.json" valueKey="id" labelKey="label" />
    ),
    disableSortBy: true,
    Cell: (props) => <StatusDisplayer status={props.value || "none"} />,
  },
  {
    id: "substatus",
    accessor: "substatus",
    Header: "Substatus",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/enums/prior_auth_substatuses.json" valueKey="value" labelKey="label" />
    ),
    disableSortBy: true,
    Cell: (props) => <SubstatusDisplayer substatus={props.value || "none"} />,
  },
  {
    id: "cannedComment",
    accessor: "cannedComment",
    Header: "Canned Comment",
    disableSortBy: true,
  },
  {
    id: "aliases",
    Header: "Actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: PortalMessageActionCell,
  },
]);

export const PortalMessagesTab = ({ portal, portalId }) => {
  const columns = useColumns();
  const extraParams = { benefit_management_portal: { id: portalId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);
  const [canCreate] = useCreatePermission("PortalMessage");

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onCreate = (results) => {
    const { refresh, setMessage } = tableRef.current;
    setMessage("Portal Message Added");
    refresh();
  };

  return (
    <>
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Toolbar className="ml-auto">
            <AdministrateBackgroundExportButton
              outline
              className="me-2"
              exportUrl="/admin/portal_messages/export"
              filters={allFilters}
              ref={tableRef}
            />
            {canCreate && (
              <>
                <ImportPortalMessageModal outline className="me-2" onImport={onImport} benefitManagementPortalId={portalId} />
                <AddPortalMessageModal color="primary" portal={portal} onAdd={onCreate} />
              </>
            )}
          </Toolbar>
        </Row>
      </Container>

      <AdministrateTable
        resourceUrl={"/admin/portal_messages.json"}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
        resourceName={"portal_message"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
