import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, Row, Col, Input } from "reactstrap";
import { MissingInfoForm } from "../../../../missinginfo/MissingInfoForm";
import { InputWrapper } from "../../../../components/inputs/final_form/InputWrapper";
import { DocumentViewer } from "../../../../components/DocumentViewer";

export const DocumentReviewModal = ({ buttonText, caseId, caseData, caseUrl, labTests, afterSave, buttonProps }) => {
  const [modal, setModal] = useState(false);
  const [activeDocument, setActiveDocument] = useState(null);
  const toggleModal = () => setModal(!modal);

  return (
    <>
      <Button {...buttonProps} onClick={toggleModal}>
        {buttonText}
      </Button>

      <Modal isOpen={modal} toggle={toggleModal} className="full-screen" scrollable>
        <ModalHeader toggle={toggleModal}>Supplemental Document Review</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <MissingInfoForm
                caseId={caseId}
                caseUrl={caseUrl}
                labTests={labTests}
                saveButtonText="Save &amp; Continue"
                afterSave={async () => {
                  setModal(false);
                  await afterSave();
                }}
              />
            </Col>
            <Col>
              <InputWrapper label="Select Document to View">
                <Input
                  type="select"
                  onClick={(e) => setActiveDocument(_.find(caseData.supplementalDocuments, (d) => d.documentId === e.target.value))}
                >
                  <option>-- Select Document --</option>

                  {caseData.supplementalDocuments.map((d) => (
                    <option key={d.documentId}>{d.documentId}</option>
                  ))}
                </Input>
              </InputWrapper>

              {activeDocument && <DocumentViewer document={activeDocument} />}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};
