import React from "react";
import { AdministrateSelect } from "./AdministrateSelect";

export const AdministrateEnumFilter = ({ options, column: { filterValue, setFilter }, ...rest }) => {
  return (
    <AdministrateSelect
      onChange={setFilter}
      value={filterValue}
      menuPortalTarget={document.body}
      styles={{
        option: (provided, state) => ({
          ...provided,
          textAlign: "left",
        }),
      }}
      className="me-2"
      {...rest}
    />
  );
};
