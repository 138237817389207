import React, { useCallback } from "react";
import { BoolCell } from "../../components/grid/cells/BoolCell";
import { MoneyCell } from "../../components/grid/cells/MoneyCell";
import { TokenCell } from "../../components/grid/cells/TokenCell";
import { BoolFilter } from "../../components/inputs/BoolFilter";
import { Toolbar } from "../../components/Toolbar";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../utils";
import { AddCptCodeModal } from "./components/AddCptCodeModal";
import { CptCodeActionCell } from "./components/CptCodeActionCell";
import { AdministrateBackgroundExportButton } from "../components/AdministrateBackgroundExportButton";
import { ImportCptCodesModal } from "./components/ImportCptCodesModal";
import { AdministrateEnumFilter } from "../components/AdministrateEnumFilter";
import { History } from "../tools/sandbox/release/History";

const useColumns = makeColumns(() => [
  {
    id: "code",
    accessor: "code",
    Header: "Code",
    Cell: TokenCell,
  },
  {
    id: "procedure_code_type",
    accessor: "procedureCodeType",
    Header: "Procedure Code Type",
    Cell: TokenCell,
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/enums/procedure_code_types.json"
        valueKey="id"
        labelKey="label"
        searchParam="search"
      />
    ),
  },
  {
    id: "description",
    accessor: "description",
    Header: "Short Description",
  },
  {
    id: "long_description",
    accessor: "longDescription",
    Header: "Long Description",
  },
  {
    id: "screening",
    accessor: "screening",
    Header: "Screening?",
    Cell: BoolCell,
    Filter: BoolFilter,
  },
  {
    id: "always_require_pa",
    accessor: "alwaysRequirePa",
    Header: "Always require PA?",
    Cell: BoolCell,
    Filter: BoolFilter,
  },
  {
    id: "trizetto_enabled",
    accessor: "trizettoEnabled",
    Header: "Trizetto Enabled?",
    Cell: BoolCell,
    Filter: BoolFilter,
  },
  {
    id: "medicare_cost_cents",
    accessor: "medicareCostCents",
    Header: <div className="text-end">Medicare Price</div>,
    Cell: (row) => <MoneyCell cents={row.value} />,
    disableFilters: true,
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: CptCodeActionCell,
    disableFilters: true,
    disableSortBy: false,
  },
]);

export const CptCodeIndexPage = () => {
  const columns = useColumns();
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  const [canCreate] = useCreatePermission("CptCode");

  const onImport = useCallback(
    (message, details = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, details.length ? "warning" : "success", details);
    },
    [tableRef]
  );

  const onCreate = useCallback(
    (values) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`New procedure code "${values.code}" (${values.procedureCodeType}) Added.`);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Procedure Codes">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <History entityName="CptCode" className="me-2" />
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/cpt_codes/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreate && (
            <>
              <ImportCptCodesModal outline className="me-2" onImport={onImport} />
              <AddCptCodeModal color="primary" onCreate={onCreate} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/cpt_codes.json"}
        resourceName={"cpt_code"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
