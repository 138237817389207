import { createRoot } from "react-dom/client";
import { NewCaseForm } from "../../new_case/NewCaseForm";

export const initNewCase = () => {
  const newCaseFormContainer = document.getElementById("new_case");

  if (newCaseFormContainer) {
    const root = createRoot(newCaseFormContainer);
    const {
      updateUrl,
      createUrl,
      draftId,
      draftData,
      isClinicUser,
      requestingProvider,
      orderableWorkflows,
      isDraftPersisted,
      clinicProvidersUrl,
      isRequestingProviderTypePharmacy,
      hasOrderingLabs,
      externalUserLabId,
      uninsuredPatientPayor,
    } = newCaseFormContainer.dataset;

    const parsedDraftId = draftId ? JSON.parse(draftId) : null;
    const parsedDraftData = draftData ? JSON.parse(draftData) : null;

    root.render(
      <NewCaseForm
        draftId={parsedDraftId}
        draftData={parsedDraftData}
        hasDraft={isDraftPersisted === "true"}
        updateUrl={updateUrl}
        createUrl={createUrl}
        isClinicUser={JSON.parse(isClinicUser)}
        requestingProvider={JSON.parse(requestingProvider)}
        isRequestingProviderTypePharmacy={isRequestingProviderTypePharmacy === "true"}
        clinicProvidersUrl={clinicProvidersUrl}
        orderableWorkflows={JSON.parse(orderableWorkflows)}
        hasOrderingLabs={hasOrderingLabs === "true"}
        externalUserLabId={externalUserLabId}
        uninsuredPatientPayor={JSON.parse(uninsuredPatientPayor)}
      />
    );
  }
};
