import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { parseCurrency } from "../../../util/formatters";
import { FeeScheduleByPlanTypeForm } from "./FeeScheduleByPlanTypeForm";

export const CreateLabFeeScheduleByPlanTypeModal = ({ labId, onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    const newValues = _.cloneDeep(values);

    setError(null);
    try {
      if (newValues.priceCents) {
        newValues.priceCents = parseCurrency(newValues.priceCents) * 100;
      }

      const res = await api.post("/admin/cpt_fee_schedule_by_plan_types", { cpt_fee_schedule_by_plan_type: snakifyKeys(newValues) });

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Fee Schedule By Plan Type
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add CPT Fee Schedule By Plan Type</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{ labId }}>
            {(props) => <FeeScheduleByPlanTypeForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
