import { useMemo, useRef, useState } from "react";
import { BoolCell } from "../../../components/grid/cells/BoolCell";
import { CountCell } from "../../../components/grid/cells/CountCell";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { Switch } from "../../../components/inputs/Switch";
import { Toolbar } from "../../../components/Toolbar";
import { extractErrorMessage } from "../../../util/api";
import { deepCompact, snakifyKeys } from "../../../util/helpers";
import { encodeFilterParams } from "../../../util/table";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { UPDATE, useCreatePermission, usePermissionCheck } from "../../utils";
import { CreateTestModal } from "../components/CreateTestModal";
import { ImportLabTestsModal } from "../components/ImportLabTestsModal";
import { ImportTestAliasesModal } from "../components/ImportTestAliasesModal";
import { LabTestActionCell } from "../components/LabTestActionCell";
import { MassArchiveTestsButton } from "../components/MassArchiveTestsButton";

const useColumns = (labId) =>
  useMemo(
    () => [
      {
        id: "id",
        accessor: "id",
        Header: "Test ID",
        Cell: TokenCell,
      },
      {
        id: "commercial_name",
        accessor: "commercialName",
        Header: "Name",
      },
      {
        id: "test_identifier",
        accessor: "testIdentifier",
        Header: "Test Code", // Yes this is Test Code paired with Test Identifier
      },
      {
        id: "lab_test_mappings.test_name",
        accessor: "nameAliases",
        Header: "Test Name Aliases",
        Cell: ({ row }) => <>{row.original.nameAliases ? row.original.nameAliases : "No Aliases"}</>,
      },
      {
        id: "lab_test_mappings.test_identifier",
        accessor: "identifierAliases",
        Header: "Test Identifier Aliases",
        Cell: ({ row }) => <>{row.original.identifierAliases ? row.original.identifierAliases : "No Aliases"}</>,
      },
      {
        id: "cpt_codes",
        accessor: "cptCount",
        Header: "CPT Codes",
        Cell: ({ value }) => <CountCell count={value} label="CPT Codes" />,
        Filter: (props) => (
          <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" isMulti valueKey="code" labelKey="code" />
        ),
      },
      {
        id: "test_category.id",
        accessor: "testCategory.name",
        Header: "Category",
        Filter: (props) => (
          <AdministrateEnumFilter {...props} resourceUrl="/admin/test_categories.json" isMulti kept={true} valueKey="id" labelKey="name" />
        ),
      },
      {
        id: "seeqerEnabled",
        Header: "seeQer Enabled?",
        accessor: "seeqerEnabled",
        Cell: BoolCell,
      },
      {
        id: "aliases",
        className: "action-cell",
        Header: "Actions",
        disableFilters: true,
        disableSortBy: true,
        Cell: LabTestActionCell,
      },
    ],
    []
  );

export const LabsTestsTab = ({ lab, labId }) => {
  const tableRef = useRef();
  const columns = useColumns(labId);
  const [showArchivedTests, setShowArchivedTests] = useState(false);
  const [tableFilters, setTableFilters] = useState({});
  const [canCreateTestMapping] = useCreatePermission("LabTestMapping");
  const [canCreateTest] = useCreatePermission("Test");
  const [canArchiveTest] = usePermissionCheck("archive", "Test");

  const extraParams = useMemo(
    () => deepCompact({ lab: { id: labId }, archived: showArchivedTests, kept: true }),
    [labId, showArchivedTests]
  );

  const allFilters = useMemo(
    () => ({
      ...snakifyKeys(extraParams),
      ...encodeFilterParams(tableFilters),
    }),
    [extraParams, tableFilters]
  );

  // This toggles the showArchivedTests between false and undefined
  // a false value will show only tests where archived is false, while
  // undefined will exclude the filter from our params and give us all
  // tests regardless of archive status
  const toggleArchivedTestFilter = () => setShowArchivedTests(showArchivedTests === false ? undefined : false);

  const handleMassArchive = (archived) => {
    tableRef.current?.setMessage(`${archived ? "Archived" : "Unarchived"} all tests`);
    tableRef.current?.refresh();
  };

  const onAddLabTests = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onCreate = (newTest) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created new test ${newTest.commercial_name}`);
    refresh();
  };

  const onAddTestAliases = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  const handleMassArchiveError = (err) => tableRef.current?.setError(extractErrorMessage(err));

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar>
          <Switch
            id="archived-tests-toggle"
            label="Include Archived Tests"
            checked={showArchivedTests === undefined}
            onChange={toggleArchivedTestFilter}
          />
        </Toolbar>

        <Toolbar className="ms-auto">
          {canArchiveTest && (
            <>
              <MassArchiveTestsButton
                outline
                className="me-2"
                labId={labId}
                onArchive={handleMassArchive}
                onError={handleMassArchiveError}
                labName={lab.labName}
              />
              <MassArchiveTestsButton
                outline
                className="me-2"
                labId={labId}
                onArchive={handleMassArchive}
                onError={handleMassArchiveError}
                labName={lab.labName}
                archive={false}
              />
            </>
          )}
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/tests/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreateTest && <ImportLabTestsModal outline className="me-2" onImport={onAddLabTests} labId={labId} />}
          {canCreateTestMapping && <ImportTestAliasesModal outline className="me-2" onImport={onAddTestAliases} labId={labId} />}
          {canCreateTest && <CreateTestModal color="primary" labId={labId} onCreate={onCreate} />}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/tests.json"}
        extraParams={extraParams}
        resourceName={"test"}
        onFilteredChange={setTableFilters}
        permissions={[UPDATE, "archive"]}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
