import React, { useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { useUpdatePermission } from "../../utils";
import { PortalMessageForm } from "./PortalMessageForm";
import { PortalMessageReadOnly } from "./PortalMessageReadOnly";

export const EditPortalMessageModal = ({ portalMessage, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [canUpdate] = useUpdatePermission("PortalMessage", portalMessage.id);
  const mode = canUpdate ? "Edit" : "View";

  const toggleModal = () => setOpen(!open);

  const handleUpdate = async (values) => {
    setError(null);

    try {
      const res = await api.patch(`/admin/portal_messages/${portalMessage.id}`, {
        portal_message: snakifyKeys(values),
      });

      if (onUpdate) {
        onUpdate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        {mode}
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>{mode} Portal Message</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />
          {canUpdate ? (
            <Form onSubmit={handleUpdate} initialValues={{ ...portalMessage }}>
              {(props) => <PortalMessageForm {...props} />}
            </Form>
          ) : (
            <PortalMessageReadOnly portalMessage={portalMessage} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
