import cx from "classnames";
import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import Icon from "./Icon";

export const StatCard = ({ value, label, icon, variant, url, className, iconClassNames }) => {
  const iconClasses = cx("fs-3", iconClassNames);
  const statCardClasses = cx("stat-card", "d-flex", "m-2", className, {
    [`stat-card--${variant}`]: !!variant,
  });

  return (
    <Card className={statCardClasses}>
      <CardBody>
        <div className="d-flex flex-row justify-content-between align-items-center">
          <span className="stat-card__value">{value}</span>

          {icon && (
            <span className="stat-card__icon ">
              <Icon name={icon} className={iconClasses} size={20} />
            </span>
          )}
        </div>

        <div>
          <span className="text-muted stat-card__label">{label}</span>
          {url && <a href={url} className="stretched-link"></a>}
        </div>
      </CardBody>
    </Card>
  );
};

// The CutoffStatCard needs a slightly different setup to make the inner card-bodies fit correctly at the edge

export const CutoffStatCard = ({ className, children }) => {
  const statCardClasses = cx("cutoff-stat-card", "d-flex", "flex-row", className);
  const bodyCount = children.length;

  return <Card className={statCardClasses}>{children}</Card>;
};

export const CutoffStatBody = ({ value, label, icon, variant, url, className, iconClassNames }) => {
  const iconClasses = cx("fs-3", iconClassNames);
  const statBodyClasses = cx("d-flex", "flex-row", "justify-content-around", "align-items-center", className, {
    [`card-body--${variant}`]: !!variant,
  });
  const chevron = <Icon name={"chevron-right"} size={12} className={"ms-2"} />;

  return (
    <CardBody className={statBodyClasses}>
      <div>
        <span className="stat-card__value">{value}</span>

        <span className="d-flex text-muted stat-card__label align-items-center">
          {label}
          {url && chevron}
        </span>
      </div>

      {url && <a href={url} className="stretched-link"></a>}

      {icon && (
        <span className="stat-card__icon">
          <Icon name={icon} className={iconClasses} size={40} />
        </span>
      )}
    </CardBody>
  );
};
