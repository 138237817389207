import { createRoot } from "react-dom/client";
import { MissingInfoCategoriesPicker } from "../../missing_info_categories/MissingInfoCategoriesPicker";

export function initMissingInfoCategoriesPicker() {
  const container = document.getElementById("missing_info_categories_picker");

  if (container) {
    const { caseId, saveUrl, missingFields, missingInfoCategories, initialCategories } = container.dataset;

    const root = createRoot(container);

    root.render(
      <MissingInfoCategoriesPicker
        caseId={caseId}
        saveUrl={saveUrl}
        missingFields={JSON.parse(missingFields)}
        missingInfoCategories={JSON.parse(missingInfoCategories)}
        initialCategories={initialCategories ? JSON.parse(initialCategories) : []}
      />
    );
  }
}
