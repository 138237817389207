import _ from "lodash";
import { Search } from "lucide-react";
import React, { useMemo, useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { FormText, UncontrolledAlert, Col } from "reactstrap";
import { FormRow } from "../components/forms/FormRow";
import { DateInput } from "../components/inputs/final_form/DateInput";
import { TextInput, TextInputWithButton } from "../components/inputs/final_form/TextInput";
import { api, extractErrorMessage } from "../util/api";
import { composeValidators, required } from "../util/validators";

export const setNpiDataMutator = ([physicianData], state, { changeValue }) => {
  changeValue(state, "providerInfo.physicianFax", () => physicianData.physicianFax);
  changeValue(state, "providerInfo.physicianFirstName", () => physicianData.physicianFirstName);
  changeValue(state, "providerInfo.physicianLastName", () => physicianData.physicianLastName);
  changeValue(state, "providerInfo.physicianPhone", () => physicianData.physicianPhone);
};

export const translateNpiResult = (result) => {
  const { basic, addresses } = result;

  return {
    physicianFax: _.get(addresses, "[0].fax_number"),
    physicianFirstName: _.get(basic, "first_name"),
    physicianLastName: _.get(basic, "last_name"),
    physicianPhone: _.get(addresses, "[0].telephone_number"),
  };
};

export const getNpiData = async (npi, form, setError, setLoadingLookup, noResultsErrorMessage, unexpectedErrorMessage) => {
  try {
    const res = await api.get(`/clinics/npi?number=${npi}`);
    let { result_count, results } = res.data;
    // The NPI API has used both camel and snake case for this key, so we are checking for both here just to be safe.
    if (result_count == null) result_count = res.data.resultCount;

    if (result_count >= 1) {
      const result = results[0];
      const translatedResult = translateNpiResult(result);
      form.mutators.setNpiDataMutator(translatedResult);
    } else if (noResultsErrorMessage) {
      setError(noResultsErrorMessage);
    }
  } catch (err) {
    console.error(err);
    const message = extractErrorMessage(err, unexpectedErrorMessage);
    setError(message);
  } finally {
    setLoadingLookup(false);
  }
};

const getEnrollmentMessage = (enrolled, checkingEnrollment) => {
  if (checkingEnrollment) {
    return { message: "Checking Enrollment Status...", color: "muted" };
  }

  if (enrolled === true) {
    return { message: `Enrolled with ${window.branding.brandNameShort}`, color: "success" };
  } else if (enrolled === false) {
    return { message: `Not Enrolled with ${window.branding.brandNameShort}`, color: "danger" };
  } else {
    return { message: null, color: null };
  }
};

export const ProviderInfoFields = ({ values, form, ...formProps }) => {
  const [loadingNpiLookup, setLoadingNpiLookup] = useState(false);
  const [checkingEnrollment, setCheckingEnrollment] = useState(false);
  const [npiEnrolled, setNpiEnrolled] = useState(null);
  const [error, setError] = useState(null);
  const showOptionalFields = values.orderedWorkflows === "priorAuthRequest";
  const noResultsErrorMessage = "No results for that NPI";
  const unexpectedErrorMessage = "An unexpected error occurred while searching for NPI.";

  const handleNpiLookup = async () => {
    setError(null);
    const npi = _.get(values, "providerInfo.physicianNPI");

    if (!npi || npi.length < 9) {
      return false;
    }

    setLoadingNpiLookup(true);

    await getNpiData(npi, form, setError, setLoadingNpiLookup, noResultsErrorMessage, unexpectedErrorMessage);
  };

  const handleEnrollmentCheck = async (npi) => {
    setNpiEnrolled(null);

    if (!npi || npi.length < 9) {
      return false;
    }

    setCheckingEnrollment(true);

    try {
      const npiToCheck = encodeURIComponent(npi);
      const res = await api.get(`/clinics/npi/${npiToCheck}`);
      setNpiEnrolled(res.data.enrolled);
    } catch (err) {
      if (_.get(err, "response.status") === 404) {
        setNpiEnrolled(false);
      } else {
        console.error(err.message);
      }
    } finally {
      setCheckingEnrollment(false);
    }
  };

  const enrollmentStatusMessage = useMemo(() => getEnrollmentMessage(npiEnrolled, checkingEnrollment), [npiEnrolled, checkingEnrollment]);

  return (
    <>
      <h5 className="mb-4">Provider & Practice Info</h5>
      {error && <UncontrolledAlert color="danger">{error}</UncontrolledAlert>}

      <FormRow>
        <Col>
          <Field
            required
            validate={composeValidators(required)}
            name="providerInfo.physicianNPI"
            label="Physician NPI"
            component={TextInputWithButton}
            buttonLabel={<Search />}
            buttonProps={{ outline: true, onClick: handleNpiLookup, disabled: loadingNpiLookup || checkingEnrollment }}
            data-testid="physicianNPI"
          >
            {enrollmentStatusMessage.message && (
              <FormText color={enrollmentStatusMessage.color}>{enrollmentStatusMessage.message}</FormText>
            )}
          </Field>
        </Col>
        <FormSpy
          subscription={{ values: true, dirty: true }}
          onChange={async ({ values, dirty }) => {
            const npi = _.get(values, "providerInfo.physicianNPI");

            if (npi && dirty) {
              await handleEnrollmentCheck(npi);
            }
          }}
        />
        <Col>
          <Field
            name="providerInfo.physicianFirstName"
            component={TextInput}
            label="Physician First Name"
            data-testid="physicianFirstName"
          />
        </Col>
        <Col>
          <Field name="providerInfo.physicianLastName" component={TextInput} label="Physician Last Name" data-testid="physicianLastName" />
        </Col>
      </FormRow>

      <FormRow>
        <Col>
          <Field
            name="providerInfo.physicianPracticeName"
            component={TextInput}
            label="Physician Practice Name"
            data-testid="physicianPracticeName"
          />
        </Col>
        <Col>
          <Field name="providerInfo.physicianPhone" component={TextInput} label="Physician Phone" type="tel" data-testid="physicianPhone" />
        </Col>
        <Col>
          <Field name="providerInfo.physicianFax" component={TextInput} label="Physician Fax" type="tel" data-testid="physicianFax" />
        </Col>
      </FormRow>

      {showOptionalFields && (
        <>
          <FormRow>
            <Col>
              <Field
                name="providerInfo.geneticCounselorName"
                component={TextInput}
                label="Genetic Counselor Name"
                data-testid="geneticCounselorName"
              />
            </Col>
            <Col>
              <Field
                name="providerInfo.geneticCounselingDate"
                component={DateInput}
                label="Genetic Counseling Date"
                data-testid="geneticCounselingDate"
              />
            </Col>
            <Col>
              <Field name="clinicInfo.zip" component={TextInput} label="Clinic Zip Code" data-testid="clinicZipCode" />
            </Col>
          </FormRow>
        </>
      )}
    </>
  );
};
