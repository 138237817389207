import React, { useMemo, useState } from "react";
import { StyledSelect as Select } from "./inputs/StyledSelect";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { api } from "../util/api";
import { userNameOrEmail } from "../util/formatters";
import { DetailedErrorAlert } from "./DetailedErrorAlert";
import { LoadingButton } from "./LoadingButton";

const usersToOptions = (users, previousAssignee = null) => {
  return users.map((u) => {
    const name = userNameOrEmail(u);
    const isPreviousAssignee = previousAssignee === u.email;

    return { label: isPreviousAssignee ? `${name} (Previously Assigned User)` : name, value: u.email };
  });
};

export const AssignmentModal = ({
  open,
  toggleModal,
  onAssign,
  caseIds,
  currentAssignee,
  assignableUsers,
  previousAssignee,
  assignmentUrl,
  modalProps,
}) => {
  const [error, setError] = useState(null);
  const [selectedAssignee, setSelectedAssignee] = useState(currentAssignee ? currentAssignee : previousAssignee);
  const [assigning, setAssigning] = useState(false);
  const [unassigning, setUnassigning] = useState(false);
  const submitting = assigning || unassigning;

  const handleSubmit = async (assignee) => {
    setError(null);

    const response = await api.post(assignmentUrl, {
      case_ids: caseIds,
      assignee,
    });

    if (onAssign) {
      onAssign(assignee, response?.data?.meta?.message, response?.data?.meta?.error_details);
    }

    setSelectedAssignee(null);
    toggleModal();
  };

  const handleAssign = async () => {
    try {
      setAssigning(true);
      await handleSubmit(selectedAssignee);
    } catch (e) {
      setError(e);
    } finally {
      setAssigning(false);
    }
  };

  const handleUnassign = async () => {
    try {
      setUnassigning(true);
      await handleSubmit(null);
    } catch (e) {
      setError(e);
    } finally {
      setUnassigning(false);
    }
  };

  const assignableUsersOptions = useMemo(() => {
    const sortedUsers = _.sortBy(assignableUsers, [(u) => (previousAssignee === u.email ? 0 : 1), "name", "email"]);

    return usersToOptions(sortedUsers, previousAssignee);
  }, [assignableUsers, previousAssignee]);

  return (
    <>
      <Modal isOpen={open} toggle={toggleModal} {...modalProps}>
        <ModalHeader toggle={toggleModal}>Assignment</ModalHeader>
        {currentAssignee && (
          <ModalBody>
            <p>
              Currently assigned to <strong>{currentAssignee}</strong>
            </p>
          </ModalBody>
        )}

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Select
            isClearable
            isSearchable
            autoFocus
            value={assignableUsersOptions.find((o) => o.value === selectedAssignee)}
            onChange={(o) => (o ? setSelectedAssignee(o.value) : setSelectedAssignee(null))}
            options={assignableUsersOptions}
            placeholder="Select Assignee"
          />
        </ModalBody>

        <ModalFooter>
          <Button outline onClick={toggleModal}>
            Cancel
          </Button>

          <LoadingButton loading={unassigning} disabled={submitting || !!selectedAssignee} onClick={handleUnassign}>
            Unassign
          </LoadingButton>

          <LoadingButton loading={assigning} disabled={submitting || !selectedAssignee} onClick={handleAssign}>
            Assign
          </LoadingButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export const AssignmentModalButton = ({ buttonProps, disabled, buttonLabel = "Assign", open, ...props }) => {
  const [modalOpen, setModalOpen] = useState(open);
  const toggleModal = () => setModalOpen(!modalOpen);

  return (
    <>
      <Button {...buttonProps} onClick={toggleModal} disabled={disabled}>
        {buttonLabel}
      </Button>

      <AssignmentModal {...props} open={modalOpen} toggleModal={toggleModal} />
    </>
  );
};
