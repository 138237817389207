import { Link } from "react-router-dom";
import { BoolCell } from "../../components/grid/cells/BoolCell";
import { BoolFilter } from "../../components/inputs/BoolFilter";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { Routes } from "../Routes";
import { makeColumns, useAdministrateTable } from "../utils";

export const ClinicsIndexPage = () => {
  const columns = makeColumns(() => [
    {
      id: "id",
      accessor: "id",
      Header: "ID",
      disableSortBy: true,
      disableFilters: true,
    },
    {
      id: "clinic_internal_id",
      accessor: "clinicInternalId",
      Header: "Clinic internal ID",
    },
    {
      id: "clinic_name",
      accessor: "clinicName",
      Header: "Clinic Name",
      Cell: ({ row, value }) => <Link to={Routes.clinic.url(row.original.id)}>{value}</Link>,
    },
    {
      id: "pa_creation_enabled",
      accessor: "paCreationEnabled",
      Header: "Prior Auth Enabled",
      Filter: BoolFilter,
      Cell: BoolCell,
      disableSortBy: true,
    },
    {
      id: "bi_creation_enabled",
      accessor: "biCreationEnabled",
      Header: "seeQer Enabled",
      Filter: BoolFilter,
      Cell: BoolCell,
      disableSortBy: true,
    },
    {
      id: "us_only",
      accessor: "usOnly",
      Header: "US Employees Only",
      Filter: BoolFilter,
      Cell: BoolCell,
      disableSortBy: true,
    },
    {
      id: "npi",
      accessor: "npi",
      Header: "NPI",
      disableSortBy: true,
    },
    {
      id: "tax_id",
      accessor: "taxId",
      Header: "Tax Id",
      disableSortBy: true,
    },
  ])();

  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  return (
    <SystemConfigLayout title="Clinics">
      <AdministrateTable
        resourceUrl={"/admin/clinics.json"}
        resourceName={"clinic"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
