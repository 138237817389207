import React from "react";
import { useTableContext } from "../../../components/AdministrateTable";
import { EditGlossaryModal } from "./EditGlossaryModal";
import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";

export const GlossaryActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();

  const handleTermEditSuccess = () => {
    setMessage(`Updated "${row.original.term}"`);
    refresh();
  };

  return (
    <>
      <EditGlossaryModal onSave={handleTermEditSuccess} termId={row.original.id} glossary={row.original} className="me-2" />
      <AdministrateResourceDeleteButton
        label="Delete"
        deleteUrl={`/admin/clinical_glossaries/${row.original.id}`}
        onSuccess={() => {
          setMessage(`Deleted "${row.original.term}"`);
          refresh();
        }}
        onError={(msg) => setError(msg)}
      />
    </>
  );
};
