import { useMemo } from "react";
import { DetailsList } from "../../../components/DetailsList";

const useProviderPayorNumberDetails = (providerPayorNumber) => {
  return useMemo(
    () => [
      { label: "ID", value: providerPayorNumber.id },
      { label: "Provider", value: providerPayorNumber.provider.npi },
      { label: "Payor", value: providerPayorNumber.payor.name },
      { label: "", value: providerPayorNumber.providerSpecificSubmissionNumber },
    ],
    [
      providerPayorNumber.id,
      providerPayorNumber.provider.npi,
      providerPayorNumber.payor.name,
      providerPayorNumber.providerSpecificSubmissionNumber,
    ]
  );
};

export const ProviderPayorNumberReadOnly = ({ providerPayorNumber }) => {
  const values = useProviderPayorNumberDetails(providerPayorNumber);
  return <DetailsList values={values} />;
};
