import { Field } from "react-final-form";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { required } from "../../../../util/validators";
import { LoadingButton } from "../../../../components/LoadingButton";
import { DateInput } from "../../../../components/inputs/final_form/DateInput";
import { formatDateTime } from "../../../../util/formatters";

export const SuperDialScriptForm = ({ handleSubmit, submitting, values }) => {
  return (
    <>
      <Field component={TextInput} required validate={required} label="ID" name="scriptId" />
      <Field component={TextInput} required validate={required} label="Name" name="scriptName" />
      <Field component={DateInput} required validate={required} label="Date Added to SuperDial" name="dateAdded" />

      {values.createdAt && (
        <div className="bg-info bg-opacity-10 text-center py-2 mb-3 rounded-2">
          <p className="fw-medium mb-0">Created on {formatDateTime(values.createdAt)}</p>
        </div>
      )}

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Save
      </LoadingButton>
    </>
  );
};
