import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { Card, CardBody, CardFooter } from "reactstrap";
import { LoadingButton } from "../components/LoadingButton";
import { NetworkStatusLabel } from "./NetworkStatusLabel";
import { PriceLabel } from "./PriceLabel";
import { humanizeList } from "../util/formatters";

export const PriceComparisonCard = ({ price, disabled, onClick, className, ...rest }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      await onClick();
    } finally {
      setLoading(false);
    }
  };

  const testNames = useMemo(() => humanizeList(price.tests.map((t) => t.name)), [price]);

  return (
    <Card className={classNames("price_comparison_card", className)} {...rest}>
      <CardBody className="p-4">
        <header>
          <h4>{testNames}</h4>

          <ul className="list-inline">
            <li className="list-inline-item text-muted">
              from <strong>{price.lab.lab_name}</strong>
            </li>

            <li className="list-inline-item">
              <NetworkStatusLabel inNetwork={price.in_network} />
            </li>
          </ul>
        </header>

        <div className="price_comparison_card__estimate_row">
          <strong className="text-muted">Estimated Patient Responsibility</strong>
          <div className="price_comparison_card__total_responsibility">
            <PriceLabel cents={price.result.estimated_patient_responsibility_total} />
          </div>
        </div>
      </CardBody>

      {onClick && (
        <CardFooter className="text-end bg-white">
          <LoadingButton outline loading={loading} disabled={disabled} onClick={handleClick}>
            Generate seeQer Case
          </LoadingButton>
        </CardFooter>
      )}
    </Card>
  );
};
