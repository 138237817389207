import { useCallback, useState } from "react";
import { api, extractErrorMessage } from "../../../util/api";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { PayorPathForm } from "./PayorPathForm";

export const AddPayorPathModal = ({ letterOptions, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = useCallback(
    async (values) => {
      try {
        const params = {
          path: values.letters.join(""),
        };

        await api.post("/admin/payor_paths", { payor_path: params });

        onAdd?.(values);

        setOpen(false);
      } catch (err) {
        setError(extractErrorMessage(err));
      }
    },
    [onAdd]
  );

  return (
    <>
      <Button color="primary" onClick={toggleModal} {...rest}>
        Add New Payor Path
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add Payor Path</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <PayorPathForm onSubmit={handleCreate} letterOptions={letterOptions} />
        </ModalBody>
      </Modal>
    </>
  );
};
