import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { parseCurrency } from "../../../util/formatters";
import { snakifyKeys } from "../../../util/helpers";
import { Icd10CodeForm } from "./Icd10CodeForm";

export const AddIcd10CodeModal = ({ onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((o) => !o);

  const handleCreate = async (values) => {
    setError(null);

    try {
      const res = await api.post(`/admin/icd10_codes`, snakifyKeys(values));

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add ICD10 Code
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add a new Icd10 Code</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{}}>
            {(props) => <Icd10CodeForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
