import React from "react";

import { SetClinicButton } from "./SetClinicButton";
import { SuppressCaseButton } from "./SuppressCaseButton";
import { formatLabName } from "../util/formatters";

export const UnmappedClinicMatchActionsCell = ({ row }) => {
  return (
    <div className="text-center">
      <div className="d-flex flex-row">
        <div className="me-2">
          <SetClinicButton
            id={row.original.id}
            publicId={row.original.requestId}
            lab={formatLabName(row.original)}
            externalClinicId={row.original.externalClinicId}
            clinic={row.original.potentialClinicName}
            clinicId={row.original.potentialClinicId}
          />
        </div>
        <div>
          <SuppressCaseButton id={row.original.id} />
        </div>
      </div>
    </div>
  );
};
