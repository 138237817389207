import React from "react";
import { Field } from "react-final-form";
import { Button, Col, FormGroup, Container } from "reactstrap";
import { BlankableField } from "../../../components/inputs/final_form/BlankableField";
import { MoneyInput } from "../../../components/inputs/final_form/MoneyInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required, distinctValues, atLeastTwoValuesMustBeSelected } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { BoolInput } from "../../../components/inputs/final_form/SelectInput";
import { CptSelector } from "../../../components/inputs/final_form/CptSelector";
import { FieldArray } from "react-final-form-arrays";
import { Fieldset } from "../../../components/inputs/final_form/Fieldset";
import { Trash } from "lucide-react";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { Checkbox } from "../../../components/inputs/final_form/Checkbox";

export const TestForm = ({ handleSubmit, submitting, edit = true, values, ...rest }) => {
  return (
    <>
      <Field component={TextInput} type="hidden" name="labId" />

      <Field component={TextInput} required validate={required} label="Test Name" name="commercialName" />

      {edit && (
        <Fieldset legend="Test Aliases">
          <FieldArray name="labTestMappings">
            {({ fields }) => (
              <Container>
                {fields.map((name, index) => (
                  <FormGroup key={`${name}-${index}`} className="align-items-end" row>
                    <Col xs="10">
                      {fields.value[index].test_name ? (
                        <Field
                          component={TextInput}
                          name={`${name}.test_name`}
                          validate={distinctValues("labTestMappings", "testName")}
                          label="By Test Name"
                        />
                      ) : null}
                      {fields.value[index].test_identifier ? (
                        <Field
                          component={TextInput}
                          name={`${name}.test_identifier`}
                          validate={distinctValues("labTestMappings", "testIdentifier")}
                          label="By Test Identifier"
                        />
                      ) : null}
                    </Col>
                    <Col xs="2">
                      <Button color="danger" outline className="pull-right" onClick={() => fields.remove(index)}>
                        <Trash />
                      </Button>
                    </Col>
                  </FormGroup>
                ))}
              </Container>
            )}
          </FieldArray>
        </Fieldset>
      )}
      <BlankableField component={TextInput} label="Test Code" name="testIdentifier" />
      <BlankableField component={TextInput} label="AIM Test Name" name="aimTestName" />
      <BlankableField component={TextInput} label="Beacon Test Name" name="beaconTestName" />
      <BlankableField component={TextInput} label="Optum Test Name" name="optumTestName" />

      <Field component={BoolInput} label="seeQer Enabled" name="seeqerEnabled" />

      <BlankableField component={MoneyInput} label="Cash Pay Pricing" name="cashPayPricingCents" />
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/test_categories.json"
        kept={true}
        label="Category"
        name="testCategoryId"
        labelKey="name"
      />

      <Field component={CptSelector} label="CPT Codes" name="cptCodes" />

      {edit && (
        <Fieldset legend="Auto-Split Settings">
          <Field component={Switch} type="checkbox" label="Enable Auto-Split" name="enableAutoSplit" />
          {values.enableAutoSplit && (
            <Field component={Switch} type="checkbox" label="Auto-Split on Ingestion" name="autoSplitOnIngestion" />
          )}
          {values.enableAutoSplit && !values.autoSplitOnIngestion && (
            <>
              <Field id="allPortals" component={Checkbox} label="Split for All Portals" name="allPortals" type="checkbox" />
              {!values.allPortals && (
                <Field
                  component={AdministrateSelectInput}
                  isMulti
                  key="benefitManagementPortalsIds"
                  label="Portals"
                  labelKey="name"
                  name="benefitManagementPortalsIds"
                  resourceUrl="/admin/benefit_management_portals.json"
                  required
                  validate={required}
                  valueKey="id"
                />
              )}
            </>
          )}
          {values.enableAutoSplit && (
            <Field
              component={AdministrateSelectInput}
              isMulti
              key="resultingTestAutoSplitConfigsIds"
              label="Resulting Tests"
              labelKey="commercial_name"
              name="resultingTestAutoSplitConfigsIds"
              resourceUrl={`/admin/tests.json?test[direction]=asc&test[order]=commercial_name&lab=${
                rest.initialValues.labId || rest.initialValues.lab.id
              }&archived=false`}
              validate={atLeastTwoValuesMustBeSelected}
              valueKey="id"
            />
          )}
        </Fieldset>
      )}

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
