import _, { debounce } from "lodash";
import qs from "qs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { api } from "./api";
import { camelizeKeys, safeJSONParse } from "./helpers";

export function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!_.isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

// This is the effect to use when we want one-off effects.
export const useMountEffect = (fn) => useEffect(fn, []); // eslint-disable-line react-hooks/exhaustive-deps

export function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, useDeepCompareMemoize(dependencies)); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useLocalStorage(key, initialValue, expiresAfter = Infinity) {
  const storedAtKey = `${key}-storedAt`;
  const now = new Date();

  // State to store our value
  // Pass initial state export function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const storedAt = window.localStorage.getItem(storedAtKey);
      const item = window.localStorage.getItem(key);
      const elapsed = (now - Date.parse(storedAt)) / 1000;

      // Parse stored json or if none return initialValue
      const expired = storedAt && elapsed > expiresAfter;
      return item && !expired ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter export function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a export function so we have same API as useState
      const valueToStore = _.isFunction(value) ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      window.localStorage.setItem(storedAtKey, now);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  const clearValue = () => {
    window.localStorage.removeItem(key);
    window.localStorage.removeItem(storedAtKey);
  };

  return {
    value: storedValue,
    set: setValue,
    clear: clearValue,
  };
}

export const useClickOutsideHandler = (ref, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
};

export const useAsync = (load, input) => {
  const currInput = useRef(input);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(null);

  const fetch = () => {
    currInput.current = input;

    setLoading(true);
    setError(null);
    setValue(null);
    load(input)
      .then((c) => {
        if (input === currInput.current) {
          setValue(c);
        }
      })
      .catch((err) => {
        if (input === currInput.current) {
          return setError(err);
        }
      })
      .finally(() => {
        if (input === currInput.current) {
          setLoading(false);
        }
      });
  };

  useEffect(fetch, [input, load]);

  return { loading, error, value, fetch, loaded: !loading && !error };
};

export const useCase = (caseId) => {
  const load = useMemo(
    () =>
      debounce(
        async (val) => {
          if (!val || val.length < 6) {
            return new Promise(() => {});
          }
          const response = await api.get(`/cases/${val}.json`);
          return camelizeKeys(response.data);
        },
        300,
        { leading: true, trailing: true }
      ),
    []
  );

  return useAsync(load, caseId);
};

export const useStateParams = (initialState, paramsName, serialize = qs.stringify, deserialize = qs.parse) => {
  const search = new URLSearchParams(window.location.search);

  const existingValue = search.get(paramsName);
  const [state, setState] = useState(existingValue ? safeJSONParse(deserialize(existingValue).params) : initialState);

  // Updates state when user navigates backwards or forwards in browser history
  useEffect(() => {
    if (existingValue) {
      // react will auto-bail if this is already the value
      // in the state object, so no need to do any fancy
      // conditional checking here.
      setState(safeJSONParse(deserialize(existingValue).params));
    }
  }, [existingValue, deserialize]);

  const onChange = (s) => {
    const newState = _.isFunction(s) ? s(state) : s;
    const searchParams = new URLSearchParams(window.location.search);
    const serializedState = serialize({ params: JSON.stringify(newState) });

    // Set state for react
    setState(newState);

    if (serializedState) {
      searchParams.set(paramsName, serializedState);
    } else {
      searchParams.delete(paramsName);
    }

    // Serialize state to URL
    const queryString = searchParams.toString();
    const path = queryString ? `${window.location.pathname}?${queryString}` : window.location.pathname;
    history.replaceState(null, null, path);
  };

  return [state, onChange];
};

// Adapted from https://github.com/donavon/use-interval
export const useInterval = (callback, delay) => {
  const savedCallbackRef = useRef();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = (...args) => savedCallbackRef.current(...args);

    if (delay !== null) {
      const intervalId = setInterval(handler, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
};

export const useStableValue = (val) => {
  const ref = useRef();
  ref.current = val;
  return useMemo(() => ref.current, []);
};

export const useUniqueId = (prefix) => useMemo(() => `${prefix}_${_.uniqueId()}`, [prefix]);

export const useEnum = (collectionName) => {
  const [loaded, setLoaded] = useState(false);
  const [collection, setCollection] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      setLoaded(false);
      try {
        setCollection((await api.get(`/enums/${collectionName}`)).data.resources);
        setError(undefined);
      } catch (e) {
        setError(e);
        setCollection(undefined);
      }

      setLoaded(true);
    })();
  }, [setLoaded, setCollection, setError, collectionName]);

  return { loaded, collection, error };
};

// useDebounced returns an async function that wraps the passed function
//  and is debounced on the trailing edge (so it will execute <timeout>ms after the last time it is called)
// Repeated calls to useDebounced from the same component (that is, as a result of re-rendering),
//  will return functions which share a debounce timer
// The function returned by useDebounced will change when the passed function (or the timeout) changes,
//  so memoization is the responsibility of the caller
// You can pass useDebounced a non-async function, but you will still have to await the return value
export const useDebounced = (asyncFunc, timeout = 300) => {
  const intervalRef = useRef(null);

  const invoke = useCallback(
    (...args) => {
      // We're using new Promise instead of an async function directly for easy clearing and so we can use setTimout
      return new Promise((resolve, reject) => {
        // Clear the previous timeout
        if (intervalRef.current) {
          clearTimeout(intervalRef.current);
        }

        // Start a new timeout
        intervalRef.current = setTimeout(() => {
          // asyncFunc could be async or not
          // If it's async, promises implicitly "stack", and the caller just needs to await once
          resolve(asyncFunc(...args));
        }, timeout);
      });
    },
    [timeout, asyncFunc]
  );

  return invoke;
};
