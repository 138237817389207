import { useCallback, useMemo, useState } from "react";
import { api } from "../util/api";
import { snakifyKeys } from "../util/helpers";

export const useFaxAttachmentForm = (fax) => {
  const [error, setError] = useState(null);
  const onSubmit = useCallback(
    async (values) => {
      try {
        await api.post(`/operations/fax/${fax.id}/attach`, snakifyKeys(values));
        window.open(`/operations/cases/${values.requestId}`, "_blank");
        window.location.reload();
      } catch (e) {
        setError(e);
        console.error(e);
      }
    },
    [fax.id]
  );

  const [kase, setCase] = useState(null);
  const caseLabId = useMemo(() => kase?.paRequest?.labInfo?.labId, [kase]);
  const caseLabName = useMemo(() => kase?.paRequest?.labInfo?.labName, [kase]);

  // If the Case in the form has a Lab that doesn't match the Lab on this
  // fax, then we should force a second acknowledgement confirming
  // that the user wants to do the attachment
  const requireLabAck = fax.lab?.lab_internal_id != null && caseLabId != null && fax.lab.lab_internal_id !== caseLabId;
  const acknowledgements = useMemo(() => {
    return requireLabAck
      ? [`You are about to attach a ${fax?.lab?.lab_name} fax to a case associated to ${caseLabName}. Are you sure you want to proceed?`]
      : [];
  }, [requireLabAck, fax?.lab?.lab_name, caseLabName]);

  return {
    onSubmit,
    error,
    acknowledgements,
    onLoadCase: setCase,
  };
};
