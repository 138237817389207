import React from "react";
import { ActionAlert } from "../../../components/ActionAlert";
import { LoadingButton } from "../../../components/LoadingButton";
import { WizardContext } from "../WizardContext";

export const NoAnswerScreen = () => (
  <WizardContext.Consumer>
    {({ loading, createFollowupTask }) => (
      <ActionAlert
        color="warning"
        actions={
          <LoadingButton
            color="warning"
            loading={loading}
            onClick={async () => createFollowupTask(2, "Follow up on Paper Submission - No Answer")}
          >
            Ok
          </LoadingButton>
        }
      >
        A Followup Task will be Created for <strong>2 days from today</strong>.
      </ActionAlert>
    )}
  </WizardContext.Consumer>
);
