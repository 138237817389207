import $ from "jquery";
import _ from "lodash";

const getValidPolicies = (payor_group, policies) => {
  return _.pickBy(policies, (policiesDefinition, _name) => _.includes(policiesDefinition?.payor_group_id?.toString(), payor_group));
};

const $emptyOption = $('<option value=""></option>').text("Select a Policy");

export function initPolicySelect() {
  const $policyCoverageSelectors = $(".import-policy-coverages");

  if ($policyCoverageSelectors.length > 0) {
    $policyCoverageSelectors.each(function () {
      attachPolicySelect(this);
    });
  }
  $(document).on("repeater.add", function (_e, $new) {
    attachPolicySelect($new);
  });
}

function attachPolicySelect($container) {
  const $payorGroupDropdown = $(".policy_coverage_payor_gorup", $container);
  const $policyDropdown = $(".policy_coverage_policy", $container);
  const availablePolicies = $policyDropdown.data("allPolicies");

  $payorGroupDropdown.on("change", function () {
    const currentPayorGroup = $payorGroupDropdown.val();
    const currentPolicy = $policyDropdown.val();
    let validPolicies = [];

    if (currentPayorGroup) {
      validPolicies = getValidPolicies(currentPayorGroup, availablePolicies);
    }

    $policyDropdown.empty();
    $policyDropdown.append($emptyOption);

    if (!_.isEmpty(validPolicies)) {
      _.forEach(validPolicies, (policiesDefinition) => {
        const { name, id } = policiesDefinition;

        const $newOption = $(`<option></option>`, { value: id }).text(name);

        $policyDropdown.append($newOption);
      });
    }
  });

  $payorGroupDropdown.trigger("change");
}
