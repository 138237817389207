import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { EditPayorAutoApprovalModal } from "./EditPayorAutoApprovalModal";
import { ViewPayorAutoApprovalModal } from "./ViewPayorAutoApprovalModal";
import { useDestroyPermission, useUpdatePermission } from "../../utils";

export const PayorAutoApprovalActionCell = ({ payorId, ...row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canUpdate] = useUpdatePermission("TestBundle", row.original.id);
  const [canDestroy] = useDestroyPermission("TestBundle", row.original.id);

  const handleChange = (msg) => {
    setMessage(`Updated ${row.original.name}`);
    refresh();
  };

  return (
    <>
      {canUpdate ? (
        <EditPayorAutoApprovalModal color="link" onCreate={handleChange} payorId={payorId} autoApproval={row.original} />
      ) : (
        <ViewPayorAutoApprovalModal color="link" payorId={payorId} autoApproval={row.original} />
      )}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/test_bundles/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Auto Approval Configuration ${row.original.name}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
    </>
  );
};
