import React from "react";
import { Field } from "react-final-form";
import { required } from "../../../util/validators";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { DropzoneInput } from "../../../components/inputs/final_form/DropzoneInput";

export const LabDocumentForm = ({ handleSubmit, submitting }) => {
  return (
    <>
      <Field component={TextInput} required validate={required} label="Description" name="description" />
      <Field component={DropzoneInput} required validate={required} label="Document" hint="Drop Document" name="attachment_document" />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
