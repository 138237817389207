import React from "react";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";

export const ImportLabCptCodeListPriceModal = ({ labId, onImport, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      labId: values.labId,
      file: {
        bytes: values.file,
      },
    };

    const res = await api.post(`/admin/lab_cpt_code_list_prices/import.json?lab_id=${labId}`, snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message);
    }
  };

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Import CPT Code List Price..."
      exampleDownloadPath="/admin/lab_cpt_code_list_prices/example"
    ></ImportModal>
  );
};
