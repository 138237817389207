import React from "react";
import { DragSource } from "react-dnd";
import { FIELD } from "../ItemTypes";
import cx from "classnames";

const itemSource = {
  beginDrag(props, monitor) {
    return props;
  },

  endDrag(props, monitor, component) {
    if (!monitor.didDrop()) {
      return;
    }
  },
};

const collect = (connect, monitor) => {
  return {
    connectDragPreview: connect.dragPreview(),
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

const TextRenderer = ({ label, scaleFactors, options = {} }) => {
  const { width, height, spacing, font } = options;
  const spacedLabel = label.split("").join(" ".repeat(spacing || 0));
  return <div style={{ width, height, fontSize: font }}>{spacedLabel}</div>;
};

const CheckmarkRenderer = ({ label, scaleFactors, isSelected, options }) => {
  let { invert = false, size } = options;
  const { vertical } = scaleFactors;

  size = size ? vertical * size : undefined;

  return (
    <label style={{ width: size, height: size }}>
      <input type="checkbox" checked={!invert} disabled />
      {isSelected && ` ${label}`}
    </label>
  );
};

class Tag extends React.Component {
  render() {
    const {
      x = 10,
      y = 10,
      scaleFactors = { vertical: 1, horizontal: 1 },
      label,
      type,
      isSelected,
      options = {},
      onClick,
      isDragging,
      connectDragSource,
      fieldDefinition,
    } = this.props;

    // Hide while dragging
    if (isDragging) {
      return null;
    }

    const scale = `scale(${scaleFactors.vertical}, ${scaleFactors.horizontal})`;

    const posStyle = {
      position: "absolute",
      left: x,
      top: y,
      transform: scale,
      transformOrigin: "top left",
    };

    const isCustomField = fieldDefinition.field && typeof fieldDefinition.field === "string" && fieldDefinition.field.startsWith("custom:");

    const tagClassNames = cx("fde__tag", "dtag", {
      "dtag--selected": isSelected,
      "dtag--custom": isCustomField,
    });

    return connectDragSource(
      <div onClick={onClick} style={posStyle} className={tagClassNames}>
        <div className="dtag__label">
          {type === "text" || type === "date" ? (
            <TextRenderer scaleFactors={scaleFactors} label={label} options={options} />
          ) : (
            <CheckmarkRenderer scaleFactors={scaleFactors} label={label} isSelected={isSelected} options={options} />
          )}
        </div>
      </div>
    );
  }
}

export const DraggableTag = DragSource(FIELD, itemSource, collect)(Tag);
