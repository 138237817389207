import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { Table } from "reactstrap";
import { api, useAdminAPI } from "../../../util/api";
import { StyledSelect as Select } from "../../../components/inputs/StyledSelect";

const PRIORITY_OPTIONS = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
];

const toPriorities = (rawData) => {
  if (!_.isEmpty(rawData)) {
    return Object.entries(rawData).map(([name, value], key) => {
      return {
        key,
        name,
        value: { label: value, value: value },
      };
    });
  }

  return [];
};

export const MyCasesPriority = () => {
  const [rawData, setRawData] = useState([]);
  const { data, error, isLoading } = useAdminAPI(`/admin/my_cases_priorities/get_instance`);

  useEffect(() => {
    if (!isLoading && !error && data) {
      setRawData(data);
    }
  }, [data, isLoading, error, setRawData]);

  const priorities = useMemo(() => toPriorities(rawData), [rawData]);

  const update = useCallback(
    async (setting, newValue) => {
      const res = await api.patch(`/admin/my_cases_priorities/update_instance`, { [setting]: newValue.value });
      setRawData(res.data);
    },
    [setRawData]
  );

  return (
    <SystemConfigLayout title="My Cases Priority">
      <Table>
        <tbody>
          {priorities.map(({ key, name, value }) => (
            <tr key={key}>
              <th>{_.startCase(name)}</th>
              <td>
                <Select value={value} onChange={(e) => update(name, e)} options={PRIORITY_OPTIONS} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </SystemConfigLayout>
  );
};
