import { createRoot } from "react-dom/client";

import ClinicsGrid from "./ClinicsGrid";

export const initClinicIndex = () => {
  const gridContainer = document.getElementById("clinic_index");

  if (gridContainer) {
    const { clinicIndexUrl } = gridContainer.dataset;
    const root = createRoot(gridContainer);
    root.render(<ClinicsGrid dataUrl={clinicIndexUrl} />);
  }
};
