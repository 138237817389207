import { Col } from "reactstrap";
import { FormRow } from "../../components/forms/FormRow";
import { Field, FormSpy } from "react-final-form";
import { TextInput } from "../../components/inputs/final_form/TextInput";
import { composeValidators, required, requiredNotNull } from "../../util/validators";
import { DateInput } from "../../components/inputs/final_form/DateInput";
import { SerializedPayorSelector } from "../../components/inputs/final_form/PayorSelector";
import { radioIsInsured } from "../../new_case/PatientAndInsuranceInfoHelperMethods";
import { InsuranceMemberIdInput } from "../../new_case/InsuranceMemberIdInput";
import { useCallback, useMemo, useState } from "react";
import { isBlank } from "../../util/helpers";
import { GenderSelector } from "../../components/inputs/GenderSelector";
import { PatientIsInsuredSelector } from "../../components/inputs/PatientIsInsuredSelector";
import { Switch } from "../../components/inputs/final_form/Switch";
import { searchPayorById, searchPayorByName } from "../../new_case/PatientAndInsuranceInfoFields";

export const PatientInsuranceInfo = ({ form, values, errors, uninsuredPatientPayor }) => {
  const [isUninsured, setIsUninsured] = useState(false);
  const [showPayorSelect, setShowPayorSelect] = useState(true);
  const uninsuredPatientPayorId = useMemo(() => uninsuredPatientPayor?.id, [uninsuredPatientPayor]);

  const useInsuranceName =
    !isBlank(values.insuranceInfo?.primaryInsurance?.insuranceName) && isBlank(values.insuranceInfo?.primaryInsurance?.insuranceId);

  const handleInsuranceNameSelected = useCallback(
    (name) => {
      const payor = searchPayorByName(name);

      form.change("insuranceInfo.primaryInsurance.insuranceId", payor?.public_id);
      form.change("insuranceInfo.primaryInsurance.insuranceName", payor?.name);
    },
    [form]
  );

  const handleMatchingPayorFound = useCallback(
    (payorId, payorName) => {
      form.change("insuranceInfo.primaryInsurance.insuranceName", payorName);
      form.change("insuranceInfo.primaryInsurance.insuranceId", payorId);
    },
    [form]
  );

  const clearUninsuredInsuranceValues = useCallback((form) => {
    form.change("insuranceInfo.primaryInsurance.memberId", null);
    form.change("insuranceInfo.primaryInsurance.insuranceName", null);
    form.change("insuranceInfo.primaryInsurance.insuranceId", null);
    form.change("insuranceInfo.primaryInsurance.planId", null);
    form.change("insuranceInfo.primaryInsurance.groupId", null);
  }, []);

  const setUninsuredInsuranceValues = useCallback(
    (form) => {
      form.change("insuranceInfo.primaryInsurance.memberId", null);
      form.change("insuranceInfo.primaryInsurance.insuranceName", uninsuredPatientPayor.name);
      form.change("insuranceInfo.primaryInsurance.insuranceId", uninsuredPatientPayor.public_id);
    },
    [uninsuredPatientPayor]
  );

  const handlePatientUninsured = useCallback(
    ({ target }) => {
      form.change("insuranceInfo.primaryInsurance.insuranceId", radioIsInsured(target.value) ? undefined : uninsuredPatientPayorId);

      if (!radioIsInsured(target.value)) {
        setIsUninsured(true);
        setShowPayorSelect(false);
        setUninsuredInsuranceValues(form);
      } else {
        setIsUninsured(false);
        setShowPayorSelect(true);
        clearUninsuredInsuranceValues(form);
      }
    },

    [form, uninsuredPatientPayorId, setUninsuredInsuranceValues, clearUninsuredInsuranceValues]
  );

  const handleInsuranceIdSelected = useCallback(
    async (idOrPublicId) => {
      const payor = await searchPayorById(idOrPublicId);
      await form.change("insuranceInfo.primaryInsurance.insuranceName", payor.name);
      await form.change("insuranceInfo.primaryInsurance.insuranceId", idOrPublicId);
    },
    [form]
  );

  return (
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        return (
          <>
            <div className="my-2">
              <h5 className="fw-bold text-dark">Patient Info</h5>
            </div>
            <FormRow>
              <Col>
                <Field
                  required
                  name="patientInfo.firstName"
                  label="First Name"
                  component={TextInput}
                  validate={composeValidators(requiredNotNull)}
                />
              </Col>

              <Col>
                <Field
                  required
                  name="patientInfo.lastName"
                  label="Last Name"
                  component={TextInput}
                  validate={composeValidators(requiredNotNull)}
                />
              </Col>

              <Col>
                <Field required name="patientInfo.dob" label="DOB" component={DateInput} validate={composeValidators(requiredNotNull)} />
              </Col>

              <Col>
                <Field
                  required
                  name="patientInfo.sex"
                  label="Gender"
                  component={(props) => <GenderSelector {...props} />}
                  validate={composeValidators(required)}
                />
              </Col>
            </FormRow>

            <div className="my-2">
              <h5 className="fw-bold text-dark">Insurance Info</h5>
            </div>

            <FormRow>
              <Field
                required
                label="Is the patient insured?"
                id="insuranceInfo.insured"
                name="insuranceInfo.insured"
                validate={composeValidators(requiredNotNull)}
                onIsInsuredChangeCallback={handlePatientUninsured}
                component={PatientIsInsuredSelector}
              />
            </FormRow>

            {!isUninsured && showPayorSelect && (
              <>
                <FormRow>
                  <Col>
                    <Field id="allPayors" component={Switch} label="Show all Payors" name="allPayors" type="checkbox" />
                  </Col>
                </FormRow>
                <FormRow>
                  <Col xs="6">
                    <Field
                      required
                      validate={composeValidators(requiredNotNull)}
                      name="insuranceInfo.primaryInsurance.memberId"
                      component={InsuranceMemberIdInput}
                      label="Member ID"
                      data-testid="primaryInsuranceMemberID"
                      selectedPayorId={_.get(values, "insuranceInfo.primaryInsurance.insuranceId")}
                      onMatchFound={handleMatchingPayorFound}
                    />
                  </Col>

                  <Col>
                    {useInsuranceName ? (
                      <Field
                        required
                        validate={composeValidators(requiredNotNull)}
                        name="insuranceInfo.primaryInsurance.insuranceName"
                        render={SerializedPayorSelector}
                        valueSelector="name"
                        isClearable
                        label="Primary Insurance Name"
                        onChange={handleInsuranceNameSelected}
                      />
                    ) : (
                      <FormSpy subscription={{ values: true }}>
                        {({ values }) => {
                          return values.allPayors == true ? (
                            <Field
                              required
                              validate={composeValidators(requiredNotNull)}
                              name="insuranceInfo.primaryInsurance.insuranceId"
                              render={SerializedPayorSelector}
                              valueSelector="public_id"
                              label="Primary Insurance"
                              data-testid="primaryInsuranceId"
                              onChange={handleInsuranceIdSelected}
                            />
                          ) : (
                            <Field
                              required
                              validate={composeValidators(requiredNotNull)}
                              name="insuranceInfo.primaryInsurance.insuranceId"
                              render={SerializedPayorSelector}
                              valueSelector="public_id"
                              key="filtered_payors"
                              scope="clinic_user"
                              label="Primary Insurance"
                              onChange={handleInsuranceIdSelected}
                            />
                          );
                        }}
                      </FormSpy>
                    )}
                  </Col>
                </FormRow>
              </>
            )}
          </>
        );
      }}
    </FormSpy>
  );
};
