import React from "react";
import { FormText } from "reactstrap";
import { InputWrapper } from "../../../components/inputs/final_form/InputWrapper";
import { AdministrateSelect } from "../../components/AdministrateSelect";

export const AdministrateSelectInput = ({ children, input, label, meta, loadOptions, defaultOptions, ...rest }) => {
  return (
    <InputWrapper label={label} required={rest.required}>
      <AdministrateSelect {...input} {...rest} />

      {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
      {children}
    </InputWrapper>
  );
};
