import $ from "jquery";
import { initDatepickerForElement } from "../forms/inputs/init_datepicker";
import { initSubstatusSelect } from "../forms/inputs/init_substatus_select";
import { api } from "../../util/api";

export const initRepeater = () => {
  $(() => {
    let repeaterTemplates = {};
    let repeaterCounts = {};
    let startingCounts = {};

    $("[data-repeat-add]").each(function () {
      const $this = $(this);
      const target = $this.data("target");
      const $original = $(`[data-repeat='${target}']:first`);
      const count = $(`[data-repeat='${target}']`).length;
      repeaterCounts[target] = count;
      startingCounts[target] = count;
      repeaterTemplates[target] = $original.clone();
    });

    $("[data-repeat]").each(function () {
      const $this = $(this);
      $this.data("original", true);
      $this.find("[data-repeat-remove]").attr("disabled", true);
    });

    $("[data-repeat-destroy]").each(function () {
      const $this = $(this);
      const target = $this.data("target");
      const disabled = target ? false : true;

      $this.data("original", true);
      $this.attr("disabled", Boolean(disabled));
    });

    $("body").on("click", "[data-repeat-add]", function (e) {
      e.preventDefault();

      const $this = $(this);
      const target = $this.data("target");
      const maxNew = $this.data("repeat-max-new");

      if (maxNew && repeaterCounts[target] - startingCounts[target] >= maxNew) {
        return false;
      }

      const $original = $(`[data-repeat='${target}']:first`);
      const $new = repeaterTemplates[target].clone();
      const $newInputs = $(":input", $new);

      // reset any inputs
      $newInputs.val("");
      $newInputs.first().focus();

      // Do some nonsense that can't easily be done in a clean multiselect like above
      // increment any indexes inside ids and names (enabling list updates)
      $newInputs.each((i, newInput) => {
        const $newInput = $(newInput);
        let name = $newInput.attr("name");
        if (name) {
          const newName = name.replace(/\[(\d)\]/gi, () => `[${repeaterCounts[target]}]`);
          $newInput.attr("name", newName);
        }

        // If we're adding datepickr-classed stuff, we need to actually init it
        if ($newInput.hasClass("datepicker")) {
          initDatepickerForElement(newInput);
        }

        if ($newInput.hasClass("request_substatus")) {
          initSubstatusSelect(newInput);
        }

        if ($newInput.hasClass("select")) {
          var value = $newInput?.get(0)?.options[0]?.value;
          if (value) {
            $newInput.val(value);
          }
        }

        // billing rate specific to default money values
        if ($newInput.attr("id") === "billing_rate_price") {
          $newInput.val("0.00");
        }
      });

      // reenable ability to remove
      $new.find("[data-repeat-remove]").attr("disabled", false);
      $new.find("[data-repeat-destroy]").attr("disabled", true);
      $new.insertBefore($original);
      repeaterCounts[target] = repeaterCounts[target] + 1;

      if (maxNew && repeaterCounts[target] - startingCounts[target] >= maxNew) {
        $this.attr("disabled", true);
      }

      $(document).trigger("repeater.add", [$new]);
    });

    $("body").on("click", "[data-repeat-remove]", function (e) {
      e.preventDefault();

      const $this = $(this);
      const target = $this.data("target");
      const $self = $this.closest(`[data-repeat='${target}']`);
      const $adder = $(`[data-target='${target}'][data-repeat-add]`);
      const maxNew = $adder.data("repeat-max-new");

      if (!$self.data("original")) {
        $self.remove();
        repeaterCounts[target] = repeaterCounts[target] - 1;

        if (maxNew && repeaterCounts[target] - startingCounts[target] < maxNew) {
          $adder.attr("disabled", false);
        }

        $(document).trigger("repeater.remove", [$self]);
      }
    });

    $("body").on("click", "[data-repeat-destroy]", function (e) {
      e.preventDefault();

      const $this = $(this);
      const target = $this.data("target");
      const confirmMessage = $this.data("confirm");

      destroy(target, confirmMessage);
    });
  });
};

const destroy = async (target, confirmMessage) => {
  if (confirm(confirmMessage)) {
    try {
      await api.delete(`${target}`);
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
  }
};
