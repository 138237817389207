import React from "react";
import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../../components/AdministrateTable";
import { useDestroyPermission, useUpdatePermission } from "../../../utils";
import { EditSeeqerReflexMappingModal } from "./EditSeeqerReflexMappingModal";
import { History } from "../../../tools/sandbox/release/History";

export const SeeqerReflexMappingActionCell = ({ row }) => {
  const [canUpdate] = useUpdatePermission("SeeqerReflexMapping", row.original.id);
  const [canDestroy] = useDestroyPermission("SeeqerReflexMapping", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = (msg) => {
    setMessage(`Updated ${row.original.dashboardDisplayName}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditSeeqerReflexMappingModal color="link" onEdit={handleChange} seeqerReflexMapping={row.original} />}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          label="Remove"
          deleteUrl={`/admin/seeqer_reflex_mappings/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Discarded ${row.original.dashboardDisplayName}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
      <History entityName={"SeeqerReflexMapping"} subjectId={row.original.id} />
    </>
  );
};
