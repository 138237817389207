import React from "react";
import { ActionAlert } from "../../../../components/ActionAlert";
import { LoadingButton } from "../../../../components/LoadingButton";
import { WizardContext } from "../../WizardContext";

export const InvalidStatusScreen = () => (
  <WizardContext.Consumer>
    {({ loading, continueWorkflow }) => {
      return (
        <ActionAlert
          color="warning"
          actions={
            <>
              <LoadingButton color="warning" loading={loading} onClick={continueWorkflow}>
                Continue
              </LoadingButton>
            </>
          }
        >
          <strong>Uh oh...</strong> looks like we've ended up in the MI workflow with an invalid status or substatus. Click{" "}
          <strong>Continue</strong> to return to processing the case.
        </ActionAlert>
      );
    }}
  </WizardContext.Consumer>
);
