import React from "react";
import { Field, Form } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required } from "../../../util/validators";
import { Switch } from "../../../components/inputs/final_form/Switch";

export const SftpDirectoryForm = ({ handleAction, sftp, directory }) => {
  return (
    <Form onSubmit={handleAction} initialValues={{ sftpConnectionSettingId: sftp.id, ...directory }}>
      {({ handleSubmit, submitting }) => {
        return (
          <>
            <Field component={TextInput} required validate={required} name="sftpConnectionSettingId" readOnly type="hidden" />
            <Field component={TextInput} label="Folder Path" name="folderPath" />
            <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
              Save
            </LoadingButton>
          </>
        );
      }}
    </Form>
  );
};
