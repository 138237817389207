import React from "react";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { TabbedRoutes } from "../../components/TabbedRoutes";
import { BulkPaRestatusForm } from "./BulkRestatusForm";
import { BulkPaRestatusHistory } from "./BulkRestatusHistory";

const restatusTabs = [
  {
    path: "upload",
    label: "Bulk Restatus PA Cases",
    Component: BulkPaRestatusForm,
  },
  {
    path: "history",
    label: "Attempt History",
    Component: BulkPaRestatusHistory,
  },
];

export const RestatusPACasesPage = () => {
  return (
    <SystemConfigLayout title={["Tools", "Restatus PA Cases"]}>
      <TabbedRoutes tabs={restatusTabs} defaultTab={"upload"} />
    </SystemConfigLayout>
  );
};
