import React, { useState } from "react";
import { ConfirmDeleteModal } from "../../../components/ConfirmDeleteModal";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";

export const DiscardPayorContactModal = ({ onDelete, contact, ...rest }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await api.delete(`/admin/payor_contacts/${contact.id}`);

      if (onDelete) {
        onDelete();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ConfirmDeleteModal
        Target={
          <LoadingButton loading={loading} {...rest}>
            Delete
          </LoadingButton>
        }
        confirmMessage={<p>Are you sure you want to delete {contact.title}?</p>}
        onDelete={handleDelete}
      />
    </>
  );
};
