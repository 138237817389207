import React from "react";
import { Field } from "react-final-form";
import { BlankableField } from "../../../components/inputs/final_form/BlankableField";
import { MoneyInput } from "../../../components/inputs/final_form/MoneyInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required } from "../../../util/validators";
import { BoolInput } from "../../../components/inputs/final_form/SelectInput";
import { CptSelector } from "../../../components/inputs/final_form/CptSelector";

export const Icd10CodeForm = ({ handleSubmit, submitting, ...rest }) => {
  return (
    <>
      <Field component={TextInput} required validate={required} label="Code" name="code" />
      <BlankableField component={TextInput} label="Description" name="description" />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
