import _ from "lodash";
import React from "react";
import { AdministrateResourceSorter } from "../../../components/AdministrateResourceSorter";
import { useUpdatePermission } from "../../../utils";

export const RankOrderTab = () => {
  const [canUpdate] = useUpdatePermission("PayorPrefixMapping");
  return canUpdate ? (
    <AdministrateResourceSorter
      Label={({ item }) => <code>{item.member_id_prefix_regex}</code>}
      Description={({ item }) => _.get(item, "payor.name", "No Payor Name")}
      resourceUrl={"/admin/payor_prefix_mappings"}
      resourceKey="payor_prefix_mapping"
      updateAttribute="mapping_order_position"
    />
  ) : null;
};
