import moment from "moment-timezone";
import { useState } from "react";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { LoadingButton } from "../../../components/LoadingButton";
import { Sorter } from "../../../components/sorter/Sorter";
import { Toolbar } from "../../../components/Toolbar";
import { api } from "../../../util/api";
import { useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const PayorsPortalSorterTab = ({ payor, refresh, loading }) => {
  const [saving, setSaving] = useState(false);
  const [lastSavedAt, setLastSavedAt] = useState(null);
  const [error, setError] = useState(null);
  const [canUpdate] = useUpdatePermission("Payor", payor.id);

  const handleSave = async (update) => {
    setError(null);
    setSaving(true);

    try {
      // Do update
      await api.put(`/admin/payor_portal_mappings/${update.id}`, {
        payor_portal_mapping: { portal_order_position: update.newIndex },
      });

      // Reload
      refresh();

      setLastSavedAt(moment());
    } catch (err) {
      setError(err);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      <DetailedErrorAlert error={error} />

      <Toolbar className="align-items-center mb-3">
        <Toolbar>
          {lastSavedAt !== null ? (
            <span className="text-muted small">Last saved {lastSavedAt.format("MM/DD/YYYYY hh:mm a")}</span>
          ) : (
            <span className="text-muted small">No Changes</span>
          )}
        </Toolbar>

        <Toolbar className="ms-auto gap-2">
          <History entityName={"PayorPortalMapping"} />
          <LoadingButton outline loading={loading} disabled={saving} onClick={refresh}>
            Reload
          </LoadingButton>
        </Toolbar>
      </Toolbar>

      <Sorter
        Label={({ item }) => item.portal_name}
        initialItems={payor?.payor_portals}
        disabled={!canUpdate || saving || loading}
        onChange={handleSave}
      />
    </div>
  );
};
