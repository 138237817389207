import { createRoot } from "react-dom/client";
import { SubmitRightPaperResponse } from "../../paper_responses/SubmitRightPaperResponse";

export const initSubmitRightPaperResponse = () => {
  const container = document.getElementById("paper_response");

  if (container) {
    const { caseId, saveUrl, request, paStatuses, paperResponses, verifiedCptCodeOptions, denialReasonCodeOptions } = container.dataset;

    const root = createRoot(container);

    root.render(
      <SubmitRightPaperResponse
        caseId={caseId}
        saveUrl={saveUrl}
        paStatuses={JSON.parse(paStatuses)}
        request={JSON.parse(request)}
        paperResponses={JSON.parse(paperResponses)}
        verifiedCptCodeOptions={JSON.parse(verifiedCptCodeOptions)}
        denialReasonCodeOptions={JSON.parse(denialReasonCodeOptions)}
      />
    );
  }
};
