import React, { useCallback } from "react";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";

export const ImportClinicLabRedirectionsModal = ({ clinicId, onImport, ...rest }) => {
  const handleImport = useCallback(
    async (values) => {
      const params = {
        clinic: clinicId,
        file: {
          bytes: values.file,
        },
      };
      const res = await api.post(`/admin/clinic_lab_redirections/import.json`, snakifyKeys(params));

      onImport?.(res.data?.meta?.message, res.data?.meta?.error_details);
    },
    [onImport, clinicId]
  );

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Import Redirection..."
      exampleDownloadPath="/admin/clinic_lab_redirections/example"
    ></ImportModal>
  );
};
