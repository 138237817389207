import React from "react";
import { CaseLinkCell, OpsCaseLinkCell } from "../components/grid/cells/CaseLinkCell";
import { DateFilter } from "../components/inputs/DateFilter";
import { formatDateTime } from "../util/formatters";
import { FaxActionsCell } from "./FaxActionsCell";
import { EnumFilter } from "../components/inputs/EnumFilter";

// This is not really a hook, it just follows a naming
// convention for hooks, and so it triggers eslint.
/* eslint-disable react-hooks/rules-of-hooks */
export const useColumns = ({ isInternalUser, sent = false, discarded = false, labOptions = [] }) => {
  if (sent) {
    return useSentColumns(isInternalUser);
  } else if (discarded) {
    return useTrashColumns(isInternalUser, labOptions);
  } else {
    return useInboxColumns(isInternalUser);
  }
};
/* eslint-enable react-hooks/rules-of-hooks */

const useInboxColumns = (isInternalUser) => [
  {
    Header: "Fax Id",
    accessor: "id",
  },
  {
    Header: "Created Date",
    id: "created_at",
    accessor: (r) => formatDateTime(r.created_at),
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    Header: "Sender Fax Number",
    accessor: "fax_number",
  },
  {
    Header: "Sender Name",
    accessor: "company_name",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Associated Case",
    accessor: "case_id",
    Cell: isInternalUser ? OpsCaseLinkCell : CaseLinkCell,
  },
  {
    Header: "Lab",
    accessor: "lab.lab_name",
    disableFilters: true,
  },
  {
    Header: "Actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: FaxActionsCell,
    width: 200,
  },
];

const useSentColumns = (isInternalUser) => [
  {
    Header: "Fax Id",
    accessor: "id",
  },
  {
    Header: "Sent Date",
    id: "created_at",
    accessor: (r) => formatDateTime(r.created_at),
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    Header: "Recipient Fax Number",
    accessor: "fax_number",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Associated Case",
    accessor: "case_id",
    Cell: isInternalUser ? OpsCaseLinkCell : CaseLinkCell,
  },
  {
    Header: "Actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => <FaxActionsCell row={row} attachmentAllowed={false} />,
    width: 200,
  },
];

const useTrashColumns = (isInternalUser, labOptions) => [
  {
    Header: "Fax Id",
    accessor: "id",
  },
  {
    Header: "Created Date",
    id: "created_at",
    accessor: (r) => formatDateTime(r.created_at),
    Filter: (props) => <DateFilter {...props} />,
  },
  {
    Header: "Mailbox",
    id: "fax_type",
    accessor: (r) => (r.fax_type == "inbound" ? "Inbox" : "Sent"),
    Filter: (props) => (
      <EnumFilter
        {...props}
        options={[
          { value: "inbound", label: "Inbox" },
          { value: ["outbound", "mail", null], label: "Sent" },
        ]}
      />
    ),
  },
  {
    Header: "Fax Number",
    accessor: "fax_number",
  },
  {
    Header: "Name",
    accessor: "recipient_name",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Associated Case",
    accessor: "case_id",
    Cell: isInternalUser ? OpsCaseLinkCell : CaseLinkCell,
  },
  {
    Header: "Lab",
    accessor: "lab.lab_name",
    id: "lab_id",
    Filter: (props) => <EnumFilter {...props} options={labOptions} />,
  },
  {
    Header: "Actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => <FaxActionsCell row={row} attachmentAllowed={false} />,
    width: 200,
  },
];
