import React from "react";
import { ChevronRight } from "lucide-react";

export const PUBLIC_ID_CELL = (value) => {
  return (
    <div>
      <a className="text-decoration-none d-flex" href={`/cases/${value}`}>
        <span className="text-secondary text-decoration-none fw-semibold">{value}</span>
        <ChevronRight className="text-muted align-self-center ms-1" size={14} />
      </a>
    </div>
  );
};

export const STATUS_CELL = (value, IconComponent, iconClassNames, currentStatus) => {
  return (
    <div className="position-relative">
      <div className="btn-icon bg-info bg-opacity-10 rounded-4 d-flex align-items-center p-1 position-absolute">
        <IconComponent className={iconClassNames} size={18} />
        <span className="ms-1 text-capitalize">{value === "approved" ? value : currentStatus}</span>
      </div>
    </div>
  );
};

export const ACTION_CELL = (actions) => {
  return (
    <div className="position-relative">
      <div className="d-flex align-items-center position-absolute">
        {actions.length > 0 &&
          actions.map((action) => {
            return action;
          })}
      </div>
    </div>
  );
};

export const MISSING_INFO_CATEGORIES_CELL = ({ value }) => {
  return <span>{value.map((category) => category.name).join(" | ")}</span>;
};
