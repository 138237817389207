import React from "react";
import { Button } from "./Button";
import { LoadingIcon } from "./LoadingIcon";

export const LoadingButton = ({ loading, icon, disabled, children, loadingLabel = null, ...props }) => (
  <Button icon={loading ? null : icon} disabled={loading || disabled} {...props}>
    {loading ? (
      <>
        <LoadingIcon className="me-1" /> {loadingLabel ?? children}
      </>
    ) : (
      children
    )}
  </Button>
);
