import React, { useState } from "react";
import { LoadingButton } from "../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../util/api";

export const AdministrateResourceDiscardButton = ({ label = "Discard", discardUrl, onError, onSuccess }) => {
  const [discarding, setDiscarding] = useState(false);

  const handleDiscard = async () => {
    if (!confirm("Are you sure you want to discard this item?")) {
      return;
    }

    setDiscarding(true);

    try {
      await api.post(discardUrl);
      onSuccess();
    } catch (err) {
      onError(extractErrorMessage(err));
    } finally {
      setDiscarding(false);
    }
  };

  return (
    <LoadingButton loading={discarding} color="link" className="text-danger" onClick={handleDiscard}>
      {label}
    </LoadingButton>
  );
};
