import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { SftpDirectoryForm } from "./SftpDirectoryForm";

export const AddSftpDirectoryModal = ({ labId, sftp, onAdd }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);
    try {
      const { data } = await adminApi.post(`/admin/labs/${labId}/sftp_directories`, snakifyKeys({ labSftpDirectory: values }));

      if (data) {
        onAdd(data);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} color="link">
        Add Sweep Directory
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          Add Sweep Directory to {sftp.host}:{sftp.port}
        </ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <SftpDirectoryForm handleAction={handleCreate} sftp={sftp} />
        </ModalBody>
      </Modal>
    </>
  );
};
