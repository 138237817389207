import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Routes } from "../../Routes";
import { useAdminResource } from "../../utils";
import { isValidJSON } from "../../../util/helpers";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";

const useBreadcrumbs = (pageTitle, caseType, benefitId) =>
  useMemo(
    () => [
      { url: Routes.pullBenefitsLogs.url(), label: Routes.pullBenefitsLogs.label },
      { url: Routes.benefitsDetail.url(caseType, benefitId), label: pageTitle, active: true },
    ],
    [pageTitle, caseType, benefitId]
  );

const useBenefit = (caseType, benefitId) =>
  useAdminResource(
    caseType == "type_prior_auth" ? `/admin/pverify_requests/${benefitId}.json` : `/admin/bi_eligibility_data/${benefitId}.json`
  );

export const PullBenefitsLogDetailPage = () => {
  const { caseType, benefitId } = useParams();
  const { resource: benefitData, error, isLoading, fetch } = useBenefit(caseType, benefitId);
  const pageTitle = `Benefit #${benefitId}`;
  const breadcrumbs = useBreadcrumbs(pageTitle, caseType, benefitId);

  return (
    <SystemConfigLayout title={pageTitle} showBreadcrumbs crumbs={breadcrumbs} loading={isLoading}>
      <dl className="row">
        <dt className="col-sm-3">Source</dt>
        <dd className="col-sm-9">{benefitData?.source}</dd>
        <dt className="col-sm-3">Transaction ID</dt>
        <dd className="col-sm-9">{benefitData?.source_transaction_id}</dd>
        <dt className="col-sm-3">Response Body</dt>
        <dd className="col-sm-9">
          {isValidJSON(benefitData?.response_body) ? JSON.stringify(benefitData?.response_body, null, 2) : benefitData?.response_body}
        </dd>
      </dl>
    </SystemConfigLayout>
  );
};
