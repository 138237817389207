import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { required } from "../../../util/validators";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { LoadingButton } from "../../../components/LoadingButton";
import { snakifyKeys } from "../../../util/helpers";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { objectToOptions } from "../../../util/formatters";
import { LAB_ROLES, CLINIC_ROLES } from "../../../util/data/global_roles";
import { OnChange } from "react-final-form-listeners";

export const AddMembershipModal = ({ accountId, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [type, setType] = useState(null);

  const toggleModal = () => {
    setOpen(!open);
    setType(null);
  };

  const handleAdd = async (values) => {
    setError(null);

    try {
      const { data } = await adminApi.post(`/admin/accounts/${accountId}/memberships.json`, snakifyKeys(values));

      onAdd(data.meta.message);

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Membership
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add Membership</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleAdd}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    required
                    validate={required}
                    component={SelectInput}
                    label="Type"
                    name="membership.type"
                    options={[
                      { label: "Lab", value: "lab" },
                      { label: "Clinic", value: "clinic" },
                    ]}
                  />
                  <OnChange name="membership.type" children={setType} />

                  {type == "lab" && (
                    <>
                      <Field
                        required
                        validate={required}
                        component={AdministrateSelectInput}
                        resourceUrl="/admin/labs.json"
                        label="Lab"
                        name="membership.typeId"
                      />
                      <Field
                        required
                        validate={required}
                        component={SelectInput}
                        options={objectToOptions(LAB_ROLES)}
                        label="Lab Role"
                        name="membership.role"
                      />
                    </>
                  )}

                  {type == "clinic" && (
                    <>
                      <Field
                        required
                        validate={required}
                        component={AdministrateSelectInput}
                        resourceUrl="/admin/clinics.json"
                        label="Clinic"
                        name="membership.typeId"
                      />
                      <Field
                        required
                        validate={required}
                        component={SelectInput}
                        options={objectToOptions(CLINIC_ROLES)}
                        label="Clinic Role"
                        name="membership.role"
                      />
                    </>
                  )}

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Save
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
