import arrayMutators from "final-form-arrays";
import React, { useMemo, useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Alert } from "reactstrap";
import { AddressFields, ADDRESS_FIELDS } from "../../../components/inputs/final_form/AddressFields";
import { BlankableField } from "../../../components/inputs/final_form/BlankableField";
import { Fieldset } from "../../../components/inputs/final_form/Fieldset";
import { PercentInput } from "../../../components/inputs/final_form/PercentInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { Condition } from "../../../components/inputs/final_form/Condition";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { camelizeKeys, snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";
import { useReadPermission, useUpdatePermission } from "../../utils";
import { LabContactFields, LabIdentifierFields, LabNpiFields } from "../components/LabFields";
import { VerifiedImportSorter } from "../components/VerifiedImportSorter";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { History } from "../../tools/sandbox/release/History";
import { StatusTranslationsFields } from "../components/StatusTranslationsFields";

const rekeyValues = (values) => {
  const newPayload = _.cloneDeep(values);
  newPayload.labSettingAttributes = values.labSetting;
  newPayload.addressAttributes = _.pick(values, ADDRESS_FIELDS);

  return _.omit(newPayload, ["labSetting", "address", ...ADDRESS_FIELDS]);
};

const defaultWorkflows = [
  { label: "Prior Auth Request", value: "prior_auth_request" },
  { label: "Benefits Investigation", value: "benefits_investigation" },
];

const standardizeReportTypes = [
  { label: "SSR V2 PA Only", value: "ssr_v2_pa" },
  { label: "SSR V2 PA and BI", value: "ssr_v2_pa_and_bi" },
];

const ssrReportTimeframes = [
  { label: "90 days", value: 90 },
  { label: "60 days", value: 60 },
  { label: "30 days", value: 30 },
  { label: "1 day", value: 1 },
];

export const LabsGeneralTab = ({ lab, labId, refresh }) => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [canRead] = useReadPermission("Lab", lab.id);
  const [canUpdate] = useUpdatePermission("Lab", lab.id);
  const [pharmacyFlag, setPharmacyFlag] = useState(false);

  const handleUpdate = async (values) => {
    setError(null);
    setMessage(null);

    try {
      await api.put(`/admin/labs/${labId}`, { lab: snakifyKeys(rekeyValues(values)) });
      setMessage("Updated Lab");
      refresh();
    } catch (err) {
      setError(extractErrorMessage(err));
    } finally {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const initialValues = useMemo(() => camelizeKeys(lab), [lab]);
  const flattenedInitialValues = _.merge(initialValues, _.omit(initialValues.address, ["id", "dashboardDisplayName"]));

  const initialAssignableOptions = useMemo(() => {
    return lab.assignedTo ? [{ label: lab.assignedTo.fullNameOrEmail, value: lab.assignedTo.id }] : [];
  }, [lab]);

  const readOnly = !canUpdate;

  return (
    <>
      {error && <Alert color="danger">{error}</Alert>}
      {message && <Alert color="info">{message}</Alert>}

      {canRead && (
        <Form
          onSubmit={handleUpdate}
          initialValues={flattenedInitialValues}
          mutators={{
            ...arrayMutators,
          }}
        >
          {({ handleSubmit, values, form, submitting, pristine }) => {
            return (
              <>
                <Field component={TextInput} required validate={required} label="ID" name="id" readOnly />

                <FormSpy subscription={{ values: true }}>
                  {({ values }) => {
                    if (values.servicingProviderType === "Pharmacy") {
                      if (pharmacyFlag) {
                        form.change("ribbonNetworkStatus", true);
                        setPharmacyFlag(false);
                      }
                    } else {
                      setPharmacyFlag(true);
                    }
                  }}
                </FormSpy>

                <LabIdentifierFields initialAssignableOptions={initialAssignableOptions} readOnly={readOnly} />

                <LabNpiFields form={form} setError={setError} readOnly={readOnly} />

                <LabContactFields readOnly={readOnly} />

                <AddressFields readOnly={readOnly}></AddressFields>

                <Fieldset legend="Lab Settings">
                  <VerifiedImportSorter label="Verified Date of Service Field Order" readOnly={readOnly} />
                  <Field
                    component={Switch}
                    label="Concord Fax Enabled"
                    name="labSetting.concordFaxEnabled"
                    id="concord-fax-enabled"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <BlankableField
                    component={TextInput}
                    label="Concord Fax Number"
                    name="labSetting.concordFaxNumber"
                    type="text"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Seeqer Enabled"
                    name="labSetting.seeqerEnabled"
                    id="seeqer-enabled"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Autoreflex PA"
                    name="labSetting.autoPa"
                    id="auto-pa"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Has Servicing Labs"
                    name="labSetting.hasServicingLabs"
                    id="has-servicing-labs"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Display Tasks to Lab"
                    name="labSetting.displayTasksToLab"
                    id="display-tasks-to-lab"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <BlankableField
                    component={TextInput}
                    label="Soft Fingerprinting Window (days)"
                    name="labSetting.softFingerprintWindow"
                    type="number"
                    min={1}
                    readOnly={readOnly}
                  />
                  <BlankableField component={TextInput} label="Lab SOP" name="labSetting.sop" type="text" readOnly={readOnly} />
                  <BlankableField
                    component={TextInput}
                    label="Supplemental Document Format"
                    name="labSetting.supplementalDocumentFormat"
                    type="text"
                    readOnly={readOnly}
                  />

                  <StatusTranslationsFields form={form} translations={lab.labSetting.statusTranslations} />

                  <Field
                    component={SelectInput}
                    label="Default Workflow"
                    name="labSetting.defaultWorkflow"
                    options={defaultWorkflows}
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Prior Auth Request"
                    name="labSetting.priorAuthRequestEnabled"
                    id="prior-auth-request-enabled"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Benefits Investigation"
                    name="labSetting.benefitsInvestigationEnabled"
                    id="benefits-investigation-enabled"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Post Service Review"
                    name="labSetting.postServiceReviewEnabled"
                    id="post-service-review-enabled"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Digital PA Only"
                    name="labSetting.digitalPaOnly"
                    id="digital-pa-only"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <BlankableField
                    component={PercentInput}
                    label="Medicare Percentage"
                    name="labSetting.medicarePercentage"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="MI handled by clinic"
                    name="labSetting.miHandledByClinic"
                    id="only-contact-lab"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <BlankableField
                    component={TextInput}
                    label="SSR Exclude Columns"
                    name="labSetting.ssrExcludeColumns"
                    type="text"
                    readOnly={readOnly}
                  />
                  <BlankableField
                    component={TextInput}
                    label="Report Name Template"
                    name="labSetting.reportNameTemplate"
                    type="text"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Generate Standardize Report"
                    name="labSetting.generateStandardizeReport"
                    id="generate-standardize-report"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={SelectInput}
                    label="Standardized Report Type"
                    name="labSetting.standardizedReportType"
                    options={standardizeReportTypes}
                    isClearable
                    readOnly={readOnly}
                  />
                  <Field
                    component={SelectInput}
                    label="Report Timeframe"
                    name="labSetting.ssrReportTimespan"
                    options={ssrReportTimeframes}
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Receive Daily CSV Report"
                    name="labSetting.receiveDailyCsvReport"
                    id="receive-daily-csv-report"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Can Order Genetic Counseling"
                    name="labSetting.canOrderGeneticCounseling"
                    id="can-order-genetic-counseling"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Enable Terminal Status Mailer"
                    name="labSetting.enableTerminalStatusMailer"
                    id="enable-terminal-status-mailer"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Submit Request Urgency Field Enabled"
                    name="labSetting.submitRequestUrgencyFieldEnabled"
                    id="submit-request-urgency-field-enabled"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Deduplicate with Lab IDs"
                    name="labSetting.deduplicateWithLabIds"
                    id="deduplicate-with-lab-ids"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Use Insurance Plan Type Code for Network Status Check"
                    name="labSetting.usePlanTypeForNetworkStatusCheck"
                    id="use-plan-type-for-network-status-check"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Allow Lab User to Close Out Cases"
                    name="labSetting.allowUserToCloseOutCases"
                    id="allow-user-to-close-out-cases"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Display Codes as Tests"
                    name="labSetting.displayCodesAsTests"
                    id="display-codes-as-tests"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="XIFIN Enabled"
                    name="labSetting.xifinEnabled"
                    id="xifin-enabled"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  {form.getState().values.labSetting.xifinEnabled && (
                    <div>
                      <BlankableField
                        component={TextInput}
                        label="Xifin OrgAlias"
                        name="labSetting.xifinOrgalias"
                        type="text"
                        readOnly={readOnly}
                      />
                      <BlankableField
                        component={TextInput}
                        label="Xifin User ID"
                        name="labSetting.xifinUserId"
                        type="text"
                        readOnly={readOnly}
                      />
                      <BlankableField
                        component={TextInput}
                        label="Xifin Password"
                        name="labSetting.xifinPassword"
                        type="password"
                        readOnly={readOnly}
                      />
                      <Field
                        component={AdministrateSelectInput}
                        resourceUrl="/admin/enums/xifin_submission_number_location_options.json"
                        labelKey="label"
                        valueKey="id"
                        label="Xifin Update Submission Number Locations"
                        name="labSetting.xifinSubmissionNumberLocation"
                        readOnly={readOnly}
                      />
                      <BlankableField
                        component={TextInput}
                        label="Xifin Authorization Number for Not Required and DCC"
                        name="labSetting.xifinNrAuthorizationNumber"
                        type="text"
                        readOnly={readOnly}
                      />
                    </div>
                  )}
                  <Field
                    component={Switch}
                    label="Move Cases without Supporting Docs To Held and Rejected after X Days"
                    name="labSetting.rejectDueToNoSupportingDocuments"
                    id="reject-due-to-no-supporting-documents"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Condition when={({ labSetting }) => labSetting.rejectDueToNoSupportingDocuments === true}>
                    <Field
                      component={TextInput}
                      label="Days Held"
                      name="labSetting.rejectDueToNoSupportingDocumentsDaysHeld"
                      id="reject-due-to-no-supporting-documents-days-held"
                      type="text"
                      readOnly={readOnly}
                    />
                  </Condition>
                  <Field
                    component={Switch}
                    label="Auto-reject Cases in MI: Missing Documents, Clinical, or Demographics"
                    name="labSetting.autoMiRejectionEnabled"
                    id="enable-auto-rejection-of-missing-information"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  {values.labSetting.autoMiRejectionEnabled && (
                    <Field
                      component={TextInput}
                      required
                      validate={required}
                      min={0}
                      step={1}
                      label="MI Rejection Grace Period (days)"
                      name="labSetting.autoMiRejectionGracePeriod"
                      type="number"
                      readOnly={readOnly}
                    />
                  )}
                  <Field
                    component={Switch}
                    label="Edith Enabled"
                    name="labSetting.edithEnabled"
                    id="enable-auto-rejection-of-missing-information"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Tilde Delimited (Sweep Setting)"
                    name="labSetting.tildeDelimited"
                    id="tilde-delimited"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Auto-Reflex to PA if Unknown Requirements"
                    name="labSetting.autoReflexIfUnknownPaRequirements"
                    id="auto-reflex-if-unknown-pa-requirements"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  {values.labSetting.benefitsInvestigationEnabled && (
                    <Field
                      component={Switch}
                      label="Do Not Generate Seeqer Report"
                      name="labSetting.doNotGenerateSeeqerPdf"
                      id="do-not-generate-seeqer-pdf"
                      type="checkbox"
                      readOnly={readOnly}
                    />
                  )}
                  <Field
                    component={Switch}
                    label="Ignore Eligibility Rejection"
                    name="labSetting.ignoreEligibilityRejection"
                    id="ignore-eligibility-rejection"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Ignore Eligibility When Reflex to PA"
                    name="labSetting.ignoreEligibilityIfReflexToPa"
                    id="ignore-eligibility-if-reflex-to-pa"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Reflex to PA When Patient is Ineligible"
                    name="labSetting.reflexWhenPatientIneligible"
                    id="ignore-eligibility-if-reflex-to-pa"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Remove No HCP PA's from Bill"
                    name="labSetting.removeNoHcpFromBill"
                    id="remove-no-hcp-from-bill"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Show Documented Clean Claim for PA"
                    name="labSetting.showDocumentedCleanClaimForPa"
                    id="show-documented-clean-claim-for-pa"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Do Not Status as Code Specific Result without approval"
                    name="labSetting.doNotStatusAsCodeSpecificWithoutApproval"
                    id="do-not-show-code-specific"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Show Code Specific Results for PA"
                    name="labSetting.showCodeSpecificResultsForPa"
                    id="show-code-specific-results-for-pa"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Limited Access"
                    name="labSetting.limitedAccess"
                    id="limited-access"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Hide Policy Link"
                    name="labSetting.hidePolicyLink"
                    id="hide-policy-link"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Only Use Provided CPT Codes"
                    name="labSetting.onlyUseProvidedCptCodes"
                    id="only-use-provided-cpt-codes"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Use abbreviated PDF"
                    name="labSetting.useAbbreviatedPdf"
                    id="use-abbreviated-pdf"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Lab Not available for price comparisons"
                    name="labSetting.labNotAvailableForPriceComparisons"
                    id="lab-not-available-for-price-comparisons"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Hold for Medical Records Request"
                    name="labSetting.holdForMedicalRecordsRequest"
                    id="hold-for-medical-records-request"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Skip Portal If Physician is Unenrolled"
                    name="labSetting.skipPortalIfPhysicianIsUnenrolled"
                    id="skip-portal-if-physician-is-unenrolled"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Create PA Result Document"
                    name="labSetting.createPaResultDocument"
                    id="create-pa-result-document"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Run Tevix Discovery"
                    name="labSetting.runTevixDiscovery"
                    id="run-tevix-discovery"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Use Snowflake Pricing"
                    name="labSetting.useSnowflakePricing"
                    id="use-snowflake-pricing"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Trizetto Lab Enabled"
                    name="labSetting.trizettoLabEnabled"
                    id="trizetto-lab-enabled"
                    type="checkbox"
                    readOnly={readOnly}
                  />
                  <Field
                    component={Switch}
                    label="Display Pa Approval %"
                    name="labSetting.displayPaApproval"
                    id="display-pa-approval"
                    type="checkbox"
                    readOnly={readOnly}
                  />

                  <Field component={Switch} label="Lab Needs Configuration" name="labNeedsConfiguration" type="checkbox" />
                </Fieldset>

                <div className="d-flex flex-row gap-2">
                  {canUpdate && (
                    <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine}>
                      Save
                    </LoadingButton>
                  )}

                  <History entityName={"Lab"} subjectId={labId} />
                </div>
              </>
            );
          }}
        </Form>
      )}
    </>
  );
};
