import React, { useContext } from "react";
import { ActionAlert } from "../../../../components/ActionAlert";
import { LoadingButton } from "../../../../components/LoadingButton";
import { WizardContext } from "../../WizardContext";

export const ExitLabWorkflowScreen = () => {
  const { loading, exitWorkflow, setError, caseId, caseData } = useContext(WizardContext);
  const { maxDaysInLabMi, autoRejectLabMi } = caseData.workflowInfo.priorAuthRequest;

  const handleContinue = async () => {
    setError(null);

    try {
      await exitWorkflow(`Lab MI Followup Started for ${caseId}`);
    } catch (err) {
      setError(`Unexpected Error: ${err.message}`);
    }
  };

  return (
    <ActionAlert
      color="warning"
      actions={
        <>
          <LoadingButton color="warning" loading={loading} onClick={handleContinue}>
            Continue
          </LoadingButton>
        </>
      }
    >
      {autoRejectLabMi ? (
        <>
          {window.branding.brandName} Cares Team has been notified of the missing information and will work with{" "}
          <strong>{caseData.labInfo.labName}</strong> to resolve. If the missing information is not resolved within {maxDaysInLabMi} days,
          this case will be closed. To resolve missing information, return to the MI workflow when new information is received from{" "}
          <strong>{caseData.labInfo.labName}</strong>.
        </>
      ) : (
        <>
          {window.branding.brandName} Cares Team has been notified of the missing information and will work with{" "}
          <strong>{caseData.labInfo.labName}</strong> to resolve. To resolve missing information, return to the MI workflow when new
          information is received from <strong>{caseData.labInfo.labName}</strong>.
        </>
      )}
    </ActionAlert>
  );
};
