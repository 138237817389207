import React from "react";
import { Field } from "react-final-form";
import { required } from "../../../util/validators";
import { LoadingButton } from "../../../components/LoadingButton";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { CptSelector } from "../../../components/inputs/final_form/CptSelector";

export const TestRepositoryForm = ({ handleSubmit, submitting, ...rest }) => {
  return (
    <>
      <Field component={TextInput} required validate={required} label="Product/Procedure/Test Name" name="commercialName" />
      <Field component={TextInput} label="Product/Procedure/Test ID" name="testIdentifier" />
      <Field component={CptSelector} required validate={required} label="Procedure Codes" name="cptCodes" />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
