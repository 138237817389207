import React from "react";
import { NavLink as Link } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavItem, UncontrolledDropdown } from "reactstrap";
import { useAnyActivePath } from "../util/useAnyActivePath";
import { usePermissions } from "./PermissionContext";
import { CHECK_PERMISSION, ADMINISTER_PERMISSION, useBulkPermissions } from "./utils";

const shouldCheckPermission = (route) => route.resource || route.action;

const getRouteActions = (route) => {
  const action = route.action || ADMINISTER_PERMISSION;
  return Array.isArray(action) ? action : [action];
};

const PermissionGate = ({ route, children }) => {
  const checkActions = getRouteActions(route);
  const [loading] = useBulkPermissions(checkActions, route.resource);
  const { checkPermission } = usePermissions();
  const showChildren =
    loading ||
    checkActions.some((a) => {
      const perm = checkPermission(a, route.resource);
      return perm === CHECK_PERMISSION || perm;
    });
  return showChildren && children;
};

const PermissionGateMemo = React.memo(PermissionGate);

const RouteNavItem = ({ route }) => {
  const item = (
    <NavItem>
      <Link to={route.url()} exact={route.exactMatch} className="nav-link">
        {route.label}
      </Link>
    </NavItem>
  );

  return shouldCheckPermission(route) ? <PermissionGateMemo route={route}>{item}</PermissionGateMemo> : item;
};

const RouteDropdownItem = ({ route }) => {
  const item = (
    <DropdownItem tag={Link} to={route.url()} exact={route.exactMatch}>
      {route.label}
    </DropdownItem>
  );

  return shouldCheckPermission(route) ? <PermissionGateMemo route={route}>{item}</PermissionGateMemo> : item;
};

const RouteDropdownItemMemo = React.memo(RouteDropdownItem);

const RouteDropdownMenu = ({ route }) => {
  const hasActiveItem = useAnyActivePath(route.items.map((t) => t.path));
  const { checkPermission } = usePermissions();
  const showDropdown = route.items.some((r) => {
    if (!shouldCheckPermission(r)) return true;

    const checkActions = getRouteActions(r);
    return checkActions.some((a) => {
      const perm = checkPermission(a, r.resource);
      return perm === CHECK_PERMISSION || perm;
    });
  });

  return (
    showDropdown && (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret className={hasActiveItem ? "active" : ""}>
          {route.label}
        </DropdownToggle>

        <DropdownMenu>
          {route.items.map((r) => (
            <RouteDropdownItemMemo key={r.path} route={r} />
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  );
};

export const SystemConfigNavbar = ({ routes }) => {
  return (
    <Navbar color="white" light expand="xs">
      <Nav navbar>
        {routes.map((r) => (r.items ? <RouteDropdownMenu key={r.label} route={r} /> : <RouteNavItem key={r.path} route={r} />))}
      </Nav>
    </Navbar>
  );
};
