import { createRoot } from "react-dom/client";
import { MissingInfoFollowupWizard } from "../../workflow/wizards/MissingInfoFollowupWizard";

export function initMissingInfoFollowup() {
  const container = document.getElementById("missinginfo");

  if (container) {
    const root = createRoot(container);

    const {
      caseId,
      caseUrl,
      caseData,
      labTests,
      assigneeUrl,
      commentsUrl,
      statusUrl,
      snoozeUrl,
      tasksUrl,
      nextStepUrl,
      moveToStepUrl,
      submitUrl,
      miInfoUrl,
      recordMiSalesforceContactUrl,
      recordMiContactAttemptUrl,
      missingFields,
      followupContactType,
    } = container.dataset;

    const parsedCaseData = caseData ? JSON.parse(caseData) : null;
    const parsedLabTests = labTests ? JSON.parse(labTests) : null;
    const parsedMissingFields = missingFields ? JSON.parse(missingFields) : null;

    root.render(
      <MissingInfoFollowupWizard
        caseId={caseId}
        caseUrl={caseUrl}
        followupContactType={followupContactType}
        labTests={parsedLabTests}
        caseData={parsedCaseData}
        statusUrl={statusUrl}
        moveToStepUrl={moveToStepUrl}
        assigneeUrl={assigneeUrl}
        snoozeUrl={snoozeUrl}
        tasksUrl={tasksUrl}
        nextStepUrl={nextStepUrl}
        commentsUrl={commentsUrl}
        submitUrl={submitUrl}
        miInfoUrl={miInfoUrl}
        missingFields={parsedMissingFields}
        recordMiSalesforceContactUrl={recordMiSalesforceContactUrl}
        recordMiContactAttemptUrl={recordMiContactAttemptUrl}
      />
    );
  }
}
