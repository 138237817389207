import { useMemo } from "react";
import { DetailsList } from "../../../components/DetailsList";

export const StatusMappingReadOnly = ({ statusMapping }) => {
  const values = useMemo(
    () => [
      { label: "Status", value: statusMapping.status },
      { label: "Request Submitted", value: statusMapping.requestSubmitted },
      {
        label: "CPT Code IDs",
        value: statusMapping.cptCodes.map((c) => (
          <span className="token me-1" key={c.id}>
            {c.code}
          </span>
        )),
      },
      {
        label: "Test IDs",
        value: statusMapping.tests.map((t) => (
          <span className="token me-1" key={t.id}>
            {t.test_identifier}
          </span>
        )),
      },
      { label: "Automated Comment", value: statusMapping.automatedComment },
    ],
    [statusMapping]
  );

  return <DetailsList values={values} />;
};
