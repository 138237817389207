import { createRoot } from "react-dom/client";
import { SubmitRightDirectionForm } from "../../submit_right_direction/SubmitRightDirectionForm";

export const initSubmitRightDirectionForm = () => {
  const submitRightDirectionFormContainer = document.getElementById("submit_right_direction");

  if (submitRightDirectionFormContainer) {
    const root = createRoot(submitRightDirectionFormContainer);
    const {
      saveUrl,
      nextPortalUrl,
      caseId,
      payorId,
      portalId,
      portalName,
      portalMessageOptions,
      denialReasonCodeOptions,
      verifiedCptCodeOptions,
      attachmentCategories,
      portalMessages,
      request,
      tests,
    } = submitRightDirectionFormContainer.dataset;

    root.render(
      <SubmitRightDirectionForm
        saveUrl={saveUrl}
        nextPortalUrl={nextPortalUrl}
        caseId={caseId}
        payorId={payorId}
        portalId={portalId}
        portalName={portalName}
        portalMessages={JSON.parse(portalMessages)}
        request={JSON.parse(request)}
        tests={JSON.parse(tests)}
        portalMessageOptions={JSON.parse(portalMessageOptions)}
        attachmentCategories={JSON.parse(attachmentCategories)}
        denialReasonCodeOptions={JSON.parse(denialReasonCodeOptions)}
        verifiedCptCodeOptions={JSON.parse(verifiedCptCodeOptions)}
      />
    );
  }
};
