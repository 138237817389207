import React from "react";

export const Fieldset = ({ children, legend, ...props }) => (
  <div className="fieldset" {...props}>
    <div className="fieldset">
      {Boolean(legend) && <legend>{legend}</legend>}
      {children}
    </div>
  </div>
);
