import { unflatten } from "flat";
import Case from "case";
import { deepCompact } from "./helpers";

export const encodeTableState = (tableState, state = {}) => {
  const { search } = state;
  const { page = 0, pageSize = 20, sorted = [], filtered = [] } = tableState;

  return {
    page: Number(page) + 1,
    per: pageSize,
    sorted: sorted,
    filtered: filtered,
    q: search,
  };
};

export const encodeTableStateForAdmin = (tableState, resourceName, state = {}) => {
  const { search } = state;
  const { page = 0, pageSize = 20, sorted = [], filtered = [] } = tableState;

  const filters = encodeFilterParams(filtered);
  const sort = encodeSortParam(resourceName, sorted[0]);

  return {
    page: Number(page) + 1,
    per: pageSize,
    q: search,
    ...filters,
    ...sort,
  };
};

export const encodeSortParam = (resourceName, sort) =>
  sort
    ? {
        [resourceName]: {
          direction: sort.desc ? "desc" : "asc",
          order: Case.snake(sort.id),
        },
      }
    : undefined;

export const encodeFilterParams = (filters) =>
  deepCompact(
    unflatten(
      _.reduce(
        filters,
        (acc, f, idx) => {
          acc[f.id] = f.value;
          return acc;
        },
        {}
      )
    )
  );
