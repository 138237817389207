import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { DateInput } from "../components/inputs/final_form/DateInput";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { LabSelector } from "../components/inputs/final_form/LabSelector";
import { ClinicSelector } from "../components/inputs/final_form/ClinicSelector";
import { Checkbox } from "../components/inputs/final_form/Checkbox";
import { AdministrateSelectInput } from "../system_config/components/inputs/AdministrateSelectInput";
import { LoadingButton } from "../components/LoadingButton";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { api, redirectTo, extractErrorMessage } from "../util/api";

export const NewAuthTokenForm = ({ tokenTypes }) => {
  const [error, setError] = useState(null);

  const tokenOptions = _.map(tokenTypes, (value, key) => ({ label: _.startCase(key), value: value }));

  const onSubmit = async (values) => {
    const formattedValues = {
      auth_token: {
        description: values.description,
        authable_lab_ids: values.authable_labs,
        authable_clinic_ids: values.authable_clinics,
        account_id: values.account,
        token_type: values.token_type,
        expires_at: values.expires_at,
        allow_basic: values.allow_basic,
        allow_signature_v1: values.allow_signature_v1,
      },
    };

    try {
      const res = await api.post("/admin/auth_tokens", formattedValues);
      redirectTo(`/admin/auth_tokens/${res.data.resource.id}`);
    } catch (error) {
      console.log(error);
      setError(extractErrorMessage(error));
    }
  };

  return (
    <>
      <DetailedErrorAlert error={error} className="mb-3" />
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Field name="description" component={TextInput} type="text" label="Description" />
            <Field name="authable_labs" render={LabSelector} label="Authable labs" isMulti data-testid="lab" key="all_labs" />
            <Field
              name="authable_clinics"
              render={ClinicSelector}
              label="Authable clinics"
              isMulti
              data-testid="clinic"
              key="all_clinics"
            />
            <Field
              component={AdministrateSelectInput}
              resourceUrl="/admin/accounts.json"
              label="Account"
              name="account_id"
              labelKey="full_name_or_email"
            />
            <Field name="token_type" component={SelectInput} options={tokenOptions} label="Token Type" />
            <Field name="expires_at" component={DateInput} label="Expires at" type="date" />
            <Field component={Checkbox} label="Allow Basic" name="allow_basic" type="checkbox" />
            <Field component={Checkbox} label="Allow Signature v1" name="allow_signature_v1" type="checkbox" />
            <LoadingButton loading={submitting} type="submit" color="primary" disabled={submitting}>
              Create Auth Token
            </LoadingButton>
          </form>
        )}
      />
    </>
  );
};
