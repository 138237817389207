import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { EditIcd10CodeModal } from "./EditIcd10CodeModal";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { AdministrateResourceDiscardButton } from "../../components/AdministrateResourceDiscardButton";
import { History } from "../../tools/sandbox/release/History";

export const Icd10CodeActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();

  const [canUpdate] = useUpdatePermission("Icd10Code", row.original.id);
  const [canDestroy] = useDestroyPermission("Icd10Code", row.original.id);

  return (
    <>
      {canUpdate && (
        <EditIcd10CodeModal
          color="link"
          icd10Code={row.original}
          onUpdate={(newCode) => {
            refresh();
            setMessage(`Added ICD10 Code ${newCode.code}`);
          }}
        />
      )}
      {canDestroy && (
        <AdministrateResourceDiscardButton
          discardUrl={`/admin/icd10_codes/${row.original.id}/discard`}
          onSuccess={() => {
            refresh();
            setMessage(`Discarded Policy ${row.original.code}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
      <History entityName="Icd10Code" subjectId={row.original.id ?? "unspecified"} />
    </>
  );
};
