import { useCallback, useState } from "react";
import { Switch } from "../../../components/inputs/Switch";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";

export const NetworkStatusToggle = ({ inNetwork, labName, payorName, payorLabSettingId, payorId, labId, onChange, onError, disabled }) => {
  const [loading, setLoading] = useState(false);
  const label = loading ? "Loading..." : inNetwork ? "In-Network" : "Out-of-Network";

  const getUpdateMessage = useCallback(
    (inNetwork) => `Set ${labName} as ${inNetwork ? "In-Network" : "Out of Network"} for ${payorName}`,
    [labName, payorName]
  );

  const handleCreate = useCallback(async () => {
    setLoading(true);

    try {
      await adminApi.post("/admin/payor_lab_settings.json", snakifyKeys({ payorLabSetting: { inNetwork: true, payorId, labId } }));
      onChange(getUpdateMessage(true));
    } catch (err) {
      onError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, [labId, payorId, getUpdateMessage, setLoading, onChange, onError]);

  const handleUpdate = useCallback(
    async (e) => {
      setLoading(true);
      const inNetwork = e.target.checked;

      try {
        await adminApi.put(`/admin/payor_lab_settings/${payorLabSettingId}.json`, snakifyKeys({ payorLabSetting: { inNetwork } }));

        onChange(getUpdateMessage(inNetwork));
      } catch (err) {
        onError(extractErrorMessage(err));
      } finally {
        setLoading(false);
      }
    },
    [payorLabSettingId, getUpdateMessage, setLoading, onChange, onError]
  );

  const handleChange = inNetwork === null ? handleCreate : handleUpdate;

  return (
    <Switch
      key={`network_status_${payorLabSettingId}_${labId}_${payorId}`}
      disabled={disabled || loading}
      id={`network_status_${payorLabSettingId}_${labId}_${payorId}`}
      checked={Boolean(inNetwork)}
      label={label}
      onChange={handleChange}
    />
  );
};
