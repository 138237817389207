import $ from "jquery";

export function initToggleMemberIdBenefitsInformation() {
  const $payorInput = $("#benefits_information_payor");
  const $memberInput = $("#member_id_benefits_information");

  if ($payorInput) {
    // Initial Check
    isUninsuredPayor($payorInput) ? $memberInput.hide() : $memberInput.show();

    $payorInput.on("change", () => {
      if (isUninsuredPayor($payorInput)) {
        $memberInput.hide();
        $memberInput.children().val("");
      } else {
        $memberInput.show();
      }
    });
  }
}

const isUninsuredPayor = ($selectedPayor) => $selectedPayor.find("option:selected").text() === "Uninsured Patient";
