import { BIReceipt } from "../../../../components/BIReceipt";

export const BIReceiptDemo = () => {
  const receiptItemsExample = [
    {
      label: "81162",
      value: "$1,234.56",
    },
    {
      label: "Total Covered Charges",
      value: "$1,234.56",
    },
    {
      label: "Total Non Covered Charges",
      value: "$0.00",
    },
    {
      label: "Current Remaining Deductible",
      value: "$0.00",
    },
    {
      label: "Current Max Out of Pocket",
      value: "$0.00",
    },
    {
      label: "Copay",
      value: "$0.00",
    },
    {
      label: "Coinsurance",
      value: "$0.00",
    },
    {
      label: "Total Estimated Patient Responsibility",
      value: "$0.00",
    },
    {
      label: "Total Charges Covered by Health Plan",
      value: "$1,234.56",
    },
  ];
  const firstExampleData = {
    headerMessage: "Estimated Total Patient Responsibility",
    headerSubMessage: "",
    headerValue: "$1,234.56",
    effectiveDate: "11/01/2022",
    receiptItems: receiptItemsExample,
  };
  const secondExampleData = {
    headerMessage: "Estimates - No Out Of Network Benefits",
    headerSubMessage: "Patient may be responsible for full charges of the test.",
    headerValue: "$1,234.56",
    effectiveDate: "11/01/2022",
    pendingProcessing: false,
    receiptItems: receiptItemsExample,
  };
  const thirdExampleData = {
    headerMessage: "Estimated Total Patient Responsibility",
    headerSubMessage: "Case is for non-seeQer test(s). No estimate generated.",
    headerValue: "N/A",
  };

  return (
    <>
      <h5 className="mt-3">BI Receipt Demo #1</h5>
      <BIReceipt
        headerMessage={firstExampleData.headerMessage}
        headerValue={firstExampleData.headerValue}
        effectiveDate={firstExampleData.effectiveDate}
        pendingProcessing={false}
        receiptItems={firstExampleData.receiptItems}
      />

      <h5 className="mt-3">BI Receipt Demo #2</h5>
      <BIReceipt
        headerMessage={secondExampleData.headerMessage}
        headerSubMessage={secondExampleData.headerSubMessage}
        headerValue={secondExampleData.headerValue}
        effectiveDate={secondExampleData.effectiveDate}
        pendingProcessing={false}
        receiptItems={secondExampleData.receiptItems}
      />

      <h5 className="mt-3">BI Receipt Demo #3</h5>
      <BIReceipt
        headerMessage={thirdExampleData.headerMessage}
        headerSubMessage={thirdExampleData.headerSubMessage}
        headerValue={thirdExampleData.headerValue}
      />

      <h5 className="mt-3">BI Receipt Demo #4</h5>
      <BIReceipt pendingProcessing={true} />
    </>
  );
};
