import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { BoxDirectoryForm } from "./BoxDirectoryForm";

export const EditLabBoxDirectoryModal = ({ labId, boxDirectory, onEdit }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const toggleModal = () => setOpen(!open);
  const title = `Edit Box Directory ${boxDirectory.box_folder_id}`;

  const handleAction = async (values) => {
    setError(null);
    try {
      const { data } = await api.put(`/admin/labs/${labId}/box_directories/${boxDirectory.id}`, snakifyKeys({ labBoxDirectory: values }));

      if (data) {
        onEdit(data);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal}>Edit</Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <BoxDirectoryForm handleAction={handleAction} directory={boxDirectory} />
        </ModalBody>
      </Modal>
    </>
  );
};
