import React, { useState, useCallback } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ClinicLabRedirectionForm } from "./ClinicLabRedirectionForm";

export const AddClinicLabRedirectionModal = ({ clinicId, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleAdd = useCallback(
    async (params) => {
      setError(null);

      try {
        await api.post(`/admin/clinic_lab_redirections`, snakifyKeys({ ...params, clinicId }));
        // Figure out why we're fetching data but not using it
        if (onAdd) {
          onAdd("Added Clinic Lab Redirection!");
        }

        setOpen(false);
      } catch (err) {
        setError(err);
      }
    },
    [clinicId, onAdd]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Clinic Lab Redirection
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Clinic Lab Redirection</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleAdd} initialValues={{ clinicId }}>
            {(props) => <ClinicLabRedirectionForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
