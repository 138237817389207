import { createRoot } from "react-dom/client";
import { SeerezOverview } from "../components/SeerezOverview";

export const initSeerezOverview = () => {
  const seerezAttatchmentsContainer = document.getElementById("seerez-overview");

  if (seerezAttatchmentsContainer) {
    const root = createRoot(seerezAttatchmentsContainer);
    const dataset = seerezAttatchmentsContainer.dataset;

    const caseAttachments = JSON.parse(dataset.caseAttachments);
    const caseAssociations = JSON.parse(dataset.caseAssociations);
    const caseComments = JSON.parse(dataset.caseComments);

    root.render(<SeerezOverview attachments={caseAttachments} associations={caseAssociations} comments={caseComments} />);
  }
};
