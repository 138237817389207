import { Field, FormSpy } from "react-final-form";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { composeValidators, numericInRange, required, requiredNotNull } from "../../../../util/validators";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";
import { LoadingButton } from "../../../../components/LoadingButton";
import { useEffect, useRef, useState } from "react";
import { adminApi as api } from "../../../../util/api";
import { SelectInput } from "../../../../components/inputs/final_form/SelectInput";
import { Switch } from "../../../../components/inputs/final_form/Switch";
import { DENIED_STATUSES } from "../../../../util/data/statuses";

const getSubstatusOptions = async (status, setSubstatusOptions) => {
  try {
    const { data } = await api.get(`/admin/enums/prior_auth_substatuses.json?status=${status}`);

    setSubstatusOptions(data.resources);
  } catch (err) {
    console.error(err);
  }
};

export const PhoneResponseForm = ({ handleSubmit, submitting, ...rest }) => {
  const [status, setStatus] = useState("");
  const [substatusOptions, setSubstatusOptions] = useState([]);
  const myRef = useRef();

  const handleStatusChange = (selected) => {
    myRef.current?.select?.clearValue();

    setStatus(selected?.value);
  };

  useEffect(() => {
    getSubstatusOptions(status, setSubstatusOptions);
  }, [status]);

  return (
    <>
      <Field component={TextInput} validate={required} label="Message" name="message" required />

      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/enums/phone_response_submit_right_direction_options.json"
        labelKey="label"
        valueKey="id"
        label="Submit Right Direction"
        name="submitRightDirection"
        validate={required}
        required
      />

      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          return (
            <>
              {values.submitRightDirection == "task_out_for_n_number_of_days_to_try_again" && (
                <>
                  <Field component={TextInput} label="Set Task Name" name="taskName" required validate={requiredNotNull} />
                  <Field
                    component={TextInput}
                    type="number"
                    name="taskDuration"
                    label="Set Task Duration(Days)"
                    validate={composeValidators(required, numericInRange(0, 90))}
                    min="0"
                    max="90"
                    required
                  />
                </>
              )}

              {values.submitRightDirection == "status_the_case" && (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/enums/prior_auth_statuses.json"
                    label="Status"
                    name="status"
                    labelKey="label"
                    onUpdate={handleStatusChange}
                    validate={requiredNotNull}
                    required
                  />

                  {substatusOptions?.length >= 1 && (
                    <Field
                      component={SelectInput}
                      label="Substatus"
                      name="substatus"
                      options={substatusOptions}
                      ref={myRef}
                      required
                      validate={required}
                    />
                  )}

                  {DENIED_STATUSES.includes(values.status) && (
                    <Field
                      component={AdministrateSelectInput}
                      resourceUrl="/admin/enums/denial_reason_options.json"
                      label="Denial Reason"
                      name="denialReasonCode"
                      labelKey="label"
                    />
                  )}
                </>
              )}

              <Field name="allPayors" subscription={{ value: true }}>
                {({ input: { value } }) =>
                  !value && (
                    <Field
                      component={AdministrateSelectInput}
                      isMulti
                      resourceUrl="/admin/payors.json"
                      label="Payors"
                      name="payorIds"
                      searchParam="name"
                      validate={required}
                      required
                      disabled={values.allPayors}
                    />
                  )
                }
              </Field>
              <Field component={Switch} name="allPayors" label="All Payors" type="checkbox" />
            </>
          );
        }}
      </FormSpy>

      <Field component={TextInput} label="Canned Comment" name="cannedComment" required validate={requiredNotNull} />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
