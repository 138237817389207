import _ from "lodash";
import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { InfoGrid } from "../components/grid/InfoGrid";
import { ProgressList } from "../components/ProgressList";

const Patient_And_Insurance_Keys = [
  {
    label: "Order Type",
    accessor: "orderedWorkflows",
  },
  { label: "Member ID", accessor: "insuranceInfo.primaryInsurance.memberId" },
  {
    label: "Primary Insurance",
    accessor: "insuranceInfo.primaryInsurance.insuranceName",
  },
  { label: "Name", accessor: null },
];

const Provider_Keys = [
  {
    label: "Physician NPI",
    accessor: "providerInfo.physicianNPI",
  },
  {
    label: "Physician Practice Name",
    accessor: "providerInfo.physicianPracticeName",
  },
  {
    label: "Physician Name",
    accessor: null,
  },
  {
    label: "Genetic Counselor Name",
    accessor: "providerInfo.geneticCounselorName",
  },
];

const ALL_KEYS = [...Patient_And_Insurance_Keys, ...Provider_Keys];

const InitialValue = [
  { label: "Patient & Insurance Info", content: null, status: "pending" },
  { label: "Provider & Practice Info", content: null, status: "pending" },
  { label: "Test & Request Info", content: null, status: "pending" },
];

const content = (formValues, keys) => {
  const items = mapItemsFromForm(formValues, keys);
  return (
    <Card className="bg-success bg-opacity-10 border border-0">
      <CardBody>
        <InfoGrid items={items} loaded={true} />
      </CardBody>
    </Card>
  );
};

const mapItemsFromForm = (formValues, keys) => {
  return keys.map((key) => {
    if (key.label === "Name") {
      return {
        label: `${formValues.patientInfo.firstName} ${formValues.patientInfo.lastName}`,
        content: `${formValues.patientInfo.dob}, ${formValues.patientInfo.sex}`,
        labelClassNames: "fw-bolder fs-5",
      };
    } else if (key.label === "Physician Name") {
      return { label: key.label, content: `${formValues.providerInfo.physicianFirstName}, ${formValues.providerInfo.physicianLastName}` };
    } else {
      return { label: key.label, content: _.startCase(_.get(formValues, key.accessor)) };
    }
  });
};

const getContent = (page, values) => {
  switch (page) {
    case 0:
      return content(values, Patient_And_Insurance_Keys);
    case 1:
      return content(values, Provider_Keys);
    case 2:
      return content(values);
  }
};

const buildProgressTimelineItems = (pageNames, page, values) => {
  const initialValue = pageNames.map((page) => Object.assign({ label: page }));

  for (let i = 0; i < page; i++) {
    initialValue[i].content = getContent(i, values);
    initialValue[i].status = "done";
  }

  return initialValue;
};

export const ProgressTimeline = ({ pageNames, page, formValues }) => {
  const progressTimelineItems = buildProgressTimelineItems(pageNames, page, formValues);

  return (
    <div className="mt-4 mr-4">
      <Card className="d-flex flex-row mb-3 border border-0">
        <CardBody className="h-50 border border-0">
          <ProgressList items={progressTimelineItems} />
        </CardBody>
      </Card>
    </div>
  );
};
