import cx from "classnames";
import { Check } from "lucide-react";
import { useEffect, useState } from "react";
import { Button, Input, InputGroup, ListGroupItem } from "reactstrap";
import { isBlank } from "../../util/helpers";

export const SortingRow = ({ label, description = null, idx, disabled, onIndexUpdate }) => {
  const [rowIdx, setRowIdx] = useState(idx + 1);

  useEffect(() => {
    setRowIdx(idx + 1);
  }, [idx]);

  const handleChange = (e) => {
    // Did you type in not a number? Noop
    if (_.isNaN(_.toNumber(e.target.value))) {
      return false;
    }

    const hasNewValue = !isBlank(e.target.value);
    const newValue = _.toNumber(e.target.value);

    // Allow clearing our internal state
    setRowIdx(hasNewValue ? newValue : "");
  };

  const handleBlur = () => {
    if (isBlank(rowIdx)) {
      // restore the proper index
      setRowIdx(idx + 1);
    } else {
      onIndexUpdate(rowIdx - 1, idx);
    }
  };

  const hasNewTargetIdx = rowIdx && Boolean(rowIdx - 1 !== idx);

  return (
    <ListGroupItem
      className={cx("d-flex flex-row align-items-center grabbable", {
        disabled,
      })}
    >
      <div className="border-right me-3 pr-2">
        <InputGroup style={{ width: 120 }} size="sm">
          <Input type="text" pattern="\d*" disabled={disabled} step={1} value={rowIdx} onChange={handleChange} />

          <Button
            outline={!hasNewTargetIdx}
            color={hasNewTargetIdx ? "success" : "secondary"}
            onClick={handleBlur}
            disabled={!hasNewTargetIdx}
          >
            <Check />
          </Button>
        </InputGroup>
      </div>

      <div style={{ width: 200 }}>{label}</div>

      {description && <div className="border-left ms-3">{description}</div>}
    </ListGroupItem>
  );
};
