import React, { useState } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { camelizeKeys, snakifyKeys } from "../../../util/helpers";
import { PayorAutoApprovalForm } from "./PayorAutoApprovalForm";
import { PayorPatientCriteriaForm } from "./PayorPatientCriteriaForm";

export const EditPayorAutoApprovalModal = ({ payorId, autoApproval, onCreate, ...rest }) => {
  const [openTestBundle, setOpenTestBundle] = useState(false);
  const [openPatientCriteria, setOpenPatientCriteria] = useState(false);
  const [testBundle, setTestBundle] = useState(null);
  const [error, setError] = useState(null);

  const toggleTestBundleModal = () => setOpenTestBundle(!openTestBundle);
  const togglePatientCriteriaModal = () => setOpenPatientCriteria(!openPatientCriteria);

  const handleEditTestBundle = async (values) => {
    setError(null);
    setOpenTestBundle(false);
    setTestBundle(values);
    setOpenPatientCriteria(true);
  };

  const handleEditPatientCriteria = async (values) => {
    setError(null);
    try {
      const currentCriteria = autoApproval?.patientCriteria || [];
      const formCriteria = values.patientCriteria;
      const [editCriteria, newCriteria] = _.partition(formCriteria, "id");
      const formIds = _.flatMap(formCriteria, "id");

      const criteriaToDelete = currentCriteria
        .filter((c) => {
          return !formIds.includes(c.id);
        })
        .map((criteria) => {
          return { ...criteria, _destroy: true };
        });

      const updatedCriteria = _.concat(newCriteria, editCriteria, criteriaToDelete);

      const params = {
        testBundle: {
          payorId: testBundle.payorId,
          name: testBundle.name,
          testIds: testBundle.testIds,
          patientCriteriaAttributes: snakifyKeys(updatedCriteria.map((c) => _.pickBy(c, _.identity))), // removes null values and snakify the keys
        },
      };

      const res = await api.patch(`/admin/test_bundles/${autoApproval.id}`, snakifyKeys(params));

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpenPatientCriteria(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleTestBundleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={openTestBundle} toggle={toggleTestBundleModal}>
        <ModalHeader toggle={toggleTestBundleModal}>Edit Payor Auto Approval</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={handleEditTestBundle} initialValues={autoApproval}>
            {(props) => <PayorAutoApprovalForm {...props} initialTestOptions={autoApproval.initialTestOptions} />}
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={openPatientCriteria} toggle={togglePatientCriteriaModal}>
        <ModalHeader toggle={togglePatientCriteriaModal}>Edit Payor Auto Approval</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form
            onSubmit={handleEditPatientCriteria}
            mutators={{ ...arrayMutators }}
            initialValues={{ patientCriteria: autoApproval.patientCriteria.map(camelizeKeys) }}
            keepDirtyOnReinitialize={true}
          >
            {(props) => (
              <PayorPatientCriteriaForm
                initialIcd10Options={autoApproval.patientCriteria.map((p) => p.initial_icd10_code_options)}
                {...props}
              />
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
