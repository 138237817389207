import { useState, useEffect } from "react";
import { api } from "../../util/api";
import { Field } from "react-final-form";
import { DetailedErrorAlert } from "../../components/DetailedErrorAlert";
import { SelectInput } from "./final_form/SelectInput";
import { OnChange } from "react-final-form-listeners";

// Since the list of possible statuses is network-fetched, it's technically stateful,
// either null (before loading) or a list (after loading)
// This function abstracts that effect and state into a single hook
// It returns a boolean (loaded), and a list of possible statuses
function usePossibleStatuses(caseId) {
  const [loaded, setLoaded] = useState(false);
  const [statuses, setStatuses] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      setLoaded(false);
      if (!caseId) {
        setStatuses(undefined);
        setError(new Error("Empty Case ID"));
      } else {
        try {
          setStatuses((await api.get(`/cases/${caseId.trim()}/statuses.json`)).data);
          setError(undefined);
        } catch (e) {
          setError(e);
          setStatuses(undefined);
        }
      }

      setLoaded(true);
    })();
  }, [caseId, setLoaded, setStatuses, setError]);

  return [loaded, statuses, error];
}

function createOptions(objectToMap) {
  return Object.entries(objectToMap).map(([key, value]) => ({
    label: value.name,
    value: key,
  }));
}

export function StatusSelector({
  caseId,
  onStatusChangeCallback = null,
  statusHtmlAttributes = {},
  substatusHtmlAttributes = {},
  ...rest
}) {
  const [loaded, statuses, error] = usePossibleStatuses(caseId);
  const [substatusOptions, setSubstatusOptions] = useState([]);

  let statusOptions = [];
  if (loaded && statuses) {
    statusOptions = createOptions(statuses);
  }

  const handleStatusChange = (option) => {
    setSubstatusOptions(createOptions(statuses[option].substatuses));
    onStatusChangeCallback(option);
  };

  return (
    <>
      {loaded && <DetailedErrorAlert error={error} />}

      <Field
        name={statusHtmlAttributes?.name}
        id={statusHtmlAttributes?.id}
        component={SelectInput}
        label="Status"
        options={statusOptions}
        {...rest}
      />

      <OnChange name={statusHtmlAttributes?.name}>{handleStatusChange}</OnChange>

      {!!substatusOptions.length && (
        <>
          <Field
            name={substatusHtmlAttributes?.name}
            id={substatusHtmlAttributes?.id}
            component={SelectInput}
            label="Substatus"
            options={substatusOptions}
          />
        </>
      )}
    </>
  );
}
