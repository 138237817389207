import $ from "jquery";
import _ from "lodash";
import moment from "moment-timezone";
import { createRoot } from "react-dom/client";
import { api, redirectTo } from "../../util/api";
import { InsuranceFormFiller, PayorRequiredFormFiller } from "../../workflow";
import { MemberIdInput } from "../../workflow/member_id_input";
import { featureEnabled } from "../../util/feature";

export function initPriorAuthWorkflow() {
  const insuranceForm = document.getElementById("insurance-form");

  if (insuranceForm) {
    const root = createRoot(insuranceForm);

    const {
      documentFilled,
      fillableDocuments,
      schemaUrl,
      createDocumentUrl,
      documentUrl,
      schemaPrecompiledValidatorUrl,
      requestId,
      documentId,
      formId,
    } = insuranceForm.dataset;
    const parsedFillableDocuments = JSON.parse(fillableDocuments);

    root.render(
      <InsuranceFormFiller
        documentFilled={JSON.parse(documentFilled)}
        fillableDocuments={parsedFillableDocuments}
        schemaUrl={schemaUrl}
        createDocumentUrl={createDocumentUrl}
        schemaPrecompiledValidatorUrl={schemaPrecompiledValidatorUrl}
        documentUrl={documentUrl}
        requestId={requestId}
        documentId={documentId}
        formId={formId}
      />
    );
  }

  const payorRequiredFormRoot = document.getElementById("payor-required-form");

  if (payorRequiredFormRoot) {
    const root = createRoot(payorRequiredFormRoot);

    const { payorRequiredForm, documentFilled, documentUrl, createDocumentUrl, documentId, schemaPrecompiledValidatorUrl } =
      payorRequiredFormRoot.dataset;

    const { schema, uiSchema, formData } = payorRequiredForm ? JSON.parse(payorRequiredForm) : {};

    root.render(
      <PayorRequiredFormFiller
        schema={schema}
        uiSchema={uiSchema}
        formData={formData}
        documentFilled={documentFilled === "true"}
        createDocumentUrl={createDocumentUrl}
        documentId={documentId}
        documentUrl={documentUrl}
        schemaPrecompiledValidatorUrl={schemaPrecompiledValidatorUrl}
      />
    );
  }

  // Set up miscellaneous conditional elements for the "response" page.
  const $paResponseForm = $("#response_data_entry_form");
  if ($paResponseForm.length > 0) {
    initStatusDropdownEffects($paResponseForm);

    const $submittedDropdown = $(".request_submitted");

    const $submissionDateSelector = $("#submission_date_selector", $paResponseForm);
    const $submissionReferenceNumber = $(".submission_reference_number", $paResponseForm).parent();

    $submittedDropdown.on("change", () => {
      const submitted = $submittedDropdown.val() === "Y";

      if (submitted) {
        $submissionDateSelector.show();
        $submissionReferenceNumber.show();
      } else {
        $submissionDateSelector.hide();
        $submissionReferenceNumber.hide();
      }
    });

    $submittedDropdown.trigger("change");

    $paResponseForm.submit(() => {
      const submittedVal = $(".request_submitted")[0].value;
      const submitted = submittedVal && submittedVal.toUpperCase() === "Y";
      const $submissionStatuses = $(".prior_auth_request_submission_records_submission_status");

      const anyOpenSubmissions =
        $submissionStatuses.length > 0 &&
        _.some($submissionStatuses, (v, k, _col) => {
          const subVal = v.children[1].value;
          // "" is falsey, but we want to consider "unset" submissions as "open". Thus, "not null or undef"
          return subVal != null && subVal.toLowerCase() !== "closed";
        });
      if (anyOpenSubmissions && !submitted) {
        return confirm("Setting Submitted to 'No' will close any open submission records. Are you sure you wish to proceed?");
      }
      return true;
    });
  }

  const $selectPayorForm = $("#select_payor_form");
  if ($selectPayorForm.length > 0) {
    const $verifiedTestInput = $(".verified_tests");

    setupTestWindowEvents($verifiedTestInput);

    setupPayorFormVerification($selectPayorForm);

    $selectPayorForm.on("ajax:success", () => {
      redirectTo("/workflow/" + $selectPayorForm.data("caseId"));
    });
  }
}

function setupPayorFormVerification($selectPayorForm) {
  if (!featureEnabled("payor_test_data_validation")) {
    return;
  }

  const $dobField = $selectPayorForm.find(".case_request_patient_dob");
  const $dobInput = $dobField.find("input");
  const fp = $dobInput.get(0)._flatpickr;

  const minDate = moment().subtract(100, "years").toDate();
  const maxDate = moment().toDate();

  const $memberIdField = $selectPayorForm.find(".case_primary_request_insurance_memberid");
  const $memberIdInput = $memberIdField.find("input");
  // Render the react component into $memberIdFeild
  const root = createRoot($memberIdField.get(0));
  root.render(<MemberIdInput selectPayorForm={$selectPayorForm.get(0)} initialMemberId={$memberIdInput.val()} />);

  const $saveButton = $selectPayorForm.find("input[type=submit]");

  function verifyData() {
    const currentDate = fp.selectedDates[0];
    const dateValid = minDate < currentDate && currentDate < maxDate;

    if (dateValid) {
      // Mark it as valid
      $dobInput.removeClass("is-invalid");
      // Enable the save button
      $selectPayorForm.find("input[type=submit]").removeClass("disabled");
    } else {
      // Mark it as invalid
      $dobInput.addClass("is-invalid");
      // Disable the save button
      $selectPayorForm.find("input[type=submit]").addClass("disabled");
    }

    // $memberIdInput is controlled by React at this point
    const $memberIdInput = $("#case_primary_request_insurance_attributes_memberid");
    const memberIdValid = !$memberIdInput.hasClass("is-invalid");

    const formValid = memberIdValid && dateValid;

    if (formValid) {
      $saveButton.removeClass("disabled");
    } else {
      $saveButton.addClass("disabled");
    }
  }

  verifyData();
  $dobInput.on("change", verifyData);

  // Add an event listener to the form
  $selectPayorForm.on("member_id-changed", verifyData);
}

function setupTestWindowEvents($verifiedTestInput) {
  const map = $verifiedTestInput.data("cptMap");

  $verifiedTestInput.on("change", () => {
    const inputId = $verifiedTestInput.attr("id");
    const $parentForm = $verifiedTestInput.closest("form");
    let testValues = $verifiedTestInput.val();

    if (!Array.isArray(testValues)) {
      testValues = [testValues];
    }

    testValues.forEach((testValue) => {
      const testId = parseInt(testValue);
      const codes = map[testId];

      if (codes !== undefined) {
        if ($parentForm.length > 0) {
          const codeChangeEvent = new CustomEvent("verified_test:change_codes", { detail: { inputId, codes } });
          $parentForm.get(0).dispatchEvent(codeChangeEvent);
        }
      }
    });
  });
}

// I think this has officially become nasty enough that it should really just be a react component
// That said, it won't be happening this week.
function initStatusDropdownEffects($paResponseForm) {
  const $statusDropdown = $(".request_status", $paResponseForm);
  const $substatusDropdown = $(".request_substatus", $paResponseForm);

  const $denialReasonDropdown = $(".denial_reason_dropdown", $paResponseForm);
  const $thirdPartyStatusContainer = $(".prior_auth_request_third_party_status", $paResponseForm);
  const $thirdPartyStatusDropdown = $(".third_party_status_dropdown", $paResponseForm);
  const $authorizationInputs = $(".authorization_input", $paResponseForm).parent();
  const $approvedCPTCodes = $(".approved_cpt_codes", $paResponseForm).parent();

  $statusDropdown.on("change", async () => {
    const currentStatus = $statusDropdown.val();
    const currentSubstatus = $substatusDropdown.val();

    const showDenialReason = currentStatus === "denied" || currentStatus === "partially_approved";
    if (showDenialReason) {
      $denialReasonDropdown.show();
    } else {
      $denialReasonDropdown.hide();
    }

    const showPrevExtStatus = currentStatus === "rejected" && currentSubstatus === "result_unavailable_submitted_by_other_party";
    if (showPrevExtStatus) {
      $thirdPartyStatusContainer.show();
    } else {
      $thirdPartyStatusContainer.hide();
    }

    if (currentStatus === "partially_approved") {
      $approvedCPTCodes.show();
      $authorizationInputs.show();
    } else {
      $approvedCPTCodes.hide();

      // authorization_* can be shown for approved or partial approved
      if (currentStatus === "approved") {
        $authorizationInputs.show();
      } else {
        $authorizationInputs.hide();
      }
    }

    const validation = await validateNewStatus(currentStatus, $paResponseForm);
    if (validation.valid !== true) {
      addErrorMessageAfter($statusDropdown, validation.message.join(" "));
    } else {
      clearErrorMessageAfter($statusDropdown);
    }
  });

  $substatusDropdown.on("change", () => {
    const currentStatus = $statusDropdown.val();
    const currentSubstatus = $substatusDropdown.val();

    const showPrevExtStatus = currentStatus === "rejected" && currentSubstatus === "result_unavailable_submitted_by_other_party";
    if (showPrevExtStatus) {
      $thirdPartyStatusContainer.show();
    } else {
      $thirdPartyStatusContainer.hide();
    }
  });

  // "previous external status" counts as "status" for the purposes of showing subfields
  $thirdPartyStatusDropdown.on("change", () => {
    // The nasty `slice` here removes "_3p" from the value
    // (required by the Rails enum to avoid conflicts with the primary status enum)
    const currentStatus = $thirdPartyStatusDropdown.val().slice(0, -3);

    const showDenialReason = currentStatus === "denied" || currentStatus === "partially_approved";
    if (showDenialReason) {
      $denialReasonDropdown.show();
    } else {
      $denialReasonDropdown.hide();
    }

    if (currentStatus === "partially_approved") {
      $approvedCPTCodes.show();
      $authorizationInputs.show();
    } else {
      $approvedCPTCodes.hide();

      // authorization_* can be shown for approved or partial approved
      if (currentStatus === "approved") {
        $authorizationInputs.show();
      } else {
        $authorizationInputs.hide();
      }
    }
  });

  $statusDropdown.trigger("change");
}

const validateNewStatus = async (newStatus, $form) => {
  const caseId = $form.data("caseId");

  if (caseId && newStatus) {
    const { data } = await api.get(`/workflow/prior_auth/${caseId}/may_transition_status?new_status=${newStatus}`);
    return {
      valid: data.may_transition,
      message: data.may_transition ? undefined : data.errors,
    };
  }
};

const addErrorMessageAfter = ($el, message) => {
  const $message = $("<span/>", {
    class: "form-text text-warning",
    text: message,
  });

  clearErrorMessageAfter($el);

  $el.addClass("is-warning").removeClass("is-valid");
  $el.after($message);
};

const clearErrorMessageAfter = ($el) => {
  $el.addClass("is-valid").removeClass("is-warning");
  $el.next(".form-text.text-warning").remove();
};
