import React, { useState, useCallback } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { api, extractErrorMessage } from "../../../util/api";
import { required, numericInRange } from "../../../util/validators";
import { snakifyKeys } from "../../../util/helpers";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { Fieldset } from "../../../components/inputs/final_form/Fieldset";

export const AddPortalModal = ({ onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = useCallback(
    async (values) => {
      setError(null);
      try {
        await api.post("/admin/benefit_management_portals", { benefit_management_portal: snakifyKeys(values) });

        onAdd?.(values);

        setOpen(false);
      } catch (err) {
        setError(extractErrorMessage(err));
      }
    },
    [onAdd]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Portal
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Portal</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate}>
            {({ handleSubmit, submitting, pristine }) => {
              return (
                <>
                  <Field component={TextInput} required validate={required} label="Name" name="name" />
                  <Field component={TextInput} label="URL" name="url" />

                  <Field component={TextInput} label="Phone" name="phone" />
                  <Field component={TextInput} label="Fax" name="faxNumber" />
                  <Field component={TextInput} label="SOP URL" name="sopUrl" />

                  <Fieldset legend="Portal Settings">
                    <Field
                      component={TextInput}
                      label="Follow-up Days"
                      validate={numericInRange(0, 90)}
                      name="followupDays"
                      type="number"
                      min="0"
                      max="90"
                    />

                    <Field
                      type="checkbox"
                      component={Switch}
                      label="Enrollment Required?"
                      name="enrollmentRequired"
                      id="enrollmentRequired"
                    />
                    <Field type="checkbox" component={Switch} label="Post-Service Review" name="postServiceReview" id="postServiceReview" />
                  </Fieldset>

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine}>
                    Save
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
