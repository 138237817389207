import React, { Component } from "react";

export class LabSettingsStatusTranslations extends Component {
  componentWillMount() {
    this.setState({ value: this.props.defaultValue });
  }

  render() {
    return (
      <React.Fragment>
        <input
          type="hidden"
          value={this.jsonValidValues()}
          name="lab_setting[status_translations]"
          id="lab_setting_status_translations"
        ></input>
        <RowHeader />
        {this.state.value &&
          this.state.value.map((row, i) => (
            <TranslationRow row={row} key={i} onRemove={() => this.onRemove(i)} onChange={(e) => this.onChange(i, e)} />
          ))}
        <AddButton onAdd={this.onAdd} />
      </React.Fragment>
    );
  }

  jsonValidValues = () => {
    const valid = this.state.value.filter((v) => {
      return (v.status || v.substatus) && v.result_status;
    });

    return JSON.stringify(valid);
  };

  onAdd = () => {
    const val = this.state.value;
    val.push({ status: "", result_status: "" });
    this.setState({ value: val });
  };

  onRemove = (idx) => {
    const val = this.state.value;
    val.splice(idx, 1);
    this.setState({ value: val });
  };

  onChange = (idx, event) => {
    const val = this.state.value;
    const row = val[idx];
    row[event.target.name] = event.target.value;
    this.setState({ value: val });
  };
}

const RowHeader = () => {
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <p
        style={{
          width: "33%",
          marginBottom: "0",
          fontWeight: "500",
          textAlign: "center",
        }}
      >
        Status
      </p>
      <p
        style={{
          width: "33%",
          marginBottom: "0",
          fontWeight: "500",
          textAlign: "center",
        }}
      >
        Substatus
      </p>
      <p
        style={{
          width: "34%",
          marginBottom: "0",
          fontWeight: "500",
          textAlign: "center",
        }}
      >
        Result Status
      </p>
    </div>
  );
};

const AddButton = ({ onAdd }) => {
  return (
    <button type="button" className="btn btn-info mb-5" onClick={onAdd}>
      Add New Translation
    </button>
  );
};

class TranslationRow extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "1em",
          marginTop: "1em",
        }}
      >
        <input type="text" value={this.props.row.status} name="status" onChange={this.props.onChange} />
        <input type="text" value={this.props.row.substatus} name="substatus" onChange={this.props.onChange} />
        <input type="text" value={this.props.row.result_status} name="result_status" onChange={this.props.onChange} />

        <button className="btn btn-danger btn-small" type="button" onClick={this.props.onRemove}>
          Remove
        </button>
      </div>
    );
  }
}
