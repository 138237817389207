import { useMemo, useRef, useState } from "react";
import { CountCell } from "../../../components/grid/cells/CountCell";
import { Switch } from "../../../components/inputs/Switch";
import { Toolbar } from "../../../components/Toolbar";
import { deepCompact, snakifyKeys } from "../../../util/helpers";
import { encodeFilterParams } from "../../../util/table";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { DESTROY, UPDATE, useCreatePermission } from "../../utils";
import { CaseTypeCptsActionCell } from "../components/CaseTypeCptsActionCell.js";
import { CreateCaseTypeCptMappingModal } from "../components/CreateCaseTypeCptMappingModal";
import { ImportCaseTypeCptMappingModal } from "../components/ImportCaseTypeCptMappingModal";

const useColumns = () =>
  useMemo(
    () => [
      {
        id: "test.commercialName",
        accessor: "test.commercialName",
        Header: "Test Name",
      },
      {
        id: "test.testIdentifier",
        accessor: "test.testIdentifier",
        Header: "Test Code",
      },
      {
        id: "payor.name",
        accessor: "payor.name",
        Header: "Payor",
        disableSortBy: true,
        Filter: (props) => (
          <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="name" labelKey="name" searchParam="name" />
        ),
      },
      {
        id: "caseType",
        accessor: (x) => {
          switch (x.caseType) {
            case "type_prior_auth":
              return "Prior Auth";
            case "type_benefits_investigation":
              return "Benefits Investigation";
            default:
              return x;
          }
        },
        Header: "Case Type",
      },
      {
        id: "benefit_management_portal",
        accessor: "benefitManagementPortal.name",
        Header: "Benefit Management Portal",
        Filter: (props) => (
          <AdministrateEnumFilter
            {...props}
            resourceUrl="/admin/benefit_management_portals.json"
            valueKey="name"
            labelKey="name"
            searchParam="name"
          />
        ),
      },
      {
        id: "plan_type_group",
        accessor: "planTypeGroupLabel",
        Header: "Plan Type",
        Filter: (props) => (
          <AdministrateEnumFilter
            {...props}
            resourceUrl={`/admin/labs/plan_type_groups.json`}
            valueKey="value"
            labelKey="label"
            searchParam="plan_type_group"
          />
        ),
      },
      {
        id: "cpt_codes",
        accessor: "cptCount",
        Header: "CPT Codes",
        Cell: ({ value }) => <CountCell count={value} label="CPT Codes" />,
        Filter: (props) => (
          <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" isMulti valueKey="code" labelKey="code" />
        ),
      },
      {
        id: "aliases",
        Header: "Actions",
        disableFilters: true,
        disableSortBy: true,
        Cell: CaseTypeCptsActionCell,
      },
    ],
    []
  );

export const CaseTypeCptsTab = ({ labId }) => {
  const tableRef = useRef();
  const columns = useColumns();
  const [showArchivedTests, setShowArchivedTests] = useState(false);
  const [tableFilters, setTableFilters] = useState({});
  const [canCreate] = useCreatePermission("CaseTypeTestMapping");

  const extraParams = useMemo(() => deepCompact({ test: { labId }, archived: showArchivedTests || undefined }), [labId, showArchivedTests]);

  const allFilters = useMemo(
    () => ({
      ...snakifyKeys(extraParams),
      ...encodeFilterParams(tableFilters),
    }),
    [extraParams, tableFilters]
  );

  const toggleArchivedTestFilter = () => setShowArchivedTests((x) => !x);

  const onCreate = (newCaseTypeMapping) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created Case Type Mapping for test ${newCaseTypeMapping.test.commercial_name}`);
    refresh();
  };

  const onImportCaseTypeMapping = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar>
          <Switch
            id="archived-tests-toggle"
            label="Include Archived Tests"
            checked={showArchivedTests === true}
            onChange={toggleArchivedTestFilter}
          />
        </Toolbar>

        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/case_type_test_mappings/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreate && (
            <>
              <ImportCaseTypeCptMappingModal outline className="me-2" onImport={onImportCaseTypeMapping} labId={labId} />
              <CreateCaseTypeCptMappingModal color="primary" labId={labId} onCreate={onCreate} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/case_type_test_mappings.json"}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
        resourceName={"test"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
