import { Trash, Undo } from "lucide-react";
import { useState } from "react";
import { LoadingButton } from "../components/LoadingButton";
import { api } from "../util/api";

export const ArchiveButton = ({ id, archived, ...props }) => {
  const [loading, setLoading] = useState(false);
  const url = `/cases/${id}/toggle_archived`;
  const icon = !!archived ? <Undo /> : <Trash />;
  const color = !!archived ? "warning" : "danger";

  return (
    <LoadingButton
      outline
      className="btn-icon"
      onClick={async (e) => {
        // TODO: something about this trigger causes a warning about updating an unmounted component, but we reload the page anyway so :shrug:
        e.preventDefault();
        setLoading(true);

        try {
          await api.post(url);
          window.location.reload();
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }}
      type="button"
      size="sm"
      color={color}
      loading={loading}
      {...props}
    >
      {icon}
    </LoadingButton>
  );
};
