import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { Form } from "react-final-form";
import { SubmissionAttemptForm } from "./SubmissionAttemptForm";
import { api, extractErrorMessage } from "../util/api";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { trackEvent } from "../util/track";

export const RecordSubmissionAttemptModal = ({
  cannedResponses,
  kasePublicId,
  modalProps,
  open,
  portalId,
  readonlyStatus,
  remainingPortals,
  selectedStatus,
  selectedSubstatus,
  denialReasonCodeOptions,
  skipNoHcpEnrollment,
  submissionType,
  submitPath,
  submitting,
  toggleModal,
  initialValues = {},
}) => {
  const [error, setError] = useState(null);

  const handleSubmit = async (params) => {
    try {
      await api.post(submitPath, params);

      await trackEvent("phone_submit", kasePublicId, {
        step: "phone_submit",
      });

      window.location.reload();
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Modal isOpen={open} toggle={toggleModal} {...modalProps}>
        <ModalHeader toggle={toggleModal}>Record Submission Attempt</ModalHeader>
        <ModalBody>
          <DetailedErrorAlert error={error} />
          <div>
            <p style={{ color: "red" }}>
              Mark <b>Yes</b> if you were able to find the patient and submit the case to a Portal
              <em>
                (Statuses from a successful portal submission may show as being&#58; in medical review, approved, denied, partially
                approved, submitted, not required, or rejected)
              </em>
            </p>
            <p style={{ color: "red" }}>
              Mark <b>NO</b> if you were NOT able to find the patient and submit the case to a Portal, and if you need to continue with a
              different submission method
            </p>
          </div>

          <Form onSubmit={handleSubmit} initialValues={initialValues}>
            {(props) => (
              <SubmissionAttemptForm
                cannedResponses={cannedResponses}
                kasePublicId={kasePublicId}
                portalId={portalId}
                readonlyStatus={readonlyStatus}
                remainingPortals={remainingPortals}
                selectedStatus={selectedStatus}
                selectedSubstatus={selectedSubstatus}
                denialReasonCodeOptions={denialReasonCodeOptions}
                skipNoHcpEnrollment={skipNoHcpEnrollment}
                submissionType={submissionType}
                submitPath={submitPath}
                submitting={submitting}
                {...props}
              />
            )}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export const RecordSubmissionAttemptButton = ({ disabled, buttonLabel = "Record Attempt", open, ...props }) => {
  const [modalOpen, setModalOpen] = useState(open);
  const toggleModal = () => setModalOpen(!modalOpen);

  let button;
  if (props.addRecord === "true") {
    button = (
      <Button color="secondary" outline onClick={toggleModal} disabled={disabled}>
        + Add Record
      </Button>
    );
  } else {
    button = (
      <Button color="primary" onClick={toggleModal} disabled={disabled}>
        {buttonLabel}
      </Button>
    );
  }

  return (
    <>
      {button}
      <RecordSubmissionAttemptModal {...props} open={modalOpen} toggleModal={toggleModal} />
    </>
  );
};
