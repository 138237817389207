import pluralize from "pluralize";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { Toolbar } from "../../../components/Toolbar";
import { extractErrorMessage } from "../../../util/api";
import { AdministrateExportButton } from "../../components/AdministrateExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { DESTROY, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreatePolicyCoverageModal } from "../components/CreatePolicyCoverageModal";
import { ImportCodesModal } from "../components/ImportCodesModal";
import { PolicyCoverageActionCell } from "../components/PolicyCoverageActionCell";

const useColumns = makeColumns(() => [
  {
    id: "cpt_code.code",
    accessor: "cptCode.code",
    Header: "CPT Code",
    Cell: TokenCell,
  },
  {
    id: "cpt_code.description",
    accessor: "cptCode.description",
    Header: "CPT Code Description",
  },
  {
    id: "icd10_code.code",
    accessor: "icd10Code.code",
    Header: "ICD10 Code",
    Cell: TokenCell,
  },
  {
    id: "icd10_code.description",
    accessor: "icd10Code.description",
    Header: "ICD10 Code Description",
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: PolicyCoverageActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

const getCreateMessage = ({ failures, successes }) => {
  if (failures.length === 0) {
    return `Created ${pluralize("Policy Coverages", successes.length, true)} `;
  }

  if (successes.length === 0) {
    return "Failed to create coverages: All CPT codes already taken.";
  }

  const errorMessages = failures.map((f) => extractErrorMessage(f.reason)).join("; ");
  const successLabel = pluralize("Policy Coverages", successes.length, true);
  const errorLabel = pluralize("errors", failures.length, true);

  return `Created ${successLabel}, and encountered ${errorLabel}. Errors: ${errorMessages}.`;
};

export const PoliciesCodesTab = ({ policy, policyId }) => {
  const [canCreate] = useCreatePermission("PolicyCoverage");

  const columns = useColumns();
  const extraParams = { policy_id: policyId };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onCreate = (results) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(getCreateMessage(results));
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateExportButton outline className="me-2" exportUrl="/admin/policy_coverages.csv" filters={allFilters} />
          {canCreate && <ImportCodesModal outline className="me-2" onImport={onImport} policyId={policyId} />}
          {canCreate && <CreatePolicyCoverageModal color="primary" policy={policy} onCreate={onCreate} />}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/policy_coverages.json"}
        extraParams={extraParams}
        permission={[DESTROY]}
        resourceName={"policy_coverage"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
