import { Copy } from "lucide-react";
import React from "react";

import { Card, Table, CardBody } from "reactstrap";

const CardEntry = ({ title, content, isToken = false }) => (
  <>
    <th scope="rowgroup">{title}</th>
    <td>
      {isToken ? (
        <>
          <span className="token">{content}</span>
          <a className="copy text-muted btn-copy d-print-none" href="#" data-clipboard-text={content}>
            <Copy className="copy-icon" size={12} />
          </a>
        </>
      ) : (
        content
      )}
    </td>
  </>
);

const DetailCard = ({ clinicData, loaded }) => {
  if (!loaded) {
    return (
      <Card>
        <CardBody>Loading...</CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <Table borderless size="sm">
          <tbody>
            <tr>
              <CardEntry title="Account Number" content={clinicData.clinic_internal_id} isToken />
              <CardEntry title="Number of Providers" content={clinicData.provider_count || 0} />
            </tr>

            <tr>
              <CardEntry title="NPI" content={clinicData.npi} isToken />
              <CardEntry title="Enrolled Date" content={new Date(clinicData.created_at).toLocaleDateString()} />
            </tr>

            <tr>
              <CardEntry title="Tax ID" content={clinicData.tax_id} isToken />
              <CardEntry title="Institution" content={clinicData.institution ? "Yes" : "No"} />
            </tr>

            <tr>
              <CardEntry title="Phone" content={clinicData.contact_phone_number} />
              <CardEntry title="" content="" />
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default DetailCard;
