import { Col } from "reactstrap";
import { FormRow } from "../../components/forms/FormRow";
import { Field } from "react-final-form";
import { SelectInput } from "../../components/inputs/final_form/SelectInput";
import { CollectionTypeSelector } from "../../components/inputs/CollectionTypeSelector";
import { composeValidators, required, requiredNotNull } from "../../util/validators";
import _ from "lodash";
import { useState } from "react";
import { SerializedCptSelector } from "../../components/inputs/final_form/CptSelector";
import { SerializedIcd10Selector } from "../../components/inputs/final_form/Icd10Selector";
import { OnChange } from "react-final-form-listeners";
import { useMappings, mapMappingsToNameOptions } from "../../new_case/TestAndRequestInfoHelperMethods";
import { Switch } from "../../components/inputs/final_form/Switch";
import { DateInput } from "../../components/inputs/final_form/DateInput";
import { SerializedLabSelector } from "../../components/inputs/final_form/LabSelector";

export const OrderInfo = ({ form, values, errors }) => {
  const { mappings, loadingMappings } = useMappings(values.labId, values.orderedWorkflows, values.allTests);
  const [testNameFocused, setTestNameFocused] = useState(false);
  const [autoMapCpts, setAutoMapCpts] = useState(null);
  const [selectedTestNames, setSelectedTestNames] = useState([]);
  const [cptCodeFieldTouched, setCptCodeFieldTouched] = useState(false);

  const testNameChangeHandler = async (value, previous) => {
    if (testNameFocused) {
      const selectedNameMappingIds = setMappingsSelectedTestNames(value, previous);
      const selectedNamesMappings = _.map(selectedNameMappingIds, (id) => _.find(mappings, (m) => m.id === id));

      if (!values.testInfo.cptCodes || autoMapCpts) {
        const cptCodes = _.map(selectedNamesMappings, (m) => m?.cpt_codes).join(",");
        const previousCptCodes = autoMapCpts;

        setAutoMapCpts(cptCodes);

        if (!cptCodes.includes(previousCptCodes) && previousCptCodes) {
          let updatedCptCodes = previousCptCodes.replace(cptCodes, "");
          updatedCptCodes = values.testInfo.cptCodes?.replace(updatedCptCodes, "") || "";

          form.mutators.setCptCodesMutator(updatedCptCodes);
        } else if (!cptCodeFieldTouched) {
          form.mutators.setCptCodesMutator(cptCodes);
        }
      }
    }
  };

  const testNameFocusHandler = () => {
    setTestNameFocused(true);
  };

  const setMappingsSelectedTestNames = (value, previous) => {
    let selectedTestNameMappings = selectedTestNames;

    if (_.difference(value, previous).length) {
      selectedTestNameMappings = selectedTestNameMappings.concat(_.difference(value, previous));
    } else if (_.difference(previous, value).length) {
      _.remove(selectedTestNameMappings, (mapping) => _.difference(previous, value).includes(mapping));
    }

    setSelectedTestNames(selectedTestNameMappings);

    return selectedTestNameMappings;
  };

  const cptCodesChangeHandler = (value, previous) => {
    if (value.length < previous.length) {
      const removedCptCode = _.difference(previous?.split(","), value?.split(","))[0];
      let autoMappedCpts = autoMapCpts;

      if (autoMappedCpts && autoMappedCpts?.includes(removedCptCode)) {
        autoMappedCpts = autoMappedCpts?.replace(removedCptCode, "");
        autoMappedCpts = autoMappedCpts?.replace(",,", ",");
        setAutoMapCpts(autoMappedCpts);
      }
    }

    if (form.getState().active === "testInfo.cptCodes") {
      setCptCodeFieldTouched(true);
    }

    if (!value) {
      setCptCodeFieldTouched(false);
    }
  };

  return (
    <>
      <div className="mb-2">
        <h5 className="fw-bold text-dark">Test Info</h5>
      </div>
      <FormRow>
        <Col xs="12">
          <Field
            required
            name="labId"
            validate={composeValidators(requiredNotNull)}
            render={SerializedLabSelector}
            placeholder="Choose a Lab..."
            label="Lab"
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col>
          <Field id="allTests" component={Switch} label="Show all tests" name="allTests" type="checkbox" />
        </Col>
      </FormRow>

      <FormRow>
        <Col>
          <Field
            required
            name="testInfo.verifiedTestIds"
            label="Test Name"
            isMulti
            disabled={loadingMappings}
            validate={composeValidators(required)}
            component={(props) => <SelectInput options={mapMappingsToNameOptions(mappings)} {...props} />}
            onFocus={testNameFocusHandler}
          />

          <OnChange name="testInfo.verifiedTestIds">{testNameChangeHandler}</OnChange>
        </Col>
      </FormRow>

      <FormRow>
        <Col>
          <Field
            required
            name="testInfo.cptCodes"
            label="CPT Codes"
            component={SerializedCptSelector}
            validate={composeValidators(required)}
          />

          <OnChange name="testInfo.cptCodes">{cptCodesChangeHandler}</OnChange>
        </Col>
        <Col>
          <Field
            required
            name="testInfo.icd10Codes"
            label="ICD 10 Codes"
            component={SerializedIcd10Selector}
            validate={composeValidators(required)}
          />
        </Col>
      </FormRow>

      <div className="my-2">
        <h5 className="fw-bold text-dark">Collection Info</h5>
      </div>

      <FormRow>
        <Col>
          <Field
            required
            name="testInfo.collectionType"
            label="Collection Type"
            component={(props) => <CollectionTypeSelector {...props} />}
            validate={composeValidators(required)}
          />
        </Col>
        <Col>
          <Field name="testInfo.collectionDate" label="Collection Date" render={DateInput} />
        </Col>
      </FormRow>
    </>
  );
};
