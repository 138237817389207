import React from "react";
import { YesNoPanel } from "../../../components/wizards/YesNoPanel";
import { formatDate } from "../../../util/formatters";
import { WizardContext } from "../WizardContext";
import { ActionAlert } from "../../../components/ActionAlert";
import { Button } from "reactstrap";

export const CheckPortalScreen = ({ inMedicalReview }) => (
  <WizardContext.Consumer>
    {({ benefitsPortal, successfulAttempt, submitUrl, dispatch }) => (
      <>
        {successfulAttempt ? (
          <YesNoPanel
            yesAction={() => {
              dispatch({
                type: "setAnswer",
                key: "readyToUpdate",
                value: true,
                doTransition: true,
                inMedicalReview: inMedicalReview,
              });
            }}
            noAction={() => {
              dispatch({
                type: "setAnswer",
                key: "readyToUpdate",
                value: false,
                doTransition: true,
                inMedicalReview: inMedicalReview,
              });
            }}
            yesLabel="Ready to Update"
            noLabel="No Change"
          >
            <h4>
              Check the Status in{" "}
              <a href={benefitsPortal.url} target="_blank" rel="noopener noreferrer">
                {benefitsPortal.name}
              </a>
            </h4>
            <h5 className="mb-5">
              Successfully Submitted <strong>{formatDate(successfulAttempt.created_at)}</strong>
            </h5>
          </YesNoPanel>
        ) : (
          <ActionAlert
            color="danger"
            actions={
              <Button href={submitUrl} color="danger">
                Ok
              </Button>
            }
          >
            <strong>Uh oh...</strong> It looks like this case has not been submitted to a portal. Click continue to return to the submission
            step.
          </ActionAlert>
        )}
      </>
    )}
  </WizardContext.Consumer>
);
