import { Pencil } from "lucide-react";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Flatpickr from "react-flatpickr";

export const RangePicker = forwardRef(({ onChange, value, range, ...props }, parentRef) => {
  const ref = useRef();
  const enabledRange = range[0] && range[1] ? { from: range[0], to: range[1] } : null;

  const handleClick = () => {
    if (ref.current && ref.current.flatpickr) {
      ref.current.flatpickr.open();
    }
  };

  useImperativeHandle(parentRef, () => {
    return ref.current;
  });

  return (
    <button className="d-flex flex-row align-items-center btn btn-datepicker" onClick={handleClick}>
      <Flatpickr
        ref={ref}
        value={value}
        placeholder="Select a date range"
        options={{
          mode: "range",
          altInput: true,
          altFormat: "m/d/Y",
          dateFormat: "Y-m-d",
          enable: [enabledRange],
        }}
        onChange={onChange}
        {...props}
      />

      <Pencil />
    </button>
  );
});

RangePicker.displayName = "RangePicker";
