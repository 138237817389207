import React, { useCallback } from "react";
import { Button } from "reactstrap";
import { stringifyParams } from "../../util/api";

export const AdministrateExportButton = ({ exportUrl, filters, ...rest }) => {
  const getExportUrl = useCallback(() => {
    const params = { ...filters, page: 1, per: 1000 };
    const paramsString = stringifyParams(params);
    return exportUrl.indexOf("?") > -1 ? `${exportUrl}&${paramsString}` : `${exportUrl}?${paramsString}`;
  }, [exportUrl, filters]);

  return (
    <Button onClick={() => window.open(getExportUrl())} download {...rest}>
      Export
    </Button>
  );
};
