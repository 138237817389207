import _ from "lodash";
import React, { useCallback, useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../components/DetailedErrorAlert";
import { DropzoneInput } from "../../components/inputs/final_form/DropzoneInput";
import { StyledSelect } from "../../components/inputs/StyledSelect";
import { LoadingButton } from "../../components/LoadingButton";
import { required } from "../../util/validators";

export const ImportModal = ({
  onImport,
  buttonLabel = "Import",
  title = "Import",
  initialValues = {},
  exampleDownloadPath,
  children,
  fileInputProps = {},
  buttonProps = {},
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = useCallback(() => setOpen(!open), [open, setOpen]);

  const onSubmit = useCallback(
    async (values) => {
      setError(null);
      try {
        await onImport(values);
        setOpen(false);
        setError(null);
      } catch (err) {
        setError(err);
      }
    },
    [onImport, setOpen, setError]
  );

  return (
    <>
      <Button onClick={toggleModal} {...buttonProps}>
        {buttonLabel}
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={onSubmit} initialValues={initialValues}>
            {(formProps) => {
              const { handleSubmit, submitting } = formProps;
              return (
                <>
                  {_.isFunction(children) ? children(formProps) : children}

                  <Field
                    required
                    validate={required}
                    component={DropzoneInput}
                    accept=".csv"
                    hint="Drop CSV to Import"
                    label="Import File"
                    name="file"
                    {...fileInputProps}
                  />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting} className="me-2">
                    Import
                  </LoadingButton>

                  {exampleDownloadPath && (
                    <Button href={exampleDownloadPath} download target="_blank" rel="noopener noreferrer" color="link">
                      Download Example CSV
                    </Button>
                  )}
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
