import React, { useState } from "react";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { useTableContext } from "../../components/AdministrateTable";

export const PolicyPayorActionCell = ({ policyId, ...row }) => {
  const [removing, setRemoving] = useState(false);
  const { refresh, setMessage, setError } = useTableContext();

  const handleRemovePayor = async () => {
    setRemoving(true);
    try {
      const { data } = await api.delete(`/admin/policies/${policyId}/payors`, { params: { payor_ids: [row.original.id] } });
      refresh();
      setMessage(data.meta.message);
    } catch (err) {
      setError(extractErrorMessage(err));
    } finally {
      setRemoving(false);
    }
  };

  return (
    <LoadingButton loading={removing} color="link" className="text-danger" onClick={handleRemovePayor}>
      Remove
    </LoadingButton>
  );
};
