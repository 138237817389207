import _ from "lodash";
import React, { useState, useMemo } from "react";
import { Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { parseCurrency } from "../../../util/formatters";
import { FeeScheduleByPlanTypeForm } from "./FeeScheduleByPlanTypeForm";

export const EditFeeScheduleByPlanTypeModal = ({ feeSchedule, onEdit, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (values) => {
    const newValues = _.cloneDeep(values);

    setError(null);
    try {
      if (values.priceCents) {
        newValues.priceCents = parseCurrency(newValues.priceCents) * 100;
      }

      const res = await api.patch(`/admin/cpt_fee_schedule_by_plan_types/${feeSchedule.id}`, {
        cpt_fee_schedule_by_plan_type: snakifyKeys(newValues),
      });

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const initialValues = {
    ...feeSchedule,
    priceCents: feeSchedule.priceCents / 100,
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Cpt Fee Schedule By Plan Type</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={handleEdit} initialValues={initialValues}>
            {(props) => <FeeScheduleByPlanTypeForm {...props} create={false} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
