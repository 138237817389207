import React from "react";
import { Card, CardBody } from "reactstrap";
import { TrinaryTree } from "../../../components/wizards/TrinaryTree";
import { YesNoToggle } from "../../../components/wizards/YesNoToggle";
import { WizardContext } from "../WizardContext";
import { HasNotReceivedSubmissionBranch } from "./branches/HasNotReceivedSubmissionBranch";
import { HasReceivedSubmissionBranch } from "./branches/HasReceivedSubmissionBranch";

export const PayorConversationScreen = () => {
  return (
    <WizardContext.Consumer>
      {({ state, dispatch }) => (
        <>
          <h5 className="mb-3">Ask the following questions:</h5>
          <Card>
            <CardBody>
              <YesNoToggle
                label="Has Payor received the submission?"
                id="payorHasReceivedSubmission"
                value={state.answers.payorHasReceivedSubmission}
                onChange={(v) => {
                  dispatch({
                    type: "setAnswer",
                    key: "payorHasReceivedSubmission",
                    value: v,
                    doTransition: false,
                  });
                }}
              />

              <TrinaryTree
                branch={state.answers.payorHasReceivedSubmission}
                falseBranch={<HasNotReceivedSubmissionBranch />}
                trueBranch={<HasReceivedSubmissionBranch />}
              />
            </CardBody>
          </Card>
        </>
      )}
    </WizardContext.Consumer>
  );
};
