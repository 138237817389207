import _ from "lodash";
import { Info } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { Table, UncontrolledPopover } from "reactstrap";
import { StyledSelect as Select } from "../../components/inputs/StyledSelect";
import { Switch } from "../../components/inputs/Switch";
import { LoadingIcon } from "../../components/LoadingIcon";
import { Toolbar } from "../../components/Toolbar";
import { api } from "../../util/api";
import { PayorGroupRow } from "./PayorGroupRow";
import { tuplesToOptions } from "./utils";

const filterGroupsByPayor = (groups, payor) =>
  groups.filter((g) =>
    _.includes(
      g.payors.map((p) => p.id),
      payor
    )
  );

const filterGroupsByCptCodes = (groups, codes) =>
  groups.filter(
    (g) =>
      _.intersection(
        g.covered_cpt_codes.map((c) => c.id),
        codes
      ).length > 0
  );

// This method includes explicit returns otherwise prettier turns it
// into a single unreadable line
const filterGroupsByRequiresPriorAuth = (groups, paRequired) =>
  groups.filter((g) => {
    return _.some(
      g.payors.map((p) => {
        return _.some(p.payor_cpt_codes_settings, (s) => s.requires_pa === paRequired);
      })
    );
  });

const filterGroupsByRequiresGeneticCounseling = (groups, gcRequired) =>
  groups.filter((g) => {
    return _.some(
      g.payors.map((p) => {
        return _.some(p.payor_cpt_codes_settings, (s) => s.gc_requirement !== null);
      })
    );
  });

export const PayorConfigGrid = ({ payorOptions, payorGroupOptions, cptOptions }) => {
  // STATE
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedPayor, setSelectedPayor] = useState(null);
  const [selectedPayorGroup, setSelectedPayorGroup] = useState(null);
  const [selectedCptCodes, setSelectedCptCodes] = useState(null);
  const [paRequired, setPaRequired] = useState(false);
  const [gcRequired, setGcRequired] = useState(false);

  const controlsDisabled = (selectedPayorGroup === null && selectedPayor === null) || loading;

  // DATA
  const payorSelectOptions = useMemo(() => tuplesToOptions(payorOptions), [payorOptions]);
  const payorGroupSelectOptions = useMemo(() => tuplesToOptions(payorGroupOptions), [payorGroupOptions]);
  const cptSelectOptionsSelectOptions = useMemo(() => tuplesToOptions(cptOptions), [cptOptions]);

  const filteredGroups = useMemo(() => {
    let newGroups = data;

    if (selectedPayor) {
      newGroups = filterGroupsByPayor(newGroups, selectedPayor);
    }

    if (selectedCptCodes) {
      newGroups = filterGroupsByCptCodes(newGroups, selectedCptCodes);
    }

    if (paRequired) {
      newGroups = filterGroupsByRequiresPriorAuth(newGroups, paRequired);
    }

    if (gcRequired) {
      newGroups = filterGroupsByRequiresGeneticCounseling(newGroups, gcRequired);
    }

    return newGroups;
  }, [data, selectedPayor, selectedCptCodes, paRequired, gcRequired]);

  // HANDLERS
  const handleSelectPayor = (option) => {
    if (option === null) {
      setSelectedPayor(null);
    } else {
      setSelectedPayor(option.value);
    }
  };

  const handleSelectPayorGroup = (option) => {
    if (option === null) {
      setSelectedPayorGroup(null);
    } else {
      setSelectedPayorGroup(option.value);
    }
  };

  const handleSelectCptCodes = (options) => {
    if (options.length) {
      setSelectedCptCodes(options.map((o) => o.value));
    } else {
      setSelectedCptCodes(null);
    }
  };

  // EFFECTS
  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setLoading(true);

      try {
        const res = await api.get("/admin/payors/grid.json", {
          params: {
            payor_group_id: selectedPayorGroup,
            payor_id: selectedPayor,
          },
        });
        if (isMounted) {
          setData(res.data);
        }
      } catch (e) {
        console.error(e);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    if (selectedPayorGroup || selectedPayor || selectedCptCodes) {
      fetchData();
    }

    return () => void (isMounted = false);
  }, [setLoading, selectedPayorGroup, selectedPayor, selectedCptCodes]);

  return (
    <div>
      <Toolbar start className="p-2 bg-light">
        <div style={{ width: 200 }}>
          <Select
            placeholder="Payor Group"
            onChange={handleSelectPayorGroup}
            options={payorGroupSelectOptions}
            className="me-3"
            isDisabled={loading}
            isClearable
          />
        </div>
        <div style={{ width: 200 }}>
          <Select
            placeholder="Payor"
            onChange={handleSelectPayor}
            options={payorSelectOptions}
            className="me-3"
            isDisabled={loading}
            isClearable
          />
        </div>

        <div style={{ width: 200 }}>
          <Select
            placeholder="CPT Codes"
            onChange={handleSelectCptCodes}
            options={cptSelectOptionsSelectOptions}
            className="me-3"
            isDisabled={controlsDisabled}
            isMulti
            isClearable
          />
        </div>

        <Switch
          id="requiers-pa-toggle"
          label="Requires Prior Auth"
          className="me-3"
          value={paRequired}
          disabled={controlsDisabled}
          onChange={() => setPaRequired(!paRequired)}
        />

        <Switch
          id="requiers-gc-toggle"
          label="Requires Genetic Counseling"
          value={gcRequired}
          disabled={controlsDisabled}
          onChange={() => setGcRequired(!gcRequired)}
        />
      </Toolbar>

      <GridBody
        loading={loading}
        filteredGroups={filteredGroups}
        selectedPayorGroup={selectedPayorGroup}
        selectedCptCodes={selectedCptCodes}
        selectedPayor={selectedPayor}
        paRequired={paRequired}
        gcRequired={gcRequired}
      />
    </div>
  );
};

const GridBody = ({ loading, filteredGroups, selectedPayorGroup, selectedCptCodes, selectedPayor, paRequired, gcRequired }) => {
  if (loading) {
    return <Loading />;
  }

  if (!selectedPayorGroup && !selectedPayor && !selectedCptCodes) {
    return <SelectPayorGroupPrompt />;
  }

  return (
    <>
      {filteredGroups.length > 0 ? (
        <Table size="sm">
          <thead>
            <tr>
              <th>Payor Group</th>
              <th>Payor</th>
              <th>CPT</th>
              <th>Covered</th>
              <th>
                PA Required
                <Info id="pa-required-info" className="ms-2 text-subtle" />
                <UncontrolledPopover trigger="hover" placement="bottom" target={`pa-required-info`}>
                  <div class="p-3">
                    An <span className="text-danger">*</span> means that there is no CPT setting for this Payor / CPT combo.
                  </div>
                </UncontrolledPopover>
              </th>
              <th>GC Required</th>
              <th>Policy</th>
            </tr>
          </thead>

          <tbody>
            {filteredGroups.map((group, i) => (
              <PayorGroupRow
                key={i}
                group={group}
                selectedCptCodes={selectedCptCodes}
                selectedPayor={selectedPayor}
                paRequired={paRequired}
                gcRequired={gcRequired}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="text-muted p-4">No data</div>
      )}
    </>
  );
};

const Loading = () => {
  return (
    <div className="text-muted p-4">
      <LoadingIcon /> Loading payor data...
    </div>
  );
};

const SelectPayorGroupPrompt = () => {
  return (
    <div className="text-muted p-4">
      <p>
        Select a <strong>payor group</strong> or <strong>payor</strong> to get started.
      </p>
    </div>
  );
};
