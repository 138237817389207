import React, { useCallback, useState } from "react";

import { AttachButton } from "../components/AttachButton";
import { api } from "../util/api";
import { snakifyKeys } from "../util/helpers";

export const DocAttachButton = ({ s3Key, s3Bucket, ...props }) => {
  const [error, setError] = useState(null);

  const onSubmit = useCallback(
    async (values) => {
      try {
        const { requestId: caseId, attachmentCategory } = values;
        await api.post(`/supplemental_documents/attach`, snakifyKeys({ s3Bucket, s3Key, caseId, attachmentCategory }));
        window.location.reload();
      } catch (e) {
        setError(e);
        console.error(e);
      }
    },
    [s3Bucket, s3Key]
  );

  return <AttachButton type="supplemental document" onSubmit={onSubmit} withCategory={false} error={error} {...props} />;
};
