import React from "react";
import { WizardContext } from "../WizardContext";
import { CheckPortalScreen } from "./CheckPortalScreen";
import { FollowupAlertScreen } from "./FollowupAlertScreen";
import { MoveToMissingInfoScreen } from "./MoveToMissingInfoScreen";
import { MedicalReviewCompleteScreen } from "./MedicalReviewCompleteScreen";
import { MedicalReviewRequiredScreen } from "./MedicalReviewRequiredScreen";
import { MedicalReviewResultsScreen } from "./MedicalReviewResultsScreen";
import { ActionPossibleScreen } from "./ActionPossibleScreen";
import { CallPayorScreen } from "./CallPayorScreen";
import { PayorConversationScreen } from "./PayorConversationScreen";
import { ConfirmFaxScreen } from "./ConfirmFaxScreen";
import { RecordResponseScreen } from "./RecordResponseScreen";

export const CHECK_PORTAL_STEP = "check_portal";
export const PORTAL_FOLLOWUP_STEP = "portal_followup_required";
export const MEDICAL_REVIEW_COMPLETE_STEP = "mr_complete";
export const MEDICAL_REVIEW_REQUIRED_STEP = "mr_required";
export const MEDICAL_REVIEW_FOLLOWUP_STEP = "mr_followup_required";
export const MEDICAL_REVIEW_RESULTS_STEP = "mr_results_entry";
export const MEDICAL_REVIEW_MISSING_INFO_STEP = "mr_missinginformation";
export const MEDICAL_REVIEW_PEER_TO_PEER_STEP = "mr_peer_to_peer_required";
export const ACTION_POSSIBLE_STEP = "action_possible";
export const CALL_PAYOR_STEP = "call_payor";
export const NO_ANSWER_STEP = "no_answer";
export const PAYOR_CONVERSATION_STEP = "payor_conversation";
export const MOVE_TO_MI_STEP = "move_to_missinginformation";
export const CONFIRM_FAX_STEP = "confirm_fax";
export const FOLLOWUP_COMPLETE_STEP = "followup_complete";

export const STEPS = {
  [CHECK_PORTAL_STEP]: {
    initial: true,
    next({ answers }, action) {
      if (answers.readyToUpdate) {
        if (action.inMedicalReview) {
          return MEDICAL_REVIEW_COMPLETE_STEP;
        } else {
          return MEDICAL_REVIEW_REQUIRED_STEP;
        }
      } else {
        return PORTAL_FOLLOWUP_STEP;
      }
    },
    Component: CheckPortalScreen,
  },

  [PORTAL_FOLLOWUP_STEP]: {
    next: false,
    Component: () => (
      <WizardContext.Consumer>
        {({ loading, createFollowupTask, inMedicalReview }) => (
          <FollowupAlertScreen
            days={inMedicalReview ? 3 : 2}
            loading={loading}
            onClick={async () => {
              await createFollowupTask(inMedicalReview ? 3 : 2, "Follow up on Digital Submission - Check Portal");
            }}
          />
        )}
      </WizardContext.Consumer>
    ),
  },

  [MEDICAL_REVIEW_COMPLETE_STEP]: {
    next({ answers }) {
      if (answers.medicalReviewComplete) {
        return MEDICAL_REVIEW_RESULTS_STEP;
      } else {
        return MEDICAL_REVIEW_FOLLOWUP_STEP;
      }
    },
    Component: MedicalReviewCompleteScreen,
  },

  [MEDICAL_REVIEW_REQUIRED_STEP]: {
    next({ answers }) {
      if (answers.medicalReviewRequired) {
        return MEDICAL_REVIEW_FOLLOWUP_STEP;
      } else {
        return ACTION_POSSIBLE_STEP;
      }
    },
    Component: MedicalReviewRequiredScreen,
  },

  [MEDICAL_REVIEW_FOLLOWUP_STEP]: {
    next: false,
    Component: () => (
      <WizardContext.Consumer>
        {({ loading, updateStatus, createFollowupTask, inMedicalReview }) => (
          <FollowupAlertScreen
            days={3}
            loading={loading}
            onClick={async () => {
              await updateStatus("medicalreview", undefined, false);
              await createFollowupTask(3, "Follow up on Digital Submission - Check Status of Medical Review");
            }}
          >
            {!inMedicalReview && (
              <>
                The case will be moved to <strong>Medical Review</strong>.
              </>
            )}
          </FollowupAlertScreen>
        )}
      </WizardContext.Consumer>
    ),
  },

  [MEDICAL_REVIEW_RESULTS_STEP]: {
    next({ answers }) {
      if (answers.medicalReviewMissingInfo) {
        return MEDICAL_REVIEW_MISSING_INFO_STEP;
      }
      if (answers.medicalReviewPeerToPeer) {
        return MEDICAL_REVIEW_PEER_TO_PEER_STEP;
      }
      return FOLLOWUP_COMPLETE_STEP;
    },
    Component: MedicalReviewResultsScreen,
  },

  [MEDICAL_REVIEW_MISSING_INFO_STEP]: {
    next: false,
  },

  [MEDICAL_REVIEW_PEER_TO_PEER_STEP]: {
    next: false,
  },

  [ACTION_POSSIBLE_STEP]: {
    next({ answers }) {
      if (answers.canTakeAction) {
        return FOLLOWUP_COMPLETE_STEP;
      } else {
        return CALL_PAYOR_STEP;
      }
    },
    Component: ActionPossibleScreen,
  },

  [CALL_PAYOR_STEP]: {
    next({ answers }) {
      if (answers.payorCallAnswered) {
        return PAYOR_CONVERSATION_STEP;
      } else {
        return NO_ANSWER_STEP;
      }
    },
    Component: CallPayorScreen,
  },

  [NO_ANSWER_STEP]: {
    next: false,
    Component: () => (
      <WizardContext.Consumer>
        {({ loading, createFollowupTask }) => (
          <FollowupAlertScreen
            days={2}
            loading={loading}
            onClick={async () => {
              await createFollowupTask(2, "Follow up on Digital Submission - No Answer");
            }}
          />
        )}
      </WizardContext.Consumer>
    ),
  },

  [PAYOR_CONVERSATION_STEP]: {
    next({ answers }) {
      if (!answers.payorHasReceivedSubmission) {
        if (answers.patientHadCoverage) {
          return CONFIRM_FAX_STEP;
        } else {
          return MOVE_TO_MI_STEP;
        }
      } else if (!answers.payorHasReachedDetermination) {
        return false;
      } else if (answers.payorHasSentDetermination) {
        return FOLLOWUP_COMPLETE_STEP;
      }
    },
    Component: PayorConversationScreen,
  },

  [MOVE_TO_MI_STEP]: {
    next: false,
    Component: MoveToMissingInfoScreen,
  },

  [CONFIRM_FAX_STEP]: {
    next: false,
    Component: ConfirmFaxScreen,
  },

  [FOLLOWUP_COMPLETE_STEP]: {
    next: false,
    Component: RecordResponseScreen,
  },
};
