import React, { useState } from "react";
import { LoadingButton } from "../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../util/api";

export const AdministrateResourceDeleteButton = ({ label = "Delete", deleteUrl, onError, onSuccess, ...rest }) => {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    if (!confirm("Are you sure you want to delete this item?")) {
      return;
    }

    setDeleting(true);

    try {
      await api.delete(deleteUrl);
      onSuccess();
    } catch (err) {
      onError(extractErrorMessage(err));
    } finally {
      setDeleting(false);
    }
  };

  return (
    <LoadingButton loading={deleting} color="link" className="text-danger" onClick={handleDelete} {...rest}>
      {label}
    </LoadingButton>
  );
};
