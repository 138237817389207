import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { EditClinicSupportdLabModal } from "./EditClinicSupportdLabModal";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { History } from "../../tools/sandbox/release/History";

export const ClinicSupportedLabActionCell = ({ row }) => {
  const [canDestroy] = useDestroyPermission("ClinicLabPermission", row.original.id);
  const [canUpdate] = useUpdatePermission("ClinicLabPermission", row.original.id);

  const { refresh, setMessage, setError } = useTableContext();
  const { id, lab } = row.original;

  const handleChange = (msg) => {
    setMessage(`Updated ${lab.lab_name}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditClinicSupportdLabModal color="link" onEdit={handleChange} clinicLabPermission={row.original} />}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/clinic_lab_permissions/${id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted "${lab.labName}".`);
          }}
          onError={setError}
        />
      )}

      <History entityName={"ClinicLabPermission"} subjectId={id} />
    </>
  );
};
