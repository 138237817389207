import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../components/DetailedErrorAlert";
import { LoadingButton } from "../../components/LoadingButton";
import { adminApi as api } from "../../util/api";
import { snakifyKeys } from "../../util/helpers";
import { required } from "../../util/validators";
import { useAdminResource } from "../utils";
import { DurationInput } from "../../components/inputs/final_form/DurationInput";

export const UpdateReviewCadenceModal = ({ resourceType, reviewType, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const { resource: cadence, error: fetchError, isLoading, fetch } = useAdminResource(`/admin/${resourceType}/cadence/${reviewType}.json`);

  const toggleModal = () => setOpen(!open);

  const handleUpdate = async (params) => {
    setError(null);

    try {
      await api.put(`/admin/${resourceType}/cadence/${reviewType}`, { cadence: snakifyKeys(params) });
      await fetch();

      if (onUpdate) {
        onUpdate();
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <LoadingButton onClick={toggleModal} {...rest} loading={isLoading}>
        Update Review Cadence
      </LoadingButton>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Update Review Cadence</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          {cadence && (
            <Form onSubmit={handleUpdate} initialValues={cadence}>
              {({ handleSubmit, submitting }) => {
                return (
                  <>
                    <Field component={DurationInput} label="Interval" name="interval" required validate={required} />

                    <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                      Submit
                    </LoadingButton>
                  </>
                );
              }}
            </Form>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
