import { useMemo } from "react";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { Toolbar } from "../../../components/Toolbar";
import { formatCentsAsDollars } from "../../../util/formatters";
import { deepCompact } from "../../../util/helpers";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreateLabCptCodeListPriceModal } from "../components/CreateLabCptCodeListPriceModal";
import { ImportLabCptCodeListPriceModal } from "../components/ImportLabCptCodeListPriceModal";
import { LabCptCodeListPriceActionCell } from "../components/LabCptCodeListPriceActionCell";

const useColumns = makeColumns(() => [
  {
    id: "cpt_code.code",
    accessor: "cptCode.code",
    Header: "CPT Code",
    Cell: TokenCell,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" valueKey="code" labelKey="code" />,
  },
  {
    id: "price_cents",
    accessor: "priceCents",
    Header: "Price",
    Cell: ({ value }) => <span>{formatCentsAsDollars(value)}</span>,
    disableFilters: true,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: LabCptCodeListPriceActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const LabsCptCodeListPricesTab = ({ labId, lab }) => {
  const columns = useColumns();

  const extraParams = useMemo(() => deepCompact({ lab: { id: labId } }), [labId]);
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);
  const [canCreate] = useCreatePermission("LabCptCodeListPrice");

  const onCreate = (newCptCodeListPrice) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created List Price for CPT Code ${newCptCodeListPrice.cpt_code.code}`);
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  return (
    <SystemConfigLayout title="Cpt Code List Pricing">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/lab_cpt_code_list_prices/export"
            filters={allFilters}
            ref={tableRef}
          />

          {canCreate && (
            <>
              <ImportLabCptCodeListPriceModal labId={labId} outline className="me-2" onImport={onImport} />
              <CreateLabCptCodeListPriceModal onCreate={onCreate} labId={labId} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/lab_cpt_code_list_prices.json`}
        resourceName={"cpt_code_list_prices"}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
