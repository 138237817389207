import axios from "axios";
import moment from "moment-timezone";
import React, { useRef, useState, useEffect, useMemo } from "react";
import { DropdownItem, Col, Row } from "reactstrap";
import { api } from "../util/api";
import { PageHeader } from "../components/PageHeader";
import { UncontrolledDropdown } from "../components/UncontrolledDropdown";
import { PillToggle } from "../components/inputs/PillToggle";
import { DashboardGrid } from "./DashboardGrid";
import { RangePicker } from "./RangePicker";
import { StatsRow } from "./StatsRow";
import { CutoffStatBody, CutoffStatCard } from "../components/StatCard";

export const createStartDate = ({ date, days = 0 }) => moment(date).subtract(days, "days").tz("America/New_York").startOf("day").toDate();
const createEndDate = ({ date, days = 0 }) => moment(date).add(days, "days").tz("America/New_York").endOf("day").toDate();

const START_DATE = createStartDate({ days: 90 });
const END_DATE = createEndDate({});
const ENABLE_DATE_FROM = createStartDate({ days: 91 });
const ENABLE_DATE_TO = createEndDate({ days: 91 });

const RANGE_LAST_90_DAYS = [moment().subtract(90, "days"), moment().endOf("day")];
const RANGE_LAST_7_DAYS = [moment().subtract(7, "days"), moment().endOf("day")];
const RANGE_YESTERDAY = [moment().subtract(1, "days"), moment().endOf("day")];
const RANGE_TODAY = [moment().startOf("day"), moment().add(1, "days").endOf("day")];

const RANGE_OPTIONS = [
  {
    value: RANGE_LAST_90_DAYS,
    label: "Last 3 Months",
    id: "3_MONTHS",
  },
  {
    value: RANGE_LAST_7_DAYS,
    label: "Last 7 Days",
    id: "7_DAYS",
  },
  {
    value: RANGE_YESTERDAY,
    label: "Yesterday",
    id: "YESTERDAY",
  },
  {
    value: RANGE_TODAY,
    label: "Today",
    id: "TODAY",
  },
];

const RangeSelect = ({ onChange, startDate, endDate, enableDateFrom, enableDateTo, ...rest }) => {
  const flatpickrRef = useRef();

  const isRangeSelected = (range) => {
    const [rangeStart, rangeEnd] = range;
    return startDate && moment(startDate).isSame(rangeStart, "day") && endDate && moment(endDate).isSame(rangeEnd, "day");
  };

  return (
    <UncontrolledDropdown
      // Normally, if the flatpickr is open, clicking in it will close the dropdown,
      //  so we need to explicitly prevent that.
      canClose={() => !flatpickrRef.current.flatpickr.isOpen}
      // Figure out if the current range matches one of the range presets
      buttonText={RANGE_OPTIONS.find((option) => isRangeSelected(option.value))?.label || "Custom Range"}
      {...rest}
    >
      <div className="dropdown-item">
        <RangePicker
          value={[startDate, endDate]}
          onChange={(value) => onChange({ value })}
          range={[enableDateFrom, enableDateTo]}
          ref={flatpickrRef}
        />
      </div>

      {RANGE_OPTIONS.map((option) => (
        <DropdownItem onClick={() => onChange(option)} key={option.id}>
          {option.label}
        </DropdownItem>
      ))}
    </UncontrolledDropdown>
  );
};

const DEFAULT_CASE_TYPE = "overview";

export function Dashboard({
  displayStats,
  archivedCases,
  payors,
  latestReportModal,
  exportModal,
  showDownloads,
  showLatestReport,
  showProvidedPayor,
  isPriorAuthEnabled,
  searchableStatuses,
  isBenefitsInvestigationEnabled,
  isPharmacyClinicUser,
}) {
  const [startDate, setStartDate] = useState(START_DATE);
  const [endDate, setEndDate] = useState(END_DATE);
  const [enableDateFrom, setEnableDateFrom] = useState(ENABLE_DATE_FROM);
  const [enableDateTo, setEnableDateTo] = useState(ENABLE_DATE_TO);

  const [caseType, setCaseType] = useState(DEFAULT_CASE_TYPE);

  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);

  // THIS MUST MATCH THE CASE_TYPE enum in case.rb
  const caseTypeOptions = [{ value: "overview", label: "Overview" }];
  if (isPriorAuthEnabled) {
    caseTypeOptions.push({ value: "type_prior_auth", label: "Prior Auth" });
  }
  if (isBenefitsInvestigationEnabled) {
    caseTypeOptions.push({ value: "type_benefits_investigation", label: "seeQer" });
  }

  useEffect(() => {
    let isMounted = true;

    const fetchStats = async () => {
      if (isMounted) {
        setLoading(true);
      }

      try {
        const res = await api.get("/dashboard/stats", {
          params: { start_date: startDate, end_date: endDate },
        });

        if (isMounted) {
          setStats(res.data);
        }
      } catch (e) {
        if (!axios.isCancel(e)) {
          console.error(e);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchStats();

    return () => void (isMounted = false);
  }, [startDate, endDate]);

  const paStats = useMemo(() => stats.prior_auth || {}, [stats]);
  const biStats = useMemo(() => stats.benefits_investigation || {}, [stats]);

  const setEnabledDate = (newDate) => {
    setEnableDateFrom(newDate ? createStartDate({ newDate, days: 91 }) : null);
    setEnableDateTo(newDate ? createEndDate({ newDate, days: 91 }) : null);
  };

  const setDateRange = ([newStartDate, newEndDate]) => {
    setEnabledDate(newStartDate);

    const parsedStartDate = newStartDate ? createStartDate({ date: newStartDate }) : null;
    const parsedEndDate = newEndDate ? createEndDate({ date: newEndDate }) : null;

    setStartDate(parsedStartDate);
    setEndDate(parsedEndDate);
  };

  const showFilter = isPriorAuthEnabled && isBenefitsInvestigationEnabled;

  return (
    <>
      <PageHeader
        header={
          <>
            <span className="me-3">All Cases</span>
            <PillToggle value={caseType} onChange={setCaseType} options={caseTypeOptions} className="bg-white" />
          </>
        }
        actions={
          <>
            <RangeSelect
              onChange={({ value }) => setDateRange(value)}
              startDate={startDate}
              endDate={endDate}
              enableDateFrom={enableDateFrom}
              enableDateTo={enableDateTo}
            />
            {showDownloads && (
              <UncontrolledDropdown className="ms-2" buttonText="Downloads">
                {showLatestReport && (
                  <DropdownItem data-bs-toggle="modal" data-bs-target={latestReportModal}>
                    Download SSR
                  </DropdownItem>
                )}
                <DropdownItem data-bs-toggle="modal" data-bs-target={exportModal} size="sm">
                  Export CSV
                </DropdownItem>
              </UncontrolledDropdown>
            )}
          </>
        }
      />

      {displayStats && (isPriorAuthEnabled || isBenefitsInvestigationEnabled) && (
        <div className="mb-5">
          <StatsRow
            startDate={startDate}
            priorAuthEnabled={isPriorAuthEnabled}
            benefitsInvestigationEnabled={isBenefitsInvestigationEnabled}
            endDate={endDate}
            currentFocus={caseType}
            paStats={paStats}
            biStats={biStats}
            loading={loading}
          />
        </div>
      )}

      {caseType === "overview" ? (
        <Row>
          {isPriorAuthEnabled && (
            <Col style={{ flexGrow: 4 }} className="m-2">
              <Row>
                <h5>Prior Auth Cases</h5>
              </Row>
              <Row>
                <CutoffStatCard>
                  <CutoffStatBody value={paStats.approved_count} label="Approved" icon="check-circle" variant="info" url="" />
                  <CutoffStatBody value={paStats.missing_info_count} label="Missing Info" icon="help-circle" variant="info" url="" />
                  <CutoffStatBody value={paStats.denied_count} label="Denied" icon="minus-circle" variant="info" url="" />
                  <CutoffStatBody value={paStats.not_required_count} label="Not Required" icon="x-circle" variant="info" url="" />
                </CutoffStatCard>
              </Row>
            </Col>
          )}
          <Col style={{ flexGrow: 2 }} className="m-2">
            <Row>
              <h5>seeQer Cases</h5>
            </Row>
            <Row>
              <CutoffStatCard>
                <CutoffStatBody value={biStats.successful_count} label="Successful" icon="check-circle" variant="success" url="" />
                <CutoffStatBody value={biStats.unsuccessful_count} label="Unsuccessful" icon="x-circle" variant="warning" url="" />
              </CutoffStatCard>
            </Row>
          </Col>
        </Row>
      ) : (
        <DashboardGrid
          apiUrl="/cases.json"
          startDate={startDate}
          endDate={endDate}
          payors={payors}
          caseType={caseType}
          totalItems={caseType === "seeQer" ? biStats.total_cases : paStats.total_cases}
          archivedCases={archivedCases}
          searchableStatuses={searchableStatuses}
          showProvidedPayor={showProvidedPayor}
          isPharmacyClinicUser={isPharmacyClinicUser}
        />
      )}
    </>
  );
}
