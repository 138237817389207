import React from "react";
import { FormText } from "reactstrap";
import { PayorSelector as BasePayorSelector } from "../PayorSelector";
import { InputWrapper, serializeInput } from "./InputWrapper";

export const PayorSelector = ({ children, input, label, meta, ...rest }) => {
  const invalid = Boolean(meta.touched && meta.error);

  return (
    <InputWrapper label={label} required={rest.required} data-testid={rest["data-testid"]}>
      <BasePayorSelector {...input} {...rest} valid={!invalid} />

      {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}

      {children}
    </InputWrapper>
  );
};

export const SerializedPayorSelector = serializeInput(PayorSelector);
