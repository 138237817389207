import React, { useState } from "react";
import { FormGroup, FormText } from "reactstrap";
import { DetailedAlert } from "../../../components/DetailedAlert";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";

export const PayorTestMappingImport = () => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageDetails, setMessageDetails] = useState(null);

  const handleImport = async (values) => {
    const params = {
      file: {
        bytes: values.file,
      },
    };

    try {
      const res = await adminApi.post("/admin/payor_test_mappings/import.json", snakifyKeys(params));
      const message = res.data?.meta?.message;
      const details = res.data?.meta?.error_details;

      setMessage(message);
      setMessageDetails(details);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <SystemConfigLayout title={["Tools", "SeeEeEcret", "Import Payor Test Mappings"]}>
      <DetailedErrorAlert error={error} />

      <DetailedAlert
        message={message}
        details={messageDetails}
        messageStyle={messageDetails && messageDetails.length > 0 ? "warning" : "info"}
      />

      <FormGroup>
        <ImportModal
          onImport={handleImport}
          title="Import Payor Test Mappings..."
          exampleDownloadPath="/admin/payor_test_mappings/example"
        />
        <FormText color="muted">Shhh... don't tell</FormText>
      </FormGroup>
    </SystemConfigLayout>
  );
};
