import _ from "lodash";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { Container, Row } from "reactstrap";
import { EnumFilter } from "../../../components/inputs/EnumFilter";
import { DateInput } from "../../../components/inputs/final_form/DateInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { formatCentsAsDollars, formatDateTimeWithMonthYear } from "../../../util/formatters";
import { deepCompact } from "../../../util/helpers";
import { required } from "../../../util/validators";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { makeColumns, useAdministrateTable, usePermissionCheck } from "../../utils";

export const BillingAnalysisIndexPage = () => {
  const [canAccessBilling] = usePermissionCheck("admin_billing");

  const columns = makeColumns(() => [
    {
      id: "lab_id",
      accessor: "labName",
      Header: "Lab",
      Filter: (props) => (
        <AdministrateEnumFilter
          {...props}
          resourceUrl="/admin/labs.json?lab[direction]=asc&lab[order]=lab_name&per_page=100"
          valueKey="id"
          labelKey="lab_name"
        />
      ),
    },
    {
      id: "case_id",
      accessor: "publicId",
      Header: `${window.branding.brandNameShort} Case ID`,
      filterable: true,
      disableSortBy: true,
    },
    {
      id: "patient_name",
      accessor: "patientName",
      Header: "Patient Name",
      filterable: true,
    },
    {
      id: "verified_insurance",
      accessor: "verifiedInsurance",
      Header: "Verified Insurance",
      disableFilters: true,
    },
    {
      id: "created_at",
      accessor: "createdAt",
      Header: "Created Date",
      disableFilters: true,
    },
    {
      id: "billable_at",
      accessor: "billableAt",
      Header: "Billable at Date",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      id: "case_type",
      accessor: "caseType",
      Header: "Case Type",
      Filter: (props) => (
        <EnumFilter
          {...props}
          options={[
            { value: "type_prior_auth", label: "seeZer" },
            { value: "type_benefits_investigation", label: "seeQer" },
          ]}
        />
      ),
    },
    {
      id: "submission_type",
      accessor: "submissionType",
      Header: "Submission Type",
      Filter: (props) => (
        <EnumFilter
          {...props}
          options={[
            { value: "paper", label: "Paper" },
            { value: "digital", label: "Digital" },
            { value: "phone", label: "Phone" },
          ]}
        />
      ),
    },
    {
      id: "status",
      accessor: "status",
      Header: "Status",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      id: "substatus",
      accessor: "substatus",
      Header: "Substatus",
      disableFilters: true,
      disableSortBy: true,
    },
    {
      id: "billable_amount",
      accessor: "billableAmount",
      Header: "Billable Amount",
      disableFilters: true,
      disableSortBy: true,
    },
  ])();

  const [snapshotDate, setSnapshotDate] = useState(moment().format("YYYY-MM-DD"));

  const extraParams = useMemo(() => deepCompact({ snapshot: snapshotDate }), [snapshotDate]);

  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const [metadata, setMetadata] = useState(null);

  const submitSnapshot = (values) => {
    setMetadata(null);
    setSnapshotDate(values.reportingDate);
  };

  useEffect(() => {
    const fetchMetadata = async () => {
      const params = new URLSearchParams(allFilters);
      try {
        const response = await api.get(`/admin/cases/billing_snapshot.json?${params}`);
        if (response.status === 200) {
          setMetadata(response.data);
        }
      } catch (err) {
        tableRef.current.setError("Error getting billing snapshot");
      }
    };
    fetchMetadata();
  }, [tableRef, allFilters]);

  return (
    canAccessBilling && (
      <SystemConfigLayout title="Billing Analysis">
        <Container fluid>
          <Row className="mb-3">
            {metadata && (
              <div className="text-end w-40">
                <Form onSubmit={_.noop} initialValues={metadata}>
                  {() => {
                    return (
                      <>
                        <Field
                          className="w-25 d-inline ms-1 text-center"
                          name="count_billable_nonzero"
                          component={TextInput}
                          label={`Total Billable Cases at Rate > $0 for ${formatDateTimeWithMonthYear(snapshotDate)}`}
                          readOnly
                        />
                        <Field
                          className="w-25 d-inline ms-1 text-center"
                          name="count_billable_zero"
                          component={TextInput}
                          label={`Total Billable Cases at Rate = $0 for ${formatDateTimeWithMonthYear(snapshotDate)}`}
                          readOnly
                        />
                        <Field
                          className="w-25 d-inline ms-1 text-center"
                          name="total_billable_cents"
                          component={TextInput}
                          label={`Total Billable Revenue for ${formatDateTimeWithMonthYear(snapshotDate)}`}
                          format={(value) => formatCentsAsDollars(value)}
                          readOnly
                        />
                      </>
                    );
                  }}
                </Form>
              </div>
            )}
            <div className="ms-auto">
              <Form onSubmit={submitSnapshot} initialValues={{ reportingDate: snapshotDate }}>
                {({ handleSubmit, submitting, form }) => {
                  return (
                    <>
                      <Field name="reportingDate" component={DateInput} label={"Select Reporting Month / Year"} validate={required} />
                      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting} className="me-1">
                        Update
                      </LoadingButton>
                    </>
                  );
                }}
              </Form>
            </div>
          </Row>
        </Container>
        <AdministrateTable
          resourceUrl={"/admin/cases.json"}
          resourceName={"billing_analysis"}
          onFilteredChange={setTableFilters}
          columns={columns}
          ref={tableRef}
          extraParams={extraParams}
          defaultSorted={[{ id: "lab_name" }]}
        />
      </SystemConfigLayout>
    )
  );
};
