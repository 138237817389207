import $ from "jquery";
import { createRoot } from "react-dom/client";
import { DeductibleWorksheet } from "../../workflow/benefits_investgition/DeductibleWorksheet";

export function initBenefitsInvestigationWorkflow() {
  // New React Components
  const worksheetContainer = document.getElementById("deductible_worksheet");

  if (worksheetContainer) {
    const root = createRoot(worksheetContainer);
    const { benefitsInvestigationData, caseId, submitUrl, insuredPayor } = worksheetContainer.dataset;
    const parsedBenefitsInvestigationData = JSON.parse(benefitsInvestigationData);

    root.render(
      <DeductibleWorksheet
        caseId={caseId}
        submitUrl={submitUrl}
        benefitsInvestigationData={parsedBenefitsInvestigationData}
        insuredPayor={insuredPayor === "true"}
      />
    );
  }

  // Legacy jQuery
  const benefitInvestigationForm = $("form.benefits_investigation_form");

  if (benefitInvestigationForm) {
    function toggleMedicareDefaults() {
      const active = $("#case_benefits_investigation_data_coverage_active");
      const covered = $("#case_benefits_investigation_data_covered");
      if ($("#case_benefits_investigation_data_medicare").val() == "true") {
        covered.val("true");
        active.val("true");
      } else {
        covered.val("");
        active.val("");
      }
    }

    $("#case_benefits_investigation_data_medicare").change(toggleMedicareDefaults);

    function updateNetworkPrefixes() {
      const $paRequiredLabel = $("label[for=case_benefits_investigation_data_pa_required]")[0];

      if ($paRequiredLabel) {
        const content = $paRequiredLabel.textContent;

        if ($("#case_benefits_investigation_data_in_network").val() === "true") {
          $paRequiredLabel.textContent = content.replace("Out of Network", "In Network");
        } else {
          $paRequiredLabel.textContent = content.replace("In Network", "Out of Network");
        }
      }
    }

    $("#case_benefits_investigation_data_in_network").change(updateNetworkPrefixes);
    updateNetworkPrefixes();
  }
}
