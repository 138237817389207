import React from "react";
import { WizardContext } from "../../WizardContext";
import { PayorRequiresAdditionalInformationBranch } from "./PayorRequiresAdditionalInformationBranch";
import { PayorHasNotSentDeterminationBranch } from "./PayorHasNotSentDeterminationBranch";
import { YesNoToggle } from "../../../../components/wizards/YesNoToggle";
import { TrinaryTree } from "../../../../components/wizards/TrinaryTree";

export const PayorHasNotReachedDeterminationBranch = () => (
  <WizardContext.Consumer>
    {({ state, dispatch }) => (
      <>
        <YesNoToggle
          label="Is additional information required to reach a determination?"
          value={state.answers.payorHasSentDetermination}
          id="payorRequiresAdditionalInfo"
          onChange={(v) => {
            dispatch({
              type: "setAnswer",
              key: "payorRequiresAdditionalInfo",
              value: v,
              doTransition: false,
            });
          }}
        />

        <TrinaryTree
          branch={state.answers.payorRequiresAdditionalInfo}
          trueBranch={<PayorRequiresAdditionalInformationBranch />}
          falseBranch={<PayorHasNotSentDeterminationBranch />}
        />
      </>
    )}
  </WizardContext.Consumer>
);
