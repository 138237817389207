import React from "react";
import Icon from "./Icon";
import { Alert } from "reactstrap";

export const AlertIcon = ({ messageType }) => {
  const getIcon = (messageType) => {
    switch (messageType) {
      case "success":
        return (
          <span className="rounded-icon">
            <Icon name="check-circle" color="white" size={14} className="round-circle" />
          </span>
        );
      case "info":
        return <Icon name="info" />;
      case "primary":
        return <Icon name="info" />;
      case "warning":
        return <Icon name="alert-octagon" />;
      case "danger":
        return <Icon name="alert-triangle" />;
    }
  };

  return (
    <>
      <span className="me-1">{getIcon(messageType)} </span>
    </>
  );
};
