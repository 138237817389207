import React from "react";
import { Alert } from "reactstrap";

export const ActionAlert = ({ children, actions, ...props }) => (
  <Alert {...props}>
    <div className="d-flex flex-row align-items-center w-100">
      <div className="alert-content me-2">{children}</div>
      <div className="alert-action ms-auto">{actions}</div>
    </div>
  </Alert>
);
