import React, { useState, useEffect } from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { required } from "../../../../util/validators";
import { LoadingButton } from "../../../../components/LoadingButton";
import { SelectInput } from "../../../../components/inputs/final_form/SelectInput";
import { Switch as SwitchInput } from "../../../../components/inputs/final_form/Switch";
import { formatDateTime } from "../../../../util/formatters";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";
import { adminApi } from "../../../../util/api";

export const SuperDialMessageForm = ({ handleSubmit, submitting, values, form }) => {
  const [status, setStatus] = useState("");
  const [substatusOptions, setSubstatusOptions] = useState([]);

  const getSubstatusOptions = async (status, setSubstatusOptions) => {
    try {
      const { data } = await adminApi.get(`/admin/enums/prior_auth_substatuses.json?status=${status}`);
      setSubstatusOptions(data.resources);
    } catch (err) {
      console.error(err);
    }
  };

  const clearField = (fieldName) => {
    form.change(fieldName, "");
  };

  const handleStatusUpdate = (selected) => {
    setSubstatusOptions([]);
    clearField("substatus");

    if (selected?.value !== "denied") {
      clearField("denialReasonCode");
    }

    setStatus(selected?.value);
  };

  const handleAssignChange = (shouldAssign) => {
    if (shouldAssign) {
      clearField("status");
      clearField("substatus");
      clearField("denialReasonCode");
    }

    form.change("assignToFollowup", shouldAssign);
  };

  useEffect(() => {
    let currentStatus = status || values.status;
    if (currentStatus) {
      getSubstatusOptions(currentStatus, setSubstatusOptions);
    }
  }, [status, values]);

  //  Field display rules:
  //  - Status will only appear when Assign to Follow Up is false
  //  - Sub Status will only appear when the Status allows a sub status
  //  - Denial Reason only appears when the Status is "denied"
  return (
    <>
      <Field component={TextInput} label="Super Dial Message" name="messageRegex" required validate={required} />
      <Field
        component={SwitchInput}
        label="Assign to Follow Up"
        name="assignToFollowup"
        type="checkbox"
        onChange={(e) => handleAssignChange(e.target.checked)}
      />

      {!values.assignToFollowup && (
        <>
          <Field
            component={AdministrateSelectInput}
            resourceUrl="/admin/enums/prior_auth_statuses.json"
            label="Status"
            name="status"
            labelKey="label"
            onUpdate={handleStatusUpdate}
          />

          {substatusOptions?.length > 0 && (
            <Field component={SelectInput} label="Sub Status" name="substatus" options={substatusOptions} includeBlank />
          )}

          {(status === "denied" || values.status === "denied") && (
            <Field
              component={AdministrateSelectInput}
              resourceUrl="/admin/enums/denial_reason_options.json"
              label="Denial Reason"
              name="denialReasonCode"
              labelKey="label"
            />
          )}
        </>
      )}

      {values.createdAt && (
        <div className="bg-info bg-opacity-10 text-center py-2 mb-3 rounded-2">
          <p className="fw-medium mb-0">Created on {formatDateTime(values.createdAt)}</p>
        </div>
      )}

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Save
      </LoadingButton>
    </>
  );
};
