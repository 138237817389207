import React from "react";
import { Field } from "react-final-form";
import { MoneyInput } from "../../../components/inputs/final_form/MoneyInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const FeeScheduleForm = ({ handleSubmit, submitting, showCptCodeField = true }) => {
  return (
    <>
      <Field component={TextInput} type="hidden" name="payorGroupId" />

      {showCptCodeField && (
        <Field
          component={AdministrateSelectInput}
          resourceUrl="/admin/cpt_codes.json"
          label="CPT Code"
          name="cptCodeId"
          required
          validate={required}
        />
      )}
      <Field component={MoneyInput} label="Price" name="priceCents" />
      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
