import React from "react";
import { ProgressList } from "../components/ProgressList";
import { Card, CardBody } from "reactstrap";

const buildCaseSteps = (steps) => {
  const step = steps.map((step) => {
    return {
      secondaryLabel: step.created_at,
      content: step.new,
      status: "caseHistory",
      historyStepIcon: "monitor",
    };
  });

  return step;
};

export const SeerezCaseHistory = ({ history }) => {
  const steps = buildCaseSteps(history);

  return (
    <>
      <h3 className="text-dark mb-3 fw-medium">Case History</h3>
      <ProgressList items={steps} />
    </>
  );
};
