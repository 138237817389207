import Case from "case";
import _ from "lodash";
import React from "react";
import { DateFilter } from "../components/inputs/DateFilter";
import { EnumFilter } from "../components/inputs/EnumFilter";
import { DefaultFilter } from "../components/inputs/DefaultFilter";
import { formatDate } from "../util/formatters";
import { DashboardActionsCell } from "./DashboardActionsCell";

const statusBadgeClass = (status) => {
  switch (status) {
    case "denied":
      return "danger";
    case "approved":
      return "success";
    case "completed":
      return "secondary";
    case "action_needed":
      return "warning";
    default:
      return "info";
  }
};

const statusTuplesToOptions = (tuples) => tuples.map((t) => ({ label: t[0], value: t[1] }));

const statusLabelForValue = (value, statusTuples) => {
  const matchingTuple = statusTuples.find((t) => t[1] === value);
  return matchingTuple ? matchingTuple[0] : Case.title(value);
};

const CASE_TYPES = {
  prior_auth: "Prior Auth",
  benefits_investigation: "seeQer",
};

export const DASHBOARD_COLUMNS = (payors, searchableStatuses = [], showProvidedPayor, caseType, isPharmacyClinicUser) => {
  const COLUMNS = [
    {
      Header: "",
      id: "actions",
      disableFilters: true,
      disableSortBy: true,
      Cell: DashboardActionsCell,
      width: 100,
    },
    {
      Header: `${window.branding.brandNameShort} Case ID`,
      id: "public_id",
      accessor: "public_id",
      Cell: ({ row, value }) => (value ? <a href={`/prior_authorize_requests/${value}`}>{value}</a> : null),
      width: 130,
    },
    {
      Header: isPharmacyClinicUser ? "Patient ID" : "Lab Patient ID",
      id: "patient_id",
      accessor: "pa_request.Patient.PatientId",
      Cell: ({ row, value }) => (row.original.public_id ? value : null),
    },
    {
      Header: isPharmacyClinicUser ? "Order ID" : "Lab Order ID",
      id: "order_id",
      accessor: "pa_request.LabOrder.LabOrderId",
      Cell: ({ row, value }) => (row.original.public_id ? value : null),
    },
    {
      Header: "Status",
      id: "status",
      accessor: "cached_workflow_status",
      Cell: ({ row, value }) => (
        <span className={`status-badge text-${statusBadgeClass(row.original.status_bucket)}`}>
          {statusLabelForValue(value, searchableStatuses)}
        </span>
      ),
      Filter: (props) => <EnumFilter options={statusTuplesToOptions(searchableStatuses)} {...props} />,
      width: 200,
      disableFilters: caseType === "type_benefits_investigation",
    },
    {
      Header: "Received Date",
      id: "created_at",
      accessor: (r) => formatDate(_.get(r, "created_at")),
      minWidth: 130,
      Filter: (props) => <DateFilter {...props} />,
    },
    {
      Header: "Patient Name",
      id: "patient_name",
      accessor: (r) => {
        const first = _.get(r, "pa_request.Patient.FirstName");
        const last = _.get(r, "pa_request.Patient.LastName");
        return `${last}, ${first}`;
      },
    },
    {
      Header: showProvidedPayor ? "Provided Payor" : "Verified Payor",
      id: showProvidedPayor ? "primary_insurance" : "payor",
      accessor: showProvidedPayor ? "primary_request_insurance.insurance_name" : "payor.name",
      minWidth: 175,
      Filter: showProvidedPayor ? DefaultFilter : (props) => <EnumFilter {...props} options={payors} />,
    },
    {
      Header: "Provider Name",
      id: "provider_name",
      accessor: (r) => {
        const first = _.get(r, "pa_request.Provider.FirstName", "");
        const last = _.get(r, "pa_request.Provider.LastName", "");
        const labName = _.get(r, "pa_request.LabName", "");
        return isPharmacyClinicUser ? labName : _.compact([last, first]).join(", ");
      },
    },
  ];

  return COLUMNS;
};
