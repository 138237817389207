import React from "react";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { EditClinicSupportedPayorModal } from "./EditClinicSupportedPayorModal";
import { History } from "../../tools/sandbox/release/History";

export const ClinicSupportedPayorActionCell = ({ row }) => {
  const [canDelete] = useDestroyPermission("ClinicPayorPermission", row.original.id);
  const [canUpdate] = useUpdatePermission("ClinicPayorPermission", row.original.id);

  const { refresh, setMessage, setError } = useTableContext();
  const { id, payor } = row.original;

  const handleChange = (msg) => {
    setMessage(`Updated ${payor.name}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditClinicSupportedPayorModal color="link" onEdit={handleChange} clinicPayorPermission={row.original} />}
      {canDelete && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/clinic_payor_permissions/${id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted "${payor.name}".`);
          }}
          onError={setError}
        />
      )}

      <History entityName={"ClinicPayorPermission"} subjectId={id} />
    </>
  );
};
