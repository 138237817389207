import _ from "lodash";
import Case from "case";

export const getNow = () => new Date() / 1000;

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const isBlank = (v) => (_.isEmpty(v) && !_.isNumber(v) && !_.isBoolean(v)) || _.isNaN(v) || _.isNil(v);

// This renames keys in an object in order:
// rename({ one: 'first', two: 'second' }, { one: 'uno' }, { two: 'dos' })
// => { uno: "first", dos: 'second' }
export function rename(obj, ...changes) {
  const result = Object.assign({}, obj);
  changes.forEach((changeset) => {
    Object.keys(changeset).forEach((oldKey) => {
      const newKey = changeset[oldKey];
      result[newKey] = result[oldKey];
      delete result[oldKey];
    });
  });
  return result;
}

export const camelize = (str) => Case.camel(str);

export function getTitle(field, schema) {
  return _.get(schema, `${field}.title`, camelize(field));
}

export function getHelp(field, uiSchema) {
  return _.get(uiSchema, `${field}[ui:help]`);
}

export function getId(field, idSchema) {
  return _.get(idSchema, `${field}.$id`);
}

export function getClassNames(field, uiSchema) {
  return _.get(uiSchema, `${field}.classNames`);
}

export function errorToInputId(error) {
  if (!error.property) return null;

  let props = error.property.split(".").filter((x) => x);
  props.unshift("root");
  return props.join("_");
}

export const deepMapKeys = (obj, fn) => {
  var x = {};

  _.forOwn(obj, function (v, k) {
    if (_.isPlainObject(v)) v = deepMapKeys(v, fn);
    x[fn(v, k)] = v;
  });

  return x;
};

export const camelizeKeys = (collection) => deepMapKeys(collection, (v, k) => Case.camel(k));
export const snakifyKeys = (collection) => deepMapKeys(collection, (v, k) => Case.snake(k));

export const deepCompact = (obj) => {
  return (function compact(current) {
    // Recursively check for empty objects, arrays, and values
    // and remove them from the object.
    _.forOwn(current, (value, key) => {
      if (isBlank(value) || (_.isObject(value) && _.isEmpty(compact(value)))) {
        delete current[key];
      }
    });

    // remove any leftover undefined values from the delete
    // operation on an array
    if (_.isArray(current)) _.pull(current, undefined);

    return current;
  })(_.cloneDeep(obj));
};

// Adapted from https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another/5306832#5306832
export const arrayMove = (arr, old_index, new_index) => {
  const newArr = _.cloneDeep(arr);

  if (new_index >= newArr.length) {
    var k = new_index - newArr.length + 1;
    while (k--) {
      newArr.push(undefined);
    }
  }

  newArr.splice(new_index, 0, newArr.splice(old_index, 1)[0]);
  return newArr;
};

export const extractNameFromDataURL = (dataString) => {
  const nameRegex = /name=(.*?);/;
  const found = dataString.match(nameRegex);
  return found && found.length > 1 ? decodeURIComponent(found[1]) : "Unknown Filename";
};

export const findMappingId = (mappings, id, property) => mappings.find((m) => m[property] == id)?.id;

export const isValidJSON = (str) => !_.isError(_.attempt(JSON.stringify, str));
export const safeJSONParse = (v) => {
  try {
    return JSON.parse(v);
  } catch (e) {
    return v;
  }
};

export const uniqByShape = (collection) => _.uniqWith(collection, _.isEqual);
