import _, { noop } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Field, useField, useForm } from "react-final-form";
import { ConfirmModal } from "../components/ConfirmModal";
import { DateInput } from "../components/inputs/final_form/DateInput";
import { Fieldset } from "../components/inputs/final_form/Fieldset";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { LoadingButton } from "../components/LoadingButton";
import { api, redirectTo } from "../util/api";
import { snakifyKeys } from "../util/helpers";
import { numericInRange, required } from "../util/validators";
import { trackEvent } from "../util/track";
import { FieldsetWrapper, notAllCodes } from "../submit_right_direction/SubmitRightTestForm";
import { Card, CardText } from "reactstrap";
import { humanizeString } from "../util/formatters";

const REQUIRED_STATUS_FIELDS = {
  approved: ["phoneSubmissionResponse.authorizationNumber", "phoneSubmissionResponse.authorizationDate"],
  denied: [],
  documented_clean_claim: ["phoneSubmissionResponse.submissionReferenceNumber"],
  medicalreview: [],
  partially_approved: [
    "phoneSubmissionResponse.authorizationNumber",
    "phoneSubmissionResponse.authorizationDate",
    "phoneSubmissionResponse.approvedCptCodes",
  ],
  submitted: [],
  rejected: [],
};

const DISPLAY_STATUS_FIELDS = {
  approved: [
    "phoneSubmissionResponse.authorizationNumber",
    "phoneSubmissionResponse.authorizationDate",
    "phoneSubmissionResponse.authorizationEffectiveDate",
    "phoneSubmissionResponse.authorizationExpirationDate",
  ],
  denied: ["phoneSubmissionResponse.denialReasonCode", "phoneSubmissionResponse.submissionReferenceNumber"],
  documented_clean_claim: ["phoneSubmissionResponse.submissionReferenceNumber"],
  medicalreview: ["phoneSubmissionResponse.submissionReferenceNumber", "phoneSubmissionResponse.expectedCloseDate"],
  partially_approved: [
    "phoneSubmissionResponse.authorizationNumber",
    "phoneSubmissionResponse.authorizationDate",
    "phoneSubmissionResponse.authorizationEffectiveDate",
    "phoneSubmissionResponse.authorizationExpirationDate",
    "phoneSubmissionResponse.approvedCptCodes",
    "phoneSubmissionResponse.deniedCptCodes",
    "phoneSubmissionResponse.denialReasonCode",
  ],
  code_specific_result: [
    "phoneSubmissionResponse.approvedCptCodes",
    "phoneSubmissionResponse.deniedCptCodes",
    "phoneSubmissionResponse.documentedCleanClaimCptCodes",
  ],
  submitted: [],
  rejected: [],
};

const StatusDependentTestField = ({ name, displayCondition, requiredCondition, ...props }) => {
  if (!displayCondition) {
    return null;
  }

  return <Field name={`${name}`} required={requiredCondition} validate={requiredCondition ? required : noop} {...props} />;
};

const ConfirmationButton = ({ children, showModal = false, loading, disabled, onClick, onModalConfirm, ...rest }) => {
  const confirmButton = (
    <LoadingButton type="button" color="primary" loading={loading} disabled={disabled} onClick={onClick} {...rest}>
      {children}
    </LoadingButton>
  );

  return showModal ? (
    <ConfirmModal
      Target={confirmButton}
      onConfirm={onModalConfirm}
      confirmLabel="Confirm"
      title="Proceed to Next Submission"
      confirmMessage="Confirming this response will move the workflow to the next submission. Are you sure?"
      confirmButtonProps={{ type: "button" }}
    />
  ) : (
    confirmButton
  );
};

const trackProceedNextSubmission = (caseId) => {
  trackEvent("proceed_to_next_submission_confirm_button_sr2_phone", caseId, {
    step: "proceed_to_next_submission_confirm_button_sr2_phone",
  });
};

export const SubmitRightPhoneResponseForm = ({
  caseId,
  request,
  getPhoneResponseObj,
  phoneResponseOptions,
  denialReasonCodeOptions,
  verifiedCptCodeOptions,
  paStatuses,
  setConfirmedPhoneResponse,
  setError,
  setShowFaxButton,
  nextSubmissionUrl,
}) => {
  const form = useForm();
  const phoneResponse = useField("phoneSubmissionResponse.message", { subscription: { value: true } })?.input?.value;

  const approvedCptCodesValue = useField("phoneSubmissionResponse.approvedCptCodes", { subscription: { value: true } })?.input?.value;
  const deniedCptCodesValue = useField("phoneSubmissionResponse.deniedCptCodes", { subscription: { value: true } })?.input?.value;
  const cleanClaimCptCodesValue = useField("phoneSubmissionResponse.documentedCleanClaimCptCodes", { subscription: { value: true } })?.input
    ?.value;

  const [confirmed, _setConfirmed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approvedCodes, setApprovedCodes] = useState(verifiedCptCodeOptions);
  const [deniedCodes, setDeniedCodes] = useState(verifiedCptCodeOptions);
  const [cleanClaimCodes, setCleanClaimCodes] = useState(verifiedCptCodeOptions);

  const handleApprovedChange = (selectedCodes) => {
    const selectedValues = selectedCodes.map((code) => code.value);
    const nonSelectedValues = verifiedCptCodeOptions.filter((code) => !selectedValues.includes(code.value));

    // Filter out these values from the denied codes and clean claim
    setDeniedCodes(nonSelectedValues.filter((code) => !cleanClaimCptCodesValue.includes(code.value)));
    setCleanClaimCodes(nonSelectedValues.filter((code) => !deniedCptCodesValue.includes(code.value)));
  };

  const handleDeniedChange = (selectedCodes) => {
    const selectedValues = selectedCodes.map((code) => code.value);
    const nonSelectedValues = verifiedCptCodeOptions.filter((code) => !selectedValues.includes(code.value));

    // Filter out these values from the approved codes and clean claim
    setApprovedCodes(nonSelectedValues.filter((code) => !cleanClaimCptCodesValue.includes(code.value)));
    setCleanClaimCodes(nonSelectedValues.filter((code) => !approvedCptCodesValue.includes(code.value)));
  };

  const handleCleanClaimChange = (selectedCodes) => {
    const selectedValues = selectedCodes.map((code) => code.value);
    const nonSelectedValues = verifiedCptCodeOptions.filter((code) => !selectedValues.includes(code.value));

    // Filter out these values from the approved codes and denied
    setApprovedCodes(nonSelectedValues.filter((code) => !deniedCptCodesValue.includes(code.value)));
    setDeniedCodes(nonSelectedValues.filter((code) => !approvedCptCodesValue.includes(code.value)));
  };

  const setConfirmed = useCallback(
    (value) => {
      _setConfirmed(value);

      // Also update the parent copy
      setConfirmedPhoneResponse(value);
    },
    [setConfirmedPhoneResponse]
  );

  // Upon choosing a phone response, we clear out the confirmation.
  // We don't want this hook to fire any other time; the linter is wrong here.
  useEffect(() => {
    if (confirmed) {
      setConfirmed(false);
      setShowFaxButton(false);
    }
  }, [phoneResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  const responseObj = !_.isEmpty(phoneResponse) && getPhoneResponseObj(phoneResponse);
  const { status } = responseObj;

  const displayForStatus = useCallback((field) => (DISPLAY_STATUS_FIELDS[status] || []).includes(field), [status]);
  const requiredForStatus = useCallback((field) => (REQUIRED_STATUS_FIELDS[status] || []).includes(field), [status]);

  const parseStatusAndSubstatus = (responseObj) => {
    const parsedStatus = responseObj.status;
    const parsedSubstatus = responseObj.substatus;

    form.change("phoneSubmissionResponse.status", parsedStatus);
    form.change("phoneSubmissionResponse.substatus", parsedSubstatus);
  };

  // other handlers
  const isCodeSpecificResult = useMemo(() => status === "code_specific_result", [status]);

  const displayAuthorizationFields = useCallback(
    (field) => {
      return (approvedCptCodesValue?.length > 0 && isCodeSpecificResult) || displayForStatus(field);
    },
    [approvedCptCodesValue?.length, displayForStatus, isCodeSpecificResult]
  );

  const displayDenialReason = useCallback(
    (field) => {
      return (deniedCptCodesValue?.length > 0 && isCodeSpecificResult) || displayForStatus(field);
    },
    [deniedCptCodesValue?.length, displayForStatus, isCodeSpecificResult]
  );

  const requiresAuthorizationFields = useCallback(
    (field) => {
      return (approvedCptCodesValue?.length > 0 && isCodeSpecificResult) || requiredForStatus(field);
    },
    [approvedCptCodesValue?.length, requiredForStatus, isCodeSpecificResult]
  );

  const requiresDenialReason = useCallback(
    (field) => {
      return (deniedCptCodesValue?.length > 0 && isCodeSpecificResult) || requiredForStatus(field);
    },
    [deniedCptCodesValue?.length, requiredForStatus, isCodeSpecificResult]
  );

  const handleConfirm = () => {
    setLoading(true);

    if (responseObj?.message !== undefined) {
      form.change("phoneSubmissionResponse.phoneResponseId", responseObj.id);
      form.change("phoneSubmissionResponse.cannedComment", responseObj.canned_comment);

      if (responseObj.submit_right_direction == "status_the_case") {
        parseStatusAndSubstatus(responseObj);

        if (responseObj?.denial_reason_code) {
          form.change("phoneSubmissionResponse.denialReasonCode", responseObj?.denial_reason_code);
        }
      } else if (responseObj.submit_right_direction == "task_out_for_n_number_of_days_to_try_again") {
        form.change("phoneSubmissionResponse.taskName", responseObj.task_name);
        form.change("phoneSubmissionResponse.taskDuration", responseObj.task_duration);

        trackEvent("task_out_case_sr2_phone_response", caseId, {
          step: "task_out_case_sr2_phone_response",
        });
      }
    }

    trackEvent("phone_submission_confirm_button", caseId, {
      step: "phone_submission_confirm_button",
    });

    setConfirmed(true);
    setShowFaxButton(["submitted", "medicalreview"].includes(responseObj.status));
    setLoading(false);
  };

  const confirmProceedNextSubmission = async (cannedComment) => {
    setLoading(true);
    setError(null);

    const params = {
      id: caseId,
      proceedNextSubmission: true,
      phoneSubmissionResponse: {
        phoneResponseId: responseObj.id,
        cannedComment: cannedComment,
      },
    };

    try {
      await api.post(nextSubmissionUrl, snakifyKeys(params));
      trackProceedNextSubmission(caseId);
      redirectTo("/workflow/" + caseId);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  return (
    <>
      {(responseObj?.status === "code_specific_result" || responseObj?.status === "partially_approved") &&
        verifiedCptCodeOptions.length === 1 && (
          <Card color="warning" className="text-center py-4 bg-opacity-10 border-0 mb-2">
            <CardText>This Case only has 1 CPT Code, use the correct Submit Right Response instead.</CardText>
          </Card>
        )}

      <Fieldset>
        <Field
          component={SelectInput}
          label="Phone Submission Response"
          name="phoneSubmissionResponse.message"
          options={phoneResponseOptions}
        ></Field>

        {confirmed && responseObj?.submit_right_direction === "task_out_for_n_number_of_days_to_try_again" && (
          <>
            <Field component={TextInput} label="Task Name" name="phoneSubmissionResponse.taskName" disabled={true} />
            <Field
              component={TextInput}
              type="number"
              name="phoneSubmissionResponse.taskDuration"
              label="Task Duration(Days)"
              validate={numericInRange(0, 90)}
              min="0"
              max="90"
              disabled={true}
            />
          </>
        )}

        {confirmed && responseObj?.submit_right_direction === "status_the_case" && (
          <>
            <Field
              component={TextInput}
              label="Status"
              format={(val) => humanizeString(val || "None")}
              placeholder="None"
              name="phoneSubmissionResponse.status"
              disabled={true}
            />
            <Field
              component={TextInput}
              label="Substatus"
              format={(val) => humanizeString(val || "None")}
              placeholder="None"
              name="phoneSubmissionResponse.substatus"
              disabled={true}
            />

            <StatusDependentTestField
              requiredCondition={requiredForStatus("phoneSubmissionResponse.submissionReferenceNumber")}
              displayCondition={displayForStatus("phoneSubmissionResponse.submissionReferenceNumber")}
              name="phoneSubmissionResponse.submissionReferenceNumber"
              component={TextInput}
              label="Reference Number"
              initialValue={request.submission_reference_number}
            />

            <FieldsetWrapper labelText="Approval Fields" displayCondition={isCodeSpecificResult}>
              <StatusDependentTestField
                requiredCondition={requiredForStatus("phoneSubmissionResponse.approvedCptCodes")}
                displayCondition={displayForStatus("phoneSubmissionResponse.approvedCptCodes")}
                name="phoneSubmissionResponse.approvedCptCodes"
                component={SelectInput}
                isMulti
                options={approvedCodes}
                onUpdate={handleApprovedChange}
                label="Approved CPT Codes"
                placeholder="Select one or more CPT Codes..."
                validate={notAllCodes(verifiedCptCodeOptions)}
              />

              <StatusDependentTestField
                requiredCondition={requiresAuthorizationFields("phoneSubmissionResponse.authorizationNumber")}
                displayCondition={displayAuthorizationFields("phoneSubmissionResponse.authorizationNumber")}
                name="phoneSubmissionResponse.authorizationNumber"
                component={TextInput}
                label="Authorization Number"
                initialValue={request.authorization_number}
              />

              <StatusDependentTestField
                requiredCondition={requiresAuthorizationFields("phoneSubmissionResponse.authorizationDate")}
                displayCondition={displayAuthorizationFields("phoneSubmissionResponse.authorizationDate")}
                name="phoneSubmissionResponse.authorizationDate"
                component={DateInput}
                label="Authorization Date"
                initialValue={request.authorization_date}
              />

              <StatusDependentTestField
                requiredCondition={requiredForStatus("phoneSubmissionResponse.authorizationEffectiveDate")}
                displayCondition={displayAuthorizationFields("phoneSubmissionResponse.authorizationEffectiveDate")}
                name="phoneSubmissionResponse.authorizationEffectiveDate"
                component={DateInput}
                label="Authorization Effective Date"
                initialValue={request.authorization_effective_date}
              />

              <StatusDependentTestField
                requiredCondition={requiredForStatus("phoneSubmissionResponse.authorizationExpirationDate")}
                displayCondition={displayAuthorizationFields("phoneSubmissionResponse.authorizationExpirationDate")}
                name="phoneSubmissionResponse.authorizationExpirationDate"
                component={DateInput}
                label="Authorization Expiration Date"
                initialValue={request.authorization_expiration_date}
              />
            </FieldsetWrapper>

            <FieldsetWrapper labelText="Denial Fields" displayCondition={isCodeSpecificResult}>
              <StatusDependentTestField
                requiredCondition={requiredForStatus("phoneSubmissionResponse.deniedCptCodes")}
                displayCondition={displayForStatus("phoneSubmissionResponse.deniedCptCodes")}
                name="phoneSubmissionResponse.deniedCptCodes"
                component={SelectInput}
                isMulti
                options={deniedCodes}
                onUpdate={handleDeniedChange}
                label="Denied CPT Codes"
                placeholder="Select one or more CPT Codes..."
                validate={notAllCodes(verifiedCptCodeOptions)}
              />

              <StatusDependentTestField
                requiredCondition={requiresDenialReason("phoneSubmissionResponse.denialReasonCode")}
                displayCondition={displayDenialReason("phoneSubmissionResponse.denialReasonCode")}
                name="phoneSubmissionResponse.denialReasonCode"
                component={SelectInput}
                label="Denial Reason Code"
                options={denialReasonCodeOptions}
                initialValue={request.denial_reason_code}
                disabled={responseObj?.denial_reason_code ? true : false}
              />
            </FieldsetWrapper>

            <FieldsetWrapper labelText="Clean Claim Fields" displayCondition={isCodeSpecificResult}>
              <StatusDependentTestField
                requiredCondition={requiredForStatus("phoneSubmissionResponse.documentedCleanClaimCptCodes")}
                displayCondition={displayForStatus("phoneSubmissionResponse.documentedCleanClaimCptCodes")}
                name="phoneSubmissionResponse.documentedCleanClaimCptCodes"
                component={SelectInput}
                isMulti
                options={cleanClaimCodes}
                onUpdate={handleCleanClaimChange}
                label="Documented Clean Claim CPT Codes"
                placeholder="Select one or more CPT Codes..."
                validate={notAllCodes(verifiedCptCodeOptions)}
              />
            </FieldsetWrapper>

            <StatusDependentTestField
              requiredCondition={requiredForStatus("phoneSubmissionResponse.expectedCloseDate")}
              displayCondition={displayForStatus("phoneSubmissionResponse.expectedCloseDate")}
              name="phoneSubmissionResponse.expectedCloseDate"
              component={DateInput}
              label="Expected Close Date"
            />
          </>
        )}

        {confirmed && responseObj && (
          <>
            <Field component={TextInput} label="Canned Comment" name={"phoneSubmissionResponse.cannedComment"} />
          </>
        )}

        {!confirmed && (
          <ConfirmationButton
            showModal={responseObj?.submit_right_direction === "proceed_to_the_next_submission"}
            onClick={handleConfirm}
            onModalConfirm={() => confirmProceedNextSubmission(responseObj.canned_comment)}
            loading={loading}
            disabled={loading || _.isEmpty(phoneResponse)}
          >
            Confirm Response
          </ConfirmationButton>
        )}
      </Fieldset>
    </>
  );
};
