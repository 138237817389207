import React from "react";
import { connect } from "react-redux";
import { getDocument } from "../store";
import { CustomFieldsEditor } from "./CustomFieldsEditor";
import { FieldInspector } from "./FieldInspector";
import { HeaderBar } from "./HeaderBar";
import { PageList } from "./PageList";
import { SidebarTools } from "./SidebarTools";

class DocumentSchemaEditorComponent extends React.Component {
  componentWillMount() {
    const { onGetSchema } = this.props;
    onGetSchema();
  }

  render() {
    const { updateSchemaPath, editDocumentPath, previewSchemaPath, exportEnabled, getSchemaPath } = this.props;

    return (
      <div className="fde">
        <div className="fde__header">
          <HeaderBar
            updateSchemaPath={updateSchemaPath}
            editDocumentPath={editDocumentPath}
            previewSchemaPath={previewSchemaPath}
            exportEnabled={exportEnabled}
            schemaPath={getSchemaPath}
          />
        </div>

        <div className="fde__body">
          <aside className="fde__sidebar">
            <div className="fde__sidebar_section">
              <SidebarTools />
            </div>
            <div className="fde__sidebar_section">
              <CustomFieldsEditor />
            </div>
          </aside>

          <PageList />

          <aside className="fde__sidebar">
            <div className="fde__sidebar_section">
              <FieldInspector />
            </div>
          </aside>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (s) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onGetSchema: () => dispatch(getDocument(ownProps.getSchemaPath)),
});

export const DocumentSchemaEditor = connect(mapStateToProps, mapDispatchToProps)(DocumentSchemaEditorComponent);
