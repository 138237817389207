import React, { useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { required } from "../../../util/validators";

export const AddClinicTestMappingModal = ({ clinicId, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const initialValues = { reflexToPa: true };
  const testRepositoryUrl = "/admin/tests.json?";

  const toggleModal = () => setOpen(!open);

  const handleAdd = async (params) => {
    setError(null);

    try {
      const createParams = { clinicId, ...params };
      const { data } = await api.post(`/admin/clinic_test_mappings/`, snakifyKeys(createParams));

      if (onAdd) {
        onAdd(`Added ${data.resource.provided_test_name}`);
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Test Mapping
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Test Mapping</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleAdd} initialValues={initialValues}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field component={AdministrateSelectInput} resourceUrl="/admin/labs.json" label="Lab" name="labId" />
                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => {
                      return values["labId"] ? (
                        <Field
                          component={AdministrateSelectInput}
                          resourceUrl={`${testRepositoryUrl}test[direction]=asc&test[order]=commercial_name&lab=${values.labId}&archived=false`}
                          label="Test"
                          labelKey="commercial_name"
                          name="testId"
                          valueKey="id"
                          key={`test-${values.labId}`}
                          validate={values["labId"] ? required : undefined}
                          required
                        />
                      ) : (
                        <Field
                          component={AdministrateSelectInput}
                          resourceUrl={`${testRepositoryUrl}test[direction]=asc&central_repository=true&archived=false`}
                          label="Test"
                          labelKey="commercial_name"
                          name="testId"
                          valueKey="id"
                          validate={values["labId"] ? undefined : required}
                          required
                        />
                      );
                    }}
                  </FormSpy>
                  <Field component={TextInput} label="Provided Test Name" name="providedTestName" validate={required} required />
                  <Field component={Switch} label="Reflex To PA" name="reflexToPa" id="reflex-to-pa" type="checkbox" />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
