import React, { useCallback, useState } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { api, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { GlossaryForm } from "./GlossaryForm";

export const EditGlossaryModal = ({ onSave, termId, glossary, ...buttonProps }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleSave = useCallback(
    async (values) => {
      setError(null);
      try {
        const { term, definition, clinicalGlossaryAliases } = values;
        const currentAliases = glossary?.clinicalGlossaryAliases || [];
        const formAliases = _.filter(clinicalGlossaryAliases, "term"); // removes empty
        const [editAliases, newAliases] = _.partition(formAliases, "id"); // splits on whether there is an id
        const newTerms = _.flatMap(newAliases, "term");
        const currentTerms = _.flatMap(currentAliases, "term");
        const formIds = _.flatMap(formAliases, "id");

        // The list of elements removed
        const aliasesToDelete = currentAliases
          .filter((a) => {
            return !formIds.includes(a.id) && !newTerms.includes(a.term);
          })
          .map((alias) => {
            return { ...alias, _destroy: true };
          });

        // Double check the term is truly new and wasn't retyped
        const aliasesToAdd = newAliases.filter((a) => !currentTerms.includes(a.term));

        // Full List to update
        const updateAliases = _.concat(aliasesToAdd, editAliases, aliasesToDelete);

        const params = {
          clinicalGlossary: {
            term,
            definition,
            clinicalGlossaryAliasesAttributes: updateAliases,
          },
        };

        const { data } = await api.patch(`/admin/clinical_glossaries/${termId}`, snakifyKeys(params));

        if (data) {
          onSave(data.resource);
        }

        setOpen(false);
      } catch (err) {
        setError(extractErrorMessage(err));
      }
    },
    [onSave, termId, glossary]
  );

  return (
    <>
      <Button onClick={toggleModal} color="link" {...buttonProps}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader>Edit Glossary Term</ModalHeader>
        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form
            onSubmit={handleSave}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={glossary}
            keepDirtyOnReinitialize={true}
          >
            {(props) => <GlossaryForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
