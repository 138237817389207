import { useState } from "react";
import { createRoot } from "react-dom/client";
import { Alert } from "reactstrap";
import { AlertIcon } from "../components/AlertIcon";

import { FLASH_MESSAGE_KEY, FLASH_TYPE_KEY } from "../util/api";

const FlashApp = ({ messageType, message }) => {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  return (
    <>
      <Alert color={messageType} isOpen={visible} toggle={onDismiss} className={`rounded border border-${messageType}`}>
        <AlertIcon messageType={messageType} />
        <span className="text-secondary">{message}</span>
      </Alert>
    </>
  );
};

export const initFlashMessage = () => {
  const messageContainer = document.getElementById("react_flash_messages");

  if (messageContainer) {
    const root = createRoot(messageContainer);
    const message = window.localStorage.getItem(FLASH_MESSAGE_KEY);
    const messageType = window.localStorage.getItem(FLASH_TYPE_KEY);

    if (message) {
      window.localStorage.removeItem(FLASH_MESSAGE_KEY);
      window.localStorage.removeItem(FLASH_TYPE_KEY);

      root.render(<FlashApp message={message} messageType={messageType} />);
    }
  }
};
