import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";

export const CreatePayorModal = ({ onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);
    try {
      const res = await api.post("/admin/payors", { payor: snakifyKeys(values) });

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Payor
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Payor</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field component={TextInput} required validate={required} label="Payor Name" name="name" />
                  <Field type="checkbox" component={Switch} required label="Supports Paper?" name="paper" id="paper" />
                  <Field type="checkbox" component={Switch} required label="No Retro Auths?" name="noRetroAuths" id="noRetroAuths" />
                  <Field
                    type="checkbox"
                    component={Switch}
                    required
                    label="Ribbon Payor Enabled?"
                    name="ribbonPayorEnabled"
                    id="ribbonPayorEnabled"
                  />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
