import React from "react";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { EditClinicLabRedirectionModal } from "./EditClinicLabRedirectionModal";
import { History } from "../../tools/sandbox/release/History";

export const ClinicLabRedirectionActionCell = ({ row }) => {
  const [canDelete] = useDestroyPermission("ClinicLabRedirection", row.original.id);
  const [canUpdate] = useUpdatePermission("ClinicLabRedirection", row.original.id);

  const { refresh, setMessage, setError } = useTableContext();
  const { id, associatedLab } = row.original;

  const handleChange = (msg) => {
    setMessage(`Updated ${associatedLab.labName}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditClinicLabRedirectionModal color="link" onEdit={handleChange} clinicLabRedirection={row.original} />}
      {canDelete && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/clinic_lab_redirections/${id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted clinic lab redirection with lab "${associatedLab.labName}".`);
          }}
          onError={setError}
        />
      )}
      <History entityName={"ClinicLabRedirection"} subjectId={row.original.id} />
    </>
  );
};
