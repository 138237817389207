import React, { Fragment, useState } from "react";
import { Toolbar } from "../../../components/Toolbar";
import { Collapse, Container, Row, Table } from "reactstrap";
import { AddLabSftpSettingModal } from "./AddLabSftpSettingModal";
import { AddSftpDirectoryModal } from "./AddSftpDirectoryModal";
import { EditSftpDirectoryModal } from "./EditSftpDirectoryModal";
import { DetailedAlert } from "../../../components/DetailedAlert";
import { AdministrateToggle } from "../../components/AdministrateToggle";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { camelizeKeys } from "../../../util/helpers";
import { ChevronDown, ChevronUp } from "lucide-react";
import { EditLabSftpSettingModal } from "./EditLabSftpSettingModal";
import { useUpdatePermission } from "../../utils";

export const LabSftpSettings = ({ lab, refresh }) => {
  const [message, setMessage] = useState(null);
  const [canUpdateLab] = useUpdatePermission("Lab", lab.id);
  const [canUpdateSftp] = useUpdatePermission("SftpConnectionSetting");
  const canUpdate = canUpdateLab || canUpdateSftp;

  const [open, setOpen] = useState(null);
  const accordion = (id) => {
    open === id ? setOpen() : setOpen(id);
  };

  const onAction = (results) => {
    setMessage(results.meta.message);
    refresh();
  };

  return (
    <>
      {canUpdate && (
        <Toolbar className="align-items-center mb-3">
          <Toolbar className="ms-auto">
            <AddLabSftpSettingModal onAdd={onAction} labId={lab.id} />
          </Toolbar>
        </Toolbar>
      )}

      {message && <DetailedAlert message={message} messageStyle="info" alertLinkClass="alert-link ms-2" />}

      <Table>
        <thead>
          <tr>
            <th>Host:Port</th>
            <th>Number of Sweeps</th>
            <th>Sweep Enabled</th>
            {canUpdate && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {lab.sftpConnectionSettings.map((sftpSettings) => {
            const sftp = camelizeKeys(sftpSettings);
            return (
              <Fragment key={sftp.id}>
                <tr>
                  <td onClick={() => accordion(sftp.id)}>
                    {open === sftp.id ? <ChevronDown /> : <ChevronUp />}
                    {sftp.host}:{sftp.port}
                  </td>
                  <td>{sftp.sweeps}</td>
                  <td>
                    <AdministrateToggle
                      id={`sftp-toggle-${sftp.id}`}
                      toggle={sftp.enabled}
                      endpoint={`/admin/labs/${lab.id}/toggle_sftp_setting`}
                      params={(toggle) => {
                        return { labSftpSetting: { id: sftp.id, enabled: toggle } };
                      }}
                      onChange={setMessage}
                      onError={setMessage}
                      disabled={!canUpdate}
                    />
                  </td>
                  {canUpdate && (
                    <td>
                      <AddSftpDirectoryModal labId={lab.id} sftp={sftp} onAdd={onAction} />
                      <EditLabSftpSettingModal labId={lab.id} sftp={sftp} onEdit={onAction} />
                      <AdministrateResourceDeleteButton
                        deleteUrl={`/admin/labs/${lab.id}/sftp_settings/${sftp.id}`}
                        onError={(err) => setError(extractErrorMessage(err))}
                        onSuccess={(results) => {
                          setMessage(`Successfully removed SFTP Setting ${sftp.host}`);
                          refresh();
                        }}
                      />
                    </td>
                  )}
                </tr>
                <tr>
                  <td colSpan="4">
                    <Collapse isOpen={open === sftp.id}>
                      <Table>
                        <thead>
                          <tr>
                            <th>Folder Path</th>
                            <th>Directory Enabled</th>
                            <th>Tilde Delimited File</th>
                            <th>Keep File in Directory</th>
                            {canUpdate && <th>Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {sftp.sftpDirectories.map((directorySnake) => {
                            const directory = camelizeKeys(directorySnake);
                            return (
                              <tr key={directory.id}>
                                <td>{directory.folderPath}</td>
                                <td>
                                  <AdministrateToggle
                                    id={`sftp-directory-toggle-${directory.id}`}
                                    toggle={directory.enabled}
                                    endpoint={`/admin/labs/${lab.id}/sftp_directories/${directory.id}`}
                                    params={(toggle) => {
                                      return { labSftpDirectory: { id: directory.id, enabled: toggle } };
                                    }}
                                    onChange={setMessage}
                                    onError={setMessage}
                                    disabled={!canUpdate}
                                  />
                                </td>
                                <td>
                                  <AdministrateToggle
                                    id={`sftp-directory-tilde-toggle-${directory.id}`}
                                    toggle={directory.tildeDelimitedFile}
                                    endpoint={`/admin/labs/${lab.id}/sftp_directories/${directory.id}`}
                                    params={(toggle) => {
                                      return { labSftpDirectory: { id: directory.id, tilde_delimited_file: toggle } };
                                    }}
                                    onChange={setMessage}
                                    onError={setMessage}
                                    disabled={!canUpdate}
                                  />
                                </td>
                                <td>
                                  <AdministrateToggle
                                    id={`sftp-directory-keep-file-toggle-${directory.id}`}
                                    toggle={directory.keepFileInDirectory}
                                    endpoint={`/admin/labs/${lab.id}/sftp_directories/${directory.id}`}
                                    params={(toggle) => {
                                      return { labSftpDirectory: { id: directory.id, keep_file_in_directory: toggle } };
                                    }}
                                    onChange={setMessage}
                                    onError={setMessage}
                                    disabled={!canUpdate}
                                  />
                                </td>
                                {canUpdate && (
                                  <td>
                                    <EditSftpDirectoryModal onEdit={onAction} labId={lab.id} sftp={sftp} directory={directory} />
                                    <AdministrateResourceDeleteButton
                                      deleteUrl={`/admin/labs/${lab.id}/sftp_directories/${directory.id}`}
                                      onError={(err) => setError(extractErrorMessage(err))}
                                      onSuccess={(results) => {
                                        setMessage(`Successfully removed directory ${directory.folderPath}`);
                                        refresh();
                                      }}
                                    />
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Collapse>
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
