import Case, { pascal } from "case";
import * as icons from "lucide-react";

const Icon = ({ name, color, size, className, strokeWidth }) => {
  const pascalName = Case.pascal(name);
  const LucideIcon = icons[pascalName];

  if (!LucideIcon) {
    console.warn(`[ICONS] Tried to render unknown icon: ${name}`);
    return null;
  }

  return <LucideIcon color={color} size={size} className={className} strokeWidth={strokeWidth} />;
};

export default Icon;
