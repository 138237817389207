import { extractErrorMessage } from "../../../util/api";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { useTableContext } from "../../components/AdministrateTable";
import { EditPayorNoteModal } from "./EditPayorNoteModal";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";

export const PayorNoteActionCell = ({ payorId, ...row }) => {
  const [canDestroy] = useDestroyPermission("PayorNote", row.original.id);
  const [canUpdate] = useUpdatePermission("PayorNote", row.original.id);

  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = () => {
    setMessage(`Updated Payor Note ${row.original.id}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditPayorNoteModal color="link" onUpdate={handleChange} payorId={payorId} note={row.original} />}

      {canDestroy && (
        <AdministrateResourceDeleteButton
          label="Delete"
          deleteUrl={`/admin/payor_notes/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Payor Note ${row.original.id} deleted.`);
          }}
          onError={(msg) => setError(extractErrorMessage(msg))}
        />
      )}
    </>
  );
};
