import React from "react";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";

export const ImportTestAliasesModal = ({ onImport, labId, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      lab_id: labId,
      file: {
        bytes: values.file,
      },
    };
    const res = await api.post("/admin/lab_test_mappings/import.json", snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message, res.data?.meta?.error_details);
    }
  };

  return (
    <ImportModal
      buttonLabel="Import Test Aliases"
      buttonProps={rest}
      onImport={handleImport}
      title="Import Test Aliases..."
      exampleDownloadPath="/admin/lab_test_mappings/example"
    />
  );
};
