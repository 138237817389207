import React from "react";
import { ActionAlert } from "../../../../components/ActionAlert";
import { Button } from "reactstrap";
import { formatDate } from "../../../../util/formatters";
import { WizardContext } from "../../WizardContext";

export const MedicalReviewPeerToPeerNotRequiredBranch = (props) => (
  <WizardContext.Consumer>
    {({ state, nextStepUrl, dispatch }) => (
      <ActionAlert
        color="success"
        actions={
          <Button href={nextStepUrl} color="success">
            Continue
          </Button>
        }
      >
        Continue with workflow to finalize Case.
      </ActionAlert>
    )}
  </WizardContext.Consumer>
);
