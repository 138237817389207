import { useCallback } from "react";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { Toolbar } from "../../../components/Toolbar";
import { formatCentsAsDollars } from "../../../util/formatters";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateExportButton } from "../../components/AdministrateExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreatePayorGroupFeeScheduleModal } from "../components/CreatePayorGroupFeeScheduleModal";
import { ImportPayorGroupMedicaidScheduleModal } from "../components/ImportPayorGroupMedicaidScheduleModal";
import { PayorGroupFeeScheduleActionCell } from "../components/PayorGroupFeeScheduleActionCell";

const useColumns = makeColumns((payorGroupId) => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
  },
  {
    id: "cpt_code.code",
    accessor: "cptCode.code",
    Header: "CPT Code",
    Cell: TokenCell,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" isMulti valueKey="code" labelKey="code" />,
  },
  {
    id: "price_cents",
    accessor: "priceCents",
    Header: "Price",
    Cell: ({ value }) => <span>{formatCentsAsDollars(value)}</span>,
    disableFilters: true,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: PayorGroupFeeScheduleActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PayorGroupsMedicaidFeeScheduleTab = ({ payorGroup, payorGroupId }) => {
  const [canCreate] = useCreatePermission("MedicaidFeeSchedule");

  const columns = useColumns(payorGroupId);

  const extraParams = { payor_group: { id: payorGroupId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onImport = useCallback(
    (message, details = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, details.length ? "warning" : "success", details);
    },
    [tableRef]
  );

  const onCreate = (newFeeSchedule) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created Medicaid Fee Schedule ${newFeeSchedule.id}`);
    refresh();
  };

  return (
    <SystemConfigLayout title="Medicaid Fee Schedules">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateExportButton outline className="me-2" exportUrl="/admin/medicaid_fee_schedules.csv" filters={allFilters} />
          {canCreate && (
            <>
              <ImportPayorGroupMedicaidScheduleModal payorGroup={payorGroup} outline className="me-2" onImport={onImport} />
              <CreatePayorGroupFeeScheduleModal onCreate={onCreate} payorGroupId={payorGroupId} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/medicaid_fee_schedules.json"}
        extraParams={extraParams}
        permissions={[DESTROY, UPDATE]}
        resourceName={"medicaid_fee_schedule"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
