import { AnimatePresence, motion } from "framer-motion";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useState } from "react";
import { Alert, Button } from "reactstrap";
import { useImmerReducer } from "use-immer";
import { DetailedErrorAlert } from "../../components/DetailedErrorAlert";
import { api, redirectTo } from "../../util/api";
import { useDeepCompareEffect, useLocalStorage } from "../../util/hooks";
import { WizardContext } from "./WizardContext";
import { eightHours, trackFinish, useTrackEvents, useWizardReducer, redirectToStep } from "./wizard_utils";

export const Wizard = ({ name, caseId, steps, children, initialAnswers, initialStep, nextStepUrl, loading = false, ...props }) => {
  const [error, setError] = useState(null);
  const [internalLoading, setInternalLoading] = useState(null);
  const [reducer, initialState] = useWizardReducer(steps, initialStep, initialAnswers);

  const stored = useLocalStorage(`${name}:${caseId}`, initialState, eightHours);
  const currentState = _.merge({}, initialState, stored.value);

  const [state, dispatch] = useImmerReducer(reducer, currentState);

  useTrackEvents(caseId, name, state);
  useDeepCompareEffect(() => stored.set(state), [state]);

  function goBack() {
    dispatch({ type: "goback" });
  }

  async function reset() {
    if (confirm("Are you sure?")) {
      dispatch({ type: "reset" });
      trackFinish(caseId, name, "Wizard Reset");
    }
  }

  async function updateStatus(status, substatus, redirect = true) {
    setError(null);
    setInternalLoading(true);

    try {
      // TODO: this might need genericizing for other workflows
      await api.post(props.statusUrl, {
        workflow: "prior_auth_request",
        status,
        substatus,
      });

      if (redirect) {
        stored.clear();
        redirectTo("/workflow");
      }
    } catch (err) {
      console.error(err);
      setError("Error while updating status");
    } finally {
      setInternalLoading(false);
    }
  }

  async function updateStep(step, redirect = true) {
    setError(null);
    setInternalLoading(true);

    try {
      await api.post(props.moveToStepUrl, { step });

      if (redirect) {
        stored.clear();
        redirectTo("/workflow");
      }
    } catch (err) {
      console.error(err);
      setError("Error while updating workflow step");
    } finally {
      setInternalLoading(false);
    }
  }

  async function createFollowupTask(days, title, doRedirect = true, message = "", doSnooze = true) {
    setError(null);
    setInternalLoading(true);

    try {
      const followupMsg = `More following required in ${days} day${days != 1 ? "s" : ""}`;

      let followupPromises = [];
      followupPromises.push(api.post(props.tasksUrl, { title, due_date: moment().add(days, "days") }));

      if (doSnooze) {
        followupPromises.push(api.post(props.snoozeUrl));
      }

      await Promise.all([trackFinish(caseId, name, followupMsg), Promise.all(followupPromises)]);

      if (doRedirect) {
        stored.clear();
        redirectTo("/workflow", message);
      }
    } catch (err) {
      console.error(err);

      if (err.response) {
        const errors = _.get(err, "response.data.errors", []);
        setError(`Error while creating followup task: ${errors.join(" ")}`);
      } else {
        setError("Error while creating followup task");
      }
    } finally {
      setInternalLoading(false);
    }
  }

  async function unassignCase() {
    setError(null);
    setInternalLoading(true);

    try {
      await api.post(props.assigneeUrl, {});
    } catch (err) {
      console.error(err);
      setError("Error while unassigning this case");
    } finally {
      setInternalLoading(false);
    }
  }

  async function exitWorkflow(message = `Followup completed for ${caseId}`) {
    setError(null);
    setInternalLoading(true);

    try {
      await trackFinish(caseId, name, "Followup Complete");
      stored.clear();
      redirectTo("/workflow", message);
    } catch (err) {
      console.error(err);
      setInternalLoading(false);
    }
  }

  async function continueWorkflow() {
    setError(null);
    setInternalLoading(true);

    try {
      await trackFinish(caseId, name, "Followup Complete");
      stored.clear();
      redirectTo(nextStepUrl);
    } catch (err) {
      console.error(err);
      setInternalLoading(false);
    }
  }

  async function continueWithStep(workflow, step, message) {
    setError(null);
    setInternalLoading(true);

    try {
      await trackFinish(caseId, name, "Followup Complete");
      stored.clear();
      redirectToStep(workflow, step, caseId, message);
    } catch (err) {
      console.error(err);
      setInternalLoading(false);
    }
  }

  async function createComment(content, isPublic = false) {
    setError(null);
    setInternalLoading(true);

    try {
      await api.post(props.commentsUrl, { content, public: isPublic });
    } catch (err) {
      console.error(err);
      setError("Error while adding comment");
    } finally {
      setInternalLoading(false);
    }
  }

  const { currentStep, history } = state;
  const Component = steps[currentStep].Component;
  const wizardContext = {
    ...props,
    caseId,
    state,
    dispatch,
    loading: internalLoading || loading,
    setLoading: setInternalLoading,
    error,
    goBack,
    reset,
    setError,
    updateStatus,
    updateStep,
    createFollowupTask,
    unassignCase,
    exitWorkflow,
    continueWorkflow,
    continueWithStep,
    createComment,
  };

  return (
    <WizardContext.Provider value={wizardContext}>
      <DetailedErrorAlert error={error} />

      <div className="mb-3">
        <Button size="sm" className="me-2" outline onClick={goBack} disabled={history.length < 1}>
          Back
        </Button>

        <Button size="sm" outline onClick={reset} disabled={_.isEqual(initialState, state)}>
          Reset Wizard
        </Button>
      </div>

      <div>
        <AnimatePresence mode="wait">
          <motion.div key={currentStep} initial={{ x: 300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -300, opacity: 0 }}>
            <Component />
          </motion.div>
        </AnimatePresence>
      </div>
    </WizardContext.Provider>
  );
};
