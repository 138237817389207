import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import { LoadingButton } from "../../../../components/LoadingButton";
import { api } from "../../../../util/api";
import { WizardContext } from "../../WizardContext";
import { DocumentReviewModal } from "./DocumentReviewModal";
import { numMissingFields } from "../../MissingInfoFollowupWizard";

export const MissingInfoDocumentsScreen = () => {
  const [loading, setLoading] = useState(false);

  return (
    <WizardContext.Consumer>
      {({ dispatch, miInfoUrl, caseId, caseData, caseUrl, labTests, missingFields }) => {
        const handleNext = async () => {
          setLoading(true);

          try {
            const response = await api.get(miInfoUrl);

            dispatch({
              type: "setAnswer",
              key: "missingInfoAnswered",
              value: true,
              doTransition: false,
            });

            dispatch({
              type: "setAnswer",
              key: "missingInfoFields",
              value: response.data,
              doTransition: true,
            });
          } catch (err) {
            console.error(err);
          } finally {
            setLoading(false);
          }
        };

        return (
          <Card>
            <CardBody className="mt-5 text-center">
              <h5>Review Documents for Missing Information</h5>
              <ul className="list-inline">
                <li className="list-inline-item text-warning">
                  <strong>{numMissingFields(missingFields)}</strong> fields are missing
                </li>
                <li className="list-inline-item">
                  <strong>{caseData?.supplementalDocuments?.length ?? 0}</strong> documents are available for review
                </li>
              </ul>
            </CardBody>

            <CardBody className="mb-5 text-center">
              <DocumentReviewModal
                caseId={caseId}
                caseData={caseData}
                caseUrl={caseUrl}
                labTests={labTests}
                afterSave={handleNext}
                buttonText={"Review Documents"}
                buttonProps={{
                  disabled: loading,
                  className: "me-2",
                  style: { minWidth: 200 },
                  color: "primary",
                }}
              />

              <LoadingButton
                outline
                style={{ minWidth: 200 }}
                onClick={() => {
                  dispatch({ type: "gonext" });
                }}
                loading={loading}
              >
                Continue Without Review
              </LoadingButton>
            </CardBody>
          </Card>
        );
      }}
    </WizardContext.Consumer>
  );
};
