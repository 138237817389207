import { Container, Row } from "reactstrap";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { Toolbar } from "../../../components/Toolbar";
import { useCallback } from "react";
import { DESTROY, UPDATE, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { AdministrateTable } from "../../components/AdministrateTable";
import { AddSuperDialMessageModal } from "./components/AddSuperDialMessageModal";
import { SuperDialMessageActionCell } from "./components/SuperDialMessageActionCell";
import { BoolCell } from "../../../components/grid/cells/BoolCell";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { EnumFilter } from "../../../components/inputs/EnumFilter";
import { StatusDisplayer, SubstatusDisplayer } from "../../submit_right/phone_responses/PhoneResponsesIndex";

const useColumns = makeColumns(() => [
  {
    id: "message_regex",
    accessor: "messageRegex",
    Header: "Super Dial Message",
  },
  {
    id: "assign_to_followup",
    accessor: "assignToFollowup",
    Header: "Assign to Follow Up",
    Cell: BoolCell,
    Filter: (props) => (
      <EnumFilter
        {...props}
        options={[
          { value: true, label: "Yes" },
          { value: false, label: "No" },
        ]}
      />
    ),
  },
  {
    id: "status",
    accessor: "status",
    Header: "Status",
    Cell: (props) => <StatusDisplayer status={props.value || "none"} />,
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/enums/prior_auth_statuses.json"
        valueKey="id"
        labelKey="label"
        searchParam="search"
      />
    ),
  },
  {
    id: "substatus",
    accessor: "substatus",
    Header: "Sub Status",
    Cell: (props) => <SubstatusDisplayer substatus={props.value || "none"} />,
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/enums/prior_auth_substatuses.json"
        valueKey="value"
        labelKey="label"
        searchParam="search"
      />
    ),
  },
  {
    id: "denial_reason_code",
    accessor: "denialReason",
    Header: "Denial Reason",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/enums/denial_reason_options.json"
        valueKey="id"
        labelKey="label"
        searchParam="search"
      />
    ),
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: SuperDialMessageActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const SuperDialMessages = () => {
  const { tableRef, setTableFilters } = useAdministrateTable();
  const columns = useColumns();
  const [canCreate] = useCreatePermission("SuperDialMessage");

  const onAdd = useCallback(
    (message, errorDetails = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, errorDetails.length ? "warning" : "success", errorDetails);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Super Dial Messages">
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Toolbar className="ml-auto">{canCreate && <AddSuperDialMessageModal onAdd={onAdd} color="primary" />}</Toolbar>
        </Row>
      </Container>

      <AdministrateTable
        resourceUrl={"/admin/super_dial_messages.json"}
        resourceName={"super_dial_message"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
