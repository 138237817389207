import React from "react";
import { Field } from "react-final-form";
import { required } from "../../../util/validators";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";

export const LabNoteForm = ({ handleSubmit, submitting }) => {
  return (
    <>
      <Field component={TextInput} type="textarea" required validate={required} label="Note" name="note" />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
