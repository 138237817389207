import pluralize from "pluralize";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { BoolCell } from "../../../components/grid/cells/BoolCell";
import { CountCell } from "../../../components/grid/cells/CountCell";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { EnumFilter } from "../../../components/inputs/EnumFilter";
import { Switch } from "../../../components/inputs/Switch";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { Routes } from "../../Routes";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreateStatusMappingModal } from "./components/CreateStatusMappingModal";
import { ImportStatusMappingsModal } from "./components/ImportStatusMappingsModal";
import { StatusMappingActionCell } from "./components/StatusMappingActionCell";
import { History } from "../../tools/sandbox/release/History";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    Cell: TokenCell,
  },
  {
    id: "lab",
    accessor: "lab",
    Header: "Lab",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/labs.json?lab[direction]=asc&lab[order]=lab_name&per_page=100"
        valueKey="lab_name"
        labelKey="lab_name"
      />
    ),
    Cell: ({ row, value }) =>
      value ? (
        <Link to={Routes.lab.url(value.id)}>
          <span className="token">{value.labName}</span>
        </Link>
      ) : (
        <span className="token">ALL LABS</span>
      ),
  },
  {
    id: "caseType",
    accessor: (x) => {
      switch (x.caseType) {
        case "type_prior_auth":
          return "Prior Auth";
        case "type_benefits_investigation":
          return "Benefits Investigation";
        default:
          return "";
      }
    },
    Header: "Case Type",
    Filter: (props) => (
      <EnumFilter
        {...props}
        options={[
          { value: "prior_auth", label: "Prior Auth" },
          { value: "benefits_investigation", label: "Benefits Investigation" },
        ]}
      />
    ),
  },
  {
    id: "payor",
    accessor: "payor.name",
    Header: "Payor",
    disableSortBy: true,
    Cell: ({ row, value }) =>
      value ? (
        <Link to={Routes.payor.url(row.original.payor?.id)}>
          <span className="token">{value}</span>
        </Link>
      ) : (
        <span className="token">ALL PAYORS</span>
      ),
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" isMulti valueKey="id" labelKey="name" searchParam="name" />
    ),
  },
  {
    id: "status",
    accessor: "status",
    Header: "Status",
    disableSortBy: true,
  },
  {
    id: "substatus",
    accessor: "substatus",
    Header: "Substatus",
    disableSortBy: true,
  },
  {
    id: "cpt_codes",
    accessor: (x) => x.cptCodes.length,
    Header: "CPT Codes",
    Cell: ({ value }) => <CountCell count={value} label="CPT Codes" />,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" isMulti valueKey="code" labelKey="code" />,
  },
  {
    id: "tests",
    accessor: (x) => x.tests.length,
    Header: "Verified Test Names",
    Cell: ({ value }) => <CountCell count={value} label="Tests" />,
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/tests.json?test[direction]=asc&test[order]=commercial_name"
        isMulti
        valueKey="commercial_name"
        labelKey="commercial_name"
      />
    ),
  },
  {
    id: "group_id",
    accessor: "groupId",
    Header: "Group ID",
    disableSortBy: true,
  },
  {
    id: "request_submitted",
    accessor: "requestSubmitted",
    Header: "Request Submitted",
    Cell: ({ value }) => <BoolCell value={value} nullable />,
    Filter: (props) => <BoolFilter {...props} />,
  },
  {
    id: "discard_case",
    accessor: "discardCase",
    Header: "Discard Case",
    Cell: ({ value }) => <BoolCell value={value} />,
    Filter: (props) => <BoolFilter {...props} />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: StatusMappingActionCell,
    disableFilters: true,
    disableSortBy: true, //action cell
  },
]);

export const StatusMappingIndexPage = () => {
  const [canCreate] = useCreatePermission("StatusMapping");
  const [showArchivedTests, setShowArchivedTests] = useState(false);
  const columns = useColumns();
  const extraParams = { archived: showArchivedTests };

  const toggleArchivedTestFilter = useCallback(() => setShowArchivedTests((x) => !x), [setShowArchivedTests]);

  const onCreate = (newMappings) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created ${pluralize("mapping", newMappings.length, true)}`);
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  return (
    <SystemConfigLayout title="Status Mappings">
      <Toolbar className="align-items-center mb-3">
        <Toolbar>
          <Switch
            id="archived-tests-toggle"
            label="Include Archived Tests"
            checked={showArchivedTests === true}
            onChange={toggleArchivedTestFilter}
          />
        </Toolbar>
        {canCreate && (
          <Toolbar className="ms-auto">
            <History entityName="StatusMapping" className="me-2" />
            <AdministrateBackgroundExportButton
              outline
              className="me-2"
              exportUrl="/admin/status_mappings/export"
              filters={allFilters}
              ref={tableRef}
            />
            <ImportStatusMappingsModal outline className="me-2" onImport={onImport} />
            <CreateStatusMappingModal color="primary" onCreate={onCreate} />
          </Toolbar>
        )}
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/status_mappings.json"}
        extraParams={extraParams}
        resourceName={"status_mapping"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
        permissions={[UPDATE, DESTROY]}
      />
    </SystemConfigLayout>
  );
};
