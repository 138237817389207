import React from "react";
import _ from "lodash";
import { Input, DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from "reactstrap";
import { uniqid } from "../utils";

const PLACEMENT_OFFSET = 40;
const INITIAL_HEIGHT = 15;
const INITIAL_WIDTH = 150;

export const newField = (label, type, field = "", page = 0) => ({
  id: uniqid(),
  label,
  type,
  field,
  position: {
    page,
    at: {
      x: PLACEMENT_OFFSET,
      y: PLACEMENT_OFFSET,
    },
  },
  options: {
    height: INITIAL_HEIGHT,
    width: INITIAL_WIDTH,
  },
});

export const buildAddButton = (label, type, key, page, onClick) => {
  return (
    <DropdownItem key={key} onClick={() => onClick(newField(label, type, key, page))}>
      <code>{label}</code>
    </DropdownItem>
  );
};

const typeMap = {
  string: "text",
  boolean: "checkmark",
  date: "date",
};

export const dataTypeToFieldType = (dataType) => typeMap[dataType] || "text";

export class AddFieldDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);

    this.state = {
      filter: "",
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState((prevState) => {
      // Get new state
      const dropdownOpen = !prevState.dropdownOpen;

      // Clear the filter on close
      const filter = dropdownOpen === false ? "" : prevState.filter;

      return {
        dropdownOpen,
        filter,
      };
    });
  }

  onFilterChange(e) {
    e.persist();
    this.setState({ filter: e.target.value });
  }

  render() {
    const { currentPage, onAddField, defaultFields, customFields } = this.props;
    const { dropdownOpen, filter } = this.state;

    // Filter Data
    let filteredDefaultFields = _.pickBy(defaultFields, (f) => _.includes(f.label.toLowerCase(), filter.toLowerCase()));

    let filteredCustomFields = _.pickBy(customFields, (f) => _.includes(f.label.toLowerCase(), filter.toLowerCase()));

    // Setup Buttons
    const defaultFieldButtons = _.map(filteredDefaultFields, (f, key) => {
      return buildAddButton(f.label, dataTypeToFieldType(f.type), key, currentPage, onAddField);
    });

    const customFieldButtons = _.map(filteredCustomFields, (f, key) => {
      return buildAddButton(f.label, dataTypeToFieldType(f.type), `custom:${key}`, currentPage, onAddField);
    });

    // Render Component

    return (
      <Dropdown isOpen={dropdownOpen} toggle={this.toggle} className="w-100">
        <DropdownToggle size="sm" className="btn-block" caret>
          Add Field
        </DropdownToggle>
        <DropdownMenu style={{ height: 300, overflowY: "auto", width: "100%" }}>
          <div className="mx-2">
            <Input autoFocus bsSize="sm" placeholder="Filter..." onChange={this.onFilterChange} />
          </div>
          <DropdownItem divider />

          <DropdownItem header>Generic Fields</DropdownItem>
          <DropdownItem onClick={() => onAddField(newField("New Text Field", "text"))}>
            <code>Text</code>
          </DropdownItem>
          <DropdownItem onClick={() => onAddField(newField("New Checkbox", "checkmark"))}>
            <code>Checkbox</code>
          </DropdownItem>

          <DropdownItem header>Default Fields</DropdownItem>
          {defaultFieldButtons ? defaultFieldButtons : <DropdownItem disabled>No Fields</DropdownItem>}

          <DropdownItem divider />
          <DropdownItem header>Custom Fields</DropdownItem>
          {customFieldButtons ? customFieldButtons : <DropdownItem disabled>No Custom Fields</DropdownItem>}
        </DropdownMenu>
      </Dropdown>
    );
  }
}
