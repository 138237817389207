import React from "react";

export const BillingReportsActionCell = ({ row }) => {
  return (
    <>
      <a href={`/exports/${row.original.id}`} target="_blank" rel="noopener noreferrer">
        Download
      </a>
    </>
  );
};
