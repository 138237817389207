import _ from "lodash";
import React from "react";
import { ArchiveButton } from "./ArchiveButton";
import { FixNowButton } from "./FixNowButton";

export const DashboardActionsCell = ({ row }) => {
  const requestId = _.get(row.original, "pa_request.RequestId");
  const archived = _.get(row.original, "archived");
  const canFixNow = _.get(row.original, "user_actionable_mi", false);

  return (
    <>
      <ArchiveButton id={requestId} archived={archived} />
      {canFixNow && <FixNowButton id={requestId} className="me-1" />}
    </>
  );
};
