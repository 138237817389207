import React, { useMemo } from "react";
import { Alert } from "reactstrap";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { TabbedRoutes } from "../../components/TabbedRoutes";
import { EvaIngestionForm } from "./EvaIngestionForm";

const useTabs = () =>
  useMemo(
    () => [
      {
        path: "eva",
        label: "Eva",
        Component: EvaIngestionForm,
      },
    ],
    []
  );

export const IngestionPage = () => {
  const tabs = useTabs();

  return (
    <SystemConfigLayout title={["Tools", "Eva"]}>
      <TabbedRoutes tabs={tabs} defaultTab={"eva"} />
    </SystemConfigLayout>
  );
};
