import $ from "jquery";
import _ from "lodash";

const getValidStatuses = (successValue, allStatuses) => {
  if (successValue == "Yes") {
    return _.pickBy(allStatuses, (statusDefinition, _name) => {
      return statusDefinition.allowed_for_successful_submission == true;
    });
  } else {
    return allStatuses;
  }
};

const $emptyStatusOption = $('<option value=""></option>').text("-- Select a Status --");

export function initStatusSelect() {
  const $successSelector = $(".submission_success_dropdown");

  if ($successSelector.length > 0) {
    $successSelector.each(function () {
      attachStatusSelect(this);
    });
  }
  $(document).on("repeater.add", function (_e, $new) {
    attachStatusSelect($new);
  });
}

function attachStatusSelect(container) {
  const $container = $(container);
  const $successForm = $container.parents("form");
  const $successDropdown = $(".request_submission_success", $container);
  const $statusDropdown = $(".request_status", $container);
  const $statusParent = $statusDropdown.parent();
  const availableStatuses = $statusDropdown.data("allStatuses");

  $successDropdown.on("change", function () {
    const currentSuccess = $successDropdown.val();
    const currentStatus = $statusDropdown.val();

    const validStatuses = getValidStatuses(currentSuccess, availableStatuses);

    $statusDropdown.empty();
    $statusDropdown.append($emptyStatusOption);

    $statusParent.show();

    // Add options for all valid statuses
    _.forEach(validStatuses, (statusDefinition, key) => {
      const { name = key } = statusDefinition;

      const $newOption = $(`<option></option>`, { value: key }).text(name);

      $statusDropdown.append($newOption);
    });

    if (currentSuccess != null && currentSuccess !== "") {
      $(".submit-button", $successForm).attr("disabled", false);
    }

    // Preserve selection
    $statusDropdown.val(currentStatus);
  });

  $successDropdown.trigger("change");
}
