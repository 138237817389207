export const GLOBAL_ROLES = {
  no_role: "No Role",
  admin: "Admin",
  sales_manager: "Account Manager",
  operations: "Operations",
  operations_supervisor: "Supervisor",
  clinical_operations: "Clinical Operations",
  system: "System",
};

export const LAB_ROLES = {
  no_role: "No Role",
  member: "Member",
  ops: "Ops",
  admin: "Admin",
  limited: "Limited",
};

export const CLINIC_ROLES = {
  no_role: "No Role",
  member: "Member",
};
