import React from "react";
import { useTableContext } from "../../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useDestroyPermission, useUpdatePermission } from "../../../utils";
import { EditCaseTipModal } from "./EditCaseTipModal";
import { History } from "../../../tools/sandbox/release/History";

export const CaseTipActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("CaseTip", row.original.id);
  const [canUpdate] = useUpdatePermission("CaseTip", row.original.id);

  return (
    <>
      {canUpdate && (
        <EditCaseTipModal
          color="link"
          caseTip={row.original}
          onUpdate={(newTip) => {
            refresh();
            setMessage(`Updated Case Tip.`);
          }}
        />
      )}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/case_tips/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Case Tip.`);
          }}
          onError={setError}
        />
      )}
      <History entityName="CaseTip" subjectId={row.original.id} />
    </>
  );
};
