import React, { useState } from "react";
import { Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { useUpdatePermission } from "../../../utils";
import { UiPathBotAutomationForm } from "./UiPathBotAutomationForm";
import { UiPathBotAutomationReadOnly } from "./UiPathBotAutomationReadOnly";

export const EditUiPathBotAutomationModal = ({ automation, onEdit, ...rest }) => {
  const [canUpdate] = useUpdatePermission("UiPathPortalConfiguration", automation.id);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (params) => {
    setError(null);
    try {
      const res = await api.patch(`/admin/ui_path_portal_configurations/${automation.id}`, {
        ui_path_portal_configuration: snakifyKeys(params),
      });

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const mode = canUpdate ? "Edit" : "View";

  const initialValues = {
    payorIds: automation.payors.map((p) => p.id),
    benefitManagementPortalId: automation.benefitManagementPortal.id,
    uiPathBotType: automation.uiPathBotType,
    uiPathFolderName: automation.uiPathFolderName,
    uiPathReleaseName: automation.uiPathReleaseName,
    enabled: automation.enabled,
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        {mode}
      </Button>

      <Modal size="xl" isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {mode} UiPath Bot Automation {automation.id}
        </ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          {canUpdate ? (
            <Form onSubmit={handleEdit} initialValues={initialValues}>
              {(props) => <UiPathBotAutomationForm automation={automation} {...props} />}
            </Form>
          ) : (
            <UiPathBotAutomationReadOnly automation={automation} />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
