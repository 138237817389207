import { Field } from "react-final-form";
import { LoadingButton } from "../../../../components/LoadingButton";
import { BlankableField } from "../../../../components/inputs/final_form/BlankableField";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { required } from "../../../../util/validators";
import { Switch } from "../../../../components/inputs/final_form/Switch";

export const MissingInfoCategoryForm = ({ handleSubmit, submitting }) => {
  return (
    <>
      <BlankableField component={TextInput} required validate={required} label="Name" name="name" />

      <Field
        className="mb-3"
        component={Switch}
        type="checkbox"
        defaultValue={false}
        required
        validate={required}
        label="Active"
        name="active"
      />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Save
      </LoadingButton>
    </>
  );
};
