import React, { useCallback, useContext, useEffect } from "react";
import _ from "lodash";
import { useImmerReducer } from "use-immer";

const PermissionContext = React.createContext({});

export const usePermissions = () => useContext(PermissionContext);

const INITIAL_PERMISSION_STATE = {
  globalPermissions: {},
  resourcePermissions: {},
};

const INSERT_GLOBAL_PERMISSION = "INSERT_GLOBAL_PERMISSION";
const INSERT_RESOURCE_PERMISSION = "INSERT_RESOURCE_PERMISSION";
const INSERT_INSTANCE_PERMISSION = "INSERT_INSTANCE_PERMISSION";

const defaultResourceObj = (resourceName) => ({
  resource: resourceName,
  abilities: {},
  instances: {},
});

const defaultInstanceAbility = (instanceId) => ({
  id: instanceId,
  abilities: {},
});

const insertIfNotExists = (obj, path, value) => {
  const exists = _.has(obj, path);
  if (!exists) {
    _.set(obj, path, value);
  }
};

const permissionReducer = (draft, action) => {
  const { resourceName, instanceId, ability } = action.payload;

  if (resourceName) {
    insertIfNotExists(draft.resourcePermissions, resourceName, defaultResourceObj(resourceName));
  }

  switch (action.type) {
    case INSERT_GLOBAL_PERMISSION:
      _.set(draft.globalPermissions, ability.ability, ability);
      break;
    case INSERT_RESOURCE_PERMISSION:
      _.set(draft.resourcePermissions, `${resourceName}.abilities.${ability.ability}`, ability);
      break;
    case INSERT_INSTANCE_PERMISSION:
      const instancePath = `${resourceName}.instances.${instanceId}`;
      insertIfNotExists(draft.resourcePermissions, instancePath, defaultInstanceAbility(instanceId));

      _.set(draft.resourcePermissions, `${instancePath}.abilities.${ability.ability}`, ability);
      break;
  }
};

const CHECK_PERMISSION = "check";

export const PermissionContextProvider = ({ children }) => {
  const [permissionState, dispatch] = useImmerReducer(permissionReducer, INITIAL_PERMISSION_STATE);

  const insertGlobalPermission = useCallback(
    ({ permissionName, permitted }) => {
      const ability = { ability: permissionName, permitted: Boolean(permitted) };
      dispatch({ type: INSERT_GLOBAL_PERMISSION, payload: { ability } });
    },
    [dispatch]
  );

  const insertResourcePermission = useCallback(
    ({ resourceName, permissionName, permitted }) => {
      const ability = {
        ability: permissionName,
        permitted: permitted === CHECK_PERMISSION ? CHECK_PERMISSION : Boolean(permitted),
      };
      dispatch({ type: INSERT_RESOURCE_PERMISSION, payload: { resourceName, ability } });
    },
    [dispatch]
  );

  const insertInstancePermission = useCallback(
    ({ resourceName, instanceId, permissionName, permitted }) => {
      const ability = { ability: permissionName, permitted: Boolean(permitted) };

      dispatch({ type: INSERT_INSTANCE_PERMISSION, payload: { resourceName, instanceId, ability } });
    },
    [dispatch]
  );

  const insertPermission = useCallback(
    ({ permissionName, resourceName = null, instanceId = null, permitted = false }) => {
      if (resourceName === null && instanceId === null) {
        insertGlobalPermission({ permissionName, permitted });
      } else if (instanceId === null) {
        insertResourcePermission({ permissionName, resourceName, permitted });
      } else {
        insertInstancePermission({ permissionName, resourceName, instanceId, permitted });
      }
    },
    [insertGlobalPermission, insertResourcePermission, insertInstancePermission]
  );

  const checkPermission = (permissionName, resourceName = null, instanceId = null) => {
    if (resourceName === null && instanceId === null) {
      return permissionState.globalPermissions[permissionName]?.permitted ?? CHECK_PERMISSION;
    }

    const canOnInstance =
      permissionState.resourcePermissions[resourceName]?.instances?.[instanceId]?.abilities?.[permissionName]?.permitted;
    const canOnResource = permissionState.resourcePermissions[resourceName]?.abilities?.[permissionName]?.permitted;

    return canOnInstance ?? canOnResource ?? CHECK_PERMISSION;
  };

  useEffect(() => {
    if (window.userPermissions) {
      _.forEach(window.userPermissions, (perm, key) => {
        if (perm === true || perm === false || perm === CHECK_PERMISSION) {
          insertGlobalPermission({ permissionName: key, permitted: perm });
        } else if (typeof "object") {
          _.forEach(perm, (permitted, permissionName) => {
            insertResourcePermission({ resourceName: key, permissionName, permitted });
          });
        }
      });
    }
  }, [insertGlobalPermission, insertResourcePermission]);

  const context = {
    insertPermission,
    checkPermission,
    insertGlobalPermission,
    insertResourcePermission,
    insertInstancePermission,
  };

  return <PermissionContext.Provider value={context}>{children}</PermissionContext.Provider>;
};
