import React from "react";
import { AddMulitplePayorReviewsButton } from "./AddMulitplePayorReviewsButton";
import { useTableContext } from "../../../components/AdministrateTable";
import { useUpdatePermission } from "../../../utils";

export const PaRequirementActionCell = ({ row }) => {
  const [canUpdate] = useUpdatePermission("Payor", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();
  const needsReview = row.original.needsReview;

  return (
    <>
      {canUpdate && needsReview && (
        <AddMulitplePayorReviewsButton
          resourceId={row.original.id}
          resourceType="payor_cpt_codes_settings"
          reviewType="requirement"
          onError={setError}
          onReview={() => {
            refresh();
            setMessage(`${row.original.name} requirements marked as reviewed`);
          }}
        />
      )}
    </>
  );
};
