import React from "react";
import { LoadingIcon } from "../components/LoadingIcon";

export const AttachedCaseDetails = ({ caseId, kase, loaded, loading, category }) => {
  return (
    <dl>
      <dt>Public ID</dt>
      <dd>
        <code>{caseId}</code>
      </dd>

      {loading && (
        <>
          <dt>Loading</dt>
          <dd>
            <LoadingIcon />
          </dd>
        </>
      )}

      {loaded && (
        <>
          <dt>Patient Name</dt>
          <dd>
            {kase.paRequest.patientInfo.firstName} {kase.paRequest.patientInfo.lastName}
          </dd>

          <dt>DOB</dt>
          <dd>{kase.paRequest.patientInfo.dob}</dd>

          <dt>Member ID</dt>
          <dd>{kase.paRequest.insuranceInfo.primaryInsurance?.memberId ?? "None"}</dd>

          {category && (
            <>
              <dt>Category</dt>
              <dd>{category}</dd>
            </>
          )}
        </>
      )}
    </dl>
  );
};
