import React from "react";
import { Alert } from "reactstrap";
import { Field } from "react-final-form";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { ImportModal } from "../../components/ImportModal";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const ImportLabFeeScheduleByPayorModal = ({ labId, onImport, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      payorIds: values.payorIds,
      file: {
        bytes: values.file,
      },
    };

    const res = await api.post(`/admin/cpt_fee_schedules/import.json?lab_id=${labId}`, snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message, res.data?.meta?.error_details);
    }
  };

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Import Fee Schedule By Payor..."
      exampleDownloadPath="/admin/cpt_fee_schedules/example"
    >
      {(formProps) => (
        <>
          <Alert color="warning">
            <p>This import will add or update CPT Fee Schedule entries based on Payor and CPT code.</p>
          </Alert>
          <Field
            component={AdministrateSelectInput}
            resourceUrl="/admin/payors.json"
            label="Payor"
            name="payorIds"
            isMulti
            searchParam="name"
          />
        </>
      )}
    </ImportModal>
  );
};
