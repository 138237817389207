import _ from "lodash";

export const mapStringsToOptions = (strings) => _.map(strings, (s) => ({ label: s, value: s }));

export const processFiles = (files) => Promise.all(files.map((f) => processFile(f)));

export const processFile = (file) => {
  const { name, size, type } = file;

  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = reject;
    reader.onload = (event) => {
      resolve({
        dataURL: addNameToDataURL(event.target.result, name),
        name,
        size,
        type,
      });
    };
    reader.readAsDataURL(file);
  });
};

export const addNameToDataURL = (dataURL, name) => {
  return dataURL.replace(";base64", `;name=${encodeURIComponent(name)};base64`);
};

// This method is used to allow a React Final Form <Field>
// component to be blankable.
//
// See issue: https://github.com/final-form/react-final-form/issues/130
export const parseIdentity = (v) => _.identity(v);
