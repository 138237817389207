import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { fieldStore } from "./store";
import { DocumentSchemaEditor } from "./components/DocumentSchemaEditor";
import { KBS } from "./components/KBS";

export const FillableDocumentEditor = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Provider store={fieldStore}>
        <KBS updateSchemaPath={props.updateSchemaPath}>
          <DocumentSchemaEditor
            getSchemaPath={props.getSchemaPath}
            updateSchemaPath={props.updateSchemaPath}
            editDocumentPath={props.editDocumentPath}
            previewSchemaPath={props.previewSchemaPath}
            exportEnabled={props.exportEnabled}
          />
        </KBS>
      </Provider>
    </DndProvider>
  );
};
