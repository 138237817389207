import _ from "lodash";
import { applyMiddleware, createStore } from "redux";
import { bindAll, createAction, createReducer } from "redux-act";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { api } from "../util/api";

// Logger
const logger = createLogger();

// Actions
export const addField = createAction("add a new field");
export const removeField = createAction("remove a field");
export const updateField = createAction("update a field");
export const selectField = createAction("select a field");
export const updateFieldOrder = createAction("update order of a field");

export const addCustomField = createAction("add custom field");
export const updateCustomField = createAction("update custom field");
export const removeCustomField = createAction("remove custom field");

export const getSchema = createAction("get schema");
export const getSchemaError = createAction("get schema error");
export const getSchemaSuccess = createAction("get schema complete");

export const updateCurrentPage = createAction("update current page");
export const updatePageDefinition = createAction("update page bounds");

export const save = createAction("save");
export const saveError = createAction("save error");
export const saveSuccess = createAction("save complete");

export const preview = createAction("preview");

// Reducers
const INITIAL_STATE = {
  selectedField: null,
  pageImages: [],
  pageSizes: [],
  fields: [],
  customFields: {},
  defaultFields: {},
  saving: false,
  loading: true,
  dirty: false,
  currentPage: 0,
  pageDefinitions: [],
};

export const fieldReducer = createReducer(
  {
    [selectField]: (s, p) => {
      return _.merge({}, s, { selectedField: p.fieldId });
    },
    [addField]: (s, p) => {
      s.fields.push(p.data);
      return _.merge({}, s, { dirty: true });
    },
    [removeField]: (s, p) => {
      const { fieldId } = p;
      let fieldIndex = _.findIndex(s.fields, (f) => f.id === fieldId);

      if (!_.isNil(fieldIndex)) {
        s.selectField = null;
        s.fields.splice(fieldIndex, 1);
      }

      return _.merge({}, s, { dirty: true });
    },
    [updateField]: (s, p) => {
      const { fieldId, data, assign = false } = p;
      let fieldIndex = _.findIndex(s.fields, (f) => f.id === fieldId);

      if (!_.isNil(fieldIndex)) {
        const oldField = s.fields[fieldIndex];
        let newField;

        if (assign) {
          newField = _.assign({}, oldField, data);
        } else {
          newField = _.merge({}, oldField, data);
        }

        s.fields.splice(fieldIndex, 1, newField);
      }

      return _.merge({}, s, { dirty: true });
    },
    [updateFieldOrder]: (s, p) => {
      const { fieldList } = p;

      _.each(fieldList, (field, index) => {
        const fieldIndex = _.findIndex(s.fields, (f) => f.id === field.id);
        if (fieldIndex >= 0) {
          const oldField = s.fields[fieldIndex];
          const newField = _.assign({}, oldField, { fieldOrder: index });
          s.fields.splice(fieldIndex, 1, newField);
        }
      });

      return _.merge({}, s, { dirty: true });
    },
    [addCustomField]: (s, p) => {
      const { key, data } = p;
      _.set(s.customFields, key, data);
      return _.merge({}, s, { dirty: true });
    },
    [removeCustomField]: (s, p) => {
      const { fieldId } = p;
      let fieldKey = _.findKey(s.customFields, (f) => f.id === fieldId);

      if (!_.isNil(fieldKey)) {
        // Remove custom field
        delete s.customFields[fieldKey];

        // Remove all custom field references
        _.each(s.fields, (f) => {
          // Continue if empty
          if (!f.field) return;

          if (_.isString(f.field)) {
            // Set to an empty array
            if (_.includes(f.field, fieldKey)) {
              f.field = [];
            }
          } else if (_.isArray(f.field)) {
            // Set only to values that do not contain the field key
            f.field = _.filter(f.field, (j) => !_.includes(j, fieldKey));
          }
        });
      }

      // Return a new state object
      return _.merge({}, s, { dirty: true });
    },
    [updateCustomField]: (s, p) => {
      // Mirrors updateField
      const { fieldId, data, assign = false } = p;
      let fieldKey = _.findKey(s.customFields, (f) => f.id === fieldId);

      if (!_.isNil(fieldKey)) {
        const oldField = s.customFields[fieldKey];
        let newField;
        let newCustomFields;
        if (assign) {
          newField = _.assign({}, oldField, data);
          newCustomFields = _.assign({}, s.customFields, {
            [fieldKey]: newField,
          });
        } else {
          newField = _.merge({}, oldField, data);
          newCustomFields = _.merge({}, s.customFields, {
            [fieldKey]: newField,
          });
        }

        s.customFields = newCustomFields;
      }

      return _.merge({}, s, { dirty: true });
    },
    [save]: (s) => {
      return _.merge({}, s, { saving: true });
    },
    [saveSuccess]: (s) => {
      return _.merge({}, s, { saving: false, dirty: false });
    },
    [saveError]: (s) => {
      return _.merge({}, s, { saving: false });
    },
    [preview]: (s, p) => {
      return _.merge({}, s, { preview: p });
    },
    [getSchema]: (s) => _.merge({}, s, { loading: true }),
    [getSchemaSuccess]: (s, schema) => _.merge({}, s, schema, { loading: false }),
    [getSchemaError]: (s) => _.merge({}, s, { loading: false }),
    [updateCurrentPage]: (s, p) => {
      const { newPage } = p;
      return _.merge({}, s, { currentPage: newPage });
    },
    [updatePageDefinition]: (s, p) => {
      const { pageIndex, page } = p;
      const { pageDefinitions } = s;
      const newDefinitions = _.clone(pageDefinitions);

      newDefinitions[pageIndex] = page;

      return _.merge({}, s, { pageDefinitions: newDefinitions });
    },
  },
  INITIAL_STATE
);

// Async Actions

const SAVE_KEYS = ["customFields", "fields"];
export const saveDocument = (updatePath) => {
  return (dispatch, getState) => {
    const state = getState();
    const saveObj = _.pickBy(state, (_v, k) => _.includes(SAVE_KEYS, k));

    dispatch(save());
    api
      .post(updatePath, { schema: saveObj })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(saveSuccess());
        } else {
          dispatch(saveError());
        }
      })
      .catch((e) => {
        if (e.response) {
          console.error(e);
        }

        dispatch(saveError());
      });
  };
};

export const previewSchema = () => {
  return (dispatch, getState) => {
    const state = getState();
    const saveObj = _.pickBy(state, (_v, k) => _.includes(SAVE_KEYS, k));
    dispatch(preview(saveObj));
  };
};

export const getDocument = (getPath) => {
  return (dispatch) => {
    dispatch(getSchema());
    api
      .get(getPath)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(getSchemaSuccess(res.data));
        } else {
          dispatch(getSchemaError());
        }
      })
      .catch((e) => {
        if (e.response) {
          console.error(e);
        }

        dispatch(saveError());
      });
  };
};

// Selectors

export const selectFieldsByPageNumber = (state, num) => {
  let fields = _.get(state, "fields", {});

  return _.filter(fields, (f) => {
    const pageNum = _.get(f, "position.page", 0);
    return pageNum === num;
  });
};

export const selectFieldById = (state, id) => {
  let fields = _.get(state, "fields", {});

  return _.find(fields, (f) => {
    return f.id === id;
  });
};

// Store
const createMiddleStore = applyMiddleware(thunkMiddleware, logger)(createStore);
export const fieldStore = createMiddleStore(fieldReducer);

bindAll([addField, removeField, updateField], fieldStore);
