import $ from "jquery";
import zxcvbn from "zxcvbn";

export const signupValidation = () => {
  runSignupValidations();
  addSignupListeners();
};

export const passwordChangeValidation = () => {
  runPasswordChangeValidations();
  addPasswordChangeListeners();
};

export const passwordOptionalChangeValidation = () => {
  runPasswordOptionalChangeValidations();
  addPasswordOptionalChangeListeners();
};

export const acceptInvitationValidation = () => {
  runAcceptInvitationValidations();
  addAcceptInvitationListeners();
};

const addSignupListeners = () => {
  $("#user_email").on("input", runSignupValidations);
  $("#user_password").on("input", runSignupValidations);
  $("#user_password_confirmation").on("input", runSignupValidations);
};

const addPasswordChangeListeners = () => {
  $("#user_email").on("input", runPasswordChangeValidations);
  $("#user_password").on("input", runPasswordChangeValidations);
  $("#user_password_confirmation").on("input", runPasswordChangeValidations);
  $("#user_current_password").on("input", runPasswordChangeValidations);
};

const addPasswordOptionalChangeListeners = () => {
  $("#user_email").on("input", runPasswordOptionalChangeValidations);
  $("#user_password").on("input", runPasswordOptionalChangeValidations);
  $("#user_password_confirmation").on("input", runPasswordOptionalChangeValidations);
  $("#user_current_password").on("input", runPasswordOptionalChangeValidations);
};

const addAcceptInvitationListeners = () => {
  $("#user_password").on("input", runAcceptInvitationValidations);
  $("#user_password_confirmation").on("input", runAcceptInvitationValidations);
};

const runSignupValidations = () => {
  const formValid = emailOk() && passwordOk() && passwordConfirmationOk();
  setButtonEnabled(formValid);
};

const runPasswordChangeValidations = () => {
  const formValid = emailOk() && passwordOk() && passwordConfirmationOk() && currentUserPasswordOk();
  setButtonEnabled(formValid);
};

const runPasswordOptionalChangeValidations = () => {
  const passwordChangeValid = !passwordChanging() || (passwordOk() && passwordConfirmationOk());
  const formValid = emailOk() && currentUserPasswordOk() && passwordChangeValid;
  setButtonEnabled(formValid);
};

const runAcceptInvitationValidations = () => {
  const formValid = passwordOk() && passwordConfirmationOk();
  setButtonEnabled(formValid);
};

const setButtonEnabled = (isEnabled) => {
  $("#signup-submit, #update-user").prop("disabled", !isEnabled);
};

const emailOk = () => {
  // TODO: Real email validation, rather than just !blank
  return !!$("#user_email").val().trim();
};

const passwordOk = () => {
  const passwordScore = zxcvbn($("#user_password").val()).score;
  const length = $("#user_password").val().length;
  return passwordScore >= 3;
};

const passwordFeedback = () => {
  return zxcvbn($("#user_password").val()).feedback;
};

const passwordConfirmationOk = () => {
  return $("#user_password").val() == $("#user_password_confirmation").val();
};

const currentUserPasswordOk = () => {
  return $("#user_current_password").val().length > 0;
};

const passwordSet = () => {
  return $("#user_password").val().length > 0;
};

const passwordConfirmationSet = () => {
  return $("#user_password_confirmation").val().length > 0;
};
const passwordChanging = () => {
  return passwordSet() || passwordConfirmationSet();
};
