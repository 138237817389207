import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useAsync } from "../util/hooks";
import { camelize } from "../util/helpers";
import { api } from "../util/api";
import { featureEnabled } from "../util/feature";

const PA_ORDER_TYPE = "priorAuthRequest";
const BI_ORDER_TYPE = "benefitsInvestigation";
export const IS_INSURED_RADIO_VALUE = "1";
export const IS_UNINSURED_RADIO_VALUE = "0";

export const setPregnancyMutator = ([sex], state, { changeValue }) => {
  changeValue(state, "patientInfo.pregnant", () => (sex === "Male" ? "Not Pregnant" : null));
};

export const clearOrderedWorkflowsMutator = ([], state, { changeValue }) => {
  changeValue(state, "orderedWorkflows", () => undefined);
};

export const setDefaultOrderedWorkflowsMutator = ([workflow], state, { changeValue }) => {
  changeValue(state, "orderedWorkflows", () => workflow);
};

export const getDefaultWorkflow = (workflowOptions, isClinicUser, workflows) => {
  if (workflowOptions.length >= 1 && isClinicUser) {
    if (_.some(workflowOptions, (o) => o.value === BI_ORDER_TYPE)) {
      return BI_ORDER_TYPE;
    }

    if (_.some(workflowOptions, (o) => o.value === PA_ORDER_TYPE)) {
      return PA_ORDER_TYPE;
    }
  }

  return camelize(workflows?.find((flow) => flow.default)?.resource);
};

export const useWorkflows = ({ isClinicUser, defaultOrderableWorkflows, labId }) => {
  const input = useMemo(() => ({ isClinicUser, defaultOrderableWorkflows, labId }), [isClinicUser, defaultOrderableWorkflows, labId]);

  const load = useCallback(async ({ isClinicUser, defaultOrderableWorkflows, labId }) => {
    if (isClinicUser) return defaultOrderableWorkflows;

    const res = await api.get(`/labs/${labId}/enabled_workflows`);

    return res.data?.enabled_workflows;
  }, []);

  return useAsync(load, input);
};

export const removeUninsured = (payors) => {
  // If the uninsured feature flag is enabled, we want to filter
  // out the magic uninsured patient payor because we are going
  // to set whether the patient is uninsured using a radio button
  // instead of selecting the uninsured patient from the payor
  // dropdown
  if (featureEnabled("uninsured_patient_payor")) {
    return payors.filter((p) => p.name.toLowerCase() !== "uninsured patient");
  } else {
    // If the feature flag is off, just return the payors
    // in an unmodified state
    return payors;
  }
};

export const mapPayorsToNameOptions = (payors) => removeUninsured(payors).map((p) => ({ label: `${p.name}`, value: p.name }));
export const mapPayorsToIdOptions = (payors) => removeUninsured(payors).map((p) => ({ label: `${p.name}`, value: p.public_id }));
export const radioIsInsured = (value) => value === IS_INSURED_RADIO_VALUE;
