import React, { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { BlankableField } from "../../../components/inputs/final_form/BlankableField";
import { Fieldset } from "../../../components/inputs/final_form/Fieldset";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { camelizeKeys, snakifyKeys } from "../../../util/helpers";
import { numericInRange, required } from "../../../util/validators";
import { useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const PortalGeneralTab = ({ portal, portalId, refresh }) => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const [canUpdatePortal] = useUpdatePermission("BenefitManagementPortal", portalId);

  const handleUpdate = async (values) => {
    setError(null);
    setMessage(null);

    try {
      await api.put(`/admin/benefit_management_portals/${portalId}`, { benefit_management_portal: snakifyKeys(values) });
      setMessage("Updated Portal");
      refresh();
    } catch (err) {
      setError(err);
    }
  };

  const initialValues = useMemo(() => camelizeKeys(portal), [portal]);

  const readOnly = !canUpdatePortal;

  return (
    <>
      <DetailedErrorAlert error={error} />
      {message && <Alert color="info">{message}</Alert>}

      <Form onSubmit={handleUpdate} initialValues={initialValues}>
        {({ handleSubmit, submitting, pristine }) => {
          return (
            <>
              <Field component={TextInput} required validate={required} label="ID" name="id" readOnly />
              <Field component={TextInput} required validate={required} label="Name" name="name" readOnly={readOnly} />
              <BlankableField component={TextInput} label="URL" name="url" readOnly={readOnly} />

              <BlankableField component={TextInput} label="Phone" name="phone" readOnly={readOnly} />
              <BlankableField component={TextInput} label="Fax" name="faxNumber" readOnly={readOnly} />
              <BlankableField component={TextInput} label="SOP URL" name="sopUrl" readOnly={readOnly} />

              <Fieldset legend="Portal Settings">
                <Field
                  component={TextInput}
                  label="Follow-up Days"
                  validate={numericInRange(0, 90)}
                  name="followupDays"
                  type="number"
                  min="0"
                  max="90"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  label="Enrollment Required?"
                  name="enrollmentRequired"
                  id="enrollmentRequired"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  label="Supports Tiers?"
                  name="supportsTiers"
                  id="supportsTiers"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  label="Post-Service Review"
                  name="postServiceReview"
                  id="postServiceReview"
                  readOnly={readOnly}
                />
                <Field
                  type="checkbox"
                  component={Switch}
                  required
                  label="Call To Followup"
                  name="callToFollowUp"
                  id="callToFollowUp"
                  readOnly={readOnly}
                />
              </Fieldset>

              {canUpdatePortal && (
                <div className="d-flex flex-row gap-2">
                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={readOnly || submitting || pristine}>
                    Save
                  </LoadingButton>

                  <History entityName={"BenefitManagementPortal"} subjectId={portalId} />
                </div>
              )}
            </>
          );
        }}
      </Form>
    </>
  );
};
