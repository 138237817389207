import React from "react";
import { FormGroup, Label } from "reactstrap";
import { CptSelector } from "../components/inputs/CptSelector";
import { StyledSelect as Select } from "../components/inputs/StyledSelect";

export const SplitPicker = ({ split, testOptions, cptOptions, onChangeTest, onChangeCodes, disabled, maxCounts }) => (
  <div className="mb-4">
    <FormGroup row>
      <Label className="col-sm-3 col-form-label">Test</Label>

      <div className="col-sm-10 col-md-9">
        <Select isMulti isClearable options={testOptions} value={split.tests} onChange={onChangeTest} isDisabled={disabled} />
      </div>
    </FormGroup>

    <FormGroup row>
      <Label className="col-sm-3 col-form-label">CPT Codes</Label>

      <div className="col-sm-10 col-md-9">
        <CptSelector options={cptOptions} value={split.codes} onChange={onChangeCodes} maxCounts={maxCounts} isDisabled={disabled} />
      </div>
    </FormGroup>
  </div>
);
