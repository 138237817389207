import React from "react";
import { PopoverBody, PopoverHeader } from "reactstrap";

const tokenizeIcdCodes = (codes) =>
  codes
    .map((icd) => (
      <span className="token" key={icd}>
        {icd}
      </span>
    ))
    .reduce((prev, curr) => [prev, ", ", curr]);

export const IcdPopoverContent = ({ codes, ...props }) => (
  <>
    <PopoverHeader>Covered ICD10 Codes</PopoverHeader>
    <PopoverBody {...props}>{codes.length > 0 ? tokenizeIcdCodes(codes) : <span>No ICD10 codes are covered</span>}</PopoverBody>
  </>
);
