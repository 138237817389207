import cx from "classnames";
import _ from "lodash";
import { Play } from "lucide-react";
import React from "react";
import { BoolCell } from "../components/grid/cells/BoolCell";
import { CheckmarkCell } from "../components/grid/cells/CheckmarkCell";
import { DueDateCell } from "../components/grid/cells/DueDateCell";
import {
  ASSIGNEE,
  CASE_COLLECTION_DATE,
  CASE_DATE_OF_SERVICE,
  CASE_LAB,
  CASE_LAB_ORDER_ID,
  CASE_LAB_PATIENT_ID,
  CASE_PATIENT_DOB,
  CASE_PATIENT_NAME,
  CASE_PHYSICIAN_NAME,
  CASE_PHYSICIAN_NPI,
  CURRENT_STATUS,
  CURRENT_STEP,
  CURRENT_SUBSTATUS,
  INSURANCE_MEMBER_ID,
  INSURANCE_NAME,
  PAYOR_PATHS,
  PHYSICIAN_PRACTICE,
  PUBLIC_ID,
} from "../components/grid/columns";
import { DateFilter, EnumFilter } from "../components/inputs";
import { formatDate, objectToOptions } from "../util/formatters";
import { trackEvent } from "../util/track";
import { PayorSelector } from "../components/inputs/PayorSelector";
import { TooltipCell } from "../components/grid/cells/TooltipCell";

const handleProcess = (caseId) => {
  trackEvent("initial_process", caseId, {
    step: "initial_process",
  });
};

const Links = ({ row }) => {
  const id = row.original.public_id;
  const requestId = _.get(row, "original.pa_request.requestId");
  const disabled = row.original.closed;

  return (
    <>
      <a onClick={() => handleProcess(id)} href={`/workflow/${id}`} className={cx("btn btn-sm btn-outline-primary me-2", { disabled })}>
        <Play className="btn__icon" />
        <span className="btn__text">Process</span>
      </a>
      <a className="btn btn-sm btn-outline-secondary" href={`/operations/cases/${requestId}`}>
        Details
      </a>
    </>
  );
};

export const PROCESS = {
  Header: "Actions",
  id: "process",
  disableFilters: true,
  disableSortBy: true,
  Cell: Links,
  className: "action-cell",
  minWidth: 220,
  maxWidth: 250,
};

const CLOSED = {
  Header: "Closed",
  id: "closed",
  accessor: (r) => _.get(r, "closed", false),
  minWidth: 85,
  Cell: CheckmarkCell,
  disableFilters: true,
};

const CURRENT_WORKFLOW = (workflows) => {
  return {
    Header: "Current Workflow",
    accessor: "workflow_state",
    minWidth: 180,
    Filter: (props) => <EnumFilter {...props} options={objectToOptions(workflows)} />,
  };
};

export const TASK_DUE = {
  Header: "Task Due",
  id: "due_date",
  accessor: "due_date",
  minWidth: 125,
  Filter: (props) => <DateFilter {...props} />,
  Cell: DueDateCell,
};

// Memoize required to ensure that PayorSelector can maintain state
const VERIFIED_PAYOR = _.memoize((initialPayors) => ({
  Header: "Verified Payor",
  id: "payor",
  accessor: "pa_request.verifiedPayorName",
  minWidth: 140,
  Filter: ({ column: { filterValue, setFilter }, ...rest }) => {
    return (
      <PayorSelector
        isClearable
        isSearchable
        value={filterValue}
        onChange={setFilter}
        initialOptions={initialPayors}
        menuPortalTarget={document.body}
        placeholder="Show All"
        {...rest}
      />
    );
  },
  Cell: TooltipCell,
}));

const LAST_UPDATED = {
  Header: "Last Updated",
  id: "last_action_taken_at",
  accessor: (r) => formatDate(_.get(r, "last_action_taken_at")),
  minWidth: 130,
  Filter: (props) => <DateFilter {...props} />,
};

export const CREATED_ON = {
  Header: "Created On",
  id: "created_at",
  accessor: (r) => formatDate(_.get(r, "created_at")),
  minWidth: 130,
  Filter: (props) => <DateFilter {...props} />,
};

export const REQUIRES_US_ASSIGNEE = {
  Header: "Offshore Restrictions",
  id: "requires_us_assignee",
  accessor: "requires_us_assignee",
  Cell: BoolCell,
  Filter: (props) => (
    <EnumFilter
      {...props}
      options={[
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ]}
    />
  ),
};

export const OPERATIONS_COLUMNS = (filterOptions) => {
  const {
    assignedUsersWithActiveCases,
    workflowOptions,
    stepOptions,
    statusOptions,
    substatusOptions,
    initialPayorOptions,
    initialLabOptions,
  } = filterOptions;

  return [
    PROCESS,
    PUBLIC_ID,
    CURRENT_WORKFLOW(workflowOptions),
    CURRENT_STEP(stepOptions),
    CURRENT_STATUS(statusOptions),
    CURRENT_SUBSTATUS(substatusOptions),
    PAYOR_PATHS,
    ASSIGNEE(assignedUsersWithActiveCases),
    CASE_PATIENT_NAME(false, true),
    CASE_PATIENT_DOB,
    PHYSICIAN_PRACTICE,
    CASE_PHYSICIAN_NPI,
    CASE_PHYSICIAN_NAME,
    CASE_LAB_PATIENT_ID,
    REQUIRES_US_ASSIGNEE,
    CASE_LAB_ORDER_ID,
    CASE_LAB(initialLabOptions),
    CASE_COLLECTION_DATE,
    CASE_DATE_OF_SERVICE,
    TASK_DUE,
    VERIFIED_PAYOR(initialPayorOptions),
    INSURANCE_NAME,
    INSURANCE_MEMBER_ID,
    LAST_UPDATED,
    CREATED_ON,
  ];
};
