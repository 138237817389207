import React, { useState } from "react";
import { Field } from "react-final-form";
import { TextInput, TextInputWithButton } from "../../../components/inputs/final_form/TextInput";
import { BlankableField } from "../../../components/inputs/final_form/BlankableField";
import { required } from "../../../util/validators";
import { adminApi as api } from "../../../util/api";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { objectToOptions } from "../../../util/formatters";
import { SPECIALIES } from "../../../util/data/specialities";

export const LabIdentifierFields = ({ initialAssignableOptions = [], ...props }) => (
  <>
    <Field component={TextInput} required validate={required} label="Lab Name" name="labName" {...props} />
    <Field
      component={AdministrateSelectInput}
      resourceUrl="/admin/accounts/lab_assignable.json"
      valueKey="id"
      labelKey="full_name_or_email"
      label="Assigned To"
      name="assignedToId"
      required
      validate={required}
      searchParam="assigned_to"
      initialOptions={initialAssignableOptions}
      {...props}
    />
    <BlankableField component={TextInput} label="Lab Internal ID" name="labInternalId" {...props} />
    <Field
      component={SelectInput}
      label="Servicing Provider Type"
      name="servicingProviderType"
      {...props}
      options={[
        { value: "Lab", label: "Lab" },
        { value: "Pharmacy", label: "Pharmacy" },
      ]}
    />
    <Field component={Switch} label="Ribbon Network Status" name="ribbonNetworkStatus" type="checkbox" />
    <BlankableField component={TextInput} label="Storage Name" name="storageName" {...props} />
  </>
);

export const LabNpiFields = ({ form, setError, ...props }) => {
  const [loadingNpiLookup, setLoadingNpiLookup] = useState(false);

  const handleNpiLookup = async () => {
    const npi = form.getState().values.npi;

    if (!npi || npi.length < 9) {
      setError("Need valid NPI Number");
      return false;
    }

    setLoadingNpiLookup(true);

    try {
      const response = await api.get(`/clinics/npi?number=${npi}`);
      if (response.status !== 200) {
        setError("Request failed for NPI Number " + npi);
      }
      const name =
        response.data.results.find((x) => x.enumeration_type === "NPI-2")?.basic?.organization_name ||
        response.data.results.find((x) => x.enumeration_type === "NPI-1")?.basic?.last_name;

      if (name != null) {
        form.change("npiRegistryName", name);
        setError(null);
      } else {
        setError("Did not find registry name for NPI Number " + npi);
      }
    } catch (err) {
      setError("Error getting registry name for NPI Number " + npi);
    } finally {
      setLoadingNpiLookup(false);
    }
  };

  const disabled = props.readOnly || props.disabled;

  return (
    <>
      <BlankableField component={TextInput} label="NPI" name="npi" {...props} />
      <BlankableField
        component={TextInputWithButton}
        buttonLabel={<span>Get from NPI Registry</span>}
        label="NPI Registry Name"
        buttonProps={{ outline: true, onClick: () => handleNpiLookup(), disabled: disabled || loadingNpiLookup }}
        name="npiRegistryName"
        {...props}
      />
      <Field component={SelectInput} options={objectToOptions(SPECIALIES)} label="Speciality" name="speciality" />

      <BlankableField component={TextInput} label="Tax ID" name="taxId" {...props} />
      <BlankableField component={TextInput} label="TevixMD Location ID" name="tevixmdLocationId" {...props} />
    </>
  );
};

export const LabContactFields = (props) => (
  <>
    <BlankableField component={TextInput} label="Phone Number" name="phoneNumber" {...props} />
    <BlankableField component={TextInput} label="Contact Name" name="contactName" {...props} />
    <BlankableField component={TextInput} label="Contact Email" name="contactEmail" {...props} />
    <BlankableField component={TextInput} label="Primary Patient Contact" name="primaryPatientContact" {...props} />
    <BlankableField component={TextInput} label="Webhook whitelist domains" name="webhookWhitelistDomains" {...props} />
  </>
);
