import _ from "lodash";
import { useEffect, useState } from "react";
import { extractNameFromDataURL } from "../util/helpers";
import { api } from "../util/api";

const mapDataUrlsToFileObjects = (files) => _.map(_.compact(files), (f) => ({ name: extractNameFromDataURL(f) }));

export const moveUploadedDocumentsMutator = ([files], state, { changeValue }) => {
  changeValue(state, "supplementalDocuments.files", (value) => {
    return _.concat(_.castArray(value), mapDataUrlsToFileObjects(files));
  });

  changeValue(state, "supplementalDocuments.newFiles", () => []);
};

export const useMappings = (labId, orderedWorkflows, allTests, shouldFetchClinicOptions) => {
  const [loadingMappings, setLoadingMappings] = useState(false);
  const [error, setError] = useState(null);
  const [mappings, setMappings] = useState([]);

  useEffect(() => {
    let mounted = true;

    const fetchMappings = async () => {
      if (!labId) {
        return false;
      }

      try {
        setLoadingMappings(true);
        setError(null);

        if (shouldFetchClinicOptions) {
          const data = await fetchMappingsClient("/tests/clinic_test_mappings");
          setMappings(data);
        } else {
          const data = await fetchMappingsClient(`/labs/${labId}/test_mappings`, allTests);
          setMappings(data.mappings);
        }
      } catch (e) {
        console.error("Error fetching mappings", e);
        setError(extractErrorMessage(e, "Error while fetching Tests for Lab"));
      } finally {
        setLoadingMappings(false);
      }
    };

    fetchMappings();

    return () => (mounted = false);
  }, [labId, orderedWorkflows, shouldFetchClinicOptions, allTests]);

  return { mappings, loadingMappings, error };
};

const fetchMappingsClient = async (url, allTests) => {
  res = await api.get(url, { params: allTests ? { all_tests: allTests } : {} });
  return res.data;
};

export const mapMappingsToNameOptions = (mappings) => {
  const filteredMappings = _.filter(mappings, (m) => !!m.name);
  const uniqueMappings = _.uniqBy(filteredMappings, "name");
  return _.map(uniqueMappings, (m) => ({ label: m.name, value: m.id }));
};

export const mapMappingsToIdOptions = (mappings) => {
  const filteredMappings = _.filter(mappings, (m) => !!m.identifier);
  const uniqueMappings = _.uniqBy(filteredMappings, "identifier");
  return _.map(uniqueMappings, (m) => ({ label: m.identifier, value: m.id }));
};

export const setTestsMutator = ([testIdentifiers, testIds, value], state, { changeValue }) => {
  const { formState } = state;
  const joinedIdentifiers = _.isArray(testIdentifiers) ? testIdentifiers.join(",") : testIdentifiers;
  const joinedIds = _.isArray(testIds) ? testIds.join(",") : testIds;

  // Update IDs if needs updating
  if (_.get(formState, "values.testInfo.testIdentifier") !== joinedIdentifiers) {
    changeValue(state, "testInfo.testIdentifier", () => joinedIdentifiers);
  }

  // Update names if needs updating
  if (_.get(formState, "values.testInfo.verifiedTestIds") !== joinedIds) {
    changeValue(state, "testInfo.verifiedTestIds", () => joinedIds);
  }

  changeValue(state, "testInfo.testIdentifierfield", () => value);
  changeValue(state, "testInfo.verifiedTestIds", () => value);
};

export const setCptCodesMutator = ([cptCodes], state, { changeValue }) => {
  changeValue(state, "testInfo.cptCodes", () => cptCodes);
};
