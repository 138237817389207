import { createRoot } from "react-dom/client";
import { BootstrapGrid } from "../components/grid/BootstrapGrid";
import { StyledSelect as Select } from "../components/inputs/StyledSelect";
import { userNameOrEmail } from "../util/formatters";
import { initColumns } from "./columns";

const MyCasesGrid = ({ data, tab }) => {
  return (
    <BootstrapGrid columns={initColumns(tab)} data={data} showPagination={false} defaultPageSize={data.length > 0 ? data.length : 20} />
  );
};

const MyCasesAssigneeSelector = ({ data, currentUser }) => {
  const emailsToDisplays = new Map(data.map((u) => [u.email, userNameOrEmail(u)]));

  const query = new URLSearchParams(window.location.search);
  const filter = query.get("assigned_to") || currentUser;

  return (
    <Select
      isClearable={false}
      isSearchable={true}
      value={
        filter
          ? {
              label: emailsToDisplays.get(filter),
              value: filter,
            }
          : null
      }
      onChange={(e) => {
        if (!e) return;
        if (!e.value) query.delete("assigned_to");
        else query.set("assigned_to", e.value);

        location.href = "//" + location.host + location.pathname + "?" + query.toString();
      }}
      options={data.map((u) => {
        return { label: userNameOrEmail(u), value: u.email };
      })}
      placeholder="Show All"
    />
  );
};

export const initMyCases = () => {
  const container = document.getElementById("my_cases_grid");

  const assigneeContainer = document.getElementById("my_cases_assignee");

  if (container) {
    const root = createRoot(container);
    const data = JSON.parse(container.dataset.data || "[]");
    const tab = container.dataset.tab || "unknown";

    root.render(<MyCasesGrid data={data} tab={tab} />);
  }

  if (assigneeContainer) {
    const assigneeRoot = createRoot(assigneeContainer);
    const data = JSON.parse(assigneeContainer.dataset.assignableUsers || "[]");
    const currentUser = assigneeContainer.dataset.currentUser;

    assigneeRoot.render(<MyCasesAssigneeSelector data={data} currentUser={currentUser} />);
  }
};
