import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { FieldArray } from "react-final-form-arrays";
import { Button, Row, Col, FormGroup, Container } from "reactstrap";
import { LoadingButton } from "../../../components/LoadingButton";
import { atLeastOneFieldRequired, required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { mapStringsToOptions } from "../../../util/forms";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { Plus, Trash } from "lucide-react";

const AGE_SYMBOL_OPTIONS = mapStringsToOptions([">", "<"]);

export const PayorPatientCriteriaForm = ({ handleSubmit, submitting, initialIcd10Options = [] }) => (
  <>
    <FieldArray name="patientCriteria">
      {({ fields }) => (
        <Container>
          {fields.map((name, index) => (
            <FormGroup key={name}>
              <Field
                isMulti
                component={AdministrateSelectInput}
                resourceUrl="/admin/icd10_codes.json"
                label="ICD10 Code"
                name={`${name}.icd10CodeIds`}
                initialOptions={initialIcd10Options[index]}
                distinct={true}
                validate={atLeastOneFieldRequired("icd10CodeIds")}
              />
              <Field
                component={AdministrateSelectInput}
                resourceUrl="/admin/enums/gender_options.json"
                labelKey="label"
                label="Gender"
                name={`${name}.gender`}
              />
              <Row>
                <Col xs="2">
                  <p className="text-center">Age is </p>
                </Col>
                <Col xs="2">
                  <Field
                    component={SelectInput}
                    placeholder=""
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    options={AGE_SYMBOL_OPTIONS}
                    name={`${name}.ageSymbol`}
                  />
                </Col>
                <Col xs="4">
                  <Field component={TextInput} name={`${name}.age`} />
                </Col>
                <Col xs="4">
                  <Button color="danger" outline className="pull-right" onClick={() => fields.remove(index)}>
                    <Trash />
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          ))}
          <Row>
            <Button color="primary" onClick={() => fields.push({ icd10CodeIds: [], age: "", gender: "" })} outline className="ms-auto">
              <Plus />
              Add Patient Criteria
            </Button>
          </Row>
        </Container>
      )}
    </FieldArray>

    <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
      Submit
    </LoadingButton>
  </>
);
