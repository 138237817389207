import { useCallback } from "react";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { Toolbar } from "../../../components/Toolbar";
import { formatCentsAsDollars } from "../../../util/formatters";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreateLabFeeScheduleByTestModal } from "../components/CreateLabFeeScheduleByTestModal";
import { ImportLabFeeScheduleByTestModal } from "../components/ImportLabFeeScheduleByTestModal";
import { LabFeeScheduleByTestActionCell } from "../components/LabFeeScheduleByTestActionCell";

const useColumns = makeColumns(() => [
  {
    id: "payor.name",
    accessor: "payor.name",
    Header: "Payor",
    sortable: false,
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="name" labelKey="name" searchParam="name" />
    ),
  },
  {
    id: "test.commercialName",
    accessor: "test.commercialName",
    Header: "Test",
    Cell: TokenCell,
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/tests.json?test[direction]=asc&test[order]=commercial_name"
        valueKey="commercial_name"
        labelKey="commercial_name"
      />
    ),
  },
  {
    id: "price_cents",
    accessor: "priceCents",
    Header: "Price",
    Cell: (row) => <span>{formatCentsAsDollars(row.value)}</span>,
    filterable: false,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: LabFeeScheduleByTestActionCell,
    filterable: false,
    sortable: false,
  },
]);

export const LabsFeeScheduleByTestTab = ({ labId, lab }) => {
  const extraParams = { lab: { id: labId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);
  const [canCreate] = useCreatePermission("CptFeeScheduleByTest");

  const columns = useColumns();

  const onCreate = useCallback(
    (newFeeSchedule) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`Created Cpt Fee Schedule By Test ${newFeeSchedule.id}`);
    },
    [tableRef]
  );

  const onImport = useCallback(
    (message, details = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, details.length ? "warning" : "success", details);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Cpt Fee Schedule By Test">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/cpt_fee_schedule_by_tests/export"
            filters={allFilters}
            ref={tableRef}
          />

          {canCreate && (
            <>
              <ImportLabFeeScheduleByTestModal labId={labId} outline className="me-2" onImport={onImport} />
              <CreateLabFeeScheduleByTestModal onCreate={onCreate} labId={labId} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/cpt_fee_schedule_by_tests.json`}
        resourceName={"cpt_fee_schedule_by_test"}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
