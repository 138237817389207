import { useMemo } from "react";
import { DetailsList } from "../../components/DetailsList";

const usePayorMemberPrefixDetails = (followupTaskConfig) => {
  const { payor, memberIdPrefixRegex } = followupTaskConfig;

  return useMemo(
    () => [
      {
        label: "Payor",
        value: payor?.name,
      },
      {
        label: "Member ID Prefix Regex",
        value: <code>{memberIdPrefixRegex}</code>,
      },
    ],
    [payor, memberIdPrefixRegex]
  );
};

export const PayorMemberPrefixReadOnly = ({ payorMemberPrefix }) => {
  const values = usePayorMemberPrefixDetails(payorMemberPrefix);
  return <DetailsList values={values} />;
};
