import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../components/AdministrateTable";
import { History } from "../../tools/sandbox/release/History";
import { useDestroyPermission } from "../../utils";
import { EditPayorMemberPrefixModal } from "./EditPayorMemberPrefixModal";

export const PayorMemberPrefixActionCell = ({ row }) => {
  const [canDelete] = useDestroyPermission("PayorPrefixMapping", row.original.id);

  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = (msg) => {
    setMessage(`Updated ${row.original.dashboardDisplayName}`);
    refresh();
  };

  return (
    <>
      <EditPayorMemberPrefixModal color="link" onEdit={handleChange} payorMemberPrefix={row.original} />
      {canDelete && (
        <AdministrateResourceDeleteButton
          label="Remove"
          deleteUrl={`/admin/payor_prefix_mappings/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Discarded ${row.original.dashboardDisplayName}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
      <History entityName={"PayorPrefixMapping"} subjectId={row.original.id} />
    </>
  );
};
