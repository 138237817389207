import setFieldData from "final-form-set-field-data";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { FormGroup } from "reactstrap";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { DateInput } from "../components/inputs/final_form/DateInput";
import { DropzoneInput } from "../components/inputs/final_form/DropzoneInput";
import { Fieldset } from "../components/inputs/final_form/Fieldset";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { WarningEngine, WarningField } from "../components/inputs/final_form/WarningEngine";
import { LoadingButton } from "../components/LoadingButton";
import { LoadingIcon } from "../components/LoadingIcon";
import { api, redirectTo } from "../util/api";
import { isBlank } from "../util/helpers";

const warningValidator = (values) => {
  const warnings = {};

  const generateBlankWarning = (field) => `${field} must be filled to resolve missing information`;

  if (isBlank(values.Patient?.FirstName)) {
    warnings["Patient.FirstName"] = generateBlankWarning("First Name");
  }

  if (isBlank(values.Patient?.LastName)) {
    warnings["Patient.LastName"] = generateBlankWarning("Last Name");
  }

  if (isBlank(values.Patient?.DOB)) {
    warnings["Patient.DOB"] = generateBlankWarning("Date of Birth");
  }

  if (isBlank(values.Patient?.Gender)) {
    warnings["Patient.Gender"] = generateBlankWarning("Gender");
  }

  return warnings;
};

export const LabFixForm = ({ caseId, caseUrl, saveUrl }) => {
  const [caseData, setCaseData] = useState(null);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCaseData = async () => {
      setError(null);
      setLoading(true);

      try {
        const { data } = await api.get(caseUrl);
        setCaseData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCaseData();
  }, [caseId, caseUrl]);

  const onSave = async (formData, submit = false) => {
    setError(null);
    setSaving(true);

    try {
      const { NewSupplementalDocuments, ...rest } = formData;
      await api.patch(saveUrl, { ...rest, submit, supplementalDocuments: { newFiles: NewSupplementalDocuments } });

      if (submit) {
        redirectTo(`/cases/${caseId}`);
      } else {
        window.location.reload();
      }
    } catch (err) {
      setError(err);
    } finally {
      setSaving(false);
    }
  };

  const onSubmit = async (formData) => {
    return await onSave(formData, true);
  };

  if (loading || !caseData) {
    return (
      <div className="text-muted text-center">
        <LoadingIcon className="me-2" /> Loading Case Data...
      </div>
    );
  }

  return (
    <>
      <DetailedErrorAlert error={error} className="mb-3" />

      <Form onSubmit={onSubmit} mutators={{ setFieldData }} initialValues={caseData.pa_request}>
        {({ handleSubmit, form, submitting, initialValues, values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Fieldset legend="Patient Info" highlight="red">
                <WarningField
                  required
                  name="Patient.FirstName"
                  component={TextInput}
                  type="text"
                  label="First Name"
                  disabled={!!initialValues.Patient.FirstName}
                  placeholder="First Name"
                />

                <WarningField
                  required
                  name="Patient.LastName"
                  component={TextInput}
                  type="text"
                  label="Last Name"
                  disabled={!!initialValues.Patient.LastName}
                  placeholder="Last Name"
                />

                <WarningField
                  required
                  name="Patient.DOB"
                  component={DateInput}
                  label="Date of Birth"
                  disabled={!!initialValues.Patient.DOB}
                  placeholder="Date of Birth"
                />

                <WarningField
                  required
                  name="Patient.Gender"
                  component={SelectInput}
                  options={[
                    { label: "Male", value: "M" },
                    { label: "Female", value: "F" },
                  ]}
                  label="Gender"
                  disabled={!!initialValues.Patient.Gender}
                  placeholder="Gender"
                />
              </Fieldset>

              <Fieldset legend="Supplemental Documents">
                <FormGroup>
                  {/*
                    This explicitly does not exist in the initial values because it is
                    exclusively for uploading new files.
                  */}
                  <Field name="NewSupplementalDocuments" component={DropzoneInput} multiple />
                </FormGroup>
              </Fieldset>

              <FormGroup>
                <div className="d-flex align-items-center justify-end">
                  <LoadingButton
                    loading={saving}
                    onClick={() => onSave(values, false)}
                    outline
                    disabled={submitting || saving}
                    className="me-2"
                  >
                    Save
                  </LoadingButton>

                  <LoadingButton loading={submitting} type="submit" color="primary" disabled={submitting || saving} className="me-2">
                    Re-submit for Processing
                  </LoadingButton>
                </div>
              </FormGroup>

              <WarningEngine mutators={form.mutators} validate={warningValidator} />
            </form>
          );
        }}
      </Form>
    </>
  );
};
