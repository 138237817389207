import React from "react";
import { FormText } from "reactstrap";
import { CptSelector as BaseCptSelector } from "../CptSelector";
import { InputWrapper, serializeInput } from "./InputWrapper";
import { featureEnabled } from "../../../util/feature";

export const CptSelector = ({ children, input, label, meta, ...rest }) => {
  const invalid = Boolean(meta.touched && meta.error);

  return (
    <InputWrapper label={label} required={rest.required} data-testid={rest["data-testid"]}>
      <BaseCptSelector valid={!invalid} {...input} {...rest} />

      {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
      {children}
    </InputWrapper>
  );
};

// serializeInput assumes that the value is an array of strings, which it won't be after the flag is turned on
// Tech-Debt (CD-9009): Remove serialized version of this component after the flag is turned on
export const SerializedCptSelector = featureEnabled("use_procedure_code_multiplier_column") ? CptSelector : serializeInput(CptSelector);
