import { Toolbar } from "../../../components/Toolbar";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreateFollowupTaskConfigModal } from "./components/CreateFollowupTaskConfigModal";
import { FollowupTaskConfigActionCell } from "./components/FollowupTaskConfigActionCell";
import { ImportFollowupTaskConfigsModal } from "./components/ImportFollowupTaskConfigsModal";
import { History } from "../../tools/sandbox/release/History";

const useColumns = makeColumns(() => [
  {
    id: "payor",
    accessor: "payor.name",
    Header: "Payor",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/payors.json?payor[direction]=asc&payor[order]=payor_name&per_page=100"
        valueKey="name"
        labelKey="name"
        searchParam="name"
        kept={true}
      />
    ),
    disableSortBy: true,
  },
  {
    id: "days_until_followup",
    accessor: "daysUntilFollowup",
    Header: "Days Until Follow-Up",
    disableFilters: true,
  },
  {
    id: "to_status",
    accessor: "toStatus",
    Header: "To Status",
    disableSortBy: true,
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/enums/prior_auth_statuses.json"
        valueKey="id"
        labelKey="label"
        searchParam="search"
      />
    ),
  },
  {
    id: "to_substatus",
    accessor: "toSubstatus",
    Header: "To Substatus",
    disableSortBy: true,
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/enums/prior_auth_substatuses.json"
        valueKey="value"
        labelKey="label"
        searchParam="search"
      />
    ),
  },
  {
    id: "from_status",
    accessor: "fromStatus",
    Header: "From Status",
    disableSortBy: true,
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/enums/prior_auth_statuses.json"
        valueKey="id"
        labelKey="label"
        searchParam="search"
      />
    ),
  },
  {
    id: "title",
    accessor: "title",
    Header: "Task Name",
    disableSortBy: true,
  },
  {
    id: "actions",
    accessor: "Actions",
    Cell: FollowupTaskConfigActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const FollowupTaskConfigsIndexPage = () => {
  const columns = useColumns();
  const [canCreate] = useCreatePermission("FollowupTaskConfig");
  const { tableRef, allFilters } = useAdministrateTable();

  const onCreate = () => {
    const { refresh, setMessage } = tableRef.current;

    setMessage("Created");
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  return (
    <SystemConfigLayout title="Follow-up Task Configurations">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/followup_task_configs/export"
            filters={allFilters}
            ref={tableRef}
          />

          <History entityName={"FollowupTaskConfig"} />

          {canCreate && (
            <>
              <ImportFollowupTaskConfigsModal outline className="mx-2" onImport={onImport} />
              <CreateFollowupTaskConfigModal color="primary" onCreate={onCreate} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/followup_task_configs.json"}
        resourceName={"followup_task_config"}
        permissions={[UPDATE, DESTROY]}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
