import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { api } from "../util/api";
import { fetchTtl } from "../util/session";
import { FollowupTaskConfigsIndexPage } from "./automations/followup_task_configs/FollowupTaskConfigsIndexPage";
import { InsurancePayorMappingIndexPage } from "./automations/insurance_payor_mappings/InsurancePayorMappingIndexPage";
import { PayorMemberPrefixesShowPage } from "./automations/payor_member_prefixes/PayorMemberPrefixesShowPage";
import { SeeqerReflexMappingIndexPage } from "./automations/seeqer_reflex_mappings/SeeqerReflexMappingIndexPage";
import { StatusMappingIndexPage } from "./automations/status_mappings/StatusMappingIndexPage";
import { BillingAnalysisIndexPage } from "./billing/analysis/BillingAnalysisIndexPage";
import { BillingReportsIndexPage } from "./billing/reports/BillingReportsIndexPage";
import { ClinicsIndexPage } from "./clinics/ClinicsIndexPage";
import { ClinicsShowPage } from "./clinics/ClinicsShowPage";
import { CptCodeIndexPage } from "./cpt_codes/CptCodeIndexPage";
import { GroupsIndexPage } from "./groups/GroupsIndexPage";
import { GroupsShowPage } from "./groups/GroupsShowPage";
import { HomePage } from "./home/HomePage";
import { Icd10CodesIndexPage } from "./icd10_codes/Icd10CodesIndexPage";
import { LabsIndexPage } from "./labs/LabsIndexPage";
import { LabsShowPage } from "./labs/LabsShowPage";
import { PayorsIndexPage } from "./payors/PayorsIndexPage";
import { PayorsShowPage } from "./payors/PayorsShowPage";
import { PayorPathsIndexPage } from "./payor_paths/PayorPathsIndexPage";
import { PayorGroupsIndexPage } from "./payor_groups/PayorGroupsIndexPage";
import { PayorGroupsShowPage } from "./payor_groups/PayorGroupsShowPage";
import { PermissionContextProvider } from "./PermissionContext";
import { PoliciesIndexPage } from "./policies/PoliciesIndexPage";
import { PoliciesShowPage } from "./policies/PoliciesShowPage";
import { PortalShowPage } from "./portals/PortalShowPage";
import { PortalsIndexPage } from "./portals/PortalsIndexPage";
import { QuickActionManagementPage } from "./quick_action_managements/QuickActionManagementPage";
import { Routes } from "./Routes";
import { SystemConfigNavbar } from "./SystemConfigNavbar";
import { ClinicalGlossaryIndex } from "./tools/clinical_glossary/ClinicalGlossaryIndex";
import { PullBenefitsLogDetailPage } from "./tools/pull_benefits_log/PullBenefitsLogDetailPage";
import { PullBenefitsLogPage } from "./tools/pull_benefits_log/PullBenefitsLogPage";
import { ReprocessBICasesPage } from "./tools/reprocess_bi_cases/ReprocessBICasesPage";
import { IngestionPage } from "./tools/ingestion/IngestionPage";
import { RibbonNpiUploadPage } from "./tools/ribbon_npi_upload/RibbonNpiUploadPage";
import { RestatusPACasesPage } from "./tools/restatus_pa_cases/RestatusPACasesPage";
import { SandboxIndex } from "./tools/sandbox/SandboxIndex";
import { PayorTestMappingImport } from "./tools/secret/PayorTestMappingImport";
import { TestCategoryIndex } from "./tools/test_category_management/TestCategoryIndex";
import { InvalidMemberIdsIndex } from "./tools/invalid_member_ids/InvalidMemberIdsIndex";
import { UsersIndexPage } from "./users/UsersIndexPage";
import { UsersShowPage } from "./users/UsersShowPage";
import { VisualManagementIndex } from "./submit_right/visual_management/VisualManagementIndex";
import { CaseTipsIndex } from "./submit_right/case_tips/CaseTipsIndex";
import { ProviderSpecificSubmissionsIndex } from "./submit_right/provider_specific_submissions/ProviderSpecificSubmissionsIndex";
import { PaRequirementsIndexPage } from "./automations/pa_requirements/PaRequirementsIndexPage";
import { PaRequirementsReviewTab } from "./automations/pa_requirements/tabs/PaRequirementsReviewTab";
import { PayorsPlanTypeNetworkStatusTab } from "./payors/tabs/PayorsPlanTypeNetworkStatusTab";
import { UiPathBotPage } from "./automations/uipath_bot/UiPathBotIndexPage";
import { PaperResponsesIndex } from "./submit_right/paper_responses/PaperResponsesIndex";
import { featureEnabled } from "../util/feature";
import { BenefitPullTypePage } from "./benefit_pull_types/BenefitPullTypePage";
import { PhoneResponsesIndex } from "./submit_right/phone_responses/PhoneResponsesIndex";
import { TestRepositoryIndexPage } from "./test_repository/TestRepositoryIndexPage";
import { MissingInfoCategoriesIndex } from "./submit_right/missing_info_categories/MissingInfoCategoriesIndex";
import { SuperDialMessages } from "./tools/superdial_messages/SuperDialMessagesIndex.js";
import { SuperDialScripts } from "./tools/superdial_scripts/SuperDialScriptsIndex.js";
import { UIPathTestCasesIndex } from "./tools/uipath_test_cases/UIPathTestCasesIndex.js";
import { PreprocessingCategoriesIndex } from "./tools/preprocessing_categories/PreprocessingCategoriesIndex.js";
import { AuthTokenIndexPage } from "./auth_tokens/AuthTokenIndexPage.js";
import { AuthTokenShowPage } from "./auth_tokens/AuthTokenShowPage.js";

function getNavbarRoutes({ showSandbox }) {
  const NAVBAR_ROUTES = [
    Routes.home,
    {
      label: "Automation",
      items: [
        Routes.followupTaskConfigs,
        Routes.paRequirementsIndex,
        Routes.payorMemberPrefixes,
        Routes.insurancePayorMappings,
        Routes.statusMappings,
        Routes.seeqerReflexMappings,
        Routes.uiPathAutomation,
      ],
    },
    { label: "Billing", items: [Routes.billingReports, Routes.billingAnalysis] },
    Routes.clinics,
    Routes.labs,
    { label: "Payors", items: [Routes.payors, Routes.payorGroups, Routes.payorPaths, Routes.policies] },
    Routes.portals,
    { label: "Users", items: [Routes.users, Routes.groups, Routes.quickActionManagement, Routes.authTokens] },
    { label: "Tests & Codes", items: [Routes.procedureCodes, Routes.icd10Codes, Routes.benefitPullType, Routes.testRepository] },
    {
      label: "Tools",
      items: [
        Routes.clinicalGlossary,
        Routes.restatusPACases,
        Routes.reprocessBICases,
        Routes.testCategory,
        Routes.pullBenefitsLogs,
        Routes.ingestion,
        Routes.ribbonNpiUpload,
        Routes.invalidMemberIds,
        Routes.superDialMessages,
        Routes.superDialScripts,
        Routes.uiPathTestCases,
        Routes.preprocessingCategories,
      ]
        .concat(showSandbox ? [Routes.sandbox] : [])
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
  ];

  if (featureEnabled("submit_right_two_point_o")) {
    const submit_right_routes = {
      label: "Submit Right",
      items: [
        Routes.visualManagement,
        Routes.caseTips,
        Routes.providerSpecificSubmissions,
        Routes.phoneResponses,
        Routes.paperResponses,
        Routes.missingInfoCategories,
      ],
    };

    NAVBAR_ROUTES.push(submit_right_routes);
  }

  return NAVBAR_ROUTES;
}

const routes = [
  { path: Routes.followupTaskConfigs.path, Component: FollowupTaskConfigsIndexPage },
  { path: Routes.payorMemberPrefixes.path, Component: PayorMemberPrefixesShowPage },
  { path: Routes.insurancePayorMappings.path, Component: InsurancePayorMappingIndexPage },
  { path: Routes.statusMappings.path, Component: StatusMappingIndexPage },
  { path: Routes.seeqerReflexMappings.path, Component: SeeqerReflexMappingIndexPage },
  { path: Routes.lab.path, Component: LabsShowPage },
  { path: Routes.labs.path, Component: LabsIndexPage },
  { path: Routes.clinic.path, Component: ClinicsShowPage },
  { path: Routes.clinics.path, Component: ClinicsIndexPage },
  { path: Routes.user.path, Component: UsersShowPage },
  { path: Routes.users.path, Component: UsersIndexPage },
  { path: Routes.group.path, Component: GroupsShowPage },
  { path: Routes.groups.path, Component: GroupsIndexPage },
  { path: Routes.authToken.path, Component: AuthTokenShowPage },
  { path: Routes.authTokens.path, Component: AuthTokenIndexPage },
  { path: Routes.quickActionManagement.path, Component: QuickActionManagementPage },
  { path: Routes.policy.path, Component: PoliciesShowPage },
  { path: Routes.policies.path, Component: PoliciesIndexPage },
  { path: Routes.payor.path, Component: PayorsShowPage },
  { path: Routes.payors.path, Component: PayorsIndexPage },
  { path: Routes.payorPaths.path, Component: PayorPathsIndexPage },
  { path: Routes.payorPlanTypeNetworkStatus.path, Component: PayorsPlanTypeNetworkStatusTab },
  { path: Routes.payorGroup.path, Component: PayorGroupsShowPage },
  { path: Routes.payorGroups.path, Component: PayorGroupsIndexPage },
  { path: Routes.portal.path, Component: PortalShowPage },
  { path: Routes.portals.path, Component: PortalsIndexPage },
  { path: Routes.clinicalGlossary.path, Component: ClinicalGlossaryIndex },
  { path: Routes.restatusPACases.path, Component: RestatusPACasesPage },
  { path: Routes.reprocessBICases.path, Component: ReprocessBICasesPage },
  { path: Routes.ingestion.path, Component: IngestionPage },
  { path: Routes.ribbonNpiUpload.path, Component: RibbonNpiUploadPage },
  { path: Routes.testCategory.path, Component: TestCategoryIndex },
  { path: Routes.pullBenefitsLogs.path, Component: PullBenefitsLogPage },
  { path: Routes.benefitsDetail.path, Component: PullBenefitsLogDetailPage },
  { path: Routes.billingReports.path, Component: BillingReportsIndexPage },
  { path: Routes.billingAnalysis.path, Component: BillingAnalysisIndexPage },
  { path: Routes.procedureCodes.path, Component: CptCodeIndexPage },
  { path: Routes.testRepository.path, Component: TestRepositoryIndexPage },
  { path: Routes.icd10Codes.path, Component: Icd10CodesIndexPage },
  { path: Routes.benefitPullType.path, Component: BenefitPullTypePage },
  { path: Routes.invalidMemberIds.path, Component: InvalidMemberIdsIndex },
  { path: Routes.sandbox.path, Component: SandboxIndex },
  { path: Routes.superDialMessages.path, Component: SuperDialMessages },
  { path: Routes.superDialScripts.path, Component: SuperDialScripts },
  { path: Routes.uiPathTestCases.path, Component: UIPathTestCasesIndex },
  { path: Routes.preprocessingCategories.path, Component: PreprocessingCategoriesIndex },

  // Submit Right Routes
  { path: Routes.visualManagement.path, Component: VisualManagementIndex },
  { path: Routes.caseTips.path, Component: CaseTipsIndex },
  { path: Routes.providerSpecificSubmissions.path, Component: ProviderSpecificSubmissionsIndex },
  { path: Routes.phoneResponses.path, Component: PhoneResponsesIndex },
  { path: Routes.paperResponses.path, Component: PaperResponsesIndex },
  { path: Routes.missingInfoCategories.path, Component: MissingInfoCategoriesIndex },

  // PA Requirements Routes
  { path: Routes.paRequirementsIndex.path, Component: PaRequirementsIndexPage },
  { path: Routes.paRequirementsEdit.path, Component: PaRequirementsReviewTab },

  { path: Routes.uiPathAutomation.path, Component: UiPathBotPage },

  // Add new routes above home path
  { path: Routes.home.path, Component: HomePage },

  // SECRET ROUTES
  { path: Routes.payorTestMappingsImport.path, Component: PayorTestMappingImport },
];

const SystemAppSessionSpy = ({ children }) => {
  const { listen } = useHistory();

  // This effect is to make sure that we reset the server-side
  // timeout on every react-router navigation event; this is
  // handled automatically when we change rails routes, but the
  // client-side routing does not reset the timer and so no matter
  // how "active" the user is, unless we listen and reset with
  // this GET request, they will timeout at 30 minutes.
  useEffect(() => {
    const unlisten = listen(() => {
      api.get("/reset_timeout").then(() => fetchTtl());
    });

    return unlisten;
  }, [listen]);

  return <>{children}</>;
};

export const SystemConfigApp = ({ showSandbox }) => {
  return (
    <Router basename="/admin/system">
      <PermissionContextProvider>
        <SystemConfigNavbar routes={getNavbarRoutes({ showSandbox })} />

        <SystemAppSessionSpy>
          <Container fluid className="mt-3">
            <Row>
              <Col>
                <Switch>
                  {routes.map(({ path, Component }) => (
                    <Route key={path} path={path}>
                      <Component />
                    </Route>
                  ))}
                </Switch>
              </Col>
            </Row>
          </Container>
        </SystemAppSessionSpy>
      </PermissionContextProvider>
    </Router>
  );
};
