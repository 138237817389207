import _ from "lodash";
import React from "react";
import { FormGroup, Label } from "reactstrap";

export const serializeInput =
  (Component) =>
  ({ delimiter = ",", input, ...rest }) => {
    const splitValue = (val) => {
      // TODO: This is indicative that we probably shouldn't be using serializeInput
      //  since the wrapped component seems to support multi-select
      if (Array.isArray(val)) {
        return val;
      }
      const splitVal = input.value ? val.toString().split(delimiter) : [];
      return _.castArray(splitVal);
    };

    const handleChange = (arrVal) => {
      const joinedValue = _.isArray(arrVal) ? arrVal.join(delimiter) : arrVal;
      input.onChange(joinedValue);
    };

    return <Component input={{ ...input, onChange: handleChange, value: splitValue(input.value) }} {...rest} />;
  };

export const InputWrapper = ({ children, label, required, htmlFor, ...rest }) => (
  <FormGroup {...rest}>
    {label && (
      <Label htmlFor={htmlFor}>
        {label}
        {required && <span className="required-mark">*</span>}
      </Label>
    )}

    {children}
  </FormGroup>
);
