import React from "react";
import { ActionAlert } from "../../../../components/ActionAlert";
import { WizardContext } from "../../WizardContext";
import { Button } from "reactstrap";

export const OtherMissingInfoCollectionScreen = () => (
  <WizardContext.Consumer>
    {({ dispatch }) => {
      return (
        <ActionAlert
          color="info"
          actions={
            <>
              <Button color="info" onClick={() => dispatch({ type: "gonext" })}>
                OK
              </Button>
            </>
          }
        >
          Collect required missing info and click <strong>OK</strong> to continue.
        </ActionAlert>
      );
    }}
  </WizardContext.Consumer>
);
