import { initialize } from "launchdarkly-js-client-sdk";

const context = (() => {
  const clientContext = Array.from(window.featureContext);
  if (!clientContext || clientContext.length === 0)
    return {
      kind: "user",
      key: "anonymous",
      name: "Anonymous User",
    };

  if (clientContext.length === 1) return clientContext[0];

  // DOC: https://launchdarkly.github.io/js-client-sdk/interfaces/LDMultiKindContext.html
  return clientContext.reduce(
    (acc, current) => {
      const { kind, ...rest } = current;
      return {
        ...current,
        [kind]: rest,
      };
    },
    { kind: "multi" }
  );
})();
const client = initialize(window.featureClientId, context, {
  bootstrap: window.featureBootstrap,
  diagnosticOptOut: true,
});

const getValue = (key, def = false) => {
  return client.variation(key, def);
};

export const getFeature = getValue;
export const featureEnabled = getValue;
