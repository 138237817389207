import React from "react";
import { WizardContext } from "../../WizardContext";
import { MISSING_CLINICAL_INFO_SUBSTATUS, MISSING_CLINIC_GC_INFO_SUBSTATUS } from "../missinginfo_steps";
import { MessageDetailsNoAnswerScreen } from "./MessageDetailsNoAnswerScreen";
import { PatientDemographicsNoAnswerScreen } from "./PatientDemographicsNoAnswerScreen";

export const NoAnswerScreen = () => {
  return (
    <WizardContext.Consumer>
      {({ state }) => {
        return (
          <>
            <h5>MI Notification</h5>

            {state.answers.substatus == MISSING_CLINICAL_INFO_SUBSTATUS || state.answers.substatus === MISSING_CLINIC_GC_INFO_SUBSTATUS ? (
              <MessageDetailsNoAnswerScreen />
            ) : (
              <PatientDemographicsNoAnswerScreen />
            )}
          </>
        );
      }}
    </WizardContext.Consumer>
  );
};
