import React from "react";
import { CollapsePanel } from "./CollapsePanel";
import { DetailGrid, DetailGridItem } from "./DetailGrid";
import { Token } from "./Token";

export const TestInfo = ({ testInfo, isPharmacyOwnedByClinic }) => {
  const testData = testInfo || { icd10Codes: [], cptCodes: [] };
  const { icd10Codes = [], cptCodes = [] } = testData;

  return (
    <div className="mb-2">
      <CollapsePanel labelText={isPharmacyOwnedByClinic ? "Product or Procedure Info" : "Test Info"}>
        <DetailGrid>
          <DetailGridItem title={isPharmacyOwnedByClinic ? "Ordering Provider" : "Lab Name"} value={testInfo.labName} />
          <DetailGridItem title="Coverage Type" value={testInfo?.coverageType} />
          <div />
          <div />
          <DetailGridItem title={isPharmacyOwnedByClinic ? "Product or Procedure" : "Test Name"} value={testInfo.name} />

          <DetailGridItem
            title={isPharmacyOwnedByClinic ? "Procedure Codes" : "CPT Codes"}
            value={cptCodes.map((c, idx) => (
              <Token key={`${c}-${idx}`} className="me-1">
                {c}
              </Token>
            ))}
          />

          <DetailGridItem
            title="ICD Codes"
            value={icd10Codes.map((c, idx) => (
              <Token key={`${c}-${idx}`} className="me-1">
                {c}
              </Token>
            ))}
          />
        </DetailGrid>
      </CollapsePanel>
    </div>
  );
};
