import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { PayorAutoApprovalForm } from "./PayorAutoApprovalForm";
import { PayorPatientCriteriaForm } from "./PayorPatientCriteriaForm";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";

export const CreateAutoApprovalModal = ({ payorId, onCreate, ...rest }) => {
  const [openTestBundle, setOpenTestBundle] = useState(false);
  const [openPatientCriteria, setOpenPatientCriteria] = useState(false);
  const [error, setError] = useState(null);
  const [testBundle, setTestBundle] = useState(null);

  const toggleTestBundleModal = () => setOpenTestBundle(!openTestBundle);
  const togglePatientCriteriaModal = () => setOpenPatientCriteria(!openPatientCriteria);

  const handleSubmitTestBundle = async (values) => {
    setError(null);
    setOpenTestBundle(false);
    setTestBundle(values);
    setOpenPatientCriteria(true);
  };
  const handleSubmitPatientCriteria = async (values) => {
    setError(null);
    try {
      const params = {
        testBundle: {
          payorId: testBundle.payorId,
          name: testBundle.name,
          testIds: testBundle.testIds,
          patientCriteriaAttributes: snakifyKeys(values.patientCriteria.map((c) => _.pickBy(c, _.identity))), // removes null values and snakify the keys
        },
      };

      const res = await api.post("/admin/test_bundles", snakifyKeys(params));

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpenPatientCriteria(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleTestBundleModal} {...rest}>
        Add Test Bundle
      </Button>
      <Modal isOpen={openTestBundle} toggle={toggleTestBundleModal}>
        <ModalHeader toggle={toggleTestBundleModal}>Add Auto-Approval</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={handleSubmitTestBundle} initialValues={{ payorId }}>
            {(props) => <PayorAutoApprovalForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
      <Modal isOpen={openPatientCriteria} toggle={togglePatientCriteriaModal}>
        <ModalHeader toggle={togglePatientCriteriaModal}>Add Auto-Approval</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form
            onSubmit={handleSubmitPatientCriteria}
            mutators={{ ...arrayMutators }}
            initialValues={{ patientCriteria: [{ icd10CodeIds: [], age: "", gender: "" }] }}
            keepDirtyOnReinitialize={true}
          >
            {(props) => <PayorPatientCriteriaForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
