import classnames from "classnames";
import { useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { FormGroup, Label } from "reactstrap";
import { Icd10Selector } from "../../../components/inputs/Icd10Selector";

const HL7_JOIN = "|";

const IcdSelectApp = ({ initialIcds, inputName, label, required }) => {
  const hl7Codes = initialIcds.map((icd) => icd.label);
  const [selectedIcds, setSelectedIcds] = useState(hl7Codes);
  const hl7Value = useMemo(() => selectedIcds.join(HL7_JOIN), [selectedIcds]);

  return (
    <FormGroup>
      {label && (
        <Label className={classnames({ required })}>
          {label} {required && <abbr title="required">*</abbr>}
        </Label>
      )}
      <Icd10Selector hl7={true} initialOptions={initialIcds} value={selectedIcds} onChange={setSelectedIcds} valid isMulti />
      <input type="hidden" name={inputName} value={hl7Value} />
    </FormGroup>
  );
};

export const initIcdSelect = () => {
  const selectContainers = Array.from(document.getElementsByClassName("icd_selector"));

  selectContainers.forEach(function (container) {
    const root = createRoot(container);
    const { initialIcds, inputName, label, required } = container.dataset;

    root.render(<IcdSelectApp initialIcds={JSON.parse(initialIcds)} required={JSON.parse(required)} inputName={inputName} label={label} />);
  });
};
