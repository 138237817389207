import React from "react";
import { Field } from "react-final-form";
import { required } from "../../../util/validators";
import { LoadingButton } from "../../../components/LoadingButton";
import { CptSelector } from "../../../components/inputs/final_form/CptSelector";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

const CASE_TYPE_OPTIONS = [
  { value: "type_prior_auth", label: "Prior Auth" },
  { value: "type_benefits_investigation", label: "Benefits Investigation" },
];

export const CaseTypeCptsForm = ({ handleSubmit, submitting, values }) => {
  return (
    <>
      <Field component={SelectInput} label="Case Type" options={CASE_TYPE_OPTIONS} name="caseType" required validate={required} />

      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/benefit_management_portals.json"
        label="Portal"
        name="benefitManagementPortalId"
        searchParam="name"
      />

      <Field component={AdministrateSelectInput} resourceUrl="/admin/payors.json" label="Payor" name="payorId" searchParam="name" />

      <Field
        component={AdministrateSelectInput}
        resourceUrl={`/admin/tests.json?lab[id]=${values?.labId}`}
        label="Test"
        name="testId"
        required
        validate={required}
      />

      <Field
        component={AdministrateSelectInput}
        resourceUrl={`/admin/labs/plan_type_groups.json`}
        label="Plan Type"
        name="planTypeGroup"
        labelKey="label"
        valueKey="value"
      />

      <Field component={CptSelector} label="CPT Codes" name="cptCodes" required validate={required} />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
