import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { Col, FormGroup } from "reactstrap";
import { SerializedCptSelector } from "../components/inputs/final_form/CptSelector";
import { DateInput } from "../components/inputs/final_form/DateInput";
import { SerializedIcd10Selector } from "../components/inputs/final_form/Icd10Selector";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { composeValidators, required } from "../util/validators";
import { FormRow } from "../components/forms/FormRow";
import { Card, CardBody } from "reactstrap";
import { DropzoneInput } from "../components/inputs/final_form/DropzoneInput";
import { AttachmentsListGroup } from "../components/AttachmentsListGroup";
import { SITE_OF_SERVICE_OPTIONS } from "../system_config/benefit_pull_types/BenefitPullTypePage";
import { useMappings, mapMappingsToNameOptions, mapMappingsToIdOptions } from "./TestAndRequestInfoHelperMethods";
import { Switch } from "../components/inputs/final_form/Switch";
import { api } from "../util/api";

export const TestAndRequestInfoFields = ({
  isClinicUser,
  isRequestingProviderTypePharmacy,
  requestingProvider,
  values,
  form,
  ...formProps
}) => {
  const shouldUpdateLabel = isClinicUser && isRequestingProviderTypePharmacy;
  const { mappings, loadingMappings } = useMappings(values.labId, values.orderedWorkflows, values.allTests, shouldUpdateLabel);
  const [autoMapCpts, setAutoMapCpts] = useState();
  const [cptCodeFieldTouched, setCptCodeFieldTouched] = useState(false);
  const [selectedTestNames, setSelectedTestNames] = useState([]);
  const [testNameFocused, setTestNameFocused] = useState(false);
  const showOptionalFields = values.orderedWorkflows === "priorAuthRequest";
  const existingFiles = _.get(values, "supplementalDocuments.files", []);
  const physicianName = requestingProvider?.name?.split(" ") ?? [];

  const testIdentifierChangeHandler = (value) => {
    const selectedMappingIds = value;
    const selectedMappings = _.map(selectedMappingIds, (id) => _.find(mappings, (m) => m.id === id));
    const selectedIdentifiers = _.map(selectedMappings, (m) => m?.identifier) || _.get(values, "testInfo.testIdentifier");
    const selectedTestIds = _.map(selectedMappings, (m) => m?.name) || _.get(values, "testInfo.verifiedTestIds");

    form.mutators.setTestsMutator(selectedIdentifiers, selectedTestIds, value);
  };

  const testNameChangeHandler = (value, previous) => {
    if (testNameFocused) {
      const selectedNameMappingIds = setMappingsSelectedTestNames(value, previous);
      const selectedNamesMappings = _.map(selectedNameMappingIds, (id) => _.find(mappings, (m) => m.id === id));

      if (!values.testInfo.cptCodes || autoMapCpts) {
        const cptCodes = _.map(selectedNamesMappings, (m) => m?.cpt_codes).join(",");
        const previousCptCodes = autoMapCpts;

        setAutoMapCpts(cptCodes);

        if (!cptCodes.includes(previousCptCodes) && previousCptCodes) {
          let updatedCptCodes = previousCptCodes.replace(cptCodes, "");
          updatedCptCodes = values.testInfo.cptCodes?.replace(updatedCptCodes, "") || "";

          form.mutators.setCptCodesMutator(updatedCptCodes);
        } else if (!cptCodeFieldTouched) {
          form.mutators.setCptCodesMutator(cptCodes);
        }
      }
    }

    const selectedMappingIds = value;
    const selectedMappings = _.map(selectedMappingIds, (id) => _.find(mappings, (m) => m.id === id));
    const selectedIdentifiers = _.map(selectedMappings, (m) => m?.identifier) || _.get(values, "testInfo.testIdentifier");
    const selectedTestIds = _.map(selectedMappings, (m) => m?.id) || _.get(values, "testInfo.verifiedTestIds");

    form.mutators.setTestsMutator(selectedIdentifiers, selectedTestIds, value);
  };

  const cptCodesChangeHandler = (value, previous) => {
    if (value.length < previous.length) {
      const removedCptCode = _.difference(previous.split(","), value.split(","))[0];
      let autoMappedCpts = autoMapCpts;

      if (autoMappedCpts && autoMappedCpts.includes(removedCptCode)) {
        autoMappedCpts = autoMappedCpts.replace(removedCptCode, "");
        autoMappedCpts = autoMappedCpts.replace(",,", ",");
        setAutoMapCpts(autoMappedCpts);
      }
    }

    if (form.getState().active === "testInfo.cptCodes") {
      setCptCodeFieldTouched(true);
    }

    if (!value) {
      setCptCodeFieldTouched(false);
    }
  };

  const setMappingsSelectedTestNames = (value, previous) => {
    let selectedTestNameMappings = selectedTestNames;

    if (_.difference(value, previous).length) {
      selectedTestNameMappings = selectedTestNameMappings.concat(_.difference(value, previous));
    } else if (_.difference(previous, value).length) {
      _.remove(selectedTestNameMappings, (mapping) => _.difference(previous, value).includes(mapping));
    }

    setSelectedTestNames(selectedTestNameMappings);

    return selectedTestNameMappings;
  };

  const testIdentifierFocusHandler = () => {
    setTestNameFocused(false);
  };

  const testNameFocusHandler = () => {
    setTestNameFocused(true);
  };

  return (
    <>
      <h5 className="mb-4">{shouldUpdateLabel ? "Product or Procedure Info" : "Test & Request Info"}</h5>
      <FormGroup>
        <FormRow>
          <Col>
            <Field name="testInfo.dateOfService" render={DateInput} label="Date of Service" data-testid="dateOfService" />
          </Col>
          <Col>
            <Field name="testInfo.collectionDate" render={DateInput} label="Collection Date" data-testid="collectionDate" />
          </Col>
          {showOptionalFields && (
            <Col>
              <Field name="testInfo.externalClinicId" render={TextInput} label="Clinic ID" data-testid="clinicID" />
            </Col>
          )}
        </FormRow>

        <FormRow>
          {showOptionalFields && (
            <>
              <Col>
                <Field name="testInfo.collectionType" render={TextInput} label="Collection Type" data-testid="collectionType" />
              </Col>
              <Col>
                <Field name="requestInfo.accessionDate" component={DateInput} label="Accession Date" data-testid="accessionDate" />
              </Col>
            </>
          )}
          <Col>
            <Field
              name="requestInfo.orderId"
              component={TextInput}
              label={shouldUpdateLabel ? "Order ID" : "Lab Order ID"}
              data-testid="labOrderID"
            />
          </Col>
        </FormRow>

        <Card className="w-100 mb-3 bg-info bg-opacity-10 border border-0">
          <CardBody>
            <FormRow>
              {isClinicUser && !isRequestingProviderTypePharmacy && (
                <Col>
                  <Field id="allTests" component={Switch} label={"Show all tests"} name="allTests" type="checkbox" />
                </Col>
              )}
            </FormRow>
            <FormRow>
              <Col>
                <Col>
                  <Field
                    required
                    name="testInfo.verifiedTestIds"
                    isMulti
                    validate={composeValidators(required)}
                    disabled={loadingMappings}
                    component={(props) => (
                      <SelectInput
                        onFocus={testNameFocusHandler}
                        {...props}
                        options={mapMappingsToNameOptions(mappings)}
                        data-testid="verifiedTestIds"
                      />
                    )}
                    label={shouldUpdateLabel ? "Product or Procedure Name" : "Test Name"}
                  />
                </Col>
              </Col>
              {showOptionalFields && (
                <Col>
                  <Field
                    name="testInfo.testIdentifierfield"
                    isMulti
                    disabled={loadingMappings}
                    component={(props) => (
                      <SelectInput
                        onFocus={testIdentifierFocusHandler}
                        {...props}
                        options={mapMappingsToIdOptions(mappings)}
                        data-testid="testIdentifier"
                      />
                    )}
                    label="Test Identifier"
                  />
                </Col>
              )}
              <OnChange name="testInfo.testIdentifierfield">{testIdentifierChangeHandler}</OnChange>
              <OnChange name="testInfo.verifiedTestIds">{testNameChangeHandler}</OnChange>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  required
                  validate={composeValidators(required)}
                  name="testInfo.cptCodes"
                  render={SerializedCptSelector}
                  label={shouldUpdateLabel ? "Procedure Codes" : "CPT Codes"}
                  data-testid="cptCodes"
                />
                <OnChange name="testInfo.cptCodes">{cptCodesChangeHandler}</OnChange>
              </Col>

              <Col>
                <Field
                  required
                  name="testInfo.icd10Codes"
                  validate={composeValidators(required)}
                  render={SerializedIcd10Selector}
                  label="ICD10 Codes"
                  data-testid="icd10Codes"
                  hl7={true}
                />
              </Col>
              <Col>
                <Field
                  label="Supplemental Documents"
                  name="supplementalDocuments.newFiles"
                  component={DropzoneInput}
                  multiple
                  data-testid="supplementalDocuments"
                />
                {existingFiles && existingFiles.length > 0 && (
                  <>
                    <strong className="small text-muted mb-2">Read Only</strong>
                    <AttachmentsListGroup attachments={_.compact(existingFiles)} />
                  </>
                )}
              </Col>
            </FormRow>
          </CardBody>
        </Card>

        {isClinicUser && isRequestingProviderTypePharmacy && (
          <>
            <Field name="providerInfo.physicianFirstName" component={TextInput} type="hidden" initialValue={physicianName[0]} />
            <Field name="providerInfo.physicianLastName" component={TextInput} type="hidden" initialValue={physicianName[1]} />
            <Field name="providerInfo.physicianNPI" component={TextInput} type="hidden" initialValue={requestingProvider?.npi} />
            <Field
              component={SelectInput}
              label="Site of Service"
              options={SITE_OF_SERVICE_OPTIONS}
              name="providerInfo.siteOfService"
              required
              validate={required}
            />
          </>
        )}
      </FormGroup>
    </>
  );
};
