import React from "react";
import { CHECK_PORTAL_STEP, STEPS } from "./screens/digital_steps";
import { Wizard } from "./Wizard";

export const DigitalFollowupWizard = (props) => {
  const {
    commentsUrl,
    snoozeUrl,
    tasksUrl,
    submitUrl,
    nextStepUrl,
    payorContacts,
    caseId,
    caseData,
    benefitsPortal,
    successfulAttempt,
    statusUrl,
  } = props;

  const inMedicalReview = "medicalreview" === caseData?.workflowInfo?.workflowStatus?.toLowerCase();

  return (
    <Wizard
      name="Digital Followup"
      initialStep={CHECK_PORTAL_STEP}
      caseId={caseId}
      steps={STEPS}
      initialAnswers={{}}
      inMedicalReview={inMedicalReview}
      commentsUrl={commentsUrl}
      snoozeUrl={snoozeUrl}
      tasksUrl={tasksUrl}
      submitUrl={submitUrl}
      nextStepUrl={nextStepUrl}
      payorContacts={payorContacts}
      caseData={caseData}
      benefitsPortal={benefitsPortal}
      successfulAttempt={successfulAttempt}
      statusUrl={statusUrl}
    />
  );
};
