import { createRoot } from "react-dom/client";
import { ClinicalGlossaries } from "../clinical_glossaries/ClinicalGlossaries";

export const initClininicalGlossaries = () => {
  const appMount = document.getElementById("clinical_glossaries");

  if (appMount) {
    const root = createRoot(appMount);
    root.render(<ClinicalGlossaries />);
  }
};
