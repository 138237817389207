import React from "react";
import { Button, Card, CardBody } from "reactstrap";

export const YesNoPanel = ({ question, yesAction, noAction, yesLabel = "Yes", noLabel = "No", displayActions = true, children }) => {
  return (
    <Card>
      {children && <CardBody className="mt-5 text-center">{children}</CardBody>}
      {question && (
        <CardBody className="mt-5 text-center">
          <h5>{question}</h5>
        </CardBody>
      )}

      {displayActions && (
        <CardBody className="mb-5 text-center">
          <Button style={{ minWidth: 200 }} className="me-2" onClick={yesAction} color="primary">
            {yesLabel}
          </Button>
          <Button style={{ minWidth: 200 }} onClick={noAction} outline>
            {noLabel}
          </Button>
        </CardBody>
      )}
    </Card>
  );
};
