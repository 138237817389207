import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Col, Form, FormGroup, FormText, Input, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import { removeField, selectFieldById, updateField } from "../store";
import { ValuesSelect } from "./ValuesSelect";

const VALID_TYPES = [
  { value: "text", label: "Text" },
  { value: "bool", label: "Yes/No" },
  { value: "checkmark", label: "Checkmark" },
  { value: "date", label: "Date" },
  { value: "select", label: "Dropdown" },
];

const VALID_COLORS = [
  { value: "", label: "None" },
  { value: "#FFFD7B", label: "Yellow" },
  { value: "#FFC85D", label: "Orange" },
  { value: "#FC6EA9", label: "Pink" },
];

const CoordinateInput = ({ xLabel = "X", yLabel = "Y", x = 0, y = 0, onChange, ...props }) => {
  return (
    <Row>
      <Col>
        <InputGroup size="sm">
          <InputGroupText>{xLabel}</InputGroupText>
          <Input onChange={(e) => onChange({ x: parseInt(e.target.value, 10), y })} className="ps-1 pe-2" type="number" min="0" value={x} />
        </InputGroup>
      </Col>
      <Col>
        <InputGroup size="sm">
          <InputGroupText>{yLabel}</InputGroupText>
          <Input onChange={(e) => onChange({ x, y: parseInt(e.target.value, 10) })} className="ps-1 pe-2" type="number" min="0" value={y} />
        </InputGroup>
      </Col>
    </Row>
  );
};

const CheckmarkInspector = ({ fieldId, onChange, size = 16, invert = false }) => {
  return (
    <Form>
      <FormGroup>
        <Label>Checkmark Size</Label>
        <Input type="number" value={size} onChange={(e) => onChange(fieldId, parseInt(e.target.value, 10), "options.size")} />
      </FormGroup>

      <FormGroup check>
        <Label check>
          <Input type="checkbox" checked={invert} onChange={(e) => onChange(fieldId, e.target.checked, "options.invert")} />
          Invert Checkmark
        </Label>

        <FormText color="muted">
          This will invert the checkmark so that <code>false</code> results in a checkmark.
        </FormText>
      </FormGroup>
    </Form>
  );
};

const TextInspector = ({ fieldId, width, height, spacing = 0, font: fontSize = 14, background = "", onChange }) => {
  return (
    <Form>
      <FormGroup>
        <Label>Size</Label>
        <CoordinateInput
          x={width || ""}
          y={height || ""}
          xLabel="W"
          yLabel="H"
          onChange={(coords) => {
            let width = parseInt(coords.x, 10);
            let height = parseInt(coords.y, 10);

            width = _.isNaN(width) ? null : Math.max(width, 0);
            height = _.isNaN(height) ? null : Math.max(height, 0);

            onChange(fieldId, { width, height }, "options");
          }}
        />
      </FormGroup>

      <FormGroup>
        <Label>Font Size</Label>
        <Input
          bsSize="sm"
          type="number"
          value={fontSize}
          min="10"
          max="28"
          onChange={(e) => onChange(fieldId, parseInt(e.target.value), "options.font")}
        />
      </FormGroup>

      <FormGroup>
        <Label>Text Spacing</Label>
        <Input
          bsSize="sm"
          type="number"
          value={spacing}
          min="0"
          onChange={(e) => onChange(fieldId, Math.max(parseInt(e.target.value, 10), 0), "options.spacing")}
        />
      </FormGroup>

      <FormGroup>
        <Label>Background Color</Label>
        <Input bsSize="sm" type="select" value={background} onChange={(e) => onChange(fieldId, e.target.value, "options.background")}>
          {_.map(VALID_COLORS, (t, i) => (
            <option key={i} value={t.value}>
              {t.label}
            </option>
          ))}
        </Input>
      </FormGroup>
    </Form>
  );
};

const customFieldCheck = (selectedField) => {
  const isCustom = (field) => {
    return field.startsWith("custom");
  };

  const fieldValue = selectedField?.field;
  return _.isArray(fieldValue) ? fieldValue.some(isCustom) : fieldValue?.startsWith("custom");
};

class FieldInspectorComponent extends React.Component {
  updateFieldValue(id, val, path, overwrite = false) {
    const { onUpdateField } = this.props;

    if (!_.isNil(val)) {
      onUpdateField(id, _.set({}, path, val), overwrite);
    }
  }

  render() {
    const { onRemoveField, selectedField, defaultFields, customFields } = this.props;
    const isCustomField = customFieldCheck(selectedField);

    if (_.isNil(selectedField)) {
      return (
        <Card>
          <CardBody>
            <span className="text-muted">Nothing Selected</span>
          </CardBody>
        </Card>
      );
    } else if (selectedField.required === undefined && !isCustomField) {
      // defaults required to true
      this.updateFieldValue(selectedField.id, true, "required");
    }

    return (
      <React.Fragment>
        <Card className="mb-3">
          <CardBody>
            <Form>
              <FormGroup>
                <Label>Position</Label>
                <CoordinateInput
                  x={_.get(selectedField, "position.at.x")}
                  y={_.get(selectedField, "position.at.y")}
                  onChange={(coords) => this.updateFieldValue(selectedField.id, coords, "position.at")}
                />
              </FormGroup>

              <FormGroup>
                <Label>Field Label</Label>
                <Input
                  bsSize="sm"
                  type="text"
                  value={selectedField.label}
                  onChange={(e) => this.updateFieldValue(selectedField.id, e.target.value, "label")}
                />
              </FormGroup>

              <FormGroup>
                <Label>Field Type</Label>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedField.type}
                  onChange={(e) => this.updateFieldValue(selectedField.id, e.target.value, "type")}
                >
                  {_.map(VALID_TYPES, (t, i) => (
                    <option key={i} value={t.value}>
                      {t.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Field Value</Label>
                <ValuesSelect
                  value={selectedField.field}
                  defaultFields={defaultFields}
                  customFields={customFields}
                  onChange={(values) => this.updateFieldValue(selectedField.id, values, "field", true)}
                />
              </FormGroup>

              {!isCustomField && (
                <FormGroup switch>
                  <Label check>Required</Label>
                  <Input
                    type="switch"
                    checked={selectedField.required}
                    onChange={(e) => this.updateFieldValue(selectedField.id, e.target.checked, "required")}
                  />
                </FormGroup>
              )}
            </Form>

            <hr />

            {selectedField.type === "checkmark" && (
              <CheckmarkInspector
                onChange={(i, v, p) => this.updateFieldValue(i, v, p)}
                fieldId={selectedField.id}
                {...selectedField.options}
              />
            )}
            {selectedField.type === "text" && (
              <TextInspector onChange={(i, v, p) => this.updateFieldValue(i, v, p)} fieldId={selectedField.id} {...selectedField.options} />
            )}
            {selectedField.type === "date" && (
              <TextInspector onChange={(i, v, p) => this.updateFieldValue(i, v, p)} fieldId={selectedField.id} {...selectedField.options} />
            )}
          </CardBody>
        </Card>

        <Button block size="sm" onClick={() => onRemoveField(selectedField.id)} color="danger">
          Delete Field
        </Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedField: selectFieldById(state, state.selectedField),
    defaultFields: state.defaultFields,
    customFields: state.customFields,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onUpdateField: (fieldId, data, assign = false) => {
    dispatch(updateField({ fieldId, data, assign }));
  },
  onRemoveField: (fieldId) => {
    dispatch(removeField({ fieldId }));
  },
});

export const FieldInspector = connect(mapStateToProps, mapDispatchToProps)(FieldInspectorComponent);
