import React from "react";
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { api } from "../util/api";
import { LoadingIcon } from "../components/LoadingIcon";
import { Check } from "lucide-react";

export class AcceptMatchButton extends React.Component {
  state = {
    loading: false,
    modal: false,
    requestId: "",
    error: false,
  };

  toggle = () => {
    if (this.state.loading) {
      return false;
    }
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  close = () => {
    this.setState({
      modal: false,
      error: false,
      requestId: "",
      loading: false,
    });
  };

  onSubmit = async () => {
    this.setState({ loading: true });
    try {
      await api.post(`/operations/prior_authorize_requests/clinic_mappings/partial/${this.props.id}/accept`);
      window.location.reload();
    } catch (e) {
      this.setState({ error: e.message, loading: false });
      console.error(e);
    }
  };

  render() {
    return (
      <div>
        <Button size="sm" color="success" onClick={this.toggle} outline title="Attach to PA Request">
          <Check />
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.close}>
            Map Request {this.props.publicId} to {this.props.clinic}
          </ModalHeader>
          <ModalBody>
            {this.state.error && <Alert color="danger">{this.state.error}</Alert>}

            <p>
              Accepting this mapping will add request {this.props.publicId} to Clinic {this.props.clinic} and create an automatic mapping
              for:
            </p>

            <ul>
              <li>Lab: {this.props.lab}</li>
              <li>External Clinic ID: {this.props.externalClinicId}</li>
              <li>to Clinic: {this.props.clinic}</li>
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.close} disabled={this.state.loading}>
              Cancel
            </Button>

            <Button color="primary" onClick={this.onSubmit} disabled={this.state.loading}>
              {this.state.loading ? <LoadingIcon /> : "Accept Match"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
