import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { BlankableField } from "../../../../components/inputs/final_form/BlankableField";
import { required } from "../../../../util/validators";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";
import { DropzoneInput } from "../../../../components/inputs/final_form/DropzoneInput";
import { LoadingButton } from "../../../../components/LoadingButton";

export const CaseTipForm = ({ handleSubmit, submitting }) => {
  return (
    <>
      <Field component={AdministrateSelectInput} resourceUrl="/admin/labs.json" label="Lab" name="labId" searchParam="lab_name" />
      <Field component={AdministrateSelectInput} resourceUrl="/admin/payors.json" label="Payor" name="payorId" searchParam="name" />
      <Field component={AdministrateSelectInput} resourceUrl="/admin/tests.json" label="Test" name="testId" searchParam="commercial_name" />
      <BlankableField component={TextInput} label="Physician NPI" name="physicianNpi" type="text" />
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/benefit_management_portals.json"
        label="Portal"
        name="benefitManagementPortalId"
        searchParam="name"
      />

      <Field component={TextInput} type="textarea" required validate={required} label="Case Tip" name="text" />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Save
      </LoadingButton>
    </>
  );
};
