import _ from "lodash";
import { useMemo } from "react";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { TabbedRoutes } from "../../components/TabbedRoutes";
import { useUpdatePermission } from "../../utils";
import { EvaluatePrefixTab } from "./tabs/EvaluatePrefixTab";
import { PayorMemberPrefixesTab } from "./tabs/PayorMemberPrefixesTab";
import { RankOrderTab } from "./tabs/RankOrderTab";

const useTabs = (canUpdate) => {
  return useMemo(
    () =>
      _.compact([
        {
          path: "prefixes",
          label: "Payor Member Prefixes",
          Component: PayorMemberPrefixesTab,
        },
        canUpdate
          ? {
              path: "order",
              label: "Rank Order",
              Component: RankOrderTab,
            }
          : undefined,
        {
          path: "evaluate",
          label: "Evaluate Member ID",
          Component: EvaluatePrefixTab,
        },
      ]),
    [canUpdate]
  );
};

export const PayorMemberPrefixesShowPage = () => {
  const [canUpdate] = useUpdatePermission("PayorPrefixMapping");
  const tabs = useTabs(canUpdate);

  return (
    <SystemConfigLayout title={["Automation", "Payor Member Prefixes"]}>
      <TabbedRoutes tabs={tabs} defaultTab={"prefixes"} />
    </SystemConfigLayout>
  );
};
