import React, { useCallback } from "react";
import { TokenCell } from "../../components/grid/cells/TokenCell";
import { Toolbar } from "../../components/Toolbar";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../utils";
import { Icd10CodeActionCell } from "./components/Icd10CodeActionCell";
import { AdministrateBackgroundExportButton } from "../components/AdministrateBackgroundExportButton";
import { ImportIcd10CodesModal } from "./components/ImportIcd10CodesModal";
import { AddIcd10CodeModal } from "./components/AddIcd10CodeModal";

const useColumns = makeColumns(() => [
  {
    id: "code",
    accessor: "code",
    Header: "Code",
    Cell: TokenCell,
  },
  {
    id: "description",
    accessor: "description",
    Header: "Description",
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: Icd10CodeActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const Icd10CodesIndexPage = () => {
  const columns = useColumns();
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();

  const extraParams = { kept: true };
  const [canCreate] = useCreatePermission("Icd10Code");

  const onImport = useCallback(
    (message, details = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, details.length ? "warning" : "success", details);
    },
    [tableRef]
  );

  const onCreate = useCallback(
    (values) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(`New Icd10 Code "${values.code}" Added.`);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="ICD10 Codes">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/icd10_codes/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreate && (
            <>
              <ImportIcd10CodesModal outline className="me-2" onImport={onImport} />
              <AddIcd10CodeModal color="primary" onCreate={onCreate} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/icd10_codes.json"}
        resourceName={"icd10_code"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
