import _ from "lodash";
import React, { useState } from "react";
import { Form, FormSpy } from "react-final-form";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { AddressFields, ADDRESS_FIELDS } from "../../../components/inputs/final_form/AddressFields";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { LabNpiFields, LabIdentifierFields, LabContactFields } from "./LabFields";

const rekeyValues = (values) => {
  const newPayload = _.cloneDeep(values);
  newPayload.addressAttributes = _.pick(values, ADDRESS_FIELDS);
  return _.omit(newPayload, ADDRESS_FIELDS);
};

export const CreateLabModal = ({ onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [pharmacyFlag, setPharmacyFlag] = useState(true);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);
    try {
      const res = await api.post("/admin/labs", { lab: snakifyKeys(rekeyValues(values)) });

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Lab
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add Lab</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate}>
            {({ handleSubmit, form, submitting }) => {
              return (
                <>
                  <Row>
                    <Col>
                      <FormSpy subscription={{ values: true }}>
                        {({ values }) => {
                          if (values.servicingProviderType === "Pharmacy") {
                            if (pharmacyFlag) {
                              form.change("ribbonNetworkStatus", true);
                              setPharmacyFlag(false);
                            }
                          } else {
                            setPharmacyFlag(true);
                          }
                        }}
                      </FormSpy>
                      <LabIdentifierFields />
                    </Col>
                    <Col>
                      <LabNpiFields form={form} setError={setError} />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <LabContactFields />
                    </Col>
                    <Col>
                      <AddressFields></AddressFields>
                    </Col>
                  </Row>
                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
