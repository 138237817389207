import React from "react";
import _ from "lodash";

export const TrinaryTree = ({ branch, nullBranch, trueBranch, falseBranch }) => {
  if (_.isNil(branch)) {
    return <>{nullBranch}</>;
  }
  if (branch === true) {
    return <>{trueBranch}</>;
  }
  if (branch === false) {
    return <>{falseBranch}</>;
  }
};
