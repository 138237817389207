import { useCallback } from "react";
import { DESTROY, UPDATE, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { Container, Row } from "reactstrap";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { MissingInfoCategoryActionCell } from "./components/MissingInfoCategoryActionCell";
import { BoolCell } from "../../../components/grid/cells/BoolCell";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { AddMissingInfoCategoryModal } from "./components/AddMissingInfoCategoryModal";
import { ImportMissingInfoCategoryModal } from "./components/ImportMissingInfoCategoryModal";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "Id",
  },
  {
    id: "name",
    accessor: "name",
    Header: "Name",
  },
  {
    id: "active",
    accessor: "active",
    Header: "Active",
    Cell: ({ value }) => <BoolCell value={value} />,
    Filter: (props) => <BoolFilter {...props} />,
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: MissingInfoCategoryActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const MissingInfoCategoriesIndex = () => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const columns = useColumns();
  const [canCreate] = useCreatePermission("MissingInfoCategory");

  const onAdd = useCallback(
    (message, errorDetails = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, errorDetails.length ? "warning" : "success", errorDetails);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Missing Info Categories">
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Toolbar className="ml-auto">
            {canCreate && (
              <AdministrateBackgroundExportButton
                outline
                className="me-2"
                exportUrl="/admin/missing_info_categories/export"
                filters={allFilters}
                ref={tableRef}
              />
            )}

            {canCreate && <ImportMissingInfoCategoryModal outline className="me-2" onImport={onAdd} />}

            {canCreate && <AddMissingInfoCategoryModal onAdd={onAdd} color="primary" />}
          </Toolbar>
        </Row>
      </Container>

      <AdministrateTable
        resourceUrl={"/admin/missing_info_categories.json"}
        resourceName={"missing_info_category"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
