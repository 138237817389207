import _ from "lodash";
import React, { useEffect, useState, useMemo } from "react";
import Dropzone from "react-dropzone";
import pluralize from "pluralize";
import { processFiles } from "../../../util/forms";
import { AttachmentsListGroup } from "../../AttachmentsListGroup";
import { InputWrapper } from "./InputWrapper";
import { Paperclip } from "lucide-react";
import { InputGroup, InputGroupText, UncontrolledPopover, Button, PopoverBody } from "reactstrap";
import cx from "classnames";

const getDropzoneClasses = ({ isDragActive, isDragReject, showError }) =>
  cx("dropzone", {
    "dropzone--active": isDragActive,
    "dropzone--reject": isDragReject || showError,
  });

export const DropzoneInput = ({
  children,
  input,
  label,
  meta,
  hint = "Drop Files Here to Add",
  accept = "image/*,application/pdf",
  maxSize,
  ...rest
}) => {
  const { multiple, id, disabled, autoFocus, readOnly } = input;
  const [localFiles, setLocalFiles] = useState(input.value || []);
  const [errors, setErrors] = useState([]);

  const handleDrop = async (droppedFiles) => {
    let filesToProcess = droppedFiles;
    if (!multiple && droppedFiles.length > 1) {
      filesToProcess = [droppedFiles[0]];
    }
    const processedFiles = await processFiles(filesToProcess);
    const newFiles = multiple ? _.uniqBy([...localFiles, ...processedFiles], (f) => f.dataURL) : processedFiles;

    setLocalFiles(newFiles);
    handleChange(newFiles);
  };

  const handleRemoveFile = (attachment) => {
    const newFiles = _.reject(localFiles, (f) => f.dataURL === attachment.dataURL);

    setLocalFiles(newFiles);
    handleChange(newFiles);
    setErrors([]);
  };

  const handleChange = (files) => {
    const { onChange } = input;
    const values = _.map(files, (f) => f.dataURL);

    onChange(values);
  };

  const handleDropRejection = (files) => {
    const filesErrors = [];
    const COMMON_ERRORS = ["file-too-large", "file-invalid-type"];

    files.forEach((file) => {
      file.errors.forEach((error) => {
        if (COMMON_ERRORS.includes(error.code)) {
          const message = error.code === "file-too-large" ? "File is too large" : error.message;
          filesErrors.push(message);
        }
      });
    });

    setErrors(filesErrors);
  };

  // This allows us to reset the input display if
  // the outer form decides to reset the value of the input
  useEffect(() => {
    if (input.value === "") {
      setLocalFiles([]);
    }
  }, [input.value]);

  const numAttachments = Object.values(localFiles).length;
  const showError = (multiple || numAttachments < 1) && ((meta.touched && meta.error) || errors.length > 0);

  const attachmentText = pluralize("Attachment", numAttachments, true);

  const popoverId = "popover-" + useMemo(() => _.uniqueId(), []);

  const fileAttachedText = multiple ? "Add More" : "Replace File";

  return (
    <InputWrapper label={label} required={rest.required}>
      <div data-testid={rest["data-testid"]}>
        <div className="d-flex align-items-center">
          <Dropzone accept={accept} onDrop={handleDrop} maxSize={maxSize} onDropRejected={handleDropRejection}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div className={getDropzoneClasses({ isDragActive, showError })} {...getRootProps()}>
                <input {...getInputProps({ multiple, id, disabled, autoFocus, readOnly })} />

                <InputGroup>
                  {isDragActive ? (
                    <div className="form-control">Release to {multiple ? "add" : "replace"}!</div>
                  ) : (
                    <>
                      <InputGroupText>
                        <Paperclip size={14} />
                      </InputGroupText>
                      <div className="form-control text-muted">{numAttachments > 0 ? fileAttachedText : hint}</div>
                    </>
                  )}
                </InputGroup>
              </div>
            )}
          </Dropzone>

          {numAttachments > 0 && (
            <>
              <div className="text-nowrap fw-bold mx-3" id={popoverId}>
                {attachmentText}
              </div>
              <UncontrolledPopover placement="top" target={popoverId} trigger="hover" className="attachement-list-popover">
                <PopoverBody className="p-0">
                  <AttachmentsListGroup attachments={Object.values(localFiles)} allowRemove onRemove={handleRemoveFile} />
                </PopoverBody>
              </UncontrolledPopover>
            </>
          )}
        </div>

        {showError && <p className="text-danger mt-2 small">{errors.length > 0 ? errors.join("; ") : meta.error}</p>}
      </div>
    </InputWrapper>
  );
};
