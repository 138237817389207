import React from "react";
import { numMissingFields } from "../MissingInfoFollowupWizard";
import { AlreadyContactedClinicScreen } from "./missinginfo/AlreadyContactedClinicScreen";
import { AlreadyContactedLabScreen } from "./missinginfo/AlreadyContactedLabScreen";
import { AnyMissingInfoRemainingScreen } from "./missinginfo/AnyMissingInfoRemainingScreen";
import { CallClinicScreen } from "./missinginfo/CallClinicScreen";
import { CanResolveMissingInfoScreen } from "./missinginfo/CanResolveMissingInfoScreen";
import { ContinueWorkflowScreen } from "./missinginfo/ContinueWorkflowScreen";
import { ExitClinicWorkflowScreen } from "./missinginfo/ExitClinicWorkflowScreen";
import { ExitLabWorkflowScreen } from "./missinginfo/ExitLabWorkflowScreen";
import { InvalidStatusScreen } from "./missinginfo/InvalidStatusScreen";
import { MissingInfoDocumentsScreen } from "./missinginfo/MissingInfoDocumentsScreen";
import { MissingInfoFormScreen } from "./missinginfo/MissingInfoFormScreen";
import { NoAnswerScreen } from "./missinginfo/NoAnswerScreen";
import { OtherMissingInfoCollectionScreen } from "./missinginfo/OtherMissingInfoCollectionScreen";
import { SalesforceContactScreen } from "./missinginfo/SalesforceContactScreen";

export const MISSING_PATIENT_DEMO_SUBSTATUS = "missing_patient_demographics";
export const MISSING_CLINICAL_INFO_SUBSTATUS = "clinical_info";
export const MISSING_CLINIC_GC_INFO_SUBSTATUS = "clinic_gc_info";
export const NO_DOCS_CLINICAL_INFO_OR_DEMOGRAPHICS_SUBSTATUS = "no_docs_clinical_info_or_demographics";

// Common MI Steps
export const MI_FORM_STEP = "mi_form";
export const INVALID_STATUS_STEP = "mi_invalid_status";
export const MI_DOCUMENT_REVIEW_STEP = "mi_document_review";
export const ANY_MI_REMAINING_STEP = "mi_any_mi_remaining_step";

// Clinic MI Steps
export const MI_ALREADY_CONTACTED_CLINIC_STEP = "mi_already_contacted";
export const MI_CALL_CLINIC_STEP = "mi_call_clinic";
export const MI_SELECT_FIELDS_STEP = "mi_select_fields";
export const MI_SEND_NOTIFICATION_STEP = "mi_send_notification";
export const OTHER_MI_COLLECTION_STEP = "mi_other_mi_collection";
export const CONTINUE_SUBMISSION_STEP = "mi_continue_submission";
export const EXIT_CLINIC_WORKFLOW_STEP = "mi_exit_workflow";

// Lab MI Steps
export const MI_NEED_CONTACT_LAB_STEP = "mi_need_contact_lab_step";
export const MI_SALESFORCE_MESSAGE_STEP = "mi_salesforce_message";
export const MI_ALREADY_CONTACTED_LAB_STEP = "mi_already_contacted_lab";
export const EXIT_LAB_WORKFLOW_STEP = "mi_exit_lab_workflow";

export const LAB_FOLLOWUP_STEPS = {
  [MI_ALREADY_CONTACTED_LAB_STEP]: {
    next: () => MI_FORM_STEP,
    Component: AlreadyContactedLabScreen,
  },
  [MI_DOCUMENT_REVIEW_STEP]: {
    next: () => ANY_MI_REMAINING_STEP,
    Component: MissingInfoDocumentsScreen,
  },
  [ANY_MI_REMAINING_STEP]: {
    next: ({ answers }) => {
      if (answers.anyRemainingMissingInfo) {
        return MI_SALESFORCE_MESSAGE_STEP;
      } else {
        return CONTINUE_SUBMISSION_STEP;
      }
    },
    Component: AnyMissingInfoRemainingScreen,
  },
  [MI_NEED_CONTACT_LAB_STEP]: {
    next: ({ answers }) => {
      if (answers.noMissingInfo === true) {
        return CONTINUE_SUBMISSION_STEP;
      }

      if (answers.needContactLab) {
        return MI_SALESFORCE_MESSAGE_STEP;
      } else {
        return MI_FORM_STEP;
      }
    },
    Component: CanResolveMissingInfoScreen,
  },
  [MI_SALESFORCE_MESSAGE_STEP]: {
    next: ({ answers }) => {
      if (answers.anyRemainingMissingInfo) {
        return MI_FORM_STEP;
      } else {
        return EXIT_LAB_WORKFLOW_STEP;
      }
    },
    Component: SalesforceContactScreen,
  },
  [MI_FORM_STEP]: {
    next: ({ answers }) => {
      const numMissing = numMissingFields(answers.missingInfoFields);

      if (numMissing > 0) {
        return EXIT_LAB_WORKFLOW_STEP;
      } else {
        return CONTINUE_SUBMISSION_STEP;
      }
    },
    Component: MissingInfoFormScreen,
  },
  [EXIT_LAB_WORKFLOW_STEP]: {
    next: false,
    Component: ExitLabWorkflowScreen,
  },
  [CONTINUE_SUBMISSION_STEP]: {
    next: false,
    Component: ContinueWorkflowScreen,
  },
  [INVALID_STATUS_STEP]: {
    next: false,
    Component: InvalidStatusScreen,
  },
};

export const CLINIC_FOLLOWUP_STEPS = {
  [MI_ALREADY_CONTACTED_CLINIC_STEP]: {
    next: () => MI_FORM_STEP,
    Component: AlreadyContactedClinicScreen,
  },
  [MI_DOCUMENT_REVIEW_STEP]: {
    next: () => ANY_MI_REMAINING_STEP,
    Component: MissingInfoDocumentsScreen,
  },
  [ANY_MI_REMAINING_STEP]: {
    next: ({ answers }) => {
      if (answers.anyRemainingMissingInfo) {
        return MI_CALL_CLINIC_STEP;
      } else {
        return CONTINUE_SUBMISSION_STEP;
      }
    },
    Component: AnyMissingInfoRemainingScreen,
  },
  [MI_CALL_CLINIC_STEP]: {
    next({ answers }) {
      if (answers.clinicCallAnswered) {
        if (answers.substatus === MISSING_PATIENT_DEMO_SUBSTATUS || answers.substatus === NO_DOCS_CLINICAL_INFO_OR_DEMOGRAPHICS_SUBSTATUS) {
          return MI_FORM_STEP;
        } else if (answers.substatus === MISSING_CLINICAL_INFO_SUBSTATUS || answers.substatus === MISSING_CLINIC_GC_INFO_SUBSTATUS) {
          return OTHER_MI_COLLECTION_STEP;
        } else {
          return INVALID_STATUS_STEP;
        }
      } else {
        return MI_SELECT_FIELDS_STEP;
      }
    },
    Component: CallClinicScreen,
  },
  [MI_SELECT_FIELDS_STEP]: {
    next: () => {
      return MI_SEND_NOTIFICATION_STEP;
    },
    Component: NoAnswerScreen,
  },
  [MI_SEND_NOTIFICATION_STEP]: {
    next: false,
    Component: () => <div>Send Notification!</div>,
  },
  [OTHER_MI_COLLECTION_STEP]: {
    next: () => {
      return CONTINUE_SUBMISSION_STEP;
    },
    Component: OtherMissingInfoCollectionScreen,
  },
  [MI_FORM_STEP]: {
    next: ({ answers }) => {
      const numMissing = numMissingFields(answers.missingInfoFields);

      if (numMissing > 0) {
        return EXIT_CLINIC_WORKFLOW_STEP;
      } else {
        return CONTINUE_SUBMISSION_STEP;
      }
    },
    Component: MissingInfoFormScreen,
  },
  [EXIT_CLINIC_WORKFLOW_STEP]: {
    next: false,
    Component: ExitClinicWorkflowScreen,
  },
  [CONTINUE_SUBMISSION_STEP]: {
    next: false,
    Component: ContinueWorkflowScreen,
  },
  [INVALID_STATUS_STEP]: {
    next: false,
    Component: InvalidStatusScreen,
  },
};
