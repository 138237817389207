import _ from "lodash";
import React, { useState } from "react";
import { BootstrapRadio } from "../inputs";
import { FormGroup, Label } from "reactstrap";

export const YesNoToggle = ({ onChange, value, yesLabel = "Yes", noLabel = "No", label, id }) => {
  return (
    <FormGroup>
      <Label>{label}</Label>
      <BootstrapRadio
        value={value}
        onChange={onChange}
        name={id}
        id={id}
        options={{
          inline: true,
          enumOptions: [
            { label: yesLabel, value: true },
            { label: noLabel, value: false },
          ],
        }}
      />
    </FormGroup>
  );
};
