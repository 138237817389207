import { useState } from "react";
import { Link } from "react-router-dom";
import { Switch } from "../../components/inputs/Switch";
import { Toolbar } from "../../components/Toolbar";
import { AdministrateEnumFilter } from "../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { Routes } from "../Routes";
import { DESTROY, makeColumns, useAdministrateTable, useCreatePermission } from "../utils";
import { CreateLabModal } from "./components/CreateLabModal";
import { ImportLabsModal } from "./components/ImportLabsModal";
import { LabActionCell } from "./components/LabActionCell";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    disableFilters: true,
    disableSortBy: true,
  },
  {
    id: "lab_internal_id",
    accessor: "labInternalId",
    Header: "Internal Lab ID",
  },
  {
    id: "lab_name",
    accessor: "labName",
    Header: "Lab",
    Cell: ({ row, value }) => (
      <Link to={Routes.lab.url(row.original.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
  },
  {
    id: "assigned_to",
    accessor: "assignedTo.fullNameOrEmail",
    Header: "Assigned To",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/accounts/lab_assignable.json"
        valueKey="id"
        labelKey="full_name_or_email"
        searchParam="assigned_to"
      />
    ),
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: LabActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const LabsIndexPage = () => {
  const columns = useColumns();
  const [hideDiscarded, setHideDiscarded] = useState(true);
  const [canCreate] = useCreatePermission("Lab");

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onCreate = (newLab) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created new lab ${newLab.lab_name}`);
    refresh();
  };

  const extraParams = { hideDiscarded };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  return (
    <SystemConfigLayout title="Labs">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <Switch
            id="show-discarded-toggle"
            label="Show Discarded"
            checked={!hideDiscarded}
            className="me-3"
            onChange={() => {
              setHideDiscarded(!hideDiscarded);
            }}
          />

          <AdministrateBackgroundExportButton outline className="me-2" exportUrl="/admin/labs/export" filters={allFilters} ref={tableRef} />

          {canCreate && (
            <>
              <ImportLabsModal outline className="me-2" onImport={onImport} />
              <CreateLabModal color="primary" onCreate={onCreate} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/labs.json?lab[direction]=asc&lab[order]=lab_name"}
        resourceName={"lab"}
        extraParams={extraParams}
        permissions={[DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
