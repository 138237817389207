import React, { useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { required } from "../../../util/validators";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { LoadingButton } from "../../../components/LoadingButton";
import { snakifyKeys } from "../../../util/helpers";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { objectToOptions } from "../../../util/formatters";
import { LAB_ROLES, CLINIC_ROLES } from "../../../util/data/global_roles";
import { OnChange } from "react-final-form-listeners";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { atLeastOneFieldRequired } from "../../../util/validators";

export const AddQuickActionModal = ({ accountId, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [type, setType] = useState(null);
  const [freetextDisabled, setFreeTextDisabled] = useState(false);

  const toggleModal = () => {
    setOpen(!open);
    setType(null);
  };

  const handleAdd = async (values) => {
    setError(null);

    try {
      const { data } = await adminApi.post(`/admin/clinic_ops_managements.json`, { clinic_ops_management: snakifyKeys(values) });
      onAdd("Added new Clinic-Ops Managemnt");

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Quick Action Management
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add Management</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleAdd}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/enums/clinic_ops_quick_actions.json"
                    labelKey="label"
                    valueKey="id"
                    label="Quick Action"
                    name="quickAction"
                    validate={required}
                  />

                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/accounts.json"
                    required
                    label="Users"
                    name="accountIds"
                    labelKey="full_name_or_email"
                    isMulti
                  />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Save
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
