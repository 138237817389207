import classnames from "classnames";
import React from "react";
import { FormText } from "reactstrap";

export const CheckboxGroup = ({ label, required, children, error, className }) => {
  return (
    <fieldset className={classnames(className, "form-group check_boxes", { required })}>
      <legend className="col-form-label pt-0">
        {label}
        {required && (
          <>
            {" "}
            <abbr title="required">*</abbr>
          </>
        )}
      </legend>
      {error && (
        <FormText color="danger" className="mb-2">
          {error}
        </FormText>
      )}

      {children}
    </fieldset>
  );
};
