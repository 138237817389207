import { Trash } from "lucide-react";
import { useState } from "react";
import { LoadingButton } from "../components/LoadingButton";
import { api } from "../util/api";

export const RejectMatchButton = ({ id, ...props }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoadingButton
      {...props}
      size="sm"
      outline
      color="danger"
      title="Reject Match"
      onClick={async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          await api.delete(`/operations/prior_authorize_requests/clinic_mappings/partial/${id}`);
          window.location.reload();
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }}
      loading={loading}
    >
      <Trash />
    </LoadingButton>
  );
};
