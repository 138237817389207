import React from "react";

export const WizardContext = React.createContext({
  state: null,
  caseData: null,
  dispatch: null,
  loading: null,
  setLoading: null,
  payorContacts: null,
  createFollowupTask: null,
  updateStatus: null,
});
