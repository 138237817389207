import React from "react";
import { Field } from "react-final-form";
import { adminApi as api, handleRequestSequence } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { atLeastOneFieldRequired } from "../../../util/validators";
import { ImportModal } from "../../components/ImportModal";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const ImportPayorCptCodeSettingModal = ({ onImport, payorId, ...rest }) => {
  const handleImport = async (values) => {
    const requestPromises = values.file.map(async (f) => {
      const params = {
        payorIds: values.payorIds,
        payorGroupIds: values.payorGroupIds,
        file: {
          bytes: f,
        },
      };

      return await api.post(`/admin/payor_cpt_codes_settings/import`, snakifyKeys(params));
    });

    const results = await handleRequestSequence(requestPromises);

    if (onImport) {
      onImport(results);
    }
  };

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Add PA Requirements from CSV..."
      exampleDownloadPath="/admin/payor_cpt_codes_settings/example"
      fileInputProps={{ multiple: true }}
    >
      {(formProps) => (
        <>
          <Field
            isMulti
            component={AdministrateSelectInput}
            resourceUrl="/admin/payors.json"
            searchParam="name"
            validate={atLeastOneFieldRequired("payorGroupIds")}
            label="Payors"
            name="payorIds"
          />

          <Field
            isMulti
            component={AdministrateSelectInput}
            resourceUrl="/admin/payor_groups.json"
            label="Payor Groups"
            name="payorGroupIds"
            validate={atLeastOneFieldRequired("payorIds")}
          />
        </>
      )}
    </ImportModal>
  );
};
