import { Container, Row } from "reactstrap";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { Toolbar } from "../../../components/Toolbar";
import { BoolCell } from "../../../components/grid/cells/BoolCell";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { Link } from "react-router-dom";
import { Routes } from "../../Routes";
import { useCallback } from "react";
import { DESTROY, UPDATE, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { AdministrateTable } from "../../components/AdministrateTable";
import { AddPreprocessingCategoryModal } from "./components/AddPreprocessingCategoryModal";
import { PreprocessingCategoryActionCell } from "./components/PreprocessingCategoryActionCell";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { ImportPreprocessingCategoryModal } from "./components/ImportPreprocessingCategoryModal";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    Cell: TokenCell,
  },
  {
    id: "name",
    accessor: "name",
    Header: "Name",
  },
  {
    id: "lab",
    accessor: "lab.dashboardDisplayName",
    Header: "Lab",
    Cell: ({ row, value }) => (
      <Link to={Routes.lab.url(row.original.lab?.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl={"/admin/labs.json?scope=with_servicing_provider"}
        valueKey="id"
        labelKey="lab_name"
        searchParam="lab_name"
      />
    ),
  },
  {
    id: "payor",
    accessor: "payor.dashboardDisplayName",
    Header: "Payor",
    Cell: ({ row, value }) => (
      <Link to={Routes.payor.url(row.original.payor?.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="id" labelKey="name" searchParam="name" />
    ),
  },
  {
    id: "test",
    accessor: "test.commercialName",
    Header: "Test",
    Filter: (props) => (
      <AdministrateEnumFilter
        {...props}
        resourceUrl="/admin/tests.json"
        valueKey="id"
        labelKey="commercial_name"
        searchParam="commercial_name"
      />
    ),
    disableSortBy: true,
  },
  {
    id: "active",
    accessor: "active",
    Header: "Active",
    Cell: BoolCell,
    Filter: BoolFilter,
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: PreprocessingCategoryActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PreprocessingCategoriesIndex = () => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const columns = useColumns();
  const [canCreate] = useCreatePermission("PreprocessingCategory");

  const onAdd = useCallback(
    (message, errorDetails = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, errorDetails.length ? "warning" : "success", errorDetails);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Preprocessing Categories">
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Toolbar className="ml-auto">
            {canCreate && (
              <AdministrateBackgroundExportButton
                outline
                className="me-2"
                exportUrl="/admin/preprocessing_categories/export"
                filters={allFilters}
                ref={tableRef}
              />
            )}

            {canCreate && <ImportPreprocessingCategoryModal outline className="me-2" onImport={onAdd} />}

            {canCreate && <AddPreprocessingCategoryModal onAdd={onAdd} color="primary" />}
          </Toolbar>
        </Row>
      </Container>

      <AdministrateTable
        resourceUrl={"/admin/preprocessing_categories.json"}
        resourceName={"preprocessing_category"}
        permissions={[UPDATE, DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
