import React from "react";
import { adminApi as api } from "../../../util/api";
import { ImportModal } from "../../components/ImportModal";

export const ImportCptCodesModal = ({ onImport, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      file: {
        bytes: values.file,
      },
    };
    const res = await api.post("/admin/cpt_codes/import.json", params);

    if (onImport) {
      onImport(res.data?.meta?.message, res.data?.meta?.error_details);
    }
  };

  return (
    <ImportModal buttonProps={rest} onImport={handleImport} title="Import Cpt Codes..." exampleDownloadPath="/admin/cpt_codes/example" />
  );
};
