import React, { useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { FormGroup, Row, Col } from "reactstrap";
import { MoneyInput } from "../../components/inputs/final_form/MoneyInput";
import { PercentInput } from "../../components/inputs/final_form/PercentInput";
import { BoolInput, SelectInput } from "../../components/inputs/final_form/SelectInput";
import { LoadingButton } from "../../components/LoadingButton";
import { CSRF_TOKEN } from "../../util/api";
import { composeValidators, required, requiredNotNull, requiredMoneyPresent } from "../../util/validators";
import { DateInput } from "../../components/inputs/final_form/DateInput";
import moment from "moment";

export const DeductibleWorksheet = ({ caseId, benefitsInvestigationData, submitUrl, insuredPayor }) => {
  benefitsInvestigationData.bi_status = benefitsInvestigationData.status;
  benefitsInvestigationData.effective_date = benefitsInvestigationData.effective_date || moment().format("YYYY-MM-DD");
  const [saving, setSaving] = useState(false);
  const formEl = useRef(null);

  benefitsInvestigationData.copay = benefitsInvestigationData.copay ? benefitsInvestigationData.copay : 0;
  benefitsInvestigationData.coinsurance = benefitsInvestigationData.coinsurance ? benefitsInvestigationData.coinsurance : 0;

  const handleSubmit = (values) => {
    if (formEl.current) {
      setSaving(true);
      formEl.current.submit();
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={benefitsInvestigationData}>
      {({ handleSubmit, form, submitting, pristine, values }) => {
        return (
          <form onSubmit={handleSubmit} ref={formEl} action={submitUrl} method="post">
            <input type="hidden" name="_method" value="patch" />
            <input type="hidden" name="authenticity_token" value={CSRF_TOKEN} />
            <input type="hidden" name="case" value={caseId} />

            <Row>
              <Col>
                <FormGroup tag="fieldset">
                  <legend>Dates</legend>

                  <Field
                    required={insuredPayor ? true : false}
                    validate={insuredPayor ? requiredNotNull : undefined}
                    name="effective_date"
                    component={DateInput}
                    label="Effective Date"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup tag="fieldset">
                  <legend>Cost Share</legend>
                  <Field name="copay" component={MoneyInput} label="Diagnostic Lab Copay" />
                  <Field name="coinsurance" component={PercentInput} label="Coinsurance" />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup tag="fieldset">
                  <legend>Deductible</legend>
                  <Field
                    required={insuredPayor ? true : false}
                    validate={insuredPayor ? requiredMoneyPresent : undefined}
                    name="deductible_remaining"
                    component={MoneyInput}
                    label="Remaining Deductible"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup tag="fieldset">
                  <legend>Max Out of Pocket</legend>
                  <Field name="moop_remaining" component={MoneyInput} label="Remaining Out of Pocket" />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup tag="fieldset">
              <Field
                required
                name="bi_status"
                validate={composeValidators(required)}
                render={(props) => (
                  <SelectInput
                    {...props}
                    options={[
                      { label: "Open", value: "open" },
                      { label: "Processing", value: "processing" },
                      { label: "Closed", value: "closed" },
                    ]}
                  />
                )}
                label="Status"
              />
            </FormGroup>

            <LoadingButton loading={saving || submitting} disabled={submitting} type="submit" color="success">
              Update Case
            </LoadingButton>
          </form>
        );
      }}
    </Form>
  );
};
