import React from "react";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { makeColumns, useAdministrateTable, useUpdatePermission } from "../../utils";
import { ImportPayorLabSettingsModal } from "../components/ImportPayorLabSettingsModal";
import { NetworkStatusCell } from "../components/NetworkStatusCell";
import { PayorLabNetworkStatusActionCell } from "../components/PayorLabNetworkStatusActionCell";

const useColumns = makeColumns(({ payorId, payorName }) => [
  {
    id: "lab_internal_id",
    accessor: "labInternalId",
    Header: "Internal Lab Id",
    Cell: TokenCell,
  },
  {
    id: "lab_name",
    accessor: "labName",
    Header: "Lab",
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/labs.json" valueKey="lab_name" />,
  },
  {
    id: "in_network",
    accessor: "inNetwork",
    Header: "Network Status",
    Cell: NetworkStatusCell,
    Filter: (props) => <BoolFilter {...props} yesLabel="In-Network" noLabel="Out-of-Network" />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <PayorLabNetworkStatusActionCell payorId={payorId} payorName={payorName} {...row} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PayorsLabNetworkStatusTab = ({ payorId, payor }) => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const [canUpdate] = useUpdatePermission("Payor", payorId);

  const payorName = payor.name ?? payor.dashboard_display_name;
  const columns = useColumns({ payorId, payorName });

  const onAddPayorLabSettings = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onExportSuccess = () => {
    const { setMessage } = tableRef.current;
    setMessage("Export started. You will receive an email when your file is ready", "success");
  };

  const onExportError = (err) => {
    const { setError } = tableRef.current;
    setError(err);
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl={`/admin/payors/${payorId}/lab_network_statuses/export`}
            filters={allFilters}
            onError={onExportError}
            onSuccess={onExportSuccess}
          />

          {canUpdate && <ImportPayorLabSettingsModal outline className="me-2" onImport={onAddPayorLabSettings} payorId={payorId} />}
        </Toolbar>
      </Toolbar>
      <AdministrateTable
        resourceUrl={`/admin/payors/${payorId}/lab_network_statuses.json`}
        resourceName={"lab_network_statuses"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
