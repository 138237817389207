import { Badge, Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { useCallback, useEffect, useState } from "react";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { Field, Form } from "react-final-form";
import { SelectInput } from "../../../../components/inputs/final_form/SelectInput";
import { mapStringsToOptions } from "../../../../util/forms";
import { FormRow } from "../../../../components/forms/FormRow";
import { api } from "../../../../util/api";
import { isEmpty } from "lodash";
import { formatDateTime } from "../../../../util/formatters";
import { LoadingButton } from "../../../../components/LoadingButton";
import { ArrowRight } from "lucide-react";

const limitOptions = mapStringsToOptions(["5", "10", "20", "30", "40", "50"]);

const HistoryLimitForm = ({ onSubmit, loading }) => {
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, submitting, errors, touched, values, valid }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <FormRow>
              <Col xs="8">
                <Field
                  name="limit"
                  placeholder="Log Limit"
                  component={(props) => <SelectInput {...props} />}
                  options={limitOptions}
                  disabled={loading || submitting}
                  initialValue={"5"}
                />
              </Col>
              <Col xs="2">
                <LoadingButton
                  title="Click here to reload changes."
                  onClick={() => handleSubmit(values)}
                  color="secondary"
                  loading={loading || submitting}
                >
                  Reload
                </LoadingButton>
              </Col>
            </FormRow>
          </form>
        );
      }}
    </Form>
  );
};

const ItemEntry = ({ entry, values }) => {
  const formatByType = (value) => {
    if (_.isNumber(value)) return value;
    if (typeof value === "boolean") return JSON.stringify(value);

    if (_.isString(value)) {
      const parsedDateTime = formatDateTime(value);
      if (parsedDateTime != "" && parsedDateTime.toLocaleUpperCase() != "INVALID DATE") {
        return parsedDateTime;
      }

      return value;
    }

    return "Empty";
  };

  const parsedValues = (values) => {
    if (Array.isArray(values)) {
      return (
        <>
          <blockquote className="mb-0">
            <span className="badge bg-secondary me-2">{formatByType(values[0])}</span>
            <ArrowRight size={14} />
            <span className="badge bg-info ms-2">{formatByType(values[1])}</span>
          </blockquote>
        </>
      );
    }
    return (
      <blockquote>
        <span className="badge bg-info">{values === null ? "Empty" : formatByType(values)}</span>
      </blockquote>
    );
  };

  return (
    <li style={{ listStyle: "none" }} className="font-monospace">
      <u>{entry}</u>
      {parsedValues(values)}
    </li>
  );
};

const ItemList = ({ results, group = false }) => {
  return (
    <>
      {results &&
        results.map((result, resultIndex) => {
          const changes = Object.entries(result.audited_changes).map(([key, value]) => {
            return <ItemEntry key={`item-${key}`} entry={key} values={value} />;
          });

          return (
            <div className="mb-4" key={`result-${resultIndex}`}>
              <div className="d-flex flex-row align-items-center gap-2 position-relative">
                <span className="badge bg-secondary rounded-circle py-1 position-absolute" style={{ left: "-10px" }}>
                  {resultIndex + 1}
                </span>
                <p className="mb-0 ps-4">
                  {group ? (
                    <>
                      <strong>{result.account}</strong> <mark style={{ letterSpacing: "0.2em" }}>{result.label}</mark> to{" "}
                      {result.auditable_id} on <em>{formatDateTime(result.created_at)}</em>
                    </>
                  ) : (
                    <>
                      <strong>{result.account}</strong> <mark style={{ letterSpacing: "0.2em" }}>{result.label}</mark> on{" "}
                      <em>{formatDateTime(result.created_at)}</em>
                    </>
                  )}
                </p>
              </div>

              <div className="d-flex flex-column pt-1 pb-3">
                <ol>{changes}</ol>
              </div>
            </div>
          );
        })}
    </>
  );
};

const HistoryModal = ({ open, toggleModal, modalProps, entityName, subjectId = null }) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(undefined);
  const [error, setError] = useState(undefined);

  const [limit, setLimit] = useState("5");

  const fetchResults = useCallback(
    async (limit) => {
      try {
        setLoading(true);

        const params = {
          type: entityName,
          id: subjectId || null,
          limit: limit,
        };

        const response = await api.get(`/admin/history.json`, {
          params: params,
        });

        setResults(response.data.resources);
        setLoading(false);
      } catch (e) {
        setError(e);
      }
    },
    [entityName, subjectId]
  );

  const onSubmit = async (values) => {
    if (values && values.limit) {
      setLimit(values.limit);
      fetchResults(values.limit);
    }
  };

  useEffect(() => {
    if (!results && open) {
      fetchResults(limit);
    }
  }, [limit, open, results, fetchResults]);

  return (
    <>
      <Modal size="md" isOpen={open} toggle={toggleModal} {...modalProps}>
        <ModalHeader toggle={toggleModal}>Record History</ModalHeader>

        <ModalBody>
          <p className="h4">
            <strong>Audit History</strong> of {entityName} {subjectId ? `ID: ${subjectId}` : null}
          </p>

          <blockquote>
            The <u>default</u> query limit is <u>5</u>.
          </blockquote>

          <div className="d-flex flex-row w-100 mb-4 border-bottom">
            <HistoryLimitForm onSubmit={onSubmit} loading={loading} />
          </div>

          <DetailedErrorAlert error={error} />
          {loading ? (
            <div className="d-flex flex-row justify-content-center align-items-center w-100">
              <Spinner color="secondary">Loading</Spinner>
            </div>
          ) : (
            <>
              {isEmpty(results) ? (
                <div className="d-flex flex-row justify-content-center align-items-center" style={{ maxHeight: "10rem" }}>
                  <div className="d-flex flex-row bg-light rounded p-3 justify-content-center gap-1 align-items-center">
                    <p className="lead mb-0">No changes for now...</p>
                  </div>
                </div>
              ) : (
                <div className="overflow-auto px-2" style={{ maxHeight: "15rem" }}>
                  <div className="border-start">
                    <ItemList results={results} group={subjectId ? false : true} />
                  </div>
                </div>
              )}
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button outline onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export const History = ({ entityName, subjectId, ...buttonProps }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => setModalOpen(!modalOpen);

  return (
    <>
      <Button title={`Click to see changes made on this ${subjectId ? "record" : "entity"}.`} onClick={toggleModal} {...buttonProps}>
        History
      </Button>
      <HistoryModal open={modalOpen} toggleModal={toggleModal} entityName={entityName} subjectId={subjectId} />
    </>
  );
};
