import React from "react";
import { NavLink as Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabPane, UncontrolledDropdown } from "reactstrap";

import { useAnyActivePath } from "../../util/useAnyActivePath";

const RouteNavItem = ({ path, label }) => {
  return (
    <NavItem key={path}>
      <NavLink tag={Link} to={path} exact>
        {label}
      </NavLink>
    </NavItem>
  );
};

const RouteDropdownItem = ({ path, label }) => {
  return (
    <DropdownItem tag={Link} to={path}>
      {label}
    </DropdownItem>
  );
};

const RouteDropdownMenu = ({ label, items }) => {
  const match = useRouteMatch();
  const hasActiveItem = useAnyActivePath(items.map((t) => `${match.path}/${t.path}`));

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle nav caret className={hasActiveItem ? "active" : ""}>
        {label}
      </DropdownToggle>

      <DropdownMenu>
        {items.map(({ path, label }) => (
          <RouteDropdownItem key={path} path={path} label={label} />
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const TabbedRoute = ({ label, path, items }) => {
  if (items) {
    return <RouteDropdownMenu items={items} label={label} />;
  } else {
    return <RouteNavItem path={path} label={label} />;
  }
};

export const TabbedRoutes = ({ tabs, defaultTab, ...rest }) => {
  const match = useRouteMatch();

  const flattenedTabs = tabs.flatMap((tab) => (tab.items ? tab.items : [tab]));

  return (
    <>
      <Nav tabs className="mb-3">
        {tabs.map(({ label, path, items }) => (
          <TabbedRoute key={path || label} label={label} path={path} items={items} />
        ))}
      </Nav>

      <Switch>
        {flattenedTabs.map((t) => {
          return (
            <Route path={`${match.path}/${t.path}`} key={`tab-${t.path}`}>
              <TabPane>
                <t.Component {...rest} />
              </TabPane>
            </Route>
          );
        })}

        {defaultTab && <Redirect from={match.path} to={`${match.path}/${defaultTab}`} />}
      </Switch>
    </>
  );
};
