import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const ConfirmModal = ({
  Target,
  confirmMessage,
  onConfirm,
  confirmLabel = "OK",
  confirmButtonProps = {},
  cancelLabel = "Cancel",
  title = "Are you sure?",
  modalProps = {},
  showCancel = true,
}) => {
  const [open, setOpen] = useState(false);
  const toggleModal = () => setOpen(!open);

  return (
    <>
      {React.cloneElement(Target, { onClick: toggleModal })}

      <Modal isOpen={open} toggle={toggleModal} {...modalProps}>
        <ModalHeader toggle={toggleModal}>{title}</ModalHeader>

        <ModalBody>{confirmMessage}</ModalBody>

        <ModalFooter>
          {showCancel && (
            <Button color="secondary" outline onClick={toggleModal}>
              {cancelLabel}
            </Button>
          )}

          <Button
            color="primary"
            onClick={() => {
              onConfirm();
              toggleModal();
            }}
            {...confirmButtonProps}
          >
            {confirmLabel}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
