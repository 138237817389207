import classnames from "classnames";
import { Check, XCircle } from "lucide-react";
import React from "react";

export const BoolIcon = ({ value = true, className, ...props }) => {
  return value ? (
    <Check className={classnames("text-success", className)} {...props} />
  ) : (
    <XCircle className={classnames("text-danger", className)} {...props} />
  );
};
