import { useState } from "react";
import { Switch } from "../../components/inputs/Switch";
import { api, extractErrorMessage } from "../../util/api";
import { snakifyKeys } from "../../util/helpers";

export const AdministrateToggle = ({ id, endpoint, params, toggle, onChange, onError, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [componentToggle, setComponentToggle] = useState(toggle);
  const label = loading ? "Loading..." : componentToggle ? "Yes" : "No";

  const handleUpdate = async (e) => {
    setLoading(true);
    const toggle = e.target.checked;

    try {
      const { data } = await api.put(endpoint, snakifyKeys(params(toggle)));

      if (data) {
        onChange(data.meta.message);
        setComponentToggle(toggle);
      }
    } catch (err) {
      onError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  return <Switch id={id} disabled={loading} checked={Boolean(componentToggle)} label={label} onChange={handleUpdate} {...props} />;
};
