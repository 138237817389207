import classnames from "classnames";
import Cleave from "cleave.js/react";
import { FormText, InputGroup, InputGroupText } from "reactstrap";
import { InputWrapper } from "./InputWrapper";

export const PercentInput = ({ children, input, label, meta, ...rest }) => (
  <InputWrapper label={label} required={rest.required}>
    <InputGroup>
      <Cleave
        options={{
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        }}
        {...input}
        className={classnames("form-control", rest.className, {
          "is-invalid": Boolean(meta.touched && meta.error),
        })}
        {...rest}
      />
      <InputGroupText>%</InputGroupText>
    </InputGroup>
    {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
    {children}
  </InputWrapper>
);
