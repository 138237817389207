import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { TabbedRoutes } from "../components/TabbedRoutes";
import { Routes } from "../Routes";
import { useAdminResource } from "../utils";
import { PortalGeneralTab } from "./tabs/PortalGeneralTab";
import { PortalLabsTab } from "./tabs/PortalLabsTab";
import { PortalPayorsTab } from "./tabs/PortalPayorsTab";
import { PortalMessagesTab } from "./tabs/PortalMessagesTab";

const useBreadcrumbs = (pageTitle, portalId) =>
  useMemo(
    () => [
      { url: Routes.portals.url(), label: Routes.portals.label },
      { url: Routes.portal.url(portalId), label: pageTitle, active: true },
    ],
    [pageTitle, portalId]
  );

const usePortal = (portalId) => useAdminResource(`/admin/benefit_management_portals/${portalId}.json`);

const TABS = [
  {
    path: "general",
    label: "General",
    Component: PortalGeneralTab,
  },
  {
    path: "payors",
    label: "Payors",
    Component: PortalPayorsTab,
  },
  {
    path: "labs",
    label: "Labs",
    Component: PortalLabsTab,
  },
  {
    path: "portalMessages",
    label: "Portal Messages",
    Component: PortalMessagesTab,
  },
];

export const PortalShowPage = () => {
  const { portalId } = useParams();
  const { resource: portal, error, isLoading, fetch } = usePortal(portalId);
  const pageTitle = portal ? portal.name : `Portal #${portalId}`;
  const breadcrumbs = useBreadcrumbs(pageTitle, portalId);

  return (
    <SystemConfigLayout title={pageTitle} showBreadcrumbs crumbs={breadcrumbs} loading={isLoading}>
      {error && <Alert color="danger">{error}</Alert>}

      <TabbedRoutes tabs={TABS} defaultTab={"general"} portal={portal} portalId={portalId} refresh={fetch} />
    </SystemConfigLayout>
  );
};
