import React from "react";

export const DetailsList = ({ values }) => {
  return (
    <dl>
      {values.map((v) => (
        <React.Fragment key={v.label}>
          <dt>{v.label}</dt>
          <dd>{v.value}</dd>
        </React.Fragment>
      ))}
    </dl>
  );
};
