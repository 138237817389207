import pluralize from "pluralize";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { atLeastOneFieldRequired } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

const getCreateMessage = ({ failures, successes }) => {
  if (failures.length === 0) {
    return `Added ${pluralize("Portals", successes.length, true)} `;
  }

  if (successes.length === 0) {
    return "Failed to add Portals";
  }

  const errorMessages = failures.map((f) => extractErrorMessage(f.reason)).join("; ");
  const successLabel = pluralize("Portals", successes.length, true);
  const errorLabel = pluralize("errors", failures.length, true);

  return `Added ${successLabel}, and encountered ${errorLabel}. Errors: ${errorMessages}.`;
};

export const AddPortalModal = ({ payor, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleAdd = async ({ portalIds }) => {
    setError(null);

    const createPayloads = portalIds.map((benefitManagementPortalId) =>
      snakifyKeys({ payorPortalMapping: { benefitManagementPortalId, payorId: payor.id } })
    );

    try {
      const resValues = await Promise.allSettled(
        createPayloads.map(async (p) => {
          await api.post("/admin/payor_portal_mappings", p);
        })
      );

      const failures = resValues.filter((v) => v.status === "rejected");
      const successes = resValues.filter((v) => v.status !== "rejected");

      if (onAdd) {
        onAdd(getCreateMessage({ successes, failures }));
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Portals...
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Link {payor.name} to Portal...</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form onSubmit={handleAdd}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    isMulti
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/benefit_management_portals.json"
                    label="Portals"
                    name="portalIds"
                    distinct={true}
                    validate={atLeastOneFieldRequired("portalIds")}
                  />
                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
