import React from "react";
import { TrinaryTree } from "../../../../components/wizards/TrinaryTree";
import { YesNoToggle } from "../../../../components/wizards/YesNoToggle";
import { WizardContext } from "../../WizardContext";
import { PayorSentDeterminationBranch } from "./PayorSentDeterminationBranch";
import { PayorHasNotSentDeterminationBranch } from "./PayorHasNotSentDeterminationBranch";

export const PayorHasReachedDeterminationBranch = () => (
  <WizardContext.Consumer>
    {({ state, dispatch }) => (
      <>
        <YesNoToggle
          label="Has Payor sent the determination?"
          value={state.answers.payorHasSentDetermination}
          id="payorHasSentDetermination"
          onChange={(v) => {
            dispatch({
              type: "setAnswer",
              key: "payorHasSentDetermination",
              value: v,
              doTransition: false,
            });
          }}
        />

        <TrinaryTree
          branch={state.answers.payorHasSentDetermination}
          trueBranch={<PayorSentDeterminationBranch />}
          falseBranch={<PayorHasNotSentDeterminationBranch />}
        />
      </>
    )}
  </WizardContext.Consumer>
);
