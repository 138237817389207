import { useCallback, useState } from "react";
import { Switch } from "../../../components/inputs/Switch";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";

export const UseLabPricingToggle = ({ clinicLabPermission, labName, useLabPricing, onChange, readOnly }) => {
  const [loading, setLoading] = useState(false);
  const label = loading ? "Loading..." : useLabPricing ? "Yes" : "No";

  const getUpdateMessage = useCallback(
    (useLabPricing) => `Set Use Lab Pricing for lab ${labName} as ${useLabPricing ? "Yes" : "No"}`,
    [labName]
  );

  const handleChange = useCallback(
    async (e) => {
      setLoading(true);
      const useLabPricing = e.target.checked;

      try {
        await adminApi.put(`/admin/clinic_lab_permissions/${clinicLabPermission.id}`, snakifyKeys({ useLabPricing: useLabPricing }));

        onChange(getUpdateMessage(useLabPricing));
      } catch (err) {
        onError(extractErrorMessage(err));
      } finally {
        setLoading(false);
      }
    },
    [clinicLabPermission.id, onChange, getUpdateMessage]
  );

  return (
    <Switch
      disabled={readOnly || loading}
      type="switch"
      id={`use_lab_pricing${clinicLabPermission.id}`}
      checked={Boolean(useLabPricing)}
      label={label}
      onChange={handleChange}
    />
  );
};
