import { camelizeKeys } from "../../util/helpers";
import { useParams } from "react-router-dom";
import { useAdminResource } from "../utils";
import { useMemo } from "react";
import { Routes } from "../Routes";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { Alert } from "reactstrap";
import { TabbedRoutes } from "../components/TabbedRoutes";
import { AuthTokenGeneralTab } from "./components/AuthTokenGeneralTab";
import { AuthTokenApiActivitiesTab } from "./components/AuthTokenApiActivitiesTab";

const useBreadcrumbs = (pageTitle, authTokenId) =>
  useMemo(
    () => [
      { url: Routes.authTokens.url(), label: Routes.authTokens.label },
      { url: Routes.authToken.url(authTokenId), label: pageTitle, active: true },
    ],
    [pageTitle, authTokenId]
  );

const useAuthToken = (authTokenId) => camelizeKeys(useAdminResource(`/admin/auth_tokens/${authTokenId}.json`));

const useTabs = () => [
  {
    path: "general",
    label: "General",
    Component: AuthTokenGeneralTab,
    resource: "AuthToken",
  },
  {
    path: "api_activities",
    label: "API Activities",
    Component: AuthTokenApiActivitiesTab,
    resource: "ApiActivity",
  },
];

export const AuthTokenShowPage = () => {
  const { authTokenId } = useParams();
  const { resource: authToken, error, isLoading, fetch } = useAuthToken(authTokenId);
  const pageTitle = `Auth Token #${authTokenId}`;
  const breadcrumbs = useBreadcrumbs(pageTitle, authTokenId);

  return (
    <SystemConfigLayout title={pageTitle} showBreadcrumbs crumbs={breadcrumbs} loading={isLoading}>
      {error && <Alert color="danger">{error}</Alert>}

      <TabbedRoutes tabs={useTabs()} defaultTab={"general"} authToken={authToken} authTokenId={authTokenId} refresh={fetch} />
    </SystemConfigLayout>
  );
};
