import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { DetailedAlert } from "../../../components/DetailedAlert";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";

export const SingleReprocessForm = () => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageDetails, setMessageDetails] = useState(null);

  const onSubmit = async ({ caseId }, form) => {
    setError(null);
    setMessage(null);
    setMessageDetails(null);

    try {
      const { data } = await adminApi.post("/admin/system/tools/reprocess_seeqer_case", snakifyKeys({ caseId }));
      setMessage(`Cloned ${caseId} for reprocessing. New Case ID is ${data?.resource?.case_id}`);
      form.restart();
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <DetailedErrorAlert error={error} />

      <DetailedAlert
        message={message}
        details={messageDetails}
        messageStyle={messageDetails && messageDetails.length > 0 ? "warning" : "info"}
      />

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, pristine }) => {
          return (
            <>
              <Field component={TextInput} required validate={required} label={`${window.branding.brandNameShort} Case ID`} name="caseId" />

              <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine}>
                Reprocess
              </LoadingButton>
            </>
          );
        }}
      </Form>
    </>
  );
};
