import Icon from "./Icon";
import cx from "classnames";

export const ProgressListItemEntry = ({ action, actionIcon, historyStepIcon, content, idx, label, lastIdx, status, secondaryLabel }) => {
  const stepIconClassNames = () => {
    let defaultClassNames = "position-absolute progress-list step-icon d-flex justify-content-center border rounded-circle";
    let doneClassNames = "bg-white border-primary text-primary";
    let inProgressClassNames = "bg-primary border-primary text-white";
    let pendingClassNames = "bg-white border-gray-700 text-gray-700";
    let historyClassNames = "bg-light";

    return cx(defaultClassNames, {
      [doneClassNames]: status === "done",
      [inProgressClassNames]: status === "inProgress" || status === "edit",
      [pendingClassNames]: status === "pending",
      [historyClassNames]: status === "caseHistory",
    });
  };

  const dottedBorderClassNames = () => {
    let defaultClassNames = "d-flex flex-column border-3 pb-4 ps-4 dotted-border-position ";

    return cx(defaultClassNames, { ["border-start-dotted"]: idx !== lastIdx });
  };

  const stepIcon = () => {
    const checkIcon = <Icon name="check" size={14} className="align-self-center" strokeWidth={3} />;
    const stepText = <p className="mb-0 fw-semibold">{status === "edit" ? "!" : idx}</p>;

    if (historyStepIcon) {
      return <Icon name={historyStepIcon} color="grey" size={14} className="align-self-center" strokeWidth={3} />;
    }
    return status === "done" ? checkIcon : stepText;
  };

  return (
    <li className="list-group-item border-0 py-0 d-flex">
      {/* step icon */}
      <div className={stepIconClassNames()}>{stepIcon()}</div>

      <div className={dottedBorderClassNames()}>
        <div className="progress-item d-flex flex-row">
          <div className="d-flex">
            <div className="d-flex flex-nowrap">
              {label && <span className="fw-bolder">{label}</span>}
              {secondaryLabel && <span className="text-muted fw-normal">{secondaryLabel}</span>}
            </div>

            {/* action icon */}
            {action && actionIcon && (
              <a href={action} className="ms-2 d-flex align-self-baseline">
                <Icon name={actionIcon} size={16} className="align-self-center text-muted" strokeWidth={1} />
              </a>
            )}
          </div>
        </div>

        {/* content */}
        <div className="w-100">{content && <div className="mt-2 mb-2">{content}</div>}</div>
      </div>
    </li>
  );
};
