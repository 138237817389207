import { Fragment, useState } from "react";
import { Table } from "reactstrap";
import { DetailedAlert } from "../../../components/DetailedAlert";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { AdministrateToggle } from "../../components/AdministrateToggle";
import { useUpdatePermission } from "../../utils";
import { AddLabBoxDirectoryModal } from "./AddLabBoxDirectoryModal";
import { EditLabBoxDirectoryModal } from "./EditLabBoxDirectoryModal";

export const LabBoxSettings = ({ lab, refresh, tabName, tab_key }) => {
  const [message, setMessage] = useState(null);
  const [canUpdateLab] = useUpdatePermission("Lab", lab.id);
  const [canUpdateBox] = useUpdatePermission("BoxDirectory");
  const canUpdate = canUpdateLab || canUpdateBox;

  const onAction = (results) => {
    window.localStorage.setItem(tab_key, tabName);
    setMessage(results.meta.message);
    refresh();
  };

  return (
    <>
      {canUpdate && (
        <Toolbar className="align-items-center mb-3">
          <Toolbar className="ms-auto">
            <AddLabBoxDirectoryModal labId={lab.id} onAdd={onAction} />
          </Toolbar>
        </Toolbar>
      )}

      {message && <DetailedAlert message={message} messageStyle="info" alertLinkClass="alert-link ms-2" />}

      <Table>
        <thead>
          <tr>
            <th>Box Folder Id</th>
            <th>Sweep Enabled</th>
            <th>Tilde Delimited File</th>
            <th>Keep File in Directory</th>
            {canUpdate && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {lab.boxDirectories?.map((boxDirectory) => {
            return (
              <Fragment key={boxDirectory.id}>
                <tr>
                  <td>{boxDirectory.box_folder_id}</td>
                  <td>
                    <AdministrateToggle
                      id={`box-toggle-${boxDirectory.id}`}
                      toggle={boxDirectory.enabled}
                      endpoint={`/admin/labs/${lab.id}/box_directories/${boxDirectory.id}`}
                      params={(toggle) => {
                        return { labBoxDirectory: { id: boxDirectory.id, enabled: toggle } };
                      }}
                      onChange={setMessage}
                      onError={setMessage}
                      disabled={!canUpdate}
                    />
                  </td>
                  <td>
                    <AdministrateToggle
                      id={`box-tilde-toggle-${boxDirectory.id}`}
                      toggle={boxDirectory.tilde_delimited_file}
                      endpoint={`/admin/labs/${lab.id}/box_directories/${boxDirectory.id}`}
                      params={(toggle) => {
                        return { labBoxDirectory: { id: boxDirectory.id, tilde_delimited_file: toggle } };
                      }}
                      onChange={setMessage}
                      onError={setMessage}
                      disabled={!canUpdate}
                    />
                  </td>
                  <td>
                    <AdministrateToggle
                      id={`box-keep-file-toggle-${boxDirectory.id}`}
                      toggle={boxDirectory.keep_file_in_directory}
                      endpoint={`/admin/labs/${lab.id}/box_directories/${boxDirectory.id}`}
                      params={(toggle) => {
                        return { labBoxDirectory: { id: boxDirectory.id, keep_file_in_directory: toggle } };
                      }}
                      onChange={setMessage}
                      onError={setMessage}
                      disabled={!canUpdate}
                    />
                  </td>
                  {canUpdate && (
                    <td>
                      <EditLabBoxDirectoryModal labId={lab.id} onEdit={onAction} boxDirectory={boxDirectory} />
                      <AdministrateResourceDeleteButton
                        deleteUrl={`/admin/labs/${lab.id}/box_directories/${boxDirectory.id}`}
                        onError={(err) => setError(extractErrorMessage(err))}
                        onSuccess={(results) => {
                          window.localStorage.setItem(tab_key, tabName);
                          setMessage(`Successfully removed Box directory ${boxDirectory.box_folder_id}`);
                          refresh();
                        }}
                      />
                    </td>
                  )}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
