import React, { useState } from "react";
import { Button } from "reactstrap";
import { api } from "../../util/api";
import { LoadingIcon } from "../LoadingIcon";
import { redirectTo } from "../../util/api";

export const DiscardButton = ({ endpoint, confirmDiscard, confirmMessage, redirectUrl = null, children, ...props }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      size="sm"
      outline
      color="danger"
      title="Delete Object"
      {...props}
      onClick={async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          if (!confirmDiscard || confirm(confirmMessage || "Are you sure?")) {
            await api.delete(endpoint);
            if (redirectUrl) {
              redirectTo(redirectUrl);
            } else {
              window.location.reload();
            }
          }
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }}
    >
      {loading ? <LoadingIcon /> : children}
    </Button>
  );
};

export const UndiscardButton = ({ endpoint, children, ...props }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      size="sm"
      outline
      color="secondary"
      title="Undelete Object"
      {...props}
      onClick={async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          await api.post(endpoint);
          window.location.reload();
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }}
    >
      {loading ? <LoadingIcon /> : children}
    </Button>
  );
};
