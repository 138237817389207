import React from "react";

export const NetworkStatusCell = ({ row, value }) => {
  const inNetwork = Boolean(value);
  const configured = value !== null;

  return (
    <>
      {inNetwork ? "In-Network" : "Out-of-Network"} {!configured && <span className="text-danger">*</span>}
    </>
  );
};
