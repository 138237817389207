import { EventMiniBus } from "../util/event-bus";

export const initHotkeys = () => {
  const sidebar = document.getElementById("sidebar");

  if (sidebar) {
    document.addEventListener("keypress", (event) => {
      if (event.code === "KeyB" && event.ctrlKey === true) {
        EventMiniBus.dispatch("sidebar:toggle", "sidebar toggling");
      }
    });
  }
};
