import React, { useCallback } from "react";
import { Container, Row } from "reactstrap";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { AddProviderPayorNumbersModal } from "./components/AddProviderPayorNumbersModal";
import { ImportProviderPayorNumbersModal } from "./components/ImportProviderPayorNumbersModal";
import { ProviderPayorNumbersActionCell } from "./components/ProviderPayorNumbersActionCell";

const useColumns = makeColumns(() => [
  {
    id: "ID",
    accessor: "id",
    Header: "ID",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/provider_payor_numbers.json" valueKey="id" labelKey="id" searchParam="id" />
    ),
  },
  {
    id: "provider",
    accessor: "providerNpi",
    Header: "Provider NPI",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/providers.json" valueKey="id" labelKey="npi" searchParam="npi" />
    ),
  },
  {
    id: "payor",
    accessor: "payorName",
    Header: "Payor",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="id" labelKey="name" searchParam="name" />
    ),
  },
  {
    id: "provider_specific_submission_number",
    accessor: "providerSpecificSubmissionNumber",
    Header: "Provider Specific Submission Number",
  },
  {
    id: "actions",
    accessor: "Actions",
    Header: "Actions",
    Cell: ProviderPayorNumbersActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const ProviderSpecificSubmissionsIndex = () => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const columns = useColumns();
  const [canCreate] = useCreatePermission("ProviderPayorNumber");

  const onAdd = useCallback(
    (message, errorDetails = []) => {
      tableRef.current?.refresh?.();
      tableRef.current?.setMessage?.(message, errorDetails.length ? "warning" : "success", errorDetails);
    },
    [tableRef]
  );

  return (
    <SystemConfigLayout title="Provider Specific Submissions">
      <Container fluid>
        <Row className="align-items-center mb-3">
          <Toolbar className="ml-auto">
            {canCreate && (
              <AdministrateBackgroundExportButton
                outline
                className="me-2"
                exportUrl="/admin/provider_payor_numbers/export"
                filters={allFilters}
                ref={tableRef}
              />
            )}
            {canCreate && <ImportProviderPayorNumbersModal outline className="me-2" onImport={onAdd} />}
            {canCreate && <AddProviderPayorNumbersModal onAdd={onAdd} color="primary" />}
          </Toolbar>
        </Row>
      </Container>

      <AdministrateTable
        resourceUrl={"/admin/provider_payor_numbers.json"}
        resourceName={"provider_payor_number"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
