import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useDestroyPermission } from "../../utils";

export const PayorGroupActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("PayorGroup", row.original.id);

  return (
    canDestroy && (
      <AdministrateResourceDeleteButton
        deleteUrl={`/admin/payor_groups/${row.original.id}`}
        onSuccess={() => {
          refresh();
          setMessage(`Deleted Payor Group ${row.original.name}`);
        }}
        onError={(msg) => setError(msg)}
      />
    )
  );
};
