import { useCallback, useState } from "react";
import { Switch } from "../../../components/inputs/Switch";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";

export const PlanTypeNetworkStatusToggle = ({
  inNetwork,
  labName,
  payorName,
  planTypeGroup,
  payorLabSettingId,
  planTypeNetworkSettingId,
  payorId,
  labId,
  onChange,
  onError,
}) => {
  const [loading, setLoading] = useState(false);
  const label = loading ? "Loading..." : inNetwork ? "In-Network" : "Out-of-Network";

  const getUpdateMessage = useCallback(
    (inNetwork) => `Set ${labName} as ${inNetwork ? "In-Network" : "Out of Network"} for ${payorName} on Plan Type ${planTypeGroup}`,
    [labName, payorName, planTypeGroup]
  );

  const handleCreatePlanTypeSetting = useCallback(async () => {
    setLoading(true);

    try {
      await adminApi.post(
        "/admin/plan_type_network_settings.json",
        snakifyKeys({ planTypeNetworkSetting: { payorLabSettingId, planTypeGroup, inNetwork: true } })
      );
      onChange(getUpdateMessage(true));
    } catch (err) {
      onError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, [getUpdateMessage, setLoading, onChange, onError, planTypeGroup, payorLabSettingId]);

  const handleCreatePayorLabSetting = useCallback(async () => {
    setLoading(true);

    try {
      await adminApi.post(
        "/admin/payor_lab_settings.json",
        snakifyKeys({
          payorLabSetting: { payorId, labId, planTypeNetworkSettingsAttributes: snakifyKeys([{ inNetwork: true, planTypeGroup }]) },
        })
      );

      onChange(getUpdateMessage(true));
    } catch (err) {
      onError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  }, [labId, payorId, getUpdateMessage, setLoading, onChange, onError, planTypeGroup]);

  const handleUpdate = useCallback(
    async (e) => {
      setLoading(true);
      const inNetwork = e.target.checked;

      try {
        await adminApi.put(
          `/admin/plan_type_network_settings/${planTypeNetworkSettingId}.json`,
          snakifyKeys({ planTypeNetworkSetting: { inNetwork: Boolean(inNetwork) } })
        );

        onChange(getUpdateMessage(inNetwork));
      } catch (err) {
        onError(extractErrorMessage(err));
      } finally {
        setLoading(false);
      }
    },
    [planTypeNetworkSettingId, getUpdateMessage, setLoading, onChange, onError]
  );

  let handleChange;

  if (payorLabSettingId === null) {
    handleChange = handleCreatePayorLabSetting;
  } else if (planTypeNetworkSettingId === null) {
    handleChange = handleCreatePlanTypeSetting;
  } else {
    handleChange = handleUpdate;
  }

  return (
    <Switch
      key={`plan_type_network_status_${planTypeNetworkSettingId}_${labId}_${payorId}_${planTypeGroup}`}
      disabled={loading}
      id={`plan_type_network_status_${planTypeNetworkSettingId}_${labId}_${payorId}_${planTypeGroup}`}
      checked={Boolean(inNetwork)}
      label={label}
      onChange={handleChange}
    />
  );
};
