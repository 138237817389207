import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { useUpdatePermission } from "../../utils";
import { LimitCoinsuranceToggle } from "./LimitCoinsuranceToggle";

export const LimitCoinsuranceActionCell = ({ row }) => {
  const [canUpdate] = useUpdatePermission("Test", row.original.id);

  const { refresh, setMessage, setError } = useTableContext();
  const { limitCoinsurance } = row.original ?? {};

  const handleChange = (msg) => {
    setMessage(msg);
    refresh();
  };

  return (
    <LimitCoinsuranceToggle
      test={row.original}
      limitCoinsurance={limitCoinsurance}
      onChange={handleChange}
      onError={setError}
      readOnly={!canUpdate}
    />
  );
};
