import { Field } from "react-final-form";
import { SelectInput } from "./final_form/SelectInput";
import { OnChange } from "react-final-form-listeners";
import { GENDER_OPTIONS } from "../../util/data/genders";

const createOptions = (objectToMap) => {
  return Object.entries(objectToMap).map(([key, value]) => ({
    label: key,
    value: value,
  }));
};

export function GenderSelector({ onGenderChangeCallback = null, genderHtmlAttributes = {}, ...rest }) {
  const genderOptions = createOptions(GENDER_OPTIONS);

  const handleGenderChange = (option) => {
    onGenderChangeCallback(option);
  };

  return (
    <>
      <Field
        name="patientInfo.sex"
        id={genderHtmlAttributes?.id || rest?.input?.id}
        component={SelectInput}
        label="Gender"
        options={genderOptions}
        {...rest}
      />

      <OnChange name="patientInfo.sex">{handleGenderChange}</OnChange>
    </>
  );
}
