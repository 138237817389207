import { makeColumns, UPDATE, useAdministrateTable, useCreatePermission, useUpdatePermission } from "../../utils";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { ImportPayorLabSettingsModal } from "../components/ImportPayorLabSettingsModal";
import { AdministrateTable } from "../../components/AdministrateTable";
import React from "react";
import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { NetworkStatusCell } from "../components/NetworkStatusCell";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { PayorPlanTypeNetworkStatusActionCell } from "./PayorPlanTypeNetworkStatusActionCell";
import { ImportPlanTypeNetworkStatusSettingsModal } from "../../labs/components/ImportPlanTypeNetworkStatusSettingsModal";

const useColumns = makeColumns(({ payorId, payorName }) => [
  {
    id: "lab_internal_id",
    accessor: "labInternalId",
    Header: "Lab Id",
    disableSortBy: true,
    disableFilters: true,
    Cell: TokenCell,
    width: 80,
  },
  {
    id: "lab_name",
    accessor: "labName",
    Header: "Lab",
    disableSortBy: true,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/labs.json" valueKey="lab_name" searchParam="lab_name" />,
  },
  {
    id: "plan_type_group",
    accessor: "planTypeGroupLabel",
    Header: "Plan Type",
    disableSortBy: true,
    // TODO (CD-9088): Re-enable
    disableFilters: true,
    // Filter: (props) => (
    //   <AdministrateEnumFilter
    //     {...props}
    //     resourceUrl="/admin/payors/plan_type_groups.json"
    //     valueKey="value"
    //     labelKey="label"
    //     searchParam="plan_type_group"
    //   />
    // ),
    Cell: TokenCell,
  },
  {
    id: "in_network",
    accessor: "inNetwork",
    Header: "Network Status",
    Cell: NetworkStatusCell,
    disableSortBy: true,
    Filter: (props) => <BoolFilter {...props} yesLabel="In-Network" noLabel="Out-of-Network" />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <PayorPlanTypeNetworkStatusActionCell payorId={payorId} payorName={payorName} {...row} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PayorsPlanTypeNetworkStatusTab = ({ payorId, payor }) => {
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable();
  const [canCreate] = useCreatePermission("PlanTypeNetworkSetting");

  const payorName = payor.name ?? payor.dashboard_display_name;
  const columns = useColumns({ payorId, payorName });

  const onAddPayorLabSettings = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onExportSuccess = () => {
    const { setMessage } = tableRef.current;
    setMessage("Export started. You will receive an email when your file is ready", "success");
  };

  const onExportError = (err) => {
    const { setError } = tableRef.current;
    setError(err);
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl={`/admin/payors/${payorId}/plan_type_network_statuses/export`}
            filters={allFilters}
            onError={onExportError}
            onSuccess={onExportSuccess}
          />

          {canCreate && (
            <ImportPlanTypeNetworkStatusSettingsModal outline className="me-2" onImport={onAddPayorLabSettings} payorId={payorId} />
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/payors/${payorId}/plan_type_network_statuses.json`}
        resourceName={"plan_type_network_statuses"}
        onFilteredChange={setTableFilters}
        permissions={[UPDATE]}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
