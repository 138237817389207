import { Toolbar } from "../../../components/Toolbar";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateTable } from "../../components/AdministrateTable";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreateAutoApprovalModal } from "../components/CreateAutoApprovalModal";
import { PayorAutoApprovalActionCell } from "../components/PayorAutoApprovalActionCell";

const useColumns = makeColumns((payorId) => [
  {
    id: "name",
    accessor: "name",
    Header: "Test Bundle Name",
  },
  {
    id: "tests.id",
    accessor: "testList",
    Header: "Tests",
    Filter: (props) => <AdministrateEnumFilter {...props} isMulti resourceUrl="/admin/tests.json" valueKey="id" />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <PayorAutoApprovalActionCell payorId={payorId} {...row} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PayorsAutoApprovalsTab = ({ payor, payorId }) => {
  const columns = useColumns(payorId);
  const extraParams = { payor: { id: payorId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);
  const [canCreate] = useCreatePermission("TestBundle");

  const onCreate = (autoApproval) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created Auto Approval Configuration ${autoApproval.name}`);
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          {/*
            Commented this out until further clarification
            <AdministrateExportButton outline className="me-2" exportUrl={`/admin/test_bundles.csv`} filters={allFilters} />
            <ImportPayorAutoApprovalModal outline className="me-2" onImport={onImport} payorId={payorId} />
            */}
          {canCreate && <CreateAutoApprovalModal color="primary" onCreate={onCreate} payorId={payorId} />}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/test_bundles.json`}
        resourceName={"test_bundle"}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
