import React from "react";
import { ActionAlert } from "../../../../components/ActionAlert";
import { LoadingButton } from "../../../../components/LoadingButton";
import { WizardContext } from "../../WizardContext";

export const ContinueWorkflowScreen = () => (
  <WizardContext.Consumer>
    {({ setError, createMiContactAttempt, loading, continueWithStep, updateStep, updateStatus, state }) => {
      return (
        <ActionAlert
          color="success"
          actions={
            <>
              <LoadingButton
                color="success"
                loading={loading}
                onClick={async () => {
                  setError(null);

                  try {
                    // Wait for all data updates before doing redirect
                    await Promise.all([
                      createMiContactAttempt(state.answers.miType),
                      updateStatus("processing", null, false),
                      updateStep("select_payor", false),
                    ]);

                    await continueWithStep("prior_auth", "select_payor");
                  } catch (err) {
                    console.error(err);
                    setError(`Unexpected error: ${err.message}`);
                  }
                }}
              >
                Continue
              </LoadingButton>
            </>
          }
        >
          Continue with workflow to finalize Case.
        </ActionAlert>
      );
    }}
  </WizardContext.Consumer>
);
