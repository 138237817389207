import React from "react";
import { Field } from "react-final-form";
import { LoadingButton } from "../../components/LoadingButton";
import { required } from "../../util/validators";
import { SelectInput } from "../../components/inputs/final_form/SelectInput";
import { DateInput } from "../../components/inputs/final_form/DateInput";
import { useState } from "react";
import { SerializedLabSelector } from "../../components/inputs/final_form/LabSelector";
import { Form } from "react-final-form";
import { api, extractErrorMessage } from "../../util/api";
import { DetailedErrorAlert } from "../../components/DetailedErrorAlert";
import { DetailedAlert } from "../../components/DetailedAlert";
import { ModalBody, ModalFooter } from "reactstrap";

const ExportFormBody = ({ handleSubmit, submitting, statusesOptions, sourcesOptions }) => {
  return (
    <>
      <Field required validate={required} label="Lab" name="lab_id" component={SerializedLabSelector} />

      <Field required validate={required} label="Start date" name="start_date" component={DateInput} />

      <Field required validate={required} label="End date" name="end_date" component={DateInput} />

      <Field required validate={required} label="Statuses" name="statuses" component={SelectInput} options={statusesOptions} isMulti />

      <Field required validate={required} label="Sources" name="sources" component={SelectInput} options={sourcesOptions} isMulti />
    </>
  );
};

export const ExportForm = ({ initialValues, statusesOptions, sourcesOptions, exportUrl }) => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const handleSubmit = async (params, form) => {
    setError(null);

    try {
      const labExportParams = { lab_export: params };
      const res = await api.post(exportUrl, labExportParams, {
        headers: { "Content-Type": "application/json" },
      });

      if (res.data.success) {
        setMessage(res.data.message);
        form.restart();
      }
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting }) => {
        return (
          <>
            <ModalBody>
              <DetailedErrorAlert error={error} />
              {message && <DetailedAlert message={message} />}
              <ExportFormBody
                handleSubmit={handleSubmit}
                submitting={submitting}
                statusesOptions={statusesOptions}
                sourcesOptions={sourcesOptions}
              />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
              <LoadingButton color="primary" loading={submitting} disabled={submitting} onClick={handleSubmit}>
                Start Export
              </LoadingButton>
            </ModalFooter>
          </>
        );
      }}
    </Form>
  );
};
