import _ from "lodash";
import React from "react";
import { DateFilter, EnumFilter } from "../../components/inputs";
import { formatDate, formatLabName, formatName, humanizeString, userNameOrEmail } from "../../util/formatters";
import { DueDateCell } from "./cells/DueDateCell";
import { TokenCell } from "./cells/TokenCell";
import { TooltipCell } from "./cells/TooltipCell";
import { ProvidedInfoCell } from "./cells/ProvidedInfoCell";
import { PayorPaths, PAYOR_PATH_OPTIONS } from "../PayorPaths";
import { LabSelector } from "../inputs/LabSelector";
import { StatusDisplayer, SubstatusDisplayer } from "../../system_config/submit_right/phone_responses/PhoneResponsesIndex";
import { AdministrateEnumFilter } from "../../system_config/components/AdministrateEnumFilter";

export const VIEW_REQUEST = {
  Header: "",
  id: "actions",
  Cell: ({ row }) => (
    <a className="btn btn-sm btn-outline-primary" href={`/operations/cases/${row.original.id}`}>
      Details
    </a>
  ),
  minWidth: 85,
  className: "table-column--link",
  disableFilters: true,
};

export const ASSIGNEE = (assignableUsers) => {
  const options = assignableUsers.map((u) => ({ label: userNameOrEmail(u), value: u.email }));

  return {
    Header: "Assignee",
    id: "assignee",
    accessor: (r) => userNameOrEmail(r.assignee),
    minWidth: 200,
    Filter: (props) => <EnumFilter options={options} {...props} />,
  };
};

export const PUBLIC_ID = {
  Header: "Public ID",
  id: "public_id",
  accessor: "pa_request.requestId",
  Cell: TokenCell,
  minWidth: 125,
};

export const SUBMISSION_METHOD = {
  Header: "Follow-Up Method",
  id: "follow_up",
  accessor: "follow_up",
  minWidth: 200,
};

export const CASE_LAB_PATIENT_ID = {
  Header: "Lab Patient ID",
  id: "patient_id",
  accessor: "pa_request.patientInfo.externalPatientId",
  Cell: TokenCell,
  minWidth: 150,
};

export const CASE_LAB_ORDER_ID = {
  Header: "Lab Order ID",
  id: "order_id",
  accessor: "pa_request.orderInfo.externalOrderId",
  Cell: TokenCell,
  minWidth: 130,
};

export const CASE_LAB_NO_FILTER = {
  Header: "Lab",
  id: "lab",
  accessor: (r) => formatLabName(r.pa_request),
  disableSortBy: true,
  Cell: TooltipCell,
};

// If we return a new Filter component every time this function is called,
//  then it cannot store state, which LabSelector needs to do
export const CASE_LAB = _.memoize((initialLabOptions) => ({
  ...CASE_LAB_NO_FILTER,
  minWidth: 200,
  Filter: ({ column: { filterValue, setFilter }, ...rest }) => {
    return (
      <LabSelector
        isClearable
        isSearchable
        value={filterValue}
        onChange={setFilter}
        menuPortalTarget={document.body}
        placeholder="Show All"
        initialOptions={initialLabOptions}
        {...rest}
      />
    );
  },
}));

export const CASE_PATIENT_NAME = ({ disableFilters = false, isInternalUser = true, disableSortBy = true }) => {
  const accessor = isInternalUser === false ? "pa_request.Patient" : "pa_request.patientInfo";

  return {
    Header: "Patient Name",
    id: "patient_name",
    accessor: (r) => formatName(_.get(r, accessor), "", isInternalUser),
    providedInfoAccessor: (r) => {
      const lastName = _.get(r, "provided_information.request_patient_last_name");
      const firstName = _.get(r, "provided_information.request_patient_first_name");
      if (!lastName || !firstName) {
        return "";
      }
      return `${lastName}, ${firstName}`;
    },
    Cell: ProvidedInfoCell,
    minWidth: 200,
    disableSortBy: disableSortBy,
    disableFilters: disableFilters,
  };
};

export const CASE_PATIENT_DOB = {
  Header: "Patient DOB",
  id: "patient_dob",
  accessor: (r) => formatDate(_.get(r, "pa_request.patientInfo.dob")),
  providedInfoAccessor: (r) => formatDate(_.get(r, "provided_information.request_patient_dob")),
  Filter: (props) => <DateFilter {...props} />,
  Cell: ProvidedInfoCell,
  minWidth: 130,
  disableSortBy: true,
};

export const CURRENT_STATUS_NO_FILTER = {
  Header: "Current Status",
  id: "status",
  accessor: "cached_workflow_status",
  Cell: (props) => <StatusDisplayer {...props} status={props?.value || "None"} />,
};

export const CURRENT_STATUS = (options) => {
  return {
    ...CURRENT_STATUS_NO_FILTER,
    minWidth: 150,
    Filter: (props) => <EnumFilter {...props} options={options} />,
  };
};

export const CURRENT_SUBSTATUS_NO_FILTER = {
  Header: "Current Sub-Status",
  id: "substatus",
  accessor: "cached_workflow_substatus",
  Cell: (props) => <SubstatusDisplayer {...props} substatus={props?.value || "None"} />,
};

export const CURRENT_SUBSTATUS = (options) => {
  return {
    ...CURRENT_SUBSTATUS_NO_FILTER,
    minWidth: 220,
    Filter: (props) => <EnumFilter {...props} options={options} />,
  };
};

export const CURRENT_STEP = (options) => {
  return {
    Header: "Current Step",
    id: "workflow_step",
    accessor: (row) => {
      if (row?.cached_workflow_step === null) return "Unstarted";
      return humanizeString(row.cached_workflow_step);
    },
    minWidth: 180,
    Filter: (props) => <EnumFilter {...props} options={options} />,
  };
};

const payorPathsFilter = (rows, id, filterValue) => {
  if (!filterValue) return rows;

  return rows.filter((row) => {
    const rowValues = row.values[id].map((el) => el.abbreviation).join("");
    return filterValue.toLowerCase() === rowValues.toLowerCase();
  });
};

export const PAYOR_PATHS = {
  Header: "Payor Paths",
  id: "payor_paths",
  accessor: "payor_paths",
  Cell: ({ value }) => <PayorPaths paths={value} />,
  minWidth: 150,
  disableSortBy: true,
  Filter: (props) => <EnumFilter {...props} options={PAYOR_PATH_OPTIONS} />,
  filter: payorPathsFilter,
  disableFilters: false,
};

export const INSURANCE_NAME = {
  Header: "Insurance Name",
  id: "primary_insurance",
  accessor: "pa_request.insuranceInfo.primaryInsurance.insuranceName",
  Cell: TooltipCell,
  minWidth: 200,
};

export const INSURANCE_MEMBER_ID = {
  Header: "Member ID",
  id: "member_id",
  accessor: "pa_request.insuranceInfo.primaryInsurance.memberId",
  Cell: ProvidedInfoCell,
  providedInfoAccessor: "provided_information.request_insurance_primary_insurance_memberid",
  minWidth: 125,
};

export const CASE_DATE_OF_SERVICE = {
  Header: "Date Of Service",
  id: "date_of_service",
  accessor: (r) => formatDate(_.get(r, "pa_request.orderInfo.serviceDate")),
  Filter: (props) => <DateFilter {...props} />,
  minWidth: 125,
};

export const CASE_COLLECTION_DATE = {
  Header: "Collection Date",
  id: "collection_date",
  accessor: (r) => formatDate(_.get(r, "pa_request.orderInfo.collectionDate")),
  Filter: (props) => <DateFilter {...props} />,
  minWidth: 125,
};

export const CASE_PHYSICIAN_NAME = {
  Header: "Physician Name",
  id: "physician_name",
  accessor: (r) => formatName(_.get(r, "pa_request.providerInfo"), "physician"),
  Cell: TooltipCell,
  minWidth: 200,
};

export const CASE_PHYSICIAN_NPI = {
  Header: "Physician NPI",
  id: "physician_npi",
  accessor: "pa_request.providerInfo.physicianNPI",
  Cell: TokenCell,
  minWidth: 130,
};

export const PHYSICIAN_PRACTICE = {
  Header: "Physician Practice",
  id: "practice",
  accessor: "pa_request.providerInfo.physicianPracticeName",
  Cell: TooltipCell,
  minWidth: 200,
};

export const VERIFIED_PAYOR = ({ disableFilters = false, disableSortBy = true }) => {
  return {
    Header: "Verified Payor",
    id: "payor",
    accessor: (row) => {
      return row?.payor?.name || "None";
    },
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="name" labelKey="name" searchParam="name" />
    ),
    Cell: TooltipCell,
    minWidth: 200,
    disableSortBy: disableSortBy,
    disableFilters: disableFilters,
  };
};

export const CREATED_ON = {
  Header: "Created On",
  id: "created_at",
  accessor: (r) => formatDate(_.get(r, "created_at")),
  minWidth: 130,
  Filter: (props) => <DateFilter {...props} />,
};

export const TASK_DUE = {
  Header: "Task Due",
  id: "due_date",
  accessor: "due_date",
  minWidth: 125,
  Filter: (props) => <DateFilter {...props} />,
  Cell: DueDateCell,
};
