import React from "react";
import { Field, Form } from "react-final-form";
import { Button, FormText } from "reactstrap";
import { CaseDetailsField } from "./inputs/final_form/CaseDetailsField";
import { Checkbox } from "./inputs/final_form/Checkbox";
import { SelectInput } from "./inputs/final_form/SelectInput";
import { LoadingButton } from "./LoadingButton";
import { required, requiredTrue } from "../util/validators";
import { attachmentCategoryOptions } from "./AttachButton";

export const AttachToCaseForm = ({ onSubmit, onLoadCase, type, withCategory, acknowledgements, proposedCaseId = null }) => {
  return (
    <Form onSubmit={onSubmit} initialValues={{ requestId: proposedCaseId }}>
      {({ handleSubmit, values, form, submitting }) => {
        const handleLoadCaseDetails = (caseDetails) => {
          form.change("labId", caseDetails?.paRequest?.labInfo?.labId);
          form.change("validCase", caseDetails !== null);

          if (onLoadCase) {
            onLoadCase(caseDetails);
          }
        };

        const inputsDisabled = Boolean(!values.requestId || !values.validCase);

        const restoreProposal = () => form.change("requestId", proposedCaseId);

        return (
          <>
            <Field
              component={CaseDetailsField}
              label="Case ID"
              required
              validate={required}
              name="requestId"
              onLoadDetails={handleLoadCaseDetails}
            >
              {Boolean(proposedCaseId) && values.requestId == proposedCaseId ? (
                <FormText color="info">
                  We have auto-populated a suggested Case ID based on the contents of the {type}. Please verify the {type} should be linked
                  to this case or provide a new Case ID D
                </FormText>
              ) : (
                <FormText color="muted">{`Enter the ${window.branding.brandNameShort} Case ID for the Case to which you'd like to attach this ${type}.`}</FormText>
              )}

              {Boolean(proposedCaseId) && !values.requestId && (
                <FormText>
                  <Button color="secondary" outline size="xs" onClick={restoreProposal}>
                    Suggest Matching Case
                  </Button>
                </FormText>
              )}
            </Field>

            {withCategory && (
              <Field
                isClearable
                component={SelectInput}
                label="Attachment Category"
                disabled={inputsDisabled}
                name="attachmentCategory"
                options={attachmentCategoryOptions}
                validate={required}
                required
              >
                <FormText color="muted">{`Enter the category this ${type} belongs to. If you're not sure, select "Other".`}</FormText>
              </Field>
            )}

            <Field
              id="confirm-attach"
              component={Checkbox}
              label="Are you sure you want to link this document to the case above?"
              disabled={inputsDisabled}
              name="confirmation"
              required
              type="checkbox"
              validate={requiredTrue}
            />

            {acknowledgements?.map((ack, idx) => (
              <div className="mt-3" key={ack}>
                <Field
                  id={`ack-${idx}`}
                  component={Checkbox}
                  label={ack}
                  disabled={inputsDisabled}
                  name={`confirmation-${idx}`}
                  required
                  type="checkbox"
                  validate={requiredTrue}
                />
              </div>
            ))}

            <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting} className="mt-3">
              Attach
            </LoadingButton>
          </>
        );
      }}
    </Form>
  );
};
