import React, { useState, useCallback } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { api, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { VisualForm } from "./VisualForm";

export const AddVisualModal = ({ onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = useCallback(
    async (values) => {
      setError(null);
      const { completionScreenVisual, ...otherParams } = values;
      const params = { ...otherParams, completionScreenVisual: { bytes: completionScreenVisual } };
      try {
        await api.post("/admin/submit_right_visual_managements", snakifyKeys(params));

        onAdd?.(values);

        setOpen(false);
      } catch (err) {
        setError(extractErrorMessage(err));
      }
    },
    [onAdd]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add New Visual
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New Visual</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{}}>
            {(props) => <VisualForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
