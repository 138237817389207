import React, { useMemo, useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { camelizeKeys, snakifyKeys } from "../../../util/helpers";
import { numericInRange, required } from "../../../util/validators";
import { useUpdatePermission } from "../../utils";

export const PayorGroupsGeneralTab = ({ payorGroup, payorGroupId, refresh }) => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [canUpdate] = useUpdatePermission("PayorGroup", payorGroupId);

  const handleUpdate = async (values) => {
    setError(null);
    setMessage(null);

    try {
      await api.put(`/admin/payor_groups/${payorGroupId}`, { payor_group: snakifyKeys(values) });
      setMessage("Updated Payor Group");
      refresh();
    } catch (err) {
      setError(err);
    }
  };

  const initialValues = useMemo(() => camelizeKeys(payorGroup), [payorGroup]);

  return (
    <>
      <DetailedErrorAlert error={error} />
      {message && <Alert color="info">{message}</Alert>}

      <Form onSubmit={handleUpdate} initialValues={initialValues}>
        {({ handleSubmit, submitting, pristine }) => {
          return (
            <>
              <Field component={TextInput} required validate={required} label="ID" name="id" readOnly />
              <Field component={TextInput} required validate={required} label="Payor Group Name" name="name" readOnly={!canUpdate} />
              <Field
                component={AdministrateSelectInput}
                resourceUrl={`/admin/payor_groups/${payorGroupId}/available_abbreviations.json`}
                label="Payor Path Letter"
                name="abbreviation"
                readOnly={!canUpdate}
              />
              <Field
                component={TextInput}
                validate={numericInRange(100, 999)}
                label="Payor Path Order"
                name="order"
                readOnly={!canUpdate}
              />

              {canUpdate && (
                <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine}>
                  Save
                </LoadingButton>
              )}
            </>
          );
        }}
      </Form>
    </>
  );
};
