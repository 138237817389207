/*
  Hey, this trackable plugin can be used
  Adding just 3 keys in your clickable element dataset
  data-track='on'
  data-case-id='example#1'
  data-event-data="something to be stored in the trackEvent"
  Regardless where you are: react, erb.
*/

import { trackEvent } from "../util/track";

export function initTrackableElement() {
  const trackableElement = document.querySelector('[data-track="on"]');

  if (trackableElement) {
    const dataset = trackableElement.dataset;
    const data = JSON.parse(dataset?.eventData);
    const eventName = dataset?.eventName;
    const caseId = dataset?.caseId;

    trackableElement.addEventListener(
      "click",
      async (event) => {
        await trackEvent(eventName, caseId, data);
      },
      { capture: true }
    );
  }
}
