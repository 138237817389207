import React from "react";
import { UndiscardButton } from "../components/buttons/DiscardButton";
import { PreviewButton } from "./PreviewButton";
import { MarkReadButton } from "./MarkReadButton";
import { FaxAttachButton } from "./FaxAttachButton";
import { Undo } from "lucide-react";

export const FaxActionsCell = ({ row, attachmentAllowed = true }) => {
  return (
    <>
      {row.original.discarded ? (
        <>
          <UndiscardButton endpoint={`/operations/fax/${row.original.id}/restore`} title="Undelete Fax" className="btn-icon">
            <Undo />
          </UndiscardButton>
        </>
      ) : (
        <>
          {attachmentAllowed && Boolean(row.original.case_id === null) && <FaxAttachButton faxId={row.original.id} fax={row.original} />}
          <PreviewButton
            id={row.original.id}
            fax={row.original}
            className="me-2 btn-icon"
            canAttach={attachmentAllowed && Boolean(row.original.case_id === null)}
            canDestroy={true}
          />
          <MarkReadButton id={row.original.id} row={row.original} className="me-1 btn-icon" />
        </>
      )}
    </>
  );
};
