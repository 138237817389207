import _ from "lodash";
import { Calendar } from "lucide-react";
import moment from "moment-timezone";
import { useMemo, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import { Button, FormGroup, Modal, ModalBody, ModalFooter } from "reactstrap";
import { formatDate, formatIsoDate } from "../../util/formatters";
import { StyledSelect as Select } from "./StyledSelect";
import { useEffect } from "react";

const OPERATIONS = {
  equals: {
    sortKey: 0,
    title: "On",
    args: 1,
    summary: (d) => `On ${formatDate(d)}`,
    shortText: (d) => `= ${formatDate(d)}`,
  },
  before: {
    sortKey: 1,
    title: "On or Before",
    args: 1,
    summary: (d) => `On or Before ${formatDate(d)}`,
    shortText: (d) => `<= ${formatDate(d)}`,
  },
  after: {
    sortKey: 2,
    title: "On or After",
    args: 1,
    summary: (d) => `On or After ${formatDate(d)}`,
    shortText: (d) => `>= ${formatDate(d)}`,
  },
  between: {
    sortKey: 3,
    title: "Between",
    args: 2,
    summary: (ds) => `Between ${formatDate(ds[0])} and ${formatDate(ds[1])}`,
    shortText: (ds) => `${formatDate(ds[0])} - ${formatDate(ds[1])}`,
  },
};

const options = _.flow(
  (ops) =>
    _.map(ops, (op, key) => ({
      sorter: op.sortKey,
      option: { value: key, label: op.title },
    })),
  (ops) => _.sortBy(ops, "sorter"),
  (ops) => _.map(ops, "option")
)(OPERATIONS);

const argsPresent = (args) => {
  if (_.isNil(args)) {
    return false;
  }

  return _.isArray(args) ? args.every((x) => x != null) : true;
};

const formatArgs = (value) => {
  value.args = _.isArray(value.args) ? _.map(value.args, (a) => formatIsoDate(a)) : formatIsoDate(value.args);

  return value;
};

export const DateFilter = ({ column: { filterValue, setFilter } }) => {
  const pickerRef = useRef();
  const [value, setValue] = useState(() => filterValue || { operation: "equals", args: null });
  const [open, setOpen] = useState(false);

  //
  // State
  //
  const operationValue = useMemo(() => options.find((o) => o.value === value.operation), [value]);
  const op = OPERATIONS[operationValue.value];
  const isDateRange = op.args > 1;

  const selectedDate = useMemo(() => {
    if (!value.args) {
      return null;
    }

    return _.isArray(value.args) ? value.args.map((v) => moment(v).toDate()) : [moment(value.args).toDate()];
  }, [value]);

  //
  // Handlers
  //
  const toggleOpen = () => setOpen(!open);

  useEffect(() => {
    if (!filterValue) {
      setValue({ operation: "equals", args: null });
    }
  }, [filterValue]);

  const handleClose = () => {
    setFilter(argsPresent(value.args) ? formatArgs(value) : null);
  };

  const handleClear = () => {
    setValue({ operation: "equals", args: null });
    setOpen(false);
  };

  const updateOperation = (option) => {
    const op = OPERATIONS[option.value];
    const currentArgs = value.args;
    const args = _.isArray(currentArgs) ? currentArgs : [currentArgs, null];

    setValue({
      operation: option.value,
      args: op.args === 1 ? args[0] : args,
    });
  };

  const updateDate = (dates) => {
    if (dates.length === 1) {
      const start = dates[0];
      setValue({ ...value, args: moment(start) });
    } else {
      const [start, end] = dates;
      setValue({ ...value, args: [moment(start), moment(end)] });
    }
  };

  return (
    <>
      <Button outline onClick={toggleOpen}>
        {argsPresent(value.args) ? (
          op.shortText(value.args)
        ) : (
          <>
            <Calendar className="me-2 text-subtle" /> Set Filter
          </>
        )}
      </Button>

      <Modal isOpen={open} toggle={toggleOpen} onClosed={handleClose}>
        <ModalBody>
          <FormGroup>
            <Select value={operationValue} onChange={updateOperation} options={options} placeholder="Select Operation" />
          </FormGroup>

          <FormGroup>
            <Flatpickr
              ref={pickerRef}
              value={selectedDate}
              placeholder={isDateRange ? "Select a date range" : "Select a Date"}
              options={{
                mode: isDateRange ? "range" : "single",
                altInput: true,
                altFormat: "m/d/Y",
                dateFormat: "Y-m-d",
              }}
              onChange={updateDate}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleClear}>Clear Filter</Button>
          <Button onClick={() => setOpen(false)}>Set Filter</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
