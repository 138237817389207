import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { formatDateTime, parseCurrency } from "../../../util/formatters";
import { snakifyKeys } from "../../../util/helpers";
import { CptCodeForm } from "./CptCodeForm";

export const EditCptCodeModal = ({ cptCode, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleUpdate = async (values) => {
    const cpt_code = snakifyKeys(values);

    setError(null);

    try {
      if (cpt_code.medicare_cost_cents) {
        cpt_code.medicare_cost_cents = parseCurrency(cpt_code.medicare_cost_cents) * 100;
      }

      const res = await api.patch(`/admin/cpt_codes/${cptCode.id}`, { cpt_code });

      if (onUpdate) {
        onUpdate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const initialValues = useMemo(
    () => ({
      ...cptCode,
      medicareCostCents: cptCode.medicareCostCents && cptCode.medicareCostCents / 100,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(cptCode)]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Edit CPT Code</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <dl>
            <dt>Created At</dt>
            <dd>{formatDateTime(cptCode.createdAt)}</dd>

            <dt>Last Updated At</dt>
            <dd>{formatDateTime(cptCode.updatedAt)}</dd>
          </dl>

          <Form onSubmit={handleUpdate} initialValues={initialValues}>
            {(props) => <CptCodeForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
