import { useState } from "react";
import { Link } from "react-router-dom";
import { BoolFilter } from "../../../components/inputs/BoolFilter";
import { Switch } from "../../../components/inputs/Switch";
import { Toolbar } from "../../../components/Toolbar";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { Routes } from "../../Routes";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission, useUpdatePermission } from "../../utils";
import { AddPayorsModal } from "../components/AddPayorsModal";
import { ClinicSupportedPayorActionCell } from "../components/ClinicSupportedPayorActionCell";
import { ImportClinicPayorsModal } from "../components/ImportClinicPayorsModal";
import { PayorReflexToPAActionCell } from "../components/PayorReflexToPAActionCell";

const useColumns = makeColumns((clinicId) => [
  {
    id: "payor.id",
    accessor: "payor.name",
    Header: "Payor",
    Cell: ({ row, value }) => (
      <Link to={Routes.payor.url(row.original.payor.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
    disableSortBy: true,
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="id" labelKey="name" searchParam="name" />
    ),
  },
  {
    id: "reflex_to_pa",
    accessor: "reflexToPa",
    Header: "Reflex To PA",
    disableSortBy: true,
    className: "action-cell",
    Cell: PayorReflexToPAActionCell,
    Filter: BoolFilter,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ClinicSupportedPayorActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const ClinicsSupportedPayorsTab = ({ clinic, clinicId, refresh }) => {
  const [canCreate] = useCreatePermission("ClinicPayorPermission", clinicId);
  const [canUpdate] = useUpdatePermission("ClinicPayorPermission", clinicId);
  const columns = useColumns(clinicId);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const extraParams = { clinic: { id: clinicId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onAdd = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/clinic_payor_permissions/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreate && (
            <>
              <ImportClinicPayorsModal clinicId={clinicId} outline className="me-2" onImport={onImport} />
              <AddPayorsModal clinicId={clinicId} color="primary" onAdd={onAdd} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/clinic_payor_permissions.json"}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
        resourceName={"clinic_payor_permission"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
