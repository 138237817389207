import React from "react";
import { Card, CardBody } from "reactstrap";
import { TrinaryTree } from "../../../components/wizards/TrinaryTree";
import { YesNoToggle } from "../../../components/wizards/YesNoToggle";
import { WizardContext } from "../WizardContext";
import { MedicalReviewNotMissingInfoBranch } from "./branches/MedicalReviewNotMissingInfoBranch";
import { MedicalReviewMissingInfoBranch } from "./branches/MedicalReviewMissingInfoBranch";

export const MedicalReviewResultsScreen = () => (
  <WizardContext.Consumer>
    {({ state, dispatch }) => (
      <>
        <h5 className="mb-3">Ask the following questions:</h5>
        <Card>
          <CardBody>
            <YesNoToggle
              label="Is this case missing information?"
              id="medicalReviewMissingInfo"
              value={state.answers.medicalReviewMissingInfo}
              onChange={(v) => {
                dispatch({
                  type: "setAnswer",
                  key: "medicalReviewMissingInfo",
                  value: v,
                });
              }}
            />

            <TrinaryTree
              branch={state.answers.medicalReviewMissingInfo}
              falseBranch={<MedicalReviewNotMissingInfoBranch />}
              trueBranch={<MedicalReviewMissingInfoBranch />}
            />
          </CardBody>
        </Card>
      </>
    )}
  </WizardContext.Consumer>
);
