import cx from "classnames";
import { ArrowUpDown, Filter, Search, SortAsc, SortDesc } from "lucide-react";
import hash from "object-hash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFilters, useFlexLayout, usePagination, useResizeColumns, useRowSelect, useSortBy, useTable } from "react-table";
import { Badge, Button, Collapse, FormGroup, Input, Label, Table, Card, UncontrolledCollapse, CardBody, CardTitle } from "reactstrap";
import { DefaultFilter } from "../inputs/DefaultFilter";
import { PaginationComponent } from "../inputs/PaginationComponent";
import { LoadingIcon } from "../LoadingIcon";
import { Toolbar } from "../Toolbar";

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const SortIcon = ({ className, canSort, sortedAsc, sortedDesc }) => {
  if (sortedAsc) {
    return <SortAsc className={className} size={12} />;
  } else if (sortedDesc) {
    return <SortDesc className={className} size={12} />;
  } else if (canSort) {
    return <ArrowUpDown className={className} size={12} />;
  }

  return null;
};

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

const FilterPopover = ({ headerGroups, filters, clearFilters, ...rest }) => {
  return (
    <div {...rest}>
      <div className="w-100 mb-2">
        <UncontrolledCollapse className="filter_collapse" toggler="filter-button">
          <Card body>
            <CardTitle tag="h5" className="ms-3 fw-semibold mb-0">
              Filter
            </CardTitle>
            <CardBody className="d-flex flex-wrap align-items-center">
              {headerGroups.map((headerGroup, idx) =>
                headerGroup.headers.map((column, j) => {
                  if (!column.canFilter) {
                    return null;
                  }

                  return (
                    <FormGroup key={`filter-${idx}-${j}`} className="w-25 py-2 ps-0 pe-3">
                      <Label className="d-block fw-bold">{column.Header}</Label>

                      {column.render("Filter")}
                    </FormGroup>
                  );
                })
              )}
              <Button color="warning" className="rounded" size="md" onClick={clearFilters}>
                Clear Filters
              </Button>
            </CardBody>
          </Card>
        </UncontrolledCollapse>
      </div>
    </div>
  );
};

const TableSearch = ({ onSearch, search, ...rest }) => {
  const [open, setOpen] = useState(Boolean(search));

  return (
    <Toolbar {...rest}>
      <Button color="secondary" className="btn-ghost" onClick={() => setOpen(!open)}>
        <Search size={16} />
      </Button>

      <Collapse horizontal isOpen={open}>
        <Input type="search" onChange={onSearch} className="ms-2" style={{ width: 200 }} placeholder="Search..." />
      </Collapse>
    </Toolbar>
  );
};

const insertCheckboxColumn = (columns) => [
  // Let's make a column for selection
  {
    id: "selection",
    // The header can use the table's getToggleAllRowsSelectedProps method
    // to render a checkbox
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <div>
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
    // The cell can use the individual row's getToggleRowSelectedProps method
    // to the render a checkbox
    Cell: ({ row }) => (
      <div>
        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
      </div>
    ),
    maxWidth: 70,
  },
  ...columns,
];

const useOutboundEffects = ({
  onFetchData,
  pageIndex,
  pageSize,
  sortBy,
  filters,
  selectedRowIds,
  onPageChange,
  onPageSizeChange,
  onFilteredChange,
  onSortedChange,
  onSelectionChange,
  keyField,
  selectable,
  selectedFlatRows,
}) => {
  // TODO: All of these useEffects are probably no longer a reasonable way to go about this.
  // We are basically using it to subscribe to a hundred different things, and the linter
  // is understandably mad at the contortions needed to make that function.
  // This entire component needs to be substantially modernized in order to satisfy the linter here.
  // See OP-265.

  useEffect(() => {
    if (_.isFunction(onFetchData)) {
      onFetchData({ page: pageIndex, pageSize, sorted: sortBy, filtered: filters });
    }
  }, [onFetchData, pageIndex, pageSize, sortBy, filters]);

  useEffect(() => {
    if (_.isFunction(onPageChange)) {
      onPageChange(pageIndex);
    }
  }, [pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_.isFunction(onPageSizeChange)) {
      onPageSizeChange(pageSize);
    }
  }, [pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_.isFunction(onSortedChange)) {
      onSortedChange(sortBy);
    }
  }, [sortBy]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_.isFunction(onFilteredChange)) {
      onFilteredChange(filters);
    }
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectable && _.isFunction(onSelectionChange)) {
      const selection = selectedFlatRows.map((r) => _.get(r, `original.${keyField}`));
      onSelectionChange(selection);
    }
  }, [selectedRowIds, selectable]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const BootstrapGrid = React.forwardRef((props, ref) => {
  const {
    className = "",
    data,
    manual = false,
    onFetchData,
    onPageChange,
    onPageSizeChange,
    onFilteredChange,
    onSortedChange,
    onSelectionChange,
    onSearch,
    columns,
    keyField = "id",
    showPagination = true,
    sorted = [],
    filtered = [],
    disableFilters = false,
    sortable = true,
    selectable = false,
    loading = false,
    resizable = true,
    getTdProps = () => [],
    getTrProps = () => [],
    trClassName = "",
    pages = 0,
    page = 0,
    defaultPageSize = 20,
    striped = false,
    dense = false,
    showPageJump = true,
    title,
    search,
    showSearch = true,
    toolbarItems = null,
    hiddenColumns = [],
    totalItems = null,
    filterLabel,
  } = props;

  const defaultColumn = {
    minWidth: 30,
    width: 150,
    maxWidth: 400,
    Filter: DefaultFilter,
    filter: "equals",
    id: "",
  };

  const preventPropagationOnResize = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
  };

  const plugins = useMemo(
    () =>
      [
        !disableFilters && useFilters,
        sortable && useSortBy,
        usePagination,
        selectable && useRowSelect,
        resizable && useResizeColumns,
        useFlexLayout,
      ].filter((n) => !_.isNil(n)),
    [selectable, disableFilters, sortable, resizable]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    page: pageData,

    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    setAllFilters,
    selectedFlatRows,
    state: { pageIndex, pageSize, sortBy, filters, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: page, pageSize: defaultPageSize, sortBy: sorted, filters: filtered, hiddenColumns: hiddenColumns },
      manualPagination: manual,
      manualFilters: manual,
      manualSortBy: manual,
      pageCount: pages,
    },
    ...plugins,
    (hooks) => {
      if (!selectable) {
        return false;
      }
      hooks.visibleColumns.push(insertCheckboxColumn);
    }
  );

  // This effect is kind of crazy, because we can't trust
  // that we get a memoized filters array passed into us,
  // so we have to hash the passed in object to make a
  // stable comparison for equality across renders.
  //
  // This is what keeps passed in filters consistent
  // with currently applied filters, so that we can _externally_
  // override the filters
  //
  // TODO: The disabled linter is reasonable, but unwinding it requires some actual restructuring.
  // Re-enable exhaustive-deps here when fixed.
  // See OP-265.
  useEffect(() => {
    if (!_.isEqual(filters, filtered) && _.isFunction(setAllFilters)) {
      setAllFilters(filtered);
    }
  }, [hash(filtered), setAllFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const clearFilters = useCallback(() => setAllFilters([]), [setAllFilters]);

  // Hook changes in table state to
  // callbacks passed into our component
  useOutboundEffects({
    onFetchData,
    pageIndex,
    pageSize,
    sortBy,
    filters,
    selectedRowIds,
    selectedFlatRows,
    onPageChange,
    onPageSizeChange,
    onFilteredChange,
    onSortedChange,
    onSelectionChange,
    keyField,
    selectable,
  });

  return (
    <>
      <Toolbar className="mb-3">
        {title && (
          <Toolbar className="w-25" start>
            <h5 className="mb-0">{title}</h5>

            <Badge pill color="light" className="text-dark ms-2">
              {totalItems || pageSize * pageCount}
            </Badge>
          </Toolbar>
        )}

        <Toolbar className="ms-auto w-100">
          {toolbarItems && <Toolbar>{toolbarItems}</Toolbar>}

          {showSearch && onSearch && <TableSearch search={search} onSearch={onSearch} className="ms-2" />}
        </Toolbar>

        <Toolbar>
          {!disableFilters && (
            <Button color="secondary" className="btn-ghost" id="filter-button">
              <Filter color={"var(--bs-secondary)"} size={16} />
              {filterLabel && <span className="ms-2">{filterLabel}</span>}
            </Button>
          )}
        </Toolbar>
      </Toolbar>

      <Toolbar className="ms-auto w-100">
        {!disableFilters && (
          <FilterPopover
            headerGroups={headerGroups}
            filters={filters}
            clearFilters={clearFilters}
            className="w-100 d-flex flex-column align-items-end"
          />
        )}
      </Toolbar>

      <div className="table-wrapper" ref={ref}>
        <Table {...getTableProps} className={cx("table-pill mb-0", className)} responsive striped={striped} size={dense ? "sm" : undefined}>
          <thead>
            {
              // Looping over the header rows
              headerGroups.map((headerGroup, idx) => (
                <React.Fragment key={idx}>
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      //Looping over the headers in each row
                      headerGroup.headers.map((column) => {
                        // Allow to hide headers
                        if (column.hideColumn) {
                          return null;
                        }

                        return (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())} className="table-header">
                            {column.render("Header")}

                            <SortIcon
                              canSort={column.canSort}
                              sortedAsc={column.isSorted && !column.isSortedDesc}
                              sortedDesc={column.isSorted && column.isSortedDesc}
                              className={cx("ms-1", { "text-subtle": !column.isSorted })}
                            />

                            <div
                              {...column.getResizerProps()}
                              className={`resizer ${column.isResizing ? "resizing" : ""}`}
                              onClick={preventPropagationOnResize}
                            />
                          </th>
                        );
                      })
                    }
                  </tr>
                </React.Fragment>
              ))
            }
          </thead>

          <tbody {...getTableBodyProps()}>
            {pageData.length ? (
              pageData.map((row) => {
                prepareRow(row);

                const rowProps = getTrProps(row);

                return (
                  <React.Fragment key={row.id}>
                    <tr {...row.getRowProps()} {...rowProps} className={cx(rowProps.className, trClassName, row.isSelected && "selected")}>
                      {row.cells.map((cell) => {
                        // Allow to hide cells
                        if (cell.column.hideColumn) {
                          return null;
                        }

                        return (
                          <td {...getTdProps(cell)} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </React.Fragment>
                );
              })
            ) : (
              <>
                {loading ? (
                  _.times(pageSize, (i) => (
                    <tr key={i}>
                      <td className="empty" colSpan={100000}>
                        &nbsp;
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="empty text-muted" colSpan={10000000}>
                      No Data
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </Table>

        {loading && (
          <div className="table-overlay">
            <span className="text-muted">
              <LoadingIcon className="me-2" /> Loading...
            </span>
          </div>
        )}

        {showPagination && (
          <PaginationComponent
            showPageSizeOptions
            showPageJump={showPageJump}
            loading={loading}
            pages={pageCount}
            page={pageIndex}
            pageSize={pageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            canPrevious={canPreviousPage}
            canNext={canNextPage}
            onPageChange={gotoPage}
            onPageSizeChange={setPageSize}
          />
        )}
      </div>
    </>
  );
});

BootstrapGrid.name = "BootstrapGrid";

export const CheckboxGrid = React.forwardRef((props, ref) => {
  return <BootstrapGrid {...props} ref={ref} selectable />;
});

CheckboxGrid.name = "CheckboxGrid";
