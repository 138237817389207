import { Check, X } from "lucide-react";
import React from "react";

export const BoolCell = ({ value, nullable, invert = false, yesLabel = "Yes", noLabel = "No" }) => {
  if (nullable && value == null) {
    return null;
  }

  const finalVal = invert ? !value : value;

  if (Boolean(finalVal)) {
    return (
      <>
        <Check className="text-success me-2" /> {invert ? noLabel : yesLabel}
      </>
    );
  }

  return (
    <>
      <X className="text-danger me-2" /> {invert ? yesLabel : noLabel}
    </>
  );
};
