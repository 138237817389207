import { createRoot } from "react-dom/client";

import { RequestSplitter } from "../request_splitter";

export const initRequestSplitter = () => {
  const splitterModal = document.getElementById("splitter-modal-content");

  if (splitterModal) {
    const root = createRoot(splitterModal);
    const { caseId } = splitterModal.dataset;
    root.render(<RequestSplitter caseId={caseId} />);
  }
};
