import { Container, Spinner, Accordion, AccordionItem, AccordionHeader, AccordionBody, Badge } from "reactstrap";
import { adminApi, extractErrorMessage } from "../../../../util/api";
import { useEffect, useState } from "react";
import { formatDateTime, humanizeString } from "../../../../util/formatters";
import { isEmpty } from "lodash";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { Copy } from "lucide-react";

const ItemDisplayer = ({ items }) => {
  const [open, setOpen] = useState("");

  const togglePanel = (id) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion open={open} toggle={togglePanel}>
      {items &&
        items.map((item, index) => (
          <AccordionItem key={`item-${index}`}>
            <AccordionHeader targetId={`item-${index}`}>
              Public ID
              <Badge className="mx-2" color="secondary">
                {item.kase_public_id}
              </Badge>
              Submitted
              <Badge className="mx-2" color="info">
                {formatDateTime(item.created_at)}
              </Badge>
              Bot Type
              <Badge className="mx-2" color="info">
                {item.ui_path_portal_configuration ? humanizeString(item.ui_path_portal_configuration.ui_path_bot_type) : "N/A"}
              </Badge>
            </AccordionHeader>
            <AccordionBody accordionId={`item-${index}`}>
              <ul className="mb-0 p-3 rounded-2 bg-light bg-opacity-20" style={{ listStyle: "none" }}>
                <li>
                  <blockquote className="mb-1">
                    <strong>Case ID</strong>:
                    <a
                      className="ms-2 me-1"
                      href={`/operations/cases/${item.kase_public_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Link to Case details."
                    >
                      {item.kase_public_id}
                    </a>
                    <a class="copy btn-copy" href="#" data-clipboard-text={item.kase_public_id} title="Copy to Clipboard.">
                      <Copy size={18} />
                    </a>
                  </blockquote>
                </li>
                <li>
                  <blockquote className="mb-1">
                    <strong>Message</strong>: {item.message}
                  </blockquote>
                </li>
                <li>
                  <blockquote className="mb-0">
                    <strong>Request</strong>: {item.ui_path_request ? item.ui_path_request.status_code : "No request made"}
                  </blockquote>
                </li>
              </ul>
            </AccordionBody>
          </AccordionItem>
        ))}
    </Accordion>
  );
};

export const UIPathHistory = () => {
  // UI Base State
  const [records, setRecords] = useState(null);
  const [error, setError] = useState(null);

  // UI Control State
  const [loading, setLoading] = useState(false);

  const fetchRecords = async () => {
    setLoading(true);

    try {
      const { data } = await adminApi.get("/admin/system/tools/ui_path_test_cases.json");

      setRecords(data);
      setLoading(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  useEffect(() => {
    fetchRecords();
  }, []);

  return (
    <Container fluid>
      <div className="bg-info bg-opacity-10 p-3 rounded-2 mb-3">
        <h4>Hint:</h4>
        <p className="mb-0">All previous Case submissions will be listed below.</p>
      </div>

      {error && <DetailedErrorAlert error={error} />}

      {loading ? (
        <div className="d-flex flex-row justify-content-center align-items-center w-100 py-6 border rounded-2">
          <Spinner color="secondary">Loading</Spinner>
        </div>
      ) : (
        <>
          {isEmpty(records) ? (
            <div className="d-flex flex-row justify-content-center align-items-center border rounded-2" style={{ minHeight: "12rem" }}>
              <div className="d-flex flex-row bg-light rounded p-3 justify-content-center gap-1 align-items-center">
                <p className="lead fw-normal mb-0">No submissions for now...</p>
              </div>
            </div>
          ) : (
            <ItemDisplayer items={records} />
          )}
        </>
      )}
    </Container>
  );
};
