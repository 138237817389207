import React, { useState } from "react";
import { LoadingButton } from "../../../components/LoadingButton";
import { useTableContext } from "../../components/AdministrateTable";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { useUpdatePermission } from "../../utils";

export const PayorGroupPayorActionCell = ({ payorGroupId, ...row }) => {
  const [removing, setRemoving] = useState(false);
  const { refresh, setMessage, setError } = useTableContext();

  const [canUpdate] = useUpdatePermission("PayorGroup", payorGroupId);

  const handleRemovePayor = async () => {
    setRemoving(true);
    try {
      const { data } = await api.delete(`/admin/payor_groups/${payorGroupId}/payors`, { params: { payor_ids: [row.original.id] } });
      refresh();
      setMessage(data.meta.message);
    } catch (err) {
      setError(extractErrorMessage(err));
    } finally {
      setRemoving(false);
    }
  };

  return (
    canUpdate && (
      <LoadingButton loading={removing} color="link" className="text-danger" onClick={handleRemovePayor}>
        Remove
      </LoadingButton>
    )
  );
};
