import React from "react";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import { LoadingButton } from "../../../../components/LoadingButton";
import { WizardContext } from "../../WizardContext";
export const MessageDetailsNoAnswerScreen = () => {
  return (
    <WizardContext.Consumer>
      {({ exitWorkflow, state, loading, createMiContactAttempt, dispatch, setError, caseId }) => {
        const handleConfirm = async () => {
          setError(null);

          try {
            await createMiContactAttempt(state.answers.miType, state.answers.miDetailMessage);
            await exitWorkflow(`Automatic MI Followup Started for ${caseId}`);
          } catch (err) {
            console.error(err);
            setError(`Unexpected Error: ${err.message}`);
          }
        };

        const updateMessage = (e) =>
          dispatch({
            type: "setAnswer",
            key: "miDetailMessage",
            value: e.target.value,
            doTransition: false,
          });

        const canSubmit = state.answers.miDetailMessage && state.answers.miDetailMessage.length > 10;

        return (
          <>
            <FormGroup>
              <Label htmlFor="mi_description">Details</Label>
              <Input onChange={updateMessage} id="mi_description" type="textarea"></Input>
              <FormText>Please provide details of the specific missing information.</FormText>
            </FormGroup>

            <LoadingButton onClick={handleConfirm} color="primary" loading={loading} disabled={!canSubmit}>
              Confirm
            </LoadingButton>
          </>
        );
      }}
    </WizardContext.Consumer>
  );
};
