import { api } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { ImportModal } from "../../../components/ImportModal";

export const ImportPreprocessingCategoryModal = ({ onImport, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      file: {
        bytes: values.file,
      },
    };

    const res = await api.post(`/admin/preprocessing_categories/import`, snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message, res.data?.meta?.error_details);
    }
  };

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Add Preprocessing Categories from CSV..."
      exampleDownloadPath="/admin/preprocessing_categories/example"
    />
  );
};
