import React, { useState } from "react";
import { Alert } from "reactstrap";
import { LoadingButton } from "../../../../components/LoadingButton";
import { MissingInfoForm } from "../../../../missinginfo/MissingInfoForm";
import { api } from "../../../../util/api";
import { WizardContext } from "../../WizardContext";

export const MissingInfoFormScreen = () => {
  const [loading, setLoading] = useState(false);

  return (
    <WizardContext.Consumer>
      {({ dispatch, caseId, caseUrl, labTests, miInfoUrl }) => {
        const handleNext = async () => {
          setLoading(true);

          try {
            const response = await api.get(miInfoUrl);
            dispatch({
              type: "setAnswer",
              key: "missingInfoFields",
              value: response.data,
              doTransition: true,
            });
          } catch (err) {
            console.error(err);
          } finally {
            setLoading(false);
          }
        };

        return (
          <>
            <Alert color="info">Collect as much of the following information as possible</Alert>

            <MissingInfoForm
              caseId={caseId}
              caseUrl={caseUrl}
              labTests={labTests}
              saveButtonText="Save &amp; Continue"
              afterSave={handleNext}
              ExtraButtons={({ submitting }) => (
                <LoadingButton loading={loading} disabled={loading || submitting} color="primary" onClick={handleNext}>
                  Continue without Saving
                </LoadingButton>
              )}
            />
          </>
        );
      }}
    </WizardContext.Consumer>
  );
};
