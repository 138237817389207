import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { TabbedRoutes } from "../components/TabbedRoutes";
import { Routes } from "../Routes";
import { useAdminResource } from "../utils";
import { GroupsGeneralTab } from "./tabs/GroupsGeneralTab";
import { GroupsMembersTab } from "./tabs/GroupsMembersTab";

const useBreadcrumbs = (pageTitle, groupId) =>
  useMemo(
    () => [
      { url: Routes.groups.url(), label: Routes.groups.label },
      { url: Routes.group.url(groupId), label: pageTitle, active: true },
    ],
    [pageTitle, groupId]
  );

const useGroup = (groupId) => useAdminResource(`/admin/groups/${groupId}.json`);

const TABS = [
  {
    path: "general",
    label: "General",
    Component: GroupsGeneralTab,
  },
  {
    path: "members",
    label: "Members",
    Component: GroupsMembersTab,
  },
];

export const GroupsShowPage = () => {
  const { groupId } = useParams();

  const { resource: group, error, isLoading, fetch } = useGroup(groupId);
  const pageTitle = group?.teamName || `Group #${groupId}`;
  const breadcrumbs = useBreadcrumbs(pageTitle, groupId);

  return (
    <SystemConfigLayout title={pageTitle} showBreadcrumbs crumbs={breadcrumbs} loading={isLoading}>
      {error && <Alert color="danger">{error}</Alert>}

      <TabbedRoutes tabs={TABS} defaultTab={"general"} group={group} groupId={groupId} refresh={fetch} />
    </SystemConfigLayout>
  );
};
