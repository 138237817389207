import axios from "axios";
import React, { useCallback, useMemo, useEffect, useRef, useState } from "react";
import { api } from "../../util/api";
import { encodeTableState } from "../../util/table";
import { useDebounced } from "../../util/hooks";
import { PersistantReactTable } from "../../components/grid/PersistantReactTable";
import { columns } from "./columns";

const getSearchFromParams = () => {
  // window.location.search is the entire query string
  const params = new URLSearchParams(window.location.search);
  return params.get("search") || "";
};

export default function CasesGrid({
  dataUrl,
  title,
  pageContext,
  filterOptions,
  actionColumn,
  isInternalUser,
  totalItems: defaultTotalItems,
}) {
  const [data, setData] = useState([]);
  const [pages, setPages] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(getSearchFromParams());
  const [totalItems, setTotalItems] = useState(defaultTotalItems);

  const tableRef = useRef();

  const fetchData = useDebounced(
    useCallback(
      async ({ params }) => {
        setLoading(true);

        try {
          let dataReq = await api.get(dataUrl, { params });

          setPages(dataReq.data.pages);
          setData(dataReq.data.rows);
          setTotalItems(dataReq.data.total);
        } catch (e) {
          if (!axios.isCancel(e)) {
            console.error(e);
          }
        } finally {
          setLoading(false);
        }
      },
      [dataUrl]
    ),
    400
  );

  const onFetchData = useCallback(
    (rawTableState) => {
      const params = encodeTableState(rawTableState, { search });
      fetchData({ params });
    },
    [fetchData, search]
  );

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.updateTableData();
    }
  }, [search]);

  const onSearchChange = (e) => {
    const search = e.target.value;
    setSearch(search);
  };

  const extraColumns = actionColumn ? [actionColumn] : [];
  const tableColumns = columns(filterOptions, pageContext, extraColumns, isInternalUser);

  const pageSize = data.length > 0 ? data.length : 20;

  return (
    <PersistantReactTable
      ref={tableRef}
      columns={tableColumns}
      data={data}
      pages={pages}
      onSearch={onSearchChange}
      search={search}
      title={`${title} Cases`}
      loading={loading}
      onFetchData={onFetchData}
      defaultPageSize={pageSize}
      filterLabel={title}
      totalItems={totalItems}
    />
  );
}
