import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { TestAliasForm } from "./TestAliasForm";

export const CreateTestAliasModal = ({ handleTestAliasCreate, test, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async (values) => {
    setError(null);

    const labTestMapping = {
      labId: values.lab.id,
      testId: values.id,
      testIdentifier: values.labTestMappingTestIdentifier,
      testName: values.labTestMappingTestName,
    };

    try {
      await api.post("/admin/lab_test_mappings", snakifyKeys({ labTestMapping }));

      if (handleTestAliasCreate) {
        handleTestAliasCreate(values.labTestMappingTestName || values.labTestMappingTestIdentifier);
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Test Alias
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add Test Alias</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{ ...test }}>
            {(props) => <TestAliasForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
