import { Button, Card, Col, Collapse, Row } from "reactstrap";
import { BlankableField } from "../../../components/inputs/final_form/BlankableField";
import { OnChange } from "react-final-form-listeners";
import { Field, useField } from "react-final-form";
import { ChevronLeft, ChevronRight, Info, Trash } from "lucide-react";
import { useState } from "react";
import { InputWrapper } from "../../../components/inputs/final_form/InputWrapper";
import { AnimatePresence, motion } from "framer-motion";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";

const RowHeader = () => {
  return (
    <Row className="mb-1">
      <Col xs="3">
        <p
          style={{
            marginBottom: "0",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Status
        </p>
      </Col>
      <Col xs="5">
        <p
          style={{
            marginBottom: "0",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Substatus
        </p>
      </Col>
      <Col className="me-auto" xs="3">
        <p
          style={{
            marginBottom: "0",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Result Status
        </p>
      </Col>
    </Row>
  );
};

const AddButton = ({ onAdd }) => {
  return (
    <Button color="primary" title="Add a new translation row..." block type="button" onClick={onAdd}>
      Add New Translation
    </Button>
  );
};

const validateStatus = (translations, rowIndex) => (value) => {
  if (translations && translations?.value.some((translation, index) => translation.status === value && rowIndex !== index))
    return "Status already selected.";

  return undefined;
};

const validateSubstatus = (translations, rowIndex) => (value) => {
  if (
    translations &&
    translations?.value.some((translation, index) => translation.substatus === value && rowIndex !== index && value !== undefined)
  )
    return "Substatus already selected.";

  return undefined;
};

const TranslationRow = ({ row, idx, onChange, onRemove, inputValue }) => {
  const statusKeys = Object.keys(window.pa_statuses);
  const statusOptions = Object.values(window.pa_statuses).map((status, index) => ({ label: status.name, value: statusKeys[index] }));

  const substatusKeys = Object.keys(window.pa_substatuses);
  const substatusOptions = Object.values(window.pa_substatuses).map((status, index) => ({
    label: status,
    value: substatusKeys[index],
  }));

  const statusChangeHandler = (status) => {
    onChange(idx, "status", status);
  };

  const substatusChangeHandler = (substatus) => {
    onChange(idx, "substatus", substatus);
  };

  const resultStatusChangeHandler = (resultStatus) => {
    onChange(idx, "result_status", resultStatus);
  };

  return (
    <Row>
      <Col xs="3">
        <BlankableField
          isClearable
          name={`status-${idx}`}
          component={SelectInput}
          placeholder="Select a PA Status"
          options={statusOptions}
          value={row.status}
          initialValue={row.status}
          disabled={row.substatus ?? false}
          validate={validateStatus(inputValue, idx)}
        />

        <OnChange name={`status-${idx}`}>{statusChangeHandler}</OnChange>
      </Col>
      <Col xs="5">
        <BlankableField
          name={`substatus-${idx}`}
          isClearable
          component={SelectInput}
          placeholder="Select a PA Substatus"
          options={substatusOptions}
          value={row.substatus}
          initialValue={row.substatus}
          disabled={row.status ?? false}
          validate={validateSubstatus(inputValue, idx)}
        />

        <OnChange name={`substatus-${idx}`}>{substatusChangeHandler}</OnChange>
      </Col>
      <Col xs="3">
        <Field
          name={`resultStatus-${idx}`}
          value={row.result_status}
          initialValue={row.result_status}
          component={TextInput}
          placeholder="End Status"
          type="text"
        />

        <OnChange name={`resultStatus-${idx}`}>{resultStatusChangeHandler}</OnChange>
      </Col>
      <Col xs="1" className="ms-auto">
        <Button color="danger" size="sm" title="Remove translation row..." type="button" onClick={onRemove}>
          <Trash size={16} />
        </Button>
      </Col>
    </Row>
  );
};

export const StatusTranslationsFields = ({ form, translations }) => {
  const initialValue = useField("labSetting.statusTranslations", { subscription: { value: true } })?.input?.value;

  const initialJSONValue = JSON.parse(initialValue || "[]");

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState({ value: [...initialJSONValue] });

  const toggleOpen = () => setOpen(!open);

  const jsonValidValues = () => {
    const valid = inputValue.value.filter((v) => {
      return (v.status || v.substatus) && v.result_status;
    });

    return JSON.stringify(valid);
  };

  const hasUnsavedValues = translations !== initialValue && initialValue !== "";

  const onAdd = () => {
    const val = inputValue.value;
    val.push({ result_status: "" });
    setInputValue({ value: val });
  };

  const onRemove = (idx) => {
    const val = inputValue.value;

    val.splice(idx, 1);

    setInputValue({ value: val });

    form.change("labSetting.statusTranslations", JSON.stringify(val));
  };

  const onChange = (idx, name, value) => {
    const val = inputValue.value;
    const row = val[idx];

    row[name] = value;

    setInputValue({ value: val });
  };

  return (
    <InputWrapper>
      <h6>Status Translations</h6>

      <AnimatePresence mode="sync">
        <Row>
          <Col xs="1">
            <Card className="p-1">
              {inputValue && inputValue.value.length > 0 ? (
                <p className="fw-semibold text-center">{inputValue.value.length} translations configured</p>
              ) : (
                <p className="text-center">No translations configured</p>
              )}

              {hasUnsavedValues && (
                <span className="badge badge-warning mb-2" title="Submit form to save translations...">
                  <small>Unsaved</small>
                </span>
              )}

              <Button color="secondary" onClick={toggleOpen} title={open ? "Close translation panel." : "Open translation panel."}>
                {open ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
              </Button>
            </Card>
          </Col>

          <Col xs="8">
            <motion.div
              initial={{ width: 0 }}
              animate={{ width: open ? "100%" : 0, height: "100%" }}
              exit={{ width: 0 }}
              transition={{ type: "spring", stiffness: 50 }}
            >
              <Collapse horizontal isOpen={open}>
                <Card className="mb-2 text-start px-2 pb-2">
                  <Field name="labSetting.statusTranslations" component={TextInput} type="hidden" initialValue={jsonValidValues()} />

                  <RowHeader />

                  {inputValue &&
                    inputValue.value?.map((row, i) => (
                      <TranslationRow row={row} key={i} idx={i} onRemove={() => onRemove(i)} onChange={onChange} inputValue={inputValue} />
                    ))}

                  <AddButton onAdd={onAdd} />
                </Card>
              </Collapse>
            </motion.div>
          </Col>

          {open && (
            <Col xs="3">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: "spring", delay: 1, stiffness: 50 }}
                className="bg-info bg-opacity-10 p-4 rounded-2 text-center"
              >
                <h5>Hint:</h5>
                <p className="mb-0">Status and Result Status</p>
                <small>or</small>
                <p>Substatus and Result Status.</p>

                <Info size={24} />
              </motion.div>
            </Col>
          )}
        </Row>
      </AnimatePresence>
    </InputWrapper>
  );
};
