import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import { PriceComparison } from "../price_comparison/PriceComparison";

export const initPriceComparison = (isChild = false) => {
  const comparisonContainer = document.getElementById("price_comparison");

  if (comparisonContainer) {
    const { caseId, payorShowPriceComparison } = comparisonContainer.dataset;
    const showPriceComparison = payorShowPriceComparison === "true";

    if (isChild) {
      render(<PriceComparison caseId={caseId} payorShowPriceComparison={showPriceComparison} />, comparisonContainer);
    } else {
      const root = createRoot(comparisonContainer);
      root.render(<PriceComparison caseId={caseId} />);
    }
  }
};
