import { ActionPossibleScreen } from "./ActionPossibleScreen";
import { AnyFollowupScreen } from "./AnyFollowupScreen";
import { CallPayorScreen } from "./CallPayorScreen";
import { ConfirmFaxScreen } from "./ConfirmFaxScreen";
import { MoveToMissingInfoScreen } from "./MoveToMissingInfoScreen";
import { ContactSupervisorScreen } from "./ContactSupervisorScreen";
import { NoAnswerScreen } from "./NoAnswerScreen";
import { PayorConversationScreen } from "./PayorConversationScreen";
import { PayorFollowupScreen } from "./PayorFollowupScreen";
import { RecordResponseScreen } from "./RecordResponseScreen";

export const ACTION_POSSIBLE_STEP = "action_possible";
export const PAYOR_FOLLOWUP_STEP = "payor_followup_required";
export const ANY_FOLLOWUP_STEP = "any_followup_required";
export const CALL_PAYOR_STEP = "call_payor";
export const NO_ANSWER_STEP = "no_answer";
export const MOVE_TO_MI_STEP = "move_to_missinginformation";
export const CONFIRM_FAX_STEP = "confirm_fax";
export const PAYOR_CONVERSATION_STEP = "payor_conversation";
export const RECORD_RESPONSE_STEP = "record_response";
export const CONTACT_SUPERVISOR_STEP = "contact_supervisor";

export const STEPS = {
  [ACTION_POSSIBLE_STEP]: {
    initial: true,
    next({ answers }) {
      if (answers.canTakeAction) {
        return RECORD_RESPONSE_STEP;
      } else {
        return PAYOR_FOLLOWUP_STEP;
      }
    },
    Component: ActionPossibleScreen,
  },
  [PAYOR_FOLLOWUP_STEP]: {
    next({ answers }) {
      if (answers.additionalPayorFollowup) {
        return CALL_PAYOR_STEP;
      } else {
        return ANY_FOLLOWUP_STEP;
      }
    },
    Component: PayorFollowupScreen,
  },
  [ANY_FOLLOWUP_STEP]: {
    next({ answers }) {
      if (answers.additionalFollowup) {
        return CONTACT_SUPERVISOR_STEP;
      } else {
        return RECORD_RESPONSE_STEP;
      }
    },
    Component: AnyFollowupScreen,
  },
  [CALL_PAYOR_STEP]: {
    next({ answers }) {
      if (answers.payorCallAnswered) {
        return PAYOR_CONVERSATION_STEP;
      } else {
        return NO_ANSWER_STEP;
      }
    },
    Component: CallPayorScreen,
  },
  [PAYOR_CONVERSATION_STEP]: {
    next({ answers }) {
      if (!answers.payorHasReceivedSubmission) {
        if (answers.patientHadCoverage) {
          return CONFIRM_FAX_STEP;
        } else {
          return MOVE_TO_MI_STEP;
        }
      } else if (!answers.payorHasReachedDetermination) {
        return false;
      } else if (answers.payorHasSentDetermination) {
        return RECORD_RESPONSE_STEP;
      }
    },
    Component: PayorConversationScreen,
  },
  [RECORD_RESPONSE_STEP]: {
    next: false,
    Component: RecordResponseScreen,
  },
  [NO_ANSWER_STEP]: {
    next: false,
    Component: NoAnswerScreen,
  },
  [CONTACT_SUPERVISOR_STEP]: {
    next: false,
    Component: ContactSupervisorScreen,
  },
  [MOVE_TO_MI_STEP]: {
    next: false,
    Component: MoveToMissingInfoScreen,
  },
  [CONFIRM_FAX_STEP]: {
    next: false,
    Component: ConfirmFaxScreen,
  },
};
