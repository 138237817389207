import React, { useMemo } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { attachmentCategoryOptions } from "../components/AttachButton";
import { AttachToCaseForm } from "../components/AttachToCaseForm";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { useCase } from "../util/hooks";
import { formatDate } from "../util/formatters";
import { AttachedCaseDetails } from "./AttachedCaseDetails";
import { useFaxAttachmentForm } from "./useFaxAttachmentForm";

const PreviewData = ({ firstName, lastName, dob, memberId }) => {
  const fullName = [firstName, lastName]
    .filter((n) => Boolean(n))
    .join(" ")
    .trim();

  const hasName = Boolean(fullName);
  const hasDob = Boolean(dob);
  const hasMemberId = Boolean(memberId);
  const hasDetails = hasName || hasDob || hasMemberId;

  return (
    <Card className="mb-3">
      <CardHeader>Fax Details</CardHeader>
      <CardBody>
        {hasDetails ? (
          <dl>
            {hasName && (
              <>
                <dt>Patient Name</dt>
                <dd>{fullName}</dd>
              </>
            )}

            {hasDob && (
              <>
                <dt>DOB</dt>
                <dd>{formatDate(dob)}</dd>
              </>
            )}

            {hasMemberId && (
              <>
                <dt>Member ID</dt>
                <dd>
                  <code>{memberId}</code>
                </dd>
              </>
            )}
          </dl>
        ) : (
          <span className="text-muted">No Information Parsed</span>
        )}
      </CardBody>
    </Card>
  );
};

/** The Preview Fax Form
 * @param {Object} params
 * @param {string} params.id
 * @param {Object} params.fax
 * @param {boolean} params.canAttach
 */
export const PreviewFaxForm = ({ id, fax, canAttach }) => {
  const { onLoadCase, acknowledgements, onSubmit } = useFaxAttachmentForm(fax);
  const kaseRequest = useCase(fax.case_id);
  const category = useMemo(() => {
    return attachmentCategoryOptions.find((opt) => opt.value === fax.prior_auth_request_attachment?.category)?.label;
  }, [fax.prior_auth_request_attachment?.category]);

  return (
    <Row>
      <Col md={8}>
        <div className="ratio ratio-4x3">
          <iframe className="embed-responsive-item" src={`/operations/fax/${id}/view_fax`} />
        </div>
      </Col>
      <Col md={4}>
        {canAttach && (
          <PreviewData
            firstName={fax.parsed_patient_data?.first_name}
            lastName={fax.parsed_patient_data?.last_name}
            dob={fax.parsed_patient_data?.dob}
            memberId={fax.parsed_patient_data?.member_id}
          />
        )}

        {(canAttach || fax.case_id) && (
          <Card>
            <CardHeader>{fax.case_id ? "Fax Linked to Case" : "Link Fax to Case"}</CardHeader>

            <CardBody>
              <DetailedErrorAlert error={kaseRequest.error} />

              {fax.case_id ? (
                <AttachedCaseDetails
                  caseId={fax.case_id}
                  kase={kaseRequest.value}
                  loaded={kaseRequest.loaded}
                  loading={kaseRequest.loading}
                  category={category}
                />
              ) : (
                <AttachToCaseForm
                  onSubmit={onSubmit}
                  onLoadCase={onLoadCase}
                  type="fax"
                  withCategory={true}
                  proposedCaseId={fax.proposed_case_id}
                  acknowledgements={acknowledgements}
                />
              )}
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  );
};
