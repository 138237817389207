import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { EditPayorGroupFeeScheduleModal } from "./EditPayorGroupFeeScheduleModal";
import { useDestroyPermission, useUpdatePermission } from "../../utils";

export const PayorGroupFeeScheduleActionCell = ({ row }) => {
  const [canUpdate] = useUpdatePermission("MedicaidFeeSchedule", row.original.id);
  const [canDestroy] = useDestroyPermission("MedicaidFeeSchedule", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = (msg) => {
    setMessage(`Updated Medicaid Fee Schedule ${row.original.id}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditPayorGroupFeeScheduleModal color="link" onEdit={handleChange} feeSchedule={row.original} />}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/medicaid_fee_schedules/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Medicaid Fee Schedule ${row.original.id}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
    </>
  );
};
