import React, { useMemo } from "react";
import { Field } from "react-final-form";
import { DropzoneInput } from "../../../components/inputs/final_form/DropzoneInput";
import { LabSftpSettingModal } from "./LabSftpSettingsModal";

export const EditLabSftpSettingModal = ({ labId, sftp, onEdit }) => {
  const inputAttributes = useMemo(
    () => ({
      component: DropzoneInput,
      hint: "Drop Keyfile",
      initialValue: sftp.keyfile_uploaded ? [{ name: "Keyfile provided" }] : undefined,
    }),
    [sftp.keyfile_uploaded]
  );

  return (
    <LabSftpSettingModal
      labId={labId}
      onAction={onEdit}
      sftp={sftp}
      toggleButtonLabel="Edit"
      toggleButtonProps={{ color: "link" }}
      actionTitle="Edit"
      keyfileInput={<Field accept={null} label="Keyfile" name="keyfile" {...inputAttributes} />}
    />
  );
};
