export const SPECIALIES = {
  abdominal_radiology: "Abdominal Radiology",
  abdominal_surgery: "Abdominal Surgery",
  adolescent_medicine_family_practice: "Adolescent Medicine (Family Practice)",
  adolescent_medicine_peds: "Adolescent Medicine-Peds",
  adult_cardiothoracic_anesthesiology: "Adult Cardiothoracic Anesthesiology",
  emergency_medicine_family_medicine: "Emergency Medicine/Family Medicine",
  emergency_sports_medicine: "Emergency/sports Medicine",
  endocrinology: "Endocrinology",
  endocrinology_diabetes_metabolism: "Endocrinology, Diabetes & Metabolism",
  endovascular_surgical_neuroradiology: "Endovascular Surgical Neuroradiology",
  endovascular_surgical_neuroradiology_neurology: "Endovascular Surgical Neuroradiology (Neurology)",
  epidemiology: "Epidemiology",
  epilepsy: "Epilepsy",
  facial_plastic_surgery: "Facial Plastic Surgery",
  family_medicine_preventive_medicine: "Family Medicine/Preventive Medicine",
  family_prac_sports_medicine: "Family Prac/sports Medicine",
  family_practice_general_practice: "Family Practice/general Practice",
  female_pelvic_medicine_and_reconstructive_surgery: "Female Pelvic Medicine and Reconstructive Surgery",
  flex_residents: "Flex Residents",
  forensic_pathology: "Forensic Pathology",
  forensic_psychiatry: "Forensic Psychiatry",
  gastroenterology: "Gastroenterology",
  general_practice: "General Practice",
  general_preventive_medicine: "General Preventive Medicine",
  general_surgery: "General Surgery",
  geriatrics: "Geriatrics",
  gynecological_oncology: "Gynecological Oncology",
  gynecology: "Gynecology",
  hand_surgery: "Hand Surgery",
  hand_surgery_plastic_surgery: "Hand Surgery (Plastic Surgery)",
  hand_surgery_surgery: "Hand Surgery (Surgery)",
  head_neck_surgery: "Head & Neck Surgery",
  hematology: "Hematology",
  hematology_oncology: "Hematology/oncology",
  hematology_pathology: "Hematology/pathology",
  hepatology: "Hepatology",
  hospice_palliative_med_obstetrics_gynecology: "Hospice & Palliative Med (Obstetrics & Gynecology)",
  hospice_palliative_med_physical_med_rehab: "Hospice & Palliative Med (Physical Med & Rehab)",
  hospice_palliative_medicine: "Hospice & Palliative Medicine",
  infectious_disease: "Infectious Disease",
  internal_medicine: "Internal Medicine",
  internal_medicine_pediatrics: "Internal Medicine/Pediatrics",
  interventional_cardiology: "Interventional Cardiology",
  medical_genetics: "Medical Genetics",
  medical_microbiology: "Medical Microbiology",
  medical_toxicology_emergency_medicine: "Medical Toxicology (Emergency Medicine)",
  medical_toxicology_pediatrics: "Medical Toxicology (Pediatrics)",
  medical_toxicology_preventive_medicine: "Medical Toxicology (Preventive Medicine)",
  mohs_micrographic_surgery: "Mohs-Micrographic Surgery",
  neonatal_perinatal_medicine: "Neonatal-Perinatal Medicine",
  nephrology: "Nephrology",
  neurological_surgery: "Neurological Surgery",
  neurology: "Neurology",
  neurology_child_neurology: "Neurology/Child Neurology",
  neuromuscular_medicine: "Neuromuscular Medicine",
  neuromusculoskeletal_medicine_omm: "Neuromusculoskeletal Medicine & OMM",
  neuropathology: "Neuropathology",
  neuroradiology: "Neuroradiology",
  nuclear_medicine: "Nuclear Medicine",
  nuclear_radiology: "Nuclear Radiology",
  obstetrics_gynecology: "Obstetrics & Gynecology",
  occupational_medicine: "Occupational Medicine",
  ophthalmology: "Ophthalmology",
  orthopaedic_surgery: "Orthopaedic Surgery",
  orthopaedic_surgery_of_the_spine: "Orthopaedic Surgery of the Spine",
  orthopaedic_trauma: "Orthopaedic Trauma",
  otolaryngology: "Otolaryngology",
  pain_medicine: "Pain Medicine",
  pain_medicine_anesthesiology: "Pain Medicine (Anesthesiology)",
  pain_medicine_neurology: "Pain Medicine (Neurology)",
  pain_medicine_physical_med_rehab: "Pain Medicine (Physical Med & Rehab)",
  pain_medicine_psychiatry: "Pain Medicine (Psychiatry)",
  pediatric_allergy: "Pediatric Allergy",
  pediatric_anesthesiology: "Pediatric Anesthesiology",
  pediatric_cardiology: "Pediatric Cardiology",
  pediatric_critical_care_medicine: "Pediatric Critical Care Medicine",
  pediatric_dermatology: "Pediatric Dermatology",
  pediatric_emergency_medicine: "Pediatric Emergency Medicine",
  pediatric_endocrinology: "Pediatric Endocrinology",
  pediatric_gastroenterology: "Pediatric Gastroenterology",
  pediatric_hematology_oncology: "Pediatric Hematology-Oncology",
  pediatric_infectious_diseases: "Pediatric Infectious Diseases",
  pediatric_nephrology: "Pediatric Nephrology",
  pediatric_ophthalmology: "Pediatric Ophthalmology",
  pediatric_orthopaedic_surgery: "Pediatric Orthopaedic Surgery",
  pediatric_otolaryngology: "Pediatric Otolaryngology",
  pediatric_pathology: "Pediatric Pathology",
  pediatric_pulmonology: "Pediatric Pulmonology",
  pediatric_radiology: "Pediatric Radiology",
  pediatric_rehab_medicine: "Pediatric Rehab Medicine",
  pediatric_rheumatology: "Pediatric Rheumatology",
  pediatric_sports_medicine: "Pediatric Sports Medicine",
  pediatric_surgery: "Pediatric Surgery",
  pediatric_transplant_hepatology: "Pediatric Transplant Hepatology",
  pediatrics: "Pediatrics",
  pediatrics_emergency_medicine: "Pediatrics - Emergency Medicine",
  pediatrics_medical_genetics: "Pediatrics - Medical Genetics",
  pediatrics_diag_lab_immunology: "Pediatrics/diag Lab Immunology",
  pediatrics_physical_medicine_and_rehabilitation: "Pediatrics/Physical Medicine and Rehabilitation",
  pediatrics_psych_child_and_adol_psych: "Pediatrics/Psych/Child and Adol Psych",
  pharmaceutical_medicine: "Pharmaceutical Medicine",
  pharmacology_clinical: "Pharmacology - Clinical",
  phlebology: "Phlebology",
  physical_medicine_rehab: "Physical Medicine & Rehab",
  physical_medicine_rehabilitation: "Physical Medicine & Rehabilitation",
  plastic_surgery: "Plastic Surgery",
  plastic_surgery_head_neck: "Plastic Surgery - Head & Neck",
  plastic_surgery_within_the_head_neck: "Plastic Surgery within the Head & Neck",
  plastic_surgery_within_the_head_neck_ent: "Plastic Surgery within the Head & Neck (ENT)",
  preventive_medical_toxicology: "Preventive Medical Toxicology",
  preventive_medicine: "Preventive Medicine",
  procedural_dermatology: "Procedural Dermatology",
  proctology: "Proctology",
  psychiatry: "Psychiatry",
  psychiatry_addiction_psychiatry: "Psychiatry - Addiction Psychiatry",
  psychiatry_family_practice: "Psychiatry - Family Practice",
  psychiatry_neurology: "Psychiatry - Neurology",
  psychoanalysis: "Psychoanalysis",
  psychosomatic_medicine: "Psychosomatic Medicine",
  public_health_genl_prevent_med: "Public Health/genl Prevent Med",
  pulmonary_critical_care_med: "Pulmonary Critical Care Med.",
  pulmonary_disease: "Pulmonary Disease",
  radiation_oncology: "Radiation Oncology",
  radiological_physics: "Radiological Physics",
  radiology: "Radiology",
  reproductive_endocrinology: "Reproductive Endocrinology",
  rheumatology: "Rheumatology",
  selective_pathology: "Selective Pathology",
  sleep_medicine: "Sleep Medicine",
  sleep_medicine_internal_medicine: "Sleep Medicine (Internal Medicine)",
  sleep_medicine_otolaryngology: "Sleep Medicine (Otolaryngology)",
  sleep_medicine_pediatrics: "Sleep Medicine (Pediatrics)",
  sleep_medicine_psych_neurology: "Sleep Medicine (Psych & Neurology)",
  spinal_cord_injury: "Spinal Cord Injury",
  sports_medicine_physical_medicine_rehab: "Sports Medicine (Physical Medicine & Rehab)",
  surgery: "Surgery",
  surgical_oncology: "Surgical Oncology",
  thoracic_surgery: "Thoracic Surgery",
  thoracic_surgery_integrated: "Thoracic Surgery - Integrated",
  transitional_year: "Transitional Year",
  transplant_hepatology_internal_medicine: "Transplant Hepatology (Internal Medicine)",
  transplant_surgery: "Transplant Surgery",
  traumatic_surgery: "Traumatic Surgery",
  undersea_and_hyperbaric_medicine_em: "Undersea and Hyperbaric Medicine-EM",
  undersea_medicine: "Undersea Medicine",
  unspecified_specialty: "Unspecified Specialty",
  urgent_care_medicine: "Urgent Care Medicine",
  urological_surgery: "Urological Surgery",
  urologyPediatric_urology: "UrologyPediatric Urology",
  vascular_interventional_rad: "Vascular & Interventional Rad",
  vascular_medicine: "Vascular Medicine",
  vascular_neurology: "Vascular Neurology",
  vascular_surgery: "Vascular Surgery",
  vascular_surgery_integrated: "Vascular Surgery- Integrated",
};
