import React from "react";
import { extractErrorDetails, extractErrorMessage } from "../util/api";
import { DetailedAlert } from "./DetailedAlert";

export const DetailedErrorAlert = ({ message, error, fallbackMessage, ...rest }) => {
  const details = extractErrorDetails(error);
  const errorMessage = message ?? extractErrorMessage(error, fallbackMessage);

  // We only show the error if we have an error
  const showError = Boolean(error);

  if (!errorMessage || !showError) {
    return null;
  }

  return <DetailedAlert message={errorMessage} details={details} messageStyle="danger" />;
};
