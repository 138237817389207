import { Link } from "react-router-dom";
import { Toolbar } from "../../../components/Toolbar";
import { findMappingId } from "../../../util/helpers";
import { AdministrateTable } from "../../components/AdministrateTable";
import { Routes } from "../../Routes";
import { DESTROY, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { AddLabPortalModal } from "../components/AddLabPortalModal";
import { LabPortalManagementActionCell } from "../components/LabPortalManagementActionCell";

const useColumns = makeColumns((labSettingId) => [
  {
    id: "name",
    accessor: "name",
    Header: "Name",
    Cell: ({ row, value }) => <Link to={Routes.portal.url(row.original.id)}>{value}</Link>,
  },
  {
    id: "actions",
    Header: "Actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => (
      <LabPortalManagementActionCell mappingId={findMappingId(row.original.labPortalMappings, labSettingId, "lab_setting_id")} {...row} />
    ),
  },
]);

export const LabsPortalManagementTab = ({ lab, labId }) => {
  const columns = useColumns(lab.labSetting.id);
  const extraParams = { labs: { id: labId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);
  const [canCreate] = useCreatePermission("LabPortalMapping");

  const onCreate = (results) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(results.meta.message);
    refresh();
  };

  return (
    <>
      {canCreate && (
        <Toolbar className="align-items-center mb-3">
          <Toolbar className="ms-auto">
            <AddLabPortalModal color="primary" labId={labId} onAdd={onCreate} />
          </Toolbar>
        </Toolbar>
      )}

      <AdministrateTable
        resourceUrl={"/admin/benefit_management_portals.json"}
        extraParams={extraParams}
        permissions={[DESTROY]}
        resourceName={"benefit_management_portal"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
