import { createRoot } from "react-dom/client";
import { Dashboard } from "../dashboard";

export const initDashboard = () => {
  const gridContainer = document.getElementById("cases-dashboard");

  if (gridContainer) {
    const root = createRoot(gridContainer);
    const {
      displayStats,
      archivedCases,
      searchableStatuses,
      searchablePayors,
      latestReportModal,
      exportModal,
      showDownloads,
      showLatestReport,
      showProvidedPayor,
      isPriorAuthEnabled,
      isBenefitsInvestigationEnabled,
      isPharmacyClinicUser,
    } = gridContainer.dataset;

    const parsedSearchableStatuses = searchableStatuses ? JSON.parse(searchableStatuses) : [];
    const parsedArchivedCases = archivedCases ? JSON.parse(archivedCases) : false;
    const payorOptions = searchablePayors ? JSON.parse(searchablePayors) : [];
    const parsedShowDownloads = showDownloads ? JSON.parse(showDownloads) : false;
    const parsedShowLatestReport = showLatestReport ? JSON.parse(showLatestReport) : false;
    const parsedIsBenefitsInvestigationEnabled = isBenefitsInvestigationEnabled ? JSON.parse(isBenefitsInvestigationEnabled) : false;
    const parsedIsPriorAuthEnabled = isPriorAuthEnabled ? JSON.parse(isPriorAuthEnabled) : false;
    const parsedShowProvidedPayor = showProvidedPayor ? JSON.parse(showProvidedPayor) : false;
    const parsedIsPharmacyClinicUser = isPharmacyClinicUser ? JSON.parse(isPharmacyClinicUser) : false;

    root.render(
      <Dashboard
        displayStats={!!displayStats}
        archivedCases={parsedArchivedCases}
        searchableStatuses={parsedSearchableStatuses}
        payors={payorOptions}
        latestReportModal={latestReportModal}
        exportModal={exportModal}
        showDownloads={parsedShowDownloads}
        showLatestReport={parsedShowLatestReport}
        showProvidedPayor={parsedShowProvidedPayor}
        isBenefitsInvestigationEnabled={parsedIsBenefitsInvestigationEnabled}
        isPriorAuthEnabled={parsedIsPriorAuthEnabled}
        isPharmacyClinicUser={parsedIsPharmacyClinicUser}
      />
    );
  }
};
