import React, { useState } from "react";
import { DetailedErrorAlert } from "../../components/DetailedErrorAlert";
import { api } from "../../util/api";
import { ClinicFollowupWizard } from "./ClinicFollowupWizard";
import { LabFollowupWizard } from "./LabFollowupWizard";

export const MI_TYPE_CLINIC = "clinic";
export const MI_TYPE_LAB = "lab";

const sharedInitialAnswers = {
  substatus: null,
  missingInfoFields: [],
  miDetailMessage: null,
};

export const initialClinicAnswers = {
  ...sharedInitialAnswers,
  clinicCallAnswered: null,
  miType: MI_TYPE_CLINIC,
};

export const initialLabAnswers = {
  ...sharedInitialAnswers,
  miType: MI_TYPE_LAB,
};

export const numMissingFields = (fields) => {
  return fields.filter((f) => f.missing).length;
};

export const MissingInfoFollowupWizard = ({
  caseId,
  caseUrl,
  followupContactType,
  labTests,
  caseData,
  statusUrl,
  assigneeUrl,
  commentsUrl,
  snoozeUrl,
  tasksUrl,
  nextStepUrl,
  submitUrl,
  miInfoUrl,
  missingFields,
  moveToStepUrl,
  recordMiContactAttemptUrl,
  recordMiSalesforceContactUrl,
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const substatus = caseData.requestSubstatus;
  const hasSupplementalDocuments = caseData.supplementalDocuments.length > 0;
  const alreadyContacted = caseData.workflowInfo.priorAuthRequest.previousContactAttempts.some((a) => a.miType === followupContactType);

  const createMiContactAttempt = async (miType, miMessage = null, contactMethod = "phone") => {
    setLoading(true);
    setError(null);

    try {
      await api.post(recordMiContactAttemptUrl, {
        mi_contact: {
          contact_type: "manual",
          contact_method: contactMethod,
          mi_type: miType,
          mi_message: miMessage,
        },
      });
    } catch (err) {
      setError(err);

      // Re-throw to prevent progress
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const updateCase = async (caseData) => {
    setLoading(true);
    setError(null);

    try {
      await api.patch(caseUrl, caseData);
    } catch (err) {
      setError(err);

      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DetailedErrorAlert className="mb-3" error={error} />

      {followupContactType === MI_TYPE_CLINIC ? (
        <ClinicFollowupWizard
          caseId={caseId}
          caseUrl={caseUrl}
          commentsUrl={commentsUrl}
          labTests={labTests}
          caseData={caseData}
          statusUrl={statusUrl}
          assigneeUrl={assigneeUrl}
          snoozeUrl={snoozeUrl}
          tasksUrl={tasksUrl}
          nextStepUrl={nextStepUrl}
          submitUrl={submitUrl}
          miInfoUrl={miInfoUrl}
          missingFields={missingFields}
          createMiContactAttempt={createMiContactAttempt}
          updateCase={updateCase}
          loading={loading}
          moveToStepUrl={moveToStepUrl}
          substatus={substatus}
          hasSupplementalDocuments={hasSupplementalDocuments}
          alreadyContacted={alreadyContacted}
        />
      ) : (
        <LabFollowupWizard
          caseId={caseId}
          caseUrl={caseUrl}
          commentsUrl={commentsUrl}
          labTests={labTests}
          caseData={caseData}
          statusUrl={statusUrl}
          assigneeUrl={assigneeUrl}
          snoozeUrl={snoozeUrl}
          tasksUrl={tasksUrl}
          nextStepUrl={nextStepUrl}
          submitUrl={submitUrl}
          miInfoUrl={miInfoUrl}
          missingFields={missingFields}
          createMiContactAttempt={createMiContactAttempt}
          updateCase={updateCase}
          loading={loading}
          moveToStepUrl={moveToStepUrl}
          substatus={substatus}
          hasSupplementalDocuments={hasSupplementalDocuments}
          recordMiSalesforceContactUrl={recordMiSalesforceContactUrl}
          alreadyContacted={alreadyContacted}
        />
      )}
    </>
  );
};
