import React from "react";
import { useTableContext } from "../../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useDestroyPermission, useUpdatePermission } from "../../../utils";
import { EditVisualModal } from "./EditVisualModal";

export const VisualActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("SubmitRightVisualManagement", row.original.id);
  const [canUpdate] = useUpdatePermission("SubmitRightVisualManagement", row.original.id);

  return (
    <>
      {canUpdate && (
        <EditVisualModal
          color="link"
          completionScreenVisual={row.original}
          onUpdate={(newVisual) => {
            refresh();
            setMessage(`Updated Visual ${newVisual.description}`);
          }}
        />
      )}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/submit_right_visual_managements/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Visual "${row.original.description}".`);
          }}
          onError={setError}
        />
      )}
    </>
  );
};
