import $ from "jquery";
import { initDenialReason } from "./forms/inputs/init_denial_reason_dropdown";

export const initDenialReasonModal = () => {
  const $denyCaseModal = $("#deny-case-form");

  if ($denyCaseModal.length > 0) {
    initDenialReason();
  }
};
