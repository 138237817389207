import React from "react";
import { EnumFilter } from "./EnumFilter";

const useBoolOptions = (yesLabel = "Yes", noLabel = "No") => [
  { value: 1, label: yesLabel },
  { value: 0, label: noLabel },
];

export const BoolFilter = ({ yesLabel = "Yes", noLabel = "No", ...props }) => (
  <EnumFilter {...props} options={useBoolOptions(yesLabel, noLabel)} />
);
