import _ from "lodash";
import cx from "classnames";
import React from "react";
import { Button, ButtonGroup } from "reactstrap";

export const ButtonToggle = ({ options, value, required, disabled, readonly, autofocus, onChange }) => {
  // Generating a unique field name to identify this set of radio buttons
  const name = `input_${parseInt(Math.random() * 10000).toString()}`;

  return (
    <ButtonGroup>
      {options.map((option, i) => {
        const id = `${name}_${i}`;
        const checked = option.value === value;
        const btnClasses = cx("btn btn-round me-2 btn-muted", { disabled: disabled || readonly });

        return (
          <Button
            disabled={disabled || readonly}
            key={i}
            className={btnClasses}
            htmlFor={id}
            active={checked}
            onClick={() => _.isFunction(onChange) && onChange(option.value)}
          >
            {option.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export const ButtonToggleInput = ({ options, ...rest }) => {
  const { enumOptions } = options;

  return <ButtonToggle options={enumOptions} {...rest} />;
};
