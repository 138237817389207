import { Link } from "react-router-dom";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { Routes } from "../../Routes";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { AddClinicLabRedirectionModal } from "../components/AddClinicLabRedirectionModal";
import { ClinicLabRedirectionActionCell } from "../components/ClinicLabRedirectionActionCell";
import { ImportClinicLabRedirectionsModal } from "../components/ImportClinicLabRedirectionsModal";

const useColumns = makeColumns((clinicId) => [
  {
    id: "associatedLab.id",
    accessor: "associatedLab.labName",
    Header: "Lab",
    Cell: ({ row, value }) => (
      <Link to={Routes.lab.url(row.original.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/labs.json" valueKey="id" labelKey="lab_name" />,
  },
  {
    id: "test.id",
    accessor: "test.commercialName",
    Header: "Test",
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/tests.json" valueKey="id" labelKey="commercial_name" />,
  },
  {
    id: "payor.id",
    accessor: "payor.name",
    Header: "Insurance Name",
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="name" labelKey="name" searchParam="name" />
    ),
    disableSortBy: true,
  },
  {
    id: "funding_type",
    accessor: "fundingType",
    Header: "Insurance Type",
    disableSortBy: true,
  },
  {
    id: "redirectedLab.id",
    accessor: "redirectedLab.labName",
    Header: "Redirected Lab",
    Cell: ({ row, value }) => (
      <Link to={Routes.lab.url(row.original.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/labs.json" valueKey="id" labelKey="lab_name" />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ClinicLabRedirectionActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const ClinicsLabRedirectionTab = ({ clinic, clinicId }) => {
  const [canCreate] = useCreatePermission("ClinicLabRedirection", clinicId);
  const columns = useColumns(clinicId);
  const extraParams = { clinic: { id: clinicId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onAdd = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/clinic_lab_redirections/export"
            filters={allFilters}
            ref={tableRef}
          />
          {canCreate && (
            <>
              <ImportClinicLabRedirectionsModal clinicId={clinicId} outline className="me-2" onImport={onImport} />
              <AddClinicLabRedirectionModal clinicId={clinicId} color="primary" onAdd={onAdd} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/clinic_lab_redirections.json"}
        extraParams={extraParams}
        resourceName={"clinic_lab_redirection"}
        permissions={[UPDATE, DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
