import React from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";
import { ImportModal } from "../../components/ImportModal";

export const ImportLabReportDeliveriesModal = ({ onImport, labId, ...rest }) => {
  const handleImport = async (values) => {
    const params = {
      labId: values.labId,
      file: {
        bytes: values.file,
      },
    };

    const res = await api.post(`/admin/report_delivery_configs/import.json`, snakifyKeys(params));

    if (onImport) {
      onImport(res.data?.meta?.message);
    }
  };

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Add Report Delivery Config to Lab from CSV..."
      initialValues={{ labId }}
      exampleDownloadPath="/admin/report_delivery_configs/example"
    >
      {(formProps) => <Field component={TextInput} name="labId" type="hidden" required validate={required} />}
    </ImportModal>
  );
};
