import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Badge, Container, Spinner, Tooltip } from "reactstrap";
import { formatDateTime } from "../../../util/formatters";
import { useCallback, useEffect, useState } from "react";
import { CircleCheckBig, CircleX, Copy } from "lucide-react";
import { adminApi as api } from "../../../util/api";
import { PaginationComponent } from "../../../components/inputs/PaginationComponent";
import { mapStringsToOptions } from "../../../util/forms";
import { AnimatePresence, motion } from "framer-motion";

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

const parseStatusCodeColor = (code) => {
  switch (true) {
    case code >= 100 && code <= 199:
      return "info";
    case code >= 200 && code <= 299:
      return "success";
    case code >= 300 && code <= 399:
      return "info";
    case code >= 400 && code <= 599:
      return "danger";
    default:
      return "info";
  }
};

const Activity = ({ record, index }) => {
  const [requestTooltipOpen, setRequestTooltipOpen] = useState(false);
  const [responseTooltipOpen, setResponseTooltipOpen] = useState(false);

  const requestTooltipToggle = () => {
    // show tooltip
    setRequestTooltipOpen(true);

    // hide it after tooltipDelay in ms
    setTimeout(() => setRequestTooltipOpen(false), 1000);
  };

  const responseTooltipToggle = () => {
    // show tooltip
    setResponseTooltipOpen(true);

    // hide it after tooltipDelay in ms
    setTimeout(() => setResponseTooltipOpen(false), 1000);
  };

  return (
    <AccordionItem>
      <AccordionHeader targetId={`${index + 1}`}>
        <p>UUID: {record.request_uuid}</p>

        <p className="mx-4">
          Method: <Badge>{record.request_method}</Badge>
        </p>

        <p className="me-4">Identity: {record.identity}</p>

        <p>Timestamp: {formatDateTime(record.created_at)}</p>
      </AccordionHeader>

      <AccordionBody accordionId={`${index + 1}`}>
        <div className="bg-secondary rounded-2 p-4">
          <p className="text-white">
            <strong>Idempotency Key</strong>: {record.idempotency_key}
          </p>

          <p className="text-white">
            <strong>Context</strong>: {record.context}
          </p>

          <p className="text-white">
            <strong>User Agent</strong>: {record.user_agent}
          </p>

          <p className="text-white">
            <strong>Referrer</strong>: {record.referrer || "None"}
          </p>

          <p className="text-white">
            <strong>IP</strong>: {record.ip}
          </p>

          <p className="text-white">
            <strong>Status</strong>: <Badge color={parseStatusCodeColor(record.response_status)}>{record.response_status}</Badge>
          </p>

          <p className="text-white">
            <strong>Success</strong>: {record.success ? <CircleCheckBig color="green" /> : <CircleX color="red" />}
          </p>

          <p className="text-white fw-semibold">Request Body:</p>

          <div className="bg-dark rounded-2 p-2 d-flex flex-row justify-content-between mb-2">
            <code style={{ width: "85%" }} className="text-wrap">
              {record.body || "None"}
            </code>

            <a className="copy text-white btn-copy" data-clipboard-text={record.body} title="Copy to Clipboard.">
              <Copy id={`request-${index}-copy`} className="copy-icon" color="white" size={12} />

              <Tooltip trigger="click" target={`request-${index}-copy`} isOpen={requestTooltipOpen} toggle={requestTooltipToggle}>
                Copied
              </Tooltip>
            </a>
          </div>

          <p className="text-white fw-semibold">Response Body:</p>

          <div className="bg-dark rounded-2 p-2 d-flex flex-row justify-content-between">
            <code style={{ width: "85%" }} className="text-wrap">
              {record.response_body || "None"}
            </code>

            <a className="copy text-white btn-copy" data-clipboard-text={record.response_body} title="Copy to Clipboard.">
              <Copy id={`response-${index}-copy`} className="copy-icon" color="white" size={12} />

              <Tooltip trigger="click" target={`response-${index}-copy`} isOpen={responseTooltipOpen} toggle={responseTooltipToggle}>
                Copied
              </Tooltip>
            </a>
          </div>
        </div>
      </AccordionBody>
    </AccordionItem>
  );
};

const ActivityList = ({ records }) => {
  const [open, setOpen] = useState("1");

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion open={open} toggle={toggle}>
      {records.map((record, index) => {
        return <Activity record={record} index={index} key={`request-${index + 1}`} />;
      })}
    </Accordion>
  );
};

export const AuthTokenApiActivitiesTab = ({ authToken, authTokenId, refresh }) => {
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const params = { page: page, per: pageSize };

      const req = await api.get("/admin/api_activities.json", { params });

      const { meta, resources } = req.data;

      setTotalPages(meta?.total_pages);
      setResults(resources);

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    if (!results) {
      fetchData();
    }
  }, [fetchData, results]);

  const handlePageChange = (args) => {
    setPage(args + 1);
    fetchData();
  };

  return (
    <>
      <Container>
        <div className="bg-info bg-opacity-10 p-3 rounded-2 mb-3">
          <h4>Hint:</h4>
          <p className="mb-0">All previous requests will be listed below.</p>
        </div>

        <AnimatePresence mode="sync">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ type: "spring", delay: 1, stiffness: 50 }}
          >
            {loading ? (
              <div className="d-flex flex-row justify-content-center align-items-center w-100">
                <Spinner color="secondary">Loading</Spinner>
              </div>
            ) : (
              <>
                {results && results.length > 0 ? (
                  <>
                    <PaginationComponent
                      page={page - 1}
                      pages={totalPages}
                      showPageSizeOptions
                      showPageJump
                      pageSize={pageSize}
                      pageSizeOptions={PAGE_SIZE_OPTIONS}
                      loading={loading}
                      canPrevious={page > 1}
                      canNext={page < totalPages}
                      onPageChange={handlePageChange}
                      className="mb-2"
                      onPageSizeChange={setPageSize}
                    />

                    <ActivityList records={results} />
                  </>
                ) : (
                  <div
                    className="d-flex flex-row justify-content-center align-items-center border rounded-2"
                    style={{ minHeight: "12rem" }}
                  >
                    <div className="d-flex flex-row bg-light rounded p-3 justify-content-center gap-1 align-items-center">
                      <p className="lead fw-normal mb-0">No requests for now...</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </motion.div>
        </AnimatePresence>
      </Container>
    </>
  );
};
