import { createRoot } from "react-dom/client";
import { SeerezCaseHistory } from "../components/SeerezCaseHistory";

export const initSeerezCaseHistory = () => {
  const seerezCaseHistoryContainer = document.getElementById("seerez-case-history");

  if (seerezCaseHistoryContainer) {
    const root = createRoot(seerezCaseHistoryContainer);
    const dataset = seerezCaseHistoryContainer.dataset;

    const seerezCaseHistory = JSON.parse(dataset.caseHistory);

    root.render(<SeerezCaseHistory history={seerezCaseHistory} />);
  }
};
