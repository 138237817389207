import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useDestroyPermission } from "../../utils";

export const PolicyCoverageActionCell = ({ row }) => {
  const [canDestroy] = useDestroyPermission("PolicyCoverage", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();

  return (
    canDestroy && (
      <AdministrateResourceDeleteButton
        label="Remove"
        deleteUrl={`/admin/policy_coverages/${row.original.id}`}
        onSuccess={() => {
          refresh();
          setMessage(`Deleted Policy Coverage ${row.original.id}`);
        }}
        onError={(msg) => setError(msg)}
      />
    )
  );
};
