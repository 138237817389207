import React from "react";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import classnames from "classnames";

export const Checkbox = ({ children, input, label, meta, id, name, ...rest }) => (
  <FormGroup check>
    <Input {...input} {...rest} invalid={Boolean(meta.touched && meta.error)} type="checkbox" id={id} />

    <Label check className={classnames({ "text-danger": Boolean(meta.touched && meta.error) })} htmlFor={id}>
      {label}
    </Label>

    {meta.touched && meta.error && <FormText color="danger">{meta.error}</FormText>}
    {children}
  </FormGroup>
);
