import React from "react";
import { useTableContext } from "../../../components/AdministrateTable";
import { AdministrateResourceDiscardButton } from "../../../components/AdministrateResourceDiscardButton";
import { useUpdatePermission, useDestroyPermission } from "../../../utils";
import { EditInvalidMemberIdModal } from "./EditInvalidMemberIdModal";
import { History } from "../../../tools/sandbox/release/History";

export const InvalidMemberIdActionCell = ({ row }) => {
  const [canUpdate] = useUpdatePermission("InvalidMemberId", row.original.id);
  const [canDestroy] = useDestroyPermission("InvalidMemberId", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();

  const handleEditSuccess = () => {
    setMessage(`Updated Invalid Member Id`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditInvalidMemberIdModal onEdit={handleEditSuccess} invalidMemberId={row.original} className="me-2" />}
      {canDestroy && (
        <AdministrateResourceDiscardButton
          label="Delete"
          discardUrl={`/admin/invalid_member_ids/${row.original.id}/discard`}
          onSuccess={() => {
            refresh();
            setMessage(`Discarded Invalid Member Id "${row.original.invalidMemberId}"`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
      <History entityName="InvalidMemberId" subjectId={row.original.id} />
    </>
  );
};
