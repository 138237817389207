import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../components/AdministrateTable";
import { History } from "../../tools/sandbox/release/History";

export const GroupActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();

  return (
    <div className="d-flex flex-row gap-2">
      <History entityName={"Group"} subjectId={row.original.id} />

      <AdministrateResourceDeleteButton
        label="Remove"
        deleteUrl={`/admin/groups/${row.original.id}`}
        onSuccess={() => {
          refresh();
          setMessage(`Group "${row.original.teamName}" Removed`);
        }}
        onError={(msg) => setError(msg)}
      />
    </div>
  );
};
