import { Toolbar } from "../../components/Toolbar";
import { AdministrateEnumFilter } from "../components/AdministrateEnumFilter";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { makeColumns, useAdministrateTable, usePermissionCheck } from "../utils";
import { AddQuickActionModal } from "./components/AddQuickActionModal";
import { ManagementActionCell } from "./components/ManagementActionCell";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    disableSortBy: true,
  },
  {
    id: "quickAction",
    accessor: "quickAction",
    Header: "Quick Action",
    disableSortBy: true,
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/enums/clinic_ops_quick_actions.json" valueKey="id" labelKey="label" />
    ),
  },
  {
    id: "accountNames",
    accessor: "accountNames",
    Header: "Members",
    disableSortBy: true,
    disableFilters: true,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ManagementActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const QuickActionManagementPage = () => {
  const [canAdminQuickAction] = usePermissionCheck("admin_manage_quick_action");
  const columns = useColumns().filter((col) => canAdminQuickAction || col.id !== "actions");
  const { tableRef, setTableFilters } = useAdministrateTable();

  const onAddManagement = (message) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message);
    refresh();
  };

  return (
    <SystemConfigLayout title="Quick Action Management">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">{canAdminQuickAction && <AddQuickActionModal color="primary" onAdd={onAddManagement} />}</Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/clinic_ops_managements.json"}
        resourceName={"clinic_ops_managements"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
