import React from "react";
import { useTableContext } from "../../../components/AdministrateTable";
import { EditTestModal } from "./EditTestModal";
import { AdministrateResourceDiscardButton } from "../../../components/AdministrateResourceDiscardButton";
import { useUpdatePermission, useDestroyPermission } from "../../../utils";

export const TestCategoryActionCell = ({ row }) => {
  const [canUpdate] = useUpdatePermission("TestCategory", row.original.id);
  const [canDestroy] = useDestroyPermission("TestCategory", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();

  const handleTestEditSuccess = () => {
    setMessage(`Updated "${row.original.name}"`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditTestModal onEdit={handleTestEditSuccess} testCategory={row.original} className="me-2" />}
      {canDestroy && (
        <AdministrateResourceDiscardButton
          label="Discard"
          discardUrl={`/admin/test_categories/${row.original.id}/discard`}
          onSuccess={() => {
            refresh();
            setMessage(`Discarded Icd10 Code "${row.original.name}"`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
    </>
  );
};
