import { useState } from "react";
import { Copy } from "lucide-react";
import { Tooltip } from "reactstrap";
import Icon from "../Icon";
import cx from "classnames";

export const ItemEntry = ({ idx, content, copyable, label, labelClassNames, tooltipDelay, icon }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipToggle = () => {
    // show tooltip
    setTooltipOpen(true);

    // hide it after tooltipDelay in ms
    setTimeout(() => setTooltipOpen(false), tooltipDelay);
  };

  const copyIconId = `copy_icon_${idx}`;
  const copyIconHref = `#${copyIconId}`;

  return (
    <>
      <div className={cx("d-flex flex-column w-50 mb-3", { "ps-2": idx % 2 })}>
        <span className={labelClassNames || "text-muted"}>{label}</span>
        <div className="d-flex flex-row">
          <span className="mt-1 text">{content}</span>
          {copyable && (
            <a className="copy text-muted btn-copy d-print-none align-self-center" href={copyIconHref} data-clipboard-text={content}>
              <Copy id={copyIconId} className="copy-icon" size={12} />
              <Tooltip trigger="click" target={copyIconId} isOpen={tooltipOpen} toggle={tooltipToggle}>
                Copied
              </Tooltip>
            </a>
          )}
        </div>
      </div>
    </>
  );
};

export const InfoGrid = ({ items, loaded, tooltipDelay = 1500 }) => {
  if (!loaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="d-flex flex-wrap">
      {items &&
        items.map((item, idx) => {
          return (
            <ItemEntry
              key={item.label}
              idx={idx}
              content={item.content}
              copyable={item.copyable}
              label={item.label}
              labelClassNames={item.labelClassNames}
              tooltipDelay={tooltipDelay}
              icon={item.icon}
            />
          );
        })}
    </div>
  );
};
