import React from "react";
import { BoolCell } from "../../components/grid/cells/BoolCell";
import { OpsCaseLinkCell } from "../../components/grid/cells/CaseLinkCell";
import { TooltipCell } from "../../components/grid/cells/TooltipCell";
import {
  CASE_LAB_NO_FILTER,
  CASE_PATIENT_NAME,
  CREATED_ON,
  CURRENT_STATUS_NO_FILTER,
  CURRENT_SUBSTATUS_NO_FILTER,
  TASK_DUE,
  VERIFIED_PAYOR,
  PAYOR_PATHS,
} from "../../components/grid/columns";
import { PersistantReactTable } from "../../components/grid/PersistantReactTable";
import { makeColumns } from "../../system_config/utils";

const useColumns = makeColumns(() => [
  {
    Header: "Case ID",
    id: "public_id",
    accessor: "public_id",
    disableFilters: true,
    Cell: OpsCaseLinkCell,
  },
  {
    Header: "Verified Clinic",
    id: "clinic_id",
    accessor: "pa_request.clinicInfo.clinicName",
    disableFilters: true,
    Cell: TooltipCell,
  },
  CASE_PATIENT_NAME({ disableSortBy: false }),
  { ...CASE_LAB_NO_FILTER, disableSortBy: false },
  VERIFIED_PAYOR({ disableSortBy: false }),
  PAYOR_PATHS,
  { ...CURRENT_STATUS_NO_FILTER, disableSortBy: false },
  { ...CURRENT_SUBSTATUS_NO_FILTER, disableSortBy: false },
  CREATED_ON,
  TASK_DUE,
  {
    Header: "USA Only",
    id: "requires_us_assignee",
    accessor: "requires_us_assignee",
    Cell: BoolCell,
  },
  {
    Header: "Verified Test",
    id: "verified_test",
    accessor: (r) => r.verified_test_names?.join(", "),
    Cell: TooltipCell,
  },
]);

export const AssignableCasesTable = React.forwardRef(
  ({ data = [], onSelectionChange, onPageSizeChange, onSortedChange, showPagination, disableSortBy, defaultPageSize, ...props }, ref) => {
    const columns = useColumns();

    return (
      <PersistantReactTable
        ref={ref}
        checkboxGrid={true}
        columns={columns}
        data={data || []}
        keyField="public_id"
        selectType="checkbox"
        disableFilters={true}
        disableSortBy={disableSortBy}
        showPagination={showPagination}
        defaultPageSize={defaultPageSize}
        onSelectionChange={onSelectionChange}
        onPageSizeChange={onPageSizeChange}
        onSortedChange={onSortedChange}
        defaultSorted={[]}
        {...props}
      />
    );
  }
);
