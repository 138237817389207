import _ from "lodash";
import React, { useCallback, useState } from "react";
import { api } from "../../util/api";
import { DetailedErrorAlert } from "../DetailedErrorAlert";
import { reactSelectColors } from "./ReactSelectWidget";
import { RemoteSelector } from "./RemoteSelector";

export const TestSelector = ({ onChange, value, disabled, readOnly, valid, labIds = [], ...rest }) => {
  const [error, setError] = useState(null);

  const loadOptions = useCallback(
    async (q) => {
      setError(null);

      try {
        const mappingsUrl = `/tests/test_mappings`;
        const response = await api.get(mappingsUrl, { params: { q, lab_ids: labIds } });

        return _.map(response.data.mappings, (m) => ({
          value: m.id,
          label: m.name,
        }));
      } catch (err) {
        setError(err);
        return [];
      }
    },
    [labIds]
  );

  return (
    <>
      <DetailedErrorAlert error={error} message="There was an error while fetching Tests" />

      <RemoteSelector
        {...rest}
        key={labIds.toString()}
        styles={reactSelectColors(!valid)}
        onChange={onChange}
        loadOptions={loadOptions}
        value={value}
        isDisabled={disabled || readOnly}
      />
    </>
  );
};
