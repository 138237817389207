import {
  CASE_PATIENT_NAME,
  PUBLIC_ID,
  VERIFIED_PAYOR,
  PAYOR_PATHS,
  CURRENT_STATUS_NO_FILTER,
  SUBMISSION_METHOD,
} from "../components/grid/columns";
import { CREATED_ON, PROCESS, TASK_DUE } from "../workflow_grid/columns";
import { formatLabName } from "../util/formatters";
import { AdministrateEnumFilter } from "../system_config/components/AdministrateEnumFilter";

const CASE_LAB = {
  Header: "Lab",
  id: "lab.id",
  accessor: (r) => formatLabName(r),
  Filter: (props) => (
    <AdministrateEnumFilter {...props} resourceUrl="/admin/labs.json" valueKey="lab_name" labelKey="lab_name" searchParam="lab_name" />
  ),
  disableSortBy: true,
  minWidth: 200,
};

export const initColumns = (tab) => {
  switch (tab) {
    case "new":
      return [PROCESS, PUBLIC_ID, CASE_PATIENT_NAME({}), PAYOR_PATHS, VERIFIED_PAYOR({ disableSortBy: false }), CASE_LAB, CREATED_ON];
    case "cares":
      return [PROCESS, PUBLIC_ID, CASE_LAB, PAYOR_PATHS, VERIFIED_PAYOR({ disableSortBy: false }), CREATED_ON, CURRENT_STATUS_NO_FILTER];
    case "backlog":
      return [
        PROCESS,
        PUBLIC_ID,
        CASE_PATIENT_NAME({}),
        PAYOR_PATHS,
        VERIFIED_PAYOR({ disableSortBy: false }),
        TASK_DUE,
        SUBMISSION_METHOD,
      ];
    case "due":
      return [
        PROCESS,
        PUBLIC_ID,
        CASE_PATIENT_NAME({}),
        PAYOR_PATHS,
        VERIFIED_PAYOR({ disableSortBy: false }),
        TASK_DUE,
        SUBMISSION_METHOD,
      ];

    default:
      return [PROCESS, PUBLIC_ID, CASE_PATIENT_NAME({}), PAYOR_PATHS, VERIFIED_PAYOR({ disableSortBy: false }), TASK_DUE];
  }
};
