import React from "react";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission } from "../../utils";
import { EditPortalMessageModal } from "./EditPortalMessageModal";
import { History } from "../../tools/sandbox/release/History";

export const PortalMessageActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("PortalMessage", row.original.id);

  const handleUpdate = () => {
    setMessage(`Updated ${row.original.message}`);
    refresh();
  };

  const handleDelete = () => {
    setMessage(`Deleted ${row.original.message}`);
    refresh();
  };

  return (
    <>
      <EditPortalMessageModal color="link" onUpdate={handleUpdate} portalMessage={row.original} />
      {canDestroy && (
        <AdministrateResourceDeleteButton
          label="Delete"
          deleteUrl={`/admin/portal_messages/${row.original.id}`}
          onSuccess={handleDelete}
          onError={(msg) => setError(msg)}
        />
      )}
      <History className="ms-4" entityName="PortalMessage" subjectId={row.original.id} />
    </>
  );
};
