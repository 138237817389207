import $ from "jquery";

const SECONDS = 1000;
const CHECK_RATE = 5 * SECONDS;

export const initSeeqerRefresh = () => {
  $(() => {
    const $biReceipt = $("[data-bi-refresh]");

    if ($biReceipt.length > 0) {
      const url = $biReceipt.data("biStatusURL");
      const status = $biReceipt.data("biStatus");
      checkForCompletion(url, status);
    }
  });
};

const checkForCompletion = (url, initialStatus) => {
  let everHadPendingComment = false;
  const checkStatusInterval = setInterval(() => {
    everHadPendingComment = everHadPendingComment || hasPendingComment();

    $.ajax(url, {
      method: "GET",
      success({ status }) {
        if (status === initialStatus) {
          // Still Processing...
          return;
        }

        clearInterval(checkStatusInterval);

        if (everHadPendingComment) {
          $("#bi_results_processing_msg").addClass("d-none");
          $("#bi_results_finished_processing_msg").removeClass("d-none");
        } else {
          location.reload();
        }
      },
      error() {
        console.error("This Case failed to check the seeqer status.");
        clearInterval(checkStatusInterval);
      },
    });
  }, CHECK_RATE);
};

const hasPendingComment = () => Boolean($("#create_comment_form_content").val());
