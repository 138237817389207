import _ from "lodash";
import React from "react";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";
import { BoolIcon } from "../../components/BoolIcon";
import { PayorRow } from "./PayorRow";

export const CptCodeRow = ({ code, group, payors }) => {
  return (
    <>
      <tr rowSpan={payors.length + 1} className="bg-light">
        <td colSpan={2}>
          <em>{group.name}</em>
        </td>

        <td id={`code-cell-${code.code}`}>
          <span className="token">{code.code}</span>
          {code.description && (
            <UncontrolledPopover trigger="hover" placement="left" target={`code-cell-${code.code}`}>
              <PopoverHeader>{code.code} Description</PopoverHeader>
              <PopoverBody>{code.description}</PopoverBody>
            </UncontrolledPopover>
          )}
        </td>

        <td>
          {/* TODO: This will eventually change to be driven by the coverage group */}
          <BoolIcon value /> Yes
        </td>

        <td colSpan="100">{/* blank space */}</td>
      </tr>

      {_.map(payors, (payor, i) => (
        <PayorRow key={`payor-row-${i}`} group={group} payor={payor} code={code} />
      ))}
    </>
  );
};
