import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { LoadingButton } from "../../../components/LoadingButton";
import { snakifyKeys } from "../../../util/helpers";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { TIER_OPTIONS } from "../tabs/PortalPayorsTab";

export const EditPortalPayorModal = ({ payorPortalMapping, payor, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const toggleModal = () => setOpen(!open);

  const handleUpdate = async (values) => {
    setError(null);
    const { tier } = values;
    try {
      const res = await api.patch(`/admin/payor_portal_mappings/${payorPortalMapping.id}`, { tier: tier });

      if (onUpdate) {
        onUpdate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Edit Payor</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />
          <Form onSubmit={handleUpdate} initialValues={{ payorId: payorPortalMapping.payor?.id, tier: payorPortalMapping.tier }}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field component={TextInput} type="hidden" name="payorId" />
                  <Field component={TextInput} type="hidden" name="benefitManagementPortalId" />
                  <Field component={SelectInput} label="Tier" options={TIER_OPTIONS} name="tier" />
                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
