import React from "react";
import { TestInfo } from "./TestInfo";
import { PracticeInfo } from "./PracticeInfo";
import { InsuranceInfo } from "./InsuranceInfo";
import { SeerezOtherInfo } from "./SeerezOtherInfo";

export const SeerezCaseDetails = ({ test, practice, insurance, otherInfo }) => {
  return (
    <>
      <h4 className="text-secondary h3 fw-medium mt-4">Prior Auth Details</h4>

      <div className="vstack gap-3">
        <TestInfo testInfo={test} />
        <PracticeInfo practiceInfo={practice} />
        <InsuranceInfo insuranceInfo={insurance} />
        <SeerezOtherInfo seerezOtherInfo={otherInfo} />
      </div>
    </>
  );
};
