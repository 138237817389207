import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { EditCptCodeListPriceModal } from "./EditCptCodeListPriceModal";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const LabCptCodeListPriceActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("LabCptCodeListPrice", row.original.id);
  const [canUpdate] = useUpdatePermission("LabCptCodeListPrice", row.original.id);

  const handleChange = (msg) => {
    setMessage(`Updated List Price for CPT Code  ${row.original.cptCode.code}`);
    refresh();
  };

  return (
    <>
      {canUpdate && <EditCptCodeListPriceModal color="link" onEdit={handleChange} cptCodeListPrice={row.original} />}
      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/lab_cpt_code_list_prices/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted List Price for CPT Code ${row.original.cptCode.code}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}
      <History entityName="LabCptCodeListPrice" subjectId={row.original.id} />
    </>
  );
};
