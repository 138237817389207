import React from "react";
import { ActionAlert } from "../../../../components/ActionAlert";
import { LoadingButton } from "../../../../components/LoadingButton";
import { WizardContext } from "../../WizardContext";

export const PayorSentDeterminationBranch = () => (
  <WizardContext.Consumer>
    {({ loading, continueWorkflow }) => (
      <ActionAlert
        className="mt-3"
        color="success"
        actions={
          <LoadingButton color="success" loading={loading} onClick={continueWorkflow}>
            Continue
          </LoadingButton>
        }
      >
        Confirm fax number and request a resend. Continue with workflow to finalize Case.
      </ActionAlert>
    )}
  </WizardContext.Consumer>
);
