import _ from "lodash";
import React, { useState, useMemo } from "react";
import { Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { InvalidMemberIdForm } from "./InvalidMemberIdForm";

export const EditInvalidMemberIdModal = ({ invalidMemberId, onEdit, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (values) => {
    const newValues = _.cloneDeep(values);

    setError(null);
    try {
      const res = await api.patch(`/admin/invalid_member_ids/${invalidMemberId.id}`, {
        invalid_member_id: snakifyKeys(newValues),
      });

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} color="link" {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Invalid Member Id</ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          <Form onSubmit={handleEdit} initialValues={{ ...invalidMemberId }}>
            {(props) => <InvalidMemberIdForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
