import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { Routes } from "../../Routes";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable, useTableContext } from "../../components/AdministrateTable";
import { makeColumns, useAdministrateTable } from "../../utils";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { AddGroupMemberModal } from "../components/AddGroupMemberModal";

const useColumns = makeColumns(({ groupId, group }) => [
  {
    id: "name",
    accessor: "fullNameOrEmail",
    Header: "Name",
    Cell: ({ row, value }) => <Link to={Routes.user.url(row.original.id)}>{value}</Link>,
  },
  {
    id: "global_role",
    accessor: "globalRole",
    Header: "Role",
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <GroupMemberAction row={row} groupId={groupId} group={group} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

const GroupMemberAction = ({ row, groupId, group }) => {
  const { refresh, setMessage, setError } = useTableContext();

  return (
    <AdministrateResourceDeleteButton
      label="Remove"
      deleteUrl={`/admin/groups/${groupId}/members/${row.original.id}`}
      onSuccess={() => {
        refresh();
        setMessage(`${row.original.fullNameOrEmail} Removed from Group "${group.team_name}"`);
      }}
      onError={(msg) => setError(msg)}
    />
  );
};

export const GroupsMembersTab = ({ group, groupId }) => {
  const columns = useColumns({ groupId, group });

  const { tableRef, setTableFilters } = useAdministrateTable();

  const onAdd = useCallback(
    ({ failures, successes }) => {
      tableRef.current?.refresh?.();

      let msg = `${successes.length} Added.`;
      if (failures.length > 0) {
        msg += ` ${failures.length} Already present.`;
      }

      tableRef.current?.setMessage?.(msg);
    },
    [tableRef]
  );

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AddGroupMemberModal group={group} onAdd={onAdd} />
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/groups/${groupId}/members.json`}
        resourceName={"group_members"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
