export const Routes = {
  home: {
    path: "/",
    url: () => "/",
    label: "Home",
    exactMatch: true,
  },
  automation: {
    path: "/automation",
    url: () => "/automation",
    label: "Automation",
  },
  clinic: {
    path: "/clinics/:clinicId",
    url: (id) => `/clinics/${id}`,
    label: "Clinic",
  },
  clinics: {
    path: "/clinics",
    url: () => "/clinics",
    label: "Clinics",
  },
  test: {
    path: "/tests/:testId",
    url: (id) => `/tests/${id}`,
    label: "Test",
  },
  labs: {
    path: "/labs",
    url: () => "/labs",
    label: "Labs",
  },
  lab: {
    path: "/labs/:labId",
    url: (id) => `/labs/${id}`,
    label: "Lab",
  },
  lab_document: {
    path: "/lab_documents/:id/download",
    url: (id) => `/lab_documents/${id}/download`,
    label: "Lab Document",
    resource: "LabDocument",
  },
  users: {
    path: "/users",
    url: () => "/users",
    label: "User Management",
    resource: "Account",
  },
  quickActionManagement: {
    path: "/quickActionManagement",
    url: () => "/quickActionManagement",
    label: "Quick Action Management",
    action: ["admin_manage_quick_action", "admin_read_quick_action"],
  },
  procedureCodes: {
    path: "/procedure_codes",
    url: () => "/procedure_codes",
    label: "Procedure Codes",
  },
  user: {
    path: "/users/:accountId",
    url: (id) => `/users/${id}`,
    label: "User",
    resource: "User",
  },
  groups: {
    path: "/groups",
    url: () => `/groups`,
    label: "User Groups",
    resource: "Group",
  },
  group: {
    path: "/groups/:groupId",
    url: (id) => `/groups/${id}`,
    label: "Group",
    resource: "Group",
  },
  authTokens: {
    path: "/auth_tokens",
    url: () => `/auth_tokens`,
    label: "Auth Tokens",
    resource: "AuthToken",
  },
  authToken: {
    path: "/auth_tokens/:authTokenId",
    url: (id) => `/auth_tokens/${id}`,
    label: "Auth Token",
    resource: "AuthToken",
  },
  followupTaskConfigs: {
    path: "/automation/followup_task_configs",
    url: () => "/automation/followup_task_configs",
    label: "Follow-up Task Configurations",
  },
  payorMemberPrefixes: {
    path: "/automation/payor_member_prefixes",
    url: () => "/automation/payor_member_prefixes",
    label: "Payor Member Prefixes",
  },
  insurancePayorMappings: {
    path: "/automation/insurance_payor_mappings",
    url: () => "/automation/insurance_payor_mappings",
    label: "Insurance Payor Mappings",
  },
  statusMappings: {
    path: "/automation/status_mappings",
    url: () => "/automation/status_mappings",
    label: "Status Mappings",
  },
  seeqerReflexMappings: {
    path: "/automation/seeqer_reflex_mappings",
    url: () => "/automation/seeqer_reflex_mappings",
    label: "seeQer Reflex Status Mappings",
  },
  policies: {
    path: "/policies",
    url: () => "/policies",
    label: "Policies",
  },
  policy: {
    path: `/policies/:policyId`,
    url: (id) => `/policies/${id}`,
    label: "Policy",
  },
  payors: {
    path: "/payors",
    url: () => "/payors",
    label: "Payors",
  },
  payor: {
    path: "/payors/:payorId",
    url: (id) => `/payors/${id}`,
    label: "Payor",
  },
  payorGroups: {
    path: "/payor_groups",
    url: () => "/payor_groups",
    label: "Payor Groups",
  },
  payorGroup: {
    path: "/payor_groups/:payorGroupId",
    url: (id) => `/payor_groups/${id}`,
    label: "Payor Group",
  },
  payorPaths: {
    path: "/payor_paths",
    url: () => "/payor_paths",
    label: "Payor Paths",
  },
  portal: {
    path: "/portals/:portalId",
    url: (id) => `/portals/${id}`,
    label: "Portal",
    resource: "BenefitManagementPortal",
  },
  portals: {
    path: "/portals",
    url: () => "/portals",
    label: "Portal Management",
    resource: "BenefitManagementPortal",
  },
  portalMessages: {
    path: "/portalMessages",
    url: () => "/portalMessages",
    label: "Portal Messages",
  },
  restatusPACases: {
    path: "/tools/restatus_pa_cases",
    url: () => "/tools/restatus_pa_cases",
    label: "Restatus PA Cases",
    resource: "BulkPaRestatusHistory",
  },
  reprocessBICases: {
    path: "/tools/reprocess_bi_cases",
    url: () => "/tools/reprocess_bi_cases",
    label: "Reprocess BI Cases",
    action: "admin_reprocess_bi_cases",
  },
  ingestion: {
    path: "/tools/eva_ingestion",
    url: () => "/tools/eva_ingestion",
    label: "Ingestion",
    action: "admin_ingest_eva_file",
  },
  ribbonNpiUpload: {
    path: "/tools/ribbon_npi_upload",
    url: () => "/tools/ribbon_npi_upload",
    label: "Ribbon NPI Upload",
    action: "admin_ribbon_npi_upload",
  },
  clinicalGlossary: {
    path: "/tools/clinical_glossary",
    url: () => "/tools/clinical_glossary",
    label: "Clinical Glossary",
    resource: "ClinicalGlossary",
  },
  pullBenefitsLogs: {
    path: "/tools/pull_benefits_logs",
    url: () => "/tools/pull_benefits_logs",
    label: "Pull Benefits Logs",
    action: "admin_pull_benefits_logs",
  },
  benefitsDetail: {
    path: "/tools/pull_benefits_log/:caseType/:benefitId",
    url: (caseType, id) => `/tools/pull_benefits_log/${caseType}/${id}`,
    label: "Benefit Detail",
  },
  testCategory: {
    path: "/tools/test_category",
    url: () => "/tools/test_category",
    label: "Test Category Management",
    resource: "TestCategory",
  },
  invalidMemberIds: {
    path: "/tools/invalid_member_ids",
    url: () => "/tools/invalid_member_ids",
    label: "Invalid Member IDs Management",
    resource: "InvalidMemberId",
  },
  payorTestMappingsImport: {
    path: "/tools/secret/import_payor_test_mappings",
    url: () => "/tools/secret/import_payor_test_mappings",
    label: "Import Payor Test Mappings",
  },
  sandbox: {
    path: "/tools/sandbox",
    url: () => "/tools/sandbox",
    label: "Sandbox",
  },
  // SuperDial Messages
  superDialMessages: {
    path: "/tools/superdial_messages",
    url: () => "/tools/superdial_messages",
    label: "Super Dial Messages",
  },
  // SuperDial Scripts
  superDialScripts: {
    path: "/tools/superdial_scripts",
    url: () => "/tools/superdial_scripts",
    label: "Super Dial Script IDs",
  },
  // UIPath Test Cases
  uiPathTestCases: {
    path: "/tools/uipath_test_cases",
    url: () => "/tools/uipath_test_cases",
    label: "UIPath Test Cases",
  },
  // Preprocessing Categories
  preprocessingCategories: {
    path: "/tools/preprocessing_categories",
    url: () => "/tools/preprocessing_categories",
    label: "Preprocessing Categories",
  },
  billingReports: {
    path: "/billing_reports",
    url: () => "/billing_reports",
    label: "Billing Reports",
    action: "admin_billing",
  },
  billingAnalysis: {
    path: "/billing_analysis",
    url: () => "/billing_analysis",
    label: "Billing Analysis",
    action: "admin_billing",
  },
  icd10Codes: {
    path: "/icd10_codes",
    url: () => "/icd10_codes",
    label: "ICD10 Codes",
  },
  testRepository: {
    path: "/test_repository",
    url: () => "/test_repository",
    label: "Product/Procedure/Test Repository",
  },

  // Submit Right routes
  visualManagement: {
    path: "/submit_right/visual_management",
    url: () => "/submit_right/visual_management",
    label: "Completion Screen Visual Management",
    resource: "SubmitRightVisualManagement",
  },
  caseTips: {
    path: "/submit_right/case_tips",
    url: () => "/submit_right/case_tips",
    label: "Case Tips",
    // resource: "CaseTips",
  },
  providerSpecificSubmissions: {
    path: "/submit_right/provider_specific_submissions",
    url: () => "/submit_right/provider_specific_submissions",
    label: "Provider Specific Submissions",
    resource: "ProviderPayorNumber",
  },
  benefitPullType: {
    path: "/benefit_pull_type",
    url: () => "/benefit_pull_type",
    label: "Benefit Pull Type",
  },
  phoneResponses: {
    path: "/submit_right/phone_responses",
    url: () => "/submit_right/phone_responses",
    label: "Phone Responses",
    // resource: "PhoneResponse"
  },
  paperResponses: {
    path: "/submit_right/paper_responses",
    url: () => "/submit_right/paper_responses",
    label: "Paper Responses",
    // resource: "Paper Responses"
  },
  missingInfoCategories: {
    path: "/submit_right/missing_info_categories",
    url: () => "/submit_right/missing_info_categories",
    label: "Missing Info Categories",
  },

  // PA Requirements
  paRequirementsIndex: {
    path: "/automation/pa_requirements",
    url: () => "/automation/pa_requirements",
    label: "PA Requirements",
  },
  paRequirementsEdit: {
    path: "/automation/pa_requirements/review",
    url: () => "/automation/pa_requirements/review",
    label: "PA Requirements Review",
  },

  // Payor Plan Type Network Status
  payorPlanTypeNetworkStatus: {
    path: "/payors/:payorId/plan_type_network_status",
    url: (id) => `/payors/${id}/plan_type_network_status`,
    label: "Payor Plan Type Network Status",
  },

  // UIPath Bot Automation
  uiPathAutomation: {
    path: "/automation/uipath_bot",
    url: () => "/automation/uipath_bot",
    label: "UiPath Bot Automation",
  },
};
