import { useCallback } from "react";
import { api } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { ImportModal } from "../../../components/ImportModal";

export const ImportPaperResponseModal = ({ onImport, ...rest }) => {
  const handleImport = useCallback(
    async (values) => {
      const params = {
        file: {
          bytes: values.file,
        },
      };

      const res = await api.post("/admin/paper_responses/import", snakifyKeys(params));

      if (onImport) {
        onImport(res.data?.meta?.message, res.data?.meta?.error_details);
      }
    },
    [onImport]
  );

  return (
    <ImportModal
      buttonProps={rest}
      onImport={handleImport}
      title="Add Paper Responses from CSV..."
      exampleDownloadPath="/admin/paper_responses/example"
    />
  );
};
