import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { GcRequirementSelect } from "./GcRequirementSelect";

export const GcRequirementActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const { id: cptCodeSettingId, gcRequirement } = row?.original ?? {};
  const { id: payorId, name: payorName } = row.original?.payor ?? {};
  const { code: cptCode } = row.original?.cptCode ?? {};

  const handleChange = (msg) => {
    setMessage(msg);
    refresh();
  };

  const handleError = (msg) => {
    setError(msg);
    refresh();
  };

  return (
    <GcRequirementSelect
      payorId={payorId}
      payorName={payorName}
      gcRequirement={gcRequirement}
      cptCodeSettingId={cptCodeSettingId}
      cptCode={cptCode}
      onChange={handleChange}
      onError={handleError}
    />
  );
};
