import _ from "lodash";
import React from "react";
import { reactSelectColors } from "./ReactSelectWidget";
import { RemotePaginateSelector } from "./RemotePaginateSelector";

export const ICD10_URL = "/codes/icd.json";

export const Icd10Selector = ({ hl7 = false, onChange, value, disabled, readOnly, valid, ...rest }) => {
  function mappingFn(resources) {
    return _.map(resources, (x) => ({
      value: x.code,
      label: hl7 ? x.formatted_code : `${x.formatted_code} - ${x.description}`,
    }));
  }

  return (
    <RemotePaginateSelector
      {...rest}
      styles={reactSelectColors(!valid)}
      resourceUrl={ICD10_URL}
      valueKey="code"
      labelKey="code"
      dataKey="resources"
      value={value}
      onChange={onChange}
      isDisabled={disabled || readOnly}
      resourceMappingFn={mappingFn}
      isMulti
    />
  );
};
