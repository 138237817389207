import React, { useMemo } from "react";
import { Field } from "react-final-form";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { Switch } from "../../../../components/inputs/final_form/Switch";
import { LoadingButton } from "../../../../components/LoadingButton";
import { required } from "../../../../util/validators";
import { AdministrateSelectInput } from "../../../components/inputs/AdministrateSelectInput";

export const UiPathBotAutomationForm = ({ handleSubmit, submitting, automation }) => {
  const initialPayorOptions = useMemo(() => {
    return automation?.payors ? automation?.payors.map((payor) => ({ label: payor.name, value: payor.id })) : [];
  }, [automation?.payors]);

  return (
    <>
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/payors.json"
        searchParam="name"
        label="Payors"
        kept={true}
        isMulti
        valueKey="id"
        name="payorIds"
        validate={required}
        initialOptions={initialPayorOptions}
        required
      />

      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/benefit_management_portals.json"
        label="Portal"
        name="benefitManagementPortalId"
        searchParam="name"
        validate={required}
        required
      />

      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/enums/ui_path_bot_types.json"
        label="Bot Type"
        name="uiPathBotType"
        valueKey="id"
        labelKey="label"
        searchParam="search"
        validate={required}
        required
      />

      <Field component={TextInput} validate={required} label="Folder Name" name="uiPathFolderName" required />
      <Field component={TextInput} validate={required} label="Release Name" name="uiPathReleaseName" required />
      <Field type="checkbox" component={Switch} validate={required} label="Enabled?" name="enabled" required />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
