import React from "react";
import { connect } from "react-redux";
import { Button, Navbar } from "reactstrap";
import { CSRF_TOKEN } from "../../util/api";
import { previewSchema, saveDocument } from "../store";

class HeaderBarComponent extends React.Component {
  render() {
    const { onSave, onPreview, saving = false, preview = {}, editDocumentPath, previewSchemaPath, exportEnabled, schemaPath } = this.props;

    return (
      <Navbar color="light" light>
        <a href={editDocumentPath}>&laquo; Back to Document</a>

        <div className="ms-auto">
          <form method="post" action={previewSchemaPath} target="_pdf_preview">
            <input type="hidden" name="authenticity_token" value={CSRF_TOKEN}></input>
            <input type="hidden" name="schema" value={JSON.stringify(preview)}></input>

            <Button outline color="primary" className="me-2" type="submit" onClick={onPreview} title="Click here to Preview your document">
              Preview
            </Button>

            <Button disabled={saving} onClick={onSave} title="Click here to save your document">
              {saving ? "Saving... " : "Save"}
            </Button>

            {exportEnabled && (
              <Button className="ms-2" title="Click here to Export your PDF Schema JSON file" tag="a" href={schemaPath} target="_blank">
                Export
              </Button>
            )}
          </form>
        </div>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    saving: state.saving,
    preview: state.preview,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSave: () => dispatch(saveDocument(ownProps.updateSchemaPath)),
  onPreview: () => dispatch(previewSchema()),
});

export const HeaderBar = connect(mapStateToProps, mapDispatchToProps)(HeaderBarComponent);
