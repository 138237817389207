import React from "react";

export const ErrorRenderer = ({ errors }) => {
  return (
    <div id="error-card" className="d-flex flex-column justify-content-center rounded p-3 pb-2 mb-2 bg-white">
      {errors.map((error, index) => (
        <div className="row justify-content-between" key={index}>
          <div className="col-md-12">
            <h5 className="text-muted fw-bold h5">Estimated Total Patient Responsibility</h5>
            <p className="fw-normal h4 text-wrap text-black">{error.message}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
