import React from "react";
import { extractErrorMessage } from "../../../util/api";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { useDestroyPermission } from "../../utils";

export const LabPortalManagementActionCell = ({ mappingId }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("LabPortalMapping", mappingId);

  return (
    canDestroy && (
      <AdministrateResourceDeleteButton
        label="Remove"
        deleteUrl={`/admin/lab_portal_mappings/${mappingId}`}
        onError={(err) => setError(extractErrorMessage(err))}
        onSuccess={() => {
          setMessage("Successfully removed Lab Portal Mapping");
          refresh();
        }}
      />
    )
  );
};
