import $ from "jquery";
import { api } from "../../util/api";

export const initBillingRateDeleteButton = () => {
  $(() => {
    $("body").on("click", "[data-remove-billing-rate]", function () {
      const $this = $(this);
      const target = $this.data("target");
      const confirmMessage = $this.data("confirm");

      destroy(target, confirmMessage);
    });
  });
};

const destroy = async (target, confirmMessage) => {
  if (confirm(confirmMessage)) {
    await api.post("alt_destroy", {
      id: target,
    });

    window.location.reload();
  }
};
