import React, { useState } from "react";
import { MarkReviewedButton } from "../../../components/buttons/MarkReviewedButton";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { AdministrateResourceDiscardButton } from "../../components/AdministrateResourceDiscardButton";
import { useTableContext } from "../../components/AdministrateTable";
import { useDestroyPermission, useUpdatePermission } from "../../utils";
import { History } from "../../tools/sandbox/release/History";

export const PolicyActionCell = ({ row }) => {
  const [canUpdate] = useUpdatePermission("Policy", row.original.id);
  const [canDestroy] = useDestroyPermission("Policy", row.original.id);
  const { refresh, setMessage, setError } = useTableContext();
  const [loading, setLoading] = useState(false);
  const activationStatus = row.original.active ? "Inactive" : "Active";

  const handleActiveClick = async () => {
    setLoading(true);
    try {
      const res = await api.put(`/admin/policies/${row.original.id}.json`, snakifyKeys({ policy: { active: !row.original.active } }));
      refresh();
      setMessage(`${row.original.name} marked as ${activationStatus}`);
    } catch (err) {
      setError(extractErrorMessage(err));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {canUpdate && row.original.needsPolicyReview && (
        <MarkReviewedButton
          resourceId={row.original.id}
          resourceType="policies"
          reviewType="policy"
          onError={setError}
          onReview={() => {
            refresh();
            setMessage(`${row.original.name} marked as reviewed`);
          }}
        />
      )}

      {canUpdate && (
        <LoadingButton onClick={handleActiveClick} loading={loading} color="link">
          {activationStatus}
        </LoadingButton>
      )}

      {canDestroy && (
        <AdministrateResourceDiscardButton
          discardUrl={`/admin/policies/${row.original.id}/discard`}
          onSuccess={() => {
            refresh();
            setMessage(`Discarded Policy ${row.original.name}`);
          }}
          onError={(msg) => setError(msg)}
        />
      )}

      <History entityName={"Policy"} subjectId={row.original.id} />
    </>
  );
};
