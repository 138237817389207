import { createRoot } from "react-dom/client";
import CasesGrid from "./shared/CasesGrid";

export const initApprovedCases = () => {
  const container = document.getElementById("approved-cases");

  if (container) {
    const root = createRoot(container);
    const dataUrl = "approved_cases.json";

    const { searchableStatuses, isInternalUser } = container.dataset;
    const statusOptions = searchableStatuses ? JSON.parse(searchableStatuses) : null;
    const filterOptions = { statusOptions };

    root.render(
      <CasesGrid dataUrl={dataUrl} title="Approved" pageContext="approved" filterOptions={filterOptions} isInternalUser={isInternalUser} />
    );
  }
};
