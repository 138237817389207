import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Button } from "reactstrap";
import { DetailedAlert } from "../../../components/DetailedAlert";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { DropzoneInput } from "../../../components/inputs/final_form/DropzoneInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";

export const BulkReprocessForm = () => {
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [messageDetails, setMessageDetails] = useState(null);

  const onBulkSubmit = async (values, form) => {
    const params = {
      file: {
        bytes: values.file,
      },
    };

    setError(null);
    setMessage(null);
    setMessageDetails(null);

    try {
      const { data } = await adminApi.post("/admin/system/tools/bulk_reprocess_seeqer_case", snakifyKeys(params));

      setMessage(data.meta.message);
      setMessageDetails(data.meta.error_details);
      form.restart();
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <DetailedErrorAlert error={error} />

      <DetailedAlert
        message={message}
        details={messageDetails}
        messageStyle={messageDetails && messageDetails.length > 0 ? "warning" : "info"}
      />

      <Form onSubmit={onBulkSubmit}>
        {({ handleSubmit, submitting, pristine }) => {
          return (
            <>
              <Field
                required
                validate={required}
                component={DropzoneInput}
                accept=".csv"
                hint="Drop CSV to Reprocess"
                label="Reprocess File"
                name="file"
              />

              <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting || pristine} className="me-2">
                Reprocess File
              </LoadingButton>

              <Button href="/admin/system/tools/example_bulk_reprocess.csv" download target="_blank" rel="noopener noreferrer" color="link">
                Download Example CSV
              </Button>
            </>
          );
        }}
      </Form>
    </>
  );
};
