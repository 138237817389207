import $ from "jquery";

export const initPageSizeSelector = () => {
  const $pageSizeSelector = $(".per-page");
  const urlParams = new URLSearchParams(window.location.search);
  const currentSize = urlParams.get("per");

  $pageSizeSelector.on("change", (e) => {
    const newSize = e.target.value;

    if (newSize !== currentSize) {
      urlParams.set("per", newSize);
      location.search = urlParams.toString();
    }
  });
};
