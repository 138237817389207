import { useState } from "react";
import { api, extractErrorMessage } from "../../../util/api";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { PayorPathForm } from "./PayorPathForm";
import { useUpdatePermission } from "../../utils";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";

export const EditPayorPathModal = ({ letterOptions, payorPath, onEdit, ...rest }) => {
  const [canUpdate] = useUpdatePermission("PayorPath", payorPath.id);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (values) => {
    try {
      const params = {
        path: values.letters.join(""),
      };

      const { data } = await api.patch(`/admin/payor_paths/${payorPath.id}`, params);

      if (data) {
        onEdit(data);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const mode = canUpdate ? "Edit" : "View";

  return (
    <>
      <Button onClick={toggleModal} color="link" {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>{mode} Payor Path</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <PayorPathForm mode={mode} onSubmit={handleEdit} letterOptions={letterOptions} subject={payorPath} />
        </ModalBody>
      </Modal>
    </>
  );
};
