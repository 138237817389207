import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api, extractErrorMessage } from "../../../util/api";

export const DiscardLabModal = ({ onDelete, labSlug, labName, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleDelete = async () => {
    setError(null);
    try {
      await api.delete(`/admin/labs/${labSlug}`);

      if (onDelete) {
        onDelete();
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Delete
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Delete Lab {labName}</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          <p>This operation will also remove configurations associated with {labName}</p>

          <Form onSubmit={handleDelete}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <LoadingButton loading={submitting} color="danger" onClick={handleSubmit} disabled={submitting}>
                    Delete
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
