import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { api, redirectTo, useAPI } from "../util/api";
import { required, composeValidators, valueInOptions } from "../util/validators";
import { trackEvent } from "../util/track";
import { ChoiceGroup } from "./inputs/final_form/ChoiceGroup";
import { LoadingButton } from "./LoadingButton";
import { DetailedErrorAlert } from "./DetailedErrorAlert";

export const ClinicMappingModal = ({
  caseId,
  buttonLabel = "Confirm Clinic Mapping",
  modalHeader = "Confirm Clinic Mapping",
  buttonProps = {},
}) => {
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const toggleModal = () => setModal(!modal);

  const { data: potentialClinics = [], isLoading } = useAPI(`/cases/${caseId}/potential_clinics`);

  const handleSubmit = async ({ clinicId }) => {
    setConfirming(true);

    try {
      await Promise.all([
        api.post(`/cases/${caseId}/set_clinic`, { clinic_id: clinicId }),
        trackEvent("clinic_verified", caseId, { clinic_id: clinicId }),
      ]);

      setModal(false);
      redirectTo(window.location, "Case updated with new Clinic", "success");
    } catch (err) {
      setError(err?.response?.data?.error);
      setConfirming(false);
    }
  };

  const clinicOptions = useMemo(() => potentialClinics.map((p) => ({ value: `${p.id}`, label: p.clinic_name })), [potentialClinics]);

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <LoadingButton {...buttonProps} onClick={toggleModal} loading={isLoading || confirming}>
          {buttonLabel}
        </LoadingButton>

        {!isLoading && <span className="ms-3 text-muted">There are {potentialClinics.length} potential clinics.</span>}
      </div>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>{modalHeader}</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleSubmit}>
            {({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit} noValidate>
                  <ChoiceGroup
                    required
                    label="Clinic"
                    name="clinicId"
                    validate={composeValidators(required, valueInOptions(clinicOptions))}
                    disabled={isLoading}
                    choices={clinicOptions}
                  />

                  <LoadingButton type="submit" color="primary" loading={isLoading || submitting} disabled={submitting}>
                    Confirm
                  </LoadingButton>
                </form>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
