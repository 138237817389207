import React, { useCallback, useState } from "react";
import { LoadingButton } from "../../components/LoadingButton";
import { adminApi, stringifyParams } from "../../util/api";

const successCallback = (ref) => {
  const { setMessage } = ref.current;

  return setMessage("Export started. You will receive an email when your file is ready", "success");
};

const errorCallback = (ref, err) => {
  const { setMessage } = ref.current;

  return setMessage(err.message, "danger");
};

export const AdministrateBackgroundExportButton = React.forwardRef(({ exportUrl, filters, onSuccess, onError, ...rest }, ref) => {
  const [loading, setLoading] = useState(false);
  const onSuccessFn = useCallback(
    (ref) => {
      if (ref) {
        return successCallback(ref);
      } else {
        return onSuccess();
      }
    },
    [onSuccess]
  );

  const onErrorFn = useCallback(
    (ref, err) => {
      if (ref) {
        return errorCallback(ref, err);
      } else {
        return onError(err);
      }
    },
    [onError]
  );

  const getExportUrl = useCallback(() => {
    const paramsString = stringifyParams(filters);

    if (!paramsString) {
      return exportUrl;
    }

    return exportUrl.indexOf("?") > -1 ? `${exportUrl}&${paramsString}` : `${exportUrl}?${paramsString}`;
  }, [exportUrl, filters]);

  const handleClick = async () => {
    setLoading(true);

    try {
      await adminApi.post(getExportUrl());

      onSuccessFn(ref);
    } catch (err) {
      onErrorFn(ref, err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton onClick={handleClick} loading={loading} {...rest}>
      Export
    </LoadingButton>
  );
});
