import * as Sentry from "@sentry/react";
import React from "react";

export function initSentry() {
  const dsn = document.querySelector("meta[name=sentry-dsn]").content;
  const environment = document.querySelector("meta[name=application-environment]").content;

  if (dsn && environment) {
    Sentry.init({
      dsn,
      environment,
      integrations: [Sentry.browserTracingIntegration()],
    });
  }
}
