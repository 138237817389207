import React, { useState, useCallback } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { LoadingButton } from "../../../components/LoadingButton";
import { api, handleRequestSequence, extractErrorMessage } from "../../../util/api";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const AddGroupMemberModal = ({ group, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = useCallback(
    async (values) => {
      setError(null);

      try {
        const addRequests = values.accountIds.map((account_id) => api.post(`/admin/groups/${group.id}/members`, { account_id }));
        const results = await handleRequestSequence(addRequests);

        onAdd?.(results);

        setOpen(false);
      } catch (err) {
        setError(extractErrorMessage(err));
      }
    },
    [group, onAdd]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Members
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add Members to {group.teamName}</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate}>
            {({ handleSubmit, form, submitting }) => {
              return (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/accounts.json?for_groups=true"
                    required
                    label="Accounts to Add"
                    name="accountIds"
                    labelKey="full_name_or_email"
                    isMulti
                  />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
