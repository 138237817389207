import { useCallback, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { Field, Form } from "react-final-form";
import { LoadingButton } from "../components/LoadingButton";
import { DropzoneInput } from "../components/inputs/final_form/DropzoneInput";
import { LabSelector, SerializedLabSelector } from "../components/inputs/final_form/LabSelector";
import { SerializedPayorSelector } from "../components/inputs/final_form/PayorSelector";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { Switch } from "../components/inputs/final_form/Switch";
import { TestSelector } from "../components/inputs/final_form/TestSelector";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { api, redirectTo } from "../util/api";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { required } from "../util/validators";

const NewFillableWorkflowDocumentForm = ({
  fwd,
  formUrl,
  workflows,
  documentTypes,
  initialLabOptions,
  initialAutoAttachLabOptions,
  initialPayorOptions,
}) => {
  const [error, setError] = useState(null);

  const onSubmit = useCallback(
    async (values) => {
      setError(null);

      try {
        const modifiedValues = { ...values, template_file: values.template_file?.[0] };

        if (fwd.id) {
          const res = await api.patch(formUrl, { fillable_workflow_document: modifiedValues });
          window.location.reload();
        } else {
          const res = await api.post(formUrl, { fillable_workflow_document: modifiedValues });
          redirectTo(`/operations/fillable_workflow_documents/${res.data.id}`, "New Workflow Document Created", "success");
        }
      } catch (err) {
        setError(err);
      }
    },
    [fwd.id, formUrl]
  );

  const workflowOptions = useMemo(() => _.map(workflows, (w) => ({ label: w, value: w })), [workflows]);
  const documentTypeOptions = useMemo(() => _.map(documentTypes, (w) => ({ label: w, value: w })), [documentTypes]);

  const initialValues = useMemo(() => {
    return {
      ..._.pick(fwd, ["name", "workflow", "document_type", "published", "auto_attach_to_case", "auto_attach_test_ids"]),
      lab_id: fwd.lab_id?.toString(),
      auto_attach_lab_ids: fwd.auto_attach_lab_ids.map((id) => id?.toString()),
      auto_attach_payor_ids: fwd.auto_attach_payor_ids.map((id) => id?.toString()),
    };
  }, [fwd]);

  return (
    <>
      <DetailedErrorAlert error={error} />

      <Form onSubmit={onSubmit} initialValues={initialValues} keepDirtyOnReinitialize>
        {({ form, handleSubmit, submitting, values }) => {
          const selectedLabIds = values.auto_attach_lab_ids ?? [];

          const handleAutoAttachLab = (labs) => {
            form.change("auto_attach_lab_ids", labs);
          };

          const handleAutoAttachPayor = (payors) => {
            form.change("auto_attach_payor_ids", payors);
          };

          return (
            <>
              <Field label="Name" required validate={required} component={TextInput} name="name" />
              <Field label="Workflow" component={SelectInput} name="workflow" options={workflowOptions} />
              <Field label="Document Type" component={SelectInput} name="document_type" options={documentTypeOptions} />

              <Field label="Lab" component={LabSelector} name="lab_id" initialOptions={initialLabOptions} />

              <Field component={Switch} name="published" type="checkbox" label="Published" />
              <Field component={DropzoneInput} label="Template File" name="template_file" />

              <hr />

              <fieldset>
                <Field component={Switch} name="auto_attach_to_case" type="checkbox" label="Auto Attach to Case" />
                <Field
                  label="Auto attach labs"
                  onChange={handleAutoAttachLab}
                  component={SerializedLabSelector}
                  name="auto_attach_lab_ids"
                  initialOptions={initialAutoAttachLabOptions}
                  isMulti
                />
                <Field
                  label="Auto attach payors"
                  onChange={handleAutoAttachPayor}
                  component={SerializedPayorSelector}
                  name="auto_attach_payor_ids"
                  initialOptions={initialPayorOptions}
                  isMulti
                />

                {selectedLabIds.length > 0 && (
                  <Field label="Auto attach tests" component={TestSelector} labIds={selectedLabIds} name="auto_attach_test_ids" isMulti />
                )}
              </fieldset>

              <LoadingButton color="primary" loading={submitting} onClick={handleSubmit}>
                {fwd.id ? "Update" : "Save"}
              </LoadingButton>
            </>
          );
        }}
      </Form>
    </>
  );
};

export const initFwdForm = () => {
  const fwdFormContainer = document.getElementById("fwd_form");

  if (fwdFormContainer) {
    const root = createRoot(fwdFormContainer);
    const { fwd, formUrl, workflows, documentTypes, initialLabOptions, initialAutoAttachLabOptions, initialPayorOptions } =
      fwdFormContainer.dataset;

    root.render(
      <NewFillableWorkflowDocumentForm
        fwd={JSON.parse(fwd) || null}
        formUrl={formUrl}
        workflows={JSON.parse(workflows)}
        documentTypes={JSON.parse(documentTypes)}
        initialLabOptions={JSON.parse(initialLabOptions)}
        initialAutoAttachLabOptions={JSON.parse(initialAutoAttachLabOptions)}
        initialPayorOptions={JSON.parse(initialPayorOptions)}
      />
    );
  }
};
