import { useMemo } from "react";
import { DetailsList } from "../../components/DetailsList";

const useInsurancePayorMappingDetails = (insurancePayorMapping) => {
  const { payor, lab, insuranceName } = insurancePayorMapping;

  return useMemo(
    () => [
      {
        label: "Payor",
        value: payor?.name,
      },
      {
        label: "Lab",
        value: lab?.labName,
      },
      {
        label: "Insurance Name",
        value: insuranceName,
      },
    ],
    [payor?.name, lab?.labName, insuranceName]
  );
};
export const InsurancePayorMappingReadOnly = ({ insurancePayorMapping }) => {
  const values = useInsurancePayorMappingDetails(insurancePayorMapping);
  return <DetailsList values={values} />;
};
