import classNames from "classnames";
import React from "react";
import { Button as BSButton } from "reactstrap";

export const Button = ({ icon, children, size, ...props }) => {
  const wrappedIcon = icon
    ? React.cloneElement(icon, { size: 20, className: classNames("icon-btn", { "me-1": Boolean(children) }) })
    : null;

  return (
    <BSButton {...props}>
      {wrappedIcon}

      {children}
    </BSButton>
  );
};
