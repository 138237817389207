import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { Alert, Col } from "reactstrap";
import { FormRow } from "../components/forms/FormRow";
import { DateInput } from "../components/inputs/final_form/DateInput";
import { InputWrapper } from "../components/inputs/final_form/InputWrapper";
import { PillToggleInput } from "../components/inputs/final_form/PillToggleInput";
import { SelectInput } from "../components/inputs/final_form/SelectInput";
import { TextInput } from "../components/inputs/final_form/TextInput";
import { mapStringsToOptions } from "../util/forms";
import { isBlank } from "../util/helpers";
import { composeValidators, required, requiredNotNull, valueInOptions } from "../util/validators";
import { InsuranceMemberIdInput } from "./InsuranceMemberIdInput";
import { Switch } from "../components/inputs/final_form/Switch";
import { getDefaultWorkflow, radioIsInsured, useWorkflows } from "./PatientAndInsuranceInfoHelperMethods";
import { LabSelector } from "../components/inputs/final_form/LabSelector";
import { PayorSelector } from "../components/inputs/final_form/PayorSelector";
import { GenderSelector } from "../components/inputs/GenderSelector";
import { PatientIsInsuredSelector } from "../components/inputs/PatientIsInsuredSelector";
import { api } from "../util/api";

const mapEnabledWorkflowsToOrderTypeOptions = (workflows) =>
  _.compact(_.map(workflows, (w) => AVAILABLE_ORDER_TYPES[w.resource] || AVAILABLE_ORDER_TYPES[w]));

const PREGNANCY_OPTIONS = mapStringsToOptions(["1st Trimester", "2nd Trimester", "3rd Trimester", "Not Pregnant"]);
const ETHNICITY_OPTIONS = mapStringsToOptions([
  "Not Provided",
  "African American/Black",
  "Hispanic/Latin American",
  "Caucasian/Non-Hispanic White",
  "Ashkenazi Jewish",
  "Sephardic Jewish",
  "Mediterranean",
  "East Asian",
  "Southeast Asian",
  "French Canadian",
  "Other",
]);

const PA_ORDER_TYPE = "priorAuthRequest";
const BI_ORDER_TYPE = "benefitsInvestigation";
const PSR_ORDER_TYPE = "postServiceReview";
const AVAILABLE_ORDER_TYPES = {
  prior_auth_request: {
    label: "Prior Auth",
    abbreviation: "Prior Authorization",
    value: PA_ORDER_TYPE,
  },
  post_service_review: {
    label: "Post-Service Review",
    value: PSR_ORDER_TYPE,
  },
  benefits_investigation: { label: "seeQer", abbreviation: "Benefits Investigation", value: BI_ORDER_TYPE },
};

export const searchPayorByName = async (name) => {
  const res = await api.get(`/payors/find_by_name?name=${name}`);
  return res.data.resource;
};

export const searchPayorById = async (id) => {
  const res = await api.get(`/payors/find_by_id/${id}.json`);
  return res.data.resource;
};

// payors is "supported payors" or "available_ordering_payors"
export const PatientAndInsuranceInfoFields = ({
  isClinicUser,
  defaultOrderableWorkflows,
  form,
  values,
  errors,
  isRequestingProviderTypePharmacy,
  hasOrderingLabs,
  uninsuredPatientPayor,
}) => {
  const {
    value: workflows,
    loading: loadingWorkflows,
    error,
  } = useWorkflows({ isClinicUser, defaultOrderableWorkflows, labId: values.labId });

  const workflowOptions = useMemo(() => mapEnabledWorkflowsToOrderTypeOptions(workflows), [workflows]);
  const defaultWorkflow = getDefaultWorkflow(workflowOptions, isClinicUser, workflows);

  const [showPayorSelect, setShowPayorSelect] = useState(true);
  const showWorkflowSelector = useMemo(() => values.labId || isClinicUser, [values.labId, isClinicUser]);
  const showOptionalFields = values.orderedWorkflows === PA_ORDER_TYPE;

  const shouldUpdateLabel = isClinicUser && isRequestingProviderTypePharmacy;

  // Memoize the uninsured payor ID, or set it to null if it does not exist
  const uninsuredPatientPayorId = useMemo(() => uninsuredPatientPayor?.public_id, [uninsuredPatientPayor]);

  const [isUninsured, setIsUninsured] = useState(
    uninsuredPatientPayorId && values.insuranceInfo?.primaryInsurance?.insuranceId === uninsuredPatientPayorId
  );

  const handleMatchingPayorFound = useCallback(
    (payorId, payorName) => {
      form.change("insuranceInfo.primaryInsurance.insuranceName", payorName);
      form.change("insuranceInfo.primaryInsurance.insuranceId", payorId);
      setShowPayorSelect(true);
    },
    [form, setShowPayorSelect]
  );

  const handleInsuranceIdSelected = useCallback(
    async (idOrPublicId) => {
      const payor = await searchPayorById(idOrPublicId);
      form.change("insuranceInfo.primaryInsurance.insuranceName", payor.name);
      form.change("insuranceInfo.primaryInsurance.insuranceId", idOrPublicId);
    },
    [form]
  );

  const handleInsuranceNameSelected = useCallback(
    async (name) => {
      const payor = await searchPayorByName(name);
      form.change("insuranceInfo.primaryInsurance.insuranceName", name);
      form.change("insuranceInfo.primaryInsurance.insuranceId", payor.public_id);
    },
    [form]
  );

  const setUninsuredInsuranceValues = useCallback(
    (form) => {
      form.change("insuranceInfo.primaryInsurance.memberId", null);
      form.change("insuranceInfo.primaryInsurance.insuranceName", uninsuredPatientPayor?.name);
      form.change("insuranceInfo.primaryInsurance.insuranceId", uninsuredPatientPayor?.public_id);
    },
    [uninsuredPatientPayor]
  );

  const clearUninsuredInsuranceValues = useCallback((form) => {
    form.change("insuranceInfo.primaryInsurance.memberId", null);
    form.change("insuranceInfo.primaryInsurance.insuranceName", null);
    form.change("insuranceInfo.primaryInsurance.insuranceId", null);
    form.change("insuranceInfo.primaryInsurance.planId", null);
    form.change("insuranceInfo.primaryInsurance.groupId", null);
  }, []);

  const handlePatientUninsured = useCallback(
    ({ target }) => {
      form.change("insuranceInfo.primaryInsurance.insuranceId", radioIsInsured(target.value) ? undefined : uninsuredPatientPayorId);

      if (!radioIsInsured(target.value)) {
        setIsUninsured(true);
        setShowPayorSelect(false);
        setUninsuredInsuranceValues(form);
      } else {
        setIsUninsured(false);
        setShowPayorSelect(true);
        clearUninsuredInsuranceValues(form);
      }
    },
    [form, setShowPayorSelect, setIsUninsured, uninsuredPatientPayorId, setUninsuredInsuranceValues, clearUninsuredInsuranceValues]
  );

  const useInsuranceName =
    !isBlank(values.insuranceInfo?.primaryInsurance?.insuranceName) && isBlank(values.insuranceInfo?.primaryInsurance?.insuranceId);

  const uninsuredPayorFeatureEnabled = true;

  const labIdChangeHandler = async (value) => {
    if (value && !isClinicUser) {
      form.mutators.clearOrderedWorkflowsMutator();
    }
  };

  const patientSexChangeHandler = (selected) => {
    if (selected) {
      form.mutators.setPregnancyMutator(selected.value);
    }
  };

  const isClinicUserWithOutLabs = isClinicUser && !hasOrderingLabs;

  return (
    <>
      <h5 className="mb-4">Patient & Insurance Info</h5>
      <FormRow>
        {isClinicUser && !isClinicUserWithOutLabs && (
          <Col>
            <Field
              id="allLabs"
              component={Switch}
              label={shouldUpdateLabel ? "Show all providers" : "Show all Labs"}
              name="allLabs"
              type="checkbox"
            />
          </Col>
        )}
      </FormRow>
      <FormRow>
        <Col>
          <FormSpy subscription={{ values: true }}>
            {({ values }) =>
              (values.allLabs === true || isClinicUserWithOutLabs) && !isRequestingProviderTypePharmacy ? (
                <Field
                  required
                  name="labId"
                  validate={composeValidators(required)}
                  render={LabSelector}
                  label="Lab"
                  placeholder="Choose a Lab..."
                  data-testid="lab"
                  key="all_labs"
                />
              ) : values.allLabs === true && isRequestingProviderTypePharmacy ? (
                <>
                  <Field
                    required
                    name="labId"
                    validate={composeValidators(required)}
                    render={LabSelector}
                    placeholder={shouldUpdateLabel ? "Choose a Provider..." : "Choose a Lab..."}
                    data-testid="lab"
                    label={shouldUpdateLabel ? "Ordering Provider" : "Lab"}
                  />

                  <OnChange name="labId">{labIdChangeHandler}</OnChange>
                </>
              ) : hasOrderingLabs ? (
                <>
                  {isClinicUser ? (
                    <Field
                      required
                      name="labId"
                      validate={composeValidators(required)}
                      render={LabSelector}
                      placeholder={shouldUpdateLabel ? "Choose a Provider..." : "Choose a Lab..."}
                      data-testid="lab"
                      scope="orderable_supported_labs"
                      label={shouldUpdateLabel ? "Ordering Provider" : "Lab"}
                      key="supported_labs"
                    />
                  ) : (
                    <Field
                      required
                      name="labId"
                      validate={composeValidators(required)}
                      render={LabSelector}
                      placeholder={shouldUpdateLabel ? "Choose a Provider..." : "Choose a Lab..."}
                      data-testid="lab"
                      label={shouldUpdateLabel ? "Ordering Provider" : "Lab"}
                      key="supported_labs"
                    />
                  )}
                  <OnChange name="labId">{labIdChangeHandler}</OnChange>
                </>
              ) : (
                <Field required name="labId" validate={composeValidators(required)} component={TextInput} type="hidden" data-testid="lab" />
              )
            }
          </FormSpy>
        </Col>
      </FormRow>

      {showWorkflowSelector && !loadingWorkflows && (
        <>
          {workflowOptions && workflowOptions.length > 0 ? (
            <>
              <Field
                required
                name="orderedWorkflows"
                validate={composeValidators(required, valueInOptions(workflowOptions))}
                disabled={loadingWorkflows || workflowOptions.length == 1}
                options={workflowOptions}
                initialValue={defaultWorkflow}
                component={PillToggleInput}
                className="bg-white"
              />

              <InputWrapper label="Order Type" required>
                <p>
                  <strong>{_.find(workflowOptions, { value: values.orderedWorkflows })?.abbreviation}</strong>
                </p>
              </InputWrapper>
            </>
          ) : (
            <>
              <Field required name="orderedWorkflows" validate={composeValidators(required)} component={TextInput} type="hidden" />

              {errors.orderedWorkflows ? (
                <Alert color="danger">Order Type is required. There are no available order types for this Lab</Alert>
              ) : (
                <Alert color="warning">There are no available order types for this Lab.</Alert>
              )}
            </>
          )}
          <OnChange name="labId">{labIdChangeHandler}</OnChange>
        </>
      )}

      <FormRow>
        <Col>
          <Field
            required
            validate={composeValidators(required)}
            name="patientInfo.firstName"
            component={TextInput}
            label="First Name"
            data-testid="patientFirstName"
          />
        </Col>
        {showOptionalFields && (
          <Col>
            <Field name="patientInfo.middleName" component={TextInput} label="Middle Name" data-testid="patientMiddleName" />
          </Col>
        )}
        <Col>
          <Field
            required
            validate={composeValidators(required)}
            name="patientInfo.lastName"
            component={TextInput}
            label="Last Name"
            data-testid="patientLastName"
          />
        </Col>
        <Col>
          <Field
            required
            validate={composeValidators(requiredNotNull)}
            name="patientInfo.dob"
            component={DateInput}
            label="DOB"
            data-testid="patientDOB"
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col>
          <Field
            required
            validate={composeValidators(requiredNotNull)}
            name="patientInfo.sex"
            component={(props) => <GenderSelector onGenderChangeCallback={patientSexChangeHandler} {...props} />}
            isClearable
            label="Gender"
          />
        </Col>
        <Col>
          <Field name="patientInfo.patientId" component={TextInput} label="Patient ID" data-testid="patientID" />
        </Col>
        {showOptionalFields && (
          <Col>
            <Field name="patientInfo.phone" component={TextInput} label="Phone Number" type="tel" data-testid="patientPhone" />
          </Col>
        )}
      </FormRow>

      <FormRow>
        {showOptionalFields && (
          <>
            <Col>
              <Field
                label="Is Patient Pregnant?"
                name="patientInfo.pregnant"
                render={(props) => <SelectInput {...props} isClearable options={PREGNANCY_OPTIONS} data-testid="patientPregnant" />}
              />
            </Col>
            <Col>
              <Field
                label="Ethnicity"
                name="patientInfo.ethnicity"
                render={(props) => <SelectInput {...props} isClearable options={ETHNICITY_OPTIONS} data-testid="patientEthnicity" />}
              />
            </Col>
            <Col>
              {values.patientInfo?.ethnicity === "Other" && (
                <Field
                  required
                  validate={composeValidators(requiredNotNull)}
                  name="patientInfo.ethnicityOther"
                  component={TextInput}
                  label="Ethnicity (other)"
                />
              )}
            </Col>
          </>
        )}
      </FormRow>

      <FormRow>
        <Col>
          {uninsuredPayorFeatureEnabled && (
            <Field
              required
              label="Is the patient insured?"
              id="insuranceInfo.insured"
              name="insuranceInfo.insured"
              validate={requiredNotNull}
              onIsInsuredChangeCallback={handlePatientUninsured}
              component={PatientIsInsuredSelector}
            />
          )}
        </Col>
      </FormRow>
      <FormRow>
        {!isUninsured && showPayorSelect && (
          <>
            <Col>
              <Field
                required
                validate={composeValidators(requiredNotNull)}
                name="insuranceInfo.primaryInsurance.memberId"
                component={InsuranceMemberIdInput}
                label="Member ID"
                data-testid="primaryInsuranceMemberID"
                selectedPayorId={_.get(values, "insuranceInfo.primaryInsurance.insuranceId")}
                onMatchFound={handleMatchingPayorFound}
              />
            </Col>
            {isClinicUser && (
              <FormRow>
                <Col>
                  <Field id="allPayors" component={Switch} label="Show all Payors" name="allPayors" type="checkbox" />
                </Col>
              </FormRow>
            )}
            {useInsuranceName ? (
              <Col>
                <Field
                  required
                  validate={composeValidators(requiredNotNull)}
                  name="insuranceInfo.primaryInsurance.insuranceName"
                  isClearable
                  render={PayorSelector}
                  key="payor_by_name"
                  valueSelector="name"
                  data-testid="primaryInsuranceName"
                  label="Primary Insurance Name"
                  onChange={handleInsuranceNameSelected}
                />
              </Col>
            ) : (
              <Col>
                <FormSpy subscription={{ values: true }}>
                  {({ values }) => {
                    return values.allPayors == true ? (
                      <Field
                        required
                        name="insuranceInfo.primaryInsurance.insuranceId"
                        validate={composeValidators(requiredNotNull)}
                        render={PayorSelector}
                        valueSelector="public_id"
                        label="Primary Insurance"
                        key="unfiltered_payors"
                        onChange={handleInsuranceIdSelected}
                        data-testid="primaryInsuranceId"
                      />
                    ) : (
                      <Field
                        required
                        validate={composeValidators(requiredNotNull)}
                        name="insuranceInfo.primaryInsurance.insuranceId"
                        render={PayorSelector}
                        valueSelector="public_id"
                        scope={isClinicUser ? "clinic_user" : ""}
                        label="Primary Insurance"
                        key="filtered_payors"
                        onChange={handleInsuranceIdSelected}
                        data-testid="primaryInsuranceId"
                      />
                    );
                  }}
                </FormSpy>
              </Col>
            )}
          </>
        )}
      </FormRow>

      {!isUninsured && showPayorSelect && showOptionalFields && (
        <FormRow>
          <Col>
            <Field
              name="insuranceInfo.primaryInsurance.groupId"
              component={TextInput}
              label="Group ID"
              data-testid="primaryInsuranceGroupID"
            />
          </Col>

          <Col>
            <Field
              name="insuranceInfo.primaryInsurance.planId"
              component={TextInput}
              label="Plan ID"
              data-testid="primaryInsurancePlanID"
            />
          </Col>
        </FormRow>
      )}

      <FormRow>
        {values.orderedWorkflows === "postServiceReview" && (
          <Col>
            <Field name="insuranceInfo.claimInfo.claimNumber" component={TextInput} label="Claim Number" />
          </Col>
        )}
      </FormRow>
    </>
  );
};
