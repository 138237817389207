import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { BoolCell } from "../../components/grid/cells/BoolCell";
import { EnumFilter } from "../../components/inputs/EnumFilter";
import { Toolbar } from "../../components/Toolbar";
import { redirectTo } from "../../util/api";
import { deepCompact } from "../../util/helpers";
import { AdministrateBackgroundExportButton } from "../components/AdministrateBackgroundExportButton";
import { AdministrateSelect } from "../components/AdministrateSelect";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { Routes } from "../Routes";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../utils";
import { CreatePayorModal } from "./components/CreatePayorModal";
import { ImportPayorsModal } from "./components/ImportPayorsModal";
import { PayorActionCell } from "./components/PayorActionCell";

const useColumns = makeColumns(() => [
  {
    id: "id",
    accessor: "id",
    Header: "Payor ID",
  },
  {
    id: "name",
    accessor: "name",
    Header: "Name",
    Cell: ({ row, value }) => (
      <Link to={Routes.payor.url(row.original.id)}>
        <span className="token">{value}</span>
      </Link>
    ),
  },
  {
    id: "paper",
    accessor: "paper",
    Header: "Supports Paper",
    Cell: BoolCell,
    Filter: (props) => (
      <EnumFilter
        {...props}
        options={[
          { value: true, label: "True" },
          { value: false, label: "False" },
        ]}
      />
    ),
  },
  {
    id: "noRetroAuths",
    accessor: "noRetroAuths",
    Header: "No Retro Auths",
    Cell: BoolCell,
    Filter: (props) => (
      <EnumFilter
        {...props}
        options={[
          { value: true, label: "True" },
          { value: false, label: "False" },
        ]}
      />
    ),
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: PayorActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PayorsIndexPage = () => {
  const columns = useColumns();
  const [selectedPayorGroupId, setSelectedPayorGroupId] = useState(null);
  const [canCreate] = useCreatePermission("Payor");

  const onCreate = (newPayor) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created new payor ${newPayor.name}`);
    redirectTo(`/admin/system/payors/${newPayor.id}`);
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onExportSuccess = () => {
    const { setMessage } = tableRef.current;
    setMessage("Export started. You will receive an email when your file is ready", "success");
  };

  const onExportError = (err) => {
    const { setError } = tableRef.current;
    setError(err);
  };

  const extraParams = useMemo(() => deepCompact({ payorGroupId: selectedPayorGroupId }), [selectedPayorGroupId]);
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  return (
    <SystemConfigLayout title="Payors">
      <Toolbar className="align-items-center mb-3">
        <Toolbar>
          <div style={{ width: 300 }}>
            <AdministrateSelect
              placeholder="Filter by Payor Group..."
              resourceUrl="/admin/payor_groups.json"
              onChange={setSelectedPayorGroupId}
              className="me-2"
            />
          </div>
        </Toolbar>

        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/payors/export"
            filters={allFilters}
            onError={onExportError}
            onSuccess={onExportSuccess}
          />
          {canCreate && (
            <>
              <ImportPayorsModal outline className="me-2" onImport={onImport} />
              <CreatePayorModal color="primary" onCreate={onCreate} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/payors.json"}
        resourceName={"payor"}
        extraParams={extraParams}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
