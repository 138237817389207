import classnames from "classnames";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { FormGroup, Label } from "reactstrap";
import { CptSelector } from "../../../components/inputs/CptSelector";
import { featureEnabled } from "../../../util/feature";

const stringifyCpts = (cpts) => {
  if (featureEnabled("use_procedure_code_multiplier_column")) {
    return JSON.stringify(cpts);
  } else {
    const HL7_JOIN = "|";
    return cpts.join(HL7_JOIN);
  }
};

const CptSelectApp = ({ initialCpts, inputName, label, required = false, testInputSubscriptionId }) => {
  const myRef = useRef();
  // initialCpts and selectedCpts vary based on the use_procedure_code_multiplier_column flag
  const [selectedCpts, setSelectedCpts] = useState(initialCpts);
  const stringCptValue = useMemo(() => stringifyCpts(selectedCpts), [selectedCpts]);

  return (
    <div ref={myRef}>
      <FormGroup>
        {label && (
          <Label className={classnames({ required })}>
            {label} {required && <abbr title="required">*</abbr>}
          </Label>
        )}
        {/* If the multiplier flag is off, setSelectedCpts is called with e.g. ["U1000", "U1000", "M1000"].
          If it's on, it's called with e.g. [{id: 1, code: "U1000", multiplier: 2}] */}
        <CptSelector value={selectedCpts} onChange={setSelectedCpts} valid />
        {/* The value passed to this input changes based on the multiplier flag.
            CD-8904 handles updating the Rails which consumes this input when the flag is on. */}
        <input type="hidden" name={inputName} value={stringCptValue} />
      </FormGroup>
    </div>
  );
};

export const initCptSelect = () => {
  const selectContainers = Array.from(document.getElementsByClassName("cpt_selector"));

  selectContainers.forEach(function (container) {
    const root = createRoot(container);
    const { initialCpts, inputName, label, required } = container.dataset;

    root.render(<CptSelectApp initialCpts={JSON.parse(initialCpts)} required={JSON.parse(required)} inputName={inputName} label={label} />);
  });
};
