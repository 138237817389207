import React, { useState, useCallback } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { api, extractErrorMessage } from "../../../util/api";
import { required } from "../../../util/validators";
import { snakifyKeys } from "../../../util/helpers";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { SelectInput } from "../../../components/inputs/final_form/SelectInput";
import { TIER_OPTIONS } from "../tabs/PortalPayorsTab";

export const AddPortalPayorModal = ({ portal, onAdd, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = useCallback(
    async (values) => {
      setError(null);
      try {
        const { data } = await api.post("/admin/payor_portal_mappings", snakifyKeys(values));

        if (onAdd) {
          onAdd(`Added ${data.resource.payor.name}`);
        }

        setOpen(false);
      } catch (err) {
        setError(extractErrorMessage(err));
      }
    },
    [onAdd]
  );

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Payor
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Payor</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{ benefitManagementPortalId: portal.id }}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field component={TextInput} type="hidden" name="benefitManagementPortalId" />
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/payors.json"
                    label="Payor"
                    name="payorId"
                    searchParam="name"
                    required
                  />
                  {portal.supports_tiers && (
                    <Field component={SelectInput} label="Tier" options={TIER_OPTIONS} defaultValue="tier_1" name="tier" />
                  )}
                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
