import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { ActionAlert } from "../../../../components/ActionAlert";
import { LoadingButton } from "../../../../components/LoadingButton";
import { isBlank } from "../../../../util/helpers";
import { WizardContext } from "../../WizardContext";
import { trackFinish } from "../../wizard_utils";

export const PayorHasNotSentDeterminationBranch = () => (
  <WizardContext.Consumer>
    {({ state, dispatch, loading, continueWorkflow, createComment }) => (
      <>
        <FormGroup>
          <Label>Reference Number</Label>
          <Input
            style={{ maxWidth: 300 }}
            name="payorReferenceNum"
            value={state.answers.payorReferenceNum || ""}
            onChange={(e) => {
              dispatch({
                type: "setAnswertext",
                key: "payorReferenceNum",
                value: e.target.value,
                doTransition: false,
              });
            }}
            onBlur={(e) => {
              dispatch({
                type: "setAnswer",
                key: "payorReferenceNum",
                value: e.target.value,
                doTransition: false,
              });
            }}
          />
        </FormGroup>

        {!isBlank(state.answers.payorReferenceNum) && (
          <>
            <ActionAlert
              className="mt-3"
              color="success"
              actions={
                <LoadingButton
                  color="success"
                  loading={loading}
                  onClick={async () => {
                    await createComment(`Reference Number: ${state.answers.payorReferenceNum}`);

                    // We await these separately so that the continue cannot
                    // interrupt the create comment network request.
                    await continueWorkflow();
                  }}
                >
                  Ok
                </LoadingButton>
              }
            >
              Confirm fax number and request determination be sent. Continue with workflow to finalize Case.
            </ActionAlert>
          </>
        )}
      </>
    )}
  </WizardContext.Consumer>
);
