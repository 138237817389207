import { AdministrateResourceDeleteButton } from "../../../components/AdministrateResourceDeleteButton";
import { useTableContext } from "../../../components/AdministrateTable";
import { useDestroyPermission, useUpdatePermission } from "../../../utils";
import { History } from "../../sandbox/release/History";
import { EditPreprocessingCategory } from "./EditPreprocessingCategoryModal";

export const PreprocessingCategoryActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();
  const [canDestroy] = useDestroyPermission("PreprocessingCategory", row.original.id);
  const [canUpdate] = useUpdatePermission("PreprocessingCategory", row.original.id);

  return (
    <>
      {canUpdate && (
        <EditPreprocessingCategory
          color="link"
          preprocessingCategory={row.original}
          onUpdate={(row) => {
            refresh();
            setMessage(`Updated ${row.name} category.`);
          }}
        />
      )}

      {canDestroy && (
        <AdministrateResourceDeleteButton
          deleteUrl={`/admin/preprocessing_categories/${row.original.id}`}
          onSuccess={() => {
            refresh();
            setMessage(`Deleted Preprocessing Category ${row.original.name}.`);
          }}
          onError={setError}
        />
      )}

      <History entityName="PreprocessingCategory" subjectId={row.original.id} />
    </>
  );
};
