import React, { useState, useCallback } from "react";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../../components/LoadingButton";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { api, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { TestCategoryForm } from "./TestCategoryForm";

export const AddTestCategoryModal = ({ onAdd, ...buttonProps }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((x) => !x);

  const handleCreate = async (values) => {
    setError(null);
    try {
      const res = await api.post("/admin/test_categories", { test_category: snakifyKeys(values) });

      if (onAdd) {
        onAdd(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button {...buttonProps} onClick={toggleModal}>
        Add Category
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add Category</ModalHeader>
        <ModalBody>
          <DetailedErrorAlert error={error} />
          <Form
            onSubmit={handleCreate}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={{ testCategories: [{ name: "" }] }}
            keepDirtyOnReinitialize={true}
          >
            {(props) => <TestCategoryForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
