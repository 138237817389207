import _ from "lodash";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { snakifyKeys } from "../../../util/helpers";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { TestRepositoryForm } from "./TestRepositoryForm";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";

export const AddTestRepositoryModal = ({ onCreate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen((o) => !o);

  const handleCreate = async (values) => {
    setError(null);

    const test = snakifyKeys(values);
    test.lab_id = null;

    try {
      const res = await api.post("/admin/tests", { test });

      if (onCreate) {
        onCreate(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Add Product/Procedure/Test
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Add a new Product/Procedure/Test</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate} initialValues={{}}>
            {(props) => <TestRepositoryForm {...props} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
