import React, { useState } from "react";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi, extractErrorMessage } from "../../../util/api";

export const ClonePayorButton = ({ payorId, onClone, onError, ...rest }) => {
  const [loading, setLoading] = useState(false);

  const handleClone = async () => {
    setLoading(true);

    try {
      await adminApi.post(`/admin/payors/${payorId}/clone`);
      onClone();
    } catch (err) {
      onError(extractErrorMessage(err, "An unknown error occurred while cloning"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton loading={loading} onClick={handleClone} {...rest}>
      Clone
    </LoadingButton>
  );
};
