import React from "react";
import { useTableContext } from "../../components/AdministrateTable";
import { AdministrateResourceDeleteButton } from "../../components/AdministrateResourceDeleteButton";
import { EditQuickActionModal } from "./EditQuickActionModal";

export const ManagementActionCell = ({ row }) => {
  const { refresh, setMessage, setError } = useTableContext();

  const handleChange = (msg) => {
    setMessage(`Updated ${row.original.dashboardDisplayName}`);
    refresh();
  };

  return <EditQuickActionModal color="link" onEdit={handleChange} clinicOpsManagement={row.original} />;
};
