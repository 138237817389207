import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { SftpDirectoryForm } from "./SftpDirectoryForm";

export const EditSftpDirectoryModal = ({ labId, sftp, directory, onEdit }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (values) => {
    setError(null);
    try {
      const { data } = await adminApi.put(
        `/admin/labs/${labId}/sftp_directories/${directory.id}`,
        snakifyKeys({ labSftpDirectory: values })
      );

      if (data) {
        onEdit(data);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} color="link">
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          Edit Sweep Directory for {sftp.host}:{sftp.port}
        </ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <SftpDirectoryForm handleAction={handleEdit} sftp={sftp} directory={directory} />
        </ModalBody>
      </Modal>
    </>
  );
};
