import pluralize from "pluralize";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert, ListGroup, ListGroupItem } from "reactstrap";
import { DetailedErrorAlert } from "../../../../components/DetailedErrorAlert";
import { TextInput } from "../../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../../components/LoadingButton";
import { adminApi as api } from "../../../../util/api";
import { camelizeKeys, snakifyKeys } from "../../../../util/helpers";
import { required } from "../../../../util/validators";

export const EvaluatePrefixTab = () => {
  const [error, setError] = useState(null);
  const [matches, setMatches] = useState(null);

  const handleEvaluate = async (values) => {
    setError(null);

    try {
      const res = await api.post("/admin/payor_prefix_mappings/evaluate.json", snakifyKeys({ payorPrefix: values }));
      const matches = res.data.resources;
      setMatches(matches.map((m) => camelizeKeys(m)));
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <DetailedErrorAlert error={error} className="mb-3" />

      <Form onSubmit={handleEvaluate}>
        {({ handleSubmit, submitting }) => {
          return (
            <>
              <Field component={TextInput} required validate={required} label="Member ID" name="memberId" />

              <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting} className="me-2">
                Evaluate
              </LoadingButton>
            </>
          );
        }}
      </Form>

      {matches && (
        <>
          <hr />

          <Alert color="info" className="mb-3">
            {pluralize(`Matches Found`, matches.length, true)}
          </Alert>

          <ListGroup>
            {matches.map((mt, idx) => (
              <ListGroupItem key={idx} className="d-flex flex-row">
                <div className="border-right me-3" style={{ width: 40 }}>
                  {idx + 1}
                </div>
                <div className="border-right me-3" style={{ width: 200 }}>
                  <code>{mt.memberIdPrefixRegex}</code>
                </div>
                <div>{mt?.payor?.name}</div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </>
      )}
    </>
  );
};
