import pluralize from "pluralize";
import { useMemo } from "react";
import { Toolbar } from "../../../components/Toolbar";
import { extractErrorMessage } from "../../../util/api";
import { deepCompact } from "../../../util/helpers";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { DESTROY, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { AddNonCoveredCodesModal } from "../components/AddNonCoveredCodesModal";
import { ImportNonCoveredCodesModal } from "../components/ImportNonCoveredCodesModal";
import { PayorNonCoveredCodeActionCell } from "../components/PayorNonCoveredCodeActionCell";

const useColumns = makeColumns((payorId) => [
  {
    id: "cpt_code.code",
    accessor: "cptCode.code",
    Header: "CPT Codes",
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" valueKey="code" labelKey="code" />,
    disableSortBy: true,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <PayorNonCoveredCodeActionCell payorId={payorId} {...row} />,
    disableFilters: true,
    disableSortBy: true,
  },
]);

const getCreateMessage = ({ failures, successes }) => {
  if (failures.length === 0) {
    return `Created ${pluralize("Non-covered CPT Codes", successes.length, true)} `;
  }

  if (successes.length === 0) {
    return "Failed to create non covered codes: All CPT codes already taken.";
  }

  const errorMessages = failures.map((f) => extractErrorMessage(f.reason)).join("; ");
  const successLabel = pluralize("Non Covered Codes", successes.length, true);
  const errorLabel = pluralize("errors", failures.length, true);

  return `Created ${successLabel}, and encountered ${errorLabel}. Errors: ${errorMessages}.`;
};

export const PayorsNonCoveredCodesTab = ({ payor, payorId }) => {
  const columns = useColumns(payorId);

  const extraParams = useMemo(() => deepCompact({ payor: { id: payorId } }), [payorId]);
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);
  const [canCreate] = useCreatePermission("CptCoverageDenial");

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onAddCptCoverageDenial = (results) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(getCreateMessage(results));
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar>
          <div style={{ width: 300 }}></div>
        </Toolbar>

        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/cpt_coverage_denials/export"
            filters={allFilters}
            ref={tableRef}
          />

          {canCreate && (
            <>
              <ImportNonCoveredCodesModal outline className="me-2" onImport={onImport} payorId={payorId} />
              <AddNonCoveredCodesModal payor={payor} color="primary" onAdd={onAddCptCoverageDenial} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/cpt_coverage_denials.json"}
        resourceName={"cpt_coverage_denials"}
        extraParams={extraParams}
        permissions={[DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
