import React from "react";
import { Field } from "react-final-form";
import { BlankableField } from "../../../components/inputs/final_form/BlankableField";
import { MoneyInput } from "../../../components/inputs/final_form/MoneyInput";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { required } from "../../../util/validators";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { BoolInput } from "../../../components/inputs/final_form/SelectInput";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const CptCodeForm = ({ handleSubmit, submitting, ...rest }) => {
  return (
    <>
      <Field component={TextInput} required validate={required} label="Code" name="code" />
      <Field
        component={AdministrateSelectInput}
        resourceUrl="/admin/enums/procedure_code_types.json"
        valueKey="id"
        labelKey="label"
        searchParam="search"
        required
        validate={required}
        label="Procedure Code Type"
        name="procedureCodeType"
      />

      <BlankableField component={TextInput} label="Short Description" name="description" />
      <Field component={TextInput} label="Description" name="longDescription" type="textarea" rows={5} required validate={required} />
      <BlankableField component={MoneyInput} label="Medicare Price" name="medicareCostCents" />
      <Field component={BoolInput} label="Screening" name="screening" />
      <Field component={BoolInput} label="Always Require PA" name="alwaysRequirePa" />
      <Field component={BoolInput} label="Trizetto Enabled" name="trizettoEnabled" />

      <Field component={Switch} label="Coverage by ICD 10 code" name="coverageByIcd10Code" type="checkbox" />

      <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
        Submit
      </LoadingButton>
    </>
  );
};
