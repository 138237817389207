import React from "react";
import { ReactiveBase, DataSearch, ReactiveList, ResultList } from "@appbaseio/reactivesearch";
import { ActionButtons } from "./ActionButtons";
import { Col, Row } from "reactstrap";

const { ResultListWrapper } = ReactiveList;

export const DocumentIndex = ({ downloadUrl, proxyUrl }) => {
  return (
    <ReactiveBase app="document-search" url={proxyUrl}>
      <DataSearch
        componentId="FileSearch"
        dataField={["file.fileName", "lab.name", "file.components"]}
        className="search-bar"
        queryFormat="or"
        placeholder="Search for file..."
        title="Search"
        debounce={100}
        showClear={true}
      />
      <ReactiveList
        componentId="SearchResult"
        dataField="s3.key"
        size={20}
        className="result-list-container"
        pagination
        loader="Loading Results..."
        react={{
          and: "FileSearch",
        }}
      >
        {({ data }) => (
          <ResultListWrapper>
            {data.map((item) => {
              return (
                <ResultList key={item._id} href={`${downloadUrl}?inline=true&key=${item.s3.key}`}>
                  <ResultList.Content>
                    <Row>
                      <Col>{item.file.fileName || item.s3.key}</Col>
                      <Col>
                        <ActionButtons s3Key={item.s3.key} s3Bucket={item.s3.bucket} />
                      </Col>
                    </Row>
                  </ResultList.Content>
                </ResultList>
              );
            })}
          </ResultListWrapper>
        )}
      </ReactiveList>
    </ReactiveBase>
  );
};
