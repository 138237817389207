import React from "react";
import { Alert, Button, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { api } from "../util/api";
import { LoadingIcon } from "../components/LoadingIcon";
import { Building } from "lucide-react";

export class SetClinicButton extends React.Component {
  state = {
    loading: false,
    modal: false,
    error: false,

    automapModal: false,
    automapLoading: false,
    clinic: "",
    clinicId: "",
    lab: "",
    labId: "",
    externalClinicId: "",
  };

  toggle = () => {
    if (this.state.loading) {
      return false;
    }
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };

  close = () => {
    this.setState({
      modal: false,
      error: false,
      clinicId: "",
      loading: false,
    });
  };

  toggleAutomap = () => this.setState({ automapModal: !this.state.automapModal });
  closeAutomap = () => {
    window.location.reload();
  };

  onClinicIdChange = (e) => {
    this.setState({ clinicId: e.target.value });
  };

  onSubmit = async () => {
    this.setState({ loading: true });
    try {
      const response = await api.post(`/operations/prior_authorize_requests/clinic_mappings/unmapped/${this.props.id}/set_clinic`, {
        clinic_id: this.state.clinicId,
      });

      if (
        response.data.clinic_name &&
        response.data.clinic_id &&
        response.data.external_clinic_id &&
        response.data.lab &&
        response.data.lab_id
      ) {
        this.setState({
          modal: false,
          automapModal: true,
          clinic: response.data.clinic_name,
          clinicId: response.data.clinic_id,
          lab: response.data.lab,
          labId: response.data.lab_id,
          externalClinicId: response.data.external_clinic_id,
        });
      } else {
        window.location.reload();
      }
    } catch (e) {
      this.setState({ error: e.message, loading: false });
      console.error(e);
    }
  };

  onAutomapSubmit = async () => {
    this.setState({ automapLoading: true });
    try {
      await api.post(`/operations/prior_authorize_requests/clinic_mappings/unmapped/automapping`, {
        clinic_id: this.state.clinicId,
        lab_id: this.state.labId,
        external_clinic_id: this.state.externalClinicId,
      });

      window.location.reload();
    } catch (e) {
      this.setState({ error: e.message, automapLoading: false });
      console.error(e);
    }
  };

  render() {
    return (
      <div>
        <Button size="sm" onClick={this.toggle} outline title="Set Clinic">
          <Building />
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.close}>Associate Request to Clinic</ModalHeader>
          <ModalBody>
            {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
            <FormGroup>
              <Label htmlFor="clinic_id">Clinic ID</Label>
              <Input id="clinic_id" onChange={this.onClinicIdChange} value={this.state.clinicId} />
              <FormText color="muted">Enter the Clinic ID for the Clinic you'd like to associate with this request.</FormText>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.close} disabled={this.state.loading}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.onSubmit} disabled={this.state.loading || !this.state.clinicId}>
              {this.state.loading ? <LoadingIcon /> : "Associate Clinic"}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.automapModal} toggle={this.toggleAutomap} className={this.props.className}>
          <ModalHeader toggle={this.closeAutomap}>Create Automapping</ModalHeader>
          <ModalBody>
            {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
            <Alert color="success">The request was associated with the clinic successfully</Alert>
            <p>Would you like to create an automatic mapping for:</p>
            <ul>
              <li>Lab: {this.state.lab}</li>
              <li>External Clinic ID: {this.state.externalClinicId}</li>
              <li>to Clinic: {this.state.clinic}</li>
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.closeAutomap} disabled={this.state.automapLoading}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.onAutomapSubmit} disabled={this.state.automapLoading}>
              {this.state.automapLoading ? <LoadingIcon /> : "Associate Clinic"}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
