import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";
import { LoadingButton } from "../../../components/LoadingButton";
import { Switch } from "../../../components/inputs/final_form/Switch";

export const EditClinicSupportedPayorModal = ({ clinicPayorPermission, onEdit, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const initialValues = { payorId: clinicPayorPermission.payor.id, reflexToPa: clinicPayorPermission.reflexToPa };

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (params) => {
    setError(null);
    try {
      const updateParams = { id: clinicPayorPermission.id, ...params };
      const res = await api.patch(`/admin/clinic_payor_permissions/${clinicPayorPermission.id}`, snakifyKeys(updateParams));

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Clinic Supported Payors</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          <Form onSubmit={handleEdit} initialValues={initialValues}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/payors.json"
                    label="Payor"
                    searchParam="name"
                    name="payorId"
                    required
                  />
                  <Field component={Switch} label="Reflex to PA" name="reflexToPa" id="reflex-to-pa" type="checkbox" />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Save
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
