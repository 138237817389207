import React from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Form, FormGroup, Input } from "reactstrap";
import { addCustomField, removeCustomField, updateCustomField } from "../store";
import { uniqid } from "../utils";
import { ValuesSelect } from "./ValuesSelect";

const newCustomField = () => ({
  id: uniqid(),
  label: "New Custom Field",
  type: "string",
});

const StringFieldForm = ({ fieldId, onChange, previewValue, required, fallbackValue, staticValue, defaultFields }) => (
  <div className="row">
    <div className="col-sm-12 col-md-6">
      <FormGroup>
        <Input bsSize="sm" type="select" value={required || ""} onChange={(e) => onChange(fieldId, e.target.value, "required")}>
          <option value="false">Not Required</option>
          <option value="true">Required</option>
        </Input>
      </FormGroup>
    </div>
    <div className="col-sm-12 col-md-6">
      <FormGroup>
        <Input
          bsSize="sm"
          type="text"
          value={previewValue || ""}
          placeholder="Preview Value"
          onChange={(e) => onChange(fieldId, e.target.value, "previewValue")}
        />
      </FormGroup>
    </div>
    <div className="col-sm-12">
      <FormGroup>
        <ValuesSelect
          value={fallbackValue}
          defaultFields={defaultFields}
          onChange={(values) => onChange(fieldId, values, "fallbackValue", true)}
          placeholder="Fallback Value..."
          isDisabled={staticValue}
        />
      </FormGroup>
    </div>
    <div className="col-sm-12">
      <FormGroup>
        <Input
          bsSize="sm"
          type="text"
          value={staticValue || ""}
          placeholder="Static Value"
          onChange={(e) => onChange(fieldId, e.target.value, "staticValue")}
        />
      </FormGroup>
    </div>
  </div>
);

const DropdownFieldForm = ({ fieldId, onChange, previewValue, required, includeBlank, selectOptions }) => (
  <div className="row">
    <div className="col-sm-12 col-md-6">
      <FormGroup>
        <Input bsSize="sm" type="select" value={required || ""} onChange={(e) => onChange(fieldId, e.target.value, "required")}>
          <option value="false">Not Required</option>
          <option value="true">Required</option>
        </Input>
      </FormGroup>
    </div>
    <div className="col-sm-12 col-md-6">
      <FormGroup>
        <Input bsSize="sm" type="select" value={includeBlank || "true"} onChange={(e) => onChange(fieldId, e.target.value, "includeBlank")}>
          <option value="true">Include Blank</option>
          <option value="false">Don't Include Blank</option>
        </Input>
      </FormGroup>
    </div>
    <div className="col-sm-12">
      <FormGroup>
        <Input
          bsSize="sm"
          type="text"
          value={selectOptions || ""}
          placeholder="Options"
          onChange={(e) => onChange(fieldId, e.target.value, "selectOptions")}
        />
      </FormGroup>
    </div>
    <div className="col-sm-12">
      <FormGroup>
        <strong>Preview</strong>
        <Input bsSize="sm" type="select" value={previewValue || ""} onChange={(e) => onChange(fieldId, e.target.value, "previewValue")}>
          {(includeBlank === "true" || includeBlank === undefined) && <option value=""></option>}
          {selectOptions && selectOptions.split(",").map((o) => <option value={o}>{o}</option>)}
        </Input>
      </FormGroup>
    </div>
  </div>
);

const DateFieldForm = ({ fieldId, onChange, previewValue, required }) => (
  <div className="row">
    <div className="col-sm-12 col-md-12">
      <FormGroup>
        <Input bsSize="sm" type="select" value={required || ""} onChange={(e) => onChange(fieldId, e.target.value, "required")}>
          <option value="false">Not Required</option>
          <option value="true">Required</option>
        </Input>
      </FormGroup>
    </div>
    <div className="col-sm-12 col-md-12">
      <FormGroup>
        <Input
          bsSize="sm"
          type="date"
          value={previewValue || ""}
          placeholder="Preview Value"
          onChange={(e) => onChange(fieldId, e.target.value, "previewValue")}
        />
      </FormGroup>
    </div>
  </div>
);

const BooleanFieldForm = ({ fieldId, onChange, previewValue }) => (
  <FormGroup>
    <Input bsSize="sm" type="select" value={previewValue || ""} onChange={(e) => onChange(fieldId, e.target.value, "previewValue")}>
      <option value="true">Preview: Checked</option>
      <option value="false">Preview: Unchecked</option>
    </Input>
  </FormGroup>
);

const onChangeType = (fieldId, type, onChange) => {
  onChange(fieldId, type, "type");
  if (type === "boolean") {
    onChange(fieldId, "true", "previewValue");
  }
};

const CustomFieldForm = ({
  fieldId,
  onRemove,
  onChange,
  label,
  type,
  previewValue,
  required,
  fallbackValue,
  staticValue,
  defaultFields,
  includeBlank,
  selectOptions,
}) => {
  return (
    <Card className="mb-2">
      <CardBody>
        <Form>
          <div className="row mb-0">
            <div className="col-sm-12 col-md-6">
              <FormGroup className="mb-0">
                <Input
                  bsSize="sm"
                  type="text"
                  value={label || ""}
                  placeholder="Label"
                  onChange={(e) => onChange(fieldId, e.target.value, "label")}
                />
              </FormGroup>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormGroup className="mb-0">
                <Input bsSize="sm" type="select" value={type || ""} onChange={(e) => onChangeType(fieldId, e.target.value, onChange)}>
                  <option value="string">Text</option>
                  <option value="boolean">Checkmark</option>
                  <option value="date">Date</option>
                  <option value="select">Dropdown</option>
                </Input>
              </FormGroup>
            </div>
          </div>

          <hr />

          {type === "boolean" && (
            <BooleanFieldForm
              fieldId={fieldId}
              onChange={onChange}
              label={label}
              type={type}
              previewValue={previewValue}
              required={required}
            />
          )}
          {type === "string" && (
            <StringFieldForm
              fieldId={fieldId}
              onChange={onChange}
              label={label}
              type={type}
              previewValue={previewValue}
              required={required}
              fallbackValue={fallbackValue}
              staticValue={staticValue}
              defaultFields={defaultFields}
            />
          )}
          {type === "date" && (
            <DateFieldForm
              fieldId={fieldId}
              onChange={onChange}
              label={label}
              type={type}
              previewValue={previewValue}
              required={required}
            />
          )}
          {type === "select" && (
            <DropdownFieldForm
              fieldId={fieldId}
              onChange={onChange}
              label={label}
              type={type}
              previewValue={previewValue}
              required={required}
              includeBlank={includeBlank}
              selectOptions={selectOptions}
            />
          )}

          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onRemove();
            }}
            className="text-danger"
          >
            Delete
          </a>
        </Form>
      </CardBody>
    </Card>
  );
};

class CustomFieldsEditorComponent extends React.Component {
  constructor(props) {
    super(props);

    this.updateFieldValue = this.updateFieldValue.bind(this);
  }

  updateFieldValue(id, val, path, overwrite = false) {
    const { onUpdateCustomField } = this.props;

    if (!_.isNil(val)) {
      onUpdateCustomField(id, _.set({}, path, val), overwrite);
    }
  }

  render() {
    const { onAddCustomField, customFields, onRemoveCustomField, defaultFields } = this.props;
    const customFieldForms = _.map(customFields, (c, i) => {
      return (
        <CustomFieldForm
          key={i}
          fieldId={c.id}
          label={c.label}
          type={c.type}
          options={c.options}
          previewValue={c.previewValue}
          selectOptions={c.selectOptions}
          required={c.required}
          includeBlank={c.includeBlank}
          fallbackValue={c.fallbackValue}
          staticValue={c.staticValue}
          onChange={this.updateFieldValue}
          defaultFields={defaultFields}
          onRemove={() => onRemoveCustomField(c.id)}
        />
      );
    });
    return (
      <React.Fragment>
        <Button size="sm" block onClick={() => onAddCustomField(uniqid(), newCustomField())}>
          Add Custom Field
        </Button>
        <br />
        {customFieldForms}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    customFields: state.customFields,
    defaultFields: state.defaultFields,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onAddCustomField: (key, data) => {
    dispatch(addCustomField({ key, data }));
  },
  onRemoveCustomField: (fieldId) => {
    if (confirm("Are you sure?")) {
      dispatch(removeCustomField({ fieldId }));
    }
  },
  onUpdateCustomField: (fieldId, data, assign) => {
    dispatch(updateCustomField({ fieldId, data, assign }));
  },
});

export const CustomFieldsEditor = connect(mapStateToProps, mapDispatchToProps)(CustomFieldsEditorComponent);
