import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { required } from "../../../util/validators";
import { AdministrateSelectInput } from "../../components/inputs/AdministrateSelectInput";

export const DiscardPayorModal = ({ onDelete, payorId, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleCreate = async ({ replacementPayorId }) => {
    setError(null);
    try {
      await api.delete("/admin/payors/discard_payor", { params: snakifyKeys({ discardPayor: { payorId, replacementPayorId } }) });

      if (onDelete) {
        onDelete();
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Delete
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Delete Payor</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleCreate}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field
                    required
                    validate={required}
                    component={AdministrateSelectInput}
                    resourceUrl="/admin/payors.json"
                    label="Replacement Payor"
                    name="replacementPayorId"
                    searchParam="name"
                  />

                  <LoadingButton loading={submitting} color="danger" onClick={handleSubmit} disabled={submitting}>
                    Delete
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
