import React, { useState } from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { DetailedErrorAlert } from "../../../components/DetailedErrorAlert";
import { LoadingButton } from "../../../components/LoadingButton";
import { adminApi as api } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";
import { Switch } from "../../../components/inputs/final_form/Switch";
import { TextInput } from "../../../components/inputs/final_form/TextInput";
import { required } from "../../../util/validators";

export const EditClinicTestMappingModal = ({ clinicTestMapping, onUpdate, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const initialValues = { providedTestName: clinicTestMapping.providedTestName, reflexToPa: clinicTestMapping.reflexToPa };

  const toggleModal = () => setOpen(!open);

  const handleUpdate = async (params) => {
    setError(null);

    try {
      const updateParams = { id: clinicTestMapping.id, ...params };
      const { data } = await api.patch(`/admin/clinic_test_mappings/${clinicTestMapping.id}`, snakifyKeys(updateParams));

      if (onUpdate) {
        onUpdate(`Updated ${data.resource.provided_test_name}`);
      }

      setOpen(false);
    } catch (err) {
      setError(err);
    }
  };

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Test Mapping</ModalHeader>

        <ModalBody>
          <DetailedErrorAlert error={error} />

          <Form onSubmit={handleUpdate} initialValues={initialValues}>
            {({ handleSubmit, submitting }) => {
              return (
                <>
                  <Field component={TextInput} label="Provided Test Name" name="providedTestName" validate={required} required />
                  <Field component={Switch} label="Reflex To PA" name="reflexToPa" id="reflex-to-pa" type="checkbox" />

                  <LoadingButton loading={submitting} onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </LoadingButton>
                </>
              );
            }}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
