import React from "react";
import { STEPS, ACTION_POSSIBLE_STEP } from "./screens/paper_steps";
import { Wizard } from "./Wizard";

const initialAnswers = {
  canTakeAction: null,
  additionalPayorFollowup: null,
  additionalFollowup: null,
  payorCallAnswered: null,
  payorHasReceivedSubmission: null,
  payorHasReachedDetermination: null,
  payorRequiresAdditionalInfo: null,
  payorHasSentDetermination: null,
  payorEstimatedTat: null,
  payorReferenceNum: null,
  patientHadCoverage: null,
};

export const PaperFollowupWizard = ({
  commentsUrl,
  snoozeUrl,
  tasksUrl,
  nextStepUrl,
  payorContacts,
  caseId,
  statusUrl,
  caseData,
  miEnabled,
}) => {
  return (
    <Wizard
      name="Paper Followup"
      initialStep={ACTION_POSSIBLE_STEP}
      caseId={caseId}
      steps={STEPS}
      initialAnswers={initialAnswers}
      commentsUrl={commentsUrl}
      snoozeUrl={snoozeUrl}
      tasksUrl={tasksUrl}
      nextStepUrl={nextStepUrl}
      payorContacts={payorContacts}
      statusUrl={statusUrl}
      caseData={caseData}
      miEnabled={miEnabled}
    />
  );
};
