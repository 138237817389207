import { useMemo } from "react";
import { DESTROY, UPDATE, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { snakifyKeys } from "../../../util/helpers";
import { CreatePayorNoteModal } from "../components/CreatePayorNoteModal";
import { PayorNoteActionCell } from "../components/PayorNoteActionCell";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable } from "../../components/AdministrateTable";
import { DateCell } from "../../../components/grid/cells/DateCell";
import { DateFilter } from "../../../components/inputs/DateFilter";
import { ImportPayorNotesModal } from "../components/ImportPayorNotesModal";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";

export const TextBoxCell = ({ note }) => {
  return (
    <blockquote className="blockquote">
      <p className="text-wrap">{note}</p>
    </blockquote>
  );
};

const useColumns = makeColumns((payorId) => [
  {
    id: "id",
    accessor: "id",
    Header: "ID",
    maxWidth: 15,
  },
  {
    id: "note",
    accessor: "note",
    Header: "Note",
    Cell: ({ row }) => <TextBoxCell note={row.values?.note} />,
  },
  {
    id: "created_at",
    accessor: "createdAt",
    Header: "Created At",
    maxWidth: 25,
    Filter: (props) => <DateFilter {...props} />,
    Cell: ({ row }) => <DateCell value={row.values?.created_at} />,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: ({ row }) => <PayorNoteActionCell payorId={payorId} {...row} />,
    disableFilters: true,
    disableSortBy: true,
    maxWidth: 40,
  },
]);

export const PayorNotesTab = ({ payor, payorId }) => {
  const [canCreate] = useCreatePermission("PayorNote");
  const columns = useColumns(payorId);

  const extraParams = useMemo(() => snakifyKeys({ payorId }), [payorId]);
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onCreate = (newPayorNote) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created new Payor Note ${newPayorNote.id}`);
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <AdministrateBackgroundExportButton
          outline
          className="me-2"
          exportUrl="/admin/payor_notes/export"
          filters={allFilters}
          ref={tableRef}
        />

        {canCreate && <ImportPayorNotesModal payorId={payorId} outline className="me-2" onImport={onImport} />}

        {canCreate && <CreatePayorNoteModal color="primary" onCreate={onCreate} payorId={payorId} />}
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/payor_notes.json?payor_id=${payorId}`}
        resourceName={"payor_notes"}
        permissions={[DESTROY, UPDATE]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
        striped
      />
    </>
  );
};
