import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { EnumFilter } from "../../components/inputs/EnumFilter";
import { Switch } from "../../components/inputs/Switch";
import { Toolbar } from "../../components/Toolbar";
import { redirectTo } from "../../util/api";
import { GLOBAL_ROLES } from "../../util/data/global_roles";
import { objectToOptions } from "../../util/formatters";
import { deepCompact } from "../../util/helpers";
import { AdministrateBackgroundExportButton } from "../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../components/AdministrateTable";
import { SystemConfigLayout } from "../components/SystemConfigLayout";
import { Routes } from "../Routes";
import { makeColumns, useAdministrateTable, useUpdatePermission } from "../utils";
import { CreateUserModal } from "./components/CreateUserModal";
import { UsersActionCell } from "./components/UsersActionCell";
import { ImportUsersModal } from "./components/ImportUsersModal";

const useColumns = makeColumns((canUpdateUsers) =>
  [
    {
      id: "id",
      accessor: "id",
      Header: "ID",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ value, row }) => (
        <Link to={Routes.user.url(row.original.id)}>
          <span className="token">{value}</span>
        </Link>
      ),
    },
    {
      id: "users.email",
      accessor: "email",
      Header: "Email",
      disableSortBy: true,
    },
    {
      id: "profiles.first_name",
      accessor: "firstName",
      Header: "First Name",
      disableSortBy: true,
      width: 100,
    },
    {
      id: "profiles.last_name",
      accessor: "lastName",
      Header: "Last Name",
      disableSortBy: true,
    },
    {
      id: "global_role",
      accessor: "readableGlobalRole",
      Header: "Role",
      Filter: (props) => <EnumFilter {...props} options={objectToOptions(GLOBAL_ROLES)} />,
      disableSortBy: true,
      width: 80,
    },
    {
      id: "actions",
      Header: "Actions",
      Cell: UsersActionCell,
      disableFilters: true,
      disableSortBy: true,
    },
  ].filter((col) => col.id !== "actions" || canUpdateUsers)
);

export const UsersIndexPage = () => {
  const [canUpdateUsers] = useUpdatePermission("Account");

  const isOpsSupervisor = window.currentAccountGlobalRole === "operations_supervisor";

  const columns = useColumns(canUpdateUsers);

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);

    refresh();
  };

  const onCreate = (data) => {
    const { refresh, setMessage } = tableRef.current;
    setMessage(data.msg);
    redirectTo(`/admin/system/users/${data.id}/general`);
  };

  const [active, setActive] = useState(undefined);
  const extraParams = useMemo(() => deepCompact({ active }), [active]);
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  return (
    <SystemConfigLayout title="Users">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <Switch
            id="show-discarded-toggle"
            label="Show Only Active Users"
            checked={active}
            className="me-3"
            onChange={() => {
              setActive(active ? undefined : true);
            }}
          />

          {canUpdateUsers && (
            <>
              <ImportUsersModal outline className="me-2" onImport={onImport} />
              <AdministrateBackgroundExportButton
                outline
                className="me-2"
                exportUrl="/admin/accounts/export"
                filters={allFilters}
                ref={tableRef}
              />
              <CreateUserModal color="primary" onCreate={onCreate} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/accounts.json?only_operations_and_supervisors=${isOpsSupervisor}`}
        resourceName={"account"}
        extraParams={extraParams}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
        defaultFiltered={
          isOpsSupervisor
            ? [
                {
                  id: "global_role",
                  value: "operations",
                },
              ]
            : []
        }
      />
    </SystemConfigLayout>
  );
};
