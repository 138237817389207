import { useCallback, useState } from "react";
import { Switch } from "../../../components/inputs/Switch";
import { adminApi, extractErrorMessage } from "../../../util/api";
import { snakifyKeys } from "../../../util/helpers";

export const PayorReflexToPAToggle = ({ clinicPayorPermission, payorName, reflexToPa, onChange, readOnly }) => {
  const [loading, setLoading] = useState(false);
  const label = loading ? "Loading..." : reflexToPa ? "Yes" : "No";

  const getUpdateMessage = useCallback(
    (reflexToPa) => `Set Reflex To PA for payor ${payorName} as ${reflexToPa ? "Yes" : "No"}`,
    [payorName]
  );

  const handleUpdate = useCallback(
    async (e) => {
      setLoading(true);
      const reflexToPa = e.target.checked;

      try {
        await adminApi.put(`/admin/clinic_payor_permissions/${clinicPayorPermission.id}`, snakifyKeys({ reflexToPa: reflexToPa }));

        onChange(getUpdateMessage(reflexToPa));
      } catch (err) {
        onError(extractErrorMessage(err));
      } finally {
        setLoading(false);
      }
    },
    [clinicPayorPermission, onChange, getUpdateMessage]
  );

  return (
    <Switch
      disabled={readOnly || loading}
      id={`reflex_to_pa${clinicPayorPermission.id}`}
      checked={Boolean(reflexToPa)}
      label={label}
      onChange={handleUpdate}
    />
  );
};
