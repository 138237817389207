import { useMemo, useState } from "react";
import { Toolbar } from "../../../../components/Toolbar";
import { deepCompact } from "../../../../util/helpers";
import { AdministrateEnumFilter } from "../../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../../components/AdministrateBackgroundExportButton";
import { AdministrateSelect } from "../../../components/AdministrateSelect";
import { AdministrateTable } from "../../../components/AdministrateTable";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../../utils";
import { CreatePayorMemberPrefixModal } from "../../components/CreatePayorMemberPrefixModal";
import { ImportPayorMemberPrefixesModal } from "../../components/ImportPayorMemberPrefixesModal";
import { PayorMemberPrefixActionCell } from "../../components/PayorMemberPrefixActionCell";

const useColumns = makeColumns(() => [
  {
    id: "payor.id",
    accessor: "payor.name",
    Header: "Payor",
    disableSortBy: true,
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="id" labelKey="name" searchParam="name" />
    ),
  },
  {
    id: "member_id_prefix_regex",
    accessor: "memberIdPrefixRegex",
    Header: "Pattern",
    disableSortBy: true,
  },
  {
    id: "member_id_prefix",
    accessor: "memberIdPrefix",
    Header: "Prefix",
    disableSortBy: true,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: PayorMemberPrefixActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const PayorMemberPrefixesTab = () => {
  const [canCreate] = useCreatePermission("PayorPrefixMapping");

  const columns = useColumns();
  const [selectedPayorGroupId, setSelectedPayorGroupId] = useState(null);

  const extraParams = useMemo(() => deepCompact({ payorGroupId: selectedPayorGroupId }), [selectedPayorGroupId]);
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  const onAdd = (newMapping) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created new ${newMapping.dashboard_display_name}`);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar>
          <div style={{ width: 300 }}>
            <AdministrateSelect
              placeholder="Filter by Payor Group..."
              resourceUrl="/admin/payor_groups.json"
              onChange={setSelectedPayorGroupId}
              className="me-2"
            />
          </div>
        </Toolbar>

        {canCreate && (
          <Toolbar className="ms-auto">
            <AdministrateBackgroundExportButton
              outline
              className="me-2"
              exportUrl="/admin/payor_prefix_mappings/export"
              filters={allFilters}
              ref={tableRef}
            />
            <ImportPayorMemberPrefixesModal outline className="me-2" onImport={onImport} />
            <CreatePayorMemberPrefixModal color="primary" onAdd={onAdd} />
          </Toolbar>
        )}
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/payor_prefix_mappings.json`}
        resourceName={"payor_prefix_mappings"}
        columns={columns}
        ref={tableRef}
        onFilteredChange={setTableFilters}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
      />
    </>
  );
};
