import cn from "classnames";
import { isFunction } from "lodash";
import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Icon from "./Icon";

// Reactstrap exposes an UncontrolledDropdown,
//  but we want to use a Lucide icon for the caret
export const UncontrolledDropdown = ({ buttonText, children, className, canClose, ...rest }) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => {
    if (!isFunction(canClose) || canClose()) {
      setOpen((prev) => !prev);
    }
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} className={cn("d-inline-flex", "align-items-center", className)} {...rest}>
      <DropdownToggle>
        {buttonText}
        <Icon className="icon ms-1" name={isOpen ? "ChevronUp" : "ChevronDown"} size={16} />
      </DropdownToggle>
      <DropdownMenu>{children}</DropdownMenu>
    </Dropdown>
  );
};
