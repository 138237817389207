import { Link } from "react-router-dom";
import { Toolbar } from "../../../components/Toolbar";
import { AdministrateTable } from "../../components/AdministrateTable";
import { Routes } from "../../Routes";
import { DESTROY, makeColumns, useAdministrateTable, useCreatePermission } from "../../utils";
import { AddPortalModal } from "../components/AddPortalModal";
import { PayorPortalManagementActionCell } from "../components/PayorPortalManagementActionCell";

const findMappingId = (mappings, payorId) => mappings.find((m) => m.payor_id == payorId)?.id;

const useColumns = makeColumns((payorId) => [
  {
    id: "name",
    accessor: "name",
    Header: "Name",
    Cell: ({ row, value }) => <Link to={Routes.portal.url(row.original.id)}>{value}</Link>,
  },
  {
    id: "actions",
    Header: "Actions",
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row }) => {
      return <PayorPortalManagementActionCell mappingId={findMappingId(row.original.payorPortalMappings, payorId)} {...row} />;
    },
  },
]);

export const PayorsPortalManagementTab = ({ payor, payorId }) => {
  const columns = useColumns(payorId);
  const extraParams = { payors: { id: payorId } };
  const { tableRef, setTableFilters } = useAdministrateTable(extraParams);
  const [canCreate] = useCreatePermission("PayorPortalMapping");

  const onAddPortal = (message) => {
    const { refresh, setMessage } = tableRef.current;
    setMessage(message);
    refresh();
  };

  return (
    <>
      <Toolbar className="align-items-center mb-3">
        <Toolbar>
          <div style={{ width: 300 }}></div>
        </Toolbar>

        <Toolbar className="ms-auto">{canCreate && <AddPortalModal payor={payor} color="primary" onAdd={onAddPortal} />}</Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={"/admin/benefit_management_portals.json"}
        extraParams={extraParams}
        permissions={[DESTROY]}
        resourceName={"benefit_management_portal"}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </>
  );
};
