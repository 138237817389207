import { TokenCell } from "../../../components/grid/cells/TokenCell";
import { Toolbar } from "../../../components/Toolbar";
import { formatCentsAsDollars } from "../../../util/formatters";
import { AdministrateEnumFilter } from "../../components/AdministrateEnumFilter";
import { AdministrateBackgroundExportButton } from "../../components/AdministrateBackgroundExportButton";
import { AdministrateTable } from "../../components/AdministrateTable";
import { SystemConfigLayout } from "../../components/SystemConfigLayout";
import { DESTROY, makeColumns, UPDATE, useAdministrateTable, useCreatePermission } from "../../utils";
import { CreateLabFeeScheduleByPayorModal } from "../components/CreateLabFeeScheduleByPayorModal";
import { ImportLabFeeScheduleByPayorModal } from "../components/ImportLabFeeScheduleByPayorModal";
import { LabFeeScheduleByPayorActionCell } from "../components/LabFeeScheduleByPayorActionCell";

const formatMedicarePercentage = (percent) => (percent ? `${percent}%` : null);

const useColumns = makeColumns(() => [
  {
    id: "payor.name",
    accessor: "payor.name",
    Header: "Payor",
    disableSortBy: true,
    Filter: (props) => (
      <AdministrateEnumFilter {...props} resourceUrl="/admin/payors.json" valueKey="name" labelKey="name" searchParam="name" />
    ),
  },
  {
    id: "cpt_code.code",
    accessor: "cptCode.code",
    Header: "CPT Code",
    Cell: TokenCell,
    Filter: (props) => <AdministrateEnumFilter {...props} resourceUrl="/admin/cpt_codes.json" valueKey="code" labelKey="code" />,
  },
  {
    id: "medicare_percentage",
    accessor: "medicarePercentage",
    Header: "Medicare Percentage",
    Cell: ({ value }) => <span>{formatMedicarePercentage(value)}</span>,
    disableFilters: true,
  },
  {
    id: "price_cents",
    accessor: "priceCents",
    Header: "Price",
    Cell: ({ value }) => <span>{formatCentsAsDollars(value)}</span>,
    disableFilters: true,
  },
  {
    id: "actions",
    Header: "Actions",
    Cell: LabFeeScheduleByPayorActionCell,
    disableFilters: true,
    disableSortBy: true,
  },
]);

export const LabsFeeScheduleByPayorTab = ({ labId, lab }) => {
  const extraParams = { lab: { id: labId } };
  const { tableRef, allFilters, setTableFilters } = useAdministrateTable(extraParams);
  const [canCreate] = useCreatePermission("CptFeeSchedule");

  const columns = useColumns();

  const onCreate = (newFeeSchedule) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(`Created Cpt Fee Schedule By ${newFeeSchedule.payor.name} for CPT Code ${newFeeSchedule.cpt_code.code}`);
    refresh();
  };

  const onImport = (message, details = []) => {
    const { refresh, setMessage } = tableRef.current;

    setMessage(message, details.length ? "warning" : "success", details);
    refresh();
  };

  return (
    <SystemConfigLayout title="Cpt Fee Schedule By Payor">
      <Toolbar className="align-items-center mb-3">
        <Toolbar className="ms-auto">
          <AdministrateBackgroundExportButton
            outline
            className="me-2"
            exportUrl="/admin/cpt_fee_schedules/export"
            filters={allFilters}
            ref={tableRef}
          />

          {canCreate && (
            <>
              <ImportLabFeeScheduleByPayorModal labId={labId} outline className="me-2" onImport={onImport} />
              <CreateLabFeeScheduleByPayorModal onCreate={onCreate} labId={labId} />
            </>
          )}
        </Toolbar>
      </Toolbar>

      <AdministrateTable
        resourceUrl={`/admin/cpt_fee_schedules.json`}
        resourceName={"cpt_fee_schedule"}
        extraParams={extraParams}
        permissions={[UPDATE, DESTROY]}
        onFilteredChange={setTableFilters}
        columns={columns}
        ref={tableRef}
      />
    </SystemConfigLayout>
  );
};
