import classnames from "classnames";
import { AlertCircle } from "lucide-react";
import React from "react";
import { Field, FormSpy } from "react-final-form";
import { FormText } from "reactstrap";

const dotNotationForObject = (obj) => {
  let keys = [];

  _.forEach(obj, (v, k) => {
    if (_.isPlainObject(v)) {
      const subKeys = dotNotationForObject(v);
      const mappedKeys = subKeys.map((sk) => `${k}.${sk}`);
      keys = _.concat(keys, mappedKeys);
    } else {
      keys.push(k);
    }
  });

  return keys;
};

export const WarningEngine = ({ mutators: { setFieldData }, validate }) => (
  <FormSpy
    onChange={({ values }) => {
      const keys = dotNotationForObject(values);
      const validatedValues = validate(values);

      _.each(keys, (k) => setFieldData(k, { warning: undefined }));
      _.each(validatedValues, (message, key) => {
        setFieldData(key, { warning: message });
      });
    }}
  />
);

export const WarningField = ({ component, children, ...props }) => (
  <Field {...props}>
    {({ className, ...inputProps }) => {
      const Component = component;
      return (
        <Component
          {...inputProps}
          className={classnames(className, {
            "is-warning": !!inputProps?.meta?.data?.warning,
          })}
        >
          {inputProps?.meta?.data?.warning && (
            <FormText color="warning">
              <AlertCircle className="me-2" />
              {inputProps.meta.data.warning}
            </FormText>
          )}
          {children}
        </Component>
      );
    }}
  </Field>
);
