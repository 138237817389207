import React from "react";
import { ChartDonut, ChartThemeColor } from "@patternfly/react-charts";

export const DonutChart = ({ color, data, height, width, legend, ...props }) => {
  const THEME_COLOR_MAP = { primary: "blue", secondary: "gray", warning: "multi", success: "green", danger: "orange", info: "blue" };
  const themeColor = THEME_COLOR_MAP[color];

  const legendData =
    legend ||
    data.map((d) => {
      return { name: `${d.y * 100}% ${d.x}` };
    });

  // TODO: should use our util number formatter to format as a percent
  return (
    <ChartDonut
      constrainToVisibleArea
      data={data}
      themeColor={themeColor}
      height={height}
      labels={({ datum }) => `${datum.x}: ${datum.y * 100}%`}
      legendData={legendData}
      legendOrientation="vertical"
      legendPosition="right"
      width={width}
      {...props}
    />
  );
};
