import Form from "@rjsf/core";
import { api } from "../util/api";
import { getPrecompiledValidator } from "./precompiled_validator";
import React, { useCallback, useEffect, useState } from "react";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { LoadingIcon } from "../components/LoadingIcon";

const FillForm = ({ schema, uiSchema, schemaFixed, formData, onDataSubmit, onDataDelete, validator }) => (
  <>
    {Boolean(schemaFixed) && (
      <div className="alert alert-info">
        This form has custom fields and data associated with it. To change the form, you need to clear the existing data.{" "}
        <a href="#" className="alert-link" onClick={onDataDelete}>
          Clear Custom Data
        </a>
      </div>
    )}

    <Form
      schema={schema || defaultSchema}
      uiSchema={{
        ...uiSchema,
        "ui:submitButtonOptions": {
          props: {
            className: "mt-3",
          },
          submitText: "Save & Continue",
        },
      }}
      formData={formData}
      onSubmit={onDataSubmit}
      validator={validator}
    />
  </>
);

export const PayorRequiredFormFiller = ({
  schema,
  uiSchema,
  formData,
  documentFilled,
  createDocumentUrl,
  documentUrl,
  documentId,
  schemaPrecompiledValidatorUrl,
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [precompiledValidator, setPrecompiledValidator] = useState({});

  const onDataSubmit = useCallback(
    async ({ formData = {} }) => {
      const url = documentFilled ? documentUrl : createDocumentUrl;
      const method = documentFilled ? "put" : "post";

      try {
        setLoading(true);
        setError(null);

        await api[method](url, {
          data: formData,
          fillable_document_id: documentId,
        });

        const nextButtons = document.querySelectorAll(".next-button");

        if (nextButtons.length > 0) {
          nextButtons[0].click();
        } else {
          window.location.reload();
        }
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    },
    [documentFilled, documentUrl, createDocumentUrl, documentId]
  );

  const onDataDelete = useCallback(async () => {
    if (confirm("Are you sure you want to change forms? All entered form data will be lost.")) {
      setLoading(true);

      try {
        await api.delete(documentUrl);
        window.location.reload();
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
  }, [documentUrl]);

  useEffect(() => {
    const fetchValidator = async () => {
      const newValidator = await getPrecompiledValidator(documentId, schemaPrecompiledValidatorUrl, schema);
      setPrecompiledValidator(newValidator);
    };

    if (documentId) {
      fetchValidator();
    }
  }, [documentId, schemaPrecompiledValidatorUrl, schema]);

  return (
    <div>
      <DetailedErrorAlert error={error} />

      {loading ? (
        <LoadingIcon />
      ) : (
        <FillForm
          schema={schema}
          uiSchema={uiSchema}
          formData={formData}
          onDataSubmit={onDataSubmit}
          schemaFixed={documentFilled}
          onDataDelete={onDataDelete}
          validator={precompiledValidator}
        />
      )}
    </div>
  );
};
