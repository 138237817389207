import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { YesNoPanel } from "../../../components/wizards/YesNoPanel";
import { WizardContext } from "../WizardContext";

export const CallPayorScreen = () => (
  <WizardContext.Consumer>
    {({ dispatch, caseData, payorContacts }) => (
      <>
        <YesNoPanel
          yesAction={() => {
            dispatch({
              type: "setAnswer",
              key: "payorCallAnswered",
              value: true,
              doTransition: true,
            });
          }}
          noAction={() => {
            dispatch({
              type: "setAnswer",
              key: "payorCallAnswered",
              value: false,
              doTransition: true,
            });
          }}
          yesLabel="Call Answered"
          noLabel="No Answer"
        >
          <h5 className="mb-5">
            Call Payor <strong>{caseData.verifiedPayorName}</strong>
          </h5>

          {payorContacts && (
            <ListGroup>
              {payorContacts.map((p) => (
                <ListGroupItem key={p.id} tag="a" href={`tel:${p.phone_number}`} className="text-start">
                  <strong>{p.title}:</strong> {p.phone_number}
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
        </YesNoPanel>
      </>
    )}
  </WizardContext.Consumer>
);
