import React, { useMemo, useState } from "react";
import { DetailedErrorAlert } from "../components/DetailedErrorAlert";
import { FormWizard } from "../components/forms/FormWizard";
import { FormWizardPage } from "../components/forms/FormWizardPage";
import { api, redirectTo } from "../util/api";
import {
  clearOrderedWorkflowsMutator,
  setDefaultOrderedWorkflowsMutator,
  setPregnancyMutator,
} from "./PatientAndInsuranceInfoHelperMethods";
import { PatientAndInsuranceInfoFields } from "./PatientAndInsuranceInfoFields";
import { ClinicProviderInfoFields } from "./ClinicProviderInfoFields";
import { ProviderInfoFields, setNpiDataMutator } from "./ProviderInfoFields";
import { TestAndRequestInfoFields } from "./TestAndRequestInfoFields";
import { setCptCodesMutator, setTestsMutator, moveUploadedDocumentsMutator } from "./TestAndRequestInfoHelperMethods";
import { Alert } from "reactstrap";
import { ArrowRight, Check } from "lucide-react";

export const NewCaseForm = ({
  updateUrl,
  createUrl,
  draftId,
  draftData,
  hasDraft = false,
  isClinicUser = false,
  requestingProvider,
  clinicProvidersUrl,
  orderableWorkflows,
  isRequestingProviderTypePharmacy = false,
  hasOrderingLabs,
  externalUserLabId,
  uninsuredPatientPayor,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const pageParam = parseInt(urlParams.get("page"), 10);
  const [resourceId, setResourceId] = useState(draftId);
  const [doUpdates, setDoUpdates] = useState(hasDraft);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);

  const onSave = async (v, page) => {
    await handleSave(v, doUpdates, false, page);
    setDoUpdates(true);
  };

  const onPublish = async (v, page) => {
    await handleSave({ ...v, submit: true }, doUpdates, true, page);
    setDoUpdates(true);
  };

  const handleSave = async (v, isUpdate = false, doRedirect = false, page = null) => {
    const method = isUpdate ? "put" : "post";
    // This ugliness is because we never update the data-draft-id or data-update-url or the
    // state values in the root div/component when doing a client-side next page load.
    const saveUrl = isUpdate ? updateUrl.replace("__ID__", resourceId) : createUrl;

    try {
      setError(null);
      const res = await api[method](saveUrl, v);
      const { resourceId, nextUrl, message } = res.data;

      if (resourceId) {
        setResourceId(resourceId);
      }

      if (nextUrl) {
        if (doRedirect) {
          const messageStyle = res.status === 201 ? "success" : "info";
          redirectTo(nextUrl, message, messageStyle);
        } else {
          history.pushState(null, "", `${nextUrl}?page=${page}`);
        }
      }
    } catch (err) {
      setError(err);
    }
  };

  // draftData and orderingLabs need "complex" comparison to identify if they've changed;
  // we could make the linter happy by making the code harder to read, but that's not particularly desirable.
  const initialValues = useMemo(
    () => (externalUserLabId ? { labId: externalUserLabId, ...draftData } : draftData),
    [JSON.stringify(draftData), externalUserLabId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <DetailedErrorAlert error={error} fallbackMessage="There was an error while saving." />

      {message && (
        <Alert color="success" className="mb-3">
          {message}
        </Alert>
      )}

      <FormWizard
        wizardTitle="Request New Case"
        initialValues={initialValues}
        initialPage={pageParam ?? 0}
        mutators={{
          setNpiDataMutator,
          setTestsMutator,
          setCptCodesMutator,
          setPregnancyMutator,
          clearOrderedWorkflowsMutator,
          setDefaultOrderedWorkflowsMutator,
          moveUploadedDocumentsMutator,
        }}
        onPageTransitionSuccess={(form, values) => {
          const newDocuments = _.get(values, "supplementalDocuments.newFiles", []);

          if (newDocuments.length > 0) {
            form.mutators.moveUploadedDocumentsMutator(newDocuments);
          }
        }}
      >
        <FormWizardPage
          pageName="Patient &amp; Insurance Info"
          onPrevPage={onSave}
          onNextPage={onSave}
          saveButtonColor={"primary"}
          saveButtonLabel="Save &amp; Continue"
          saveButtonIcon={<ArrowRight className="ms-2" />}
        >
          {(formProps) => {
            return (
              <>
                <PatientAndInsuranceInfoFields
                  isClinicUser={isClinicUser}
                  defaultOrderableWorkflows={orderableWorkflows}
                  isRequestingProviderTypePharmacy={isRequestingProviderTypePharmacy}
                  hasOrderingLabs={hasOrderingLabs}
                  uninsuredPatientPayor={uninsuredPatientPayor}
                  {...formProps}
                />
              </>
            );
          }}
        </FormWizardPage>

        {!(isClinicUser && isRequestingProviderTypePharmacy) && (
          <FormWizardPage
            pageName="Provider & Practice Info"
            onPrevPage={onSave}
            onNextPage={onSave}
            saveButtonColor={"primary"}
            saveButtonLabel="Save &amp; Continue"
            saveButtonIcon={<ArrowRight className="ms-2" />}
          >
            {(formProps) => {
              if (clinicProvidersUrl) {
                return <ClinicProviderInfoFields clinicProvidersUrl={clinicProvidersUrl} {...formProps} />;
              } else {
                return <ProviderInfoFields {...formProps} />;
              }
            }}
          </FormWizardPage>
        )}

        <FormWizardPage
          pageName="Test &amp; Request Docs"
          onPrevPage={onSave}
          onNextPage={onPublish}
          saveButtonColor={"success"}
          saveButtonLabel="Submit"
          saveButtonIcon={<Check className="ms-2" />}
        >
          {(formProps) => {
            return (
              <>
                <TestAndRequestInfoFields
                  isClinicUser={isClinicUser}
                  isRequestingProviderTypePharmacy={isRequestingProviderTypePharmacy}
                  requestingProvider={requestingProvider}
                  {...formProps}
                />
              </>
            );
          }}
        </FormWizardPage>
      </FormWizard>
    </>
  );
};
