import { createRoot } from "react-dom/client";
import { LabFixForm } from "../../lab_fix/LabFixForm";

export const initLabFixForm = () => {
  const labFixFormContainer = document.getElementById("lab_fix");

  if (labFixFormContainer) {
    const root = createRoot(labFixFormContainer);
    const { caseId, caseUrl, saveUrl } = labFixFormContainer.dataset;
    // const parsedDraftId = draftId ? JSON.parse(draftId) : null;
    // const parsedDraftData = draftData ? JSON.parse(draftData) : null;

    root.render(<LabFixForm caseId={caseId} caseUrl={caseUrl} saveUrl={saveUrl} />);
  }
};
