import Case from "case";
import _ from "lodash";
import { Download, Trash } from "lucide-react";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Button, List, ListGroup, ListGroupItem, ListInlineItem, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LoadingButton } from "../components/LoadingButton";
import { api } from "../util/api";
import { formatDateTime } from "../util/formatters";
import { camelizeKeys } from "../util/helpers";
import { trackEvent } from "../util/track";
import { EventMiniBus } from "../util/event-bus";

const canPreview = (attachment) => attachment.mimetype.includes("image") || attachment.mimetype.includes("pdf");

const getPreviewSrc = (caseId, attachment) => {
  return `/request_attachments/${caseId}/pdf_preview/${attachment.publicId}?attachment_type=${attachment.attachmentType}#toolbar=0`;
};

const attachmentIsPdf = (attachment) => {
  return attachment.mimetype.includes("pdf");
};

const AttachmentPreviewer = ({ attachment, caseId }) => {
  return (
    <>
      {attachmentIsPdf(attachment) ? (
        <div className="ratio ratio-letter">
          <iframe src={getPreviewSrc(caseId, attachment)} />
        </div>
      ) : (
        <img className="w-100" src={attachment.url} />
      )}
    </>
  );
};

const AttachmentRow = ({ attachment, onClick, allowDelete }) => {
  const [deleting, setDeleting] = useState(false);
  const showPreview = canPreview(attachment);

  const handleDelete = useCallback(async () => {
    setDeleting(true);

    try {
      await api.delete(attachment.removeUrl);
    } catch (err) {
      console.error(err);
    } finally {
      window.location.reload();
      setDeleting(false);
    }
  }, [attachment.removeUrl]);

  return (
    <>
      <ListGroupItem className="d-flex align-items-center">
        <header className="d-block text-truncate w-100">
          <h6 className="mb-1" style={{ maxWidth: "13rem" }}>
            {showPreview ? (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onClick(attachment.publicId);
                }}
              >
                <code>{attachment.label}</code>
              </a>
            ) : (
              <a href={attachment.url} target="_blank" rel="noopener noreferrer" download>
                <code>{attachment.label}</code>
              </a>
            )}
          </h6>

          <div className="small text-muted">
            {Case.title(attachment.category === "prior_auth_requests" ? "prior_auth_results" : attachment.category)}
          </div>

          {attachment.uploadedAt && <div className="small text-muted">{formatDateTime(attachment.uploadedAt)}</div>}

          {attachment.uploader && <div className="small text-muted">{attachment.uploader}</div>}
        </header>

        {allowDelete && attachment.removeUrl && (
          <LoadingButton loading={deleting} size="sm" outline color="danger" onClick={handleDelete} className="ms-3">
            <Trash size={12} />
          </LoadingButton>
        )}
      </ListGroupItem>
    </>
  );
};

const CaseAttachmentList = ({ caseId, attachmentCategories, allowDelete, autoPopUpEnabled, shouldOfferPriceComparison }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewId, setPreviewId] = useState(null);
  const [offerOpen, setOfferOpen] = useState(true);

  const handleModalToggle = () => setPreviewOpen((o) => !o);

  const handlePreview = (attachmentId) => {
    setPreviewOpen(true);
    setPreviewId(attachmentId);
  };

  const allAttachments = useMemo(
    () => Object.values(attachmentCategories).flatMap((atts) => atts.map((a) => camelizeKeys(a))),
    [attachmentCategories]
  );

  const previewableAttachments = useMemo(() => allAttachments.filter((a) => canPreview(a)), [allAttachments]);

  const previewingAttachment = useMemo(
    () => previewableAttachments?.find((a) => a.publicId === previewId),
    [previewId, previewableAttachments]
  );

  const handlePreviousAttachment = () => {
    const currentIdx = previewableAttachments.findIndex((a) => a.publicId === previewId);
    const prevIdx = Math.max(currentIdx - 1, 0);

    setPreviewId(previewableAttachments[prevIdx]?.publicId);
  };

  const handleNextAttachment = () => {
    const currentIdx = previewableAttachments.findIndex((a) => a.publicId === previewId);
    const nextIdx = Math.min(currentIdx + 1, previewableAttachments.length - 1);

    setPreviewId(previewableAttachments[nextIdx]?.publicId);
  };

  useEffect(() => {
    const lastBiResultPreview = allAttachments.filter((att) => att.category === "benefits_investigation_attachment");

    if (autoPopUpEnabled) {
      setTimeout(() => {
        handlePreview(lastBiResultPreview[lastBiResultPreview.length - 1].publicId);
      }, 500);
    }
  }, [autoPopUpEnabled, allAttachments]);

  const handlePriceComparisonOffer = (choice) => {
    setOfferOpen((o) => !o);

    if (!choice) {
      // Track decision for see the offer
      handleModalToggle();
      return;
    }

    handleModalToggle();
    trackEvent("PRICE_COMPARISON_OFFER_ACCEPTED", caseId, { data: "price_comparison_viewed" });
    EventMiniBus.dispatch("comparison:open", true);
  };

  return (
    <>
      <ListGroup flush>
        {Object.keys(attachmentCategories).length > 0 ? (
          <>
            {_.map(attachmentCategories, (attachments, category) => {
              return (
                <React.Fragment key={category}>
                  <ListGroupItem className="small" color="light">
                    <strong>{Case.title(category === "priorAuthRequests" ? "priorAuthResults" : category)}</strong>
                  </ListGroupItem>

                  {attachments.map((attachment) => (
                    <AttachmentRow
                      onClick={handlePreview}
                      attachment={camelizeKeys(attachment)}
                      key={attachment.publicId}
                      allowDelete={allowDelete}
                    />
                  ))}
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <ListGroupItem className="text-muted">No attachments</ListGroupItem>
        )}
      </ListGroup>

      <Modal isOpen={previewOpen} size="lg" toggle={handleModalToggle}>
        <ModalHeader toggle={handleModalToggle}>
          <div className="d-flex align-items-center">
            Attachment Preview
            {previewingAttachment && (
              <a className="ms-3 d-inline-block" href={previewingAttachment.url} download>
                <Download />
              </a>
            )}
          </div>
        </ModalHeader>
        <ModalBody>
          {previewingAttachment && (
            <>
              <AttachmentPreviewer attachment={previewingAttachment} caseId={caseId} />
              {shouldOfferPriceComparison && (
                <Modal isOpen={offerOpen} toggle={handlePriceComparisonOffer} className="modal-offer" backdrop="static" centered>
                  <ModalBody>The Laboratory chosen is Out Of Network, would you like to see In-Network alternatives?</ModalBody>

                  <ModalFooter>
                    <Button onClick={() => handlePriceComparisonOffer(true)} color="primary">
                      Yes
                    </Button>
                    <Button onClick={() => handlePriceComparisonOffer(false)}>No</Button>
                  </ModalFooter>
                </Modal>
              )}
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <div className="w-100 d-flex align-items-center">
            <Button onClick={handlePreviousAttachment} className="me-auto">
              Previous
            </Button>

            <Button onClick={handleNextAttachment}>Next</Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export const initCaseAttachmentsPreview = () => {
  const attachmentList = document.querySelector(".case-attachment-list");
  const seeqerContainer = document.querySelector("#bi-details-section");

  if (attachmentList) {
    const root = createRoot(attachmentList);
    const { attachmentCategories, allowDelete, caseId } = attachmentList.dataset;

    const autoPopUpEnabled = seeqerContainer?.dataset.autoPopUp !== undefined;
    const shouldOfferPriceComparison = seeqerContainer?.dataset.autoOfferPriceComparison !== undefined;

    root.render(
      <CaseAttachmentList
        caseId={caseId}
        attachmentCategories={camelizeKeys(JSON.parse(attachmentCategories))}
        allowDelete={JSON.parse(allowDelete)}
        autoPopUpEnabled={autoPopUpEnabled}
        shouldOfferPriceComparison={shouldOfferPriceComparison}
      />
    );
  }
};
