import zxcvbn from "zxcvbn";
import $ from "jquery";

//Requires zxcvbn.js and Bootstrap
$.fn.zxcvbnProgressBar = function (options) {
  //init settings
  var settings = $.extend(
    {
      passwordInput: "#Password",
      userInputs: [],
      ratings: ["Too Weak", "Too Weak", "Too Weak", "Strong", "Very Strong"],
      //all progress bar classes removed before adding score specific css class
      allProgressBarClasses: "bg-danger bg-warning bg-success progress-bar-striped active",
      //bootstrap css classes (0-4 corresponds with zxcvbn score)
      progressBarClass0: "progress-bar-striped active bg-danger",
      progressBarClass1: "progress-bar-striped active bg-danger",
      progressBarClass2: "progress-bar-striped active bg-danger",
      progressBarClass3: "bg-success",
      progressBarClass4: "bg-success",

      allHelpTextClasses: "text-danger text-success",
      helpTextClass0: "text-danger",
      helpTextClass1: "text-danger",
      helpTextClass2: "text-danger",
      helpTextClass3: "text-success",
      helpTextClass4: "text-success",
    },
    options
  );

  return this.each(function () {
    settings.progressBar = $(this);
    settings.helpText = $('<small class="progress-help form-text"></small>');

    var passwordInput = $.find(settings.passwordInput)[0];

    //init progress bar display
    settings.helpText.insertAfter(passwordInput);
    updateProgressBar();

    //Update progress bar on each keypress of password input
    $(passwordInput).keyup(function (event) {
      updateProgressBar();
    });
  });

  function getHelpText(feedback) {
    let messages = [];

    if (feedback.warning) {
      messages.push(feedback.warning);
    }

    messages.push(feedback.suggestions[0]);

    return messages.join(". ");
  }

  function updateProgressBar() {
    var $progressBar = settings.progressBar;
    var $helpText = settings.helpText;
    var passwordInput = $.find(settings.passwordInput)[0];
    var password = $(passwordInput).val();

    if (password) {
      var result = zxcvbn(password, settings.userInputs);
      //result.score: 0, 1, 2, 3 or 4 - if crack time is less than 10**2, 10**4, 10**6, 10**8, Infinity.
      var scorePercentage = (result.score + 1) * 20;

      $helpText.text(getHelpText(result.feedback));
      $helpText.show();

      $progressBar.css("width", scorePercentage + "%");

      if (result.score == 0) {
        //weak
        $progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass0);
        $progressBar.html(settings.ratings[0]);

        $helpText.removeClass(settings.allHelpTextClasses).addClass(settings.helpTextClass0);
      } else if (result.score == 1) {
        //normal
        $progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass1);
        $progressBar.html(settings.ratings[1]);

        $helpText.removeClass(settings.allHelpTextClasses).addClass(settings.helpTextClass1);
      } else if (result.score == 2) {
        //medium
        $progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass2);
        $progressBar.html(settings.ratings[2]);

        $helpText.removeClass(settings.allHelpTextClasses).addClass(settings.helpTextClass2);
      } else if (result.score == 3) {
        //strong
        $progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass3);
        $progressBar.html(settings.ratings[3]);

        $helpText.removeClass(settings.allHelpTextClasses).addClass(settings.helpTextClass3);
      } else if (result.score == 4) {
        //very strong
        $progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass4);
        $progressBar.html(settings.ratings[4]);

        $helpText.removeClass(settings.allHelpTextClasses).addClass(settings.helpTextClass4);
      }
    } else {
      $progressBar.css("width", "0%");

      $progressBar.removeClass(settings.allProgressBarClasses).addClass(settings.progressBarClass0);

      $progressBar.html("");
      $helpText.hide();
    }
  }
};
