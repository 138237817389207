import $ from "jquery";

export const initCannedFaxResponseButton = () => {
  const $cannedResponseOptions = $("#cannedFaxResponsesSelector option");
  const $noteField = $("#send_fax_note");

  if ($cannedResponseOptions && $noteField) {
    function setNoteContents(e) {
      e.preventDefault();

      if ($noteField.val().trim() === "" || confirm("This will replace your current message. Continue?")) {
        $noteField.val(e.target.value);
      }
    }

    $cannedResponseOptions.click((e) => setNoteContents(e));
  }
};
