import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { Alert, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { adminApi as api, extractErrorMessage } from "../../../../util/api";
import { snakifyKeys } from "../../../../util/helpers";
import { SeeqerReflexMappingForm } from "./SeeqerReflexMappingForm";

const testToOption = (t) => ({ label: t.commercial_name, value: t.id });

export const EditSeeqerReflexMappingModal = ({ seeqerReflexMapping, onEdit, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const toggleModal = () => setOpen(!open);

  const handleEdit = async (params) => {
    setError(null);
    try {
      const res = await api.patch(`/admin/seeqer_reflex_mappings/${seeqerReflexMapping.id}`, {
        seeqer_reflex_mapping: snakifyKeys(params),
      });

      if (onEdit) {
        onEdit(res.data.resource);
      }

      setOpen(false);
    } catch (err) {
      setError(extractErrorMessage(err));
    }
  };

  const initialTestOptions = useMemo(() => seeqerReflexMapping.tests.map(testToOption), [seeqerReflexMapping]);

  return (
    <>
      <Button onClick={toggleModal} {...rest}>
        Edit
      </Button>

      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit seeQer Reflex Mapping</ModalHeader>

        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}

          <Form
            onSubmit={handleEdit}
            initialValues={{
              labId: seeqerReflexMapping.lab.id,
              payorId: seeqerReflexMapping?.payor?.id,
              testIds: seeqerReflexMapping.tests.map((t) => t.id),
              allPayors: seeqerReflexMapping?.payor == null,
            }}
          >
            {(props) => <SeeqerReflexMappingForm {...props} initialTestOptions={initialTestOptions} />}
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};
