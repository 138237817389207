import { createRoot } from "react-dom/client";
import { Sidebar } from "../components/Sidebar";
import { PermissionContextProvider } from "../system_config/PermissionContext";

export const initSidebar = () => {
  const sidebarContainer = document.getElementById("sidebar");

  if (sidebarContainer) {
    const root = createRoot(sidebarContainer);
    const { logoutPath, userName, logoPath, logoPathCollapsed, newCasePath, navItems, userMenuItems } = sidebarContainer.dataset;

    root.render(
      <PermissionContextProvider>
        <Sidebar
          logoutPath={logoutPath}
          userName={userName}
          logoPath={logoPath}
          newCasePath={newCasePath}
          logoPathCollapsed={logoPathCollapsed}
          userMenuItems={JSON.parse(userMenuItems)}
          navItems={JSON.parse(navItems)}
        />
      </PermissionContextProvider>
    );
  }
};
