import moment from "moment-timezone";
import React, { useState } from "react";
import { DetailedErrorAlert } from "../../components/DetailedErrorAlert";
import { LoadingIcon } from "../../components/LoadingIcon";
import { Sorter } from "../../components/sorter/Sorter";
import { api, useAdminAPI } from "../../util/api";

export const AdministrateResourceSorter = ({ resourceUrl, Label, Description, resourceKey, updateAttribute }) => {
  const [saving, setSaving] = useState(false);
  const [lastSavedAt, setLastSavedAt] = useState(null);
  const [error, setError] = useState(null);

  const { data, error: loadError, isLoading, fetch: reload } = useAdminAPI(`${resourceUrl}/all`);

  if (isLoading) {
    return <LoadingIcon />;
  }

  const handleSave = async (update) => {
    setError(null);
    setSaving(true);

    try {
      // Do update
      await api.put(`${resourceUrl}/${update.id}`, {
        [resourceKey]: { [updateAttribute]: update.newIndex },
      });

      // Reload
      reload();

      setLastSavedAt(moment());
    } catch (err) {
      setError(err);
    } finally {
      setSaving(false);
    }
  };

  return (
    <div>
      <DetailedErrorAlert error={error ?? loadError} />

      {lastSavedAt !== null && (
        <div className="mb-3">
          <span className="text-muted small">Last saved {lastSavedAt.format("MM/DD/YYYYY hh:mm a")}</span>
        </div>
      )}

      <Sorter Label={Label} Description={Description} initialItems={data.resources} disabled={isLoading || saving} onChange={handleSave} />
    </div>
  );
};
