import { createRoot } from "react-dom/client";

import { UnmappedClinicMatchesGrid } from "../../unmapped_clinic_matches_grid";

export const initUnmappedClinicMatchesGrid = () => {
  const gridContainer = document.getElementById("unmapped_clinic_mappings_grid");

  if (gridContainer) {
    const root = createRoot(gridContainer);
    root.render(<UnmappedClinicMatchesGrid />);
  }
};
