import axios from "axios";
import cx from "classnames";
import { debounce } from "lodash";
import React, { Component } from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import { PersistantReactTable } from "../components/grid/PersistantReactTable";
import { Switch } from "../components/inputs/Switch";
import { Toolbar } from "../components/Toolbar";
import { api } from "../util/api";
import { encodeTableState } from "../util/table";
import { useColumns } from "./columns";

export class FaxesGrid extends Component {
  state = {
    data: [],
    pages: -1,
    loading: false,
    search: "",
    showNeedReview: true,
  };

  constructor(props) {
    super(props);
    this.table = React.createRef();
    this.fetchData = debounce(this.fetchData, 300);
  }

  updateTableData = () => this.table.updateTableData();

  fetchData = async (tableState = null, _instance) => {
    // show the loading overlay
    this.setState({ loading: true });

    try {
      let dataReq = await api.get(this.props.apiUrl, {
        params: {
          lab_id: this.props.labId,
          discarded: this.props.discarded,
          sent: this.props.sent,
          show_need_review: this.state.showNeedReview,
          ...tableState,
        },
      });

      this.setState({
        data: dataReq.data.rows,
        pages: dataReq.data.pages,
      });
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error(e);
      }
    } finally {
      this.setState({ loading: false, tableState });
    }
  };

  onFetchData = async (rawTableState, _instance) => {
    const tableState = encodeTableState(rawTableState, this.state);

    try {
      await this.fetchData(tableState);
    } catch (e) {
      console.error(e);
    }
  };

  onSearchChange = (e) => {
    const search = e.target.value;
    this.setState({ search }, () => this.updateTableData());
  };

  rowProps = (rowInfo) => {
    if (!rowInfo) return {};
    const { original } = rowInfo;

    return {
      className: cx({
        read: original.read,
        unread: !original.read,
      }),
    };
  };

  onToggleShowNeedReview = () => {
    this.setState({ showNeedReview: !this.state.showNeedReview });
    this.updateTableData();
  };

  render() {
    const { data, pages, loading, showNeedReview } = this.state;
    const { isInternalUser, discarded, sent, labList } = this.props;
    const labOptions = JSON.parse(labList).map((l) => ({ value: l.id, label: l.lab_name }));

    // This is not really a hook, it just follows a naming
    // convention for hooks, and so it triggers eslint.
    const columns = useColumns({ isInternalUser, discarded, sent, labOptions }); // eslint-disable-line react-hooks/rules-of-hooks

    return (
      <Card>
        {!discarded && !sent && (
          <CardHeader>
            <Row>
              <Col>
                <Toolbar className="ms-auto">
                  <Switch
                    id="show-need-review-toggle"
                    label="Show Faxes Needing Review"
                    checked={showNeedReview}
                    className="me-3"
                    onChange={this.onToggleShowNeedReview}
                  />
                </Toolbar>
              </Col>
            </Row>
          </CardHeader>
        )}

        <PersistantReactTable
          ref={(i) => (this.table = i)}
          columns={columns}
          data={data}
          pages={pages}
          loading={loading}
          onFetchData={this.onFetchData}
          getTrProps={this.rowProps}
          striped={false}
          defaultSorted={[{ id: "id" }]}
        />
      </Card>
    );
  }
}
